import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './fertilizerStats.constants';

export const fetchFertilizerStats = (fertId, unitId, dose) => ({
  [RSAA]: {
    endpoint: `fertilizers/${fertId}/stats?unitId=${unitId}&dose=${dose}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_FERTILIZER_STATS, types.GET_FERTILIZER_STATS_SUCCESS, types.GET_FERTILIZER_STATS_FAILURE],
  },
});

export const resetFertilizerStats = () => dispatch =>
  dispatch({
    type: types.RESET_FERTILIZER_STATS,
  });
