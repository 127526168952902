import React, { ReactNode, CSSProperties, useEffect, FC } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton, Theme, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getAgiJobsByApplicationId } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import { getExpensesExportsTableId } from '../../actions/actions.actions';

import StatusError from '../../../../../assets/img/icons/satellite/statusError.svg';
import StatusPending from '../../../../../assets/img/icons/satellite/statusPending.svg';
import StatusSuccess from '../../../../../assets/img/icons/satellite/statusSuccess.svg';
import { deleteAGIntegratedJob, downloadAGIntegratedFile, getAGIntegratedJobs } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import FileService from '../../../../../shared/services/File.service';
import { AsyncFn } from '../../../../../types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const COLUMNS = {
  format: getColDesc(false, <span style={{ marginLeft: 5 }}><FormattedMessage id="VaExports.cols.status" /></span>),
  name: getColDesc(false, <FormattedMessage id="VaExports.cols.format" />),
  date: getColDesc(false, <FormattedMessage id="VaExports.cols.date" />),
  download: {},
  remove: {},
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    color: theme.palette.grey[500],
    marginBottom: 12,
  },
  format: {
    fontWeight: 500,
  },
  statusIcon: {
    width: 20,
    marginLeft: 10,
  },
  iconColumns: {
    width: 30,
  },
}));

let cron: ReturnType<typeof setInterval>;

enum STATUSES {
  COMPLETE='Complete',
  ERROR='Error',
  PENDING='Pending',
  RUNNING='Running'
}

const getStatusCopy = (status: STATUSES) => {
  switch (status) {
    case STATUSES.COMPLETE:
      return 'VaExports.status.complete';
    case STATUSES.ERROR:
      return 'VaExports.status.error';
    case STATUSES.PENDING:
      return 'VaExports.status.pending';
    case STATUSES.RUNNING:
      return 'VaExports.status.running';
    default:
      return '';
  }
};

const getStatusIcon = (status: STATUSES) => {
  switch (status) {
    case STATUSES.COMPLETE:
      return StatusSuccess;
    case STATUSES.ERROR:
      return StatusError;
    case STATUSES.PENDING:
      return StatusPending;
    case STATUSES.RUNNING:
      return StatusPending;
    default:
      return StatusPending;
  }
};

interface Job {
  exportDate: string;
  jobId: number;
  mapType: string;
  status: STATUSES;
}

interface OwnProps {
  applicationId: number;
}
interface ConnectedProps extends OwnProps {
  deleteJob: (id: number) => void;
  downloadJobFile: (applicationId: number, jobId: number) => void;
  getJobs: (applicationId: number) => void;
  jobs?: {
    isFetching: boolean;
    result: Job[];
  }
}

const ExportsList: FC<ConnectedProps> = ({
  applicationId,
  deleteJob,
  downloadJobFile,
  getJobs, jobs,
}) => {
  useEffect(() => {
    if (!applicationId) return;
    getJobs(applicationId);
    cron = setInterval(() => getJobs(applicationId), 40 * 1000);
    return () => { clearInterval(cron); };
  }, [applicationId, getJobs]);

  const classes = useStyles();

  const handleDownloadJobFile = (applicationId: number, jobId: number) => {
    (downloadJobFile as AsyncFn<number, number>)(applicationId, jobId)
      .then(res => FileService.processFileResponse(res));
  };

  const handleDeleteJobFile = (jobId: number) => {
    (deleteJob as AsyncFn<number>)(jobId).then(() => getJobs(applicationId));
  };

  if (!applicationId || !jobs) return null;

  const hasNodata = !jobs.isFetching && !jobs.result.length;
  const hasData = !jobs.isFetching && jobs.result.length > 0;

  return (
    <div id={getExpensesExportsTableId(applicationId)}>
      <div className={classes.heading}><FormattedMessage id="VaExports.heading" /></div>
      <CfTableWrapper>
        <CfTableHead
          columns={COLUMNS}
          namespace="EXPORTS"
    />
        {hasNodata && <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} translId="VaExports.nodata" />}
        {hasData &&
          <TableBody>
            {jobs.isFetching && <CfTableBodyLoader columns={COLUMNS} />}
            {jobs?.result.map(j =>
              <TableRow key={j.jobId}>
                <CfTableCell name="status">
                  <Tooltip title={<FormattedMessage id={getStatusCopy(j.status)} />}>
                    <img alt="success" className={classes.statusIcon} src={getStatusIcon(j.status)} />
                  </Tooltip>
                </CfTableCell>
                <CfTableCell classes={{ root: classes.format }} name="format">
                  {j.mapType}
                </CfTableCell>
                <CfTableCell name="date">
                  {/* temporary fix until BE sends ISO date string */}
                  {moment(j.exportDate).format('DD.MM.YYYY HH:mm:ss')}
                </CfTableCell>
                <CfTableCell classes={{ root: classes.iconColumns }} name="download">
                  <IconButton
                    aria-label="Download export"
                    disabled={j.status !== STATUSES.COMPLETE}
                    onClick={() => handleDownloadJobFile(applicationId, j.jobId)}
                    size="large">
                    <FileDownloadIcon />
                  </IconButton>
                </CfTableCell>
                <CfTableCell classes={{ root: classes.iconColumns }} name="remove">
                  <IconButton
                    aria-label="Remove item"
                    onClick={() => handleDeleteJobFile(j.jobId)}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </CfTableCell>
              </TableRow>,
            )}
          </TableBody>
        }
      </CfTableWrapper>
    </div>
  );
};

const mapStateToProps = (state: unknown, ownProps: OwnProps) => ({
  // @ts-ignore
  jobs: getAgiJobsByApplicationId(state, ownProps),
});

const mapDispatchToProps = {
  getJobs: getAGIntegratedJobs,
  downloadJobFile: downloadAGIntegratedFile,
  deleteJob: deleteAGIntegratedJob,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportsList);
