import React, { Component, Fragment } from 'react';

import Popover from '@mui/material/Popover';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getZones as getZonesSelector } from '../../../../../shared/api/core/zones/zones.selectors';

import { getZones, resetZones } from '../../../../../shared/api/core/zones/zones.api';
import CfSwitcherButton from '../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton';
import ZoneAutosuggest from '../../../../../shared/components/specific/ZoneAutosuggest/ZoneAutosuggest';
import withPopover from '../../../../../shared/hocs/withPopover';
import ParcelsService from '../../services/Parcels.service';

const styles = {
  paper: {
    overflow: 'hidden',
    height: 250,
    width: 250,
  },
  switcherButtonLabel: {
    fontSize: 13,
  },
  button: {
    marginLeft: -9,
  },
};

class ParcelZoneAssign extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;
    this.noZoneOption = [
      {
        id: null,
        name: formatMessage({ id: 'ParcelDetailOverview.noZoneOption' }),
      },
    ];
  }

  componentDidUpdate(prevProps) {
    const { parcel } = this.props;

    if (parcel && parcel.id && parcel.id !== prevProps.parcel.id) {
      this.fetchZones();
    }
  }

  componentWillUnmount() {
    this.props.resetZones();
  }

  onSuggestionSelect = suggestion => {
    this.props.handlePopoverClose();

    const { parcel } = this.props;
    if (parcel && parcel.zone && parcel.zone.id === suggestion.id) {
      return;
    }
    this.props.onZoneChange(suggestion.id, parcel ? parcel.id : undefined);
  };

  onSuggestionClear = () => {
    this.props.resetZones();
  };

  fetchZones = name => {
    this.props.getZones({ name });
  };

  render() {
    const {
      anchorEl,
      classes,
      handlePopoverClose,
      handlePopoverOpen,
      isOpen,
      parcel,
      suggestions,
      withPopoverEl,
    } = this.props;
    const isHistorical = ParcelsService.isParcelHistorical(parcel);

    const extendedeSuggestions = suggestions.length ? this.noZoneOption.concat(suggestions) : suggestions;
    return withPopoverEl ? (
      <Fragment>
        <CfSwitcherButton
          classes={{ custom: classes.button }}
          disabled={isHistorical}
          onClick={handlePopoverOpen}
          transparent={true}>
          <span className={classes.switcherButtonLabel}>
            {parcel && parcel.zone ? parcel.zone.name : <FormattedMessage id="ParcelDetailOverview.noZoneOption" />}
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          onClose={handlePopoverClose}
          open={isOpen}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 250,
            vertical: 0,
          }}
        >
          <ZoneAutosuggest
            autoFocus={true}
            getSuggestions={this.fetchZones}
            onSuggestionClear={this.onSuggestionClear}
            onSuggestionSelect={this.onSuggestionSelect}
            suggestions={extendedeSuggestions}
          />
        </Popover>
      </Fragment>
    ) : (
      <ZoneAutosuggest
        autoFocus={true}
        getSuggestions={this.fetchZones}
        onSuggestionClear={this.onSuggestionClear}
        onSuggestionSelect={this.onSuggestionSelect}
        suggestions={extendedeSuggestions}
      />
    );
  }
}

ParcelZoneAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  withPopoverEl: PropTypes.bool.isRequired,
  getZones: PropTypes.func.isRequired,
  resetZones: PropTypes.func.isRequired,
  onZoneChange: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  parcel: PropTypes.object,
  anchorEl: PropTypes.any,
};

ParcelZoneAssign.defaultProps = {
  withPopoverEl: true,
  anchorEl: null,
  parcel: null,
};

const mapStateToProps = state => ({
  suggestions: getZonesSelector()(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getZones,
      resetZones,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
  withPopover,
)(ParcelZoneAssign);
