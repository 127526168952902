import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  placeholderItem: {
    backgroundColor: theme.palette.grey[300],
  },
  placeholderText: {
    height: 18,
    margin: 2,
    borderRadius: 9,
  },
});

const CfLoadingPlaceholder = props => {
  const { classes, float, width } = props;
  return <div className={`${classes.placeholderItem} ${classes.placeholderText}`} style={{ width, float }} />;
};

CfLoadingPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  float: PropTypes.string,
};

CfLoadingPlaceholder.defaultProps = {
  width: 85,
  float: 'left',
};

export default withStyles(styles)(CfLoadingPlaceholder);
