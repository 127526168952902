import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getMonitoringDataError as getError,
  getMonitoringDataIsFetching,
  getMonitoringCropIntervalsIsFetching,
} from '../../../../../../shared/api/sentinel/monitoring/monitoring.selectors';
import {
  getIndex,
  getDateFrom,
  getDateTo,
  getGraphData,
  getMonitoringData,
  getCropIntervals,
  getMonitoringStatus,
} from '../../selectors/monitoring.selectors';

import { setMonitoringIndex, setMonitoringInterval, resetMonitoringInterval } from '../../actions/monitoring.actions';

import * as satelliteProductsTypes from '../../../../../../shared/constants/satelliteProductsTypes.constants';

import {
  getMonitoringData as getMonitoringDataApi,
  resetMonitoringData,
  getCropIntervals as getCropIntervalsApi,
  resetCropIntervals,
} from '../../../../../../shared/api/sentinel/monitoring/monitoring.api';
import CfErrorPage from '../../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import CfStatusPanel from '../../../../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import withWidth from '../../../../../../shared/hocs/withWidth';
import SatelliteIcon from '../../../../../../shared/icons/SatelliteIcon';
import { disableHTMLCanvasImageSmoothing, enableHTMLCanvasImageSmoothing } from '../../../../../../shared/misc/helpers';
import SectionHeader from '../../../management/components/SectionHeader/SectionHeader';
import BioMonitoringGraph from '../../components/BioMonitoringGraph/BioMonitoringGraph';
import BioMonitoringGraphInfoDialog from '../../components/BioMonitoringGraphInfoDialog/BioMonitoringGraphInfoDialog';
import BioMonitoringHistoryImage from '../../components/BioMonitoringHistoryImage/BioMonitoringHistoryImage';
import BioMonitoringHistoryInfoDialog from '../../components/BioMonitoringHistoryInfoDialog/BioMonitoringHistoryInfoDialog';
import BioMonitoringImagesOverallError from '../../components/BioMonitoringImagesOverallError/BioMonitoringImagesOverallError';
import BioMonitoringIndexSwitcher from '../../components/BioMonitoringIndexSwitcher/BioMonitoringIndexSwitcher';
import BioMonitoringLegend from '../../components/BioMonitoringLegend/BioMonitoringLegend';
import BioMonitoringStatusWrapper from '../../components/BioMonitoringStatusWrapper/BioMonitoringStatusWrapper';
import BioMonitoringZonesLegend from '../../components/BioMonitoringZonesLegend/BioMonitoringZonesLegend';
import CropIntervalSwitcher from '../../components/CropIntervalSwitcher/CropIntervalSwitcher';
import * as monitoringImageStatus from '../../selectors/monitoringStatus';
import BioMonitoringImageErrorService from '../../services/BioMonitoringImageError.service';

const supportedImageStates = [
  monitoringImageStatus.OK,
  monitoringImageStatus.NO_ZONES,
  monitoringImageStatus.NO_ZONES_2020,
  monitoringImageStatus.LAI_ONLY,
];

const styles = theme => {
  const { breakpoints } = theme;
  return {
    heading: {
      margin: 0,
      fontSize: '18px',
    },
    headerBar: {
      marginBottom: 5,
    },
    wrapper: {
      marginBottom: '10px',
    },
    dialogWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    imagesLegendWrapper: {
      marginTop: 9,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    legend: {
      fontSize: 13,
    },
    zonesLegend: {
      marginTop: 6,
      marginBottom: 3,
      marginRight: -15,
      fontSize: 13,
    },
    historicalStatusPanel: {
      marginTop: 15,
      marginBottom: 30,
    },
    [breakpoints.down('xs')]: {
      heading: {
        textAlign: 'center',
        marginBottom: 10,
      },
      imagesLegendWrapper: {
        marginTop: -6,
        alignItems: 'center',
      },
    },
  };
};

export class BioMonitoring extends Component {
  componentDidMount() {
    // temporarily disable global HTMLCanvas smoothing
    disableHTMLCanvasImageSmoothing();

    const { monitoringStatus, parcelId } = this.props;

    if (monitoringStatus === satelliteProductsTypes.ACTIVE || monitoringStatus === satelliteProductsTypes.HISTORICAL) {
      this.props.getCropIntervalsApi(parcelId);
    }
  }

  componentDidUpdate(prevProps) {
    const { cropIntervals, dateFrom, dateTo, index, monitoringStatus, parcelId } = this.props;

    if (parcelId !== prevProps.parcelId) {
      this.props.resetCropIntervals();
      this.props.resetMonitoringInterval();
      this.props.resetMonitoringData();
    }

    if (
      (monitoringStatus === satelliteProductsTypes.ACTIVE || monitoringStatus === satelliteProductsTypes.HISTORICAL) &&
      !prevProps.monitoringStatus
    ) {
      this.props.getCropIntervalsApi(parcelId);
    }

    if ((index && index !== prevProps.index) || (dateFrom && dateFrom !== prevProps.dateFrom)) {
      this.props.onZonesClick(null);
      const status = supportedImageStates.join(',');
      this.props.getMonitoringDataApi(parcelId, index, dateFrom, dateTo, status);
    }

    if (cropIntervals.length && cropIntervals !== prevProps.cropIntervals) {
      this.props.setMonitoringInterval(cropIntervals[0].from, cropIntervals[0].to);
    }
  }

  componentWillUnmount() {
    // enable global HTMLCanvas smoothing
    enableHTMLCanvasImageSmoothing();
  }

  render() {
    const {
      classes,
      cropIntervals,
      displayedMap,
      error,
      geometries,
      graphData,
      index,
      isFetchingCropIntervals,
      isFetchingData,
      langId,
      mapImage,
      monitoringData,
      monitoringStatus,
      ngRedirectToSowing,
      onZonesClick,
      parcelId,
      width,
    } = this.props;

    const overallImagesError = BioMonitoringImageErrorService.getOverallImagesError(monitoringData, index);
    const cropHarvestedError = BioMonitoringImageErrorService.checkCropHarvestedError(monitoringData);
    const lastImageDateTo = monitoringData?.[0]?.dateTo;

    return (
      <CfErrorPage error={error}>
        <BioMonitoringStatusWrapper langId={langId} monitoringStatus={monitoringStatus} width={width}>
          <Fragment>
            {cropHarvestedError && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CfStatusPanel
                    dense={true}
                    fullWidth={true}
                    horizontal={width !== 'xs'}
                    icon={cropHarvestedError.icon}
                    linkText={<FormattedMessage id="BioMonitoring.harvestCrop" />}
                    onLinkClick={() => ngRedirectToSowing([parcelId])}
                    content={
                      <FormattedMessage
                        id={`BioMonitoring.status.${cropHarvestedError.id}`}
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid className={classes.headerBar} item xs={12}>
                <Grid alignItems="center" container justifyContent={width !== 'xs' ? 'flex-start' : 'center'}>
                  <Grid data-test="bio-monitoring-switchers" item sm={'auto'} xs={12}>
                    <Grid alignItems="center" container justifyContent={'center'} spacing={1}>
                      <Grid item sm={'auto'} xs={12}>
                        <CropIntervalSwitcher
                          cropIntervals={cropIntervals}
                          onMenuItemClick={this.props.setMonitoringInterval}
                        />
                      </Grid>
                      <Grid item sm={'auto'} xs={12}>
                        <BioMonitoringIndexSwitcher index={index} setMonitoringIndex={this.props.setMonitoringIndex} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isFetchingData || isFetchingCropIntervals ? (
              <CfLoader />
            ) : (
              <Fragment>
                {overallImagesError && (
                  <BioMonitoringImagesOverallError
                    lastImageDateTo={lastImageDateTo}
                    overallImagesError={overallImagesError}
                  />
                )}
                {!monitoringData.length && (
                  <CfStatusPanel
                    fullWidth={true}
                    grey={true}
                    icon={SatelliteIcon}
                    title={<FormattedMessage id={'BioMonitoring.imagesProcessed'} />}
                  />
                )}
                {!overallImagesError && !!monitoringData.length && (
                  <Grid
                    className={classes.wrapper}
                    container
                    spacing={1}
                    style={{ height: isFetchingData || isFetchingCropIntervals ? '90%' : 'auto' }}
                  >
                    <Grid data-test="bio-monitoring-graph" item xs={12}>
                      <Paper>
                        <SectionHeader
                          dialogHeadingTranslationId={`BioMonitoring.${index}`}
                          headingTranslationId={'BioMonitoring.AverageValues.heading'}
                          infoDialogContent={<BioMonitoringGraphInfoDialog index={index} />}
                          testId="bio-monitoring-graph-header"
                        />
                        <BioMonitoringGraph data={graphData} index={index} isFetching={isFetchingData} />
                      </Paper>
                    </Grid>

                    <Grid data-test="image-history" item xs={12}>
                      <Paper>
                        <Grid container justifyContent="space-between" spacing={0}>
                          <Grid className={classes.dialogWrapper} data-test="bio-monitoring-legend" item xs={12}>
                            <SectionHeader
                              headingTranslationId={'common.history'}
                              infoDialogContent={<BioMonitoringHistoryInfoDialog index={index} />}
                              maxDialogWidth={'md'}
                              testId="bio-monitoring-legend-header"
                            >
                              <div className={classes.imagesLegendWrapper}>
                                <div className={classes.legend}>
                                  <BioMonitoringLegend index={index} />
                                </div>
                                <div className={classes.zonesLegend}>
                                  <BioMonitoringZonesLegend index={index} />
                                </div>
                              </div>
                            </SectionHeader>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start" spacing={0}>
                          {monitoringData.map((item, i) => (
                            <BioMonitoringHistoryImage
                              displayedMap={displayedMap}
                              geometries={geometries}
                              item={item}
                              key={`${item.dateFrom}-${item.dateTo}`}
                              mapImage={mapImage}
                              onMapClick={onZonesClick}
                              order={i}
                            />
                          ))}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        </BioMonitoringStatusWrapper>
      </CfErrorPage>
    );
  }
}

BioMonitoring.propTypes = {
  width: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  getCropIntervalsApi: PropTypes.func.isRequired,
  resetCropIntervals: PropTypes.func.isRequired,
  getMonitoringDataApi: PropTypes.func.isRequired,
  resetMonitoringData: PropTypes.func.isRequired,
  setMonitoringInterval: PropTypes.func.isRequired,
  resetMonitoringInterval: PropTypes.func.isRequired,
  setMonitoringIndex: PropTypes.func.isRequired,
  graphData: PropTypes.array.isRequired,
  monitoringData: PropTypes.array.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  displayedMap: PropTypes.bool.isRequired,
  onZonesClick: PropTypes.func.isRequired,
  geometries: PropTypes.array,
  mapImage: PropTypes.object,
  cropIntervals: PropTypes.array.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  monitoringStatus: PropTypes.string,
  isFetchingCropIntervals: PropTypes.bool.isRequired,
  ngRedirectToSowing: PropTypes.func.isRequired,
};

BioMonitoring.defaultProps = {
  isMonitoring: true,
  geometries: null,
  mapImage: null,
  monitoringStatus: undefined,
};

const mapStateToProps = state => ({
  graphData: getGraphData(state),
  monitoringData: getMonitoringData(state),
  isFetchingData: getMonitoringDataIsFetching(state),
  error: getError(state),
  index: getIndex(state),
  dateFrom: getDateFrom(state),
  dateTo: getDateTo(state),
  cropIntervals: getCropIntervals(state),
  monitoringStatus: getMonitoringStatus(state),
  isFetchingCropIntervals: getMonitoringCropIntervalsIsFetching(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCropIntervalsApi,
      resetCropIntervals,
      getMonitoringDataApi,
      resetMonitoringData,
      setMonitoringInterval,
      resetMonitoringInterval,
      setMonitoringIndex,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(BioMonitoring)));
