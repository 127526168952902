import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/stores.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import StoresWrapper from './shared/containers/StoresWrapper/StoresWrapper';

StoresController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Localization',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function StoresController(
  $element,
  $scope,
  $state,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    farmId: this.farmId,
    farm: this.farm,
    langId: null,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    ngGoToAction,
    reducer,
    ngGoToStore,
    ngGoToTab,
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: 'stores',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngGoToAction(actionId) {
    return $state.go('farm.active.actions.action', { actionId });
  }

  function ngGoToStore(stockId) {
    return $state.go('farm.active.store', { stockId });
  }

  function ngGoToTab(tab) {
    return $state.go(`farm.active.stores.${tab}`);
  }

  const render = () => {
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <StoresWrapper {...p} />} />, $element[0]);
  };
}
