/**
 * Created by ondrejzvara on 22.9.16.
 */


/*@ngInject*/
export default function TestGeoJSONProvider() {

    var point = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "Point",
            "coordinates": [
                25.6640625,
                48.922499263758255
            ]
        }
    };

    var polygon = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        22.5,
                        50.792047064406866
                    ],
                    [
                        27.0263671875,
                        53.461890432859114
                    ],
                    [
                        28.168945312499996,
                        52.348763181988105
                    ],
                    [
                        26.6748046875,
                        51.01375465718821
                    ],
                    [
                        22.5,
                        50.792047064406866
                    ]
                ]
            ]
        }
    };

    var multiPolygon = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            22.5,
                            50.792047064406866
                        ],
                        [
                            27.0263671875,
                            53.461890432859114
                        ],
                        [
                            28.168945312499996,
                            52.348763181988105
                        ],
                        [
                            26.6748046875,
                            51.01375465718821
                        ],
                        [
                            22.5,
                            50.792047064406866
                        ]
                    ]
                ],
                [
                    [
                        [
                            23.5,
                            50.792047064406866
                        ],
                        [
                            29.0263671875,
                            53.461890432859114
                        ],
                        [
                            30.168945312499996,
                            52.348763181988105
                        ],
                        [
                            26.6748046875,
                            51.01375465718821
                        ],
                        [
                            23.5,
                            50.792047064406866
                        ]
                    ]
                ]
            ]
        }
    };


    var invalidPolygon = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        33.134765625,
                        54.39335222384589
                    ],
                    [
                        37.79296875,
                        58.17070248348609
                    ],
                    [
                        33.134765625,
                        54.39335222384589
                    ]
                ]
            ]
        }
    };

    var linestring = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    29.179687499999996,
                    49.210420445650286
                ],
                [
                    30.3662109375,
                    51.80861475198521
                ],
                [
                    36.474609375,
                    50.90303283111257
                ],
                [
                    36.1669921875,
                    49.75287993415023
                ]
            ]
        }
    };

    var linestringIntersecting = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    25.6201171875,
                    49.439556958940855
                ],
                [
                    27.861328125,
                    51.481382896100975
                ],
                [
                    31.069335937499996,
                    49.866316729538674
                ],
                [
                    23.994140624999996,
                    51.26191485308451
                ]
            ]
        }
    };

    var polygonIntersecting = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        31.069335937499996,
                        51.590722643120145
                    ],
                    [
                        34.80468749999999,
                        55.7765730186677
                    ],
                    [
                        40.517578125,
                        53.12040528310657
                    ],
                    [
                        36.6064453125,
                        51.508742458803326
                    ],
                    [
                        30.7177734375,
                        55.1286490684888
                    ],
                    [
                        32.1240234375,
                        55.87531083569679
                    ],
                    [
                        31.069335937499996,
                        51.590722643120145
                    ]
                ]
            ]
        }
    };

    return {
        getPoint: getPoint,
        getPolygon: getPolygon,
        getInvalidPolygon: getInvalidPolygon,
        getMultiPolygon: getMultiPolygon,
        getPolygonIntersecting: getPolygonIntersecting,
        getLineString: getLineString,
        getLineStringIntersecting: getLineStringIntersecting
    };

    //////////////////////////////////////

    function getPoint(){
        return _.cloneDeep(point);
    }

    function getPolygon(){
        return _.cloneDeep(polygon);
    }

    function getInvalidPolygon(){
        return _.cloneDeep(invalidPolygon);
    }

    function getMultiPolygon(){
        return _.cloneDeep(multiPolygon);
    }

    function getPolygonIntersecting(){
        return _.cloneDeep(polygonIntersecting);
    }

    function getLineString(){
        return _.cloneDeep(linestring);
    }

    function getLineStringIntersecting(){
        return _.cloneDeep(linestringIntersecting);
    }
}



