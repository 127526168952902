import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMeasurements, getTool } from '../../selectors/editor.selectors';

import { editorToolEnd, editorToolStart } from '../../actions/editor/editor.actions';
import { removeMeasurement, clearMeasurements } from '../../actions/measurement/measurement.actions';

import * as tools from '../../constants/tools.constants';

import ToolbarIconBtn from '../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn';
import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import withWidth from '../../../../shared/hocs/withWidth';
import MeasurementIcon from '../../../../shared/icons/mapTools/MeasurementIcon';
import MeasurementItem from '../../components/MeasurementItem/MeasurementItem';
import MeasurementsOverLimit from '../../components/MeasurementsOverLimit/MeasurementsOverLimit';
import ToolbarBtn from '../../components/ToolbarBtn/ToolbarBtn';
import ToolbarBtnCancel from '../../components/ToolbarBtnCancel/ToolbarBtnCancel';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  measurementsList: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
};

export class MeasurementToolbar extends Component {
  constructor(props) {
    super(props);

    // limit for measurements items to display individually
    const measurementsLimit = this.getMeasurementsLimit(props.width);

    this.state = {
      active: false,
      measurementsLimit,
    };
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;
    if (width !== prevProps.width) {
      this.setState({
        measurementsLimit: this.getMeasurementsLimit(width),
      });
    }
  }

  getMeasurementsLimit = width => (width === 'lg' || width === 'xl' ? 10 : 5);

  startMeasureTool = () => {
    const { tool } = this.props;
    if (!(tool === tools.MEASURE)) {
      if (tool) {
        this.props.editorToolEnd(tool);
      }
      this.props.editorToolStart(tools.MEASURE);
    }
  };

  removeMeasurementsOverLimit = () => {
    const { measurements } = this.props;
    const { measurementsLimit } = this.state;
    measurements.slice(measurementsLimit).forEach(measurement => {
      this.props.removeMeasurement(measurement);
    });
  };

  render() {
    const { classes, measurements, tool } = this.props;
    const { measurementsLimit } = this.state;

    return (
      <div className={classes.wrapper}>
        <ToolbarSection>
          <ToolbarIconBtn
            active={tool === tools.MEASURE}
            aria-label={tools.MEASURE}
            callback={this.startMeasureTool}
            data-test={tools.MEASURE}
            icon={MeasurementIcon}
            key={tools.MEASURE}
            product-fruits={'measure parcel'}
            tooltipTitle={`ToolbarIconBtn.${tools.MEASURE}-tooltip`}
            withBackground={true}
          />
        </ToolbarSection>
        {tool === tools.MEASURE && (
          <Fragment>
            <div className={classes.measurementsList}>
              {measurements.slice(0, measurementsLimit).map(measurement => (
                <MeasurementItem
                  key={measurement.id}
                  measurement={measurement}
                  onRemove={() => {
                    this.props.removeMeasurement(measurement);
                  }}
                />
              ))}
              {measurements.length > measurementsLimit && (
                <MeasurementsOverLimit
                  onRemove={this.removeMeasurementsOverLimit}
                  overLimit={measurements.length - measurementsLimit}
                />
              )}
              {measurements.length > 0 && (
                <ToolbarBtn callback={this.props.clearMeasurements} light={true} translationId={'common.deleteAll'} />
              )}
            </div>
            <ToolbarBtnCancel
              onCancel={() => {
                this.props.editorToolEnd(tools.MEASURE);
              }}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tool: getTool(state),
  measurements: getMeasurements(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editorToolStart,
      editorToolEnd,
      removeMeasurement,
      clearMeasurements,
    },
    dispatch,
  );

MeasurementToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  editorToolStart: PropTypes.func.isRequired,
  editorToolEnd: PropTypes.func.isRequired,
  removeMeasurement: PropTypes.func.isRequired,
  clearMeasurements: PropTypes.func.isRequired,
  measurements: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  tool: PropTypes.string,
};

MeasurementToolbar.defaultProps = {
  tool: null,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(compose(withStyles(styles), withWidth())(MeasurementToolbar)),
);
