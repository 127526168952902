import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const styles = theme => {
  const { breakpoints } = theme;
  return {
    root: {
      textAlign: 'left',
      lineHeight: 1.25,
      '&:focus': {
        textDecoration: 'none',
        outline: 'none',
      },
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    [breakpoints.down('xs')]: {
      root: {
        margin: 0,
        minWidth: 48,
      },
      leftIcon: {
        marginRight: 0,
      },
    },
  };
};

/**
 * Back button which is fully responsive; It should route to the provided path
 * @version 1.0.0
 */
const CfBackButton = props => {
  const { classes, onClick, path, translId } = props;
  const linkProps = onClick ? { onClick } : { component: Link, to: path };
  return (
    <Grid alignContent="flex-start" container justifyContent="flex-start" spacing={0}>
      <Grid item xs={12}>
        <Button
          aria-label="go back button"
          color="primary"
          size="large"
          classes={{
            root: classes.root,
          }}
          {...linkProps}
        >
          <ArrowBackIcon className={classes.leftIcon} />
          <Hidden only={['xs', 'lg', 'xl']}>
            <FormattedMessage id="common.back" />
          </Hidden>
          <Hidden only={['xs', 'sm', 'md']}>
            <FormattedMessage id={translId} />
          </Hidden>
        </Button>
      </Grid>
    </Grid>
  );
};

CfBackButton.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** Path where button should redirect the router */
  path: PropTypes.string,
  /** Translation ID of the button text */
  translId: PropTypes.string,
  /** Callback called after the button was clicked; Is defined, the buttons Link behavior is ovverriden */
  onClick: PropTypes.func,
};

CfBackButton.defaultProps = {
  translId: 'common.back',
  path: '',
  onClick: null,
};

export default withStyles(styles)(CfBackButton);
