import React, { Component } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import Coordinates from 'coordinate-parser';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import CfAutosuggest from '../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

const max4326lat = 90;
const min4326lat = -90;
const max4326lon = 180;
const min4326lon = -180;

const styles = theme => ({
  suggestionsContainerOpen: {
    position: 'static',
    marginTop: 7,
    margin: 0,
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
    display: 'block !important',
  },
  suggestionsContainer: {
    // fixes Firefox behaviour
    display: 'none',
    zIndex: 10,
  },
  suggestionsList: {
    maxHeight: '300px',
    maxWidth: '350px',
  },
  suggestionItem: {
    overflow: 'inherit',
    fontSize: 14,
    paddingTop: 6,
    paddingBottom: 6,
  },
  input: {
    padding: '2px 5px 1px 0',
    fontSize: '14px',
  },
  positionStart: {
    width: '30px',
    height: '30px',
    padding: 0,
    margin: '0 3px',
  },
  container: {},
  isEmpty: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    overflow: 'hidden',
    fontSize: '14px',
    padding: '12px 16px',
    borderRadius: 4,
    marginTop: 7,
  },
  greyText: {
    color: theme.palette.grey[500],
  },
});

export class ParcelCoordSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      position: false,
      isNoResult: false,
    };

    this.hoveredSuggestionId = null;
    this.getDebouncedSuggestions = debounce(this.props.getSuggestions, 500);
  }

  componentWillUnmount() {
    if (this.getDebouncedSuggestions) {
      this.getDebouncedSuggestions.cancel();
    }
  }

  onReset = () => {
    this.onChange('');
    return this.props.onSuggestionReset();
  };

  onChange = value => {
    this.setState({
      value,
      isSelected: false,
      isNoResult: false,
    });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const { isNoResult, position } = this.state;
      const { suggestions } = this.props;

      if (suggestions.length) {
        return;
      }

      if (isNoResult) this.setState({ isNoResult: false });

      if (!position) {
        const { isFetching } = this.props;
        if (isFetching) {
          return;
        }

        const { value } = this.state;
        this.getDebouncedSuggestions.cancel();
        this.props.getSuggestions(value).then(res => {
          if (res && !res.payload.length) {
            this.setState({ isNoResult: true });
          }
        });
      } else if (!position) {
        this.setState({ isNoResult: true });
      }
    }
  };

  onSuggestionSelect = suggestion => {
    this.setState({ isSelected: true, position: false });
    this.props.onSuggestionSelect(suggestion);
  };

  onItemMouseEnter = sugg => {
    const { position } = this.state;
    if (!position && !this.hoveredSuggestionId) {
      this.hoveredSuggestionId = sugg.id;
      this.props.onItemHover(this.hoveredSuggestionId);
    }
  };

  onItemMouseLeave = () => {
    const { position } = this.state;
    if (!position && this.hoveredSuggestionId) {
      this.hoveredSuggestionId = null;
      this.props.onItemHover(this.hoveredSuggestionId);
    }
  };

  getSuggestions = (value, reason) => {
    const position = this.isValidPosition(value.replace(/,/g, '.'));
    this.setState({
      position,
    });

    if (!position) {
      return this.getDebouncedSuggestions(value, reason);
    }
  };

  getParcelSuggestionName = suggestion =>
    `${suggestion.blockNr ? `${suggestion.blockNr}` : ''} ${suggestion.localName ? suggestion.localName : ''}`;

  getSuggestionValue = suggestion => {
    if (suggestion.blockNr || suggestion.localName) {
      return this.getParcelSuggestionName(suggestion);
    } else if (suggestion.lat) {
      return `${suggestion.lat.toFixed(6)}, ${suggestion.lon.toFixed(6)}`;
    }
  };

  getSuggestionItemValue = suggestion => {
    if (suggestion.blockNr || suggestion.localName) {
      return this.getParcelSuggestionName(suggestion);
    } else if (suggestion.lat) {
      const { classes } = this.props;
      return (
        <span>
          <span className={classes.greyText}>
            <FormattedMessage id="common.lat" />
          </span>{' '}
          <span>{suggestion.lat.toFixed(6)}</span>{' '}
          <span className={classes.greyText}>
            <FormattedMessage id="common.lon" />
          </span>{' '}
          <span>{suggestion.lon.toFixed(6)}</span>
        </span>
      );
    }
  };

  isValidPosition = value => {
    if (value.indexOf('/') !== -1) {
      return false;
    }

    let coords = false;
    try {
      coords = new Coordinates(value);
    } catch (e) {
      return coords;
    }

    if (!this.isValidCoords(coords)) {
      return false;
    }

    return coords;
  };

  isValidCoords = coords => {
    if (!coords.latitude || !coords.longitude) {
      return false;
    }

    return (
      min4326lat <= coords.latitude &&
      coords.latitude <= max4326lat &&
      min4326lon <= coords.longitude &&
      coords.longitude <= max4326lon
    );
  };

  render() {
    const { classes, suggestions } = this.props;
    const { isNoResult, isSelected, position, value } = this.state;
    const { formatMessage } = this.props.intl;
    const icon = value.length ? <ClearIcon /> : <SearchIcon />;
    const sugg =
      position && !isSelected ? [{ lat: position.getLatitude(), lon: position.getLongitude() }] : suggestions;

    return (
      <div>
        <CfAutosuggest
          autoFocus={this.props.autoFocus}
          clearInputOnAdornmentClick={true}
          clearInputOnSelect={false}
          clearSuggestions={this.props.onSuggestionClear}
          disableUnderline={true}
          getSuggestionItemValue={this.getSuggestionItemValue}
          getSuggestions={this.getSuggestions}
          getSuggestionValue={this.getSuggestionValue}
          multiSection={false}
          onAdornmentClick={this.onReset}
          onChange={this.onChange}
          onItemMouseEnter={this.onItemMouseEnter}
          onItemMouseLeave={this.onItemMouseLeave}
          onKeyPress={this.onKeyPress}
          onSuggestionSelected={this.onSuggestionSelect}
          placeholder={formatMessage({ id: 'ParcelCoordSearch.map-search' })}
          scrollable={true}
          suggestions={sugg}
          testData="map-search-selector"
          classes={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsContainer: classes.suggestionsContainer,
            suggestionsList: classes.suggestionsList,
            suggestionItem: classes.suggestionItem,
            input: classes.input,
          }}
          startAdornment={
            <IconButton
              color="default"
              size="large"
              classes={{
                root: classes.positionStart,
              }}>
              {icon}
            </IconButton>
          }
        />
        {isNoResult && (
          <div className={classes.isEmpty}>
            <FormattedMessage id={'ParcelCoordSearch.is-no-result'} />{' '}
          </div>
        )}
      </div>
    );
  }
}

ParcelCoordSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  isFetching: PropTypes.bool,
  autoFocus: PropTypes.bool,
  getSuggestions: PropTypes.func.isRequired,
  onSuggestionReset: PropTypes.func.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  onItemHover: PropTypes.func.isRequired,
};

ParcelCoordSelector.defaultProps = {
  suggestions: [],
  isFetching: false,
  autoFocus: false,
};

export default injectIntl(withStyles(styles)(ParcelCoordSelector));
