import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/admin.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import AdminWrapper from './shared/containers/AdminWrapper/AdminWrapper';

AdminController.$inject = [
  '$element',
  '$scope',
  '$rootScope',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Localization',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function AdminController(
  $element,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    reducer,
    langId: null,
    ngImpersActivate: Auth.impersActivate,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function render() {
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <AdminWrapper {...p} />} />, $element[0]);
  }
}
