import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getSeeds, getSeedsIsFetching } from '../../../../../shared/api/core/seeds/seeds.selectors';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';

function SeedSelector({ isEditing, isFetching, seeds }) {
  if (isFetching) {
    return (
      <LinearProgress color="secondary" />
    );
  }

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing || isFetching}
        label={<FormattedMessage id="action.sowingSeed" />}
        name="seedId"
        select
        validate={validators.required}
        validateOnBlur
      >
        {seeds.map(seeds => (
          <MenuItem key={seeds.id} value={seeds.id}>
            {seeds.name}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
}

SeedSelector.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  seeds: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  seeds: getSeeds()(state),
  isFetching: getSeedsIsFetching()(state),
});

export default connect(mapStateToProps)(SeedSelector);
