import React from 'react';

import BaseContainer from '../../../../../shared/containers/BaseContainer/BaseContainer';
import ActionOtherDetail from '../ActionOtherDetail/ActionOtherDetail';

const ActionOtherDetailWrapper = props => {
  const { langId, translations } = props;

  return (
    <BaseContainer langId={langId} translations={translations}>
      <ActionOtherDetail {...props} />
    </BaseContainer>
  );
};

export default ActionOtherDetailWrapper;
