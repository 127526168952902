export const fusariumCalculatorScale = [
  {
    id: 1,
    nameTranslationId: 'highRisk',
    boundaryTranslationId: 'highRiskBoundary',
    color: '#D01D02',
    descriptionTranslationId: 'highRiskDescription',
  },
  {
    id: 2,
    nameTranslationId: 'moderateRisk',
    boundaryTranslationId: 'moderateRiskBoundary',
    color: '#FF6B00',
    descriptionTranslationId: 'moderateRiskDescription',
  },
  {
    id: 3,
    nameTranslationId: 'lowRisk',
    boundaryTranslationId: 'lowRiskBoundary',
    color: '#EBA607',
    descriptionTranslationId: 'lowRiskDescription',
  },
];
