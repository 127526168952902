import * as types from './actions.constants';

export default () => (
  state = {
    isFetching: false,
    isFetchingAction: false,
    error: {},
    errorAction: {},
    validationError: undefined,
    items: [],
    item: {},
    totalCount: 0,
    isDeleting: false,
    errorDeleting: {},
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_ACTIONS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_ACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.GET_ACTION:
      return {
        ...state,
        isFetchingAction: true,
        errorAction: {},
      };
    case types.GET_ACTION_SUCCESS:
      return {
        ...state,
        isFetchingAction: false,
        item: action.payload,
      };
    case types.GET_ACTION_ERROR:
      return {
        ...state,
        isFetchingAction: false,
        errorAction: action.payload,
      };
    case types.ACTION_VALIDATE:
      return {
        ...state,
        isFetching: true,
      };
    case types.ACTION_VALIDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        validationError: undefined,
      };
    case types.ACTION_VALIDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        validationError: action.payload,
      };
    case types.RESET_VALIDATE_FAILURE:
      return {
        ...state,
        validationError: undefined,
      };
    case types.ACTION_SAVE:
      return {
        ...state,
        isFetching: true,
      };
    case types.ACTION_SAVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        validationError: undefined,
        error: {},
      };
    case types.ACTION_SAVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.DELETE_ACTIONS:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_ACTIONS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        errorDeleting: {},
      };
    case types.DELETE_ACTIONS_ERROR:
      return {
        ...state,
        isDeleting: false,
        errorDeleting: action.payload,
      };
    default:
      return state;
  }
};
