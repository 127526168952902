import React, { Fragment } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';

import CfBackButton from '../../../../../shared/components/common/CfBackButton/CfBackButton';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import VariableActionExport from '../VariableActionExport/VariableActionExport';

const styles = theme => ({
  headerWrapper: {
    padding: theme.spacing(2),
  },
});

const ActionFormHeader = props => {
  const { classes, isDisabled, isExisting, isLoading, ngGoToActions, onClick, onExport, title } = props;
  return (
    <div className={classes.headerWrapper}>
      <PageHeader
        backButton={<CfBackButton onClick={() => ngGoToActions()} translId={'action.backToActivities'} />}
        heading={<PageHeading dataTest="action-name" translationId={title} />}
        actionButtons={
          isExisting ? (
            <Fragment>
              {onExport &&
              <VariableActionExport
                iconButton={true}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onExport={onExport}
              />}
              <IconButton data-test="eph-edit" disabled={isDisabled} onClick={onClick} size="large">
                <EditIcon />
              </IconButton>
            </Fragment>
          ) : null
        }
      />
    </div>
  );
};

ActionFormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  ngGoToActions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ActionFormHeader.defaultProps = {
  onExport: undefined,
  isLoading: false,
};

export default compose(withStyles(styles), withFarm())(ActionFormHeader);
