import React from 'react';

import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  statusIcon: {
    width: 16,
    height: 16,
  },
  iconHover: {
    '&:hover $statusIconHover': {
      visibility: 'visible',
    },
    position: 'relative',
    display: 'flex',
    padding: 4,
    width: 24,
    height: 24,
  },
  statusIconHover: {
    visibility: 'hidden',
    position: 'absolute',
    width: 16,
    height: 16,
    color: 'rgba(51, 51, 51, 0.3)',
  },
  uninstalled: {
    color: theme.palette.grey[400],
  },
  installed: {
    color: '#296AF6',
  },
});

function IrrigationStatus(props) {
  const { classes, installed } = props;
  const messageId = installed ? 'IrrigationStatus.installedUnit' : 'IrrigationStatus.uninstalledUnit';
  return (
    <Tooltip
      placement="bottom"
      title={
        <span>
          <FormattedMessage id={messageId} />
        </span>
      }
    >
      <span className={classes.iconHover}>
        <FiberManualRecord className={classes.statusIconHover} />
        <FiberManualRecord
          className={`${classes.statusIcon} ${installed ? classes.installed : classes.uninstalled}`}
          data-test={'status'}
        />
      </span>
    </Tooltip>
  );
}

IrrigationStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  installed: PropTypes.bool,
};

IrrigationStatus.defaultProps = {
  installed: false,
};

export default withStyles(styles)(IrrigationStatus);
