import React, { Component } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedTime } from 'react-intl';

import IrrigationService from '../../services/Irrigation.service';

const HOVER_BUFFER = 4;
const GRAPH_HEIGHT = 14;

const styles = theme => ({
  intervals: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    height: '14px',
    borderRadius: '10px',
    position: 'relative',
  },
  interval: {
    position: 'absolute',
    backgroundColor: '#296AF6',
    top: HOVER_BUFFER,
    left: HOVER_BUFFER,
    height: GRAPH_HEIGHT,
    borderRadius: '10px',
  },
  hoverInterval: {
    height: GRAPH_HEIGHT + HOVER_BUFFER * 2,
    position: 'absolute',
    top: -HOVER_BUFFER,
    visible: false,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: 3,
    '-webkit-border-radius': 3,
    '-moz-border-radius': 3,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-moz-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
  tooltipTitleLabel: {
    fontWeight: 400,
    marginRight: 5,
  },
  tooltipTitleTime: {
    color: '#296AF6',
  },
});

class ValveChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphWidth: 0,
    };
    this.graphWrapperRef = React.createRef();
  }

  componentDidMount() {
    this.updateGraphWidth();
    window.addEventListener('resize', this.updateGraphWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateGraphWidth);
  }

  updateGraphWidth = () => {
    this.setState({
      graphWidth: this.graphWrapperRef.current?.offsetWidth || 0,
    });
  };

  renderInterval = interval => {
    const { classes, datetimeEnd, datetimeStart } = this.props;
    const { graphWidth } = this.state;

    const startEnd = interval.split('/');

    if (startEnd[0] === startEnd[1]) {
      return null;
    }

    const startInPixels = IrrigationService.getChartDatetimePositionInPixels(
      startEnd[0],
      graphWidth,
      datetimeStart,
      datetimeEnd,
    );
    const endInPixels = IrrigationService.getChartDatetimePositionInPixels(
      startEnd[1],
      graphWidth,
      datetimeStart,
      datetimeEnd,
    );

    let width = Math.ceil(endInPixels - startInPixels);
    if (isNaN(width) || width === 0) {
      return null;
    }

    if (width > 0 && width < 2) {
      width = 2;
    }
    const left = Math.round(startInPixels);

    return (
      <Tooltip
        key={`${startEnd[0]}-${startEnd[1]}`}
        classes={{
          tooltip: classes.tooltip,
        }}
        title={
          <span>
            <span className={classes.tooltipTitleLabel}>
              <FormattedMessage id={'ValveIntervals.irrigated'} />
            </span>
            <span className={classes.tooltipTitleTime}>
              <FormattedTime value={startEnd[0]} />
              &ndash;
              <FormattedTime value={startEnd[1]} />
            </span>
          </span>
        }
      >
        <span className={classes.hoverInterval} style={{ width: width + HOVER_BUFFER * 2, left: left - HOVER_BUFFER }}>
          <span
            className={classes.interval}
            style={{
              width,
            }}
          />
        </span>
      </Tooltip>
    );
  };

  render() {
    const { classes, intervals } = this.props;

    return (
      <div className={classes.intervals} ref={this.graphWrapperRef}>
        {intervals.map(interval => this.renderInterval(interval))}
      </div>
    );
  }
}

ValveChart.propTypes = {
  intervals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  datetimeStart: PropTypes.string,
  datetimeEnd: PropTypes.string,
};

ValveChart.defaultProps = {
  datetimeStart: null,
  datetimeEnd: null,
};

export default withStyles(styles)(ValveChart);
