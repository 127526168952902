import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './farmUsers.constants';

export const getUsersByFarm = farmId => {
  const params = {
    farmId,
  };
  return {
    [RSAA]: {
      endpoint: `farmUsers?${queryString.stringify(params)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_USERS_BY_FARM, types.GET_USERS_BY_FARM_SUCCESS, types.GET_USERS_BY_FARM_ERROR],
    },
  };
};
