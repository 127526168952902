import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => (
  {
    container: {
      display: 'flex',
      height: '93vh',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#fff',
      flexWrap: 'nowrap',
    },
    header: {
      width: '100%',
      marginTop: '27px',
      marginBottom: '35px',
    },
    areaName: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '64px',
    },
    buttons: {
      alignSelf: 'flex-end',
      margin: 'auto 24px 20px 0',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '60px',
      },
    },
    button: {
      marginLeft: '8px',
      alignSelf: 'flex-end',
    },
    buttonLoader: {
      marginRight: '5px',
    },
  }
));
