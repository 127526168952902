import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getGroups } from '../../../shared/api/iot/groups/groups.selectors';
import { getLocationsAdvancedFilter } from '../../selectors/locations.selectors';

import { setAdvancedFilter } from '../../../shared/actions/filter.actions';

import SensorFilterByNodeType from '../../../shared/components/specific/SensorFilterByNodeType/SensorFilterByNodeType';
import SensorFilterByStatus from '../../../shared/components/specific/SensorFilterByStatus/SensorFilterByStatus';
import CfFilter from '../../../shared/containers/CfFilter/CfFilter';
import DeviceTypesService from '../../../shared/services/DeviceTypes.service';
import FilterService from '../../../shared/services/Filter.service';
import NodeLocationSelectGroups from '../../components/NodeLocationSelectGroups/NodeLocationSelectGroups';

const styles = {
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
  tooltip: {
    marginBottom: 4,
  },
};
export class NodeLocationsAdvancedFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedFilter: { ...props.advancedFilter },
    };
  }

  componentDidUpdate(prevProps) {
    const { isHistoryActive, namespace } = this.props;
    if (isHistoryActive && !prevProps.isHistoryActive) {
      const newValues = FilterService.getUpdatedFilterState(this.state.advancedFilter, 'status', []);
      this.props.setAdvancedFilter(newValues, namespace);
    }
    const oldProps = pick(prevProps.advancedFilter, ['status', 'nodeType', 'groups']);
    const newProps = pick(this.props.advancedFilter, ['status', 'nodeType', 'groups']);
    if (!isEqual(newProps, oldProps)) {
      this.setFilterState({ ...this.props.advancedFilter });
    }
  }

  setFilterState = newValues => {
    this.setState({
      advancedFilter: newValues,
    });
  };

  handleChange = (filterPart, items) => {
    const filters = FilterService.getUpdatedFilterState(this.state.advancedFilter, filterPart, items);
    this.setFilterState(filters);
  };

  render() {
    const { advancedFilter, classes, groups, intl, isHistoryActive, langId, namespace } = this.props;
    const deviceGroups = DeviceTypesService.getDeviceGroups();
    const { formatMessage } = intl;
    const nodeTypes = deviceGroups.map(deviceGroup => ({
      name: deviceGroup,
      label: formatMessage({ id: `DeviceGroup.${deviceGroup}` }),
    }));

    return (
      <CfFilter
        filterState={this.state.advancedFilter}
        langId={langId}
        namespace={namespace}
        setAdvancedFilterState={this.setFilterState}
      >
        <div className={classes.container}>
          <Grid container>
            <Tooltip
              title={isHistoryActive ? <FormattedMessage id="SensorsAdvancedFilter.statusDisabledExplanation" /> : ''}
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <Grid className={classes.select} item xs={12}>
                <SensorFilterByStatus
                  disabled={isHistoryActive}
                  label={<FormattedMessage id="common.state" />}
                  selectedStatus={advancedFilter.status}
                  setSensorStatusFilter={this.handleChange}
                  useLabelTranslation={true}
                />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid container>
            <Grid className={classes.select} item xs={12}>
              <SensorFilterByNodeType
                label={<FormattedMessage id="common.type" />}
                nodeTypes={nodeTypes}
                selectedNodeType={advancedFilter.nodeType}
                setNodeTypeFilter={this.handleChange}
                useLabelTranslation={true}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className={classes.select} item xs={12}>
              <NodeLocationSelectGroups
                groups={groups}
                label={<FormattedMessage id="NodeGroups.group" />}
                selectedGroups={advancedFilter.groups}
                setGroups={this.handleChange}
              />
            </Grid>
          </Grid>
        </div>
      </CfFilter>
    );
  }
}

NodeLocationsAdvancedFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  namespace: PropTypes.string,
  advancedFilter: PropTypes.object,
  langId: PropTypes.string.isRequired,
  isHistoryActive: PropTypes.bool.isRequired,
  setAdvancedFilter: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
};

NodeLocationsAdvancedFilter.defaultProps = {
  anchorEl: null,
  namespace: null,
  advancedFilter: {},
};

const mapStateToProps = state => ({
  advancedFilter: getLocationsAdvancedFilter(state),
  groups: getGroups(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAdvancedFilter,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeLocationsAdvancedFilter)),
);
