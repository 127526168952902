import React from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  hint: {
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
});

export const SatelliteImagesInfoDialog = props => {
  const { children, classes } = props;

  return (
    <div>
      <div className={classes.hint}>
        <Typography>
          <FormattedMessage id={'SatelliteImagesInfoDialog.hint'} />
        </Typography>
      </div>
      {children}
    </div>
  );
};

SatelliteImagesInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

SatelliteImagesInfoDialog.defaultProps = {
  children: null,
};

export default withStyles(styles)(SatelliteImagesInfoDialog);
