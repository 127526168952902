import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './predictions.constants';

export const setPredictionConfig = (featureLocationId, params) => ({
  [RSAA]: {
    endpoint: `pest-predictions/computed-feature-location/${featureLocationId}/configuration?`,
    method: methods.PATCH,
    module: modules.IOT,
    body: JSON.stringify(params),
    types: [
      types.SET_PREDICTION_CONFIG,
      types.SET_PREDICTION_CONFIG_SUCCESS,
      types.SET_PREDICTION_CONFIG_ERROR,
    ],
  },
});

export const getPredictionConfig = (featureLocationName, featureLocationId) => ({
  [RSAA]: {
    endpoint: `pest-predictions/computed-feature-location/${featureLocationId}?`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.GET_PREDICTION_CONFIG,
      types.GET_PREDICTION_CONFIG_SUCCESS,
      types.GET_PREDICTION_CONFIG_ERROR,
    ],
  },
});
