import React from 'react';

import Tabs from '@mui/material/Tabs';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';

const styles = theme => ({
  tabsRoot: {
    backgroundColor: theme.palette.grey[100],
  },
  indicator: {
    display: 'none',
  },
});

/**
 * Tabs wrapper for additional navigation over panels, papers or tables.
 * Other extra props not defined here will be propagated to MUI Tabs component.
 * @version 1.0.0
 */
const CfPanelTabs = props => {
  const { children, classes, onChange, value, ...otherProps } = props;

  return (
    <Tabs
      onChange={(evt, val) => onChange(val)}
      textColor="primary"
      value={value}
      variant="fullWidth"
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
      }}
      {...otherProps}
    >
      {children}
    </Tabs>
  );
};

CfPanelTabs.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** Tabs to be rendered inside of this component. CfPanelTab's are expected to be used. */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  /** Callback fired when user changes selected tab. */
  onChange: PropTypes.func,
  /** Value of selected tab. */
  value: PropTypes.any,
};

CfPanelTabs.defaultProps = {
  onChange: () => {},
  value: 0,
};

export default compose(withStyles(styles))(CfPanelTabs);
