import head from 'lodash/head';
import { createSelector } from 'reselect';

import {
  getMonitoringData as getMonitoringDataApi,
  getMonitoringCropIntervals,
} from '../../../../../shared/api/sentinel/monitoring/monitoring.selectors';
import { getSatelliteProducts } from '../../../../../shared/api/sentinel/satelliteProducts/satelliteProducts.selectors';

export const getIndex = state => state.ui.monitoring.index;
export const getDateFrom = state => state.ui.monitoring.dateFrom;
export const getDateTo = state => state.ui.monitoring.dateTo;

export const getMonitoringStatus = createSelector(getSatelliteProducts, products => products.biomonitoringStatus);

export const getIsMonitoring = state => Boolean(getMonitoringData(state));

export const getCropIntervals = createSelector(getMonitoringCropIntervals, cropIntervals =>
  cropIntervals.map(cropInterval => ({
    id: `${cropInterval.crop.legislativeCode}_${cropInterval.from}`,
    ...cropInterval,
  })),
);

export const getMonitoringData = createSelector(getMonitoringDataApi, data =>
  data
    .filter(item => item?.snapshots?.length)
    .map(item => ({
      crop: item.crop,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      status: item.status,
      value: head(item.snapshots)?.value,
      imgPath: head(item.snapshots)?.imgPath,
      imgExtent: head(item.snapshots)?.imgExtent,
      zones: head(item.snapshots)?.zones,
      indexType: head(item.snapshots)?.indexType,
    })),
);

export const getGraphData = createSelector(getMonitoringData, data => data.slice().reverse());
