import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const KnowledgebaseIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M12,2.6c-5.2,0-9.4,4.2-9.4,9.4s4.2,9.4,9.4,9.4s9.4-4.2,9.4-9.4S17.2,2.6,12,2.6z M13,17.7h-2.1v-2.1H13V17.7zM14.6,11.4l-0.7,0.7c-0.5,0.5-0.9,1-0.9,2.1v0.3h-2.1v-0.7c0-0.9,0.4-1.8,1-2.4l1-1c0.3-0.3,0.4-0.6,0.4-1c0-0.7-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v0.3H8.5V9.5C8.5,7.5,10,6,11.9,6s3.5,1.6,3.5,3.5C15.4,10.2,15.1,10.9,14.6,11.4z" />
    </svg>
  </SvgIcon>
);

export default KnowledgebaseIcon;
