import React from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const style = {
  link: {
    color: '#00A179',
    '&:visited, &:hover, &:focus': {
      color: '#00A179',
    },
  },
};

const LoginLinks = props => (
  <Grid alignItems="center" container justifyContent="center" spacing={2}>
    <Grid item xs={12}>
      <a className={props.classes.link} href="#" id="forgot-password" onClick={props.resetRedirect}>
        <FormattedMessage id="Login.forgot-password-question" />
      </a>
    </Grid>
    <Grid item xs={12}>
      <FormattedMessage id="Login.dont-have-account" />&nbsp;
      <a className={props.classes.link} href="#" onClick={props.signupRedirect}>
        <FormattedMessage id="Login.dont-have-account-register" />
      </a>
    </Grid>
  </Grid>
);

LoginLinks.propTypes = {
  resetRedirect: PropTypes.func,
  signupRedirect: PropTypes.func,
  classes: PropTypes.object,
};

LoginLinks.defaultProps = {
  resetRedirect: PropTypes.func,
  signupRedirect: PropTypes.func,
  classes: {},
};

export default withStyles(style)(LoginLinks);
