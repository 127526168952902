import React, { Fragment } from 'react';

import { IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AddCircleIconFilled from '../../../../../shared/icons/AddCircleIconFilled';
import CloseCircleIconFilled from '../../../../../shared/icons/CloseCircleIconFilled';

const styles = (theme) => ({
  card: {
    width: 150,
    height: 150,
    backgroundColor: theme.palette.grey[100],
    position: 'relative',
    overflow: 'unset',
    borderRadius: '5px',
  },
  title: {
    fontSize: 16,
  },
  buttonAdd: {
    padding: 6,
    margin: 6,
    position: 'relative',
    top: '10px',
    left: '5px',
  },
  buttonRemove: {
    padding: 6,
    margin: 6,
    position: 'absolute',
    right: -27,
    top: -27,
    color: theme.palette.grey[400],
    zIndex: 10,
  },
  cardContent: {
    padding: 16,
  },
  cardActions_root: {
    justifyContent: 'flex-end',
  },
  href: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

const VariableFertilizationCard = (props) => {
  const { children, classes, disabled, onAddClick, onRemoveClick, title } = props;

  return (
    <Card className={classes.card} data-test="management-zone-card">
      {children ? (
        <Fragment>
          {children}
          <IconButton
            aria-label="remove-management-zone"
            className={classes.buttonRemove}
            disabled={disabled}
            onClick={onRemoveClick}
            size="large">
            <CloseCircleIconFilled />
          </IconButton>
        </Fragment>
      ) : (
        <a className={classes.href} onClick={onAddClick} role="button" tabIndex={0}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Typography className={classes.title} color="textSecondary">
              <FormattedMessage id={title} />
            </Typography>
          </CardContent>
          <CardActions
            classes={{
              root: classes.cardActions_root,
            }}
          >
            <IconButton
              aria-label="add-management-zone"
              className={classes.buttonAdd}
              disabled={disabled}
              size="large">
              <AddCircleIconFilled />
            </IconButton>
          </CardActions>
        </a>
      )}
    </Card>
  );
};

VariableFertilizationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

VariableFertilizationCard.defaultProps = {
  disabled: false,
  children: PropTypes.node,
};

export default withStyles(styles)(VariableFertilizationCard);
