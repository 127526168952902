import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WindGustIcon = props => (
  <SvgIcon {...props}>
    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 17.5H2V15.5H10.5" />
      <path d="M13.5 22.5V20.5C13.8978 20.5 14.2794 20.342 14.5607 20.0607C14.842 19.7794 15 19.3978 15 19C15 18.6022 14.842 18.2206 14.5607 17.9393C14.2794 17.658 13.8978 17.5 13.5 17.5H12V15.5H13.5C14.4283 15.5 15.3185 15.8688 15.9749 16.5251C16.6313 17.1815 17 18.0717 17 19C17 19.9283 16.6313 20.8185 15.9749 21.4749C15.3185 22.1312 14.4283 22.5 13.5 22.5V22.5Z" />
      <path d="M10.5 6.5H2V8.5H10.5" />
      <path d="M13.5 1.5V3.5C13.8978 3.5 14.2794 3.65803 14.5607 3.93934C14.842 4.22064 15 4.60218 15 5C15 5.39782 14.842 5.77936 14.5607 6.06066C14.2794 6.34197 13.8978 6.5 13.5 6.5H12V8.5H13.5C14.4283 8.5 15.3185 8.13125 15.9749 7.47487C16.6313 6.8185 17 5.92826 17 5C17 4.07174 16.6313 3.1815 15.9749 2.52513C15.3185 1.86875 14.4283 1.5 13.5 1.5Z" />
      <path d="M18.5698 4.5V6.5C18.9676 6.5 19.3492 6.65803 19.6305 6.93934C19.9118 7.22064 20.0698 7.60218 20.0698 8C20.0698 8.39782 19.9118 8.77936 19.6305 9.06066C19.3492 9.34197 18.9676 9.5 18.5698 9.5H2.06982V11.5H18.5698C19.4981 11.5 20.3883 11.1312 21.0447 10.4749C21.7011 9.8185 22.0698 8.92826 22.0698 8C22.0698 7.07174 21.7011 6.1815 21.0447 5.52513C20.3883 4.86875 19.4981 4.5 18.5698 4.5V4.5Z" />
      <path d="M18.5698 19.5V17.5C18.9676 17.5 19.3492 17.342 19.6305 17.0607C19.9118 16.7794 20.0698 16.3978 20.0698 16C20.0698 15.6022 19.9118 15.2206 19.6305 14.9393C19.3492 14.658 18.9676 14.5 18.5698 14.5H2.06982V12.5H18.5698C19.4981 12.5 20.3883 12.8688 21.0447 13.5251C21.7011 14.1815 22.0698 15.0717 22.0698 16C22.0698 16.9283 21.7011 17.8185 21.0447 18.4749C20.3883 19.1312 19.4981 19.5 18.5698 19.5Z" />
    </svg>
  </SvgIcon>
);

export default WindGustIcon;
