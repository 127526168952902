/**
 * Created by ondrejzvara on 25.6.16.
 */

import { getJSON } from 'redux-api-middleware';
import * as Sentry from "@sentry/browser";

/* @ngInject */
export default function ResponseErrorInterceptor(Logger, Spinner, $q, BACKEND_OPTIONS, Modal) {
  const service = {
    handleRestangularErrorResponse,
    handleRSAAErrorResponse,
    spinnerEnd
  };
  return service;

  // ///////////////////////

  function handleRestangularErrorResponse(response) {
    if (response) {
      const formattedUrl = response.config.url.replace(BACKEND_OPTIONS.apiUrl, '');
      Logger.error(response.config.method, `${response.status}`, formattedUrl, response.statusText);
    }

    if (response && response.status && response.status === 500) {
      notifySentry(response.data);

      if (response.config && response.config.bypassErrorInterceptor) {
        return $q.reject(response);
      }

      showErrorModal();
      return false;
    }
  }

  function handleRSAAErrorResponse(response, displayErrorModal = true) {
    if (response && response.status === 500) {
      getJSON(response).then((json) => {
        notifySentry(json);
        if (displayErrorModal) showErrorModal();
      });
    }
  }

  function showErrorModal() {
    const modalConfig = {
      size: 'sm',
      templateUrl: 'app/assets/templates/modals/error500.html'
    };

    Modal.activateModal(modalConfig);
  }

  function spinnerEnd(response) {
    Spinner.responseErrorSpinnerInterceptor(response.config.url, response.config.method, response.config.params);
  }

  function notifySentry(response) {
    Sentry.captureException(new Error(errorMessage(response)));
  }

  function errorMessage(response) {
    if (response) {
      return `${response.status} ${response.error} ${response.path} ${response.exception} ${response.message}`;
    }
    return '';
  }
}
