import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './fertilizers.constants';

export const getFertilizersByName = search => ({
  [RSAA]: {
    endpoint: `fertilizers?name=${search}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_FERTILIZERS, types.GET_FERTILIZERS_SUCCESS, types.GET_FERTILIZERS_FAILURE],
  },
});

export const resetFertilizers = () => ({
  type: types.RESET_FERTILIZERS,
});
