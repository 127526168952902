/**
 * Created by brasko on 28.9.2015.
 */


/*@ngInject*/
export default function cfFocus($timeout) {

    var directive = {
        restrict: 'A',
        scope: {
            focus: '=cfFocus'
        },
        link: function (scope, elem) {

            scope.$watch(function(){ return scope.focus; }, function (value) {

                if (value === true) {

                    $timeout(function () {

                        elem[0].focus();

                    });
                }
            });
        }
    };
    return directive;
}