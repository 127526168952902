import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../reducers/sensors.reducer';

import CfApp from '../shared/containers/CfApp/CfApp';

import SensorsWrapper from './containers/SensorsWrapper/SensorsWrapper';

SensorsController.$inject = [
  '$element',
  '$scope',
  '$rootScope',
  '$transitions',
  'Localization',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Auth',
  'ResponseErrorInterceptor',
  'FarmsResource',
  '$state',
];

export default function SensorsController(
  $element,
  $scope,
  $rootScope,
  $transitions,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
  FarmsResource,
  $state,
) {
  const props = {
    farmId: this.farm.id,
    crs: this.farm.crs,
    farmBbox: this.farm.boundingBox,
    farm: this.farm,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    ngGoToNewNotification,
    cacheKey: 'locations',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  const deregisterOnTransitionSuccess = $transitions.onBefore({}, transition => {
    // hack because of behaviour of ui-router vs react-router
    // we need to block transition inside sensors - when we want to switch farm, it is superseded
    const sensorsState = 'farm.active.sensors';
    const transitionFrom = transition.from();
    const transitionTo = transition.to();
    const transitionParams = transition.params();
    if (
      transitionFrom.name.startsWith(sensorsState) &&
      transitionTo.name.startsWith(sensorsState) &&
      transitionParams.farmId === props.farmId
    ) {
      transition.abort();
    }
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);

      // the map should not rely on the farm bounding box from the angular router resolve, as it may become obsolete
      // when parcels are added/removed
      // TODO: bbox loading logic must be moved to the Map.service, this is already in the new map
      FarmsResource.getFarmBoundingBox(props.farmId)
        .then(bbox => {
          props.farmBbox = bbox;
        })
        .finally(() => {
          render();
        });
    });
  }

  function ngGoToNewNotification() {
    $state.go('farm.active.notifications.new');
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <SensorsWrapper {...p} />} />, $element[0]);
  }
}
