import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './monitoring.constants';

export const getCropIntervals = parcelId => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}/crop-intervals?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MONITORING_CROP_INTERVALS,
      types.GET_MONITORING_CROP_INTERVALS_SUCCESS,
      types.GET_MONITORING_CROP_INTERVALS_ERROR,
    ],
  },
});

export const resetCropIntervals = () => ({
  type: types.RESET_MONITORING_CROP_INTERVALS,
});

export const getMonitoringData = (parcelId, index, dateFrom, dateTo, status, perPage) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}?type=${index}&date-from=${dateFrom || ''}&date-to=${dateTo ||
      ''}&status=${status || ''}&per-page=${perPage || ''}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [types.GET_MONITORING_DATA, types.GET_MONITORING_DATA_SUCCESS, types.GET_MONITORING_DATA_ERROR],
  },
});

export const resetMonitoringData = () => ({
  type: types.RESET_MONITORING_DATA,
});
