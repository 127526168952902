import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './countries.constants';

export const getCountries = params => {
  const p = {
    search: params.search,
  };
  return {
    [RSAA]: {
      endpoint: `countries/supported?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_COUNTRIES, types.GET_COUNTRIES_SUCCESS, types.GET_COUNTRIES_FAILURE],
    },
  };
};

export const getCountryByIpAddress = () => ({
  [RSAA]: {
    endpoint: 'countries?',
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_COUNTRY,
      {
        type: types.GET_COUNTRY_SUCCESS,
        payload: (action, state, res) =>
          res.text().then((str) => ({ data: str })),
      },
      types.GET_COUNTRY_FAILURE,
    ],
  },
});

export const resetCountry = () => ({
  type: types.RESET_COUNTRY,
});

export const resetCountries = () => ({
  type: types.RESET_COUNTRIES,
});
