import React from 'react';

import NavigationIcon from '@mui/icons-material/Navigation';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfChartTooltipDaytime from '../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime';
import CfChartTooltipLabelWrapper from '../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipWrapper from '../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import SensorChartTooltipValueItem from '../SensorChartTooltipValueItem/SensorChartTooltipValueItem';

const style = () => ({
  tooltipValueWrapper: {
    padding: '2px 10px 2px 10px',
  },
  icon: {
    fontSize: 15,
    verticalAlign: 'text-bottom',
  },
});

const SensorChartTooltipWind = props => {
  const {
    active,
    classes,
    payload,
    granularity,
    features: [speed, gust, direction],
  } = props;
  if (active && payload && payload[0]) {
    const speedPayload = payload.find(item => item.name === speed.name);
    const gustPayload = payload.find(item => item.name === gust.name);
    const directionPayload = payload.find(item => item.name === direction.name);
    const dateTime = payload[0].payload?.dateTime || '';

    return (
      <CfChartTooltipWrapper>
        <CfChartTooltipLabelWrapper>
          <CfChartTooltipDaytime granularity={granularity} label={dateTime} />
        </CfChartTooltipLabelWrapper>

        <SensorChartTooltipValueItem
          feature={gust}
          payload={gustPayload}
          classes={{
            tooltipValueWrapper: classes.tooltipValueWrapper,
          }}
        />
        <SensorChartTooltipValueItem
          feature={speed}
          payload={speedPayload}
          classes={{
            tooltipValueWrapper: classes.tooltipValueWrapper,
          }}
        />
        <SensorChartTooltipValueItem
          feature={direction}
          payload={directionPayload}
          classes={{
            tooltipValueWrapper: classes.tooltipValueWrapper,
          }}
        >
          <FormattedMessage id={`NodeFeature.Wind${directionPayload.value}`} />{' '}
          <NavigationIcon
            className={classes.icon}
            style={{ color: direction.color }}
            transform={`rotate(${directionPayload.value * 45})`}
          />
        </SensorChartTooltipValueItem>
      </CfChartTooltipWrapper>
    );
  }
  return null;
};

SensorChartTooltipWind.propTypes = {
  classes: PropTypes.object.isRequired,
  granularity: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
};

SensorChartTooltipWind.defaultProps = {
  payload: [],
  active: false,
  classes: {},
};

export default withStyles(style)(SensorChartTooltipWind);
