import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MapIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M27.68.58l-.24,0-8,3.09L10.53.58,2.11,3.41a.75.75,0,0,0-.53.72V26.68a.74.74,0,0,0,.74.74l.24,0,8-3.09,8.94,3.13,8.42-2.83a.75.75,0,0,0,.53-.72V1.32A.74.74,0,0,0,27.68.58ZM19.47,24.52l-8.94-3.15V3.46l8.94,3.15Z" />
    </svg>
  </SvgIcon>
);

export default MapIcon;
