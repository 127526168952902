import React, { useEffect, useState, FC } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getApiError } from '../../../shared/api/irrigation/areas/areas.selectors';
import { getCreateOrEditAreaName,
  getCreateOrEditAreaErrorMessages,
  getCreateOrEditAreaFieldErrors,
  getCreateOrEditAreaDevicesToEdit,
  getCreateOrEditAreaSelectedDevices,
} from '../../selectors/createOrEditArea.selectors';

import { createArea, setAreaName, checkForm, resetForm } from '../../actions/createOrEditArea.actions';
import { setEnlargedVariant } from '../../actions/map.actions';

import { AREA_FORM_FIELDS } from '../../irrigation.constants';

import { resetApiError } from '../../../shared/api/irrigation/areas/areas.api';
import CfBackButton from '../../../shared/components/common/CfBackButton/CfBackButton';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import { Thunk } from '../../../types';
import ErrorSnackbar from '../../components/ErrorSnackbar/ErrorSnackbar';

import AvailableDevicesList from './components/AvailableDevicesList';
import useCreateAreaStyles from './styles/useCreateArea.styles';

import { IrrigationState } from '../../../reducers/irrigation.reducer.types';
import { FieldErrors, DeviceToEdit } from '../../reducer/createArea.reducer.types';

interface Props {
  apiError: boolean;
  areaName: string;
  checkForm: () => unknown;
  createArea: (redirectFn: (areaId: string) => void) => void;
  devicesToEdit: DeviceToEdit[];
  errorMessages: string[];
  fieldErrors: FieldErrors;
  resetApiError: () => void;
  resetForm: () => void;
  selected: string[];
  setAreaName: (name: string) => void;
  setEnlargedVariant: (bool: boolean) => void;
}

const CreateIrrigationArea: FC<Props> = ({
  apiError,
  areaName,
  checkForm,
  createArea,
  devicesToEdit,
  errorMessages,
  fieldErrors,
  resetApiError,
  resetForm,
  selected,
  setAreaName,
  setEnlargedVariant,
}) => {
  const classes = useCreateAreaStyles();
  const intl = useIntl();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const hasError = !!errorMessages.length;

  useEffect(() => () => {
    resetForm();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    setEnlargedVariant(true);
  }, [setEnlargedVariant]);

  useEffect(() => {
    if (apiError) {
      setSubmitting(false);
    }
  }, [apiError]);

  const handleNameChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setAreaName(e.currentTarget.value);
    if (hasError) {
      checkForm();
    }
  };

  const handleGoToAreasList = () => {
    history.push('../areas');
  };

  const handleGoToAreaDetail = (areaId: string) => {
    setSubmitting(false);
    history.push(`../area/${areaId}/plan`);
  };

  const handleSubmitData = () => {
    const isValid = checkForm() as boolean;

    if (!isValid) return;

    if (hasNoSelectedDevice) {
      setShowDialog(true);
      return;
    }
    handleCreateArea();
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleCreateArea = () => {
    handleCloseDialog();
    setSubmitting(true);
    createArea(handleGoToAreaDetail);
  };

  const hasNoSelectedDevice = !selected.length && devicesToEdit.length;

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <PageHeader
          backButton={<CfBackButton onClick={handleGoToAreasList} />}
          heading={<PageHeading value={<FormattedMessage id="Irrigation.createArea.heading" />} />}
        />
      </Box>
      <Box className={classes.areaName}>
        <TextField
          autoFocus
          defaultValue={areaName}
          error={!!fieldErrors[AREA_FORM_FIELDS.AREA_NAME]}
          id={AREA_FORM_FIELDS.AREA_NAME}
          label={<FormattedMessage id="Irrigation.createArea.form.name" />}
          onBlur={handleNameChange}
          placeholder={intl.formatMessage({ id: 'Irrigation.createArea.form.namePlaceholder' })}
          variant="standard"
          helperText={!!fieldErrors[AREA_FORM_FIELDS.AREA_NAME] &&
            intl.formatMessage({ id: fieldErrors[AREA_FORM_FIELDS.AREA_NAME] as string })}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <AvailableDevicesList />
      <Box className={classes.buttons}>
        <Button
          onClick={handleGoToAreasList}
          variant="contained"
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmitData}
          variant="contained"
        >
          {isSubmitting && <CircularProgress
            className={classes.buttonLoader}
            color="primary"
            size={20}
            thickness={8}
          />}
          <FormattedMessage id="Irrigation.createArea.form.createButton" />
        </Button>
      </Box>
      <CfDialog
        acceptText={<FormattedMessage id="Irrigation.createArea.dialog.accept" />}
        cancelText={<FormattedMessage id="Irrigation.createArea.dialog.cancel" />}
        onAccept={handleCreateArea}
        onCancel={handleCloseDialog}
        opened={showDialog}
        title={<FormattedMessage id="Irrigation.createArea.dialog.heading" />}
      >
        <FormattedMessage id="Irrigation.createArea.dialog.text" />
      </CfDialog>
      <ErrorSnackbar
        handleClose={resetApiError}
        open={apiError}
      />
    </Box>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  areaName: getCreateOrEditAreaName(state),
  errorMessages: getCreateOrEditAreaErrorMessages(state),
  fieldErrors: getCreateOrEditAreaFieldErrors(state),
  selected: getCreateOrEditAreaSelectedDevices(state),
  devicesToEdit: getCreateOrEditAreaDevicesToEdit(state),
  apiError: getApiError(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) => bindActionCreators({
  setEnlargedVariant,
  setAreaName,
  createArea,
  checkForm,
  resetForm,
  resetApiError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateIrrigationArea);
