import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfSelector from '../../../../../shared/components/form/CfSelector/CfSelector';

export function SensorRainfallSelector(props) {
  const { onSelect, types, val } = props;
  return (
    <CfSelector
      idPropertyName="name"
      items={types}
      label={<FormattedMessage id="SensorsAdmin.rainfallType" />}
      name="rainfall-type"
      input={props.input.onChange ? props.input : {
        value: types.find(type => type.value === val) || '',
        onChange: (type) => { onSelect(type.value); },
      }}
    />
  );
}

SensorRainfallSelector.propTypes = {
  input: PropTypes.any,
  val: PropTypes.string,
  onSelect: PropTypes.func,
  types: PropTypes.array,
};

SensorRainfallSelector.defaultProps = {
  input: {},
  val: '',
  onSelect: () => {},
  types: [
    { name: 'pronamic', value: 'pronamic' },
    { name: <FormattedMessage id="SensorsAdmin.rainfallTypeChina" />, value: 'default' }],
};

export default SensorRainfallSelector;
