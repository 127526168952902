import angular from 'angular';

import LoginController from './login.controller';

// eslint-disable-next-line import/prefer-default-export
const loginComponent = {
  bindings: {},
  controller: LoginController,
};

export default angular
  .module('app.core.login', [])
  .config(config)
  .component('loginComponent', loginComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('login', {
    url: '/login',
    views: {
      app: {
        component: 'loginComponent',
      },
    },
    params: {
      isApiError: false,
    },
  });
}
