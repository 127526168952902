import moment from 'moment';

import * as sensorsStatus from '../constants/sensorsStatus.constants';

import Logger from './Logger.service';

const HOURS_UPDATE_WARNING_LIMIT = 12;

export default class SensorsService {
  static hasZeroCoordinates(nodeLocation) {
    return nodeLocation.geometry
      ? nodeLocation.geometry.coordinates[0] === 0 && nodeLocation.geometry.coordinates[1] === 0
      : true;
  }

  static getNodesStates() {
    return [
      sensorsStatus.ACTIVE,
      sensorsStatus.INACTIVE,
      sensorsStatus.ERROR,
      sensorsStatus.TRANSFER,
      sensorsStatus.UNKNOWN,
    ];
  }

  static getValveNumber(valve) {
    return valve.replace('Valve ', '');
  }

  static isActive(nodeLocation) {
    return nodeLocation.status === sensorsStatus.ACTIVE;
  }

  static isInactive(nodeLocation) {
    return nodeLocation.status === sensorsStatus.INACTIVE;
  }

  static isHistoric(nodeLocation) {
    return !!nodeLocation.to;
  }

  static isError(nodeLocation) {
    return nodeLocation.status === sensorsStatus.ERROR;
  }

  static isTransfer(nodeLocation) {
    return nodeLocation.status === sensorsStatus.TRANSFER;
  }

  static isUnknown(nodeLocation) {
    return nodeLocation.status === sensorsStatus.UNKNOWN;
  }

  static isErrorOrTransfer(nodeLocation) {
    return this.isError(nodeLocation) || this.isTransfer(nodeLocation);
  }

  static isCurrentlyActiveWithCoordinates(nodeLocation) {
    return this.isActive(nodeLocation) && !this.hasZeroCoordinates(nodeLocation) && !this.isHistoric(nodeLocation);
  }

  static isCurrentlyActiveWithValidSignal(nodeLocation) {
    const { signal } = nodeLocation;
    return (
      this.isActive(nodeLocation) && !this.isHistoric(nodeLocation) && isFinite(signal) && signal >= 0 && signal <= 5
    );
  }

  static isMeteoOrField(node) {
    return node && (node.deviceType.startsWith('FIELD') || node.deviceType.startsWith('METEO'));
  }

  static isTooLongWithoutUpdate(lastUpdate) {
    const lastUpdateMoment = moment(lastUpdate);
    return moment().diff(lastUpdateMoment, 'hours') > HOURS_UPDATE_WARNING_LIMIT;
  }

  static isCorrectNode(node) {
    let pass = true;
    if (!node.config) {
      pass = false;
      Logger.warn(`No config assigned. Skipping node with ID ${node.id}`);
    }

    if (
      !node.config.charts.every(chart =>
        chart.features.every(feature => {
          if (feature.sensors.length < 1) {
            Logger.warn(`No sensors assigned to the feature - data glitch. Skipping node with ID ${node.id}`);
            return false;
          }

          return true;
        }),
      )
    ) {
      pass = false;
    }

    return pass;
  }

  static getLocationStatusColor(nodeLocation) {
    const isHistoric = this.isHistoric(nodeLocation);
    if (isHistoric) {
      return '#5E15A7';
    }
    switch (nodeLocation.status) {
      case sensorsStatus.ACTIVE:
        return '#7ED321';
      case sensorsStatus.ERROR:
        return '#DB4437';
      case sensorsStatus.TRANSFER:
        return '#EBA607';
      default:
        return '#ABABAB';
    }
  }
}
