import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './groups.constants';

export const getGroups = params => {
  const p = {
    'sort-col': params.orderBy,
    'sort-dir': params.order,
  };

  return {
    [RSAA]: {
      endpoint: `groups?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [types.GET_GROUPS, types.GET_GROUPS_SUCCESS, types.GET_GROUPS_ERROR],
    },
  };
};

export const resetGroups = () => ({
  type: types.RESET_GROUPS,
});

export const updateGroup = groupTo => {
  const groupId = groupTo.id;
  delete groupTo.id;
  return {
    [RSAA]: {
      endpoint: `groups/${groupId}?`,
      method: methods.PATCH,
      module: modules.IOT,
      body: JSON.stringify(groupTo),
      types: [types.UPDATE_GROUP, types.UPDATE_GROUP_SUCCESS, types.UPDATE_GROUP_ERROR],
    },
  };
};

export const deleteGroup = group => ({
  [RSAA]: {
    endpoint: `groups/${group.id}?`,
    method: methods.DELETE,
    module: modules.IOT,
    types: [types.DELETE_GROUP, types.DELETE_GROUP_SUCCESS, types.DELETE_GROUP_ERROR],
  },
});

export const createGroup = groupDto => ({
  [RSAA]: {
    endpoint: 'groups?',
    method: methods.POST,
    body: JSON.stringify(groupDto),
    module: modules.IOT,
    types: [types.POST_GROUP, types.POST_GROUP_SUCCESS, types.POST_GROUP_ERROR],
  },
});
