import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getManagementZonesIsFetching,
  getManagementZonesError as getError,
  getManagementZonesIsFetchingActivate,
  getManagementZonesActivated,
  getManagementZonesErrorActivating,
} from '../../../../../../shared/api/sentinel/management/management.selectors';
import {
  getCropQuality,
  getCrops,
  getCropVariability,
  getCropZones,
  getHistorySnaps,
  getManagementZonesStatus,
} from '../../selectors/management.selectors';

import { setCropLegislativeCode } from '../../actions/management.actions';

import * as satelliteProductsTypes from '../../../../../../shared/constants/satelliteProductsTypes.constants';
import * as services from '../../../../../../shared/constants/services.constants';

import {
  getManagementZones,
  resetManagementZones,
  activateManagementZones,
} from '../../../../../../shared/api/sentinel/management/management.api';
import CfErrorPage from '../../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import CfStatusPanel from '../../../../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import CfSwitcher from '../../../../../../shared/components/common/CfSwitcher/CfSwitcher';
import ServiceNotPurchased from '../../../../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased';
import { links } from '../../../../../../shared/constants/links';
import withWidth from '../../../../../../shared/hocs/withWidth';
import SatelliteIcon from '../../../../../../shared/icons/SatelliteIcon';
import CropQualityGraph from '../../components/CropQualityGraph/CropQualityGraph';
import CropVariabilityGraph from '../../components/CropVariabilityGraph/CropVariabilityGraph';
import ManagementHistoryDialog from '../../components/ManagementHistoryDialog/ManagementHistoryDialog';
import ManagementHistoryImage from '../../components/ManagementHistoryImage/ManagementHistoryImage';
import ManagementZonesMap from '../../components/ManagementZonesMap/ManagementZonesMap';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

const styles = theme => {
  const { breakpoints } = theme;
  return {
    heading: {
      fontSize: '18px',
      margin: 0,
    },
    wrapper: {
      marginBottom: '10px',
    },
    headerBar: {
      marginBottom: 5,
    },
    cfSwitcherWrapper: {
      justifyContent: 'flex-start',
    },
    [breakpoints.down('xs')]: {
      heading: {
        textAlign: 'center',
        marginBottom: 10,
      },
    },
  };
};

export class ManagementZones extends Component {
  componentDidMount() {
    const { crops, managementZonesStatus, parcelId } = this.props;
    if (crops && crops.length) {
      this.props.setCropLegislativeCode(this.props.crops[0]);
    }

    if (managementZonesStatus === satelliteProductsTypes.ACTIVE) {
      this.props.getManagementZones(parcelId);
    }
  }

  componentDidUpdate(prevProps) {
    const { activated, crops, langId, managementZonesStatus, parcelId } = this.props;

    if (parcelId !== prevProps.parcelId) {
      this.props.resetManagementZones();
    }

    if (managementZonesStatus === satelliteProductsTypes.ACTIVE && !prevProps.managementZonesStatus) {
      this.props.getManagementZones(parcelId);
    }

    if (crops && crops.length && !prevProps.crops.length) {
      this.props.setCropLegislativeCode(crops[0]);
    }

    if (langId && langId !== prevProps.langId && parcelId) {
      this.getManagementZones(parcelId);
    }

    if (activated && !prevProps.activated) {
      this.getManagementZones(parcelId);
    }
  }

  getManagementZones = parcelId => {
    this.props.getManagementZones(parcelId);
  };

  render() {
    const {
      activated,
      classes,
      cropQuality,
      cropVariability,
      cropZones,
      crops,
      displayedMap,
      error,
      errorActivating,
      geometries,
      historySnaps,
      isFetching,
      isFetchingActivate,
      langId,
      managementZonesStatus,
      parcelId,
      width,
    } = this.props;
    return (
      <CfErrorPage error={error}>
        {(managementZonesStatus === satelliteProductsTypes.INACTIVE ||
          managementZonesStatus === satelliteProductsTypes.HISTORICAL) && (
          <CfStatusPanel
            customContent={<ServiceNotPurchased serviceId={services.MANAGEMENT_ZONES} />}
            icon={SatelliteIcon}
            linkHref={langId === 'cs-CZ' ? links.cs.precisionFarming : links.en.precisionFarming}
            linkText={<FormattedMessage id="common.findOutMore" />}
            title={<FormattedMessage id="ManagementZones.notPurchased.title" />}
            titleWithIcon={true}
          />
        )}
        {managementZonesStatus === satelliteProductsTypes.AWAITING_USER && (
          <CfStatusPanel
            activated={activated}
            errorText={errorActivating.isError ? <FormattedMessage id="ManagementZones.cropsHistoryMissing" /> : null}
            icon={SatelliteIcon}
            isFetching={isFetchingActivate}
            linkText={<FormattedMessage id="ManagementZones.activateManagementZones" />}
            onLinkClick={() => this.props.activateManagementZones(parcelId)}
            title={<FormattedMessage id="ManagementZones.available" />}
            content={
              <FormattedMessage
                id="ManagementZones.availableExplanation"
                values={{
                  b: chunks => <b>{chunks}</b>,
                }}
              />
            }
          />
        )}
        {managementZonesStatus === satelliteProductsTypes.AWAITING_DATA && (
          <CfStatusPanel
            content={<FormattedMessage id="ManagementZones.triggeredExplanation" />}
            icon={SatelliteIcon}
            title={<FormattedMessage id="ManagementZones.triggered" />}
          />
        )}
        {managementZonesStatus === satelliteProductsTypes.ACTIVE && (
          <Fragment>
            {isFetching ? (
              <CfLoader />
            ) : (
              <Grid className={classes.wrapper} container spacing={1}>
                <Grid className={classes.headerBar} item xs={12}>
                  <Grid alignItems="center" container justifyContent={'flex-start'}>
                    <Grid item xs={12}>
                      <CfSwitcher
                        getItemId={item => item.legislativeCode}
                        getItemValue={item => item.name}
                        items={crops}
                        onMenuItemClick={this.props.setCropLegislativeCode}
                        classes={{
                          wrapper: classes.cfSwitcherWrapper,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid data-test="generalized-zones" item lg={4} md={displayedMap ? 7 : 3} sm={6} xs={12}>
                  <Paper>
                    <SectionHeader
                      headingTranslationId={'ManagementZones.GeneralizedZones.heading'}
                      hintTranslationId={'ManagementZones.GeneralizedZones.hint'}
                    />
                    <ManagementZonesMap
                      displayedMap={displayedMap}
                      geometries={geometries}
                      zones={cropZones}
                      onMapClick={z => {
                        this.props.onZonesClick(z);
                      }}
                    />
                  </Paper>
                </Grid>

                <Grid data-test="crop-quality" item lg={2} md={displayedMap ? 5 : 3} sm={6} xs={12}>
                  <Paper>
                    <SectionHeader
                      headingTranslationId={'ManagementZones.CropQuality.heading'}
                      hintTranslationId={'ManagementZones.CropQuality.hint'}
                    />
                    <CropQualityGraph data={cropQuality} isFetching={isFetching} />
                  </Paper>
                </Grid>

                <Grid data-test="crop-variability" item lg={6} md={displayedMap ? 12 : 6} sm={12} xs={12}>
                  <Paper>
                    <SectionHeader
                      headingTranslationId={'ManagementZones.CropVariability.heading'}
                      hintTranslationId={'ManagementZones.CropVariability.hint'}
                    />
                    <CropVariabilityGraph data={cropVariability} isFetching={isFetching} />
                  </Paper>
                </Grid>

                <Grid data-test="history" item xs={12}>
                  <Paper style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <SectionHeader
                      headingTranslationId={'common.history'}
                      infoDialogContent={<ManagementHistoryDialog item={head(historySnaps)} />}
                      maxDialogWidth={'md'}
                    />
                    {historySnaps.map(item => (
                      <ManagementHistoryImage item={item} key={item.date} width={width} zones={cropZones} />
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </CfErrorPage>
    );
  }
}

ManagementZones.propTypes = {
  width: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  crops: PropTypes.array.isRequired,
  parcelId: PropTypes.string.isRequired,
  setCropLegislativeCode: PropTypes.func.isRequired,
  getManagementZones: PropTypes.func.isRequired,
  resetManagementZones: PropTypes.func.isRequired,
  historySnaps: PropTypes.array.isRequired,
  cropQuality: PropTypes.object.isRequired,
  cropVariability: PropTypes.array.isRequired,
  cropZones: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  langId: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  activateManagementZones: PropTypes.func.isRequired,
  isFetchingActivate: PropTypes.bool.isRequired,
  activated: PropTypes.bool.isRequired,
  errorActivating: PropTypes.object.isRequired,
  displayedMap: PropTypes.bool.isRequired,
  onZonesClick: PropTypes.func.isRequired,
  geometries: PropTypes.array,
  managementZonesStatus: PropTypes.string,
};

ManagementZones.defaultProps = {
  isManagementZones: false,
  isWaitingForUser: false,
  geometries: null,
  managementZonesStatus: undefined,
};

const mapStateToProps = state => ({
  crops: getCrops(state),
  historySnaps: getHistorySnaps(state),
  cropQuality: getCropQuality(state),
  cropVariability: getCropVariability(state),
  cropZones: getCropZones(state),
  isFetching: getManagementZonesIsFetching(state),
  error: getError(state),
  isFetchingActivate: getManagementZonesIsFetchingActivate(state),
  activated: getManagementZonesActivated(state),
  errorActivating: getManagementZonesErrorActivating(state),
  managementZonesStatus: getManagementZonesStatus(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCropLegislativeCode,
      getManagementZones,
      resetManagementZones,
      activateManagementZones,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(ManagementZones)));
