import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CHART_TYPE_PREFIX } from '../../services/DeviceConfig.service';

const styles = () => ({
  sensorHeading: {
    fontWeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  sensorHeadingLevel: {
    fontSize: 13,
    borderRadius: 3,
    '-webkit-border-radius': 3,
    '-moz-border-radius': 3,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-moz-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    marginLeft: 5,
    padding: '0px 4px',
    display: 'inline-block',
  },
  icon: {
    fontSize: 20,
    verticalAlign: 'middle',
  },
  sensorHeadingName: {
    fontSize: 13,
  },
});

const SensorChartHeading = (props) => {
  const { chartProps: { InfoDialog, icon: FeatureIcon, name, stroke, unit }, classes, level, testId } = props;
  const nameHeading = (
    <span data-test={testId}>
      <FormattedMessage
        id={`NodeFeature.${name.replace(CHART_TYPE_PREFIX, '')}`}
        values={{ number: '' }}
      /> {unit ? `(${unit})` : ''}
    </span>
  );
  return (
    <div className={classes.sensorHeading} style={{ color: stroke }}>
      <FeatureIcon className={classes.icon} height={20} htmlColor={stroke} width={20} />
      &nbsp;
      <span className={classes.sensorHeadingName}>
        {nameHeading}
        {level && <span className={classes.sensorHeadingLevel}> {`${level} cm`}</span>}
      </span>
      {InfoDialog && <InfoDialog heading={nameHeading} />}
    </div>
  );
};

SensorChartHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  chartProps: PropTypes.object.isRequired,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  testId: PropTypes.string,
};

SensorChartHeading.defaultProps = {
  level: null,
  testId: 'heading',
};

export default withStyles(styles)(SensorChartHeading);
