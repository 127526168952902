import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import parcelActivitiesUI from '../core/parcels/detail/activities/reducer/parcelActivities.reducer';
import managementUI from '../core/parcels/detail/management/reducers/management.reducer';
import monitoringUI from '../core/parcels/detail/monitoring/reducers/monitoring.reducer';
import parcelDetailUI from '../core/parcels/detail/shared/reducer/parcelDetail.reducer';
import parcelListUI from '../core/parcels/list/reducer/parcelList.reducer';
import actionsApi from '../shared/api/core/actions/actions.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import parcelsApi from '../shared/api/core/parcels/parcels.reducer';
import zonesApi from '../shared/api/core/zones/zones.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import managementApi from '../shared/api/sentinel/management/management.reducer';
import monitoringApi from '../shared/api/sentinel/monitoring/monitoring.reducer';
import satelliteProductsApi from '../shared/api/sentinel/satelliteProducts/satelliteProducts.reducer';
import variableApplicationApi from '../shared/api/sentinel/variableApplication/variableApplication.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      parcelList: parcelListUI(),
      statistics: combineReducers({
        zones: zonesApi('statistics'),
      }),
      parcelDetail: parcelDetailUI(),
      parcelActivities: parcelActivitiesUI(),
      monitoring: monitoringUI(),
      management: managementUI(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
    }),
    api: combineReducers({
      parcels: parcelsApi(),
      zones: zonesApi(),
      monitoring: monitoringApi(),
      management: managementApi(),
      satelliteProducts: satelliteProductsApi(),
      geometry: geometryApi(),
      layers: layersApi(),
      actions: actionsApi(),
      variableApplication: variableApplicationApi(),
    }),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
