import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getLocationsTextFilter, getLocationsHistoryFilter } from '../../selectors/locations.selectors';
import { getNodesStatistics } from '../../selectors/nodes.selectors';

import { setHistoryFilter } from '../../actions/locations.actions';

import { NAMESPACE as namespace } from '../../reducer/locations.reducer';

import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import withWidth from '../../../shared/hocs/withWidth';
import NodeLocationsHistoryFilter from '../../components/NodeLocationsHistoryFilter/NodeLocationsHistoryFilter';
import NodeLocationsAdvancedFilter from '../NodeLocationsAdvancedFilter/NodeLocationsAdvancedFilter';

const styles = theme => ({
  filtersWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  filterItem: {
    marginRight: 24,
    marginBottom: 8,
  },
  textFilterItem: {
    width: '100%',
  },
  filtersGroup: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 450,
    },
  },
});

export function NodeLocationsListFilter(props) {
  const { classes, historyFilter, isMapDisplayed, langId, statistics, textFilter, width } = props;
  const wrapperFlexDirection =
    width === 'xs' || width === 'sm' || (width === 'md' && isMapDisplayed) ? 'column' : 'row';

  return (
    <div className={classes.filtersWrapper} style={{ flexDirection: wrapperFlexDirection }}>
      <div className={classes.filtersGroup}>
        <span className={`${classes.filterItem} ${classes.textFilterItem}`}>
          <CfTextFilter
            initialValue={textFilter}
            name="node-locations-text-filter"
            namespace={namespace}
            translId="NodeLocationsListFilter.sensorNameOrID"
          />
        </span>
        <span className={classes.filterItem}>
          <NodeLocationsAdvancedFilter isHistoryActive={historyFilter.historic} langId={langId} namespace={namespace} />
        </span>
      </div>
      <div>
        <NodeLocationsHistoryFilter
          historyFilter={historyFilter}
          setLocationsFilter={props.setLocationsFilter}
          statistics={statistics}
        />
      </div>
    </div>
  );
}

NodeLocationsListFilter.propTypes = {
  classes: PropTypes.object,
  statistics: PropTypes.object.isRequired,
  historyFilter: PropTypes.object.isRequired,
  textFilter: PropTypes.string.isRequired,
  setLocationsFilter: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  isMapDisplayed: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
};

NodeLocationsListFilter.defaultProps = {
  classes: {},
  isMapDisplayed: true,
};

const mapStateToProps = state => ({
  historyFilter: getLocationsHistoryFilter(state),
  textFilter: getLocationsTextFilter(state),
  statistics: getNodesStatistics(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLocationsFilter: setHistoryFilter,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withStyles(styles), withWidth())(NodeLocationsListFilter));
