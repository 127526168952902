import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { editorSetHoveredId } from '../../../core/map/actions/editor/editor.actions';

import { IRRIGATION_URLS } from '../../irrigation.constants';

import AddToIrrigationArea from '../Areas/AddToArea';
import CreateArea from '../Areas/CreateArea';
import IrrigationAreaDetail from '../IrrigationAreaDetail/IrrigationAreaDetail';

const IrrigationAreaRoutes = ({ editorSetHoveredId, langId }) => {
  useEffect(() => {
    editorSetHoveredId(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Switch>
        <Route
          path={`/farm/:farmId/${IRRIGATION_URLS.CREATE_AREA}`}
          render={routerProps => (
            <CreateArea {...routerProps} />
          )}
        />
        <Route
          path={`/farm/:farmId/${IRRIGATION_URLS.ADD_TO_AREA}`}
          render={routerProps => (
            <AddToIrrigationArea {...routerProps} />
          )}
        />
        <Route
          path={`/farm/:farmId/${IRRIGATION_URLS.AREA}/:areaId/plan`}
          render={routerProps => (
            <IrrigationAreaDetail {...routerProps} langId={langId} tabPlan />
          )}
        />
        <Route
          path={`/farm/:farmId/${IRRIGATION_URLS.AREA}/:areaId/devices`}
          render={routerProps => (
            <IrrigationAreaDetail {...routerProps} langId={langId} tabDevices />
          )}
        />
      </Switch>
    </Fragment>
  );
};

IrrigationAreaRoutes.propTypes = {
  langId: PropTypes.string.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editorSetHoveredId,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(IrrigationAreaRoutes);
