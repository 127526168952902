import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { getItems } from '../../shared/api/telematics/drives/drives.selectors';

import { TelematicsState } from '../../reducers/telematics.reducer.types';
import { LogbookItemTo } from '../../shared/api/telematics/telematics.types';

export const getTelematicsListPage = (state: TelematicsState) => state.ui.telematicsList.page;
export const getTelematicsListOrder = (state: TelematicsState) => state.ui.telematicsList.order;
export const getTelematicsListOrderBy = (state: TelematicsState) => state.ui.telematicsList.orderBy;
export const getTelematicsListRowsPerPage = (state: TelematicsState) => state.ui.telematicsList.rowsPerPage;
export const getTelematicsListSelected = (state: TelematicsState) => state.ui.telematicsList.selected;

export const getTelematicsListTextFilter = (state: TelematicsState) => state.ui.telematicsList.textFilter;
export const getTelematicsListAdvancedFilter = (state: TelematicsState) => state.ui.telematicsList.advancedFilter;

export const getTelematicsListDateFilter = (state: TelematicsState) => state.ui.telematicsList.dateFilter;
export const getTelematicsListDuration = (state: TelematicsState) => state.ui.telematicsList.dateFilter.duration;
export const getTelematicsListDateFrom = (state: TelematicsState) => state.ui.telematicsList.dateFilter.dateFrom;
export const getTelematicsListDateTo = (state: TelematicsState) => state.ui.telematicsList.dateFilter.dateTo;

export const getTelematicsListDateLastUpdate = (state: TelematicsState) =>
  state.ui.telematicsList.dateFilter.lastUpdate;

export const getDrivesListItems = (state: TelematicsState) => getItems(state);

export const getTelematicsListSelectedOnPage = createSelector(
  getDrivesListItems,
  getTelematicsListSelected,
  (drives: LogbookItemTo[], selected: string[]) =>
    intersection(
      drives.map((drive: LogbookItemTo) => drive.id),
      selected,
    ),
);
