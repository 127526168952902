import { getSize as getExtentSize } from 'ol/extent';

export const MAP_SRID_ID = '3857';
export const DATA_SRID_ID = '4326';
export const transformOptions = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};
export const TELEMATICS_GEOMETRY_COLOR = '#FFE307';
export const TELEMATICS_GEOMETRY_PER_DAY_COLOR = '#FFE3074d';
export const TELEMATICS_GEOMETRY_WIDTH = 3;

export const getZoomedExtent = (extent, zoomFactor) => {
  if (zoomFactor) {
    const extentSize = getExtentSize(extent);
    const extraWidth = extentSize[0] / zoomFactor - extentSize[0];
    const extraHeight = extentSize[1] / zoomFactor - extentSize[1];
    const newExtent = [
      extent[0] - extraWidth / 2,
      extent[1] - extraHeight / 2,
      extent[2] + extraWidth / 2,
      extent[3] + extraHeight / 2,
    ];
    return newExtent;
  }
  return extent;
};
