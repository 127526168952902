import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { createComponent, mapError } from '../../reduxFormUtils';

const styles = theme => ({
  formControlLabel: {
    color: theme.palette.grey[900],
  },
});

export const CfRadioGroup = props => {
  const { RadioProps, classes, labelPropertyFactory, onChange, options, value } = props;

  return (
    <RadioGroup data-test="strategy" name={name} onChange={onChange} row value={value}>
      {options.map(opt => (
        <FormControlLabel
          control={<Radio color="primary" {...RadioProps} />}
          key={opt.value}
          label={labelPropertyFactory(opt)}
          value={opt.value}
          classes={{
            root: classes.formControlLabel,
            label: classes.formControlLabelText,
          }}
        />
      ))}
    </RadioGroup>
  );
};

CfRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  labelPropertyFactory: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  RadioProps: PropTypes.object,
};

CfRadioGroup.defaultProps = {
  RadioProps: {},
};

export default withStyles(styles)(
  createComponent(
    CfRadioGroup,
    ({
      input,
      input: { onBlur, onChange, value, ...restInputProps },
      onChange: onChangeFromField,
      labelPropertyFactory = option => option.name,
      ...restProps
    }) =>
      mapError({
        ...restProps,
        ...restInputProps,
        value,
        labelPropertyFactory,
        onChange: evt => {
          onChange(evt.target.value);
          if (onChangeFromField) {
            onChangeFromField(evt.target.value);
          }
        },
      }),
  ),
);
