import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SupportIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M19.91,6.73H18.15v7.91H6.73v1.75a.89.89,0,0,0,.88.88h9.66l3.51,3.51V7.61A.88.88,0,0,0,19.91,6.73ZM16.39,12V4.1a.87.87,0,0,0-.88-.88H4.09a.87.87,0,0,0-.87.88V16.39l3.51-3.51h8.78A.88.88,0,0,0,16.39,12Z" />
    </svg>
  </SvgIcon>
);

export default SupportIcon;
