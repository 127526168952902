import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../reducers/telematics.reducer';

import CfApp from '../shared/containers/CfApp/CfApp';

import TelematicsWrapper from './containers/TelematicsWrapper/TelematicsWrapper';

TelematicsController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  '$transitions',
  'Localization',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function TelematicsController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    ngGoToMainMap,
    farmId: this.farmId,
    farm: this.farm,
    initDateFrom: this.dateFrom,
    initDateTo: this.dateTo,
    initMachineFilter: this.machine,
    reducer,
    ngRedirectToMainMapWithFilters,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: 'telematicsList',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    // TODO @CFD-53 in case of problems with routing consider using deregisterOnTransitionSuccess
    // method from other section controllers
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngGoToMainMap(farmId, parcelId) {
    $state.go('farm.active.map', { farmId, parcelId });
  }

  function ngRedirectToMainMapWithFilters(dateFrom, dateTo, machine, driver) {
    $state.go('farm.active.map', { dateFrom, dateTo, machine, driver });
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <TelematicsWrapper {...p} />} />, $element[0]);
  }
}
