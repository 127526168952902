import React, { Component } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Fab, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getDevices,
  getIsFetchingDevices,
  getDevicesTotalCount,
} from '../../../../shared/api/irrigation/devices/devices.selectors';
import { getDevicesListTextFilter } from '../../../selectors/devicesList.selectors';
import { getMapExtent } from '../../../selectors/map.selectors';

import { editorSetHoveredId, editorSetSelected } from '../../../../core/map/actions/editor/editor.actions';
import { zoomToExtent } from '../../../../core/map/actions/map/map.actions';
import { fetchDevices, fetchIrrigationPoints } from '../../../actions/devices.actions';
import { setEnlargedVariant } from '../../../actions/map.actions';

import { NAMESPACE } from '../../../reducer/devicesList.reducer';

import CfTextFilter from '../../../../shared/containers/CfTextFilter/CfTextFilter';
import { setActivePage, setPrevPage } from '../../../actions/commonActions';
import IrrigationDevicesTable from '../../../components/IrrigationDevicesTable/IrrigationDevicesTable';

export const MAP_ZOOM_SCALE_FACTOR = 0.75;
const styles = theme => ({
  container: {
    margin: 15,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
  },
  textFilter: {
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  fab: {
    height: '28px',
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  fabMobile: {
    height: '28px',
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.up(700)]: {
      display: 'none',
    },
  },
});

export class IrrigationDevicesList extends Component {
  constructor(props) {
    super(props);
    this.path = window.location.pathname;
    props.setEnlargedVariant(false);
  }

  componentDidMount() {
    const { mapExtent } = this.props;
    this.props.fetchDevices(NAMESPACE);
    this.props.editorSetHoveredId(null);
    this.props.editorSetSelected([]);
    this.props.setActivePage(this.path);
    if (mapExtent.length > 0) {
      this.props.zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR);
    }
  }

  componentDidUpdate(prevProps) {
    const { mapExtent } = this.props;
    const oldPropsToRefreshWithPoint = pick(prevProps, ['textFilter']);
    const newPropsToRefreshWithPoint = pick(this.props, ['textFilter']);

    if (!isEqual(newPropsToRefreshWithPoint, oldPropsToRefreshWithPoint)) {
      this.props.fetchDevices(NAMESPACE);
      this.props.fetchIrrigationPoints(NAMESPACE);
    }

    if (mapExtent.length && !isEqual(mapExtent, prevProps.mapExtent)) {
      this.props.zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR);
    }
  }

  componentWillUnmount() {
    this.props.setPrevPage(this.path);
  }

  reloadDevices= () => {
    this.props.fetchDevices(NAMESPACE);
  };

  render() {
    const { classes, devices, editorSetHoveredId, isFetching, textFilter, totalCount } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="irrigation-devices-text-filter"
              namespace={NAMESPACE}
              translId="IrrigationList.deviceNameOrID"
            />
          </div>
          <div className={classes.actionButtons}>
            <Fab
              className={classes.fab}
              onClick={this.reloadDevices}
              size="small"
              variant="extended"
            >
              <RefreshIcon sx={{ mr: 1 }} />
              <FormattedMessage id="Irrigation.reloadValvesStatus" />
            </Fab>
            <Fab
              className={classes.fabMobile}
              onClick={this.reloadDevices}
              size="small"
            >
              <RefreshIcon />
            </Fab>
          </div>
        </div>
        <Paper
          elevation={3}
          style={{ marginRight: 10 }}
        >
          <IrrigationDevicesTable
            devices={devices}
            handleContentRefresh={this.reloadDevices}
            handleHover={editorSetHoveredId}
            isFetching={isFetching}
            namespace={NAMESPACE}
            totalCount={totalCount}
        />
        </Paper>
      </div>
    );
  }
}

IrrigationDevicesList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchDevices: PropTypes.func.isRequired,
  fetchIrrigationPoints: PropTypes.func.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
  devices: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  textFilter: PropTypes.string.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  mapExtent: PropTypes.array.isRequired,
  zoomToExtent: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setPrevPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  devices: getDevices(state),
  isFetching: getIsFetchingDevices(state),
  totalCount: getDevicesTotalCount(state),
  mapExtent: getMapExtent(state),
  textFilter: getDevicesListTextFilter(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDevices,
      fetchIrrigationPoints,
      editorSetHoveredId,
      editorSetSelected,
      zoomToExtent,
      setEnlargedVariant,
      setActivePage,
      setPrevPage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IrrigationDevicesList));
