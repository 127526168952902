import React, { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import legend from '../../../../../../assets/img/satellite/hist-potential-legend.png';
import CfLazyImage from '../../../../../../shared/components/common/CfLazyImage/CfLazyImage';
import SatelliteImagesInfoDialog from '../../../shared/components/SatelliteImagesInfoDialog/SatelliteImagesInfoDialog';
import SatelliteInfoDialogItem from '../../../shared/components/SatelliteInfoDialogItem/SatelliteInfoDialogItem';

const styles = theme => ({
  image: {
    minWidth: '150px',
    maxWidth: '150px',
    height: 'auto',
    imageRendering: 'pixelated',
    '&': {
      // fallback for FF
      imageRendering: '-moz-crisp-edges',
    },
    marginRight: 25,
  },
  itemLegendWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  legend: {
    margin: '0 5px',
  },
  legendInfo: {
    color: theme.palette.grey[500],
    marginTop: 10,
  },
  [theme.breakpoints.down('sm')]: {
    image: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
});

export const ManagementHistoryDialog = props => {
  const { classes, item } = props;

  return (
    <SatelliteImagesInfoDialog>
      <Fragment>
        <SatelliteInfoDialogItem label={<FormattedMessage id="ManagementZones.History.satellite" />}>
          <CfLazyImage
            alt={`History Snap from ${item.date}`}
            classes={{ custom: classes.image }}
            src={item.managementZoneImgPath}
            testId="satellite"
          />
        </SatelliteInfoDialogItem>
        <SatelliteInfoDialogItem label={<FormattedMessage id="ManagementZones.History.rel-involvement" />}>
          <Fragment>
            <CfLazyImage
              alt={`History Snap from ${item.date}`}
              classes={{ custom: classes.image }}
              src={item.involvementImgPath}
              testId="involvement"
            />
            <div className={classes.itemLegendWrapper}>
              <div className={classes.legendWrapper}>
                <span style={{ color: '#8F3D2A' }}>
                  <FormattedMessage id="common.low" />
                </span>
                <img alt="legend" className={classes.legend} src={legend} />{' '}
                <span style={{ color: '#438B00' }}>
                  <FormattedMessage id="common.high" />
                </span>
              </div>
              <div className={classes.legendInfo}>
                <Typography>
                  <FormattedMessage id={'ManagementZones.History.dialogLegend'} />
                </Typography>
              </div>
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
      </Fragment>
    </SatelliteImagesInfoDialog>
  );
};

ManagementHistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
};

ManagementHistoryDialog.defaultProps = {
  item: {
    managementZoneImgPath: 'foo',
    involvementImgPath: 'foo',
  },
};

export default withStyles(styles)(ManagementHistoryDialog);
