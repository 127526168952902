/**
 * Created by ondrejzvara on 21.4.16.
 */

import angular from 'angular';
import Auth from './Auth.service';
import Intercom from '../../../shared/services/Intercom.service';

export default angular
  .module('app.auth', [])
  .run(run)
  .factory('Auth', Auth).name;

/* @ngInject */
function run($rootScope, $state, Logger, Auth, UserStorage, BACKEND_OPTIONS, $transitions) {

  // the refresh token is always clear on the login page
  $transitions.onStart({ to: 'login' }, () => Auth.clearRefreshToken()
    .then(() => {
      Intercom.restart(BACKEND_OPTIONS.intercom_app_id);
    })
    .catch(() => {
      $state.go('login', { isApiError: true });
    }));

  $rootScope.$on('event:auth-loginRequired', () => {
    Logger.warn('401 Unauthorized, refreshing the access token...');
    Auth.refreshLogin();
  });
}
