import * as pestTypes from '../constants/pestTypes.constants';

import BugIcon from '../../shared/icons/BugIcon';
import SpotsIcon from '../../shared/icons/SpotsIcon';

const LAST_VALUE = 'lastValue';
const SPREADING_VALUE = 'spreadingValue';

const pestTypesConfig = [
  {
    id: pestTypes.CORN_BORER,
    name: 'PestPrediction.CornBorer',
    general1: 'PestPrediction.CornBorer.general.1',
    general2: 'PestPrediction.CornBorer.general.2',
    limitDescription: true,
    displayOccurrences: false,
    containsAdditionalValue: false,
    icon: BugIcon,
    colorsAndDescriptions: [
      {
        value: 249,
        color: '#7ED321',
        translationId: 'PestPrediction.CornBorer_1',
      },
      {
        value: 250,
        color: '#FFBF00',
        translationId: 'PestPrediction.CornBorer_2',
      },
      {
        value: 300,
        color: '#FFBF00',
        translationId: 'PestPrediction.CornBorer_3',
      },
      {
        value: 360,
        color: '#D01D02',
        translationId: 'PestPrediction.CornBorer_4',
      },
      {
        value: 520,
        color: '#D01D02',
        translationId: 'PestPrediction.CornBorer_5',
      },
      {
        value: 550,
        color: '#D01D02',
        translationId: 'PestPrediction.CornBorer_6',
      },
      {
        value: 600,
        color: '#7ED321',
        translationId: 'PestPrediction.CornBorer_7',
      },
    ],
  },
  {
    id: pestTypes.WHEAT_BLOTCH,
    name: 'PestPrediction.WheatBlotch',
    general1: 'PestPrediction.WheatBlotch.general.1',
    general2: 'PestPrediction.WheatBlotch.general.2',
    limitDescription: false,
    displayOccurrences: true,
    containsAdditionalValue: false,
    tooltipMessage: 'PestPrediction.WheatBlotch.tooltip',
    icon: SpotsIcon,
    colorsAndDescriptions: [
      {
        value: 0,
        color: '#7ED321',
        translationId: 'PestPrediction.noConditions',
      },
      {
        value: 1,
        color: '#FFBF00',
        translationId: 'PestPrediction.WheatBlotch.progress',
      },
      {
        value: 21,
        color: '#D01D02',
        translationId: 'PestPrediction.WheatBlotch.progress',
      },
      {
        value: 28,
        color: '#D01D02',
        translationId: 'PestPrediction.WheatBlotch.progress',
      },
    ],
    infoTable: [
      {
        key: 'PestPrediction.WheatBlotch.table.row1.key',
        values: ['PestPrediction.WheatBlotch.table.row1.value.1', 'PestPrediction.WheatBlotch.table.row1.value.2'],
      },
      {
        key: 'PestPrediction.WheatBlotch.table.row2.key',
        values: [
          'PestPrediction.WheatBlotch.table.row2.value.1',
          'PestPrediction.WheatBlotch.table.row2.value.2',
          'PestPrediction.WheatBlotch.table.row2.value.3',
        ],
      },
      {
        key: 'PestPrediction.WheatBlotch.table.row3.key',
        values: ['PestPrediction.WheatBlotch.table.row3.value', 'PestPrediction.WheatBlotch.table.row.value.default'],
      },
      {
        key: 'PestPrediction.WheatBlotch.table.row4.key',
        values: ['PestPrediction.WheatBlotch.table.row4.value', 'PestPrediction.WheatBlotch.table.row.value.default'],
      },
      {
        key: 'PestPrediction.WheatBlotch.table.row5.key',
        values: ['PestPrediction.WheatBlotch.table.row5.value', 'PestPrediction.WheatBlotch.table.row.value.default'],
      },
      {
        key: 'PestPrediction.WheatBlotch.table.row6.key',
        values: ['PestPrediction.WheatBlotch.table.row6.value.1', 'PestPrediction.WheatBlotch.table.row6.value.2'],
      },
    ],
  },
  {
    id: pestTypes.FUSARIUM_HEAD_BLIGHT,
    name: 'PestPrediction.FusariumHeadBlight',
    general1: 'PestPrediction.FusariumHeadBlight.general.1',
    general3: 'PestPrediction.FusariumHeadBlight.general.3',
    limitDescription: false,
    displayOccurrences: true,
    containsAdditionalValue: true,
    tooltipMessage: 'PestPrediction.FusariumHeadBlight.tooltip',
    tooltipMessageAdditional: 'PestPrediction.FusariumHeadBlight.tooltipAdditional',
    icon: SpotsIcon,
    colorsAndDescriptions: [
      {
        value: 0,
        color: '#7ED321',
        translationId: 'PestPrediction.noConditions',
      },
      {
        value: 1,
        color: '#D01D02',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 4,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 28,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 29,
        color: '#7ED321',
        translationId: 'PestPrediction.conditionsOccurrence',
      },
    ],
    colorsAndDescriptionsAdditional: [
      {
        value: 0,
        color: '#7ED321',
        translationId: 'PestPrediction.noConditions',
      },
      {
        value: 1,
        color: '#D01D02',
        translationId: 'PestPrediction.FusariumHeadBlight.progressAdditional',
      },
      {
        value: 4,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progressAdditional',
      },
      {
        value: 28,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progressAdditional',
      },
      {
        value: 29,
        color: '#7ED321',
        translationId: 'PestPrediction.conditionsSpread',
      },
    ],
    conditions: {
      heading: 'PestPrediction.FusariumHeadBlight.conditions.heading',
      list: [
        { id: 1, translationId: 'PestPrediction.FusariumHeadBlight.conditions.1' },
        { id: 2, translationId: 'PestPrediction.FusariumHeadBlight.conditions.2' },
        { id: 3, translationId: 'PestPrediction.FusariumHeadBlight.conditions.3' },
        { id: 4, translationId: 'PestPrediction.FusariumHeadBlight.conditions.4' },
      ],
    },
  },
  {
    id: pestTypes.PHOMA_FOVEATA,
    name: 'PestPrediction.PhomaFoveata',
    general1: 'PestPrediction.PhomaFoveata.general.1',
    general3: 'PestPrediction.PhomaFoveata.general.3',
    limitDescription: false,
    displayOccurrences: true,
    containsAdditionalValue: false,
    tooltipMessage: 'PestPrediction.PhomaFoveata.tooltip',
    icon: SpotsIcon,
    colorsAndDescriptions: [
      {
        value: 0,
        color: '#7ED321',
        translationId: 'PestPrediction.noConditions',
      },
      {
        value: 1,
        color: '#D01D02',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 4,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 28,
        color: '#FFBF00',
        translationId: 'PestPrediction.FusariumHeadBlight.progress',
      },
      {
        value: 29,
        color: '#7ED321',
        translationId: 'PestPrediction.conditionsOccurrence',
      },
    ],
  },
];

export default class SensorsPestPrediction {
  static getPredictionTypeConfig(pestType) {
    return pestTypesConfig.find(config => config.id === pestType);
  }

  static getTranslationIdForTooltip(pestType, isAdditional) {
    const pestConfig = pestTypesConfig.find(config => config.id === pestType);
    return isAdditional ? pestConfig.tooltipMessageAdditional : pestConfig.tooltipMessage;
  }

  static shouldDisplayOccurrences(pestType) {
    return pestTypesConfig.find(config => config.id === pestType).displayOccurrences;
  }

  static getPredictionTypeDescriptionByValue(pestType, value, isAdditional) {
    const pestConf = pestTypesConfig.find(config => config.id === pestType);
    if (!pestConf || isNaN(parseFloat(value))) return null;
    const colorsAndDescriptions = isAdditional
      ? pestConf.colorsAndDescriptionsAdditional
      : pestConf.colorsAndDescriptions;
    if (!colorsAndDescriptions) return null;
    return colorsAndDescriptions.find(
      (description, index, array) =>
        (index !== array.length - 1 && value >= description.value && value < array[index + 1].value) ||
        (index === 0 && value < description.value) ||
        (index === array.length - 1 && value >= description.value),
    );
  }

  static getLimits(pestType) {
    const pestConf = pestTypesConfig.find(config => config.id === pestType);
    return pestConf.limitDescription ? pestConf.colorsAndDescriptions.map(description => description.value) : [];
  }

  static getPredictionMaxLimit(pestType) {
    const limits = pestTypesConfig
      .find(config => config.id === pestType)
      .colorsAndDescriptions.map(description => description.value);
    return limits[limits.length - 1];
  }

  static getInfoTable(pestType) {
    return pestTypesConfig.find(config => config.id === pestType).infoTable || [];
  }

  static getLastValue(values) {
    return values[LAST_VALUE];
  }

  static getSpreadingValue(values) {
    return values[SPREADING_VALUE];
  }
}
