import React, { Component } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSensorsAdminNodeTypes } from '../../selectors/sensors.selectors';

import { changeDeviceType, fetchNodes } from '../../actions/sensors.actions';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfSwitcherButton from '../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton';
import withPopover from '../../../../../shared/hocs/withPopover';
import SensorTypeMenuItem from '../../components/SensorTypeMenuItem/SensorTypeMenuItem';

const styles = theme => ({
  menuItem: {
    fontSize: 16,
    paddingBottom: 5,
    paddingTop: 5,
  },
  text: {
    color: theme.palette.grey[500],
    fontSize: 13,
  },
  switcherButtonLabel: {
    fontSize: 13,
  },
  button: {
    marginLeft: -9,
  },
  unknownType: {
    color: theme.palette.grey[500],
  },
});

class SensorAdminChangeSensorType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpened: false,
      newType: {},
    };
  }

  onCancel = () => {
    this.setState({
      dialogOpened: false,
    });
  };

  changeType = () => {
    this.props.changeDeviceType(this.props.item.id, this.state.newType).then(() => this.props.fetchNodes());
    this.setState({
      dialogOpened: false,
    });
  };

  handleChange = option => {
    this.setState({
      dialogOpened: true,
      newType: option.name,
    });
  };

  render() {
    const { anchorEl, classes, handlePopoverClose, handlePopoverOpen, isOpen, item } = this.props;
    const awaitingMessage = this.props.item.deviceType === 'AWAITING_MESSAGE';
    return !awaitingMessage ? (
      <div>
        <CfSwitcherButton
          classes={{ custom: classes.button }}
          data-test={'sensor-type'}
          onClick={handlePopoverOpen}
          transparent={true}
        >
          <span className={classes.switcherButtonLabel}>{item.deviceType}</span>
        </CfSwitcherButton>
        <Menu
          anchorEl={anchorEl}
          id="sensor-admin-change-sensor-type"
          keepMounted
          onClose={handlePopoverClose}
          open={isOpen}
        >
          <MenuItem value="UNKNOWN">
            <span className={classes.unknownType}>UNKNOWN</span>
          </MenuItem>
          {this.props.nodeTypes.map(option => (
            <MenuItem
              className={classes.menuItem}
              key={option.name}
              onClick={event => this.handleChange(option, event)}
              value={option.name}
            >
              <SensorTypeMenuItem option={option} />
            </MenuItem>
          ))}
        </Menu>
        <CfDialog
          acceptText={<FormattedMessage id="SensorAdminChangeSensorType.changeType" />}
          cancelText={<FormattedMessage id="common.cancel" />}
          data-test="change-sensor-type"
          dialogHeight={'95px'}
          onAccept={this.changeType}
          onCancel={this.onCancel}
          onClose={this.onCancel}
          opened={this.state.dialogOpened}
          title={<FormattedMessage id="SensorAdminChangeSensorType.header" />}
        >
          <FormattedMessage
            id="SensorAdminChangeSensorType.dialog"
            values={{
              oldType: item.deviceType || '',
              newType: this.state.newType || '',
            }}
          />
        </CfDialog>
      </div>
    ) : (
      <div className={classes.text}>
        <FormattedMessage id="SensorAdminChangeSensorType.await" />
      </div>
    );
  }
}

SensorAdminChangeSensorType.propTypes = {
  item: PropTypes.object.isRequired,
  classes: PropTypes.object,
  changeDeviceType: PropTypes.func.isRequired,
  fetchNodes: PropTypes.func.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
};

SensorAdminChangeSensorType.defaultProps = {
  classes: {},
  anchorEl: null,
};

const mapStateToProps = state => ({
  nodeTypes: getSensorsAdminNodeTypes(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeDeviceType,
      fetchNodes,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
  withPopover,
)(SensorAdminChangeSensorType);
