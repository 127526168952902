import React, { Fragment } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: '34px 50px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '34px 16px 20px',
    },
  },
  dialogContent: {
    padding: '0px 50px 24px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px 24px 16px',
    },
  },
});

/**
 * Styled simple dialog
 * @version 1.0.0
 */
function CfSimpleDialog(props) {
  const { children, classes, fullWidth, heading, maxWidth, onDialogClose, open } = props;
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onDialogClose}
      open={open}
    >
      <DialogTitle classes={{ root: classes.title }} data-test="dialog-title" id="simple-dialog-title">
        {heading}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }} data-test="dialog-content">
        <Fragment>{children}</Fragment>
      </DialogContent>
      <DialogActions data-test="dialog-footer">
        <Button autoFocus color="primary" onClick={onDialogClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CfSimpleDialog.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** The inside od the dialog */
  children: PropTypes.node.isRequired,
  /** Heading label */
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /** FUnction to be called on close */
  onDialogClose: PropTypes.func.isRequired,
  /** Whether the dialog is open */
  open: PropTypes.bool.isRequired,
  /** Max width */
  maxWidth: PropTypes.string,
  /** fullWidth boolean */
  fullWidth: PropTypes.bool,
};

CfSimpleDialog.defaultProps = {
  heading: null,
  maxWidth: 'sm',
  fullWidth: false,
};

export default withStyles(styles)(CfSimpleDialog);
