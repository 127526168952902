import * as types from './eagriAuth.constants';

export default () => (
  state = {
    isFetching: false,
    error: null,
    item: null,
    setAuthIsFetching: false,
    setAuthError: null,
    setAuthItem: null,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_EAGRI_AUTH:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_EAGRI_AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_EAGRI_AUTH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SET_EAGRI_AUTH:
      return {
        ...state,
        isSetting: true,
        setAuthError: null,
      };
    case types.SET_EAGRI_AUTH_SUCCESS:
      return {
        ...state,
        setAuthIsFetching: false,
        setAuthItem: action.payload,
      };
    case types.SET_EAGRI_AUTH_ERROR:
      return {
        ...state,
        setAuthIsFetching: false,
        setAuthError: action.payload,
      };
    default:
      return state;
  }
};
