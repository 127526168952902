import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../shared/api/api.constants';
import * as types from './actionTypes.constants';

export const getActionTypes = () => {
  const p = {
    page: 1,
    'per-page': 500,
  };
  return {
    [RSAA]: {
      endpoint: `action-types?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_ACTION_TYPES, types.GET_ACTION_TYPES_SUCCESS, types.GET_ACTION_TYPES_ERROR],
    },
  };
};
