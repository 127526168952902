/**
 * Created by ondrejzvara on 16.3.17.
 */


/*@ngInject*/
export default function cfDatepickerEnd($rootScope, $timeout) {

    var directive = {
        restrict: 'E',
        template: require('./cf-datepicker-end.html'),
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            minEndDate: '=?min',
            maxEndDate: '=?max',
            validators: "@?",
            label: "@?",
            onChange: "&?"
        },
        link: function(scope){

            scope.validators = scope.validators ? scope.validators : undefined; // by default it is not required
            scope.onChange = scope.onChange || angular.noop;
            scope.onDateEndBlur = onDateEndBlur;

            $rootScope.$on('dateStartChange', function(evt, dateStart){
                if(new Date(dateStart).getTime() > new Date(scope.model).getTime() && scope.model !== null){
                    scope.model = dateStart;
                }
            });

            activate();

            function activate(){
                $timeout(function(){
                    $rootScope.$broadcast('dateEndChange', scope.model);
                });
            }

            function onDateEndBlur(){
                $timeout(function(){
                    $rootScope.$broadcast('dateEndChange', scope.model);
                    scope.onChange(scope.model);
                });
            }

        }
    };
    return directive;

}
