import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfPrimaryTab from '../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';

export const TABS = {
  fertilizers: {
    url: 'fertilizers',
    id: 'fertilizers',
    materialTypeId: 'FR',
  },
  chemistries: {
    url: 'pors',
    id: 'chemistries',
    materialTypeId: 'CH',
  },
  seeds: {
    url: 'seeds',
    id: 'seeds',
    materialTypeId: 'SD',
  },
};

class StoresTabs extends Component {
  constructor(props) {
    super(props);
    this.baseUrl = 'stores';
    this.tabs = TABS;

    this.orderedTabs = [this.tabs.fertilizers, this.tabs.chemistries, this.tabs.seeds];

    this.state = {
      tabValue: this.tabs.fertilizers.id,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.handlePathnameChange(location.pathname);
  }

  handlePathnameChange = pathname => {
    const { tabs } = this;
    const activeTab = this.orderedTabs.find(tab => pathname.toLowerCase().includes(tab.url));
    const activeTabId = activeTab ? activeTab.id : tabs.fertilizers.id;
    this.setState({ tabValue: activeTabId });
  };

  handleChange = (event, value) => {
    const { ngGoToTab } = this.props;
    this.setState({ tabValue: value });
    ngGoToTab(this.tabs[value].url);
    this.props.setStoreMaterialType(this.tabs[value].materialTypeId);
  };

  render() {
    const { tabValue } = this.state;
    return (
      <CfPrimaryTabs centered onChange={this.handleChange} tabValue={tabValue}>
        {this.orderedTabs.map(tab => (
          <CfPrimaryTab
            data-test={tab.id}
            key={tab.id}
            label={<FormattedMessage id={`common.tab.${tab.id}`} />}
            value={tab.id}
          />
        ))}
      </CfPrimaryTabs>
    );
  }
}

StoresTabs.propTypes = {
  location: PropTypes.object.isRequired,
  setStoreMaterialType: PropTypes.func.isRequired,
  ngGoToTab: PropTypes.func.isRequired,
};

export default StoresTabs;
