import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './crops.constants';

export const getAvailableSeedCrops = () => ({
  [RSAA]: {
    endpoint: 'crops?seedCropOnly=true&per-page=500&sort-col=crop.name&',
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_CROPS, types.GET_CROPS_SUCCESS, types.GET_CROPS_FAILURE],
  },
});

export const resetCrops = () => ({
  type: types.RESET_CROPS,
});
