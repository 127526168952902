import {
  getItems as getLocationsApi,
  getIsFetching as getIsFetchingLocationsApi,
  getError as getErrorApi,
  getTotalCount as getLocationsTotalCountApi,
  getPoints as getPointsApi,
  getIsFetchingPoints as getIsFetchingPointsApi,
  getLocation as getLocationApi,
  getIsFetchingLocation as getIsFetchingLocationApi,
  getErrorLocation as getErrorLocationApi,
} from '../../shared/api/iot/locations/locations.selectors';

export const getLocations = state => getLocationsApi(state);
export const getIsFetchingLocations = state => getIsFetchingLocationsApi(state);
export const getIsErrorLocations = state => getErrorApi(state);
export const getLocationsTotalCount = state => getLocationsTotalCountApi(state);

export const getLocation = state => getLocationApi(state);
export const getIsFetchingLocation = state => getIsFetchingLocationApi(state);
export const getErrorLocation = state => getErrorLocationApi(state);

export const getLocationsPoints = state => getPointsApi(state);
export const getIsFetchingLocationsPoints = state => getIsFetchingPointsApi(state);

export const getLocationsHistoryFilter = state => state.ui.locations.historyFilter;
export const getLocationsTextFilter = state => state.ui.locations.textFilter;
export const getLocationsStatusFilter = state => getLocationsAdvancedFilter(state).status || [];
export const getLocationsNodeTypeFilter = state => getLocationsAdvancedFilter(state).nodeType || [];
export const getLocationsGroupsFilter = state => getLocationsAdvancedFilter(state).groups || [];
export const getLocationsAdvancedFilter = state => state.ui.locations.advancedFilter;
export const getCurrentLocation = (state, props) =>
  getLocationsApi(state).find(location => location.id === props.match.params.sensorId);

export const getLocationsPage = state => state.ui.locations.page;
export const getLocationsOrder = state => state.ui.locations.order;
export const getLocationsOrderBy = state => state.ui.locations.orderBy;
export const getLocationsRowsPerPage = state => state.ui.locations.rowsPerPage;
