import { createSelector } from 'reselect';

import { MainMapState } from '../../../../reducers/map.reducer.types';

export const getMainMapTelematicsMachinePositions =
    (state: MainMapState) => state.api.mainMapTelematics.machinePositions;

export const getMainMapTelematicsMachineDrivesHistory =
    (state: MainMapState) => state.api.mainMapTelematics.machineDrivesHistory;

export const getMainMapTelematicsMachines =
    (state: MainMapState) => state.api.mainMapTelematics.machines;
export const getMainMapTelematicsIsFetchingMachines =
    (state: MainMapState) => state.api.mainMapTelematics.isFetchingMachines;
export const getMainMapTelematicsMachineSelectorFilter =
    (state: MainMapState) => state.api.mainMapTelematics.machineSelectorFilter;

export const getMainMapTelematicsMachinesFiltered = createSelector(
  getMainMapTelematicsMachines,
  getMainMapTelematicsMachineSelectorFilter,
  (machines, machineSelectorFilter) => {
    if (machineSelectorFilter.length === 0) return machines;

    const filteredMachines = machines.filter(machine =>
      machine.name?.toLowerCase().includes(machineSelectorFilter.toLowerCase()) ||
      machine.licencePlate?.toLowerCase().includes(machineSelectorFilter.toLowerCase()),
    );
    return filteredMachines;
  });

export const getMainMapTelematicsMachinesGroups =
    (state: MainMapState) => state.api.mainMapTelematics.machinesGroups;
export const getMainMapTelematicsIsFetchingMachinesGroups =
    (state: MainMapState) => state.api.mainMapTelematics.isFetchingMachinesGroups;

export const getMainMapTelematicsDrivers =
    (state: MainMapState) => state.api.mainMapTelematics.drivers;
export const getMainMapTelematicsIsFetchingDrivers =
    (state: MainMapState) => state.api.mainMapTelematics.isFetchingDrivers;
