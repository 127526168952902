/**
 * farm.classifiers.private module, manages private farm classifiers
 */


import angular from 'angular';
import tpl from './classifiers.private.html';
import ClassifiersController from './classifiers.private.controller';

import fertilizers from './fertilizers/fertilizers.module';
import pors from './pors/pors.module';
import seeds from './seeds/seeds.module';

export default angular.module('app.components.farm.classifiers.private', [
    fertilizers,
    pors,
    seeds,
])
    .controller('ClassifiersController', ClassifiersController)
    .config(config)
    .name;


/*@ngInject*/
function config($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.when('/farm/classifiers/private', '/farm/classifiers/private/centers');

    $stateProvider
        .state('farm.active.classifiers.private', {
            url: '/private',
            abstract: true,
            views: {
                'farm@farm.active': {
                    controller: "ClassifiersController as ClassifiersCtrl",
                    template: tpl
                }
            }
        });
}