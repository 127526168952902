import React, { Component } from 'react';

import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
  listKey: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.main,
    margin: '20px 0 12px 0',
  },
  listValue: {
    fontSize: 16,
  },
  listValueAdditional: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  content: {
    margin: '24px 0px',
  },
});

class InfoList extends Component {
  renderInfoTableValues(values) {
    const { classes } = this.props;
    return values.map((v, index) => (
      <div className={index === 2 ? classes.listValueAdditional : classes.listValue} key={v}>
        <FormattedMessage id={v} />
      </div>
    ));
  }

  render() {
    const { classes, list } = this.props;
    return list.map((l, index) => (
      <div data-test="info-table" key={l.key}>
        <div className={classes.content}>
          <div className={classes.listKey}>
            <FormattedMessage id={l.key} />
          </div>
          {this.renderInfoTableValues(l.values)}
        </div>
        {index < list.length - 1 && <Divider className={classes.divider} variant="fullWidth" />}
      </div>
    ));
  }
}

InfoList.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.array.isRequired,
};

InfoList.defaultProps = {
  classes: {},
};

export default withStyles(style)(InfoList);
