const weightUnits = Object.freeze({
  KG: { id: 'kg', name: 'kg' },
  Q: { id: 'q', name: 'q' },
  T: { id: 't', name: 't' },
});

const volumeUnits = Object.freeze({
  L: { id: 'l', name: 'l' },
  M3: { id: 'm3', name: 'm3' },
});

const countUnits = Object.freeze({
  VJ: { id: 'vj', name: 'VJ' },
});

const weightUnitIds = Object.values(weightUnits).map(unit => unit.id);
const volumeUnitIds = Object.values(volumeUnits).map(unit => unit.id);
const countUnitIds = Object.values(countUnits).map(unit => unit.id);

export default class Unit {
  static getUnits() {
    return Object.values({ ...weightUnits, ...volumeUnits, ...countUnits });
  }

  static getCompatibleUnits(unitId) {
    const units = this.getUnits();
    const compatibleUnitIds = Unit.getCompatibleUnitIds(unitId);
    return units.filter(unit => compatibleUnitIds.includes(unit.id));
  }

  static getCompatibleUnitIds(unitId) {
    switch (true) {
      case weightUnitIds.includes(unitId):
        return weightUnitIds;
      case volumeUnitIds.includes(unitId):
        return volumeUnitIds;
      case countUnitIds.includes(unitId):
        return countUnitIds;
      default:
        return [];
    }
  }

  static getUnitById(unitId) {
    return this.getUnits().find(unit => unit.id === unitId);
  }
}
