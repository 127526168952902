import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  scrollingItem: {
    flex: '0 0 auto',
  },
};

function HorizontalScrollingItem(props) {
  return <div className={props.classes.scrollingItem} ref={props.refFnc}>{props.children}</div>;
}

HorizontalScrollingItem.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  refFnc: PropTypes.func,
};

HorizontalScrollingItem.defaultProps = {
  refFnc: () => {},
};

export default withStyles(styles)(HorizontalScrollingItem);
