import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/actions.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import ActionsWrapper from './shared/containers/ActionsWrapper/ActionsWrapper';

ActionsController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Localization',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function ActionsController(
  $element,
  $scope,
  $state,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    farmId: this.farmId,
    langId: null,
    initParcelIds: this.parcelIds,
    initZoneIds: this.zoneIds,
    ngGoToActions,
    ngGoToAction,
    ngGoToEph,
    ngGoToSowing,
    ngGoToVrf,
    ngGoToVrs,
    ngGoToOtherNew,
    ngGoToHarvest,
    ngGoToMowing,
    reducer,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: 'actionList',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngGoToSowing() {
    return $state.go('farm.active.actions.sowing');
  }

  function ngGoToOtherNew() {
    return $state.go('farm.active.actions.otherNew');
  }

  function ngGoToHarvest() {
    return $state.go('farm.active.actions.harvest');
  }

  function ngGoToMowing() {
    return $state.go('farm.active.actions.mowing');
  }

  function ngGoToEph() {
    return $state.go('farm.active.actions.eph');
  }

  function ngGoToVrf() {
    return $state.go('farm.active.actions.vrf');
  }

  function ngGoToVrs() {
    return $state.go('farm.active.actions.vrs');
  }

  function ngGoToActions() {
    return $state.go('farm.active.actions');
  }

  function ngGoToAction(actionId, route) {
    return $state.go(`farm.active.${route}`, { actionId });
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <ActionsWrapper {...p} />} />, $element[0]);
  }
}
