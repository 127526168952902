import React, { Component, Suspense } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getManagementZones } from '../../../../../../shared/api/sentinel/management/management.selectors';
import { getMonitoringData } from '../../../../../../shared/api/sentinel/monitoring/monitoring.selectors';
import { getIsFetchingParcel, getVariableApplicationStatus } from '../../selectors/parcelDetail.selectors';

import { updateParcelName } from '../../actions/parcelDetail.actions';

import * as satelliteProductsTypes from '../../../../../../shared/constants/satelliteProductsTypes.constants';

import { isParcelSown } from '../../../../../../evidence/actions/others/helpers/others.helpers';
import { getParcelById, getParcelSeedingInfo, getParcelRestrictions } from '../../../../../../shared/api/core/parcels/parcels.api';
import {
  getSatelliteProducts,
  resetSatelliteProducts,
} from '../../../../../../shared/api/sentinel/satelliteProducts/satelliteProducts.api';
import CfBackButton from '../../../../../../shared/components/common/CfBackButton/CfBackButton';
import CfErrorPage from '../../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import PageHeader from '../../../../../../shared/components/common/PageHeader/PageHeader';
import ShowHideMap from '../../../../../../shared/components/common/ShowHideMap/ShowHideMap';
import withWidth from '../../../../../../shared/hocs/withWidth';
import ParcelsService from '../../../../shared/services/Parcels.service';
import ParcelActivities from '../../../activities/containers/ParcelActivities/ParcelActivities';
import ManagementZones from '../../../management/containers/ManagementZones/ManagementZones';
import BioMonitoring from '../../../monitoring/containers/BioMonitoring/BioMonitoring';
import ParcelDetailOverview from '../../../overview/containers/ParcelDetailOverview/ParcelDetailOverview';
import ParcelDetailFabButton from '../../components/ParcelDetailFabButton/ParcelDetailFabButton';
import ParcelDetailHeading from '../../components/ParcelDetailHeading/ParcelDetailHeading';
import ParcelDetailTabs from '../../components/ParcelDetailTabs/ParcelDetailTabs';
import ParcelDetailMap from '../ParcelDetailMap/ParcelDetailMap';

const styles = theme => ({
  wrapper: {
    height: '100%',
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #EFEFEF',
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  bodyWrapper: {
    position: 'relative',
  },
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    height: '100%',
  },
  content: {
    // for demonstration purpose of scrolling
    // height: '1500px',
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
  },
  mapWrapper: {
    height: '100%',
  },
});

export class ParcelDetail extends Component {
  constructor(props) {
    super(props);
    this.tabs = {
      overview: {
        id: 'overview',
        path: 'overview',
      },
      activities: {
        id: 'activities',
        path: 'activities',
      },
      monitoring: {
        id: 'monitoring',
        path: 'bio-monitoring',
      },
      management: {
        id: 'management',
        path: 'management-zones',
      },
    };
    this.state = {
      displayMap: this.isDesktop(props.width),
      activeTab: this.tabs.overview.id,
      selectedParcel: null,
      geometries: null,
      mapImage: null,
    };
    this.fetchParcelData(props.match.params.parcelId);
  }

  componentDidMount() {
    this.setTabByPathname(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { langId, location, match, width } = this.props;
    if (prevProps.match.params.parcelId !== match.params.parcelId) {
      this.fetchParcelData(this.props.match.params.parcelId);
      this.setMapExtraLayer(null);
    }

    if (langId && langId !== prevProps.langId) {
      this.props.getParcelById(null, match.params.parcelId);
    }

    if (width !== prevProps.width) {
      if (this.isDesktop(prevProps.width) && !this.isDesktop(width) && this.state.displayMap) {
        this.toggleMap();
      }

      if (!this.isDesktop(prevProps.width) && this.isDesktop(width) && !this.state.displayMap) {
        this.toggleMap();
      }
    }

    if (location.pathname !== prevProps.location.pathname) {
      const tabPath = this.tabs[this.state.activeTab].path;
      if (!location.pathname.toLowerCase().includes(tabPath)) {
        this.setTabByPathname(location.pathname);
      }
    }
  }

  setTabByPathname = pathname => {
    let tabId = this.tabs.overview.id;
    forEach(this.tabs, tab => {
      if (pathname.toLowerCase().includes(tab.path)) {
        tabId = tab.id;
      }
    });

    this.activateTab(tabId);
  };

  setMapExtraLayer = (source, isImage = false) => {
    this.setState({
      geometries: isImage ? null : source,
      mapImage: isImage ? source : null,
    });
  };

  fetchParcelData = parcelId => {
    this.props.resetSatelliteProducts();
    this.props.getParcelById(null, parcelId);
    this.props.getParcelSeedingInfo(null, parcelId);
    this.props.getSatelliteProducts(parcelId);
    if (this.props.farm && this.props.farm.customer && this.props.farm.customer.countryCode === 'CZ') {
      this.props.getParcelRestrictions(null, parcelId);
    }
  };

  isDesktop = width => ['md', 'lg', 'xl'].indexOf(width) >= 0;

  confirmEditing = newName => this.props.updateParcelName(this.props.parcel.id, newName);

  toggleMap = () => {
    this.setState({
      displayMap: !this.state.displayMap,
    });
  };

  goToParcel = selectedParcel => {
    const { farm, history, width } = this.props;
    const { activeTab, displayMap } = this.state;
    history.push(`/farm/${farm.id}/parcels/${selectedParcel.id}/${this.tabs[activeTab].path}`);
    this.setState({ selectedParcel, displayMap: this.isDesktop(width) ? displayMap : false });
  };

  activateTab(tabId) {
    const { activeTab, displayMap } = this.state;
    const { width } = this.props;
    let displayMapNew = displayMap;
    if (activeTab === this.tabs.activities.id && this.isDesktop(width)) {
      displayMapNew = true;
    }
    if (tabId === this.tabs.activities.id) {
      displayMapNew = false;
    }
    this.setState({
      activeTab: tabId,
      displayMap: displayMapNew,
      geometries: null,
    });
  }

  render() {
    const error = {};
    const {
      classes,
      farm,
      history,
      isFetchingParcel,
      langId,
      location,
      management,
      match,
      ngGoToAction,
      ngGoToMainMap,
      ngGoToParcels,
      ngRedirectToEph,
      ngRedirectToHarvest,
      ngRedirectToMowing,
      ngRedirectToOtherActionsNew,
      ngRedirectToSowing,
      ngRedirectToVrf,
      ngRedirectToVrs,
      parcel,
      variableApplicationStatus,
      width,
    } = this.props;

    const isHistorical = ParcelsService.isParcelHistorical(parcel);
    const { parcelId } = match.params;
    const { activeTab, displayMap, geometries, mapImage, selectedParcel } = this.state;
    const { countryCode } = this.props.farm.customer;
    const displayContent = !displayMap || (width !== 'xs' && width !== 'sm');
    const isSown = isParcelSown(parcel);

    let parcelColor = null;
    if (!isFetchingParcel && parcel?.currentSeedApplication) {
      parcelColor = parcel.currentSeedApplication.seed.crop.color;
    }

    if ((geometries || mapImage) && (activeTab === this.tabs.management.id || activeTab === this.tabs.monitoring.id)) {
      parcelColor = { red: 255, green: 255, blue: 255, fillAlfa: 0 };
    }
    return (
      <CfErrorPage error={error}>
        <Grid className={classes.wrapper} container>
          {displayContent && (
            <Grid className={classes.bodyWrapper} item md={displayMap ? 8 : 12} xs={12}>
              <Scrollbars>
                <div className={classes.body}>
                  <div className={classes.headerWithTabs}>
                    <div className={classes.headerWrapper}>
                      <PageHeader
                        actionButtons={
                          <ParcelDetailFabButton
                            isHistorical={isHistorical}
                            isSown={isSown}
                            isVraAllowed={variableApplicationStatus === satelliteProductsTypes.ACTIVE}
                            ngRedirectToEph={ngRedirectToEph}
                            ngRedirectToHarvest={ngRedirectToHarvest}
                            ngRedirectToMowing={ngRedirectToMowing}
                            ngRedirectToOtherActionsNew={ngRedirectToOtherActionsNew}
                            ngRedirectToSowing={ngRedirectToSowing}
                            ngRedirectToVrf={ngRedirectToVrf}
                            ngRedirectToVrs={ngRedirectToVrs}
                            parcelId={parcelId}
                          />
                        }
                        backButton={
                          <CfBackButton
                            translId={'ParcelDetail.backToParcels'}
                            onClick={() => {
                              ngGoToParcels(farm.id);
                            }}
                          />
                        }
                        classes={{
                          header: classes.header,
                        }}
                        heading={
                          parcel ? (
                            <ParcelDetailHeading
                              confirmEditing={this.confirmEditing}
                              isFetchingParcel={isFetchingParcel}
                              parcel={parcel}
                              selectedParcel={selectedParcel}
                            />
                          ) : null
                        }
                      />
                    </div>
                    <ParcelDetailTabs
                      activateTab={tabId => this.activateTab(tabId)}
                      activeTab={activeTab}
                      history={history}
                      location={location}
                      match={match}
                      tabs={this.tabs}
                      width={width}
                    />
                  </div>
                  <Grid className={classes.contentWrapper} container>
                    <Grid item lg={displayMap ? 'auto' : 1} xl={displayMap ? 'auto' : 2} />
                    <Grid className={classes.content} item lg={displayMap ? 12 : 10} xl={displayMap ? 12 : 8}>
                      {parcel?.id && (
                        <Suspense fallback={<CfLoader />}>
                          <Switch>
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.overview.path}`}
                              render={() => (
                                <ParcelDetailOverview
                                  countryCode={countryCode}
                                  displayedMap={displayMap}
                                  goToParcel={this.goToParcel}
                                  langId={langId}
                                  parcel={parcel}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.activities.path}`}
                              render={() => (
                                <ParcelActivities
                                  langId={langId}
                                  match={match}
                                  ngGoToAction={ngGoToAction}
                                  parcelId={parcelId}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.monitoring.path}`}
                              render={() => (
                                <BioMonitoring
                                  displayedMap={displayMap}
                                  geometries={geometries}
                                  langId={langId}
                                  mapImage={mapImage}
                                  ngRedirectToSowing={ngRedirectToSowing}
                                  onZonesClick={this.setMapExtraLayer}
                                  parcelId={parcelId}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.management.path}`}
                              render={() => (
                                <ManagementZones
                                  displayedMap={displayMap}
                                  geometries={geometries}
                                  langId={langId}
                                  managementZones={management}
                                  onZonesClick={this.setMapExtraLayer}
                                  parcelId={parcelId}
                                />
                              )}
                            />
                          </Switch>
                        </Suspense>
                      )}
                    </Grid>
                    <Grid item lg={displayMap ? 'auto' : 1} xl={displayMap ? 'auto' : 2} />
                  </Grid>
                </div>
              </Scrollbars>
              {<ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={!displayMap} />}
            </Grid>
          )}
          <Grid className={classes.mapWrapper} item md={4} style={{ display: displayMap ? 'block' : 'none' }} xs={12}>
            {!displayContent && <ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={false} />}
            <ParcelDetailMap
              displayMap={displayMap}
              geometries={geometries}
              goToParcel={this.goToParcel}
              isHistorical={isHistorical}
              mapImage={mapImage}
              ngGoToMainMap={ngGoToMainMap}
              parcelColor={parcelColor}
              parcelId={parcelId}
            />
          </Grid>
        </Grid>
      </CfErrorPage>
    );
  }
}

ParcelDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  farm: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getParcelById: PropTypes.func.isRequired,
  getParcelSeedingInfo: PropTypes.func.isRequired,
  getParcelRestrictions: PropTypes.func.isRequired,
  resetSatelliteProducts: PropTypes.func.isRequired,
  parcel: PropTypes.object,
  width: PropTypes.string.isRequired,
  updateParcelName: PropTypes.func.isRequired,
  management: PropTypes.object,
  isFetchingParcel: PropTypes.bool.isRequired,
  ngGoToParcels: PropTypes.func.isRequired,
  ngGoToMainMap: PropTypes.func.isRequired,
  ngRedirectToEph: PropTypes.func.isRequired,
  ngRedirectToSowing: PropTypes.func.isRequired,
  ngRedirectToOtherActionsNew: PropTypes.func.isRequired,
  ngRedirectToHarvest: PropTypes.func.isRequired,
  ngRedirectToVrf: PropTypes.func.isRequired,
  ngRedirectToVrs: PropTypes.func.isRequired,
  ngRedirectToMowing: PropTypes.func.isRequired,
  getSatelliteProducts: PropTypes.func.isRequired,
  variableApplicationStatus: PropTypes.string,
  ngGoToAction: PropTypes.func.isRequired,
};

ParcelDetail.defaultProps = {
  parcel: null,
  management: {},
  variableApplicationStatus: undefined,
};

const mapStateToProps = state => ({
  parcel: state.api.parcels.item,
  monitoring: getMonitoringData(state),
  management: getManagementZones(state),
  isFetchingParcel: getIsFetchingParcel(state),
  variableApplicationStatus: getVariableApplicationStatus(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getParcelById,
      getParcelSeedingInfo,
      getParcelRestrictions,
      updateParcelName,
      getSatelliteProducts,
      resetSatelliteProducts,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(compose(withStyles(styles), withWidth())(ParcelDetail));
