/**
 * Created by ondrejzvara on 2.11.15.
 */


/*@ngInject*/
export default function SeedsResource(Api, ResourceUtils) {

    var baseAll = Api.setBaseAll("seeds");

    var service = {
        getAllSeeds: getAllSeeds,
        getSeeds: getSeeds,
        editSeeds: editSeeds,
        deleteSeeds: deleteSeeds,
        addToFarm: addToFarm
    };
    return service;

    /////////////////////////

    function getAllSeeds() {
        return getSeeds({}, {count: 500});
    }

    function getSeeds(ngTparams, custParams) {

        var params = ResourceUtils.composeParams(ngTparams, custParams);

        if (custParams && custParams.providerId) {
            params.providerId = custParams.providerId;
        }
        if (custParams && custParams.ids) {
            params.ids = custParams.ids;
        }

        return ResourceUtils.getItems(baseAll, params, ngTparams);
    }

    function editSeeds(items) {
        var restangularizedItems = _.map(items, function (item) {
            return Api.restangularizeOne(null, item, "seeds");
        });
        return ResourceUtils.editItems(restangularizedItems);
    }

    function deleteSeeds(items) {
        return ResourceUtils.deleteItems(items);
    }

    function addToFarm(item) {
        return ResourceUtils.createItems(baseAll, [item]);
    }
}
