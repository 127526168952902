import { API_METHODS as methods } from '../../../shared/api/api.constants';
import * as types from './reset.constants';

export const changeLanguage = langId => ({
  type: types.LANG_CHANGE,
  langId,
});

export const setFetch = isFetching => ({
  type: types.SET_FETCH,
  isFetching,
});

export const resetError = () => ({
  type: types.RESET_ERROR,
  error: new Error('Unexpected error'),
});

export const setMailSent = (gwUrl, mail) => dispatch => {
  dispatch(setFetch(true));
  const resetInit = new Request(resetInitUrl(gwUrl, mail), {
    headers: {
      Origin: window.location.href,
    },
    method: methods.POST,
  });

  fetch(resetInit)
    .then(status)
    .then(() => {
      dispatch({
        type: types.MAIL_SENT,
      });
    })
    .catch(() => {
      dispatch(resetError());
    })
    .then(() => {
      dispatch(setFetch(false));
    });

  dispatch(setFetch(false));
};

export const checkResetKey = (gwUrl, key) => dispatch => {
  dispatch(setFetch(true));
  const checkKeyReq = new Request(checkKeyUrl(gwUrl, key), {
    headers: {
      Accept: 'application/json',
      Origin: window.location.href,
    },
    method: methods.GET,
  });

  fetch(checkKeyReq)
    .then(status)
    .then(json)
    .then(validity => {
      dispatch({
        type: types.RESET_KEY_VALIDITY,
        resetKeyValid: validity,
      });
    })
    .catch(() => {
      dispatch(resetError());
    })
    .then(() => {
      dispatch(setFetch(false));
    });
};

export const saveNewPassword = (gwUrl, key, pass) => dispatch => {
  dispatch(setFetch(true));
  const resetFinish = new Request(resetFinishUrl(gwUrl), {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Origin: window.location.href,
    },
    method: methods.POST,
    body: JSON.stringify({ key, newPassword: pass }),
  });

  fetch(resetFinish)
    .then(status)
    .then(() => {
      dispatch({
        type: types.RESET_SUCCESSFUL,
      });
      dispatch(setFetch(false));
    })
    .catch(() => {
      dispatch({
        type: types.RESET_KEY_VALIDITY,
        resetKeyValid: false,
      });
      dispatch(setFetch(false));
    });
};

const resetInitUrl = (gwUrl, mail) => `${gwUrl}/accounts/reset-password/init?mail=${mail}`;
const resetFinishUrl = gwUrl => `${gwUrl}/accounts/reset-password/finish`;
const checkKeyUrl = (gwUrl, key) => `${gwUrl}/accounts/check-reset-key?resetKey=${key}`;

function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }
  return Promise.reject(new Error());
}

function json(response) {
  return response.json();
}
