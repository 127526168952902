import React, { Fragment } from 'react';

import { Radio } from '@mui/material';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SubtractableArea from '../SubtractableArea/SubtractableArea';

const styles = theme => ({
  saRow: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

const OPTION_NONE = { restrictionType: 'none', restrictionValue: 0 };

function SubtractableAreaWithRadioButton(props) {
  const { classes, field, form, isEditing, options, validateOnBlur, ...restProps } = props;
  const optionsWithNone = [OPTION_NONE, ...options];
  return (
    <Fragment>
      <RadioGroup data-test={'area-subtraction-list'} {...field} {...restProps} name={field.name}>
        <Grid container>
          {optionsWithNone.map(option => (
            <Grid item key={`radio-${option.restrictionType}-${option.restrictionValue}`} md={3} sm={4} xs={12}>
              <span
                className={classes.saRow}
                data-test={`${option.restrictionType.toLowerCase()}-radio-subtraction-item`}
              >
                <Radio
                  checked={field.value?.toString() === option.restrictionValue?.toString()}
                  color="primary"
                  disabled={!isEditing}
                  value={option.restrictionValue}
                />
                {option.restrictionValue === 0 ?
                  <FormattedMessage id="SubtractableArea.none" /> :
                  <SubtractableArea sa={option} />}
              </span>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Fragment>
  );
}

SubtractableAreaWithRadioButton.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  isEditing: PropTypes.bool,
};

SubtractableAreaWithRadioButton.defaultProps = {
  options: [],
  isEditing: false,
  validateOnBlur: false,
};

export default withStyles(styles)(SubtractableAreaWithRadioButton);
