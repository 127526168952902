import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { zonesColors } from '../../services/monitoringZonesColors';

const getIndexGradient = index =>
  `linear-gradient(90deg,${zonesColors[index].map((item, i) => `${item.color} ${i * 10 + 5}%`)})`;

const styles = theme => ({
  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  legendImage: {
    margin: '0px 5px',
    width: 107,
    height: 8,
    borderRadius: 10,
    border: '1px solid #E4E4E4',
  },
  label: {
    color: theme.palette.grey[500],
  },
});

export const BioMonitoringLegend = props => {
  const { classes, index } = props;
  const gradient = getIndexGradient(index);

  return (
    <div className={classes.legendWrapper}>
      <span className={classes.label}>Min</span>{' '}
      <span className={classes.legendImage} style={{ background: gradient }} />{' '}
      <span className={classes.label}>Max</span>
    </div>
  );
};

BioMonitoringLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.string,
};

BioMonitoringLegend.defaultProps = {
  index: null,
};

export default withStyles(styles)(BioMonitoringLegend);
