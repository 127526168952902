import React, { useCallback, useEffect } from 'react';

import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { match as matchType, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getAreaDetail, getAreaPrograms, getIsFetchingAreaDetail } from '../../../shared/api/irrigation/areas/areas.selectors';

import { fetchArea, fetchAreaPrograms } from '../../actions/areasList.actions';
import { fetchDevices } from '../../actions/devices.actions';
import { setEnlargedVariant } from '../../actions/map.actions';

import { NAMESPACE } from '../../reducer/areaDevices.reducer';

import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import { Thunk } from '../../../types';
import { setActivePage, setPrevPage } from '../../actions/commonActions';
import IrrigationPlanCalendar from '../../components/IrrigationPlanCalendar/IrrigationPlanCalendar';

import { IrrigationState } from '../../../reducers/irrigation.reducer.types';
import { AreaDetail, AreaProgram } from '../../../shared/api/irrigation/areas/area.types';

interface Props {
  areaDetail: AreaDetail;
  areaPrograms: AreaProgram[];
  fetchArea: (areaId: string) => void;
  fetchAreaPrograms: (areaId: string) => void;
  fetchDevices: (namespace: string, areaId?: string) => void;
  handleCloseModal: () => void;
  isFetchingAreaDetail: boolean;
  langId: string;
  match: matchType<{areaId: string}>;
  setActivePage: (pathname: string) => void;
  setEnlargedVariant: (bool: boolean) => void;
  setPrevPage: (pathname: string) => void;
  showDialogFromFab: boolean;
}

const useStyles = makeStyles({
  wrapperStyle: {
    height: '100%',
  },
});

const IrrigationPlan = ({
  areaDetail,
  areaPrograms,
  fetchArea,
  fetchAreaPrograms,
  fetchDevices,
  handleCloseModal,
  isFetchingAreaDetail,
  langId,
  match,
  setActivePage,
  setEnlargedVariant,
  setPrevPage,
  showDialogFromFab,
}: Props) => {
  const classes = useStyles();
  const areaId = match.params.areaId;

  const { pathname } = useLocation();

  const fetchDevicesForThisArea = useCallback(() => fetchDevices(NAMESPACE, areaId), [fetchDevices, areaId]);

  useEffect(() => {
    fetchArea(areaId);
    fetchAreaPrograms(areaId);
    fetchDevicesForThisArea();
    setEnlargedVariant(false);
    setActivePage(pathname);
    return () => {
      setPrevPage(pathname);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapperStyle}>
      {isFetchingAreaDetail ? (
        <CfLoader />
      ) : (
        <Paper
          elevation={3}
          style={{ margin: '15px 25px 15px 15px' }}
        >
          <Grid container>
            <IrrigationPlanCalendar
              areaDetail={areaDetail}
              areaPrograms={areaPrograms}
              fabCloseModal={handleCloseModal}
              langId={langId}
              showDialogFromFab={showDialogFromFab}
            />
          </Grid>
        </Paper>

      )}
    </div>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  areaDetail: getAreaDetail(state),
  isFetchingAreaDetail: getIsFetchingAreaDetail(state),
  areaPrograms: getAreaPrograms(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      fetchArea,
      fetchAreaPrograms,
      fetchDevices,
      setEnlargedVariant,
      setActivePage,
      setPrevPage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IrrigationPlan);
