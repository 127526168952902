import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setEnlargedVariant } from '../../actions/map.actions';
import { activateStart, activateCancel, activate, updateCoordsFromForm } from '../../actions/node.actions';

import CfBackButton from '../../../shared/components/common/CfBackButton/CfBackButton';
import DeviceActivation from '../../../shared/components/specific/DeviceActivation/DeviceActivation';

export function NodeActivation(props) {
  const { history, match, newNode, nodeLocation, oldNode } = props;
  return (
    <DeviceActivation
      activateCancel={props.activateCancel}
      activateStart={props.activateStart}
      buttonMessageId={'NodeActivation.placeSensor'}
      device={oldNode}
      deviceId={oldNode?.externalId}
      geometryWgs={newNode.geometryWgs}
      pageHeadingMessageId={'NodeActivation.sensorPlacement'}
      setEnlargedVariant={props.setEnlargedVariant}
      updateCoordsFromForm={props.updateCoordsFromForm}
      activate={(name) => {
        delete props.newNode.geometryWgs;
        return props.activate(
          oldNode.id,
          name,
          newNode.geometry,
          match.params.farmId,
          nodeLocation.id,
        );
      }}
      backButton={
        <CfBackButton
          onClick={() => {
            history.push(`/farm/${match.params.farmId}/sensors/${match.params.sensorId}`);
          }}
        />
      }
    />
  );
}

NodeActivation.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  nodeLocation: PropTypes.object,
  oldNode: PropTypes.object,
  newNode: PropTypes.object,
  activateStart: PropTypes.func.isRequired,
  activateCancel: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  updateCoordsFromForm: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
};

NodeActivation.defaultProps = {
  history: {},
  match: {},
  nodeLocation: {},
  oldNode: {},
  newNode: {},
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateStart,
      activateCancel,
      activate,
      updateCoordsFromForm,
      setEnlargedVariant,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(NodeActivation);
