import React, { FC, useState, useEffect, ReactNode } from 'react';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FieldInputProps, FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import Localization from '../../../services/Localization.service';

const useStyles = makeStyles({
  helperText: {
    position: 'absolute',
    bottom: -22,
  },
});

interface Props {
  children: ReactNode;
  customClasses: Record<string, string>;
  disableValue: boolean;
  field: FieldInputProps<string>;
  form: FormikProps<Record<string, unknown>>;
  shrinkLabel: boolean;
  validateOnBlur: boolean;
}

// Component displays number as string formatted by respective locale.
// IMPORTANT: The input type is "text" so formik treats the output as string.
// Therefore, do not forget to convert it back to number (e.g. during blur event or form submit)
const CfFormikNumericTextField: FC<Props> = (props) => {
  const {
    children,
    customClasses,
    disableValue,
    field,
    form,
    shrinkLabel,
    validateOnBlur,
    ...restProps } = props;

  const intl = useIntl();
  const classes = useStyles();

  const [localizedValueString, setLocalizedValueString] =
    useState(Localization.num2strNonFixed(field.value, intl.locale));

  const [useLocalValue, setUseLocalValue] = useState(false);

  useEffect(() => {
    if (useLocalValue) return;
    setLocalizedValueString(Localization.num2strNonFixed(field.value, intl.locale));
  }, [field.value, intl.locale, useLocalValue]);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const { value } = e.currentTarget;
    const isLastCharacterNumber = !isNaN(value[value.length - 1]);
    if (!isLastCharacterNumber) {
      setUseLocalValue(true);
      setLocalizedValueString(e.currentTarget.value);
    } else {
      setUseLocalValue(false);
    }
    const newNumericValue = Localization.str2numNonFixed(e.currentTarget.value, intl.locale);
    const newE = { ...e };
    newE.currentTarget.value = newNumericValue;
    field.onChange(newE);
  };

  const shrinkLabelProps = {
    InputLabelProps: { shrink: true },
  };
  const error = form.errors[field.name];

  return (
    <TextField
      classes={customClasses}
      error={Boolean(error)}
      helperText={error}
      name={field.name}
      onChange={handleChange}
      value={disableValue ? '' : localizedValueString}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
      onBlur={e => {
        field.onBlur(e);
        if (validateOnBlur) {
          form.validateField(field.name);
        }
      }}
      {...(shrinkLabel && shrinkLabelProps)}
      {...restProps}
    >
      {children}
    </TextField>
  );
};

export default CfFormikNumericTextField;
