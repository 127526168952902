import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  label: {
    padding: '0px 4px',
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    borderRadius: 4,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: 'min-content',
  },
});

function CfLabel(props) {
  const { classes, message } = props;
  return (
    <span className={classes.label} data-test="label">
      {message}
    </span>
  );
}

CfLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(CfLabel);
