import React, { FC } from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';

import { COLOR_GREY } from '../../../../../theme';

import { IrrigationAdminDevice } from '../../admin.irrigation.types';

interface Props {
  device: IrrigationAdminDevice;
}

const DeviceStatus: FC<Props> = ({
  device,
}) => (
  <Tooltip
    disableInteractive
    title={
      <span>
        <FormattedMessage id={device.status === 'INACTIVE' ? 'common.inactive' : 'common.active'} />
      </span>
    }
  >
    {device.status === 'INACTIVE' ? (
      <CancelRoundedIcon style={{ color: COLOR_GREY[400] }} />
    ) : (
      <CheckIcon color="primary" />
    )}
  </Tooltip>
);

export default DeviceStatus;
