import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './IrrigationMap.css';

import { getHoveredId, getSelected } from '../../../core/map/selectors/editor.selectors';
import { getIsFetchingPoints } from '../../../shared/api/irrigation/devices/devices.selectors';
import { getIsAreasList } from '../../selectors/common.selectors';
import { getIsSelecting, getMapColumns, getMapExtent, getPointsBySectionType, getSelectionCoords } from '../../selectors/map.selectors';

import { editorSetHoveredId } from '../../../core/map/actions/editor/editor.actions';
import { storeInitialLayers } from '../../../core/map/actions/layersUI/layersUI.actions';
import { storeServiceWrapper, resetMap, zoomToExtent } from '../../../core/map/actions/map/map.actions';
import { updateCoordsFromMap } from '../../actions/devices.actions';
import { refreshIrrigationStyles, setDefaultExtent, setEnlargedVariant } from '../../actions/map.actions';

import MapHintControl from '../../../core/map/components/MapHintControl/MapHintControl';
import MapLoader from '../../../core/map/components/MapLoader/MapLoader';
import EventListener from '../../../core/map/services/EventListener.service';
import { fetchLayersConfig, resetLayers } from '../../../shared/api/other/layers/layers.api';
import withConfig from '../../../shared/hocs/context/withConfig';
import withFarm from '../../../shared/hocs/context/withFarm';
import SelectMapPositionService from '../../../shared/services/SelectMapPosition.service';
import IrrigationMapService from '../../services/IrrigationMap.service';
import { MAP_ZOOM_SCALE_FACTOR } from '../IrrigationList/devices/IrrigationDevicesList';

const styles = {
  map: {
    height: '100%',
    position: 'relative',
  },
};

export class IrrigationMap extends Component {
  componentDidMount() {
    const { config, farm, history } = this.props;

    this.irrMapService = new IrrigationMapService(
      config,
      farm,
      this.props.editorSetHoveredId,
      history,
      this.props.setEnlargedVariant,
      this.props.setDefaultExtent,
    );
    this.map = this.irrMapService.getMap();
    this.props.fetchLayersConfig(farm.customer.countryCode !== 'CZ');

    this.selectionMapPositionService = new SelectMapPositionService(
      this.map.getMap(),
      this.handleDrawNode,
      this.handleHoverMap);

    const el = new EventListener(this.map.getMap());
    this.el = el;
    this.props.storeServiceWrapper('main', this.map);
    this.props.storeServiceWrapper('el', el);
  }

  componentDidUpdate(prevProps) {
    const {
      hoveredFeatureId,
      isAreasList,
      isSelecting,
      layersConfig: newLayersConfig,
      mapColumns,
      mapExtent,
      points,
      selected,
      selectionCoords,
    } = this.props;

    const {
      hoveredFeatureId: prevHoveredFeatureId,
      layersConfig: prevLayersConfig,
      mapExtent: prevMapExtent,
      selected: prevSelected,
    } = prevProps;

    if (mapColumns !== prevProps.mapColumns) {
      this.map.updateSize();
    }
    if (mapExtent !== prevMapExtent) {
      this.props.zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR);
    }

    if (hoveredFeatureId !== prevHoveredFeatureId) {
      this.props.refreshIrrigationStyles(this.irrMapService.pointsLayer);
    }

    /*
     * Sets layers on the map when:
     * 1) layer config is fetched
     */
    if (!prevLayersConfig.length && newLayersConfig.length) {
      this.layers = this.irrMapService.getLayers();
      this.irrMapService.setLayersWithConfig(newLayersConfig, this.props.storeInitialLayers);
      const style = this.irrMapService.getStyle();
      this.props.storeServiceWrapper('layers', this.layers);
      this.props.storeServiceWrapper('style', style);

      this.layers.addLayer(this.irrMapService.pointsLayer, 'points');
      this.layers.addLayer(this.selectionMapPositionService.getSelectionLayer(), 'selection');

      if (points.length) {
        this.irrMapService.setPointsToLayer(points, isAreasList);
      }
      this.props.refreshIrrigationStyles(this.irrMapService.pointsLayer);
    }

    if (isSelecting !== prevProps.isSelecting) {
      this.selectionMapPositionService.setSelectionMode(isSelecting);
      this.irrMapService.setIsSelecting(isSelecting);

      if (isSelecting) {
        this.props.editorSetHoveredId(null);
        this.props.refreshIrrigationStyles(this.irrMapService.pointsLayer);

        if (selectionCoords) {
          this.selectionMapPositionService.setSelection(selectionCoords);
        } else {
          this.selectionMapPositionService.clearSelection();
        }
      } else {
        this.selectionMapPositionService.selectionModeInactive();
      }
    }

    if (this.irrMapService.pointsLayer && (points !== prevProps.points)) {
      this.irrMapService.setPointsToLayer(points, isAreasList);
      this.props.refreshIrrigationStyles(this.irrMapService.pointsLayer);
    }

    if (this.irrMapService.pointsLayer && !isEqual(selected, prevSelected)) {
      this.props.refreshIrrigationStyles(this.irrMapService.pointsLayer);
    }
  }

  componentWillUnmount() {
    this.props.resetMap();
    this.props.resetLayers();
  }

  handleDrawNode = feature => {
    this.props.updateCoordsFromMap(feature.getGeometry().getCoordinates());
  };

  handleHoverMap = () => {
    if (this.props.isSelecting) {
      this.selectionMapPositionService.displaySelectionCursor(true);
    }
  };

  handleLeaveMap = () => {
    if (this.props.isSelecting) {
      this.selectionMapPositionService.displaySelectionCursor(false);
    }
  };

  render() {
    const { classes, isFetchingPoints } = this.props;
    return (
      <div className={classes.map} id="irrigation-map">
        <MapLoader isFetching={isFetchingPoints} />
        <MapHintControl onMouseEnter={this.handleLeaveMap} />
      </div>
    );
  }
}

IrrigationMap.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  refreshIrrigationStyles: PropTypes.func.isRequired,
  storeServiceWrapper: PropTypes.func.isRequired,
  storeInitialLayers: PropTypes.func.isRequired,
  resetMap: PropTypes.func.isRequired,
  resetLayers: PropTypes.func.isRequired,
  fetchLayersConfig: PropTypes.func.isRequired,
  layersConfig: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  hoveredFeatureId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isFetchingPoints: PropTypes.bool.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  selectionCoords: PropTypes.array,
  selected: PropTypes.array.isRequired,
  setDefaultExtent: PropTypes.func.isRequired,
  updateCoordsFromMap: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  mapColumns: PropTypes.number.isRequired,
  mapExtent: PropTypes.array.isRequired,
  zoomToExtent: PropTypes.func.isRequired,
  isAreasList: PropTypes.bool.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
};

IrrigationMap.defaultProps = {
  geometries: null,
  selectionCoords: [],
  trackClickOrigin: false,
  points: [],
  hoveredFeatureId: '',
};

const mapStateToProps = (state, ownProps) => ({
  layersConfig: state.api.layers.items,
  hoveredFeatureId: getHoveredId(state),
  selected: getSelected(state),
  points: getPointsBySectionType(state, ownProps),
  isFetchingPoints: getIsFetchingPoints(state),
  isSelecting: getIsSelecting(state),
  selectionCoords: getSelectionCoords(state),
  mapColumns: getMapColumns(state),
  mapExtent: getMapExtent(state),
  isAreasList: getIsAreasList(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      storeServiceWrapper,
      storeInitialLayers,
      fetchLayersConfig,
      resetMap,
      resetLayers,
      refreshIrrigationStyles,
      setDefaultExtent,
      updateCoordsFromMap,
      setEnlargedVariant,
      zoomToExtent,
      editorSetHoveredId,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfig(),
  withFarm(),
  withStyles(styles),
)(IrrigationMap);
