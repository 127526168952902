/**
 * Created by ondrejzvara on 21.3.17.
 */

import angular from 'angular';
import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";

import { createAppModule, fetchConfig } from './app.config';
import './assets/less/main.less';

// create and export the main app module
const appModule = createAppModule();
export default appModule.name;

// fetch configuration files asynchronously and inject the included constants, bootstrap the angular afterwards
fetchConfig()
  .then((jsonConfig) => {
    appModule.constant('BACKEND_OPTIONS', jsonConfig.BACKEND_OPTIONS)
    .constant('ENVIRONMENT', jsonConfig.ENVIRONMENT)
    .constant('PRODUCT_FRUITS_ID', jsonConfig.PRODUCT_FRUITS_ID);
    return jsonConfig;
  })
  .then(bootstrapApplication);

function bootstrapApplication(configuration) {
  angular.element(document).ready(() => {
    configureSentry(configuration);
    angular.bootstrap(document, ['app'], {
      strictDi: true
    });
    $.material.init();
  });
}

function configureSentry(configuration) {
  const nodeEnv = process.env.NODE_ENV;
  console.info("APP VERSION:",process.env.BUILD_INFO_APP_COMMIT)
  Sentry.init({
    dsn: configuration.BACKEND_OPTIONS.sentryDsn,
    integrations: [new AngularIntegration()],
    environment: configuration.ENVIRONMENT,
    release: process.env.BUILD_INFO_APP_VERSION,
    beforeSend(event) {
      // send only in production, by returning null, it discards the event
      if (nodeEnv !== 'production') {
        return null;
      }
      return event;
    }
  });
}
