import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  radarPlaceholder: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  forecastIconPlaceholder: {
    width: 42,
    height: 42,
    borderRadius: '50%',
  },
  placeholderItem: {
    backgroundColor: theme.palette.grey[300],
  },
  placeholderButton: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    margin: 5,
  },
  placeholderTimeline: {
    height: 18,
    margin: 20,
    borderRadius: 9,
    flex: 1,
  },
});

function WeatherRadarPlaceholder(props) {
  const { classes } = props;
  return (
    <div className={classes.radarPlaceholder}>
      <div className={`${classes.placeholderItem} ${classes.placeholderButton}`} />
      <div className={`${classes.placeholderItem} ${classes.placeholderTimeline}`} />
    </div>
  );
}

WeatherRadarPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WeatherRadarPlaceholder);
