import React from 'react';

import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as sensorsStatus from '../../../shared/constants/sensorsStatus.constants';

import SensorsService from '../../../shared/services/Sensors.service';

const styles = {
  statusIcon: {
    width: 16,
    height: 16,
  },
  iconHover: {
    '&:hover $statusIconHover': {
      visibility: 'visible',
    },
    position: 'relative',
    display: 'flex',
    padding: 4,
    width: 24,
    height: 24,
  },
  statusIconHover: {
    visibility: 'hidden',
    position: 'absolute',
    width: 16,
    height: 16,
    color: 'rgba(51, 51, 51, 0.3)',
  },
};

function NodeLocationStatus(props) {
  const { classes, nodeLocation } = props;

  const isHistoric = SensorsService.isHistoric(nodeLocation);
  const nodeLocationStatus = isHistoric ? sensorsStatus.HISTORICAL : nodeLocation.status;
  const iconColor = SensorsService.getLocationStatusColor(nodeLocation);

  return (
    <Tooltip
      placement="bottom"
      classes={{
        tooltip: classes.tooltip,
      }}
      title={
        <span>
          <FormattedMessage id={`SensorsStatus.${nodeLocationStatus}`} />
        </span>
      }
    >
      <span className={classes.iconHover}>
        <FiberManualRecord className={classes.statusIconHover} />
        <FiberManualRecord className={classes.statusIcon} data-test={'status'} style={{ color: iconColor }} />
      </span>
    </Tooltip>
  );
}

NodeLocationStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object,
};

NodeLocationStatus.defaultProps = {
  nodeLocation: {},
};

export default withStyles(styles)(NodeLocationStatus);
