import React, { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import chlorophyllImage from '../../../../../../assets/img/satellite/chlorophyll.png';
import chlorophyllZonesImage from '../../../../../../assets/img/satellite/chlorophyll_zones.png';
import leafAreaImage from '../../../../../../assets/img/satellite/LAI.png';
import leafAreaZonesImage from '../../../../../../assets/img/satellite/LAI_zones.png';
import waterImage from '../../../../../../assets/img/satellite/water.png';
import waterZonesImage from '../../../../../../assets/img/satellite/water_zones.png';
import CfLazyImage from '../../../../../../shared/components/common/CfLazyImage/CfLazyImage';
import SatelliteImagesInfoDialog from '../../../shared/components/SatelliteImagesInfoDialog/SatelliteImagesInfoDialog';
import SatelliteInfoDialogItem from '../../../shared/components/SatelliteInfoDialogItem/SatelliteInfoDialogItem';
import BioMonitoringLegend from '../BioMonitoringLegend/BioMonitoringLegend';
import BioMonitoringZonesLegend from '../BioMonitoringZonesLegend/BioMonitoringZonesLegend';

const images = {
  CAB: chlorophyllImage,
  CW: waterImage,
  LAI: leafAreaImage,
};

const zonesImages = {
  CAB: chlorophyllZonesImage,
  CW: waterZonesImage,
  LAI: leafAreaZonesImage,
};

const styles = theme => ({
  image: {
    minWidth: '150px',
    maxWidth: '150px',
    height: 'auto',
    imageRendering: 'pixelated',
    '&': {
      // fallback for FF
      imageRendering: '-moz-crisp-edges',
    },
    marginRight: 25,
  },
  itemLegendWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  legend: {
    margin: '0 5px',
  },
  legendInfo: {
    color: theme.palette.grey[500],
    marginTop: 10,
  },
  [theme.breakpoints.down('sm')]: {
    image: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  zonesLegend: {
    lineHeight: 1.8,
  },
});

export const BioMonitoringHistoryInfoDialog = props => {
  const { classes, index } = props;

  return (
    <SatelliteImagesInfoDialog>
      <Fragment>
        <SatelliteInfoDialogItem label={<FormattedMessage id={`BioMonitoring.${index}`} />} testId="index">
          <Fragment>
            <CfLazyImage
              alt="Monitoring exemplar source image"
              classes={{ custom: classes.image }}
              src={images[index]}
              testId="info-dialog-source-image"
            />
            <div>
              <BioMonitoringLegend index={index} />
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
        <SatelliteInfoDialogItem label={<FormattedMessage id="BioMonitoringInfoDialog.zoning" />} testId="zones">
          <Fragment>
            <CfLazyImage
              alt="Monitoring exemplar zoning image"
              classes={{ custom: classes.image }}
              src={zonesImages[index]}
              testId="info-dialog-zoning-image"
            />
            <div className={classes.itemLegendWrapper}>
              <div className={classes.zonesLegend}>
                <BioMonitoringZonesLegend index={index} />
              </div>
              <div className={classes.legendInfo}>
                <Typography>
                  <FormattedMessage id={`BioMonitoringInfoDialog.zoningExplanation.${index}`} />
                </Typography>
              </div>
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
      </Fragment>
    </SatelliteImagesInfoDialog>
  );
};

BioMonitoringHistoryInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.string,
};

BioMonitoringHistoryInfoDialog.defaultProps = {
  index: null,
};

export default withStyles(styles)(BioMonitoringHistoryInfoDialog);
