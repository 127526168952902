import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const IrrigationOffIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 34" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 31.6431L25.4833 27.1264L17.8167 19.4598L9.2 10.8431L6.7 8.3431L2.11667 3.75977L0 5.87643L4.63333 10.5098C2.65 12.9431 1.66667 15.9598 1.66667 18.8598C1.66667 22.1931 2.96667 25.7098 5.56667 28.3098C8.16667 30.9098 11.5833 32.2264 15 32.2264C17.9667 32.2264 20.95 31.2264 23.3833 29.2598L27.8833 33.7598L30 31.6431ZM5 19.4598C5.01667 16.6264 5.75 14.6764 7.13333 13.0098L13.5833 19.4598H5Z" />
      <path d="M15 4.90964L22.0666 12.2096C23.9667 14.0763 24.9833 16.1263 25 19.4596H22.05L27.0833 24.493C27.9167 22.693 28.3333 20.743 28.3333 18.8596C28.3333 15.5263 27.0333 12.0596 24.4333 9.45964L15 0.0429688L8.81665 6.2263L11.3 8.70964L15 4.90964Z" />
    </svg>
  </SvgIcon>
);

export default IrrigationOffIcon;
