import React, { FC, useEffect } from 'react';

import { LinearProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTelematicsStatus } from '../../../shared/api/telematics/sectionStatus/sectionStatus.actions';
import { fetchOperations, fetchProductionOperations } from '../../actions/telematicsList.actions';

import * as services from '../../../shared/constants/services.constants';
import { TELEMATICS_URLS } from '../../telematics.constants';

import { isTelematicSectionAllowed, isTelematicSectionVerified } from '../../../shared/api/telematics/sectionStatus/sectionStatus.selector';
import CfStatusPanel from '../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import ServiceNotPurchased from '../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased';
import { links } from '../../../shared/constants/links';
import TelematicsIcon from '../../../shared/icons/navbar/TelematicsIcon';
import { Thunk } from '../../../types';
import TelematicsDetail from '../TelematicsDetail/TelematicsDetail';
import TelematicsList from '../TelematicsList/TelematicsList';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriverTo, MachineTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  noTelematics: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

interface Props {
  farmId: string;
  fetchOperations(): void;
  fetchProductionOperations(): void;
  getTelematicsStatus(): void,
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo,
  isSectionAllowed: boolean,
  isVerified: boolean;
  langId: string,
  ngRedirectToMainMapWithFilters: (
    dateFrom?: string, dateTo?: string, machine?: MachineTo[], driver?: DriverTo[]
  ) => void
}

const Telematics: FC<Props> = ({
  farmId,
  fetchOperations,
  fetchProductionOperations,
  getTelematicsStatus,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  isSectionAllowed,
  isVerified,
  langId,
  ngRedirectToMainMapWithFilters,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getTelematicsStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSectionAllowed || !isVerified) return;
    fetchOperations();
    fetchProductionOperations();
  }, [isSectionAllowed, fetchProductionOperations, fetchOperations, langId, isVerified]);

  if (!isVerified) {
    return <LinearProgress color="primary" style={{ marginTop: 8 }} />;
  }

  if (isSectionAllowed) {
    return (
      <Switch>
        <Route
          exact
          path={`/farm/:farmId/${TELEMATICS_URLS.main}`}
          render={() =>
            <TelematicsList
              farmId={farmId}
              initDateFrom={initDateFrom}
              initDateTo={initDateTo}
              initMachineFilter={initMachineFilter}
              langId={langId}
              ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
            />}
        />
        <Route
          exact
          path={`/farm/:farmId/${TELEMATICS_URLS.detail}`}
          render={() => <TelematicsDetail farmId={farmId} langId={langId} />}
        />
      </Switch>
    );
  }
  return (
    <div className={classes.noTelematics}>
      <PageHeader
        classes={{ header: classes.header }}
        heading={<PageHeading translationId="common.telematics" />}
          />
      <CfStatusPanel
        customContent={<ServiceNotPurchased serviceId={services.TELEMATICS} />}
        icon={TelematicsIcon}
        linkHref={langId === 'cs-CZ' ? links.cs.telematics : links.en.telematics}
        linkText={<FormattedMessage id="common.findOutMore" />}
        testId="no-telematics"
        title={<FormattedMessage id="Telematics.notPurchased.title" />}
        titleWithIcon={true}
          />
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isSectionAllowed: isTelematicSectionAllowed(state),
  isVerified: isTelematicSectionVerified(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchOperations,
      fetchProductionOperations,
      getTelematicsStatus,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Telematics);
