import React from 'react';

import { withStyles } from '@mui/styles';
import { times } from 'lodash';
import PropTypes from 'prop-types';

import CfLoadingPlaceholder from '../../../../shared/components/common/CfLoadingPlaceholder/CfLoadingPlaceholder';

const styles = theme => ({
  weatherPlaceholder: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    height: '100%',
    alignItems: 'center',
    margin: '0px 20px',
  },
  weatherItemPlaceholder: {
    height: '100%',
    display: 'flex',
    margin: '0px 10px',
    alignItems: 'center',
  },
  placeholderItem: {
    backgroundColor: theme.palette.grey[300],
  },
  placeholderIcon: {
    width: 63,
    height: 63,
    borderRadius: '50%',
    margin: 10,
  },
  placeholderText: {
    height: 18,
    margin: 2,
    borderRadius: 9,
  },
});

function WeatherCurrentlyPlaceholder(props) {
  const { classes } = props;
  return (
    <div className={classes.weatherPlaceholder}>
      <div className={`${classes.placeholderItem} ${classes.placeholderIcon}`} />
      <div className={classes.weatherItemPlaceholder}>
        <div>
          <CfLoadingPlaceholder />
          <CfLoadingPlaceholder />
        </div>
      </div>
      {times(2, i => (
        <div className={classes.weatherItemPlaceholder} key={i}>
          <div>
            <CfLoadingPlaceholder />
            <CfLoadingPlaceholder width={115} />
            <CfLoadingPlaceholder />
          </div>
        </div>
      ))}
    </div>
  );
}

WeatherCurrentlyPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WeatherCurrentlyPlaceholder);
