import React, { Component, Fragment } from 'react';

import NavigationIcon from '@mui/icons-material/Navigation';
import PropTypes from 'prop-types';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import { CHART_TYPES } from '../../services/DeviceConfig.service';

const style = {
  icon: {
    fontSize: 15,
    verticalAlign: 'text-bottom',
  },
};

export default class ChartPreviewInfo extends Component {
  renderCheckedValue = (sensorValue, color, unit) => {
    const { decimalDigits } = this.props;
    return (
      <span style={{ color }}>
        {isNaN(parseFloat(sensorValue)) ? (
          '-'
        ) : (
          <Fragment>
            {<CfFormattedNumber decimalDigits={decimalDigits} value={sensorValue || 0} />}
            {unit ? ` ${unit}` : ''}
          </Fragment>
        )}
      </span>
    );
  };

  render() {
    const {
      chart: { lastUpdateUnit, name, stroke: color, unit, value },
    } = this.props;

    if (Array.isArray(value)) {
      return (
        <span>
          {this.renderCheckedValue(value[0], color)}
          <span> &#8211; </span>
          {this.renderCheckedValue(value[1], color, unit)}
        </span>
      );
    }

    if (name === CHART_TYPES.CHART_WIND) {
      return (
        <span>
          {this.renderCheckedValue(value.FEATURE_WIND_SPEED_AVG, color, unit)}
          <span style={{ color: '#BDDED6' }}> {'\u00A0|\u00A0'} </span>
          {this.renderCheckedValue(value.FEATURE_WIND_GUST_AVG, color, unit)}
          <span style={{ color: '#BDDED6' }}> {'\u00A0|\u00A0'} </span>
          <span style={{ color }}>
            <NavigationIcon style={style.icon} transform={`rotate(${value.FEATURE_WIND_DIRECTION_MAX_COUNT * 45})`} />
          </span>
        </span>
      );
    }

    if (typeof value !== 'object' && isNaN(parseFloat(value))) {
      return <span style={{ color }}>-</span>;
    }

    const displayUnit = lastUpdateUnit || unit;

    return this.renderCheckedValue(value, color, displayUnit);
  }
}

ChartPreviewInfo.propTypes = {
  decimalDigits: PropTypes.number,
  chart: PropTypes.object.isRequired,
};

ChartPreviewInfo.defaultProps = {
  decimalDigits: 1,
};
