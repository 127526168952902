import React, { Component, Fragment } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSelectedParcels,
  getParcelsSuggestions,
  getLastCurrentSeedDate,
  getValidFrom,
  getValidFromError,
} from '../../selectors/editor.selectors';

import { editorToolEnd, editorToolStart, setValidFrom, validateValidFrom } from '../../actions/editor/editor.actions';
import { onMouseClick } from '../../actions/split/split.actions';
import { refreshSplitStyles } from '../../actions/style/style.actions';

import { NO_CROP_LEGISLATIVE_CODE } from '../../constants/crops.constants';
import * as stages from '../../constants/stages.constants';

import { getParcelsMapActionable, resetParcelsMapActionable } from '../../../../shared/api/core/parcels/parcels.api';
import MapDatePicker from '../../components/MapDatePicker/MapDatePicker';
import SelectParcelForm from '../../components/SelectParcelForm/SelectParcelForm';
import ToolbarBtnCancel from '../../components/ToolbarBtnCancel/ToolbarBtnCancel';
import ToolbarBtnSubmit from '../../components/ToolbarBtnSubmit/ToolbarBtnSubmit';

class SplitToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { lastCurrentSeedDate, validFrom } = this.props;
    if (
      validFrom !== prevProps.validFrom ||
      (moment.isMoment(lastCurrentSeedDate) && !lastCurrentSeedDate.isSame(prevProps.lastCurrentSeedDate))
    ) {
      this.props.validateValidFrom();
    }
  }

  onSuggestionFetch = textFilter => {
    this.props.getParcelsMapActionable({
      ...(textFilter && textFilter.length ? { localNameBlockNr: textFilter } : {}),
    });
  };

  onSuggestionClear = () => {
    this.props.resetParcelsMapActionable();
  };

  onSuggestionsReset = () => {
    this.props.resetParcelsMapActionable();
  };

  onParcelSelect = suggestion => {
    const feature = {
      get(prop) {
        return this[prop];
      },
      id: suggestion.id,
      local_name: suggestion.localName,
      crop_legislative_code: NO_CROP_LEGISLATIVE_CODE,
      seed_date_start: suggestion.currentSeedDate,
    };
    this.props.onMouseClick(feature).then(() => {
      this.props.refreshSplitStyles();
    });
  };

  onParcelRemove = () => {
    this.props.editorToolEnd('SPLIT');
    this.props.editorToolStart('SPLIT');
  };

  render() {
    const {
      error,
      langId,
      lastCurrentSeedDate,
      onCancel,
      onSubmit,
      parcels,
      stage,
      suggestions,
      validFrom,
      validFromError,
    } = this.props;

    return (
      <Fragment>
        <SelectParcelForm
          disabled={stage !== stages.STAGE_1}
          langId={langId}
          onParcelRemove={this.onParcelRemove}
          onParcelSelect={this.onParcelSelect}
          onSuggestionClear={this.onSuggestionClear}
          onSuggestionFetch={this.onSuggestionFetch}
          onSuggestionsReset={this.onSuggestionsReset}
          parcels={parcels}
          suggestions={suggestions}
        />
        {stage !== stages.STAGE_1 && (
          <MapDatePicker
            error={!!validFromError}
            minDate={lastCurrentSeedDate}
            setDate={this.props.setValidFrom}
            validFrom={validFrom}
          />
        )}
        <span>
          <ToolbarBtnCancel onCancel={onCancel} />
          <ToolbarBtnSubmit
            disabled={stage !== stages.STAGE_3 || !!error || !!validFromError}
            onSubmit={onSubmit}
            translationId={'tools.SPLIT'}
          />
        </span>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  parcels: getSelectedParcels(state),
  suggestions: getParcelsSuggestions(state),
  lastCurrentSeedDate: getLastCurrentSeedDate(state),
  validFrom: getValidFrom(state),
  validFromError: getValidFromError(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getParcelsMapActionable,
      resetParcelsMapActionable,
      onMouseClick,
      refreshSplitStyles,
      editorToolEnd,
      editorToolStart,
      setValidFrom,
      validateValidFrom,
    },
    dispatch,
  );

SplitToolbar.propTypes = {
  langId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getParcelsMapActionable: PropTypes.func.isRequired,
  onMouseClick: PropTypes.func.isRequired,
  refreshSplitStyles: PropTypes.func.isRequired,
  editorToolEnd: PropTypes.func.isRequired,
  resetParcelsMapActionable: PropTypes.func.isRequired,
  editorToolStart: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  stage: PropTypes.string,
  error: PropTypes.string,
  parcels: PropTypes.array,
  lastCurrentSeedDate: PropTypes.object,
  setValidFrom: PropTypes.func.isRequired,
  validFrom: PropTypes.string,
  validateValidFrom: PropTypes.func.isRequired,
  validFromError: PropTypes.string,
};

SplitToolbar.defaultProps = {
  stage: null,
  error: null,
  parcels: [],
  lastCurrentSeedDate: null,
  validFrom: null,
  validFromError: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitToolbar);
