export const GET_ADMIN_PARCELS = 'GET_ADMIN_PARCELS';
export const GET_ADMIN_PARCELS_SUCCESS = 'GET_ADMIN_PARCELS_SUCCESS';
export const GET_ADMIN_PARCELS_ERROR = 'GET_ADMIN_PARCELS_ERROR';
export const ADD_PARCELS_SERVICES = 'ADD_PARCELS_SERVICES';
export const ADD_PARCELS_SERVICES_SUCCESS = 'ADD_PARCELS_SERVICES_SUCCESS';
export const ADD_PARCELS_SERVICES_ERROR = 'ADD_PARCELS_SERVICES_ERROR';
export const UPDATE_PARCEL_SERVICES = 'UPDATE_PARCEL_SERVICES';
export const UPDATE_PARCEL_SERVICES_SUCCESS = 'UPDATE_PARCEL_SERVICES_SUCCESS';
export const UPDATE_PARCEL_SERVICES_ERROR = 'UPDATE_PARCEL_SERVICES_ERROR';
export const RESET_ADMIN_PARCELS = 'RESET_ADMIN_PARCELS';
