import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isDialogOpen } from '../../../shared/selectors/manager.selectors';
import { getSentinelAdminFarm } from '../../selectors/sentinel.selectors';

import {
  setDialogOpen,
  setActionButtonEnabled,
  setSensorsTabActive,
  setActionButtonHidden,
} from '../../../shared/actions/manager.actions';
import { fetchActiveParcels, setActiveParcels, setFarmFilter } from '../../actions/sentinel.actions';

import { NAMESPACE as namespace } from '../../reducer/sentinel.reducer';

import CfTextFilter from '../../../../../shared/containers/CfTextFilter/CfTextFilter';
import FarmSwitcher from '../../../shared/containers/FarmSwitcher/FarmSwitcher';
import SentinelAdminParcelsDialog from '../SentinelAdminParcelsDialog/SentinelAdminParcelsDialog';
import SentinelAdminTable from '../SentinelAdminTable/SentinelAdminTable';

const styles = {
  sentinelAdmin: {
    fontWeight: 500,
  },
  switchers: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  tableHeaderContainer: {
    paddingBottom: 8,
  },
};

export class SentinelAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeParcel: null,
    };
    props.setActionButtonHidden(false);
    props.setActionButtonEnabled(Boolean(props.farm));
    props.setSensorsTabActive(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.farm && (!prevProps.farm || this.props.farm.id !== prevProps.farm.id)) {
      this.props.setActionButtonEnabled(true);
      this.props.fetchActiveParcels();
    }

    if (!this.props.farm && prevProps.farm) {
      this.props.setActionButtonEnabled(false);
    }

    if (!this.props.dialogOpen && prevProps.dialogOpen) {
      this.setState({
        activeParcel: null,
      });
    }
  }

  openDialogWithParcel = activeParcel => {
    this.setState({
      activeParcel,
    });
    this.props.setDialogOpen(true);
  };

  handleDialogAccept = (adding, parcelsSettings) => {
    const { farm } = this.props;
    this.props.setDialogOpen(false);
    this.props.setActiveParcels(adding, farm.id, parcelsSettings);
  };

  render() {
    const { classes, dialogOpen, farm, langId } = this.props;
    const { activeParcel } = this.state;
    return (
      <div>
        <SentinelAdminParcelsDialog
          onAccept={this.handleDialogAccept}
          onClose={() => this.props.setDialogOpen(false)}
          opened={dialogOpen}
          parcel={activeParcel}
        />
        <Grid alignItems="center" className={classes.sentinelAdmin} container spacing={2}>
          <Grid item xs={12}>
            <Grid className={classes.tableHeaderContainer} container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <CfTextFilter
                  id="sentinel-admin-text-filter"
                  namespace={namespace}
                  translId="SentinelAdmin.blockNrLocalName"
                />
              </Grid>
              <Grid item md={4} sm={1} xs={3} />
              <Grid className={classes.switchers} item md={4} sm={5} xs={9}>
                <FarmSwitcher farm={farm} onFarmSelect={this.props.setFarmFilter} />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <SentinelAdminTable farm={farm} langId={langId} openParcelDialog={this.openDialogWithParcel} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SentinelAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  fetchActiveParcels: PropTypes.func.isRequired,
  setActiveParcels: PropTypes.func.isRequired,
  setFarmFilter: PropTypes.func.isRequired,
  farm: PropTypes.object,
  setDialogOpen: PropTypes.func.isRequired,
  setActionButtonEnabled: PropTypes.func.isRequired,
  setSensorsTabActive: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setActionButtonHidden: PropTypes.func.isRequired,
};

SentinelAdmin.defaultProps = {
  farm: null,
};

const mapStateToProps = state => ({
  farm: getSentinelAdminFarm(state),
  dialogOpen: isDialogOpen(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActiveParcels,
      setActiveParcels,
      setFarmFilter,
      setDialogOpen,
      setActionButtonEnabled,
      setSensorsTabActive,
      setActionButtonHidden,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SentinelAdmin));
