import React, { FC, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getError } from '../../../../../shared/api/core/parcels/parcels.selectors';
import { getParcelListTextFilter, getParcelListSelectedOnPage } from '../../selectors/parcelList.selectors';

import { setTableSelected } from '../../../../../shared/actions/table.actions';
import { createZone, updateZone, deleteZone, assignZone, assignCenter } from '../../../shared/actions/parcels.actions';
import { fetchParcels } from '../../actions/parcelList.actions';

import { NAMESPACE as namespace } from '../../reducer/parcelList.reducer';

import CfErrorPage from '../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import withWidth from '../../../../../shared/hocs/withWidth';
import { AsyncFn, Thunk } from '../../../../../types';
import ParcelListActions from '../../components/ParcelListActions/ParcelListActions';
import ParcelListTextFilter from '../../components/ParcelListTextFilter/ParcelListTextFilter';
import ParcelFilter from '../ParcelFilter/ParcelFilter';
import ParcelTable from '../ParcelTable/ParcelTable';
import ParcelZoneStatistics from '../ParcelZoneStatistics/ParcelZoneStatistics';

import { ParcelsState, Zone } from '../../../../../shared/api/core/parcels/parcels.types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  textFilter: {
    maxWidth: 600,
    flexGrow: 1,
    order: 2,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%',
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 3,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  listActionsItem: {
    order: 1,
  },
  actionsFilterWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  filtersHeader: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
}));

export interface ParcelListProps {
  assignCenter: (centerId: string, payload: string[]) => void,
  assignZone: (zoneId: string, parcelId: string) => Promise<void>,
  createZone: (zone: Zone) => void,
  deleteZone: (zone: Zone) => void,
  error: object,
  fetchParcels: () => void;
  langId: string,
  ngRedirectToEph: (selectedOnPage: string[]) => void
  ngRedirectToHarvest: (selectedOnPage: string[]) => void
  ngRedirectToMowing: (selectedOnPage: string[]) => void
  ngRedirectToOtherActionsNew: (selectedOnPage: string[]) => void
  ngRedirectToParcelDetail: (parcelId: string) => void
  ngRedirectToSowing: (selectedOnPage: string[]) => void
  selectedOnPage: string[],
  setTableSelected: (selected: string[], namespace: string) => void,
  textFilter: string,
  updateZone: (zone: Zone) => void,
}

export const ParcelList: FC<ParcelListProps> = ({
  assignCenter,
  assignZone,
  createZone,
  deleteZone,
  error,
  fetchParcels,
  langId,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToParcelDetail,
  ngRedirectToSowing,
  selectedOnPage,
  setTableSelected,
  textFilter,
  updateZone,
}) => {
  const classes = useStyles();
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() =>
    () => {
      setTableSelected([], namespace);
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const onAssignZone = (zoneId: string, parcelIds: string[], bulk: boolean) => {
    const promises = [] as Promise<void>[];
    (Array.isArray(parcelIds) ? parcelIds : [parcelIds]).forEach((parcelId: string) => {
      promises.push(assignZone(zoneId, parcelId));
    });
    Promise.all(promises).then(() => {
      setShouldReloadData(!shouldReloadData);
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onAssignCenter = (centerId: string, payload: string[], bulk: boolean) => {
    (assignCenter as AsyncFn<string, string[]>)(centerId, payload).then(() => {
      setShouldReloadData(!shouldReloadData);
      fetchParcels();
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onUpdateZone = (zone: Zone) =>
    (updateZone as AsyncFn<Zone>)(zone).then(() => setShouldReloadData(!shouldReloadData));

  const onDeleteZone = (zone: Zone) =>
    (deleteZone as AsyncFn<Zone>)(zone).then(() => setShouldReloadData(!shouldReloadData));

  const onCreateZone = (zone: Zone) => createZone(zone);

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <PageHeader
          heading={<PageHeading dataTest="parcelDetail-heading" value={<FormattedMessage id="common.parcels" />} />}
          classes={{
            header: classes.header,
          }}
          />
        <Grid container spacing={1}>
          <Grid item lg={9} md={8} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className={classes.filtersHeader}>
                  <div className={classes.textFilter}>
                    <ParcelListTextFilter namespace={namespace} textFilter={textFilter} />
                  </div>
                  <div className={classes.actionsFilterWrapper}>
                    <div className={classes.listActionsItem}>
                      <ParcelListActions
                        ngRedirectToEph={() => ngRedirectToEph(selectedOnPage)}
                        ngRedirectToHarvest={() => ngRedirectToHarvest(selectedOnPage)}
                        ngRedirectToMowing={() => ngRedirectToMowing(selectedOnPage)}
                        ngRedirectToOtherActionsNew={() => ngRedirectToOtherActionsNew(selectedOnPage)}
                        ngRedirectToSowing={() => ngRedirectToSowing(selectedOnPage)}
                        onAssignCenter={onAssignCenter}
                        onAssignZone={onAssignZone}
                        selected={selectedOnPage}
                        />
                    </div>
                    <div className={classes.advancedFilter}>
                      <ParcelFilter langId={langId} namespace={namespace} />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <ParcelTable
                  langId={langId}
                  ngRedirectToParcelDetail={ngRedirectToParcelDetail}
                  onAssignCenter={onAssignCenter}
                  onAssignZone={onAssignZone}
                  shouldReloadData={shouldReloadData}
                  />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} md={4} xs={12}>
            <Grid container spacing={0}>
              <ParcelZoneStatistics
                langId={langId}
                onCreateZone={onCreateZone}
                onDeleteZone={onDeleteZone}
                onUpdateZone={onUpdateZone}
                shouldReloadData={shouldReloadData}
                />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  textFilter: getParcelListTextFilter(state),
  selectedOnPage: getParcelListSelectedOnPage(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      createZone,
      updateZone,
      deleteZone,
      assignZone,
      assignCenter,
      fetchParcels,
      setTableSelected,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ParcelList));
