import React from 'react';

export default contextName => {
  const Context = React.createContext(null);

  if (process.env.NODE_ENV !== 'production') {
    Context.displayName = `${contextName}Context`;
  }

  return Context;
};
