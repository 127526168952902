import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WeatherCurrentlyIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M12,6.88A5.12,5.12,0,1,0,17.12,12,5.12,5.12,0,0,0,12,6.88Zm0,8.41A3.29,3.29,0,1,1,15.29,12,3.29,3.29,0,0,1,12,15.29Z" />
      <rect height="3.19" width="1.82" x="11.16" y="2.18" />
      <rect height="3.19" width="1.82" x="11.16" y="18.62" />
      <rect height="1.82" width="3.19" x="18.62" y="11.1" />
      <rect height="1.82" width="3.19" x="2.18" y="11.1" />
      <rect height="3.19" transform="translate(-2.51 6.21) rotate(-45)" width="1.82" x="5.33" y="4.54" />
      <rect height="3.19" transform="translate(-7.32 17.84) rotate(-45)" width="1.82" x="16.95" y="16.16" />
      <rect height="1.82" transform="translate(0.84 14.42) rotate(-45)" width="3.19" x="16.23" y="5.28" />
      <rect height="1.82" transform="translate(-10.79 9.6) rotate(-45)" width="3.19" x="4.6" y="16.91" />
    </svg>
  </SvgIcon>
);

export default WeatherCurrentlyIcon;
