/**
 * Created by ondrejzvara on 24.3.17.
 */


import angular from 'angular';
import ObjTpl from './ObjTpl.service';

export default angular.module('app.misc', [])
    .factory('ObjTpl', ObjTpl)
    .name;
