import React, { FC, useState, useCallback, useEffect } from 'react';

import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { StaticDateRangePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DateRange } from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { getNumberOfDays } from '../../../../sensors/actions/node.actions';

import usePopover from '../../../hooks/usePopover';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: (lightStyle) => (lightStyle ? 28 : 32),
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 16,
  },
  dateButton: {
    background: (lightStyle) => (lightStyle ? theme.palette.common.white : theme.palette.grey[300]),
    padding: 0,
    borderRadius: 50,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  dateContent: {
    margin: 'auto 8px',
    paddingTop: 1,
  },
  icon: {
    margin: 'auto 12px auto 6px',
    width: 22,
  },
  popoverPaper: {
    overflow: 'visible',
    marginTop: 7,
  },
  rightPart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    textAlign: 'left',
    padding: '0 16px',
  },
  dateRangePicker: {
    '& .MuiTypography-subtitle1': {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 260,
    },
    '& .css-f7iyql': {
      padding: 0,
    },
  },
}));

const rangeShortcuts = {
  today: 'TODAY',
  yesterday: 'YESTERDAY',
  lastWeek: 'LAST_WEEK',
  lastMonth: 'LAST_MONTH',
  last7Days: 'LAST_7_DAYS',
  last30Days: 'LAST_30_DAYS',
};

type RangeShortcutType = keyof typeof rangeShortcuts;

const rangeShortcutsLabel = [
  {
    range: rangeShortcuts.today,
    label: <FormattedMessage id="common.dateRangeSelector.today" />,
  },
  {
    range: rangeShortcuts.yesterday,
    label: <FormattedMessage id="common.dateRangeSelector.yesterday" />,
  },
  {
    range: rangeShortcuts.lastWeek,
    label: <FormattedMessage id="common.dateRangeSelector.lastWeek" />,
  },
  {
    range: rangeShortcuts.lastMonth,
    label: <FormattedMessage id="common.dateRangeSelector.lastMonth" />,
  },
  {
    range: rangeShortcuts.last7Days,
    label: <FormattedMessage id="common.dateRangeSelector.last7Days" />,
  },
  {
    range: rangeShortcuts.last30Days,
    label: <FormattedMessage id="common.dateRangeSelector.last30Days" />,
  },
];

interface Props {
  dateFrom: string;
  dateTo: string;
  lightStyle?: boolean;
  minDate?: string;
  setDateFrom: (date?: string) => void;
  setDateTo: (date?: string) => void;
}

const DateRangeSelector: FC<Props> = ({
  dateFrom,
  dateTo,
  lightStyle = false,
  minDate = '2010-01-01T00:00:00.000Z',
  setDateFrom,
  setDateTo,
}) => {
  const classes = useStyles(lightStyle);
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } = usePopover();
  const [selectedDate, setSelectedDate] = useState<DateRange<Moment>>([null, null]);
  // Use react key attribute to re-mount the StaticDateRangePicker component: https://github.com/mui/mui-x/issues/4542
  const [key, setKey] = useState(0);

  const numberOfSelectedDays = getNumberOfDays(dateFrom, dateTo);

  useEffect(() => {
    setSelectedDate([
      moment(dateFrom),
      moment(dateTo),
    ]);
  }, [dateFrom, dateTo]);

  const handleRangeClick = useCallback((range: RangeShortcutType) => {
    const today = moment();
    switch (range) {
      case rangeShortcuts.today:
        setSelectedDate([
          moment().startOf('day'),
          today,
        ]);
        break;
      case rangeShortcuts.yesterday:
        setSelectedDate([
          moment().subtract('1', 'day').startOf('day'),
          moment().subtract('1', 'day').endOf('day'),
        ]);
        setKey(key + 1);
        break;
      case rangeShortcuts.lastWeek:
        setSelectedDate([
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ]);
        setKey(key + 1);
        break;
      case rangeShortcuts.lastMonth:
        setSelectedDate([
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ]);
        setKey(key + 1);
        break;
      case rangeShortcuts.last7Days:
        setSelectedDate([
          moment().subtract('6', 'days'),
          today,
        ]);
        setKey(key + 1);
        break;
      case rangeShortcuts.last30Days:
        setSelectedDate([
          moment().subtract('29', 'days'),
          today,
        ]);
        setKey(key + 1);
        break;
      default:
        break;
    }
  }, [setSelectedDate, key],
  );

  const handleDateSelectAccept = () => {
    const newDateFrom = selectedDate[0];
    const newDateTo = selectedDate[1] ?? selectedDate[0];
    setDateFrom(moment(newDateFrom).startOf('day').toISOString());
    setDateTo(moment(newDateTo).endOf('day').toISOString());
    setSelectedDate([newDateFrom, newDateTo]);
    handlePopoverClose();
  };

  const handleDateSelectCancel = () => {
    setSelectedDate([
      moment(dateFrom),
      moment(dateTo),
    ]);
    handlePopoverClose();
  };

  return (
    <>
      <Button
        className={classes.dateButton}
        onClick={handlePopoverOpen}
        variant="contained"
      >
        <div className={classes.wrapper}>
          <span className={classes.dateContent} data-test={'telematics-date'}>
            <FormattedDate day="2-digit" month="2-digit" value={dateFrom} year="numeric" />
            {numberOfSelectedDays > 1 && (
              <>
                {' – '}
                <FormattedDate day="2-digit" month="2-digit" value={dateTo} year="numeric" />
              </>
            )}
          </span>
          <InsertInvitationOutlinedIcon className={classes.icon} data-test={'telematics-calendar'} />
        </div>
      </Button>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        >
        <Box
          display="flex"
          key={key}
          mb={2}
          mt={2}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              className={classes.dateRangePicker}
              disableFuture
              displayStaticWrapperAs="desktop"
              minDate={moment(minDate)}
              onChange={(newValue) => setSelectedDate(newValue)}
              renderInput={() => <div />}
              value={selectedDate}
            />
          </LocalizationProvider>

          <Divider flexItem orientation="vertical" />
          <div className={classes.rightPart}>
            <List>
              {rangeShortcutsLabel.map(({ label, range }) => (
                <ListItem disablePadding key={range}>
                  <ListItemButton
                    onClick={() => handleRangeClick(range as RangeShortcutType)}
                    >
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        fontSize: 14,
                      }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box className={classes.buttonGroup}>
              <Button
                color="inherit"
                onClick={handleDateSelectCancel}
                sx={{ mr: 1 }}
                variant="contained"
                >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                color="primary"
                onClick={handleDateSelectAccept}
                variant="contained"
                >
                <FormattedMessage id="common.select" />
              </Button>
            </Box>
          </div>
        </Box>
      </Popover>
    </>
  );
};

export default DateRangeSelector;
