export const GET_ADMIN_FARMS = 'GET_ADMIN_FARMS';
export const GET_ADMIN_FARMS_SUCCESS = 'GET_ADMIN_FARMS_SUCCESS';
export const GET_ADMIN_FARMS_ERROR = 'GET_ADMIN_FARMS_ERROR';
export const CREATE_FARM_NOTIFICATIONS = 'CREATE_FARM_NOTIFICATIONS';
export const CREATE_FARM_NOTIFICATIONS_SUCCESS = 'CREATE_FARM_NOTIFICATIONS_SUCCESS';
export const CREATE_FARM_NOTIFICATIONS_ERROR = 'CREATE_FARM_NOTIFICATIONS_ERROR';
export const UPDATE_FARM_NOTIFICATIONS = 'UPDATE_FARM_NOTIFICATIONS';
export const UPDATE_FARM_NOTIFICATIONS_SUCCESS = 'UPDATE_FARM_NOTIFICATIONS_SUCCESS';
export const UPDATE_FARM_NOTIFICATIONS_ERROR = 'UPDATE_FARM_NOTIFICATIONS_ERROR';
export const RESET_ADMIN_FARMS = 'RESET_ADMIN_FARMS';
