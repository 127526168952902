import React, { Component } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import {
  getAccount,
  getIsFetchingAccount,
  getIsSavingAccount,
  getSavingAccountError,
} from '../../../../../shared/api/gateway/accounts/accounts.selectors';

import { fetchAccount, saveAccount } from '../../actions/profile.actions';

import CfSnackbar from '../../../../../shared/components/common/CfSnackbar/CfSnackbar';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import ProfileForm from '../../components/ProfileForm/ProfileForm';

const styles = theme => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 360,
  },
  formButtons: {
    margin: '0px 12px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
  progress: {
    marginLeft: 10,
  },
});

const FORM_NAME = 'profile';

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      savingError: false,
      savingSuccess: false,
    };
    this.props.fetchAccount();
  }

  componentDidUpdate(prevProps) {
    const { account, isSaving, savingError } = this.props;
    if (!prevProps.account && account) {
      this.setState({
        initialized: true,
      });
    }
    if (account && account !== prevProps.account) {
      this.props.initialize(account);
    }
    if (prevProps.isSaving && !isSaving) {
      if (savingError) {
        this.setState({
          savingError: true,
        });
      } else {
        this.setState({
          savingSuccess: true,
        });
      }
    }
  }

  saveAccount = this.props.handleSubmit(values => {
    this.props.saveAccount(values);
  });

  handleSnackbarClose = () => {
    this.setState({
      savingSuccess: false,
      savingError: false,
    });
  };

  render() {
    const { change, classes, isFetching, isSaving, pristine, reset } = this.props;
    const { initialized, savingError, savingSuccess } = this.state;

    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <PageHeader
            classes={{ header: classes.header }}
            heading={<PageHeading value={<FormattedMessage id="Profile.myProfile" />} />}
          />
          {!initialized ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <form>
              <ProfileForm changeField={change} disabled={isFetching || isSaving} />
            </form>
          )}
          <div className={classes.formButtons}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  data-test={'cancel'}
                  disabled={pristine || isFetching || isSaving}
                  fullWidth
                  onClick={reset}
                  variant="contained"
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  data-test={'save'}
                  disabled={pristine || isFetching || isSaving}
                  fullWidth
                  onClick={this.saveAccount}
                  variant="contained"
                >
                  <FormattedMessage id="common.save" />
                  {(isFetching || isSaving) && initialized && (
                    <CircularProgress className={classes.progress} size={20} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <CfSnackbar
          isError={savingError}
          isSuccess={savingSuccess}
          onClose={this.handleSnackbarClose}
          open={savingSuccess || savingError}
          message={
            (savingSuccess && <FormattedMessage id="Profile.saveSuccess" />) ||
            (savingError && <FormattedMessage id="Profile.saveError" />)
          }
        />
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  saveAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  savingError: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  account: null,
  savingError: null,
};

const mapStateToProps = state => ({
  account: getAccount(state),
  isFetching: getIsFetchingAccount(state),
  isSaving: getIsSavingAccount(state),
  savingError: getSavingAccountError(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccount,
      saveAccount,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME,
  })(withStyles(styles)(Profile)),
);
