import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

// https://mui.com/components/use-media-query/#migrating-from-withwidth
const useWidth = () => {
  const theme = useTheme();
  if (!theme) {
    return 'xs';
  }

  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export default useWidth;
