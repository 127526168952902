/**
 * Created by ondrejzvara on 29.3.17.
 */



import angular from 'angular';
import tpl from './por.html';
import PorController from './por.controller';


export default angular.module('app.components.farm.classifiers.private.pors.por', [])
    .controller('PorController', PorController)
    .config(config)
    .name;


/*@ngInject*/
function config($stateProvider) {

    $stateProvider
         .state('farm.active.classifiers.private.pors.por', {
             url: '/{por}',
                views: {
                    'farm@farm.active': {
                    controller: "PorController as PorCtrl",
                    template: tpl
                }
            },
            resolve:   {
                porIdName:  function($stateParams) {
                    return $stateParams.por;
                }
            }
         });
}