import React, { Component } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import { getIsSavingPassword, getSavingPasswordError } from '../../../../../shared/api/gateway/accounts/accounts.selectors';

import { changePassword } from '../../../../../shared/api/gateway/accounts/accounts.api';
import CfSnackbar from '../../../../../shared/components/common/CfSnackbar/CfSnackbar';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import PasswordChangeForm from '../../components/PasswordChangeForm/PasswordChangeForm';

const styles = theme => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 360,
  },
  formButtons: {
    margin: '0px 12px',
  },
  progress: {
    marginLeft: 10,
  },
});

const FORM_NAME = 'password_change';

export class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      savingError: false,
      savingSuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isSaving, savingError } = this.props;
    if (prevProps.isSaving && !isSaving) {
      if (savingError) {
        this.setState({
          savingError: true,
        });
      } else {
        this.setState({
          savingSuccess: true,
        });
      }
    }
  }

  handleSnackbarClose = () => {
    this.setState({
      savingSuccess: false,
      savingError: false,
    });
  };

  changePassword = this.props.handleSubmit(values => {
    this.props.changePassword(values).then(() => {
      this.props.reset();
    });
  });

  render() {
    const { classes, isSaving } = this.props;
    const { savingError, savingSuccess } = this.state;

    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <PageHeader
            classes={{ header: classes.header }}
            heading={<PageHeading value={<FormattedMessage id="PasswordChange.changePassword" />} />}
          />
          <form>
            <PasswordChangeForm disabled={isSaving} />
          </form>
          <div className={classes.formButtons}>
            <Grid container spacing={3}>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Button
                  color="primary"
                  data-test={'save'}
                  disabled={isSaving}
                  fullWidth
                  onClick={this.changePassword}
                  variant="contained"
                >
                  <FormattedMessage id="PasswordChange.changePassword" />
                  {isSaving && <CircularProgress className={classes.progress} size={20} />}
                </Button>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </div>
        </div>
        <CfSnackbar
          isError={savingError}
          isSuccess={savingSuccess}
          onClose={this.handleSnackbarClose}
          open={savingSuccess || savingError}
          message={
            (savingSuccess && <FormattedMessage id="PasswordChange.changeSuccess" />) ||
            (savingError && <FormattedMessage id="PasswordChange.changeError" />)
          }
        />
      </div>
    );
  }
}

PasswordChange.propTypes = {
  classes: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  savingError: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

PasswordChange.defaultProps = {
  savingError: null,
};

const mapStateToProps = state => ({
  isSaving: getIsSavingPassword(state),
  savingError: getSavingPasswordError(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME,
  })(withStyles(styles)(PasswordChange)),
);
