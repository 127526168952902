import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import DateTime from '../../../shared/services/DateTime.service';
import { FEATURE_TYPE_PREFIX } from '../../services/FeatureConfig.service';
import FeatureData from '../../services/FeatureData.service';

const styles = theme => ({
  wrapper: {
    fontSize: 13,
    marginLeft: 13,
    marginRight: 13,
  },
  itemLabel: {
    color: theme.palette.grey[500],
  },
  itemValue: {
    marginLeft: 3,
    fontWeight: 500,
  },
});

function SensorChartCumulativeRainfall(props) {
  const { chartProps, classes, duration, granularity } = props;
  const item = FeatureData.getLastCumulativeRainfall(chartProps.data, duration);
  const dateStart = FeatureData.getStartOfCumulativeRainfall(item?.dateTime, granularity);
  const dateEnd = DateTime.endDateTimeByDuration(granularity, item?.dateTime);
  return (
    <div className={classes.wrapper}>
      {chartProps && item && (
        <span>
          <span className={classes.itemLabel}>
            <FormattedMessage id={`NodeFeature.${item.featureName.replace(FEATURE_TYPE_PREFIX, '')}`} />{' '}
            {dateStart && dateEnd && (
              <Fragment>
                {'('}
                <FormattedDate day="numeric" hour="numeric" minute="numeric" month="numeric" value={dateStart} />
                <span> &#8211; </span>
                <FormattedDate day="numeric" hour="numeric" minute="numeric" month="numeric" value={dateEnd} />
                {')'}
              </Fragment>
            )}
          </span>
          <span className={classes.itemValue} style={{ color: chartProps.stroke }}>
            <CfFormattedNumber decimalDigits={1} value={item.value || 0} /> {chartProps.unit}
          </span>
        </span>
      )}
    </div>
  );
}

SensorChartCumulativeRainfall.propTypes = {
  classes: PropTypes.object.isRequired,
  chartProps: PropTypes.object,
  duration: PropTypes.number.isRequired,
  granularity: PropTypes.string.isRequired,
};

SensorChartCumulativeRainfall.defaultProps = {
  chartProps: {},
};

export default withStyles(styles)(SensorChartCumulativeRainfall);
