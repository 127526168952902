import get from 'lodash/get';

export const getActions = state => state.api.actions.items;
export const getIsFetching = state => state.api.actions.isFetching;
export const getError = state => state.api.actions.error;
export const getTotalCount = state => state.api.actions.totalCount;

export const getAction = state => state.api.actions.item;
export const getIsFetchingAction = state => state.api.actions.isFetchingAction;

export const getValidationFailure = state => get(state.api.actions.validationError, 'response');

export const isDeletingActions = state => state.api.actions.isDeleting;
