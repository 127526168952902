import * as commonTableTypes from '../../shared/actions/table.constants';
import * as sortOrder from '../../shared/constants/sortOrder.constants';

export const NAMESPACE = 'groups';

export default (reducerNamespace = NAMESPACE) => (
  state = {
    order: sortOrder.DESC,
    orderBy: 'count',
  },
  action,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case commonTableTypes.SET_TABLE_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case commonTableTypes.SET_TABLE_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
      };
    default:
      return state;
  }
};
