import React from 'react';

import { Grid, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = {
  paper: {
    padding: 40,
    margin: 40,
  },
};

const VariableFertilizationExpired = props => (
  <Paper className={props.classes.paper}>
    <Grid alignItems={'center'} container justifyContent={'center'} spacing={2}>
      <Grid item xs={12}>
        <Typography align="center" gutterBottom variant="h5">
          <FormattedMessage id="VariableFertilization.inactive-subscription-heading" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography align="center" gutterBottom variant="body1">
          <FormattedMessage id="VariableFertilization.inactive-subscription-text" />
        </Typography>
      </Grid>

      <Grid item>
        <Button color="primary" onClick={props.onSubscribe} type="submit" variant="contained">
          <FormattedMessage id="VariableFertilization.inactive-subscription-btn" />
        </Button>
      </Grid>
    </Grid>
  </Paper>
);

VariableFertilizationExpired.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};

export default withStyles(styles)(VariableFertilizationExpired);
