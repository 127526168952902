import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsEditing } from '../../selectors/editor.selectors';
import {
  getMainMapTelematicsDriverFilter,
  getMainMapTelematicsMachineFilter,
} from '../../selectors/mainMapTelematics.selectors';
import {
  getParcelDetail,
  isParcelDetailFetching,
  getContext,
  getIsFetching,
  getMainMap,
  getLayers } from '../../selectors/map.selectors';
import { getWeatherType } from '../../selectors/weather.selectors';

import { setLangId } from '../../../../shared/actions/lang.actions';
import {
  fetchMachinePositions,
  fetchMachineDrivesHistory,
  resetMachineDrivesHistory,
} from '../../actions/mainMapTelematics/mainMapTelematics.actions';
import {
  clearParcelDetail,
  updateParcelName,
  zoomToFarm,
  initMapSaga,
} from '../../actions/map/map.actions';
import { setWeatherType } from '../../actions/weather/weather.actions';

import './MainMap.css';

import { PREVIEW, TELEMATICS, WEATHER } from '../../constants/contexts.constants';

import SectionWrapper from '../../../../shared/components/specific/SectionWrapper/SectionWrapper';
import withConfig from '../../../../shared/hocs/context/withConfig';
import withWidth from '../../../../shared/hocs/withWidth';
import HomeControl from '../../components/HomeControl/HomeControl';
import LayersCropsControl from '../../components/LayersCropsControl/LayersCropsControl';
import MapLoader from '../../components/MapLoader/MapLoader';
import MapParcelDetail from '../../components/MapParcelDetail/MapParcelDetail';
import TelematicsToolbar from '../../components/TelematicsToolbar/TelematicsToolbar';
import WeatherSwitcher from '../../components/WeatherSwitcher/WeatherSwitcher';
import ContextSwitcher from '../ContextSwitcher/ContextSwitcher';
import EditorToolbar from '../EditorToolbar/EditorToolbar';
import MainMapTelematicsDetail from '../MainMapTelematicsDetail/MainMapTelematicsDetail';
import MapSearch from '../MapSearch/MapSearch';
import MeasurementToolbar from '../MeasurementToolbar/MeasurementToolbar';
import WeatherControl from '../WeatherControl/WeatherControl';

export const MAP_ID = 'main-map';
export const MAP_SRID_ID = '3857';
export const DATA_SRID_ID = '4326';

const styles = theme => ({
  mainMap: {
    height: '100%',
    position: 'relative',
  },
  editorSectionWrapper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 350px)',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  contextSwitcherSectionWrapper: {
    flexDirection: 'column',
  },
});

export const transformOptions = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};

const MACHINE_POSITIONS_REFRESH_PERIOD = 90_000;

export class MainMap extends Component {
  componentDidMount() {
    const { config, countryCode, farmId, langId, parcelId } = this.props;

    this.props.initMapSaga(farmId, langId, countryCode, config.api, parcelId);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.props.resetMachineDrivesHistory();
  }

  componentDidUpdate(prevProps) {
    const { context, langId } = this.props;

    if (prevProps.mainMap !== this.props.mainMap) {
      this.main = this.props.mainMap;
    }
    if (prevProps.layers !== this.props.layers) {
      this.layers = this.props.layers;
    }

    if (prevProps.langId !== langId) {
      this.props.setLangId(langId);
    }

    // eslint-disable-next-line max-len
    const userHasSwitchToTelematicsContext = prevProps.context !== TELEMATICS && context === TELEMATICS;
    // eslint-disable-next-line max-len
    const userHasSwitchFromTelematicsContext = prevProps.context === TELEMATICS && context !== TELEMATICS;

    if (userHasSwitchToTelematicsContext) {
      this.interval = setInterval(() => {
        if (this.props.context === TELEMATICS) {
          this.props.fetchMachinePositions();
          if (this.props.machineFilter.length || this.props.driverFilter.length) {
            this.props.fetchMachineDrivesHistory();
          }
        }
      }, MACHINE_POSITIONS_REFRESH_PERIOD);
    } else if (userHasSwitchFromTelematicsContext) {
      clearInterval(this.interval);
    }
  }

  render() {
    const {
      classes,
      context,
      countryCode,
      farmId,
      initDateFrom,
      initDateTo,
      initDriverFilter,
      initMachineFilter,
      isEditing,
      isFetching,
      langId,
      ngRedirectToTelematics,
      parcelDetail,
      parcelDetailFetching,
      weatherType,
      width,
    } = this.props;
    return (
      <div className={classes.mainMap} id={MAP_ID}>
        <HomeControl width={width} zoomToHome={this.props.zoomToFarm} />

        <SectionWrapper right={15} top={15}>
          <MapSearch langId={this.props.langId} />
        </SectionWrapper>

        <SectionWrapper
          bottom={15}
          classes={{ custom: classes.contextSwitcherSectionWrapper }}
          left={15}
          right={'unset'}
        >
          <ContextSwitcher langId={this.props.langId} />
        </SectionWrapper>

        <SectionWrapper left={14} top={17}>
          <LayersCropsControl />
        </SectionWrapper>

        <SectionWrapper
          classes={{ custom: classes.editorSectionWrapper }}
          left={width === 'xs' || width === 'sm' ? 15 : 59}
          top={width === 'xs' || width === 'sm' ? 59 : 17}
        >
          {context === PREVIEW && <EditorToolbar countryCode={countryCode} langId={langId} />}
          {context === TELEMATICS &&
            <TelematicsToolbar
              initDateFrom={initDateFrom}
              initDateTo={initDateTo}
              initDriverFilter={initDriverFilter}
              initMachineFilter={initMachineFilter}
              langId={langId}
            />
          }
          {context === WEATHER &&
            <WeatherSwitcher
              setWeatherType={this.props.setWeatherType}
              weatherType={weatherType}
            />
          }
        </SectionWrapper>

        {context === WEATHER && <WeatherControl langId={langId} />}

        {context === TELEMATICS &&
          <MainMapTelematicsDetail
            langId={langId}
            ngRedirectToTelematics={ngRedirectToTelematics}
          />
        }

        {context === PREVIEW && (
          <Fragment>
            {!isEditing && parcelDetail && (
              <MapParcelDetail
                clearParcelDetail={this.props.clearParcelDetail}
                countryCode={countryCode}
                farmId={farmId}
                isFetching={parcelDetailFetching}
                parcel={parcelDetail}
                updateParcelName={this.props.updateParcelName}
              />
            )}
            <SectionWrapper right={15} top={59} zIndex={8}>
              <MeasurementToolbar />
            </SectionWrapper>
          </Fragment>
        )}

        <MapLoader isFetching={isFetching} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isEditing: getIsEditing(state),
  isFetching: getIsFetching(state),
  parcelDetail: getParcelDetail(state),
  parcelDetailFetching: isParcelDetailFetching(state),
  weatherType: getWeatherType(state),
  context: getContext(state),
  machineFilter: getMainMapTelematicsMachineFilter(state),
  driverFilter: getMainMapTelematicsDriverFilter(state),
  mainMap: getMainMap(state),
  layers: getLayers(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateParcelName,
      clearParcelDetail,
      zoomToFarm,
      setLangId,
      setWeatherType,
      fetchMachinePositions,
      fetchMachineDrivesHistory,
      resetMachineDrivesHistory,
      initMapSaga,
    },
    dispatch,
  );

MainMap.propTypes = {
  updateParcelName: PropTypes.func.isRequired,
  clearParcelDetail: PropTypes.func.isRequired,
  zoomToFarm: PropTypes.func.isRequired,
  parcelDetail: PropTypes.object,
  parcelDetailFetching: PropTypes.bool,
  config: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setLangId: PropTypes.func.isRequired,
  weatherType: PropTypes.string.isRequired,
  setWeatherType: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  parcelId: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  initDateFrom: PropTypes.string,
  initDateTo: PropTypes.string,
  initDriverFilter: PropTypes.array,
  initMachineFilter: PropTypes.array,

  fetchMachinePositions: PropTypes.func.isRequired,
  fetchMachineDrivesHistory: PropTypes.func.isRequired,
  resetMachineDrivesHistory: PropTypes.func.isRequired,
  ngRedirectToTelematics: PropTypes.func.isRequired,
  initMapSaga: PropTypes.func.isRequired,

  mainMap: PropTypes.object,
  layers: PropTypes.object,
};

MainMap.defaultProps = {
  parcelDetail: undefined,
  parcelDetailFetching: false,
  layersConfig: [],
  parcelId: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withConfig(), withStyles(styles), withWidth())(MainMap));
