import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import SensorGroup from '../../../shared/components/specific/SensorGroup/SensorGroup';
import PestPrediction from '../PestPrediction/PestPrediction';

const styles = () => ({
  nodeDetailSensors: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  sensorInfo: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 4,
  },
});

function NodeLastValues(props) {
  const { classes, hideValues, isActive, isDetail, isHistoric, node, pestPredictions, totalEvapotranspiration } = props;
  return (
    <Fragment>
      <SensorGroup
        displayValues={!isHistoric && !hideValues}
        isActive={isActive}
        isDetail={isDetail}
        node={node}
        totalEvapotranspiration={totalEvapotranspiration}
      />
      {(isActive || isHistoric) &&
        pestPredictions
          .filter(pestPrediction => pestPrediction.visible)
          .map(pestPrediction => (
            <span className={classes.sensorInfo} key={pestPrediction.id}>
              <PestPrediction displayValues={!isHistoric && !hideValues} pestPrediction={pestPrediction} />
            </span>
          ))}
    </Fragment>
  );
}

NodeLastValues.propTypes = {
  node: PropTypes.object,
  isActive: PropTypes.bool,
  isHistoric: PropTypes.bool,
  classes: PropTypes.object,
  pestPredictions: PropTypes.array,
  isDetail: PropTypes.bool,
  hideValues: PropTypes.bool,
  totalEvapotranspiration: PropTypes.number,
};

NodeLastValues.defaultProps = {
  node: {},
  isActive: true,
  isHistoric: false,
  classes: {},
  nodeLocation: {},
  pestPredictions: [],
  isDetail: true,
  hideValues: false,
  totalEvapotranspiration: 0,
};

export default withStyles(styles)(NodeLastValues);
