import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import HorizontalScrolling from '../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling';
import HorizontalScrollingItem from '../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem';
import Localization from '../../../../shared/services/Localization.service';
import Weather from '../../services/Weather.service';

import WeatherCurrentlyPlaceholder from './WeatherCurrentlyPlaceholder';

const styles = theme => ({
  weatherCurrently: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  weatherSummary: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 30,
    alignSelf: 'center',
  },
  sectionRowLabel: {
    color: theme.palette.grey[500],
  },
  sectionRowValue: {
    fontWeight: 500,
  },
  sectionColumn: {
    marginRight: 15,
  },
  weatherSection: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 15,
  },
  nowColumn: {
    marginRight: 15,
  },
  weatherIconDiv: {
    display: 'flex',
    height: '100%',
    marginRight: 20,
    alignItems: 'center',
  },
  weatherInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 15,
  },
  weatherIcon: {
    width: 48,
    height: 48,
  },
});

class WeatherCurrently extends Component {
  renderWeatherSection = sectionRows => (
    <div className={this.props.classes.weatherSection}>
      <div className={this.props.classes.sectionColumn}>
        {sectionRows.map(row => (
          <div className={this.props.classes.sectionRowLabel} key={`${row.label}-label`}>
            {row.label ? <FormattedMessage id={row.label} /> : '\u00A0'}
          </div>
        ))}
      </div>
      <div className={this.props.classes.sectionColumn}>
        {sectionRows.map(row => (
          <div className={this.props.classes.sectionRowValue} key={`${row.label}-value`}>
            {row.value ? row.value : '\u00A0'}
          </div>
        ))}
      </div>
    </div>
  );

  render() {
    const { classes, currently, intl, isFetching } = this.props;
    return (
      <div className={classes.weatherCurrently} data-test="weather-currently-data">
        {isFetching ? (
          <WeatherCurrentlyPlaceholder />
        ) : (
          <HorizontalScrolling>
            <HorizontalScrollingItem>
              <div className={classes.weatherIconDiv}>
                <img alt="weather icon" className={classes.weatherIcon} src={Weather.getWeatherIcon(currently.icon)} />
              </div>
            </HorizontalScrollingItem>
            <HorizontalScrollingItem>
              <div className={classes.weatherSummary}>
                <div>{Math.round(currently.temperature)} °C</div>
                <div>{currently.summary}</div>
              </div>
            </HorizontalScrollingItem>
            <HorizontalScrollingItem>
              {this.renderWeatherSection([
                {
                  label: 'WeatherCurrently.precipitation',
                  value: `${Localization.num2str(currently.precipIntensity, intl.locale, 1)} mm/h`,
                },
                { label: 'WeatherCurrently.humidity', value: `${Math.round(currently.humidity * 100)} %` },
                {
                  label: 'WeatherCurrently.dewPoint',
                  value: `${Localization.num2str(currently.dewPoint, intl.locale, 1)} °C`,
                },
              ])}
            </HorizontalScrollingItem>
            <HorizontalScrollingItem>
              {this.renderWeatherSection([
                { label: 'WeatherCurrently.pressure', value: `${Math.round(currently.pressure)} hPa` },
                { label: 'WeatherCurrently.wind', value: `${Math.round(currently.windSpeed)} m/s` },
                { label: 'WeatherCurrently.visibility', value: `${Math.round(currently.visibility)} km` },
              ])}
            </HorizontalScrollingItem>
          </HorizontalScrolling>
        )}
      </div>
    );
  }
}

WeatherCurrently.propTypes = {
  intl: PropTypes.object.isRequired,
  currently: PropTypes.object,
  classes: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
};

WeatherCurrently.defaultProps = {
  currently: {},
  classes: {},
};

export default injectIntl(withStyles(styles)(WeatherCurrently));
