import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfSwitcher from '../../../../../../shared/components/common/CfSwitcher/CfSwitcher';

const styles = theme => ({
  cropName: {
    color: theme.palette.grey[500],
    display: 'inline-block',
  },
  dateInterval: {
    textTransform: 'lowercase',
    marginRight: 6,
  },
});

function CropIntervalSwitcher(props) {
  const { classes, cropIntervals, onMenuItemClick } = props;
  return (
    <CfSwitcher
      arrowsShift={true}
      getItemId={item => item.id}
      getItemValue={item => item.id}
      items={cropIntervals}
      onMenuItemClick={item => onMenuItemClick(item.from, item.to)}
      testId={'crop-interval-switcher'}
      getItemViewValue={item => (
        <span>
          <span className={classes.dateInterval}>
            <FormattedDate month="short" value={item.from} year="numeric" />
            {' \u2013 '}
            {item.to ? (
              <FormattedDate month="short" value={item.to} year="numeric" />
            ) : (
              <FormattedMessage id="common.now" />
            )}
          </span>{' '}
          <span className={classes.cropName}>{item.crop.name}</span>
        </span>
      )}
    />
  );
}

CropIntervalSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  cropIntervals: PropTypes.array.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CropIntervalSwitcher);
