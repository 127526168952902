import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ImageNotSupportedIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M18.55,15.73c1.69-3.24,1.67-8.2,1.28-11.69-3.33-.35-8.31-.29-11.58,1.38Z" />
      <path d="M2.13,2.12h0L.72,3.53,5.27,8.08a6.85,6.85,0,0,0,.6,8.78,7.31,7.31,0,0,0,0,4.53l.1.37,2.44-.87-.11-.34a4.85,4.85,0,0,1-.23-1.9,6.7,6.7,0,0,0,7.79,0l4.64,4.64,1.41-1.4Z" />
    </svg>
  </SvgIcon>
);

export default ImageNotSupportedIcon;
