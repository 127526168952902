/**
 * Created by ondrejzvara on 2.11.15.
 */

/* @ngInject */
export default function FarmsResource(Api, ResourceUtils, FarmsStorage) {
  const baseFarmAccountAll = Api.setBaseAll('account/farms');

  const service = {
    getFarm,
    getFarmBoundingBox,
    getEagriFarmName,
    getFarms,
    createFarm,
    validateFarm,
    createEagriFarm,
    createEmptyFarm
  };
  return service;

  // ///////////////////////

  function getFarm(code) {
    const baseOne = Api.setBaseOne('farms', code);
    return Api.getItem(baseOne);
  }

  function getFarmBoundingBox(farmId) {
    const baseOne = Api.setBaseOne('farms');
    return Api.getItem(baseOne, { id: farmId }).then(result => result.boundingBox);
  }

  function getEagriFarmName() {
    const baseFarmAll = Api.setBaseAll('farms/eagri/name');
    return Api.customGet(baseFarmAll, '', {}, null);
  }

  function getFarms() {
    return Api.getItems(baseFarmAccountAll, {}).then((result) => {
      FarmsStorage.setFarms(result.data.plain());
      return result.data.plain();
    });
  }

  function createEmptyFarm(params) {
    const baseFarmAll = Api.setBaseAll('farms');
    return Api.customPost(baseFarmAll, null, null, params, true);
  }

  function createFarm(files, params) {
    const baseFarmAll = Api.setBaseAll('farms/file');
    return ResourceUtils.uploadFile(baseFarmAll, files, null, params);
  }

  function validateFarm(files, params) {
    const baseFarmAll = Api.setBaseAll('farms/file/validate');
    return ResourceUtils.uploadFile(baseFarmAll, files, null, params);
  }

  function createEagriFarm(params) {
    const baseFarmAll = Api.setBaseAll('farms/eagri');
    return Api.customPost(baseFarmAll, null, null, params, true);
  }
}
