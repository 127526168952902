import React from 'react';

const CloseCircleIconFilled = () => (
  <svg fill="none" height="33" viewBox="0 0 33 33" width="33" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="16.7319" cy="16.1855" fill="#EEEEEE" r="12" />
    </g>
    <path
      d="M21.7845 12.8321L18.416 16.1858L21.7845 19.5395L20.1003 21.2163L16.7318 17.8627L13.3634 21.2163L11.6792 19.5395L15.0476 16.1858L11.6792 12.8321L13.3634 11.1553L16.7318 14.509L20.1003 11.1553L21.7845 12.8321Z"
      fill="#848484"
    />
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="32"
        id="filter0_d"
        width="32"
        x="0.731934"
        y="0.185547"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" mode="normal" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default CloseCircleIconFilled;
