import React, { FC, ReactNode } from 'react';

import { connect } from 'react-redux';

import { getIsFetchingProductionOperations, getProductionOperations } from '../../../../shared/api/telematics/drives/drives.selectors';

import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { ProductionOperationTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
  defaultValues?: ProductionOperationTo;
  isFetching: boolean;
  label: ReactNode;
  onChange(item: ProductionOperationTo): void;
  options: ProductionOperationTo[];
}

const ProductionOperationSelector: FC<Props> = (
  { defaultValues = [],
    options,
    isFetching,
    label,
    onChange,
  }) => (
    <CfAutocomplete
      defaultValues={defaultValues}
      id="production-operation-selector"
      isFetching={isFetching}
      label={label}
      onChange={onChange}
      suggestions={options}
      testId="production-operation-filter"
    />
);

const mapStateToProps = (state: TelematicsState) => ({
  options: getProductionOperations(state),
  isFetching: getIsFetchingProductionOperations(state),
});

export default connect(mapStateToProps)(ProductionOperationSelector);
