import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/parcels.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import ParcelsWrapper from './shared/containers/ParcelsWrapper/ParcelsWrapper';

ParcelsController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Localization',
  'Auth',
  'ResponseErrorInterceptor',
  '$timeout',
  '$transitions',
];

export default function ParcelsController(
  $element,
  $scope,
  $state,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
  $timeout,
  $transitions,
) {
  const props = {
    langId: null,
    ngRedirectToEph,
    ngRedirectToSowing,
    ngRedirectToOtherActionsNew,
    ngRedirectToHarvest,
    ngRedirectToVrf,
    ngRedirectToVrs,
    ngRedirectToMowing,
    ngRedirectToParcelDetail,
    ngGoToParcels,
    ngGoToAction,
    ngGoToMainMap,
    reducer,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      langId: null,
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
    cacheKey: 'parcelList',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  const deregisterOnTransitionSuccess = $transitions.onBefore({}, transition => {
    // preventing page reload after push to history by react router and going away from parcel detail
    const parcelDetailState = 'farm.active.parcels.detail';
    if (transition.from().name.startsWith(parcelDetailState) && transition.to().name.startsWith(parcelDetailState)) {
      transition.abort();
    }
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  };

  function ngRedirectToEph(parcelIds) {
    $state.go('farm.active.actions.eph', { parcelIds });
  }

  function ngRedirectToSowing(parcelIds) {
    $state.go('farm.active.actions.sowing', { parcelIds });
  }

  function ngRedirectToOtherActionsNew(parcelIds) {
    $state.go('farm.active.actions.otherNew', { parcelIds });
  }

  function ngRedirectToHarvest(parcelIds) {
    $state.go('farm.active.actions.harvest', { parcelIds });
  }

  function ngRedirectToParcelDetail(parcelId) {
    $state.go('farm.active.parcels.detail.overview', { parcelId });
  }

  function ngGoToParcels(farmId) {
    $state.go('farm.active.parcels', { farmId });
  }

  function ngGoToAction(actionId, route) {
    return $state.go(`farm.active.${route}`, { actionId });
  }

  function ngGoToMainMap(farmId, parcelId) {
    $state.go('farm.active.map', { farmId, parcelId });
  }

  function ngRedirectToVrf(parcelIds) {
    return $state.go('farm.active.actions.vrf', { parcelIds });
  }

  function ngRedirectToVrs(parcelIds) {
    return $state.go('farm.active.actions.vrs', { parcelIds });
  }

  function ngRedirectToMowing(parcelIds) {
    $state.go('farm.active.actions.mowing', { parcelIds });
  }

  const render = () => {
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <ParcelsWrapper {...p} />} />, $element[0]);
  };
}
