import React, { FC, useEffect, useState } from 'react';

import { Divider, List } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MainMapTelematicsMachineSelectorGroupItem from './MainMapTelematicsMachineSelectorGroupItem';

import { MachineGroupTo, MachineTo } from '../../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
  },
}));

interface MachinesByGroup {
    [index: string]: MachineTo[];
  }

interface MainMapTelematicsMachineSelectorGroupProps {
  keysMachinesGroup: string[],
  machinesByGroup: MachinesByGroup,
  machinesGroups: MachineGroupTo[];
  onChangeMachineFilter(items: MachineTo[], checked: boolean): void;
  selectedValues: MachineTo[];
}

const MainMapTelematicsMachineSelectorGroup: FC<MainMapTelematicsMachineSelectorGroupProps> = ({
  keysMachinesGroup,
  machinesByGroup,
  machinesGroups,
  onChangeMachineFilter,
  selectedValues,
}) => {
  const classes = useStyles();

  const [openGroupState, setOpenGroupState] = useState<boolean[]>([]);

  useEffect(() => {
    setOpenGroupState(new Array(keysMachinesGroup.length).fill(false));
  }, [keysMachinesGroup.length]);

  const handleOpenGroup = (position: number) => {
    const updatedOpenGroupState = openGroupState.map((item, index) =>
      (index === position ? !item : false),
    );
    setOpenGroupState(updatedOpenGroupState);
  };

  return (
    <List
      aria-labelledby="machines-filter"
      className={classes.list}
      dense
      >
      <Divider />
      {keysMachinesGroup.map((key, index) => {
        const groupName = machinesGroups.find(item => item.code === key)?.value;
        return (
          <MainMapTelematicsMachineSelectorGroupItem
            group={key}
            groupName={groupName}
            handleOpenGroup={() => handleOpenGroup(index)}
            key={key}
            machinesInGroup={machinesByGroup[key]}
            onChangeMachineFilter={onChangeMachineFilter}
            openGroupState={openGroupState[index]}
            selectedValues={selectedValues}
          />);
      })}
    </List>
  );
};

export default MainMapTelematicsMachineSelectorGroup;
