import React from 'react';

import Avatar from '@mui/material/Avatar';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import NavbarService from '../../services/NavbarService';

const styles = theme => ({
  mainAvatar: {
    fontWeight: 700,
    margin: 10,
    backgroundColor: theme.palette.secondary.main,
    border: '2px solid',
    borderColor: theme.palette.primary.dark,
    color: 'inherit',
  },
  mainAvatarDesktop: {
    height: 24,
    width: 24,
    fontSize: 14,
  },
  mainAvatarMobile: {
    height: 26,
    width: 26,
    fontSize: 16,
  },
  greyAvatar: {
    backgroundColor: theme.palette.grey[500],
    color: '#FFFFFF',
    height: 20,
    width: 20,
    fontSize: 14,
    fontWeight: 700,
  },
});

function UserAvatar(props) {
  const { classes, grey, mobile, user } = props;
  const username = NavbarService.getUsername(user);
  return (
    <Avatar
      className={
        grey
          ? classes.greyAvatar
          : `${classes.mainAvatar} ${mobile ? classes.mainAvatarMobile : classes.mainAvatarDesktop}`
      }
    >
      {username.charAt(0).toUpperCase()}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  grey: PropTypes.bool,
  mobile: PropTypes.bool,
};

UserAvatar.defaultProps = {
  mobile: false,
  grey: false,
};

export default withStyles(styles)(UserAvatar);
