import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 50,
    marginBottom: 20,
  },
  text: {
    width: '100%',
    fontSize: 16,
  },
  boldText: {
    fontWeight: 700,
  },
  gutter: {
    marginBottom: 20,
  },
  button: {
    alignItems: 'first baseline',
    borderRadius: 21,
    color: '#fff',
    margin: '0 auto',
    boxShadow: '0px 8px 8px 0px rgba(0,0,0,0.36)',
    lineHeight: '24px',
  },

});

const PlusIcon = () => (<span>+</span>);

const EmptyAreas = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleCreateNewArea = () => {
    history.push('./area/create');
  };

  return (
    <Box className={classes.container}>
      <Typography align="center" className={classnames(classes.text, classes.boldText)}>
        <FormattedMessage id="Irrigation.emptyAreas.message.main" />
      </Typography>
      <Typography align="center" className={classnames(classes.text, classes.gutter)}>
        <FormattedMessage id="Irrigation.emptyAreas.message.sub" />
      </Typography>
      <Button
        aria-label="create irrigation area"
        className={classes.button}
        color="secondary"
        onClick={handleCreateNewArea}
        startIcon={<PlusIcon />}
        variant="contained"
      >
        <FormattedMessage id="Irrigation.emptyAreas.button" />
      </Button>
    </Box>
  );
};

export default EmptyAreas;
