import React, { Component } from 'react';

import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEvapotranspirationData } from '../../../shared/api/iot/locations/locations.selectors';
import { getSensorsData } from '../../../shared/api/iot/sensors/sensors.selectors';
import { getDateFrom, getDateTo, getDuration, getGranularity } from '../../selectors/node.selectors';

import { setDuration, resetDuration, setGranularity, resetGranularity, setDateFrom, setDateTo, getNumberOfDays } from '../../actions/node.actions';

import { resetEvapotranspirationData } from '../../../shared/api/iot/locations/locations.api';
import DateRangeSelector from '../../../shared/components/common/DateRangeSelector/DateRangeSelector';
import PeriodSwitcher from '../../../shared/components/common/PeriodSwitcher/PeriodSwitcher';
import withWidth from '../../../shared/hocs/withWidth';
import NodeLocationChart from '../NodeLocationChart/NodeLocationChart';

const styles = () => ({
  nodeCharts: {
    marginBottom: 16,
  },
  nodeChartsPaper: {
    padding: 20,
  },
  switchers: {
    display: 'flex',
    alignItems: 'center',
  },
  chartsHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  heading: {
    marginRight: 10,
  },
});

export class NodeLocationCharts extends Component {
  constructor(props) {
    super(props);
    const { nodeLocation } = props;

    this.state = {
      config: nodeLocation.node.config,
    };
  }

  componentDidUpdate(prevProps) {
    const { dateFrom, dateTo, evapotranspirationData, nodeLocation, sensorsData } = this.props;
    if (nodeLocation.id !== prevProps.nodeLocation.id) {
      this.props.resetDuration();
      this.props.resetGranularity();
    }
    if (prevProps.sensorsData !== sensorsData ||
      prevProps.evapotranspirationData !== evapotranspirationData) {
      this.updateConfig(sensorsData, evapotranspirationData);
    }
    if (prevProps.dateFrom !== dateFrom ||
      prevProps.dateTo !== dateTo) {
      const numberOfSelectedDays = getNumberOfDays(dateFrom, dateTo);
      this.props.setDuration(numberOfSelectedDays);
    }
  }

  componentWillUnmount() {
    this.props.resetEvapotranspirationData();
  }

  updateConfig(sensorsData = null, evapotranspirationData = null) {
    const {
      node: { config },
    } = this.props.nodeLocation;

    if (sensorsData || evapotranspirationData) {
      const configWithData = {
        ...config,
        charts: config.charts.map(chart => ({
          ...chart,
          features: chart.features.map(feature => {
            if (feature.name.startsWith('FEATURE_EVAPOTRANSPIRATION')) {
              if (!isEmpty(evapotranspirationData)) {
                return {
                  ...feature,
                  sensors: [{
                    feature: 'Evapotranspiration',
                    normalized: true,
                    aggregations: Object.keys(evapotranspirationData),
                    data: [evapotranspirationData],
                    value: evapotranspirationData.total,
                  }],
                };
              }
              return feature;
            }
            return {
              ...feature,
              sensors: feature.sensors.map(sensor => {
                if (sensorsData[sensor.id]) {
                  return {
                    ...sensor,
                    aggregations: Object.keys(sensorsData[sensor.id]),
                    data: [sensorsData[sensor.id]],
                  };
                }
                return sensor;
              }),
            };
          }),
        })),
      };

      this.setState({
        config: configWithData,
      });
    }
  }

  render() {
    const {
      classes,
      dateFrom,
      dateTo,
      duration,
      evapotranspirationData,
      granularity,
      nodeLocation,
      width } = this.props;
    const { config } = this.state;
    return (
      <div className={classes.nodeCharts}>
        <Grid className={classes.chartsHeader} container>
          <div>
            <span className={classes.heading} style={{ marginBottom: width === 'xs' ? 5 : 0 }}>
              <FormattedMessage id="NodeCharts.chartOFAverageValues" />
            </span>
            <DateRangeSelector
              dateFrom={dateFrom}
              dateTo={dateTo}
              minDate={nodeLocation.from}
              setDateFrom={this.props.setDateFrom}
              setDateTo={this.props.setDateTo}
            />
          </div>
          <div className={classes.switchers}>
            <span style={{ margin: width === 'xs' ? '10px 10px 5px 0px' : '0px 10px 0px 0px' }}>
              <FormattedMessage id="NodeCharts.chartInterval" />
            </span>
            <PeriodSwitcher
              duration={duration}
              granularity={granularity}
              resetGranularity={this.props.resetGranularity}
              setGranularity={this.props.setGranularity}
            />
          </div>
        </Grid>
        <Paper className={classes.nodeChartsPaper}>
          {config &&
            duration &&
            granularity &&
            dateFrom &&
            dateTo &&
            config.charts.map(chart => {
              const { name } = chart;
              const isWindChart = name.startsWith('CHART_WIND');
              const isEvapotranspirationChart = name.startsWith('CHART_EVAPOTRANSPIRATION');
              const isBaraniDevice = nodeLocation.node.deviceType === 'METEO_BARANI_HELIX';

              return ((isWindChart || isEvapotranspirationChart) && isBaraniDevice) ?
                (
                  (nodeLocation.evapotranspiration) &&
                    <NodeLocationChart
                      chart={chart}
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                      duration={duration}
                      granularity={granularity}
                      key={`${nodeLocation.id}-${chart.name}`}
                      location={nodeLocation}
                      totalEvapotranspiration={evapotranspirationData.total}
                    />
                ) : (
                  <NodeLocationChart
                    chart={chart}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    duration={duration}
                    granularity={granularity}
                    key={`${nodeLocation.id}-${chart.name}`}
                    location={nodeLocation}
                    totalEvapotranspiration={evapotranspirationData.total}
                  />
                );
            })
          }
        </Paper>
      </div>
    );
  }
}

NodeLocationCharts.propTypes = {
  nodeLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  resetEvapotranspirationData: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  granularity: PropTypes.string.isRequired,
  setDuration: PropTypes.func.isRequired,
  resetDuration: PropTypes.func.isRequired,
  setGranularity: PropTypes.func.isRequired,
  resetGranularity: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  sensorsData: PropTypes.object.isRequired,
  evapotranspirationData: PropTypes.object.isRequired,
};

NodeLocationCharts.defaultProps = {
  classes: {},
  historicPestPredictions: [],
};

const mapStateToProps = state => ({
  dateFrom: getDateFrom(state),
  dateTo: getDateTo(state),
  duration: getDuration(state),
  granularity: getGranularity(state),
  sensorsData: getSensorsData(state),
  evapotranspirationData: getEvapotranspirationData(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDateFrom,
      setDateTo,
      resetEvapotranspirationData,
      setDuration,
      resetDuration,
      setGranularity,
      resetGranularity,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(compose(withStyles(styles), withWidth())(NodeLocationCharts)));
