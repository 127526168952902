import React, { Component, Fragment } from 'react';

import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfNoData from '../../charts/CfNoData/CfNoData';

const styles = () => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  custom: {
    // display: 'inline-block'
  },
});

/**
 * Lazy loaded image, shows loader while loading
 * @version 1.0.0
 */
export class CfLazyImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
    };
  }

  onLoad = () => {
    this.setState({
      isLoading: false,
      isError: false,
    });
  };

  onError = () => {
    this.setState({
      isLoading: false,
      isError: true,
    });
  };

  reload = props => {
    if (!props.src) {
      return;
    }

    this.setState({
      isLoading: true,
      isError: false,
      src: null,
    });

    this.image = new Image();

    this.image.src = props.src;
    this.image.onload = () => {
      this.setState({
        isLoading: false,
        isError: false,
        src: this.image.src,
      });
    };

    this.image.onerror = () => {
      this.setState({
        isLoading: false,
        isError: true,
        src: null,
      });
    };
  };

  render() {
    const { alt, classes, src, testId } = this.props;
    const { isError, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && (
          <div className={`${classes.loader} ${classes.custom}`}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && isError && (
          <div className={`${classes.loader} ${classes.custom}`}>
            <CfNoData />
          </div>
        )}
        <img
          alt={alt}
          className={classes.custom}
          data-test={testId}
          onError={this.onError}
          onLoad={this.onLoad}
          src={src}
          style={{ display: isLoading || (!isLoading && isError) ? 'none' : 'block' }}
        />
      </Fragment>
    );
  }
}

CfLazyImage.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** Src image attribute */
  src: PropTypes.string,
  /** Alt image attribute */
  alt: PropTypes.string.isRequired,
  /** Testing purpose ID */
  testId: PropTypes.string,
};

CfLazyImage.defaultProps = {
  testId: null,
  src: '',
};

export default withStyles(styles)(CfLazyImage);
