import React, { Fragment, Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import tail from 'lodash/tail';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getParcelsFertilization } from '../../../../../shared/api/core/fertilzation/fertilization.selectors';
import { getParcelsAndZonesSuggestions, getParcelsToAdd } from '../../selectors/actions.selectors';

import {
  addInitParcelsToAdd,
  addInitZoneParcelsToAdd,
  addParcelOrZoneParcelsToAdd,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
  getPredefinedSubtractions,
  updateParcelsList,
  getMaterialSubtractions,
  updateParcelsSubtractions,
  calculateParcelRestrictedArea,
} from '../../actions/actions.actions';

import { getParcelCurrentFertilization } from '../../../../../shared/api/core/fertilzation/fertilization.api';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import ListSelectorError from '../../../../../shared/components/form/ListSelectorError/ListSelectorError';
import ActionParcelsList from '../../components/ActionParcelsList/ActionParcelsList';
import ParcelZoneSelector from '../../components/ParcelZoneSelector/ParcelZoneSelector';
import {
  resolveTargetCrop,
  actionDateChanged,
  parcelsCountChanged,
  targetCropChanged,
} from '../../misc/action.helpers';

const styles = theme => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: '12px 24px',
    overflow: 'hidden',
    marginTop: 10,
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: 'right',
  },
});

class ActionParcelsControl extends Component {
  componentDidMount() {
    const { initParcelIds, initZoneIds } = this.props;

    if (Array.isArray(initParcelIds) && initParcelIds.length) {
      this.props.addInitParcelsToAdd(initParcelIds);
    }

    if (Array.isArray(initZoneIds) && initZoneIds.length) {
      this.props.addInitZoneParcelsToAdd(initZoneIds);
    }

    const { actionDate, fields, targetCrop } = this.props;
    const parcels = fields.getAll();
    if (parcels && !isEmpty(actionDate) && !isEmpty(targetCrop)) {
      this.updateInitialN(parcels, actionDate, targetCrop);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actionDate: prevActionDate,
      fields: prevFields,
      formName: prevFormName,
      parcelsToAdd: prevParcelsToAdd,
      targetCrop: prevTargetCrop,
    } = prevProps;

    const {
      actionDate: newActionDate,
      driftClass: newDriftClass,
      expenses: newExpenses,
      fields: newFields,
      isEditing: newIsEditing,
      parcelsToAdd: newParcelsToAdd,
      targetCrop: newTargetCrop,
    } = this.props;

    const prevParcels = prevFields.getAll() || [];
    const newParcels = newFields.getAll();

    // Comparison booleans to detect the change
    const targetCropDiff = targetCropChanged(prevTargetCrop, newTargetCrop);
    const parcelsCountDiff = parcelsCountChanged(prevParcels, newParcels);
    const actionDateDiff = actionDateChanged(prevActionDate, newActionDate);

    // Values calculated based on new inputs
    const resolvedTargetCrop = resolveTargetCrop(newParcels);

    /*
     * Recalculates current fertilization on newly added parcels;
     * Recalculates current fertilization on all selected parcels when:
     * 1) action date changed or target crop chenged
     * 2) and target crop is set or resolved
     * 3) and action date is set
     */
    if ((actionDateDiff || targetCropDiff) && (newTargetCrop || resolvedTargetCrop) && newActionDate) {
      const parcels = parcelsCountDiff ? differenceBy(newParcels, prevParcels, 'id') : newParcels;
      this.updateInitialN(parcels, newActionDate, newTargetCrop || resolvedTargetCrop);
    }

    /*
     * Adds parcels from the external prop parcelsToAdd,
     * which is the user interaction, but parcels can be
     * added also thru the form initialization (like from the local storage)
     */
    if (newParcelsToAdd.length > prevParcelsToAdd.length) {
      this.addParcels(differenceBy(newParcelsToAdd, prevParcelsToAdd, 'id'));
      this.props.clearParcelsToAdd();
    }

    // detection of new parcels regardless of how they appear in the form (manually added, or pre-initialized)
    if (newParcels.length > prevParcels.length && newIsEditing) {
      this.updateInitialN(newParcels, newActionDate, newTargetCrop || resolvedTargetCrop);
      this.getParcelsSubtractions(newParcels, prevParcels, newExpenses, newDriftClass, newTargetCrop, prevFormName);
    }
  }

  getParcelsSubtractions(newParcels, prevParcels, newExpenses, newDriftClass, newTargetCrop, prevFormName) {
    const diffParcels = differenceBy(newParcels, prevParcels, 'id');
    const promises = [];
    const parcelIds = diffParcels.map(p => p.id).concat(prevParcels.map(p => p.id));

    /*
     * Recalculate subtractable areas for parcels already in form
     * because adding new parcel can affect subtractable areas
     * in the previously added parcels (e.g. if the share border)
     */
    if (prevParcels.length > 0) {
      promises.push(this.props.updateParcelsSubtractions(parcelIds, prevParcels));
    }

    diffParcels.forEach(parcel => {
      const materialIds = newExpenses.map(material => material.material.id);
      promises.push(
        this.props.getPredefinedSubtractions(parcel.id, parcelIds).then(predefinedSas => {
          if (materialIds.length) {
            return this.props
              .getMaterialSubtractions(
                materialIds,
                parcelIds,
                newDriftClass,
                parcel,
                newTargetCrop.legislativeCode,
                newExpenses,
              )
              .then(materialsSas => {
                const subtractableAreas = predefinedSas.concat(materialsSas);
                return this.props
                  .calculateParcelRestrictedArea(parcel.id, subtractableAreas, parcelIds)
                  .then(restrictedArea => ({
                    ...parcel,
                    restrictedArea,
                    subtractableAreas,
                  }));
              });
          }
          return this.props.calculateParcelRestrictedArea(parcel.id, predefinedSas, parcelIds).then(restrictedArea => ({
            ...parcel,
            restrictedArea,
            subtractableAreas: predefinedSas,
          }));
        }),
      );
    });

    // update the parcels list with the recalculated parcels and with newly added parcels
    if (promises.length) {
      Promise.all(promises).then(allParcels => {
        const parcels = prevParcels.length === 0 ? allParcels : [...allParcels[0], ...tail(allParcels)];
        this.props.updateParcelsList(parcels, prevFormName);
      });
    }
  }

  getSuggestions = searchInput => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.fetchParcelsAndZonesSuggestions(searchInput, parcelsOnly, zonesOnly);
  };

  clearSuggestions = () => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.clearParcelsAndZonesSuggestions(parcelsOnly, zonesOnly);
  };

  addParcels(parcels) {
    this.props.updateParcelsList(
      [
        ...this.props.fields.getAll(),
        ...parcels
          .filter(parcel => !this.isParcelInList(parcel))
          .map(p => ({
            ...p,
            subtractableAreas: [],
            restrictedArea: 0,
            actionParcelTotalArea: p.area,
          })),
      ],
      this.props.formName,
    );
    return parcels;
  }

  isParcelInList = parcelToCheck => find(this.props.fields.getAll(), ['id', parcelToCheck.id]) !== undefined;

  handleSuggestionSelected = suggestion => {
    this.props.addParcelOrZoneParcelsToAdd(suggestion);
  };

  handleItemRemove = index => {
    this.props.fields.remove(index);
  };

  updateInitialN(parcels, newActionDate, targetCrop) {
    parcels.forEach(parcel =>
      this.props.getParcelCurrentFertilization(
        parcel.id,
        newActionDate,
        targetCrop.legislativeCode,
        this.props.existingAction ? this.props.existingAction.id : null,
      ),
    );
  }

  render() {
    const {
      classes,
      fields,
      formName,
      intl: { formatMessage },
      isEditing,
      maxParcelCount,
      meta: { error, submitFailed },
      parcelsArea,
      placeholder,
      subtractableAreasIds,
      suggestions,
    } = this.props;

    return (
      <Fragment>
        {isEditing && (maxParcelCount ? fields.length < maxParcelCount : true) ? (
          <Fragment>
            <ParcelZoneSelector
              autoFocus={true}
              inputRef={ref => ref}
              onSuggestionsClearRequested={this.clearSuggestions}
              onSuggestionSelected={this.handleSuggestionSelected}
              onSuggestionsFetchRequested={this.getSuggestions}
              placeholder={placeholder}
              suggestions={suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
            />
            {submitFailed && error && <ListSelectorError error={error} />}
          </Fragment>
        ) : (
          <div className={classes.parcelsLabel}>
            <FormattedMessage id="common.parcels" />:
          </div>
        )}
        <ActionParcelsList
          fields={fields}
          formName={formName}
          isEditing={isEditing}
          onItemRemove={this.handleItemRemove}
          parcelsFertilization={this.props.parcelsFertilization}
          subtractableAreasIds={subtractableAreasIds}
        />
        {parcelsArea > 0 && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item lg={6} md={7} sm={8} xs={12}>
              <div className={classes.totalActivityArea}>
                <span className={classes.totalAreaLabel}>
                  <FormattedMessage id="ParcelZoneSelector.totalActivityArea" />:{' '}
                </span>
                <span className={classes.totalAreaValue}>{<CfFormattedNumber value={parcelsArea} />} ha</span>
              </div>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

ActionParcelsControl.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  existingAction: PropTypes.object,
  fetchParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  clearParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  addParcelOrZoneParcelsToAdd: PropTypes.func.isRequired,
  clearParcelsToAdd: PropTypes.func.isRequired,
  addInitParcelsToAdd: PropTypes.func.isRequired,
  addInitZoneParcelsToAdd: PropTypes.func.isRequired,
  getPredefinedSubtractions: PropTypes.func.isRequired,
  updateParcelsSubtractions: PropTypes.func.isRequired,
  getMaterialSubtractions: PropTypes.func.isRequired,
  calculateParcelRestrictedArea: PropTypes.func.isRequired,
  getParcelCurrentFertilization: PropTypes.func.isRequired,
  updateParcelsList: PropTypes.func.isRequired,
  // eslint-disable-next-line
  driftClass: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  // eslint-disable-next-line
  expenses: PropTypes.array.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  suggestions: PropTypes.array.isRequired,
  parcelsToAdd: PropTypes.array,
  parcelsFertilization: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  targetCrop: PropTypes.object,
  actionDate: PropTypes.object,
  initParcelIds: PropTypes.array,
  initZoneIds: PropTypes.array,
  maxParcelCount: PropTypes.number,
  parcelsOnly: PropTypes.bool,
  zonesOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  subtractableAreasIds: PropTypes.array,
};

ActionParcelsControl.defaultProps = {
  existingAction: {},
  parcelsToAdd: [],
  parcelsFertilization: {},
  initParcelIds: null,
  initZoneIds: null,
  targetCrop: {},
  actionDate: {},
  maxParcelCount: null,
  parcelsOnly: false,
  zonesOnly: false,
  placeholder: 'ParcelZoneSelector.placeholder',
  subtractableAreasIds: undefined,
};

const mapStateToProps = (state, props) => {
  const { filter, formName } = props;

  return {
    suggestions: getParcelsAndZonesSuggestions(i => (filter?.length ? filter.includes(i.id) : true), formName)(state),
    parcelsToAdd: getParcelsToAdd(formName, state),
    parcelsFertilization: getParcelsFertilization(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchParcelsAndZonesSuggestions,
      clearParcelsAndZonesSuggestions,
      addParcelOrZoneParcelsToAdd,
      clearParcelsToAdd,
      addInitParcelsToAdd,
      addInitZoneParcelsToAdd,
      getPredefinedSubtractions,
      updateParcelsList,
      getMaterialSubtractions,
      updateParcelsSubtractions,
      calculateParcelRestrictedArea,
      getParcelCurrentFertilization,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(ActionParcelsControl)));
