import React from 'react';

import { Chip, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    maxWidth: '16.25rem',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '16.25rem',
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    width: 'auto',
    padding: 4,
  },
  chip: {
    fontSize: '0.875rem',
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
  },
}));

const IrrigationStartTimeList = ({ onDeleteStartTimes, plan }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {plan.map(time => {
        const handleDelete = () => {
          onDeleteStartTimes(time);
        };
        return (
          <ListItem className={classes.listItem} key={time}>
            <Chip
              className={classes.chip}
              label={time}
              onDelete={handleDelete}
              size="small"
            />
          </ListItem>
        );
      })}
    </List>
  );
};

IrrigationStartTimeList.propTypes = {
  plan: PropTypes.array.isRequired,
  onDeleteStartTimes: PropTypes.func.isRequired,
};

export default IrrigationStartTimeList;
