import { createSelector } from 'reselect';

import { isParcelFetching } from '../../../../../shared/api/core/parcels/parcels.selectors';
import { getSatelliteProducts } from '../../../../../shared/api/sentinel/satelliteProducts/satelliteProducts.selectors';

export const getIsFetchingParcel = state => isParcelFetching()(state);

export const getVariableApplicationStatus = createSelector(
  getSatelliteProducts,
  products => products.variableApplicationStatus,
);
