/**
 * Created by ondrejzvara on 10.12.15.
 */

/* @ngInject */

export default function cfPorBatch() {
  return {
    restrict: 'E',
    scope: {},
    bindToController: {
      por: '=',
      editable: '='
    },
    template: require('./cf-por-batch.html'),
    controller: cfPorBatchController,
    controllerAs: 'cfPorBatchCtrl'
  };
}

/* @ngInject */
function cfPorBatchController(FileUploader, ChemistriesResource, BatchesResource, Plain, Modal) {
  const vm = this;

  vm.initPor = null;
  vm.uploader = new FileUploader({
    url: '',
    queueLimit: 1
  });

  vm.isAddingNew = false;

  vm.switchBatch = switchBatch;

  vm.cancelCreating = cancelCreating;
  vm.saveCreating = saveCreating;

  vm.startEditing = startEditing;
  vm.cancelEditing = cancelEditing;
  vm.saveEditing = saveEditing;

  vm.addBatchTemplate = addBatchTemplate;
  vm.removeBatch = removeBatch;

  vm.cancelLabel = cancelLabel;
  vm.uploadLabel = uploadLabel;
  vm.deleteLabel = deleteLabel;
  vm.downloadLabel = downloadLabel;

  activate();

  function activate() {}

  function switchBatch(batch) {
    closeOthers(batch);

    batch.isCollapsed = !batch.isCollapsed;
    if (batch && !batch.blobUrl) {
      getFile(batch.id).then((blobInfo) => {
        batch.blob = blobInfo.blob;
        batch.blobUrl = blobInfo.blobUrl;
      });
    }
  }

  function closeOthers(batch) {
    _.each(
      _.filter(vm.por.batch, b => b.id !== batch.id && b.isEdit === false),
      (batch) => {
        batch.isCollapsed = true;
      }
    );
  }

  function startEditing(batch) {
    vm.initPor = _.cloneDeep(vm.por);
    batch.isEdit = true;
  }

  function resetEditing() {
    vm.por = vm.initPor;
  }

  function cancelEditing(batch) {
    batch.isEdit = false;
    resetEditing();
  }

  function saveEditing(batch) {
    if (!batch.name || batch.name === '') {
      return;
    }

    batch.isEdit = false;
  }

  /** ********************************************************************* */

  function addBatchTemplate() {
    if (!vm.editable) {
      return;
    }

    if (!vm.por.batches) {
      vm.por.batches = [];
    }
    vm.por.batches.push({ batchName: '', isEdit: true, isCollapsed: true, isNew: true });
    vm.isAddingNew = true;
  }

  function cancelCreating(batch) {
    batch.isEdit = false;
    const index = vm.por.batches.indexOf(batch);
    if (index > -1) {
      vm.por.batches.splice(index, 1);
      vm.isAddingNew = false;
    }
  }

  function saveCreating(batch) {
    if (!batch.batchName) {
      return false;
    }

    BatchesResource.createBatch(vm.por.id, batch).then(() => {
      getPor();
    });
  }

  function removeBatch(batch) {
    if (!_.isObject(batch) || _.isEmpty(batch)) {
      return;
    }

    const config = {
      data: batch,
      size: 'md',
      templateUrl: 'app/assets/templates/por/por.deleteBatch.html'
    };

    return Modal.activateModal(config).then((batch) => {
      BatchesResource.deleteBatch(batch.id).then(() => {
        getPor();
      });
    });
  }

  /** ********************************************************************* */

  function cancelLabel() {
    vm.uploader.removeFromQueue(vm.uploader.queue[0]);
    const element = angular.element('#batch-label-upload');
    if (element) {
      element.prop('value', null);
    }
  }

  function uploadLabel(batch) {
    if (vm.uploader.queue.length) {
      return BatchesResource.addLabelToBatch(vm.uploader.queue[0]._file, batch.id).then(() => {
        getPor();
        vm.uploader.clearQueue();
      });
    }
  }

  function deleteLabel(batch) {
    return BatchesResource.deleteBatchLabel(batch.id).then(() => {
      getPor();
      vm.uploader.clearQueue();
    });
  }

  function downloadLabel(batch) {
    return BatchesResource.downloadBatchLabel(batch.id, batch.label.fileName);
  }

  /** ********************************************************************* */

  function getFile(batchId) {
    return BatchesResource.getBatchLabel(batchId);
  }

  function getPor() {
    return ChemistriesResource.getChemistry(vm.por.id).then((data) => {
      vm.por = new Plain(data);
      vm.isAddingNew = false;
      return data;
    });
  }
}
