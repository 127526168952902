/**
 * Created by ondrejzvara on 17.1.16.
 */

export function cfPorTargetOrganism() {
    return function(array) {

        var result = '-';
        if(_.isArray(array)){

            result = "";
            _.each(array, function(item){
                if(item.organism && item.organism.name){
                    result += _.capitalize(item.organism.name);
                    result += ', ';
                }
            });
            if(result){
                result = _.trimEnd(result, ', ');
            } else {
                result = "-";
            }
        }

        return result;

    };
}
