import * as deviceGroups from '../constants/deviceGroups.constants';

export default class DeviceTypesService {
  static getDeviceGroupByDeviceType(deviceTypeId, deviceTypes) {
    if (deviceTypes && deviceTypeId) {
      const deviceType = deviceTypes.find(item => deviceTypeId === item.name);
      return deviceGroups[deviceType?.group];
    }
    return undefined;
  }

  static getDeviceGroups() {
    return [
      deviceGroups.METEO,
      deviceGroups.FIELD,
      deviceGroups.SOIL,
      deviceGroups.STORAGE,
      deviceGroups.SILO,
      deviceGroups.OTHER,
    ];
  }
}
