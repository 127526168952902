export const GET_FARMS = 'GET_FARMS';
export const GET_FARMS_SUCCESS = 'GET_FARMS_SUCCESS';
export const GET_FARMS_FAILURE = 'GET_FARMS_FAILURE';

export const RESET_FARMS = 'RESET_FARMS';

export const GET_FARM = 'GET_FARM';
export const GET_FARM_SUCCESS = 'GET_FARM_SUCCESS';
export const GET_FARM_FAILURE = 'GET_FARM_FAILURE';

export const GET_EAGRI_FARM_NAME = 'GET_EAGRI_FARM_NAME';
export const GET_EAGRI_FARM_NAME_SUCCESS = 'GET_EAGRI_FARM_NAME_SUCCESS';
export const GET_EAGRI_FARM_NAME_FAILURE = 'GET_EAGRI_FARM_NAME_FAILURE';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const RESET_COUNTRIES = 'RESET_COUNTRIES';

export const CREATE_EMPTY_FARM = 'CREATE_EMPTY_FARM';
export const CREATE_EMPTY_FARM_SUCCESS = 'CREATE_EMPTY_FARM_SUCCESS';
export const CREATE_EMPTY_FARM_ERROR = 'CREATE_EMPTY_FARM_ERROR';

export const CREATE_EAGRI_FARM = 'CREATE_EAGRI_FARM';
export const CREATE_EAGRI_FARM_SUCCESS = 'CREATE_EAGRI_FARM_SUCCESS';
export const CREATE_EAGRI_FARM_ERROR = 'CREATE_EAGRI_FARM_ERROR';

export const VALIDATE_FARM_FILE = 'VALIDATE_FARM_FILE';
export const VALIDATE_FARM_FILE_SUCCESS = 'VALIDATE_FARM_FILE_SUCCESS';
export const VALIDATE_FARM_FILE_ERROR = 'VALIDATE_FARM_FILE_ERROR';

export const CREATE_FARM_BY_FILE = 'CREATE_FARM_BY_FILE';
export const CREATE_FARM_BY_FILE_SUCCESS = 'CREATE_FARM_BY_FILE_SUCCESS';
export const CREATE_FARM_BY_FILE_ERROR = 'CREATE_FARM_BY_FILE_ERROR';
