import keyBy from 'lodash/keyBy';

import * as types from './fertilization.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    current: {},
    expected: {},
  },
  action,
) => {
  switch (action.type) {
    case types.GET_PARCEL_FERTILIZATION_CURRENT:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PARCEL_FERTILIZATION_CURRENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        current: {
          ...state.current,
          [action.payload.parcelId]: action.payload,
        },
      };
    case types.GET_PARCEL_FERTILIZATION_CURRENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.GET_PARCEL_FERTILIZATION_EXPECTED:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PARCEL_FERTILIZATION_EXPECTED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        expected: keyBy(action.payload, 'parcelId'),
      };
    case types.GET_PARCEL_FERTILIZATION_EXPECTED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_PARCEL_FERTILIZATION_EXPECTED:
      return {
        ...state,
        isFetching: false,
        expected: {},
      };
    default:
      return state;
  }
};
