import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAdvancedFilterStyles = makeStyles((theme: Theme) => ({
  subtext: {
    color: theme.palette.grey[500],
    marginTop: '-5px',
  },
}));

export default useAdvancedFilterStyles;
