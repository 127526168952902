import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WeatherDayIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M12,2.83A9.17,9.17,0,1,0,21.17,12,9.17,9.17,0,0,0,12,2.83ZM17.2,17.18A7.32,7.32,0,1,1,19.35,12,7.32,7.32,0,0,1,17.2,17.18Z" />
      <polygon points="12.66 7.42 10.84 7.42 10.84 13.07 15.49 15.83 16.42 14.26 12.66 12.03 12.66 7.42" />
    </svg>
  </SvgIcon>
);

export default WeatherDayIcon;
