import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import NodeGroups from '../../containers/NodeGroups/NodeGroups';
import NodeLocationsListFilter from '../../containers/NodeLocationsListFilter/NodeLocationsListFilter';
import NodeLocationsTable from '../../containers/NodeLocationsTable/NodeLocationsTable';

const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  nodesList: {
    paddingRight: 10,
  },
  groups: {
    marginBottom: 40,
  },
});

class NodeLocationsList extends Component {
  componentDidMount() {
    this.props.fetchLocations();
    this.props.zoomToFarm();
    this.props.resetHoveredAndSelectedFeatures();
  }

  render() {
    const { classes, farmId, hoveredFeatureId, isMapDisplayed, langId, locations } = this.props;
    return (
      <div className={classes.wrapper}>
        <PageHeader
          classes={{ header: classes.header }}
          heading={
            <PageHeading dataTest="node-locations-list-heading" value={<FormattedMessage id="common.sensors" />} />
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NodeLocationsListFilter isMapDisplayed={isMapDisplayed} langId={langId} />
            <div>
              <Grid container spacing={1}>
                <Grid item lg={9} md={isMapDisplayed ? 12 : 9} xs={12}>
                  <NodeLocationsTable
                    history={this.props.history}
                    hoveredFeatureId={hoveredFeatureId}
                    langId={langId}
                    locations={locations}
                    match={this.props.match}
                  />
                </Grid>
                <Grid className={classes.groups} item lg={3} md={isMapDisplayed ? 12 : 3} xs={12}>
                  <Grid container spacing={0}>
                    <NodeGroups farmId={farmId} langId={langId} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NodeLocationsList.propTypes = {
  history: PropTypes.object,
  locations: PropTypes.array,
  hoveredFeatureId: PropTypes.string,
  classes: PropTypes.object,
  zoomToFarm: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  isMapDisplayed: PropTypes.bool,
  fetchLocations: PropTypes.func.isRequired,
  resetHoveredAndSelectedFeatures: PropTypes.func.isRequired,
  farmId: PropTypes.string,
};

NodeLocationsList.defaultProps = {
  history: {},
  locations: [],
  hoveredFeatureId: null,
  classes: {},
  isMapDisplayed: true,
  farmId: '',
};

export default withStyles(styles)(NodeLocationsList);
