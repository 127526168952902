import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function YesNo(props) {
  return props.value ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />;
}

YesNo.propTypes = {
  value: PropTypes.bool.isRequired,
};
