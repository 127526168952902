import React, { Fragment, Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getGroups, getIsFetchingGroups } from '../../../shared/api/iot/groups/groups.selectors';
import { getGroupsOrder, getGroupsOrderBy } from '../../selectors/groups.selectors';

import { fetchGroups, createGroup, deleteGroup, updateGroup, filterGroup } from '../../actions/groups.actions';
import { fetchLocations } from '../../actions/locations.actions';

import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import NameEditDialog from '../../../shared/components/common/NameEditDialog/NameEditDialog';
import { Header, GroupsBody, NewGroup } from '../../components/NodeGroupsTable/NodeGroupsTable';

const styles = theme => ({
  wrapper: {
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  paper: {
    paddingTop: 10,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  createGroup: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 15px',
  },
  createGroupForm: {
    padding: '15px 15px',
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 15,
    marginBottom: 15,
  },
});

export class NodeGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingNewGroup: false,
      editing: false,
      groupToEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, ['order', 'orderBy']);
    const newProps = pick(this.props, ['order', 'orderBy']);

    if (!isEqual(newProps, oldProps)) {
      this.fetchGroups();
    }
  }

  onNewGroupCreate = () => {
    this.setState({
      addingNewGroup: true,
    });
  };

  onCreateGroupDismiss = () => {
    this.setState({
      addingNewGroup: false,
    });
  };

  onCreateGroupConfirm = group =>
    this.props
      .createGroup({ ...group, farmId: this.props.farmId })
      .then(() => {
        this.fetchGroups();
      })
      .finally(() => {
        this.setState({
          addingNewGroup: false,
        });
      });

  onUpdateGroup = group => {
    this.onEditCancel();
    this.props.updateGroup(group).then(() => {
      this.fetchGroups();
      this.props.fetchLocations();
    });
  };

  onDeleteGroup = group =>
    this.props.deleteGroup(group).then(() => {
      this.fetchGroups();
      this.props.fetchLocations();
    });

  onEditGroup = group => {
    this.setState({
      editing: true,
      groupToEdit: group,
    });
  };

  onEditCancel = () => {
    this.setState({
      editing: false,
      groupToEdit: {},
    });
  };

  fetchGroups() {
    this.props.fetchGroups();
  }

  render() {
    const { classes, groups, isFetching, order, orderBy } = this.props;
    const { addingNewGroup, editing, groupToEdit } = this.state;
    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {isFetching ? (
                <CfLoader classes={{ wrapper: classes.wrapper }} />
              ) : (
                <Fragment>
                  <span className={classes.header}>
                    <FormattedMessage id={'NodeGroups.header'} />
                  </span>
                  <Table stickyHeader>
                    <Header order={order} orderBy={orderBy} />
                  </Table>
                  <Scrollbars autoHeight={true} autoHeightMax={400}>
                    <Table data-test="group-list">
                      <TableBody>
                        {groups.map(item => (
                          <GroupsBody
                            item={item}
                            key={item.id}
                            onDeleteGroup={this.onDeleteGroup}
                            onEditGroup={this.onEditGroup}
                            onFilterGroup={this.props.filterGroup}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbars>
                  <div className={classes.createGroup}>
                    {!addingNewGroup && (
                      <span className={classes.button}>
                        <Button
                          className={classes.label}
                          data-test="add-group"
                          disabled={addingNewGroup}
                          endIcon={<AddIcon />}
                          onClick={this.onNewGroupCreate}
                        >
                          <FormattedMessage id="NodeGroups.newGroup" />
                        </Button>
                      </span>
                    )}
                    {addingNewGroup && (
                      <div className={classes.createGroupForm}>
                        <NewGroup
                          onCreateGroupConfirm={this.onCreateGroupConfirm}
                          onCreateGroupDismiss={this.onCreateGroupDismiss}
                        />
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
        {editing && (
          <NameEditDialog
            item={groupToEdit}
            onAccept={this.onUpdateGroup}
            onClose={this.onEditCancel}
            opened={editing}
            title={<FormattedMessage id="NodeGroups.editGroup" />}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: getIsFetchingGroups(state),
  groups: getGroups(state),
  order: getGroupsOrder(state),
  orderBy: getGroupsOrderBy(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations,
      fetchGroups,
      createGroup,
      deleteGroup,
      updateGroup,
      filterGroup,
    },
    dispatch,
  );

NodeGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  filterGroup: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  farmId: PropTypes.string,
};

NodeGroups.defaultProps = {
  farmId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeGroups));
