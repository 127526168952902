import { getMapDragging } from '../../selectors/editor.selectors';
import { getEventListener, getLayers } from '../../selectors/map.selectors';

import { editorSetHoveredId } from '../editor/editor.actions';
import { mapSetCursor } from '../map/map.actions';

export const setMouseMoveEL = (onMouseMove, refresh, key) => (dispatch, getState) => {
  getEventListener(getState()).setFeatureHoverEL(
    (feature, evt) => {
      dispatch(onMouseMove(feature, evt));
    },
    () => {
      if (refresh) {
        dispatch(refresh());
      }
    },
    key,
    layer => layer === getLayers(getState()).getParcelLayer(),
  );
};

export const setMouseClickEL = (onMouseClick, refresh, key, layerFilter, dispatchOnMouseClick = true) =>
  (dispatch, getState) => {
    const filter = layerFilter || (layer => layer === getLayers(getState()).getParcelLayer());
    getEventListener(getState()).setFeatureClickEL(
      (feature, evt) => {
        if (dispatchOnMouseClick) {
          dispatch(onMouseClick(feature, evt));
        } else {
          onMouseClick(feature, evt);
        }
      },
      () => {
        dispatch(refresh());
      },
      key,
      filter,
    );
  };

export const setCoordinateHoverEL = (onMouseMove, key) => (dispatch, getState) => {
  getEventListener(getState()).setCoordinateHoverEL(coordinate => {
    dispatch(onMouseMove(coordinate));
  }, key);
};

export const setCoordinateClickEL = (onMouseMove, key) => (dispatch, getState) => {
  getEventListener(getState()).setCoordinateClickEL(coordinate => {
    dispatch(onMouseMove(coordinate));
  }, key);
};

export const removeEL = key => (_, getState) => {
  getEventListener(getState()).removeEL(key);
};

export const setHoverStyleEL = (filter, refreshFnc, key, layerFilter, dispatchRefreshFnc = true) =>
  (dispatch, getState) => {
    const lf = layerFilter || (layer => layer === getLayers(getState()).getParcelLayer());
    let cachedHoverId;
    let shouldRefresh = false;
    const onMouseMove = feature => {
      if (filter(feature)) {
        const id = feature.get('id');
        if (cachedHoverId === id) {
          shouldRefresh = false;
          return null;
        }

        cachedHoverId = id;
        dispatch(editorSetHoveredId(id));
        dispatch(mapSetCursor('pointer'));
        shouldRefresh = true;
      } else {
        if (cachedHoverId === undefined) {
          shouldRefresh = false;
          return null;
        }

        cachedHoverId = undefined;
        dispatch(editorSetHoveredId(null));
        dispatch(mapSetCursor(''));
        shouldRefresh = true;
      }
    };

    const refresh = () => {
      const isMapDragging = getMapDragging(getState());
      if (!isMapDragging && shouldRefresh) {
        if (dispatchRefreshFnc) {
          dispatch(refreshFnc());
        } else {
          refreshFnc();
        }
      }
    };

    const el = getEventListener(getState());
    el.setFeatureHoverEL(onMouseMove, refresh, key, lf);
  };

export const activateContextMenuEL = () => (dispatch, getState) => {
  getEventListener(getState()).activateContextMenuEL();
};

export const deactivateContextMenuEL = () => (dispatch, getState) => {
  getEventListener(getState()).deactivateContextMenuEL();
};
