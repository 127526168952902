import angular from 'angular';

import ImpersonationController from './impersonation.controller';

const impersonationComponent = {
  bindings: {
    farm: '<',
    impersDisable: '&',
  },
  controller: ImpersonationController,
};

export default angular.module('app.admin.impersonation', []).component('impersonationComponent', impersonationComponent)
  .name;
