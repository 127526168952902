import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './auth.constants';

import Jwt from '../../../services/Jwt.service';

// see the counterpart AngularJS implementation in Auth.doGetAccessToken
export const getAccessToken = () => {
  let headers;

  // Send the old authorization in order to restore context of
  // the original and impersonated user on BE. We use a different header
  // from Authorization because the token could be already
  // expired (we only need it to restore the context, not for validation,
  // which is performed by the refresh token)
  if (Jwt.isImpersonatedToken()) {
    headers = {
      'X-Expired-Authorization': Jwt.getTokenValue(),
    };
  }

  return {
    [RSAA]: {
      endpoint: 'authenticate?',
      method: methods.GET,
      module: modules.GATEWAY,
      credentials: 'include',
      headers,
      options: {
        mode: 'cors',
      },
      noAuthHeader: true,
      types: [types.GET_ACCESS_TOKEN, types.GET_ACCESS_TOKEN_SUCCESS, types.GET_ACCESS_TOKEN_FAILURE],
    },
  };
};

export const setAccessToken = payload => ({
  type: types.SET_ACCESS_TOKEN,
  token: payload,
});

export const saveRefreshTokenPromise = promise => ({
  type: types.SAVE_REFRESH_TOKEN_PROMISE,
  promise,
});

export const clearRefreshTokenPromise = () => ({
  type: types.CLEAR_REFRESH_TOKEN_PROMISE,
});
