import { sortBy } from 'lodash';

import { AREA_FORM_FIELDS, GPS_COORDINATES_PRECISION, GEOMETRY_WHOLE_COORDINATES } from '../irrigation.constants';

import { Geometry, Device } from '../../shared/api/irrigation/devices/devices.types';
import { CreateOrEditAreaState, DeviceToEdit } from '../reducer/createArea.reducer.types';

const mapGeometry = (geometry: Geometry) => {
  const geometryConfig = {
    type: 'Point',
    coordinates: [0, 0],
  };
  if (geometry && geometry.coordinates) {
    return {
      ...geometryConfig,
      coordinates: [
        geometry.coordinates[0].toFixed(GPS_COORDINATES_PRECISION),
        geometry.coordinates[1].toFixed(GPS_COORDINATES_PRECISION),
      ],
    };
  }
  return geometryConfig;
};

const mapDevices = (devices: Device[]) => devices.map(device => ({
  id: device.id,
  externalId: device.externalId,
  name: device.name,
  geometry: mapGeometry(device.geometry),
}));

const editDevice = (state: CreateOrEditAreaState, deviceId: string, field: string, value: string) =>
  state.devicesToEdit.map(device => {
    if (device.id === deviceId) {
      switch (field) {
        case AREA_FORM_FIELDS.NAME:
          return {
            ...device,
            name: value,
          };
        case AREA_FORM_FIELDS.LATITUDE:
          return {
            ...device,
            geometry: {
              ...device.geometry,
              coordinates: [value, device.geometry.coordinates[1]],
            },
          };
        case AREA_FORM_FIELDS.LONGITUDE:
          return {
            ...device,
            geometry: {
              ...device.geometry,
              coordinates: [device.geometry.coordinates[0], value],
            },
          };
        case GEOMETRY_WHOLE_COORDINATES:
          return {
            ...device,
            geometry: {
              ...device.geometry,
              coordinates: value,
            },
          };

        default:
          return device;
      }
    }
    return device;
  });

const sortDevices = (devices: DeviceToEdit[], order = 'asc') => {
  const sort = sortBy(devices, [AREA_FORM_FIELDS.NAME]);
  if (order === 'desc') return sort.reverse();
  return sort;
};

export const REDUCER_HELPERS = {
  mapGeometry,
  mapDevices,
  editDevice,
  sortDevices,
};

export const createPayloadForEditEndpoint = (selectedData: DeviceToEdit[]) => selectedData.map(device => ({
  ...device,
  geometry: {
    ...device.geometry,
    coordinates: [parseFloat(device.geometry.coordinates[0]), parseFloat(device.geometry.coordinates[1])],
  },
}));

type Payload = {
  areaName: string
  devices: DeviceToEdit[]
}

export const createPayloadForCreateEndpoint = (selectedData: Payload) => (
  {
    name: selectedData.areaName,
    devices: selectedData.devices.map(device => ({
      ...device,
      geometry: {
        ...device.geometry,
        coordinates: [parseFloat(device.geometry.coordinates[0]), parseFloat(device.geometry.coordinates[1])],
      },
    })),
  }
);
