import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getOperations } from '../../../shared/api/telematics/drives/drives.selectors';

import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import CfFormControl from '../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import CfFormikTimePicker from '../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker';
import { CfFormikErrors, CfFormikProps } from '../../../types';
import { getOperationDuration, getOperationName } from '../../helpers';

import ProductionOperationSelector from './ProdutionOperationSelector';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { OperationTo, ProductionOperationTo, TelematicsOperation } from '../../../shared/api/telematics/telematics.types';
import { TelematicsItemFormValues, TelematicsOperationFormValues } from '../../telematics.types';

const validate = (values: TelematicsOperationFormValues) => {
  const errors: CfFormikErrors<TelematicsOperationFormValues> = {};
  const isParcelOperation = values.operation === TelematicsOperation.PARCEL;

  if (!values.timeFrom) {
    errors.timeFrom = 'validation.required';
  } else if (!values.timeFrom?.isValid()) {
    errors.timeFrom = 'validation.timeNotValid';
  }

  if (!values.timeTo) {
    errors.timeTo = 'validation.required';
  } else if (!values.timeTo?.isValid()) {
    errors.timeTo = 'validation.timeNotValid';
  }

  if (!!values.timeFrom?.isValid() && !!values.timeTo?.isValid()) {
    const diff = values.timeTo.diff(values.timeFrom, 'minutes');
    if (diff < 0 || diff > (24 * 60)) {
      errors.duration = 'validation.timeNotValid';
    }
  }

  if (isParcelOperation) {
    if (!values.cultivated) {
      errors.cultivated = 'validation.required';
    }
    if (!values.productionOperation?.code) {
      errors.productionOperation = 'validation.required';
    }
  }

  if (values.cultivated < 0 || values.cultivated > 99999) {
    errors.cultivated = 'TelematicsList.validation.fiveDigits';
  }

  if (values.distance < 0 || values.distance > 99999) {
    errors.distance = 'TelematicsList.validation.fiveDigits';
  }

  return errors;
};

interface Props {
  initialValues: TelematicsItemFormValues,
  onClose(): void;
  onSubmit(newValues: TelematicsOperationFormValues): void;
  operations: OperationTo[];
  selectedOperation?: TelematicsOperation;
}

const TelematicsOperationDialog: FC<Props> = ({
  initialValues,
  onClose,
  onSubmit,
  operations,
  selectedOperation,
}) => (
  <Formik<TelematicsOperationFormValues>
    enableReinitialize={true}
    onSubmit={onSubmit}
    validate={validate}
    validateOnBlur={false}
    validateOnChange={false}
    initialValues={{
      operation: initialValues?.operation || selectedOperation,
      timeFrom: initialValues?.timeFrom,
      timeTo: initialValues?.timeTo,
      productionOperation: initialValues?.productionOperation || {},
      cultivated: initialValues?.cultivated || 0,
      distance: initialValues?.distance ? parseFloat(initialValues.distance.toFixed(2)) : 0,
    }}
  >
    {({ errors, setFieldValue, submitForm, values }: CfFormikProps<TelematicsOperationFormValues>) => {
      const durationValue = getOperationDuration(values.timeFrom, values.timeTo);

      const handleCultivatedBlur = () => {
        setFieldValue('cultivated', Math.round(values.cultivated * 10000) / 10000);
      };
      const handleDistanceBlur = () => {
        setFieldValue('distance', Math.round(values.distance * 100) / 100);
      };

      const handleProductionOperationChange = (value: ProductionOperationTo) => setFieldValue('productionOperation', value);
      return (
        <Form>
          <CfDialog
            acceptText={<FormattedMessage id="common.save" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onCancel={onClose}
            onClose={onClose}
            opened={!!selectedOperation}
            title={values.operation ? getOperationName(operations, values.operation) : ''}
          >
            <>
              {values.operation && <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  label={<FormattedMessage id="TelematicsList.operation" />}
                  name="operation"
                  select
                >
                  {operations.map((operation) =>
                    <MenuItem key={operation.code} value={operation.code}>{operation.name}</MenuItem>,
                  )}
                </Field>
              </CfFormControl>}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CfFormControl>
                    <Field
                      component={CfFormikTimePicker}
                      customDate={initialValues?.timeFrom}
                      label={<FormattedMessage id="TelematicsList.timeFrom" />}
                      name="timeFrom"
                    />
                  </CfFormControl>

                </Grid>
                <Grid item xs={6}>
                  <CfFormControl>
                    <Field
                      component={CfFormikTimePicker}
                      customDate={initialValues?.timeTo}
                      customSeconds={59}
                      label={<FormattedMessage id="TelematicsList.timeTo" />}
                      name="timeTo"
                    />
                  </CfFormControl>
                </Grid>
              </Grid>

              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled
                  error={!!errors.duration}
                  helperText={errors.duration && <FormattedMessage id={errors.duration} />}
                  label={<FormattedMessage id="TelematicsList.operationDuration" />}
                  name="duration"
                  value={durationValue}
                />
              </CfFormControl>

              <CfFormControl>
                <ProductionOperationSelector
                  defaultValue={values?.productionOperation}
                  error={!!errors.productionOperation}
                  helperText={errors.productionOperation && <FormattedMessage id={errors.productionOperation} />}
                  label={<FormattedMessage id="TelematicsList.productionOperation" />}
                  onChange={handleProductionOperationChange}
                />
              </CfFormControl>

              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  error={!!errors.cultivated}
                  helperText={errors.cultivated && <FormattedMessage id={errors.cultivated} />}
                  label={<FormattedMessage id="TelematicsList.operationArea" />}
                  name="cultivated"
                  onBlur={handleCultivatedBlur}
                  type="number"
                />
              </CfFormControl>

              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  error={!!errors.distance}
                  helperText={errors.distance && <FormattedMessage id={errors.distance} />}
                  label={<FormattedMessage id="TelematicsList.operationDistance" />}
                  name="distance"
                  onBlur={handleDistanceBlur}
                  type="number"
                />
              </CfFormControl>
            </>
          </CfDialog>
        </Form>
      );
    }}
  </Formik>
);

const mapStateToProps = (state: TelematicsState) => ({
  operations: getOperations(state),
});

export default connect(mapStateToProps)(TelematicsOperationDialog);
