/**
 * Created by brasko on 4.9.2015.
 */

/* @ngInject */
export default function Tabs(Logger) {
  const objects = {
    classifiers: [
      {
        state: 'farm.active.classifiers.private.seeds',
        heading: 'components.farm.classifiers.seed.seeds',
        view: 'seeds',
        index: 0
      },
      {
        state: 'farm.active.classifiers.private.fertilizers',
        heading: 'components.farm.classifiers.fertilizers.fert',
        view: 'fertilizers',
        index: 1
      },
      {
        state: 'farm.active.classifiers.private.pors',
        heading: 'components.farm.classifiers.pors.por',
        view: 'pors',
        index: 2
      }
    ],
    classifiersPublic: [
      {
        state: 'farm.active.classifiers.public.pors',
        heading: 'components.farm.classifiers.pors.por',
        view: 'porsPublic',
        index: 0
      }
    ],
    actions: [
      {
        state: 'farm.active.actions.planned',
        heading: 'components.farm.actions.planned.planned',
        view: 'planned',
        index: 0
      },
      {
        state: 'farm.active.actions.past',
        heading: 'components.farm.actions.confirmed.confirmed',
        view: 'past',
        index: 1
      }
    ],
    parcel_activities: [
      {
        state: 'farm.active.parcel.activities.crops',
        heading: 'shared.crops',
        view: 'crops',
        index: 0
      },
      {
        state: 'farm.active.parcel.activities.eph',
        heading: 'shared.eph',
        view: 'eph',
        index: 1
      },
      {
        state: 'farm.active.parcel.activities.other',
        heading: 'shared.other-actions',
        view: 'other',
        index: 2
      }
    ]
  };

  const service = {
    getTabsTpl
  };
  return service;

  // ///////////////////////

  function getTabsTpl(attr) {
    if (!attr || typeof attr !== 'string' || !objects.hasOwnProperty(attr)) {
      Logger.error(`Invalid attribute type or format: ${attr}`);
      return {};
    }
    return objects[attr];
  }
}
