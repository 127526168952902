import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules } from '../../api.constants';
import * as types from './lpis.constants';

export const updateLpis = (lpisId, lpisDto) => ({
  [RSAA]: {
    endpoint: `lpis/${lpisId}?`,
    method: 'PUT',
    module: modules.CORE,
    body: JSON.stringify(lpisDto),
    types: [types.UPDATE_LPIS, types.UPDATE_LPIS_SUCCESS, types.UPDATE_LPIS_FAILURE],
  },
});
