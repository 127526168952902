import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';

import { GEOM_TYPES } from '../geometry/Geometry.service';

import CommonLineStyle from './CommonLineStyle.service';
import CommonPointStyle from './CommonPointStyle.service';
import CommonPolygonStyle from './CommonPolygonStyle.service';

export const STROKE_COLOR = {
  DEFAULT: 'rgba(255, 255, 255, 1)',
  DISABLED: 'rgba(44, 44, 44, 0.8)',
  SELECTED: 'rgba(235, 166, 7, 1)',
  AVAILABLE: 'rgba(255, 255, 255, 1)',
  REACHABLE: 'rgba(155, 155, 155, 1)',
  ERROR: 'rgba(219, 68, 55, 1)',
  LPIS: 'rgba(51, 51, 51, 1)',
  LPIS_PUBLIC: 'rgba(102, 255, 0, 1)',
  BUFFER: 'rgba(252, 215, 131, 1)',
  MEASUREMENT: 'rgba(192, 192, 192, 1)',
  MEASUREMENT_LINE: 'rgba(250, 250, 250, 1)',
};

export const FILL_COLOR = {
  DEFAULT: 'rgba(255, 255, 255, 0.15)',
  HOVERED: 'rgba(255, 255, 255, 0.75)',
  DISABLED: 'rgba(44, 44, 44, 0.45)',
  DETAIL: 'rgba(255, 255, 255, 0.85)',
  SELECTED: 'rgba(235, 166, 7, 0.5)',
  AVAILABLE: 'rgba(255, 255, 255, 0.55)',
  REACHABLE: 'rgba(155, 155, 155, 0.55)',
  ERROR: 'rgba(219, 68, 55, 0.5)',
  DRAWING: 'rgba(235, 166, 7, 0.25)',
  REDUCTION: 'rgba(0, 0, 0, 0.15)',
  TRANSPARENT: 'rgba(0, 0, 0, 0)',
  LPIS: 'rgba(0, 0, 0, 0)',
  HISTORICAL: 'rgba(235, 166, 7, 0.75)',
  MEASUREMENT: 'rgba(255, 255, 255, 0.25)',
};

export const TEXT_COLOR = {
  LPIS_PUBLIC: 'rgba(0, 113, 0, 1)',
};

export const STYLE_TYPES = {
  DEFAULT: 'DEFAULT',
  HOVERED: 'HOVERED',
  DISABLED: 'DISABLED',
  DETAIL: 'DETAIL',
  SELECTED: 'SELECTED',
  AVAILABLE: 'AVAILABLE',
  REACHABLE: 'REACHABLE',
  DRAWING: 'DRAWING',
  DRAWN: 'DRAWN',
  DRAWN_ERROR: 'DRAWN_ERROR',
  REDUCTION: 'REDUCTION',
  TRANSPARENT: 'TRANSPARENT',
  LPIS: 'LPIS',
  BUFFER: 'BUFFER',
  MEASUREMENT: 'MEASUREMENT',
};

export default class CommonStylesService {
  static getGeometryStyle(options) {
    const { fill, image, stroke, ...rest } = options;
    return new Style({
      ...(stroke && {
        stroke: new Stroke({
          ...stroke,
        }),
      }),
      ...(fill && {
        fill: new Fill({
          ...fill,
        }),
      }),
      ...(image && {
        image: new CircleStyle({
          radius: image.radius,
          fill: new Fill({
            color: image.fill.color,
          }),
          stroke: new Stroke({
            color: image.stroke.color,
            width: image.stroke.width,
          }),
        }),
      }),
      ...rest,
    });
  }

  static getLabelStyle(sColor, fColor) {
    return new Style({
      text: new Text({
        font: '500 12px Roboto, sans-serif',
        overflow: true,
        fill: new Fill({
          color: fColor,
        }),
        stroke: new Stroke({
          color: sColor,
          width: 5,
        }),
        padding: [5, 5, 5, 5],
      }),
    });
  }

  static getStylesMap() {
    return {
      [GEOM_TYPES.POLYGON]: {
        [STYLE_TYPES.DEFAULT]: CommonPolygonStyle.getDefault(),
        [STYLE_TYPES.HOVERED]: CommonPolygonStyle.getHovered(),
        [STYLE_TYPES.DISABLED]: CommonPolygonStyle.getDisabled(),
        [STYLE_TYPES.DETAIL]: CommonPolygonStyle.getDetail(),
        [STYLE_TYPES.SELECTED]: CommonPolygonStyle.getSelected(),
        [STYLE_TYPES.AVAILABLE]: CommonPolygonStyle.getAvailable(),
        [STYLE_TYPES.REACHABLE]: CommonPolygonStyle.getReachable(),
        [STYLE_TYPES.DRAWING]: CommonPolygonStyle.getDrawing(),
        [STYLE_TYPES.DRAWN]: CommonPolygonStyle.getDrawn(),
        [STYLE_TYPES.DRAWN_ERROR]: CommonPolygonStyle.getDrawnError(),
        [STYLE_TYPES.REDUCTION]: CommonPolygonStyle.getDrawingReduction(),
        [STYLE_TYPES.TRANSPARENT]: CommonPolygonStyle.getTransparent(),
        [STYLE_TYPES.LPIS]: CommonPolygonStyle.getLpis(),
        [STYLE_TYPES.BUFFER]: CommonPolygonStyle.getBuffer(),
        [STYLE_TYPES.MEASUREMENT]: CommonPolygonStyle.getMeasurement(),
      },
      [GEOM_TYPES.LINESTRING]: {
        [STYLE_TYPES.DRAWING]: CommonLineStyle.getDrawing(),
        [STYLE_TYPES.DRAWN]: CommonLineStyle.getDrawn(),
        [STYLE_TYPES.DRAWN_ERROR]: CommonLineStyle.getDrawnError(),
        [STYLE_TYPES.BUFFER]: CommonLineStyle.getBuffer(),
        [STYLE_TYPES.MEASUREMENT]: CommonLineStyle.getMeasurement(),
      },
      [GEOM_TYPES.POINT]: {
        [STYLE_TYPES.DRAWING]: CommonPointStyle.getDrawing(),
        [STYLE_TYPES.DRAWN]: CommonPointStyle.getDrawed(),
        [STYLE_TYPES.TRANSPARENT]: CommonPointStyle.getTransparent(),
        [STYLE_TYPES.DRAWN_ERROR]: CommonPointStyle.getDrawedError(),
        [STYLE_TYPES.MEASUREMENT]: CommonPointStyle.getMeasurement(),
      },
    };
  }

  static getStyle(geomType, styleType) {
    return CommonStylesService.getStylesMap()[geomType][styleType];
  }
}
