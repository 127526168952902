import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';

import SnackbarText from '../SnackbarText/SnackbarText';

const tool = tools.MEASURE;

class MeasureHint extends Component {
  getHint = stage => {
    switch (stage) {
      case stages.STAGE_1:
        return (
          <SnackbarText color="light">
            <FormattedMessage id={`hint.${tool}_${stage}`} />
          </SnackbarText>
        );
      default:
        return null;
    }
  };

  render() {
    const { data, stage } = this.props;
    return this.getHint(stage, data);
  }
}

MeasureHint.propTypes = {
  stage: PropTypes.string,
  data: PropTypes.object,
};

MeasureHint.defaultProps = {
  stage: null,
  data: null,
};

export default MeasureHint;
