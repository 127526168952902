import React, { useState, useEffect, FC, useMemo } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCountryCodes, isFetchingCountryCodes } from '../../../../shared/api/telematics/admin/telematicsAdmin.selectors';

import { NAMESPACE } from '../reducer/telematicsAdmin.reducer';

import { getCountryCodesApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import CfFilter from '../../../../shared/containers/CfFilter/CfFilter';
import useCountryCodes from '../../../../shared/hooks/useCountryCodes';
import { Thunk } from '../../../../types';

import { AdminState, TelematicsAdvancedFilter } from '../../../../reducers/admin.reducer.types';

interface Props {
  countryCodes: string[];
  fetchCountryCodes: () => void;
  isFetchingCountryCodes: boolean;
  langId: string;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  selectItem: {
    marginBottom: 10,
  },
}));

const AdvancedFilter: FC<Props> = ({
  countryCodes,
  fetchCountryCodes,
  isFetchingCountryCodes,
  langId,
}) => {
  useEffect(() => {
    fetchCountryCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filter, setFilter] = useState<TelematicsAdvancedFilter>({});
  const countryCodesWithName = useCountryCodes(countryCodes, langId);

  const intl = useIntl();

  const farmStates = useMemo(() => ([
    {
      name: intl.formatMessage({ id: 'TelematicsAdmin.advancedFilter.status.enabled' }),
      value: true,
    },
    {
      name: intl.formatMessage({ id: 'TelematicsAdmin.advancedFilter.status.disabled' }),
      value: false,
    },
  ]), [intl]);

  const classes = useStyles();

  return (
    <CfFilter
      filterState={filter}
      namespace={NAMESPACE}
      setAdvancedFilterState={setFilter}
    >
      <Grid className={classes.container} container>
        <Grid className={classes.selectItem} item xs={12}>
          <CfAutocomplete
            defaultValues={filter?.countryCodes || []}
            id="farm-country-filter"
            isFetching={isFetchingCountryCodes}
            isMultiple
            label={<FormattedMessage id="TelematicsAdmin.advancedFilter.country" />}
            onChange={(values) => { setFilter({ ...filter, countryCodes: values }); }}
            suggestions={countryCodesWithName}
            testId="node-type-filter"
        />
        </Grid>
        <Grid className={classes.selectItem} item xs={12}>
          <CfAutocomplete
            defaultValues={filter?.enabled}
            id="farm-status-filter"
            label={<FormattedMessage id="TelematicsAdmin.advancedFilter.status" />}
            onChange={(value) => { setFilter({ ...filter, enabled: value }); }}
            suggestions={farmStates}
            testId="node-type-filter"
        />
        </Grid>
      </Grid>
    </CfFilter>
  );
};

const mapStateToProps = (state: AdminState) => ({
  isFetchingCountryCodes: isFetchingCountryCodes(state),
  countryCodes: getCountryCodes(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  fetchCountryCodes: getCountryCodesApi,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter);
