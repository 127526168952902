// CfReduxFormSelect
import Select from '@mui/material/Select';

import { createComponent, mapError } from '../../reduxFormUtils';

export default createComponent(
  Select,
  ({
    defaultValue,
    input,
    input: { onBlur, onChange, value, ...restInputProps },
    onChange: onChangeFromField,
    ...restProps
  }) =>
    mapError({
      ...restProps,
      ...restInputProps,
      value,
      onChange: evt => {
        onChange(evt.target.value);
        if (onChangeFromField) {
          onChangeFromField(evt.target.value);
        }
      },
      onBlur: () => onBlur(value),
    }),
);
