import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../shared/api/api.constants';
import * as types from './products.constants';

export const getProducts = () => {
  const p = {
    external: true,
    'per-page': 100,
  };
  return {
    [RSAA]: {
      endpoint: `products?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_PRODUCTS, types.GET_PRODUCTS_SUCCESS, types.GET_PRODUCTS_ERROR],
    },
  };
};
