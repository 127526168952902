import React, { Fragment, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfReduxFormTextField from '../../../../../shared/components/form/CfReduxFormTextField/CfReduxFormTextField';
import * as validators from '../../../../../shared/misc/validators';

export function PasswordChangeForm(props) {
  const { disabled } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Fragment>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled={disabled}
          fullWidth
          label={<FormattedMessage id="PasswordChange.currentPassword" />}
          name="oldPassword"
          type="password"
          validate={[validators.required]}
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled={disabled}
          fullWidth
          label={<FormattedMessage id="PasswordChange.newPassword" />}
          name="newPassword"
          type={showPassword ? 'text' : 'password'}
          validate={[validators.required, validators.noWhitespace, validators.minLength6]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  disabled={disabled}
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={e => e.preventDefault()}
                  size="large">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </CfFormControl>
    </Fragment>
  );
}

PasswordChangeForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PasswordChangeForm;
