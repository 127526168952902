import React, { FC, ReactNode, useEffect } from 'react';

import { Theme } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEquipments, getIsFetchingEquipments } from '../../../shared/api/telematics/drives/drives.selectors';

import { fetchEquipments } from '../../actions/telematicsList.actions';

import { resetEquipments } from '../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { EquipmentTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: '-5px',
  },
}));

interface TelematicsDetailEquipmentSelectorProps {
  disabled: boolean;
  error: boolean;
  fetchEquipments(): void;
  helperText?: string | ReactNode;
  isFetching: boolean;
  label: ReactNode;
  onChange(item: EquipmentTo): void;
  options: EquipmentTo[];
  resetEquipments(): void;
  selectedCode: string;
}

const handleGetSelected = (option: EquipmentTo, value?: EquipmentTo | null) =>
  option.code === value?.code;

const handleGetLabel = (option?: EquipmentTo | null) => {
  if (!option) return '';
  if (option.name) return `${option.name} (${option.code})`;
  return option.code;
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const TelematicsDetailEquipmentSelector: FC<TelematicsDetailEquipmentSelectorProps> = (
  { disabled,
    error,
    fetchEquipments,
    helperText,
    isFetching,
    label,
    onChange,
    options,
    resetEquipments,
    selectedCode,
  }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchEquipments();
    return () => {
      resetEquipments();
    };
  }, [fetchEquipments, resetEquipments]);

  const handleRenderOption = (option: EquipmentTo) => (
    <div>
      <div>{option.name ?? option.code}</div>
      <div className={classes.id}>{option.code}</div>
    </div>
  );

  const selectedEquipment = selectedCode &&
    options.find(option => option.code === selectedCode) || { code: selectedCode, name: '' };

  return (
    <CfAutocomplete
      defaultValues={selectedEquipment}
      disabled={disabled}
      error={error}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-equipment-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="telematics-detail-equipment-selector"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getEquipments(state),
  isFetching: getIsFetchingEquipments(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchEquipments,
      resetEquipments,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsDetailEquipmentSelector);
