import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import EditableText from '../../../../shared/components/common/EditableText/EditableText';
import MapBottomPanel from '../../../../shared/components/specific/MapBottomPanel/MapBottomPanel';
import MapBottomPanelHeader from '../../../../shared/components/specific/MapBottomPanelHeader/MapBottomPanelHeader';
import withWidth from '../../../../shared/hocs/withWidth';
import MapParcelDetailBody from '../MapParcelDetailBody/MapParcelDetailBody';

const styles = theme => ({
  parcelNameWrapper: {
    display: 'flex',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  detailGroup: {
    margin: 5,
  },
  parcelDetailLink: {
    textTransform: 'uppercase',
    fontWeight: 500,
    margin: '2px 0px',
  },
  parcelBlockNumber: {
    color: theme.palette.grey[500],
    marginRight: 10,
  },
});

class MapParcelDetail extends Component {
  confirmEditing = newName => this.props.updateParcelName(this.props.parcel.id, newName);

  render() {
    const { classes, countryCode, farmId, isFetching, parcel, width } = this.props;
    // TODO - replace link to parcel detail with NavLink tag
    return (
      <MapBottomPanel onPanelClose={this.props.clearParcelDetail}>
        <Fragment>
          <MapBottomPanelHeader testId="parcel-detail-header">
            <div>
              <div className={classes.parcelNameWrapper}>
                <div className={classes.parcelBlockNumber} data-test="block-number">
                  {parcel.blockNr}
                </div>
                <EditableText
                  initialValue={parcel.localName}
                  labelTestId="parcel-name"
                  maxWidth={width === 'xs' || width === 'sm' ? '200px' : '140px'}
                  onConfirmEditing={newValue => this.confirmEditing(newValue)}
                  truncate={true}
                />
              </div>
              <div className={classes.parcelDetailLink}>
                <a
                  color="primary"
                  data-test="parcel-detail-link"
                  href={`/farm/${farmId}/parcels/${parcel.id}/overview`}
                >
                  <FormattedMessage id="common.detail" />
                </a>
              </div>
            </div>
          </MapBottomPanelHeader>
          <MapParcelDetailBody countryCode={countryCode} isFetching={isFetching} parcel={parcel} />
        </Fragment>
      </MapBottomPanel>
    );
  }
}

MapParcelDetail.propTypes = {
  parcel: PropTypes.object,
  isFetching: PropTypes.bool,
  farmId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  updateParcelName: PropTypes.func.isRequired,
  clearParcelDetail: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

MapParcelDetail.defaultProps = {
  parcel: undefined,
  isFetching: false,
};

export default compose(withStyles(styles), withWidth())(MapParcelDetail);
