import * as types from './management.constants';

export const setCropLegislativeCode = crop => dispatch =>
  dispatch({
    type: types.SET_CROP_LEGISLATIVE_CODE,
    cropLegislativeCode: crop.legislativeCode,
  });

export const resetCropLegislativeCode = () => dispatch =>
  dispatch({
    type: types.RESET_CROP_LEGISLATIVE_CODE,
  });
