import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMaterialIds, getParcelsIds } from '../selectors/actions.selectors';

import {
  updateMaterialSubtractions,
  removeMaterialSubtractions,
  getParcelsRestrictedArea,
} from '../actions/actions.actions';

import ListSelectorError from '../../../../shared/components/form/ListSelectorError/ListSelectorError';
import { getDisplayName } from '../../../../shared/hocs/hocHelpers';
import withOpen from '../../../../shared/hocs/withOpen';
import { COLOR_GREY } from '../../../../theme';
import { targetCropChanged, expenseCountChanged } from '../misc/action.helpers';

const styles = {
  materialsLabel: {
    color: COLOR_GREY[500],
  },
};

const actionExpenseControl = () => WrappedComponent => {
  class ActionExpenseControl extends Component {
    constructor(props) {
      super(props);

      this.state = {
        index: undefined,
        isAdding: true,
        expense: null,
        // switch to rerender list when splice is used, solves redux-form bug
        listRerenderSwitch: false,
      };
    }

    componentDidUpdate(prevProps) {
      const { expenses: oldExpenses, targetCrop: oldTargetCrop } = prevProps;

      const {
        driftClass,
        expenses: newExpenses,
        formName,
        isEditing,
        materialIds,
        parcelIds,
        parcels: newParcels,
        targetCrop: newTargetCrop,
      } = this.props;

      const expenseCountDiff = expenseCountChanged(oldExpenses, newExpenses);
      const targetCropDiff = targetCropChanged(oldTargetCrop, newTargetCrop);

      /*
       * Update parcel subraction areas when:
       * 1) target crop changed
       * 2) there is at least 1 expense
       */
      if (targetCropDiff && newExpenses.length && isEditing) {
        this.updateMaterialSubtractions(materialIds, parcelIds, driftClass, newTargetCrop, newExpenses);
      }

      /*
       * Update parcel subraction areas when:
       * 1) expenses changed
       * 2) there is at least 1 expense
       */
      if ((oldExpenses.length !== newExpenses.length) && newExpenses.length && isEditing) {
        this.updateMaterialSubtractions(materialIds, parcelIds, driftClass, newTargetCrop, newExpenses);
      }

      /*
       * Remove parcel subraction areas when:
       * 1) expenses changed
       * 2) there is no expense
       */
      if ((oldExpenses.length !== newExpenses.length) && !newExpenses.length) {
        this.removeMaterialSubtractions(newParcels);
      }

      /*
       * Update restriction areas of all parcels when:
       * 1) expense count changed
       * 2) and there is at least 1 parcel
       */
      if (expenseCountDiff && newParcels.length) {
        this.props.getParcelsRestrictedArea(newParcels, formName);
      }
    }

    onSuggestionSelected = expense => {
      const { materialIds } = this.props;

      if (!materialIds.includes(expense.material.id)) {
        this.setState({
          index: undefined,
          isAdding: true,
          expense,
        });
        this.props.onOpen();
      }
    };

    updateMaterialSubtractions(materialIds, parcelIds, driftClass, targetCrop, newExpenses) {
      const { formName, parcels } = this.props;
      parcels.forEach((parcel, index) =>
        this.props.updateMaterialSubtractions(
          materialIds,
          parcelIds,
          driftClass,
          parcel,
          targetCrop.legislativeCode,
          newExpenses,
          index,
          formName,
        ),
      );
    }

    removeMaterialSubtractions(newParcels) {
      newParcels.forEach((parcel, index) => {
        this.props.removeMaterialSubtractions(parcel, index, this.props.formName);
      });
    }

    handleDialogOpen = (field, index) => {
      this.setState({
        index,
        isAdding: false,
        expense: field,
      });
      this.props.onOpen();
    };

    handleDialogClose = () => {
      this.setState({
        index: undefined,
        isAdding: false,
        expense: null,
      });
      this.props.onClose();
    };

    handleDialogAccept = expense => {
      const { fields } = this.props;
      const { index, isAdding } = this.state;

      this.handleDialogClose();
      if (isAdding) {
        fields.push(expense);
        return;
      }

      this.setState({
        index: undefined,
        listRerenderSwitch: !this.state.listRerenderSwitch,
      });

      fields.remove(index);
      fields.insert(index, expense);
    };

    handleItemRemove = index => {
      this.props.fields.remove(index);
    };

    render() {
      const {
        SelectorProps: { component: Selector, ...restSelectorProps },
        classes,
        fields,
        formName,
        isEditing,
        isOpen,
        maxExpenseCount,
        meta: { error, submitFailed },
        onOpen,
        ...restProps
      } = this.props;

      const { expense, isAdding, listRerenderSwitch } = this.state;

      return (
        <Fragment>
          {isEditing && (maxExpenseCount ? fields.length < maxExpenseCount : true) ? (
            <Fragment>
              <Selector onSuggestionSelected={this.onSuggestionSelected} {...restSelectorProps} />
              {submitFailed && error && <ListSelectorError error={error} />}
            </Fragment>
          ) : (
            <div className={classes.materialsLabel}>
              <FormattedMessage id="common.materials" />:
            </div>
          )}
          <WrappedComponent
            expense={expense}
            fields={fields}
            formName={formName}
            isAdding={isAdding}
            isEditing={isEditing}
            isOpen={isOpen}
            listRerenderSwitch={listRerenderSwitch}
            onAccept={this.handleDialogAccept}
            onClose={this.handleDialogClose}
            onOpen={this.handleDialogOpen}
            onRemove={this.handleItemRemove}
            {...restProps}
          />
        </Fragment>
      );
    }
  }

  const mapStateToProps = (state, props) => ({
    materialIds: getMaterialIds(props.formName, state),
    parcelIds: getParcelsIds(props.formName, state),
  });

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        updateMaterialSubtractions,
        removeMaterialSubtractions,
        getParcelsRestrictedArea,
      },
      dispatch,
    );

  ActionExpenseControl.propTypes = {
    intl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    meta: PropTypes.object.isRequired,
    parcels: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    expenses: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    materialIds: PropTypes.array.isRequired,
    parcelIds: PropTypes.array.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    updateMaterialSubtractions: PropTypes.func.isRequired,
    removeMaterialSubtractions: PropTypes.func.isRequired,
    getParcelsRestrictedArea: PropTypes.func.isRequired,
    fertilizerAdditionalProps: PropTypes.object,
    chemistryAdditionalProps: PropTypes.object,
    maxExpenseCount: PropTypes.number,
    driftClass: PropTypes.string,
    targetCrop: PropTypes.object,
    SelectorProps: PropTypes.shape({
      component: PropTypes.object.isRequired,
      placeholder: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  };

  ActionExpenseControl.defaultProps = {
    parcels: [],
    expenses: [],
    fertilizerAdditionalProps: {},
    chemistryAdditionalProps: {},
    maxExpenseCount: null,
    driftClass: '',
    targetCrop: null,
    SelectorProps: {
      disabled: false,
    },
  };

  ActionExpenseControl.displayName = `ActionExpenseControl(${getDisplayName(WrappedComponent)})`;

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withOpen,
    injectIntl,
    withStyles(styles),
  )(ActionExpenseControl);
};

export default actionExpenseControl;
