import React, { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfStaticMap from '../../../../../shared/components/specific/CfStaticMap/CfStaticMap';

const styles = theme => ({
  cropstamp: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: theme.palette.common.white,
    position: 'absolute',
    zIndex: 100,
    left: 0,
    top: 0,
    padding: '2px 6px',
    fontSize: '12px',
    borderTopLeftRadius: '5px',
  },
  datestamp: {
    fontSize: '12px',
    textAlign: 'center',
    padding: '2px 0px',
  },
  typestamp: {
    color: theme.palette.grey[500],
    fontSize: '12px',
    position: 'absolute',
    zIndex: 100,
    left: 0,
    top: -20,
  },
  cropstampActive: {
    backgroundColor: 'rgba(235, 166, 7, 1)',
  },
  href: {
    '&:focus': {
      outline: 'none',
    },
  },
  mapImage: {
    width: 150,
    height: 150,
  },
});

const VaMapSourceItem = props => {
  const {
    classes,
    intl: { formatDate },
    isSelected,
    mapId,
    onClick,
    satellite: {
      crop: { name },
      dateFrom: from,
      dateTo: to,
      type,
      zones,
    },
    showType,
  } = props;

  const datestamp = from && to ? `${formatDate(from)} − ${formatDate(to)}` : null;

  return (
    <Fragment>
      <div className={classes.mapImage}>
        <a className={classes.href} onClick={onClick} role="button" tabIndex={0}>
          {type && showType && (
            <span className={classes.typestamp} data-test="type">
              <Typography variant="caption">
                <FormattedMessage id={`VaMapSourceDialog.${type}`} />
              </Typography>
            </span>
          )}
          <span className={`${classes.cropstamp} ${isSelected ? classes.cropstampActive : ''}`}>
            <span data-test="crop">{name}</span>
          </span>
          <CfStaticMap geometries={zones} isSelected={isSelected} mapId={mapId} />
        </a>
      </div>
      {datestamp && <div className={classes.datestamp}>{datestamp}</div>}
    </Fragment>
  );
};

VaMapSourceItem.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  satellite: PropTypes.object,
  mapId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  showType: PropTypes.bool,
};

VaMapSourceItem.defaultProps = {
  isSelected: false,
  showType: false,
  satellite: null,
};

export default compose(injectIntl, withStyles(styles))(VaMapSourceItem);
