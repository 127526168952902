import { formValueSelector, arrayPush, change } from 'redux-form';

import { getChemistryByName, resetChemistry } from '../../../../shared/api/core/chemistry/chemistry.api';
import { getFertilizersByName, resetFertilizers } from '../../../../shared/api/core/fertilizers/fertilizers.api';
import {
  addParcels,
  getParcelsById,
  getParcelsByName,
  getParcelsInZone,
  resetParcels,
} from '../../../../shared/api/core/parcels/parcels.api';
import { getSeeds, getSeedsByName as getSeedsByNameApi, resetSeeds as clearSeedsApi } from '../../../../shared/api/core/seeds/seeds.api';
import {
  calculateTotalSubtractableArea,
  resetTotalSubtractableArea,
  createParcelSubtractionHistory,
  getMaterialSubtractableAreas,
  getPredefinedSubtractableAreas,
  updateParcelsSubtractionAreas,
} from '../../../../shared/api/core/subtractableAreas/subtractableAreas.api';
import { getUnits } from '../../../../shared/api/core/units/units.api';
import { getZones, resetZones } from '../../../../shared/api/core/zones/zones.api';
import {
  exportVariableApplication as exportVariableApplicationApi,
  exportVariableApplicationLegacy as exportVariableApplicationLegacyApi,
  getAGIntegratedJobs,
} from '../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import FileService from '../../../../shared/services/File.service';
import { getActionTypes } from '../../../api/actionTypes/actionTypes.api';
import { getProducts } from '../../../api/products/products.api';
import SubtractableAreasService from '../../eph/services/SubtractableAreas.service';
import ParcelSubtractionMapper from '../services/ParcelSubtractionMapper.service';

export const getExpensesExportsTableId = (applicationId) => `expenses-exports-${applicationId}`;

export const fetchActionTypes = () => dispatch => {
  dispatch(getActionTypes());
};

export const fetchProducts = () => dispatch => {
  dispatch(getProducts());
};

export const fetchSeeds = () => dispatch => {
  dispatch(getSeeds());
};

export const fetchUnits = () => dispatch => {
  dispatch(getUnits());
};

export const fetchParcelsAndZonesSuggestions = (searchInput, parcelsOnly = false, zonesOnly = false, isSown = false) =>
  dispatch => {
    if (parcelsOnly) {
      return dispatch(getParcelsByName('suggestions', searchInput, isSown));
    }

    if (zonesOnly) {
      return dispatch(getZones({ name: searchInput }));
    }

    dispatch(getParcelsByName('suggestions', searchInput, isSown));
    dispatch(getZones({ name: searchInput }));
  };

export const clearParcelsAndZonesSuggestions = (parcelsOnly = false, zonesOnly = false) => dispatch => {
  if (parcelsOnly) {
    return dispatch(resetParcels('suggestions'));
  }

  if (zonesOnly) {
    return dispatch(resetZones());
  }

  dispatch(resetParcels('suggestions'));
  dispatch(resetZones());
};

export const addParcelOrZoneParcelsToAdd = parcelOrZone => dispatch => {
  const isZone = 'parcelCount' in parcelOrZone;
  if (isZone && parcelOrZone.parcelCount > 0) {
    dispatch(getParcelsInZone('additions', [parcelOrZone.id]));
  } else if (!isZone) {
    dispatch(addParcels('additions', [parcelOrZone]));
  }
};

export const getAllParcelsInZone = zone => dispatch =>
  dispatch(getParcelsInZone('additions', [zone.id])).then(sa => sa.payload);

export const addInitParcelsToAdd = initParcelIds => dispatch => {
  dispatch(getParcelsById('additions', initParcelIds));
};

export const addInitZoneParcelsToAdd = zoneIds => dispatch => {
  dispatch(getParcelsInZone('additions', zoneIds));
};

export const clearParcelsToAdd = () => dispatch => {
  dispatch(resetParcels('additions'));
};

export const updateParcelsList = (parcels, form) => dispatch => {
  dispatch(change(form, 'parcels', parcels));
};

export const getChemistryAndFertilizersByName = (searchInput, chemOnly = false, fertOnly = false) => dispatch => {
  if (chemOnly) {
    return dispatch(getChemistryByName(searchInput));
  }

  if (fertOnly) {
    return dispatch(getFertilizersByName(searchInput));
  }

  dispatch(getChemistryByName(searchInput));
  dispatch(getFertilizersByName(searchInput));
};

export const clearChemistryAndFertilizers = (chemOnly, fertOnly) => dispatch => {
  if (chemOnly) {
    return dispatch(resetChemistry());
  }

  if (fertOnly) {
    return dispatch(resetFertilizers());
  }

  dispatch(resetChemistry());
  dispatch(resetFertilizers());
};

export const getSeedsByName = searchInput => dispatch => {
  dispatch(getSeedsByNameApi(searchInput));
};

export const clearSeeds = () => dispatch => {
  dispatch(clearSeedsApi());
};

/*
 * PARCEL SUBTRACTIONS RELATED FUNCTIONS
 */

/*
 * Fetch already defined subtraction areas
 * e.g. absolute areas defined by map drawing
 */
export const getPredefinedSubtractions = (parcelId, parcelIds) => dispatch =>
  dispatch(getPredefinedSubtractableAreas(parcelId, parcelIds)).then(sa => sa.payload);

/*
 * Save parcels subtractions in order to use
 * the same subtractions in next application (EPH action)
 */
export const saveParcelsSubtractions = parcels => dispatch => {
  parcels.forEach(parcel => {
    ParcelSubtractionMapper.createNewParcelSubtractionTos(parcel.subtractableAreas).forEach(parcelSubtractionTo => {
      dispatch(createParcelSubtractionHistory(parcel.id, parcelSubtractionTo));
    });
  });
};

/*
 * Recalculate subtractable areas for parcels already in form
 * because adding new parcel can affect subtractable areas
 * in the previously added parcels (e.g. if the share border)
 */
export const updateParcelsSubtractions = (parcelIds, parcels) => dispatch => {
  const subtractions = ParcelSubtractionMapper.createParcelsSubtractions(parcels);
  return dispatch(updateParcelsSubtractionAreas(parcelIds, subtractions)).then(res =>
    parcels.map(parcel => {
      parcel.subtractableAreas = parcel.subtractableAreas.map(oldSa =>
        SubtractableAreasService.updateAreaInSubtractableArea(parcel.id, res.payload, oldSa),
      );
      return parcel;
    }),
  );
};

export const updateParcelsSubtractionsNewForm = (parcelIds, parcels) => dispatch => {
  const subtractions = ParcelSubtractionMapper.createNewFormParcelsSubtractions(parcels);
  return dispatch(updateParcelsSubtractionAreas(parcelIds, subtractions)).then(res =>
    parcels.map(parcel => {
      const boundaryChecked = parcel.subtractableAreas.boundaryChecked;
      const waterChecked = parcel.subtractableAreas.waterChecked;
      parcel.subtractableAreas.absolute = parcel.subtractableAreas.absolute.map(oldSa =>
        SubtractableAreasService.updateAreaInSubtractableArea(parcel.id, res.payload, oldSa),
      );
      parcel.subtractableAreas.boundary = parcel.subtractableAreas.boundary.map(oldSa =>
        SubtractableAreasService.updateAreaInSubtractableArea(parcel.id, res.payload, oldSa),
      );
      parcel.subtractableAreas.boundaryChecked = boundaryChecked;
      parcel.subtractableAreas.water = parcel.subtractableAreas.water.map(oldSa =>
        SubtractableAreasService.updateAreaInSubtractableArea(parcel.id, res.payload, oldSa),
      );
      parcel.subtractableAreas.waterChecked = waterChecked;
      return parcel;
    }),
  );
};

/*
 * MATERIAL SUBTRACTIONS RELATED FUNCTIONS
 */

/*
 * ??
 */
export const getMaterialSubtractions = (
  materialIds,
  parcelIds,
  driftClass,
  parcel,
  cropLegCode,
  expenses,
) => dispatch =>
  dispatch(
    getMaterialSubtractableAreas(
      parcel.id,
      ParcelSubtractionMapper.createActionMaterialsTo(materialIds, parcelIds, driftClass, cropLegCode, expenses),
    ),
  ).then(({ payload: materialSas }) =>
    materialSas.map(materialSa => ({
      ...materialSa,
      checked: true,
      material: true,
    })),
  );

/*
 * Update parcel subtractions assigned based on used material
 * by fetching new material subtractions and merging them with
 * existing non-material subtractions
 */
export const updateMaterialSubtractions = (
  materialIds,
  parcelIds,
  driftClass,
  parcel,
  cropLegCode,
  expenses,
  index,
  formName,
) => dispatch => {
  dispatch(removeMaterialSubtractions(parcel));
  dispatch(
    getMaterialSubtractableAreas(
      parcel.id,
      ParcelSubtractionMapper.createActionMaterialsTo(materialIds, parcelIds, driftClass, cropLegCode, expenses),
    ),
  ).then(({ payload: materialSas }) => {
    const updatedSubstractableAreas = SubtractableAreasService.uniteSubtractableAreas(
      parcel.subtractableAreas,
      materialSas,
    );
    dispatch(updateSubtractableAreas(updatedSubstractableAreas, index, formName));
    dispatch(getParcelRestrictedArea(parcel.id, index, updatedSubstractableAreas, parcelIds, formName));
  });
};

/*
 * Clear parcel subtractions assigned based on used material
 */
export const removeMaterialSubtractions = (parcel, index, formName) => dispatch => {
  const updatedSubstractableAreas = parcel.subtractableAreas
    ? parcel.subtractableAreas.filter(sa => sa.material !== true)
    : [];
  dispatch(updateSubtractableAreas(updatedSubstractableAreas, index, formName));
};

/*
 * RESTRICTED AREA RELATED FUNCTIONS
 * (restricted area = sum of subtractable areas for given parcel, computed by BE)
 */

export const getRestrictedArea = (parcelId, subtractableAreas, parcelIds) => dispatch => {
  dispatch(calculateParcelRestrictedArea(parcelId, subtractableAreas, parcelIds)).then(payload =>
    dispatch(change('subtractableAreas', 'restrictedArea', payload)),
  );
};

export const resetRestrictedArea = () => dispatch => {
  dispatch(resetTotalSubtractableArea());
};

/*
 * Calculates and updates total of subtractable areas (= restricted area)
 * based on checked subtractable areas for one parcels
 */
export const getParcelRestrictedArea = (parcelId, index, subtractableAreas, parcelIds, formName) => dispatch => {
  dispatch(calculateParcelRestrictedArea(parcelId, subtractableAreas, parcelIds)).then(payload =>
    dispatch(updateParcelRestrictedArea(payload, index, formName)),
  );
};

/*
 * Calculates and updates total of subtractable areas (= restricted area)
 * based on checked subtractable areas for all parcels
 */
export const getParcelsRestrictedArea = (parcels, formName) => dispatch => {
  const parcelIds = parcels.map(parcel => parcel.id);
  parcels.forEach((parcel, index) => {
    dispatch(getParcelRestrictedArea(parcel.id, index, parcel.subtractableAreas, parcelIds, formName));
  });
};

/*
 * Calculates total of subtractable areas (= restricted area)
 * based on checked subtractable areas for one parcel
 */
export const calculateParcelRestrictedArea = (parcelId, subtractableAreas, parcelIds) => dispatch => {
  const checkedSa = SubtractableAreasService.getCheckedSubtractableAreas(subtractableAreas);
  if (!Array.isArray(checkedSa) || !checkedSa.length) {
    return Promise.resolve(0.0);
  }
  return dispatch(calculateTotalSubtractableArea(parcelId, checkedSa, parcelIds)).then(
    res => res.payload.subtractionGeometry.area,
  );
};

export const addSubtractionArea = (subtractableAreas, parcelId, parcelIds) => dispatch => {
  dispatch(arrayPush('subtractableAreas', 'subtractableAreas', subtractableAreas, parcelId, parcelIds));
};

export const updateSubtractableAreas = (sas, index, formName) => dispatch => {
  dispatch(change(formName, `parcels[${index}].subtractableAreas`, sas));
};

export const updateParcelRestrictedArea = (restrictedArea, index, formName) => (dispatch, getState) => {
  const parcels = formValueSelector(formName)(getState(), 'parcels');
  const newParcels = [...parcels];
  newParcels[index] = {
    ...newParcels[index],
    restrictedArea,
  };
  // dispatch(change(formName, `parcels[${index}].restrictedArea`, restrictedArea));
  dispatch(change(formName, 'parcels', newParcels));
};

export const updateParcelGeometry = (geometry, index, formName) => dispatch => {
  dispatch(change(formName, `parcels[${index}].geometry`, geometry));
};

export const updateVariableExpense = (variableExpense, index, formName) => dispatch => {
  dispatch(change(formName, `expenses[${index}].variableExpense`, variableExpense));
};

export const updateFertilizationType = (formName, newType) => dispatch => {
  dispatch(change(formName, 'fertilizationType', newType));
};

export const updateChemistryEffectiveness = (effectiveness, index, formName) => dispatch => {
  dispatch(change(formName, `expenses[${index}].effectiveness`, effectiveness));
};

export const updateVariableExpenseZoneDose = (payload, totalDose, expenseIndex, zoneIndex, formName) => (
  dispatch,
  getState,
) => {
  const expenses = formValueSelector(formName)(getState(), 'expenses');

  const newZone = {
    ...expenses[expenseIndex].variableExpense.applicationZones[zoneIndex],
    totalDose,
    ...(payload ? { k: payload.k2oIntake } : {}),
    ...(payload ? { accountableN: payload.nitrogenIntake } : {}),
    ...(payload ? { p: payload.p2o5Intake } : {}),
  };

  dispatch(change(formName, `expenses[${expenseIndex}].variableExpense.applicationZones[${zoneIndex}]`, newZone));
};

export const updateVariableExpenseSumValues = (payload, expenseIndex, formName, sumTotalDose, averageDose) => (
  dispatch,
  getState,
) => {
  const expenses = formValueSelector(formName)(getState(), 'expenses');
  const { variableExpense } = expenses[expenseIndex];

  const newVarExpense = {
    ...variableExpense,
    doseHa: averageDose,
    totalDose: sumTotalDose,
    ...(payload ? { k: payload.k2oIntake } : {}),
    ...(payload ? { accountableN: payload.nitrogenIntake } : {}),
    ...(payload ? { p: payload.p2o5Intake } : {}),
  };

  dispatch(change(formName, `expenses[${expenseIndex}].variableExpense`, newVarExpense));
};

export const exportVariableApplications = (applications, exportType, parcelName, parcelNumber) => dispatch => {
  if (exportType === 'COMMON' || exportType === 'TRIMBLE') {
    applications.forEach(({ id }) => {
      dispatch(exportVariableApplicationLegacyApi(id, exportType)).then(res => {
        FileService.processFileResponse(res);
      });
    });
  } else {
    applications.forEach(({ id, material }, index) => {
      dispatch(exportVariableApplicationApi(id, exportType, material, parcelName, parcelNumber))
        .then(() => {
          dispatch(getAGIntegratedJobs(id));
          if (index === 0) {
            setTimeout(() => document.getElementById(getExpensesExportsTableId(id)).scrollIntoView(true), 100);
          }
        });
    });
  }
};
