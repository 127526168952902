export const links = {
  cs: {
    precisionFarming: 'https://www.cleverfarm.cz/precizni-zemedelstvi',
    sensors: 'https://www.cleverfarm.cz/senzory',
    homepage: 'https://www.cleverfarm.cz/',
    telematics: 'https://www.cleverfarm.cz/kontakt',
    irrigation: 'https://www.cleverfarm.cz/cleverirrigation',
  },
  en: {
    precisionFarming: 'https://www.cleverfarm.ag/precision-farming',
    sensors: 'https://www.cleverfarm.ag/sensors',
    homepage: 'https://www.cleverfarm.ag/',
    telematics: 'https://www.cleverfarm.ag/contact',
    irrigation: 'https://www.cleverfarm.ag/cleverirrigation',
  },
};
