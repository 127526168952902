export default class MapColumnsService {
  static getMapColumnsByWidth = (width, isMapEnlargedVariant) => {
    let mapColumns = 4;
    if (isMapEnlargedVariant) {
      if (width === 'xs') {
        mapColumns = 0;
      } else if (width === 'sm') {
        mapColumns = 5;
      } else if (width === 'md') {
        mapColumns = 7;
      } else {
        mapColumns = 8;
      }
    } else if (width === 'xs' || width === 'sm' || width === 'md') {
      mapColumns = 0;
    }
    return mapColumns;
  };

  static getMapColumns = (width, isMapEnlargedVariant, mapColumns) => {
    let mapColumnsNew = 0;
    if (mapColumns === 0) {
      if (width === 'xs' || (!isMapEnlargedVariant && width === 'sm')) {
        mapColumnsNew = 12;
      } else if (!isMapEnlargedVariant && width === 'md') {
        mapColumnsNew = 4;
      } else {
        mapColumnsNew = MapColumnsService.getMapColumnsByWidth(width, isMapEnlargedVariant);
      }
    }
    return mapColumnsNew;
  };
}
