/**
 * Created by ondrejzvara on 9.3.16.
 */


/*@ngInject*/ 
export default function cfPorSelector() {

    var directive = {
        restrict: 'E',
        template: require('./cf-por-selector.html'),
        require: ['ngModel', 'cfClassifierSelector'],
        bindToController: {
            model: '=ngModel',
            external: '=?',
            allowCreate: '=?',
            errId: '@?',
            validators: '@?',
            onStateChange: '=?', //EMPTY, TYPING, SELECTED, CREATE
            disabled: '=?'
        },
        controller: cfPorSelectorController,
        controllerAs: 'cfPorSelectorCtrl'
    };

    return directive;
}


/*@ngInject*/
function cfPorSelectorController(ChemistriesResource) {
    var vm = this;

    vm.errId = vm.errId ? vm.errId : null;
    vm.validators = vm.validators ? vm.validators : 'none';
    vm.createOptionTemplateUrl = vm.allowCreate ? "cf-por-selector-create-option-template.html" : null;
    vm.optionTemplateUrl = "cf-por-selector-option-template.html";
    vm.getPorsByName = getPorsByName;
    vm.optionFormat = optionFormat;

    function getPorsByName(search) {
        return ChemistriesResource.getChemistries( {}, { external: vm.external, search: search, cropId: vm.cropId } ).then(function(result) {
            var results = result.plain();
            return vm.allowCreate ? [{ name: search }].concat(results) : results;
        });
    }

    function optionFormat(option) {
        if (option) {
            var arr = _.remove([option.name, option.marketingAuth, option.regNumber, option.aktualState], function(val) {
                return !_.isNil(val) && !_.isEmpty(val);
            } );
            return _.join(arr, ", ");
        }
        return null;
    }

}


