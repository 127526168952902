import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './sensors.constants';

export const getSensorData = (sensorId, locationId, dateFrom, dateTo, gran, aggregation) => {
  const params = {
    'location-id': locationId,
    duration: `${dateFrom}/${dateTo}`,
    granularity: gran,
    function: aggregation,
  };

  return {
    [RSAA]: {
      endpoint: `sensors/${sensorId}/data?${queryString.stringify(params)}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        {
          type: types.GET_SENSOR_DATA,
          payload: () => ({
            sensorId,
            aggregation,
          }),
        },
        {
          type: types.GET_SENSOR_DATA_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(data => ({
              data,
              sensorId,
              aggregation,
            })),
        },
        types.GET_SENSOR_DATA_ERROR,
      ],
    },
  };
};

export const patchSensor = (id, type) => {
  const dto = {
    type,
  };

  return {
    [RSAA]: {
      endpoint: `sensors/${id}?`,
      method: methods.PATCH,
      module: modules.IOT,
      body: JSON.stringify(dto),
      types: [types.PATCH_SENSOR, types.PATCH_SENSOR_SUCCESS, types.PATCH_SENSOR_ERROR],
    },
  };
};

export const resetSensorData = () => ({
  type: types.RESET_SENSOR_DATA,
});
