import React from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  itemDescWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 25,
  },
  itemValueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    itemValueWrapper: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  rowWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
});

export const SatelliteInfoDialogItem = props => {
  const { children, classes, label, testId } = props;

  return (
    <Grid className={classes.rowWrapper} container data-test={testId}>
      <Grid className={classes.itemDescWrapper} item sm={3} xs={12}>
        {label}
      </Grid>
      <Grid className={classes.itemValueWrapper} item sm={9} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

SatelliteInfoDialogItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

SatelliteInfoDialogItem.defaultProps = {
  testId: 'info',
};

export default withStyles(styles)(SatelliteInfoDialogItem);
