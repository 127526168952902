import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Localization from '../../../../../shared/services/Localization.service';

const styles = theme => ({
  ha: {
    color: theme.palette.text.secondary,
  },
});

function SubtractableArea(props) {
  const { classes, intl, sa } = props;
  const localizedRestrictionValue = Localization.num2strNonFixed(sa.restrictionValue, intl.locale);
  const localizedRestrictionArea = Localization.num2str(sa.restrictionArea, intl.locale, 2);
  return (
    <span>
      {(sa.restrictionType !== 'Absolute') && `${localizedRestrictionValue} m`}
      {(sa.restrictionType !== 'Absolute') && (
        <span className={classes.ha}>
          {` (${localizedRestrictionArea} ha)`}
        </span>
      )}
      {(sa.restrictionType === 'Absolute') && `${localizedRestrictionArea} ha`}
    </span>
  );
}

SubtractableArea.propTypes = {
  sa: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

SubtractableArea.defaultProps = {
  isEditing: false,
};

export default injectIntl(withStyles(styles)(SubtractableArea));
