import React, { FC, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Theme } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getErrorDrives } from '../../../shared/api/telematics/drives/drives.selectors';
import { getTelematicsListAdvancedFilter, getTelematicsListDateFrom, getTelematicsListDateTo, getTelematicsListSelectedOnPage, getTelematicsListTextFilter } from '../../selectors/telematicsList.selectors';

import { setDateFrom, setDateTo } from '../../actions/telematicsList.actions';

import { TELEMATICS_URLS } from '../../telematics.constants';

import { NAMESPACE as namespace } from '../../reducer/telematicsList.reducer';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import DateRangeSelector from '../../../shared/components/common/DateRangeSelector/DateRangeSelector';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import SendAllButton from '../../../shared/components/common/SendAllButton/SendAllButton';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { Thunk } from '../../../types';
import TelematicsExport from '../../components/TelematicsExport/TelematicsExport';
import TelematicsAdvancedFilter from '../TelematicsAdvancedFilter/TelematicsAdvancedFilter';
import TelematicsListStateSwitcher from '../TelematicsListStateSwitcher/TelematicsListStateSwitcher';
import TelematicsTable from '../TelematicsTable/TelematicsTable';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriverTo, MachineTo } from '../../../shared/api/telematics/telematics.types';
import { TelematicsAdvancedFilter as TelematicsAdvancedFilterType } from '../../telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    paddingBottom: theme.spacing(0.5),
    gap: theme.spacing(1.5),
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto min-content',
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date date date'
        'actions . filter'
      `,
    },
  },
  listActionsItem: {
    gridArea: 'actions',
  },
  date: {
    gridArea: 'date',
    display: 'flex',
  },
  textFilter: {
    gridArea: 'text-filter',
    maxWidth: 500,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      width: '100%',
    },
  },
  advancedFilter: {
    gridArea: 'filter',
  },
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
  },
  buttonToMap: {
    borderRadius: '50px',
    color: theme.palette.common.black,
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },

}));

export interface TelematicsListProps {
  advancedFilter: TelematicsAdvancedFilterType;
  dateFrom: string;
  dateTo: string;
  errorDrives?: { code: number, isError: boolean; };
  farmId: string;
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo;
  langId: string;
  ngRedirectToMainMapWithFilters: (
    dateFrom: string, dateTo: string, machine?: MachineTo[], driver?: DriverTo[]
  ) => void,
  selectedOnPage: string[];
  setDateFrom: (date?: string) => void;
  setDateTo: (date?: string) => void;
  textFilter: string;
}

export const TelematicsList: FC<TelematicsListProps> = ({
  advancedFilter,
  dateFrom,
  dateTo,
  errorDrives,
  farmId,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  langId,
  ngRedirectToMainMapWithFilters,
  selectedOnPage,
  setDateFrom,
  setDateTo,
  textFilter,
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (initDateFrom) setDateFrom(initDateFrom);
    if (initDateTo) setDateTo(initDateTo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateNew = () => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.new}`);
  };

  const isMachineOrDriverFilterSelected = !!(advancedFilter?.machine || advancedFilter?.driver);

  return (
    <CfErrorPage error={errorDrives}>
      <div className={classes.wrapper}>
        <PageHeader
          actionButtons={
            <>
              <div className={classes.actionButton}>
                {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                }<SendAllButton handleClick={() => {}} tooltipTitle="todo: send all tooltip" />
              </div>
              <div className={classes.actionButton}>
                <TelematicsExport />
              </div>
              <div>
                <Tooltip title={<FormattedMessage id="Telematics.create" />}>
                  <Fab
                    aria-label="create telematics"
                    className={classes.button}
                    color="secondary"
                    disabled={false}
                    onClick={handleCreateNew}
                    size="medium"
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </div>
            </>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading dataTest="telematics-heading" value={<FormattedMessage id="common.telematics" />} />
          }
        />

        <div className={classes.filters}>
          <div className={classes.listActionsItem}>
            <TelematicsListStateSwitcher selected={selectedOnPage} />
          </div>

          <div className={classes.date}>
            <DateRangeSelector
              dateFrom={dateFrom}
              dateTo={dateTo}
              setDateFrom={setDateFrom}
              setDateTo={setDateTo}
            />
          </div>

          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="telematics-list-text-filter"
              namespace={namespace}
              translId="TelematicsList.textFilterPlaceholder"
            />
          </div>

          <div className={classes.advancedFilter}>
            <TelematicsAdvancedFilter
              initMachineFilter={initMachineFilter}
              langId={langId}
              namespace={namespace}
            />
          </div>
          {isMachineOrDriverFilterSelected && <div>
            <Button
              className={classes.buttonToMap}
              size="small"
              variant="contained"
              onClick={() => {
                ngRedirectToMainMapWithFilters(dateFrom, dateTo, advancedFilter?.machine, advancedFilter?.driver);
              }}
            >
              <FormattedMessage id="TelematicsList.buttonToMap" />
            </Button>
          </div>}

        </div>
        <TelematicsTable
          langId={langId}
          goToDetail={(driveId: string) => {
            history.push(`/farm/${farmId}/telematics/${driveId}`);
          }}
        />
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  selectedOnPage: getTelematicsListSelectedOnPage(state),
  textFilter: getTelematicsListTextFilter(state),
  errorDrives: getErrorDrives(state),
  advancedFilter: getTelematicsListAdvancedFilter(state),
  dateFrom: getTelematicsListDateFrom(state),
  dateTo: getTelematicsListDateTo(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setDateFrom,
      setDateTo,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsList);
