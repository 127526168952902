import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { Link } from 'react-router-dom';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import SubtractableAreaLink from '../../../eph/components/SubtractableAreaLink/SubtractableAreaLink';
import ActionsService from '../../services/Actions.service';

const styles = {
  tooltip: {
    fontSize: 12,
  },
  subheading: {
    color: 'inherit',
  },
};

class ActionParcelsList extends Component {
  getCurrentNitrogen(field) {
    return get(this.props.parcelsFertilization, `current[${field.id}].accountableN`);
  }

  getExpectedNitrogen(field) {
    return get(this.props.parcelsFertilization, `expected[${field.id}].accountableN`);
  }

  renderParcelItem = (fieldName, index, field) => (
    <SelectionItem
      editing={this.props.isEditing}
      key={fieldName}
      onRemoveItem={() => {
        this.props.onItemRemove(index);
      }}
    >
      <Grid container spacing={0}>
        <Grid item sm={3} xs={12}>
          <Link to={`/farm/${this.props.farm.id}/parcels/${field.id}/overview`}>
            <SelectionItemHeading
              classes={{ subheading: this.props.classes.subheading }}
              reversed={true}
              subheading={field.blockNr}
            >
              <div>{field.localName}</div>
            </SelectionItemHeading>
          </Link>
        </Grid>
        <Grid item sm={2} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
            <SubtractableAreaLink
              editing={this.props.isEditing}
              formName={this.props.formName}
              index={index}
              parcel={field}
              subtractableAreasIds={this.props.subtractableAreasIds}
            />
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={3} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id={ActionsService.getCropLabel(field)} />}>
            <div>
              {field.actionSeedApplication
                ? field.actionSeedApplication.seed.name
                : field.currentSeedApplication.seed.name}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={2} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="ActionParcelsList.NSA" />}>
            <div>
              {field.nitrateVulnerable ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={2} xs={6}>
          <SelectionItemColumn
            label={
              <span>
                N <FormattedMessage id={'unit.kg'} />
                /ha
              </span>
            }
          >
            <Tooltip
              classes={{ tooltip: this.props.classes.tooltip }}
              placement="bottom"
              title={<FormattedMessage id="ActionParcelsList.accountableNitrogen" />}
            >
              <div>{this.renderNitrogen(field)}</div>
            </Tooltip>
          </SelectionItemColumn>
        </Grid>
      </Grid>
    </SelectionItem>
  );

  renderNitrogen(field) {
    const currentNitrogen = this.getCurrentNitrogen(field);
    return currentNitrogen !== undefined ? (
      <span>
        <CfFormattedNumber value={currentNitrogen} />
        {this.renderExpectedNitrogen(currentNitrogen, field)}
      </span>
    ) : (
      '...'
    );
  }

  renderExpectedNitrogen(currentNitrogen, field) {
    const expectedNitrogen = this.getExpectedNitrogen(field);
    const addedNitrogen = currentNitrogen + expectedNitrogen;
    return expectedNitrogen !== undefined ? (
      <span>
        {'\u2192'} <CfFormattedNumber value={addedNitrogen} />
      </span>
    ) : (
      ''
    );
  }

  render() {
    const { fields } = this.props;
    return (
      <div id="action-parcel-list">
        {fields.map((fieldName, index) => {
          const field = fields.get(index);
          return this.renderParcelItem(fieldName, index, field);
        })}
      </div>
    );
  }
}

ActionParcelsList.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  parcelsFertilization: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func,
  formName: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  subtractableAreasIds: PropTypes.array,
  farm: PropTypes.object.isRequired,
};

ActionParcelsList.defaultProps = {
  onItemRemove: () => {},
  subtractableAreasIds: undefined,
};

export default compose(withStyles(styles), withFarm())(ActionParcelsList);
