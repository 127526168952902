import React, { Fragment, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SensorsService from '../../../shared/services/Sensors.service';
import NodeDeactivation from '../NodeDeactivation/NodeDeactivation';
import NodeLocationEditGroup from '../NodeLocationEditGroup/NodeLocationEditGroup';
import NodeLocationExport from '../NodeLocationExport/NodeLocationExport';

function NodeLocationActionsMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { deactivate, groups, match, ngGoToNewNotification, nodeLocation, setGroups } = props;
  const isHistoric = SensorsService.isHistoric(nodeLocation);

  return (
    <Fragment>
      <IconButton data-test="sensor-action-menu" onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isHistoric && (
          <MenuItem aria-label="create-notification" onClick={ngGoToNewNotification}>
            <FormattedMessage id="Notifications.createNotification" />
          </MenuItem>
        )}
        {!isHistoric && (
          <NodeLocationEditGroup groups={groups} menuItem={true} nodeLocation={nodeLocation} setGroups={setGroups} />
        )}
        <NodeLocationExport nodeLocation={nodeLocation} />
        {!isHistoric && <NodeDeactivation deactivate={deactivate} match={match} nodeLocation={nodeLocation} />}
      </Menu>
    </Fragment>
  );
}

NodeLocationActionsMenu.propTypes = {
  ngGoToNewNotification: PropTypes.func.isRequired,
  nodeLocation: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  setGroups: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  deactivate: PropTypes.func.isRequired,
};

export default NodeLocationActionsMenu;
