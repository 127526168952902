import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './parcels.constants';

export const getParcels = params => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    // 'sort-dir': params.order,
    // 'sort-col': params.sortColumn
  };

  return {
    [RSAA]: {
      endpoint: `parcels?${queryString.stringify(p)}&`,
      method: methods.GET,
      context: null,
      module: modules.CORE,
      types: [types.GET_PARCELS, types.GET_PARCELS_SUCCESS, types.GET_PARCELS_FAILURE],
    },
  };
};

export const getParcelsSearch = params => {
  const endpoint = 'filter';
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    'sort-dir': params.order,
    'sort-col': params.orderBy,
    ids: params.ids,
    'neighbors-more-than': params.neighborsMoreThan,
    localNameBlockNr: params.localNameBlockNr || params.textFilter,
    zoneIds: params.zoneIds,
    cropLegislativeCode: params.cropLegislativeCode,
  };

  return {
    [RSAA]: {
      endpoint: `parcels/${endpoint}?${queryString.stringify(p)}&`,
      method: 'GET',
      context: null,
      module: modules.CORE,
      types: [types.GET_PARCELS, types.GET_PARCELS_SUCCESS, types.GET_PARCELS_FAILURE],
    },
  };
};

export const getParcelsByName = (context, search, isSown = false) => ({
  [RSAA]: {
    endpoint: `parcels/filter?localNameBlockNr=${search}&sown-only=${isSown}&`,
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_PARCELS, types.GET_PARCELS_SUCCESS, types.GET_PARCELS_FAILURE],
  },
});

export const getParcelsMapActionable = params => {
  const p = {
    page: 1,
    'per-page': 50,
    ids: params.ids,
    'neighbors-more-than': params.neighborsMoreThan,
    localNameBlockNr: params.localNameBlockNr,
  };

  return {
    [RSAA]: {
      endpoint: `parcels/map/actionable?${queryString.stringify(p)}&`,
      method: 'GET',
      context: null,
      module: modules.CORE,
      types: [
        types.GET_PARCELS_MAP_ACTIONABLE,
        types.GET_PARCELS_MAP_ACTIONABLE_SUCCESS,
        types.GET_PARCELS_MAP_ACTIONABLE_FAILURE,
      ],
    },
  };
};

export const getParcelById = (context, parcelId) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}?`,
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_PARCEL, types.GET_PARCEL_SUCCESS, types.GET_PARCEL_FAILURE],
  },
});

export const getParcelsById = (context, parcelIds) => ({
  [RSAA]: {
    endpoint: `parcels?${queryString.stringify({ ids: parcelIds })}&`,
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_PARCELS, types.GET_PARCELS_SUCCESS, types.GET_PARCELS_FAILURE],
  },
});

export const getParcelsInZone = (context, zoneIds) => ({
  [RSAA]: {
    endpoint: `parcels/filter?zoneIds=${zoneIds.join(',')}&per-page=200&`,
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_PARCELS, types.GET_PARCELS_SUCCESS, types.GET_PARCELS_FAILURE],
  },
});

export const getParcelsStatistics = (context = null) => ({
  [RSAA]: {
    endpoint: 'parcels/statistics?',
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_PARCELS_STATISTICS, types.GET_PARCELS_STATISTICS_SUCCESS, types.GET_PARCELS_STATISTICS_FAILURE],
  },
});

export const getParcelActions = (context, parcelId, params) => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    'sort-dir': params.order,
    'sort-col': params.orderBy,
    from: params.dateFilter,
    'search-str': params.textFilter,
  };

  return {
    [RSAA]: {
      endpoint: `parcels/${parcelId}/actions?${queryString.stringify(p)}&`,
      method: methods.GET,
      context,
      module: modules.CORE,
      types: [types.GET_PARCEL_ACTIONS, types.GET_PARCEL_ACTIONS_SUCCESS, types.GET_PARCEL_ACTIONS_FAILURE],
    },
  };
};

export const getParcelFertilization = (context, parcelId) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/fertilization?`,
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [
      types.GET_PARCEL_FERTILIZATION,
      types.GET_PARCEL_FERTILIZATION_SUCCESS,
      types.GET_PARCEL_FERTILIZATION_FAILURE,
    ],
  },
});

export const getParcelsFertilization = parcelIds => ({
  [RSAA]: {
    endpoint: `parcels/fertilization?parcelIds=${parcelIds}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_PARCELS_FERTILIZATION,
      types.GET_PARCELS_FERTILIZATION_SUCCESS,
      types.GET_PARCELS_FERTILIZATION_FAILURE,
    ],
  },
});

export const getParcelSeedingInfo = (context, parcelId) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/seeding-info?`,
    method: 'GET',
    context,
    module: modules.CORE,
    types: [types.GET_PARCEL_SEEDINGS, types.GET_PARCEL_SEEDINGS_SUCCESS, types.GET_PARCEL_SEEDINGS_FAILURE],
  },
});

export const getParcelRestrictions = (context, parcelId) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/restrictions?`,
    method: 'GET',
    context,
    module: modules.CORE,
    types: [
      types.GET_PARCEL_RESTRICTIONS, types.GET_PARCEL_RESTRICTIONS_SUCCESS, types.GET_PARCEL_RESTRICTIONS_FAILURE,
    ],
  },
});

export const patchParcel = (parcelId, parcelPatchDto, context) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}?`,
    method: methods.PATCH,
    context,
    body: JSON.stringify(parcelPatchDto),
    module: modules.CORE,
    types: [types.PATCH_PARCEL, types.PATCH_PARCEL_SUCCESS, types.PATCH_PARCEL_FAILURE],
  },
});

export const createParcel = (parcelToDto, isDryMode) => ({
  [RSAA]: {
    endpoint: `parcels?dry-mode=${isDryMode}&`,
    method: methods.POST,
    body: JSON.stringify(parcelToDto),
    module: modules.CORE,
    types: [types.CREATE_PARCEL, types.CREATE_PARCEL_SUCCESS, types.CREATE_PARCEL_ERROR],
  },
});

export const addParcels = (context, parcels) => ({
  type: types.ADD_PARCELS,
  meta: {
    context,
  },
  payload: parcels,
});

export const resetParcels = context => ({
  type: types.RESET_PARCELS,
  meta: {
    context,
  },
});

export const resetParcelsMapActionable = () => ({
  type: types.RESET_PARCELS_MAP_ACTIONABLE,
  meta: {
    context: null,
  },
});

export const resetParcel = context => ({
  type: types.RESET_PARCEL,
  meta: {
    context,
  },
});

export const resetParcelsStatistics = context => ({
  type: types.RESET_PARCELS_STATISTICS,
  meta: {
    context,
  },
});

export const resetParcelsFertilization = context => ({
  type: types.RESET_PARCELS_FERTILIZATION,
  meta: {
    context,
  },
});

export const getCentersApi = params => {
  const p = {
    search: params.textFilter,
  };

  return {
    [RSAA]: {
      endpoint: `centers/?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_CENTERS_REQUEST,
        types.GET_CENTERS_SUCCESS,
        types.GET_CENTERS_ERROR,
      ],
    },
  };
};

export const resetCentersApi = () => ({
  type: types.RESET_CENTERS,
});

export const putParcelsAssignCenterApi = (centerId, payload) => ({
  [RSAA]: {
    endpoint: `centers/${centerId}/parcels?`,
    method: methods.PUT,
    module: modules.CORE,
    body: JSON.stringify(payload),
    types: [
      types.PUT_ASSIGN_CENTER_REQUEST,
      types.PUT_ASSIGN_CENTER_SUCCESS,
      types.PUT_ASSIGN_CENTER_ERROR,
    ],
  },
});
