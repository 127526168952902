import * as types from './subtractableAreas.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    item: {},
  },
  action,
) => {
  switch (action.type) {
    case types.GET_SUBTRACTABLE_AREA:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_SUBTRACTABLE_AREA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_SUBTRACTABLE_AREA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
