import React, { FC, useEffect, useState } from 'react';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Fab, Theme, Tooltip } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { setDeviceValvesForManualAction, getIsFetchingDeviceValves } from '../../../../shared/api/irrigation/devices/devices.selectors';
import { Namespace } from '../../../selectors/devices.common.selectors';

import { valvesManualControl, fetchDeviceValves } from '../../../actions/devices.actions';

import { IRRIGATION_URLS } from '../../../irrigation.constants';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableCheckbox from '../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import { Thunk } from '../../../../types';
import IrrigationService from '../../../services/Irrigation.service';
import IrrigationStatus from '../../IrrigationStatus/IrrigationStatus';
import ValvesStatus from '../../ValvesStatus/ValvesStatus';

import { payloadPrepareStopManualAction } from './IrrigationManualAction.helper';
import IrrigationManualActionModal from './IrrigationManualActionModal';
import NextActionCell from './NextActionCell';

import { IrrigationState } from '../../../../reducers/irrigation.reducer.types';
import { Device, Valve, ValveManualAction } from '../../../../shared/api/irrigation/devices/devices.types';

interface Props {
  device: Device;
  deviceValves: Valve[];
  farmId: string;
  fetchDeviceValves: (deviceId: string) => void;
  handleContentRefresh: () => void;
  handleHover: (id: string | null) => void;
  isFetchingDeviceValves: boolean;
  isIrrigating: boolean;
  namespace: Namespace;
  selected?: string[];
  valvesManualControl: (deviceId: string, payload: ValveManualAction[], onAfterAction: () => void) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  nameCell: {
    paddingLeft: '12px',
  },
  activateButton: {
    padding: '3px 8px',
    marginBottom: 4,
    width: 'max-content',
  },
  deviceName: {
    fontWeight: 500,
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  fabActive: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabNotActive: {
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

const DeviceTableRow: FC<Props> = ({
  device,
  deviceValves,
  farmId,
  fetchDeviceValves,
  handleContentRefresh,
  handleHover,
  isFetchingDeviceValves,
  isIrrigating,
  namespace,
  selected,
  valvesManualControl,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isActive = IrrigationService.isActive(device);
  const [showDialog, setShowDialog] = useState(false);
  const [hasClickedToStopValves, setHasClickedToStopValves] = useState(false);

  const handleRowClick = () => history.push(`/farm/${farmId}/${IRRIGATION_URLS.DEVICE}/${device.id}`);

  useEffect(() => {
    if (hasClickedToStopValves === true) {
      const stopManualAction = payloadPrepareStopManualAction(deviceValves);
      valvesManualControl(device.id, stopManualAction, handleContentRefresh);
      setHasClickedToStopValves(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceValves]);

  const handleStopManualAction = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    fetchDeviceValves(device.id);
    setHasClickedToStopValves(true);
  };

  const handleOpenModal = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    fetchDeviceValves(device.id);
    setShowDialog(true);
  };

  const handleCloseModal = () => {
    setShowDialog(false);
  };

  const handleSubmitModal = (startManualAction: ValveManualAction[]) => {
    valvesManualControl(device.id, startManualAction, handleContentRefresh);
    setShowDialog(false);
  };

  return (
    <>
      <TableRow
        hover
        key={device.id}
        onClick={handleRowClick}
        onMouseEnter={() => handleHover(device.id)}
        onMouseLeave={() => handleHover(null)}
        style={{ cursor: 'pointer' }}
    >
        {selected && <CfTableCheckbox id={device.id} namespace={namespace} selected={selected} />}
        {!selected && (
        <CfTableCell name={'status'}>
          <IrrigationStatus installed={isActive} />{' '}
        </CfTableCell>
        )}
        <CfTableCell name={'name'}>
          <div className={classes.nameCell}>
            <div className={classes.deviceName}>{device.name}</div>
            <div className={classes.secondaryText}>{device.externalId}</div>
          </div>
        </CfTableCell>

        <CfTableCell name={'valveStatus'}>
          <ValvesStatus installed={isActive} valvesStatus={device.valves} />
        </CfTableCell>
        <NextActionCell device={device} />
        <CfTableCell name={'manualAction'}>
          {isActive &&
          <Tooltip
            title={
              <span>
                <FormattedMessage id={isIrrigating ? 'Irrigation.valveManualAction.off' : 'Irrigation.valveManualAction.on'} />
              </span>
            }
          >
            <Fab
              aria-label="action"
              className={isIrrigating ? classes.fabActive : classes.fabNotActive}
              onClick={isIrrigating ? handleStopManualAction : handleOpenModal}
              size="small"
            >
              <PowerSettingsNewIcon />
            </Fab>
          </Tooltip>
        }
        </CfTableCell>
      </TableRow>
      {showDialog && !isFetchingDeviceValves &&
      <IrrigationManualActionModal
        deviceName={device.name}
        deviceValves={deviceValves}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        showDialog={showDialog}
      />
    }
    </>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  deviceValves: setDeviceValvesForManualAction(state),
  isFetchingDeviceValves: getIsFetchingDeviceValves(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) => bindActionCreators(
  {
    fetchDeviceValves,
    valvesManualControl,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTableRow);
