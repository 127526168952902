import moment from 'moment';

import * as types from '../actions/node.constants';
import * as granularity from '../constants/granularity.constants';

export default () => (
  state = {
    newNode: undefined,
    duration: 1,
    granularity: granularity.G30M,
    dateFrom: moment().startOf('day').toISOString(),
    dateTo: moment().endOf('day').toISOString(),
  },
  action,
) => {
  switch (action.type) {
    case types.SET_DURATION:
      return {
        ...state,
        duration: action.duration,
        granularity: action.granularity,
      };
    case types.RESET_DURATION:
      return {
        ...state,
        duration: 1,
        granularity: granularity.G30M,
      };
    case types.SET_GRANULARITY:
      return {
        ...state,
        granularity: action.granularity,
      };
    case types.RESET_GRANULARITY:
      return {
        ...state,
        granularity: granularity.G30M,
      };
    case types.RESET_NODE:
      return {
        ...state,
        newNode: undefined,
        duration: 1,
        granularity: granularity.G30M,
      };
    case types.EDIT_START:
      return {
        ...state,
        newNode: action.newNode,
      };
    case types.EDIT_CANCEL:
      return {
        ...state,
        newNode: undefined,
      };
    case types.EDIT_UPDATE:
      return {
        ...state,
        newNode: action.newNodeVersion,
      };
    case types.SET_DATE_FROM:
      return {
        ...state,
        dateFrom: action.dateFrom,
      };
    case types.SET_DATE_TO:
      return {
        ...state,
        dateTo: action.dateTo,
      };
    default:
      return state;
  }
};
