import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const StoresIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M26.75,10,16.6,1.33a2.46,2.46,0,0,0-3.2,0L3.25,10a3,3,0,0,0-1,2.23V25.4a1.86,1.86,0,0,0,1.86,1.86h6.4V16.34h9V27.26h6.4a1.86,1.86,0,0,0,1.86-1.86V12.21A3,3,0,0,0,26.75,10Z" />
    </svg>
  </SvgIcon>
);

export default StoresIcon;
