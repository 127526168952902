import PropTypes from 'prop-types';

import useToggles from '../../../toggles/toggles';

const CfToggleSwitch = ({ activeComponent, deactiveComponent, toggleId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, __, isToggleActive] = useToggles();
  const isActive = isToggleActive(toggleId);

  if (isActive) return activeComponent();
  return deactiveComponent();
};

CfToggleSwitch.propTypes = {
  toggleId: PropTypes.string.isRequired,
  activeComponent: PropTypes.func.isRequired,
  deactiveComponent: PropTypes.func,
};

CfToggleSwitch.defaultProps = {
  deactiveComponent: () => null,
};

export default CfToggleSwitch;
