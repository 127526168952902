import moment from 'moment';

import * as commonTypes from '../../../../shared/actions/table.constants';
import { ROWS_PER_PAGE } from '../../../../shared/constants/rowsPerPage.constants';
import * as types from '../actions/store.constants';

export const NAMESPACE = 'store';

export default (reducerNamespace = NAMESPACE) => (
  state = {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE,
    from: moment()
      .startOf('year')
      .toISOString('YYYY-MM-DD'),
    to: '',
    unitId: null,
    amount: null,
  },
  action,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case commonTypes.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case commonTypes.SET_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
      };
    case types.SET_STORE_FROM_FILTER:
      return {
        ...state,
        from: action.from,
        page: 0,
      };
    case types.SET_STORE_TO_FILTER:
      return {
        ...state,
        to: action.to,
        page: 0,
      };
    case types.SET_STORE_UNIT_ID:
      return {
        ...state,
        unitId: action.unitId,
      };
    case types.SET_STORE_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    default:
      return state;
  }
};
