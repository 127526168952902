import moment from 'moment';
import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './reports.constants';

export const getTypes = () => ({
  [RSAA]: {
    endpoint: 'reports/types?',
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_REPORT_TYPES, types.GET_REPORT_TYPES_SUCCESS, types.GET_REPORT_TYPES_ERROR],
  },
});

export const getReport = params => {
  const p = {
    from: moment(params.from).format('YYYY-MM-DD'),
    to: moment(params.to).format('YYYY-MM-DD'),
    parcelIds: params.parcelIds ? params.parcelIds.join() : params.parcelIds,
  };

  return {
    [RSAA]: {
      endpoint: `reports/${params.id}.${params.format}?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_REPORT,
        {
          type: types.GET_REPORT_SUCCESS,
          payload: (action, state, res) => ({
            body: res.blob(),
            disposition: res.headers.get('Content-Disposition'),
            type: res.headers.get('Content-Type'),
            status: res.status,
            id: params.id,
          }),
        },
        types.GET_REPORT_ERROR],
    },
  };
};

export const getSowingPlan = params => {
  const p = {
    year: params.year,
  };
  return {
    [RSAA]: {
      endpoint: `reports/seeding-plan?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_SOWING_PLAN,
        {
          type: types.GET_SOWING_PLAN_SUCCESS,
          payload: (action, state, res) => ({
            body: res.blob(),
            disposition: res.headers.get('Content-Disposition'),
            type: res.headers.get('Content-Type'),
            status: res.status,
            id: 'sowingPlan',
          }),
        },
        types.GET_SOWING_PLAN_ERROR],
    },
  };
};
