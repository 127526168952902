/**
 * Created by mpenak on 7/10/17.
 */

import angular from 'angular';

import SignupController from './signup.controller';

// eslint-disable-next-line import/prefer-default-export
export const signupComponent = {
  bindings: {},
  controller: SignupController,
};

export default angular
  .module('app.core.signup', [])
  .config(config)
  .component('signupComponent', signupComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('signup', {
      url: '/signup',
      views: {
        app: {
          component: 'signupComponent',
        },
      },
    })
    .state('signupImport', {
      url: '/signup/import',
      views: {
        app: {
          component: 'signupComponent',
        },
      },
    })
    .state('signupImportProgress', {
      url: '/signup/import-in-progress',
      views: {
        app: {
          component: 'signupComponent',
        },
      },
    })
    .state('signupImportFailed', {
      url: '/signup/import-failed',
      views: {
        app: {
          component: 'signupComponent',
        },
      },
    });
}
