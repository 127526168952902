import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfPrimaryTab from '../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';

class AdminManagerTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };

    this.tabs = {
      0: {
        url: 'sensors',
      },
      1: {
        url: 'notifications',
      },
      2: {
        url: 'sentinel',
      },
      3: {
        url: 'irrigation',
      },
      4: {
        url: 'telematics',
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.handlePathnameChange(location.pathname);
  }

  handlePathnameChange = pathname => {
    const { tabs } = this;
    let tabValue = 0;
    if (pathname.toLowerCase().includes(tabs[0].url)) {
      tabValue = 0;
    } else if (pathname.toLowerCase().includes(tabs[1].url)) {
      tabValue = 1;
    } else if (pathname.toLowerCase().includes(tabs[2].url)) {
      tabValue = 2;
    } else if (pathname.toLowerCase().includes(tabs[3].url)) {
      tabValue = 3;
    } else if (pathname.toLowerCase().includes(tabs[4].url)) {
      tabValue = 4;
    }

    this.setState({ tabValue });
  };

  handleChange = (event, value) => {
    const { history, match } = this.props;
    const { farmId } = match.params;
    this.setState({ tabValue: value });
    history.push(`/farm/${farmId}/admin/${this.tabs[value].url}`);
  };

  render() {
    const { tabValue } = this.state;
    return (
      <CfPrimaryTabs centered onChange={this.handleChange} tabValue={tabValue}>
        <CfPrimaryTab data-test="admin-iot" label={<FormattedMessage id="AdminManagerTabs.sensors" />} />
        <CfPrimaryTab data-test="admin-notifications" label={<FormattedMessage id="common.notifications" />} />
        <CfPrimaryTab data-test="admin-sentinel" label={<FormattedMessage id="AdminManagerTabs.sentinel" />} />
        <CfPrimaryTab data-test="admin-irrigation" label={<FormattedMessage id="common.irrigation" />} />
        <CfPrimaryTab data-test="admin-telematics" label={<FormattedMessage id="AdminManagerTabs.telematics" />} />
      </CfPrimaryTabs>
    );
  }
}

AdminManagerTabs.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AdminManagerTabs;
