import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import useToggles from '../../../../shared/toggles/toggles';
import NavbarService from '../../services/NavbarService';

export function MainMenuItem(props) {
  const { classes, farmId, isActive, menuItem, mobile, onMenuItemClick } = props;
  // eslint-disable-next-line no-unused-vars
  const [_, __, isToggleActive] = useToggles();
  const Icon = NavbarService.getMenuItemIcon(menuItem.id);

  if (menuItem.toggleId && !isToggleActive(menuItem.toggleId)) {
    return null;
  }

  // todo: "a" is temporary
  // change to "<NavLink to=" after remove angular and updating react-router-dom to version 6 (or higher)
  return (
    <a
      className={`${classes.menuItemLink} ${mobile ? classes.menuItemLinkMobile : ''}`}
      href={`/farm/${farmId}/${menuItem.route}`}
      id={`main-menu-${menuItem.id}`}
      onClick={onMenuItemClick}
      product-fruits={`${menuItem.id}`}
      style={{
        color: isActive ? '#FFFFFF' : undefined,
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.15)' : undefined,
      }}
    >
      <span className={mobile ? classes.navlinkContentMobile : classes.navlinkContentDesktop}>
        {mobile && <Icon className={classes.navlinkIcon} />}
        <FormattedMessage id={menuItem.translationId} />
      </span>
    </a>
  );
}

MainMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  onMenuItemClick: PropTypes.func,
};

MainMenuItem.defaultProps = {
  mobile: false,
  onMenuItemClick: () => {},
};

export default MainMenuItem;
