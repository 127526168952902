import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = {
  buttons: {
    marginTop: 20,
  },
  button: {
    width: '47%',
  },
};

export class CfTextFieldWithConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue,
      error: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
      error: e.target.value === '',
    });
  };

  submit = () => {
    const { value } = this.state;
    if (value) {
      this.props.onAccept(value.trim());
    }
  };

  render() {
    const { acceptText, cancelText, classes, disabled, label, labelTestId, onCancel } = this.props;
    const { error, value } = this.state;
    return (
      <Fragment>
        <Grid alignItems="center" container justifyContent="center" spacing={0}>
          <TextField
            autoFocus={true}
            data-test={labelTestId}
            error={error}
            fullWidth={true}
            helperText={error ? <FormattedMessage id="validation.required" /> : ''}
            label={label}
            name="new-group"
            onChange={e => this.handleChange(e)}
            value={value}
          />
        </Grid>
        <Grid alignItems="center" className={classes.buttons} container justifyContent="space-between" spacing={0}>
          <Button
            className={classes.button}
            data-test="cancel-button"
            disableElevation
            id="dismiss"
            onClick={onCancel}
            type="reset"
            variant="contained"
          >
            {cancelText}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            data-test="accept-button"
            disabled={disabled}
            disableElevation
            id="accept"
            onClick={() => this.submit()}
            variant="contained"
          >
            {acceptText}
          </Button>
        </Grid>

      </Fragment>
    );
  }
}

CfTextFieldWithConfirm.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.object,
  labelTestId: PropTypes.string,
  disabled: PropTypes.bool,
  cancelText: PropTypes.any,
  acceptText: PropTypes.any,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

CfTextFieldWithConfirm.defaultProps = {
  acceptText: <FormattedMessage id="common.add" />,
  cancelText: <FormattedMessage id="common.cancel" />,
  initialValue: '',
  classes: {},
  labelTestId: 'add-item',
  disabled: false,
  onAccept: () => {},
  onCancel: () => {},
};

export default withStyles(styles)(CfTextFieldWithConfirm);
