import * as nodesApiTypes from '../nodes/nodes.constants';
import * as types from './sensors.constants';

export default () => (
  state = {
    isFetching: false,
    items: {},
    error: {},
    data: {},
  },
  action,
) => {
  switch (action.type) {
    case nodesApiTypes.GET_NODES_SUCCESS:
      return {
        ...state,
        items: action.payload.sensors,
      };
    case types.GET_SENSOR_DATA:
      return {
        ...state,
        data: setSensorDataFetching(state.data, action),
        error: {},
      };
    case types.GET_SENSOR_DATA_SUCCESS:
      return {
        ...state,
        data: replaceInSensorsDataList(state.data, action),
      };
    case types.GET_SENSOR_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.RESET_SENSOR_DATA:
      return {
        ...state,
        error: {},
        data: {},
      };
    default:
      return state;
  }
};

const replaceInSensorsDataList = (stateData, action) => {
  const {
    payload: { aggregation, data, sensorId },
  } = action;

  return {
    ...stateData,
    [sensorId]: {
      ...stateData[sensorId],
      [aggregation]: {
        data: data.sensorData,
        statistics: {
          maxValue: data.maxValue,
          minValue: data.minValue,
          avgValue: data.avgValue,
        },
        isFetching: false,
      },
    },
  };
};

const setSensorDataFetching = (data, action) => {
  const {
    payload: { aggregation, sensorId },
  } = action;

  if (!data[sensorId]) {
    return {
      ...data,
      [sensorId]: {
        [aggregation]: {
          isFetching: true,
        },
      },
    };
  }

  return {
    ...data,
    [sensorId]: {
      ...data[sensorId],
      [aggregation]: {
        isFetching: true,
      },
    },
  };
};
