export const GET_NODES = 'GET_NODES';
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS';
export const GET_NODES_ERROR = 'GET_NODES_ERROR';
export const RESET_NODES = 'RESET_NODES';

export const GET_NODE = 'GET_NODE';
export const GET_NODE_SUCCESS = 'GET_NODE_SUCCESS';
export const GET_NODE_ERROR = 'GET_NODE_ERROR';

export const GET_NODES_DEFAULT_FARM = 'GET_NODES_DEFAULT_FARM';
export const GET_NODES_DEFAULT_FARM_SUCCESS = 'GET_NODES_DEFAULT_FARM_SUCCESS';
export const GET_NODES_DEFAULT_FARM_ERROR = 'GET_NODES_DEFAULT_FARM_ERROR';
export const RESET_NODES_DEFAULT_FARM = 'RESET_NODES_DEFAULT_FARM';

export const GET_NODES_STATISTICS = 'GET_NODES_STATISTICS';
export const GET_NODES_STATISTICS_SUCCESS = 'GET_NODES_STATISTICS_SUCCESS';
export const GET_NODES_STATISTICS_ERROR = 'GET_NODES_STATISTICS_ERROR';
export const RESET_NODES_STATISTICS = 'RESET_NODES_STATISTICS';

export const CREATE_NODE = 'CREATE_NODE';
export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
export const CREATE_NODE_ERROR = 'CREATE_NODE_ERROR';

export const PUT_NODE = 'PUT_NODE';
export const PUT_NODE_SUCCESS = 'PUT_NODE_SUCCESS';
export const PUT_NODE_ERROR = 'PUT_NODE_ERROR';

export const PATCH_NODE = 'PATCH_NODE';
export const PATCH_NODE_SUCCESS = 'PATCH_NODE_SUCCESS';
export const PATCH_NODE_ERROR = 'PATCH_NODE_ERROR';

export const GET_NODE_TYPES = 'GET_NODE_TYPES';
export const GET_NODE_TYPES_SUCCESS = 'GET_NODE_TYPES_SUCCESS';
export const GET_NODE_TYPES_ERROR = 'GET_NODE_TYPES_ERROR';
export const RESET_NODE_TYPES = 'RESET_NODE_TYPES';

export const GET_NETWORK_TYPES = 'GET_NETWORK_TYPES';
export const GET_NETWORK_TYPES_SUCCESS = 'GET_NETWORK_TYPES_SUCCESS';
export const GET_NETWORK_TYPES_ERROR = 'GET_NETWORK_TYPES_ERROR';
export const RESET_NETWORK_TYPES = 'RESET_NETWORK_TYPES';

export const EXPORT_NODES = 'EXPORT_LOCATION';
export const EXPORT_NODES_SUCCESS = 'EXPORT_LOCATION_SUCCESS';
export const EXPORT_NODES_ERROR = 'EXPORT_LOCATION_ERROR';

export const GET_BARANI_BASES_REQUEST = 'GET_BARANI_BASES_REQUEST';
export const GET_BARANI_BASES_SUCCESS = 'GET_BARANI_BASES_SUCCESS';
export const GET_BARANI_BASES_ERROR = 'GET_BARANI_BASES_ERROR';
export const RESET_BARANI_BASES = 'RESET_BARANI_BASES';

export const PUT_BARANI_ASSIGN_REQUEST = 'PUT_BARANI_ASSIGN_REQUEST';
export const PUT_BARANI_ASSIGN_SUCCESS = 'PUT_BARANI_ASSIGN_SUCCESS';
export const PUT_BARANI_ASSIGN_ERROR = 'PUT_BARANI_ASSIGN_ERROR';

export const DELETE_BARANI_UNASSIGN_REQUEST = 'DELETE_BARANI_UNASSIGN_REQUEST';
export const DELETE_BARANI_UNASSIGN_SUCCESS = 'DELETE_BARANI_UNASSIGN_SUCCESS';
export const DELETE_BARANI_UNASSIGN_ERROR = 'DELETE_BARANI_UNASSIGN_ERROR';
