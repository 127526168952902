/**
 * Created by ondrejzvara on 29.3.17.
 */


import angular from 'angular';
import tpl from './fertilizers.html';
import FertilizersController from './fertilizers.controller';

import fertilizer from './fertilizer/fert.module';

export default angular.module('app.components.farm.classifiers.private.fertilizers', [
    fertilizer
])
    .controller('FertilizersController', FertilizersController)
    .config(config)
    .name;


/*@ngInject*/
function config($stateProvider) {

    $stateProvider
        .state('farm.active.classifiers.private.fertilizers', {
            url: '/fertilizers',
            views: {
                fertilizers: {
                    controller: "FertilizersController as FertilizersCtrl",
                    template: tpl
                }
            }
        });
}