export default class Logger {
  static log(...params) {
    // eslint-disable-next-line
    if (process.env.NODE_ENV !== 'production') if (window.console) console.log(...params);
  }

  static warn(...params) {
    // eslint-disable-next-line
    if (process.env.NODE_ENV !== 'production') if (window.console) console.warn(...params);
  }
}
