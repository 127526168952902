import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    padding: '6px 10px',
    minHeight: 32,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '50px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.75,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

export const CfButtonPanel = ({ children, classes, uppercase, ...rest }) => (
  <span className={`${classes.root} ${uppercase ? classes.uppercase : ''}`} {...rest}>
    {children}
  </span>
);

CfButtonPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  uppercase: PropTypes.bool,
};

CfButtonPanel.defaultProps = {
  uppercase: true,
};

export default withStyles(styles)(CfButtonPanel);
