import React, { FC, useState, useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { TableRow } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableRowToolButton from '../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton';
import CfTableRowTools from '../../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import CfTableCheckbox from '../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import Countries from '../../../../shared/services/Countries.service';

import EditDialog from './EditDialog';
import ActivatedIcon from './icons/Activated';
import DeactivatedIcon from './icons/Deactivated';

import { AccountTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    item: AccountTo;
    namespace: string;
    selected: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    transform: 'scale(1)',
    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  cellMultipleRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  farmName: {
    fontWeight: 500,
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  editButton: {
    visibility: 'hidden',
  },
  flag: {
    fontSize: 20,
  },
}));

const Row: FC<Props> = ({ item, namespace, selected }) => {
  const classes = useStyles();
  const [showEditDialog, setEditDialog] = useState(false);

  const handleEditDialogOpen = useCallback(() => setEditDialog(true), [setEditDialog]);
  const handleEditDialogClose = useCallback(() => setEditDialog(false), [setEditDialog]);

  return (
    <TableRow className={classes.row}>
      <CfTableCheckbox id={item.farmId} namespace={namespace} selected={selected} />
      <CfTableCell name="companyName">
        <div className={classes.cellMultipleRowsContainer}>
          <span className={classes.farmName}>{item.name}</span>
          <span className={classes.greyText}>{item.farmId}</span>
        </div>
      </CfTableCell>
      <CfTableCell name="companyId">{item.externalId || '-'}</CfTableCell>
      <CfTableCell classes={{ root: classes.flag }} name="countryCode">{Countries.countryToFlag(item.countryCode)}</CfTableCell>
      <CfTableCell name="subholding">-</CfTableCell>
      <CfTableCell name="holding">-</CfTableCell>
      <CfTableCell name="classifier">Winfas</CfTableCell>
      <CfTableCell name="state">{item.enabled ? <ActivatedIcon /> : <DeactivatedIcon /> }</CfTableCell>
      <CfTableCell name="activatedAt">{item.activated ? <FormattedDate value={item.activated} /> : '-'}</CfTableCell>
      <CfTableCell name="deactivatedAt">{item.deactivated ? <FormattedDate value={item.deactivated} /> : '-'}</CfTableCell>
      <CfTableRowTools toolsClass={classes.editButton}>
        <CfTableRowToolButton
          icon={<EditIcon />}
          onClick={handleEditDialogOpen}
          tooltipMessage={<FormattedMessage id="IrrigationAdmin.editDevice" />}
        />
      </CfTableRowTools>
      <EditDialog
        farm={item}
        handleClose={handleEditDialogClose}
        opened={showEditDialog}
      />
    </TableRow>
  );
};

export default Row;
