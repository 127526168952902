import React, { Component } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const style = {
  tooltip: {
    margin: 4,
  },
};

class SentinelStatus extends Component {
  constructor(props) {
    super(props);
    this.SENTINEL_STATES = {
      ACTIVE: 'active',
      ACTIVE_NO_DATA: 'activeNoData',
      WAITING_FOR_USER: 'waitingForUser',
      HISTORICAL_SERVICE: 'historicalService',
      INACTIVE: 'inactive',
    };
  }

  getStatus = (active, product) => {
    if (active) {
      if (product) {
        if (product.receivedDate) {
          return this.SENTINEL_STATES.ACTIVE;
        }
        return this.SENTINEL_STATES.ACTIVE_NO_DATA;
      }
      return this.SENTINEL_STATES.WAITING_FOR_USER;
    }
    if (product) {
      return this.SENTINEL_STATES.HISTORICAL_SERVICE;
    }
    return this.SENTINEL_STATES.INACTIVE;
  };

  getIcon = (status) => {
    const { testId } = this.props;
    switch (status) {
      case this.SENTINEL_STATES.ACTIVE:
        return <CheckCircleIcon color="primary" data-test={testId} />;
      case this.SENTINEL_STATES.ACTIVE_NO_DATA:
        return <CheckCircleOutlineIcon color="primary" data-test={testId} />;
      case this.SENTINEL_STATES.WAITING_FOR_USER:
        return <CheckCircleIcon color="secondary" data-test={testId} />;
      case this.SENTINEL_STATES.HISTORICAL_SERVICE:
        return <CancelIcon data-test={testId} htmlColor="#5E15A7" />;
      default:
        return <CancelIcon color="disabled" data-test={testId} />;
    }
  };

  render() {
    const { active, classes, intl, product } = this.props;
    const status = this.getStatus(active, product);
    const dateFrom = product && product.createdDate ? intl.formatDate(product.createdDate) : null;
    const dateTo = product && product.stoppedDate ? intl.formatDate(product.stoppedDate) : null;
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<FormattedMessage id={`SentinelStatus.${status}`} values={{ dateFrom, dateTo }} />}
      >
        {this.getIcon(status)}
      </Tooltip>
    );
  }
}

SentinelStatus.propTypes = {
  classes: PropTypes.object,
  active: PropTypes.bool,
  testId: PropTypes.string,
  product: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

SentinelStatus.defaultProps = {
  classes: {},
  active: false,
  testId: 'status-icon',
  product: null,
};

export default injectIntl(withStyles(style)(SentinelStatus));
