import { MAIN_MAP_TELEMATICS_PREFIX } from '../../../../core/map/actions/mainMapTelematics/mainMapTelematics.constants';

export const GET_MACHINE_POSITIONS_REQUEST = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_POSITIONS_REQUEST`;
export const GET_MACHINE_POSITIONS_SUCCESS = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_POSITIONS_SUCCESS`;
export const GET_MACHINE_POSITIONS_ERROR = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_POSITIONS_ERROR`;

export const GET_MACHINE_DRIVES_HISTORY_REQUEST =
`${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_DRIVES_HISTORY_REQUEST`;
export const GET_MACHINE_DRIVES_HISTORY_SUCCESS =
`${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_DRIVES_HISTORY_SUCCESS`;
export const GET_MACHINE_DRIVES_HISTORY_ERROR =
`${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINE_DRIVES_HISTORY_ERROR`;

export const RESET_MACHINE_DRIVES_HISTORY =
`${MAIN_MAP_TELEMATICS_PREFIX}/RESET_MACHINE_DRIVES_HISTORY`;

export const GET_MACHINES_REQUEST = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_REQUEST`;
export const GET_MACHINES_SUCCESS = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_SUCCESS`;
export const GET_MACHINES_ERROR = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_ERROR`;
export const RESET_MACHINES = `${MAIN_MAP_TELEMATICS_PREFIX}/RESET_MACHINES`;
export const SET_MACHINE_SELECTOR_FILTER = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_MACHINE_SELECTOR_FILTER`;

export const GET_MACHINES_GROUPS_REQUEST = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_GROUPS_REQUEST`;
export const GET_MACHINES_GROUPS_SUCCESS = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_GROUPS_SUCCESS`;
export const GET_MACHINES_GROUPS_ERROR = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_GROUPS_ERROR`;
export const RESET_MACHINES_GROUPS = `${MAIN_MAP_TELEMATICS_PREFIX}/RESET_MACHINES_GROUPS`;

export const GET_DRIVERS_REQUEST = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_DRIVERS_REQUEST`;
export const GET_DRIVERS_SUCCESS = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_DRIVERS_SUCCESS`;
export const GET_DRIVERS_ERROR = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_DRIVERS_ERROR`;
export const RESET_DRIVERS = `${MAIN_MAP_TELEMATICS_PREFIX}/RESET_DRIVERS`;
