import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getDeviceTypes } from '../../../../sensors/selectors/nodes.selectors';

import SensorsIcon from '../../../icons/navbar/SensorsIcon';
import DeviceTypesService from '../../../services/DeviceTypes.service';

const styles = {
  itemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    marginRight: '3px',
  },
};

export function NodeDeviceType(props) {
  const { classes, deviceTypes, displayIcon, node } = props;
  const deviceGroup = DeviceTypesService.getDeviceGroupByDeviceType(node.deviceType, deviceTypes);

  return (
    <span className={classes.itemContent}>
      {displayIcon && <SensorsIcon className={classes.icon} />}
      <span className={classes.spanMessageMargin}>
        {deviceGroup ? <FormattedMessage id={`DeviceGroup.${deviceGroup}`} /> : '-'}
      </span>
    </span>
  );
}

NodeDeviceType.propTypes = {
  node: PropTypes.object,
  deviceTypes: PropTypes.array.isRequired,
  displayIcon: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

NodeDeviceType.defaultProps = {
  node: {},
  displayIcon: true,
};

const mapStateToProps = state => ({
  deviceTypes: getDeviceTypes(state),
});

export default injectIntl(connect(mapStateToProps, null)(withStyles(styles)(NodeDeviceType)));
