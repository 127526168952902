import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Line, Dot } from 'recharts';

class CfChartLine extends Component {
  renderDot(datapoint, data) {
    if (datapoint.payload[datapoint.dataKey]) {
      const index = data.findIndex(item => item.dateTime === datapoint.payload.dateTime);
      const leftPoint = data[index - 1];
      const rightPoint = data[index + 1];
      if ((!leftPoint || !leftPoint[datapoint.dataKey]) && (!rightPoint || !rightPoint[datapoint.dataKey])) {
        return <Dot {...datapoint} />;
      }
    }

    return undefined;
  }

  render() {
    const { dotData, ...rest } = this.props;
    return <Line {...rest} dot={point => this.renderDot(point, dotData)} fill={rest.stroke} r={1.5} type="monotoneX" />;
  }
}

CfChartLine.defaultProps = Line.defaultProps;
CfChartLine.displayName = Line.displayName;
CfChartLine.getComposedData = Line.getComposedData;
CfChartLine.repeat = Line.repeat;
CfChartLine.renderDotItem = Line.renderDotItem;

CfChartLine.propTypes = {
  dotData: PropTypes.array.isRequired,
};

export default CfChartLine;
