import jwtDecode from 'jwt-decode';
import localStorageService from 'local-storage';

/**
 * Created by jenc on 7.12.2017
 */
class Jwt {
  getToken() {
    return localStorageService.get('token');
  }

  storeToken(token) {
    localStorageService.set('token', token);
  }

  removeToken() {
    localStorageService.remove('token');
  }

  hasValidToken() {
    const token = this.getToken();

    if (!(token && token.id_token)) {
      return false;
    }

    return !this.isTokenExpired(token.id_token);
  }

  isTokenExpired(idToken) {
    const jwt = jwtDecode(idToken);
    const time = new Date().getTime() / 1000;
    return time > jwt.exp;
  }

  readToken() {
    const token = this.getToken();
    return token && jwtDecode(token.id_token);
  }

  getTokenValue() {
    const token = this.getToken();
    return token && token.id_token;
  }

  isImpersonatedToken() {
    const token = this.readToken();
    return token && Object.prototype.hasOwnProperty.call(token, 'original_user_id');
  }
}

export default new Jwt();
