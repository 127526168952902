import { Theme, Breakpoint } from '@mui/material';
import { makeStyles } from '@mui/styles';

const COORDINATES = {
  BREAKPOINT_SMALL: 'xs',
  BREAKPOINT_LARGE: 'lg',
  COL_SIZE: '69px',
  MARGIN_DESKTOP: '15px',
  MARGIN_MOBILE: '5px',
};

const COLUMN_SIZE = '110px';

export default makeStyles((theme: Theme) => (
  {
    container: {
      display: 'flex',
      width: '93%',
      minHeight: '200px',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      margin: '0 18px 24px',
    },
    coordinatesContainer: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    claims: {
      width: '100%',
      marginBottom: '12px',
    },
    table: {
      width: '100%',
      maxHeight: '45vh',
      overflow: 'auto',
      '& > div': {
        width: '99%',
        margin: '2px auto',
      },
    },
    nameCol: {
      marginRight: '5px',
      '& > p': {
        margin: '0 0 2px',
      },
      '& > p:last-child': {
        color: theme.palette.grey[500],
      },
    },
    tableCell: {
      [theme.breakpoints.down(COORDINATES.BREAKPOINT_SMALL as Breakpoint)]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    tableRow: {
      '&.Mui-selected:hover': {
        backgroundColor: '#F2EDDF',
      },
    },
    icon: {
      display: 'inline-block',
      marginRight: '5px',
      verticalAlign: 'middle',
      [theme.breakpoints.up(COORDINATES.BREAKPOINT_LARGE as Breakpoint)]: {
        marginRight: '15px',
      },
    },
    activeIcon: {
      cursor: 'pointer',
    },
    textFieldShort: {
      '& .MuiInputBase-root': {
        fontWeight: 'normal !important',
        width: COORDINATES.COL_SIZE,
      },
      '& .MuiInputBase-input': {
        paddingBottom: '4px',
      },
      '& .MuiFormHelperText-root': {
        width: COORDINATES.COL_SIZE,
      },
    },
    textFieldLong: {
      '& .MuiInputBase-root': {
        width: COLUMN_SIZE,
      },
      '& .MuiFormHelperText-root': {
        maxWidth: COLUMN_SIZE,
      },
    },
    withMargin: {
      marginRight: COORDINATES.MARGIN_MOBILE,
      [theme.breakpoints.up(COORDINATES.BREAKPOINT_LARGE as Breakpoint)]: {
        marginRight: COORDINATES.MARGIN_DESKTOP,
      },
    },
    textField: {
      '& .MuiInputBase-root': {
        color: theme.palette.grey[500],
        fontSize: '13px',
        fontWeight: 500,
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.grey[400],
        fontWeight: 400,
      },
      '& .MuiInput-underline::before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
      '& .MuiInput-underline.Mui-disabled::before': {
        borderBottom: 'none',
      },
      '& .MuiInputBase-multiline': {
        paddingBottom: '4px',
      },
    },
    textFieldInError: {
      '& .MuiFormHelperText-root': {
        color: 'red !important',
      },
    },
    validationError: {
      color: 'red',
    },
  }
));
