import React, { CSSProperties, FC, ReactNode, useEffect } from 'react';

import { Theme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getTotalCount,
  getParcelsFertilization,
  getParcelsFertilizationIsFetching,
} from '../../../../../shared/api/core/parcels/parcels.selectors';
import {
  getParcelListItems,
  getParcelListPage,
  getParcelListOrder,
  getParcelListOrderBy,
  getParcelListRowsPerPage,
  getParcelListSelected,
  getParcelListSelectedOnPage,
  getParcelListTextFilter,
  isFetchingParcels,
  getParcelListAdvancedFilter,
} from '../../selectors/parcelList.selectors';

import { fetchParcels, resetParcels, resetParcel, fetchParcelsFertilization } from '../../actions/parcelList.actions';

import { NAMESPACE as namespace } from '../../reducer/parcelList.reducer';

import { resetParcelsFertilization } from '../../../../../shared/api/core/parcels/parcels.api';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfLoadingPlaceholder from '../../../../../shared/components/common/CfLoadingPlaceholder/CfLoadingPlaceholder';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableCellNumber from '../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableCheckbox from '../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import CfTableFooter from '../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import { COLOR_GREY } from '../../../../../theme';
import { Thunk } from '../../../../../types';
import ParcelCenterAssign from '../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign';
import ParcelZoneAssign from '../../../shared/containers/ParcelZoneAssign/ParcelZoneAssign';

import { Fertilization, Parcel, ParcelListAdvancedFilter, ParcelsState } from '../../../../../shared/api/core/parcels/parcels.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties, rightPadding?: boolean) => ({
  align: 'inherit',
  sortable,
  label,
  style,
  rightPadding,
});

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  'localName,zone.name': getColDesc(
    true,
    <span>
      <FormattedMessage id="common.name" />
    </span>,
  ),
  blockNr: getColDesc(false, <FormattedMessage id="common.short-code" />),
  area: getColDesc(
    false,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.area" /> (ha)
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.area-dpb" /> (ha)
      </p>
    </span>, { textAlign: 'right' }, true),
  culture: getColDesc(
    false,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.culture" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.crop" />
      </p>
    </span>,
  ),
  nitrogen: getColDesc(false,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="ParcelTable.nitrogen-to-crop" /> (kg/ha)
      </p>
    </span>, { textAlign: 'right' }, true,
  ),
  nitrateVulnerable: getColDesc(false, <FormattedMessage id="term.NSA" />),
  validFrom: getColDesc(false, <FormattedMessage id="common.validFrom" />),
  zone: getColDesc(false, <FormattedMessage id="common.zone" />),
  center: getColDesc(false, <FormattedMessage id="common.center" />),
};

const useStyles = makeStyles((theme: Theme) => ({
  greyText: {
    color: theme.palette.grey[500],
  },
  cellRow: {
    margin: 0,
  },
  name: {
    fontWeight: 500,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export interface ParcelTableProps {
  advancedFilter: ParcelListAdvancedFilter;
  count: number;
  fertilization: Fertilization,
  fetchParcels: () => void;
  fetchParcelsFertilization: (items: Parcel[]) => void;
  isFetching: boolean;
  isFetchingFertilization: boolean;
  items: Parcel[],
  langId: string,
  ngRedirectToParcelDetail: (parcelId: string) => void,
  onAssignCenter: (centerId: string, payload: string[], bulk: boolean) => void,
  onAssignZone: (zoneId: string, parcelIds: string[], bulk: boolean) => void,
  order: string;
  orderBy: string;
  page: number;
  resetParcel: () => void;
  resetParcels: () => void;
  resetParcelsFertilization: () => void;
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
  shouldReloadData: boolean;
  textFilter: string;
}

export const ParcelTable: FC<ParcelTableProps> = ({
  advancedFilter = {},
  count,
  fertilization,
  fetchParcels,
  fetchParcelsFertilization,
  isFetching,
  isFetchingFertilization,
  items,
  langId,
  ngRedirectToParcelDetail,
  onAssignCenter,
  onAssignZone,
  order,
  orderBy,
  page,
  resetParcel,
  resetParcels,
  resetParcelsFertilization,
  rowsPerPage,
  selected,
  selectedOnPage,
  shouldReloadData,
  textFilter = '',
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchParcels();

    return () => {
      resetParcels();
      resetParcel();
      resetParcelsFertilization();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchParcels();
    resetParcelsFertilization();
  // eslint-disable-next-line max-len
  }, [page, rowsPerPage, order, orderBy, textFilter, advancedFilter, shouldReloadData, resetParcelsFertilization, fetchParcels]);

  useEffect(() => {
    if (items.length) {
      fetchParcelsFertilization(items);
    }
  }, [items, fetchParcelsFertilization]);

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  return (
    <CfTableWrapper testId="parcel-list">
      <CfTableHead
        columns={columns}
        count={items.length}
        items={items}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={'localName,zone.name'}
        selected={selected}
        selectedOnPage={selectedOnPage}
        />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {items.length ? (
        <TableBody>
          {items.map(item => (
            <TableRow
              className={classes.pointer}
              hover
              key={item.id}
              onClick={() => ngRedirectToParcelDetail(item.id)}
              selected={isSelected(item.id)}
              >
              <CfTableCheckbox id={item.id} namespace={namespace} selected={selected} />
              <CfTableCell name={'name'}>
                <span className={classes.name}>{item.localName}</span>
              </CfTableCell>
              <CfTableCell name={'blockNr'}>
                <span>{item.blockNr}</span>
              </CfTableCell>
              <CfTableCell name={'area'}>
                <span>
                  <CfTableCellNumber padding={true}>
                    <p className={`${classes.cellRow}`}>
                      <CfFormattedNumber value={item.area || 0} />
                    </p>
                    <p className={`${classes.cellRow} ${classes.greyText}`}>
                      <CfFormattedNumber value={get(item, 'parcelBlockList[0].lpisBlock.areaDec', null) || 0} />
                    </p>
                  </CfTableCellNumber>
                </span>
              </CfTableCell>

              <CfTableCell name={'culture'}>
                <span>
                  <p className={`${classes.cellRow}`}>
                    {get(item, 'parcelBlockList[0].lpisBlock.landUse.name', null)}
                  </p>
                  <p className={`${classes.cellRow} ${classes.greyText}`}>
                    {get(item, 'currentSeedApplication.seed.crop.name', null)}
                  </p>
                </span>
              </CfTableCell>

              <CfTableCell name={'nitrogen'}>
                <CfTableCellNumber padding={true}>
                  {isFetchingFertilization ? (
                    <CfLoadingPlaceholder float={'right'} width={40} />
                  ) : (
                    <p className={`${classes.cellRow}`}>
                      <CfFormattedNumber value={fertilization[item.id]?.accountableN || 0} />
                    </p>
                  )}
                </CfTableCellNumber>
              </CfTableCell>

              <CfTableCell name={'nitrateVulnerable'}>
                <FormattedMessage id={`common.${item.nitrateVulnerable ? 'yes' : 'no'}`} />
              </CfTableCell>

              <CfTableCell name={'validFrom'}>
                <FormattedDate value={item.validFrom} />
              </CfTableCell>

              <TableCell
                key={'zone'}
                onClick={evt => evt.stopPropagation()}
                padding={'none'}
                style={{
                  width: '1px',
                  whiteSpace: 'nowrap',
                  paddingRight: '5px',
                }}
                >
                <ParcelZoneAssign
                  onZoneChange={(zoneId: string, parcelIds: string[]) => onAssignZone(zoneId, parcelIds, false)}
                  parcel={item}
                  />
              </TableCell>

              <TableCell
                key={'center'}
                onClick={evt => evt.stopPropagation()}
                padding={'none'}
                style={{
                  width: '1px',
                  whiteSpace: 'nowrap',
                  paddingRight: '5px',
                }}
                >
                <ParcelCenterAssign
                  onCenterChange={(centerId) => onAssignCenter(centerId, [item.id], false)}
                  parcel={item}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  items: getParcelListItems(state),
  fertilization: getParcelsFertilization(state),
  isFetchingFertilization: getParcelsFertilizationIsFetching(state),
  isFetching: isFetchingParcels(state),
  count: getTotalCount()(state),
  page: getParcelListPage(state),
  order: getParcelListOrder(state),
  orderBy: getParcelListOrderBy(state),
  rowsPerPage: getParcelListRowsPerPage(state),
  selected: getParcelListSelected(state),
  selectedOnPage: getParcelListSelectedOnPage(state),
  textFilter: getParcelListTextFilter(state),
  advancedFilter: getParcelListAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchParcels,
      resetParcels,
      resetParcel,
      fetchParcelsFertilization,
      resetParcelsFertilization,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelTable);
