import * as types from './monitoring.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    cropIntervals: [],
    cropIntervalsIsFetching: false,
    cropIntervalsError: {},
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_MONITORING_DATA:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_MONITORING_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload || [],
      };
    case types.GET_MONITORING_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_MONITORING_DATA:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: [],
      };
    case types.GET_MONITORING_CROP_INTERVALS:
      return {
        ...state,
        cropIntervalsIsFetching: true,
        cropIntervalsError: {},
      };
    case types.GET_MONITORING_CROP_INTERVALS_SUCCESS:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervals: action.payload,
      };
    case types.GET_MONITORING_CROP_INTERVALS_ERROR:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervalsError: action.payload,
      };
    case types.RESET_MONITORING_CROP_INTERVALS:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervals: [],
      };

    default:
      return state;
  }
};
