/**
 * Created by ondrejzvara on 22.3.17.
 */

import angular from 'angular';
import Storage from './Storage.service';
import UserStorage from './UserStorage.service';
import FarmStorage from './FarmStorage.service';
import FarmsStorage from './FarmsStorage.service';

export default angular
  .module('app.storage', [])
  .factory('Storage', Storage)
  .factory('UserStorage', UserStorage)
  .factory('FarmStorage', FarmStorage)
  .factory('FarmsStorage', FarmsStorage).name;
