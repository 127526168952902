import React from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  header: {
    alignItems: 'center',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

function PageHeader(props) {
  const { actionButtons, backButton, classes, heading } = props;
  return (
    <Grid className={classes.header} container spacing={2}>
      <Grid item xs={2}>
        {backButton}
      </Grid>
      <Grid item xs={8}>
        {heading}
      </Grid>
      <Grid className={classes.actionButtons} item xs={2}>
        {actionButtons}
      </Grid>
    </Grid>
  );
}

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  backButton: PropTypes.node,
  heading: PropTypes.node,
  actionButtons: PropTypes.node,
};

PageHeader.defaultProps = {
  backButton: null,
  heading: null,
  actionButtons: null,
};

export default withStyles(styles)(PageHeader);
