import React from 'react';

const ActivatedIcon = () => (
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.500122C4.48 0.500122 0 4.98012 0 10.5001C0 16.0201 4.48 20.5001 10 20.5001C15.52 20.5001 20 16.0201 20 10.5001C20 4.98012 15.52 0.500122 10 0.500122ZM8 15.5001L3 10.5001L4.41 9.09012L8 12.6701L15.59 5.08012L17 6.50012L8 15.5001Z" fill="#00A179" />
  </svg>

);

export default ActivatedIcon;
