/**
 * Created by brasko on 23.9.2015.
 */

/* @ngInject */
export default function Unit(UnitsResource, $q, Logger) {
  let units;

  /**
   * Service Unit
   * @namespace Unit
   */
  const service = {
    getUnits,
    getUnitsPor,
    getUnitById,
    getUnitsByQuantityId,
    getAreaCompatibleUnits
  };

  return service;

  // //////////////////////////

  function getUnits(force) {
    if (!units || force) {
      return UnitsResource.getUnits({}, {}).then((data) => {
        units = data.plain();
        return units;
      });
    }
    return $q.resolve(units);
  }

  function getUnitsPor() {
    if (!units) {
      return UnitsResource.getUnitsPor({}, {}).then((data) => {
        units = data.plain();
        return units;
      });
    }
    return $q.resolve(units);
  }

  function getUnitById(unitId) {
    return _.find(units, { id: unitId });
  }

  function getUnitsByQuantityId(quantityId) {
    return _.filter(units, { quantityId: quantityId });
  }

  function getAreaCompatibleUnits() {
    return _.filter(units, (unit) => {
      const unitQuantityId = _.get(unit, 'quantityId');
      const unitId = _.get(unit, 'id');

      if (!unitQuantityId || !unitId) {
        Logger.error('Incorrect data format from BE, ommiting. Unit: ', unit);
        return false;
      }

      return unitQuantityId !== 'A' && unitId.indexOf('/') === -1;
    });
  }
}
