import React, { Component } from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';

const styles = theme => ({
  errorWrapper: {
    marginBottom: '20px',
  },
  error: {
    color: theme.palette.secondary.main,
  },
});

class ActionValidationDetailsDialog extends Component {
  renderErrors = errors =>
    errors.map(error => (
      <div className={this.props.classes.errorWrapper} data-test="validation-item" key={error.restrictionCode}>
        <Typography className={this.props.classes.error} key={error.restrictionDescription} variant="subtitle1">
          {error.restrictionDescription}
        </Typography>
        {this.renderDescriptions(error)}
      </div>
    ));

  renderDescriptions = error =>
    error.violationDescriptions?.map((desc, index) => (
      // it could happen sometimes that the message is same (more a feature issue, but using the index workaround here
      // eslint-disable-next-line react/no-array-index-key
      <Typography key={`${desc.message} - ${index}`}>{desc.message}</Typography>
    ));

  render() {
    const { onBack, opened, validationErrors } = this.props;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.back" />}
        borderSize={1}
        onAccept={onBack}
        opened={opened}
        title={<FormattedMessage id="ActionValidationDetailsDialog.problems" />}
      >
        <div>{this.renderErrors(validationErrors)}</div>
      </CfDialog>
    );
  }
}

ActionValidationDetailsDialog.propTypes = {
  classes: PropTypes.object,
  opened: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  validationErrors: PropTypes.array,
};

ActionValidationDetailsDialog.defaultProps = {
  classes: {},
  opened: false,
  validationErrors: [],
};

export default withStyles(styles)(ActionValidationDetailsDialog);
