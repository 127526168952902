import debounce from 'lodash/debounce';

import { getGeometry } from '../../../../shared/api/core/geometry/geometry.selectors';
import { getStage, getSelectedParcel, getValidFrom } from '../../selectors/editor.selectors';
import { getInteraction } from '../../selectors/map.selectors';

import {
  editorSetStage,
  editorSetDrawingError,
  editorClearDrawingError,
  editorHintReopen,
  editorHintRefresh,
  editorHintClose,
  editorSetSelected,
  handleResponse,
  handleGenericError,
  showErrorHint,
} from '../editor/editor.actions';
import { setHoverStyleEL, setMouseMoveEL, setMouseClickEL, removeEL } from '../eventListener/eventListener.actions';
import {
  setDrawIA,
  removeDrawIA,
  setModifyIA,
  removeModifyIA,
  removeSnapIA,
  setLayer,
  removeLayer,
  removeDrawingErrorStyle,
  setDrawingErrorStyle,
} from '../interaction/interaction.actions';
import { zoomToGeometry, mapSetCursor, setMapFetching } from '../map/map.actions';
import {
  addAreaOverlay,
  removeOverlays,
  updateOverlayPosition,
  removeOverlay,
  addMapHintOverlay,
} from '../overlay/overlay.actions';
import { refreshSplitStyles } from '../style/style.actions';

import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';

import { getParcelGeometryById, splitGeometry } from '../../../../shared/api/core/geometry/geometry.api';
import Feature from '../../services/Feature.service';
import { GEOM_TYPES } from '../../services/geometry/Geometry.service';
import GeometryValidation from '../../services/geometry/GeometryValidation.service';

let IS_TOOL_ACTIVE = false;
let HAS_PARCEL_OVERLAY = false;
let LAST_PARCEL_OVERLAY = null;
const SPLIT_IA = 'split_parcel';

/*
 * STAGE_1 - tool opened, no parcel selected
 * STAGE_2 - tool opened, parcel selected
 * STAGE_3 - tool opened, parcel selected, draw line finished
 */

export const splitStart = () => dispatch => {
  IS_TOOL_ACTIVE = true;
  dispatch(editorSetStage(stages.STAGE_1));
  dispatch(refreshSplitStyles());
  dispatch(
    setHoverStyleEL(f => Feature.isFeature(f) && Feature.isWithoutCrop(f), refreshSplitStyles, 'splitHoverStyleELKey'),
  );
  dispatch(setMouseClickEL(onMouseClick, refreshSplitStyles, 'splitSelectELKey'));
  dispatch(setMouseMoveEL((feature, evt) => onMouseMove(feature, evt), refreshSplitStyles, 'splitHoverHintELKey'));
  dispatch(editorHintReopen());
};

export const splitSubmit = () => (dispatch, getState) => {
  const state = getState();
  const geometry = getInteraction(state).getGeometry(SPLIT_IA);
  const validFrom = getValidFrom(state);
  return dispatch(validateSplitLine(geometry)).then(() => dispatch(splitParcel(geometry, false, validFrom)));
};

export const splitEnd = () => dispatch => {
  IS_TOOL_ACTIVE = false;
  dispatch(editorHintClose());
  dispatch(removeEL('splitHoverStyleELKey'));
  dispatch(removeEL('splitHoverHintELKey'));
  dispatch(removeEL('splitSelectELKey'));

  dispatch(removeLayer(SPLIT_IA));
  dispatch(removeModifyIA(SPLIT_IA));
  dispatch(removeDrawIA(SPLIT_IA));
  dispatch(removeSnapIA(SPLIT_IA));
  dispatch(removeOverlays());
};

/** ************************************************************ */

export const onMouseClick = feature => dispatch => {
  if (!Feature.isFeature(feature) || !Feature.isWithoutCrop(feature)) {
    return;
  }

  dispatch(
    editorSetSelected({
      id: feature.get('id'),
      localName: feature.get('local_name'),
      currentSeedDate: feature.get('seed_date_start'),
    }),
  );

  dispatch(removeEL('splitHoverStyleELKey'));
  dispatch(removeEL('splitHoverHintELKey'));
  dispatch(removeEL('splitSelectELKey'));

  dispatch(setMapFetching(true));
  return dispatch(getParcelGeometryById(feature.get('id')))
    .then(({ payload }) => {
      dispatch(zoomToGeometry(payload.geometry));
      dispatch(setLayer(SPLIT_IA, GEOM_TYPES.LINESTRING));
      dispatch(setDrawIA(null, geom => dispatch(onDrawEnd(geom)), SPLIT_IA, GEOM_TYPES.LINESTRING));
      dispatch(mapSetCursor(''));

      dispatch(editorSetStage(stages.STAGE_2));
      dispatch(editorHintReopen());
    })
    .finally(() => {
      dispatch(setMapFetching(false));
    });
};

const onDrawEnd = geometry => dispatch => {
  dispatch(removeDrawIA(SPLIT_IA));
  dispatch(onModifyChange(geometry));

  dispatch(
    setModifyIA(
      geom => {
        dispatch(onModifyChange(geom));
      },
      null,
      null,
      SPLIT_IA,
      GEOM_TYPES.LINESTRING,
    ),
  );
};

const onModifyChange = geometry => dispatch => {
  dispatch(validateSplitLine(geometry))
    .then(() => {
      dispatch(removeDrawingErrorStyle(SPLIT_IA, GEOM_TYPES.LINESTRING));
      dispatch(editorClearDrawingError(true));
      debouncedGetIntermediateResult(geometry, dispatch);
    })
    .catch(reason => {
      debouncedGetIntermediateResult.cancel();
      dispatch(setDrawingErrorStyle(SPLIT_IA, GEOM_TYPES.LINESTRING));
      dispatch(editorSetDrawingError(reason));
    });
};

const validateSplitLine = geometry => (dispatch, getState) =>
  GeometryValidation.validateSplitLine(geometry, getGeometry(getState()));

const getIntermediateResult = geometry => (dispatch, getState) => {
  dispatch(setMapFetching(true));
  const validFrom = getValidFrom(getState());
  return dispatch(splitParcel(geometry, true, validFrom))
    .then(handleResponse)
    .then(payload => {
      if (payload.length && IS_TOOL_ACTIVE) {
        dispatch(removeOverlays());
        const elementId = 'parcel-area';
        payload.forEach((parcel, index) => {
          dispatch(addAreaOverlay(parcel, elementId, `${elementId}-${index}`));
        });

        const state = getState();
        const data = { main: getSelectedParcel(state), results: payload };
        const isEnteringStage3 = getStage(state) !== stages.STAGE_3;
        if (isEnteringStage3) {
          dispatch(editorSetStage(stages.STAGE_3));
          dispatch(editorHintReopen(data));
        } else {
          dispatch(editorHintRefresh(data));
        }
      }
    })
    .catch(payload => dispatch(handleGenericError(payload, tools.SPLIT)))
    .catch(reason => dispatch(showErrorHint(reason)))
    .finally(() => dispatch(setMapFetching(false)));
};

const debouncedGetIntermediateResult = debounce((geometry, dispatch) => dispatch(getIntermediateResult(geometry)), 500);

export const splitParcel = (geometry, isDryMode = false, validFrom) => (dispatch, getState) => {
  const feature = getSelectedParcel(getState());

  // we have to make sure the ends of the split line are snapped correctly
  // this is a hack for unprecise snapping of OL, which is interpreted by TURF
  // as not overlaping. To be done in further snap-related tasks
  /*
  const parcel = getGeometry(state);
  const first = Geometry.getFirstCoord(line);
  const last = Geometry.getLastCoord(line);
  GeometryValidation.isPointInPolygon(first, parcel, () => {
    line = Geometry.snapToBorder(line, parcel, first);
  });

  GeometryValidation.isPointInPolygon(last, parcel, () => {
    line = Geometry.snapToBorder(line, parcel, last);
  });
  */

  return dispatch(splitGeometry(geometry, feature.id, isDryMode, validFrom));
};

// TODO refactor with other tools
export const onMouseMove = (feature, evt) => dispatch => {
  if (Feature.isFeature(feature)) {
    let elementId;
    if (!Feature.isWithoutCrop(feature)) {
      elementId = 'parcel-has-crop-split';
    }

    if (elementId) {
      if (!HAS_PARCEL_OVERLAY) {
        dispatch(addMapHintOverlay(evt, elementId, elementId));
        HAS_PARCEL_OVERLAY = true;
        LAST_PARCEL_OVERLAY = elementId;
        dispatch(mapSetCursor('pointer'));
      } else {
        dispatch(updateOverlayPosition(elementId, evt.coordinate));
      }
    } else {
      dispatch(resetMapHintOverlay());
    }
    return;
  }

  if (!Feature.isFeature(feature)) {
    dispatch(mapSetCursor(''));
    dispatch(resetMapHintOverlay());
  }
};

const resetMapHintOverlay = () => dispatch => {
  dispatch(removeOverlay(LAST_PARCEL_OVERLAY));
  LAST_PARCEL_OVERLAY = null;
  HAS_PARCEL_OVERLAY = false;
};
