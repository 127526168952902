const PREFIX = 'CREATE_OR_EDIT_AREA';

export const SET_DEVICES_TO_EDIT = `${PREFIX}/SET_DEVICES_TO_EDIT`;
export const EDIT_DEVICE = `${PREFIX}/EDIT_DEVICE`;
export const SET_AREA_NAME = `${PREFIX}/SET_AREA_NAME`;
export const SORT_DEVICES = `${PREFIX}/SORT_DEVICES`;
export const SET_FIELD_ERRORS = `${PREFIX}/SET_FIELD_ERRORS`;
export const SET_FORM_TOUCHED = `${PREFIX}/SET_FORM_TOUCHED`;
export const RESET_FORM = `${PREFIX}/RESET_FORM`;
export const ACTIVATE_COORDINATES_SELECTOR = `${PREFIX}/ACTIVATE_COORDINATES_SELECTOR`;
export const RESET_COORDINATES_SELECTOR = `${PREFIX}/RESET_COORDINATES_SELECTOR`;
export const SET_HOVERED_DEVICE = `${PREFIX}/SET_HOVERED_DEVICE`;
