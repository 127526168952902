import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const NotSentIcon = props => (
  <SvgIcon {...props}>
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.6458" cy="10.7109" fill="#ABABAB" r="10" />
      <path d="M13.5558 13.4999V15.246L17.63 11.1718L13.5558 7.09765V8.84372L15.8839 11.1718L13.5558 13.4999ZM10.0636 12.9179V15.246L14.1378 11.1718L10.0636 7.09765V9.48395C7.15352 9.48395 5.11644 8.55271 3.66138 6.51562C4.2434 9.42575 5.98947 12.3359 10.0636 12.9179Z" fill="white" />
      <path d="M13.5558 13.4999V15.246L17.63 11.1718L13.5558 7.09765V8.84372L15.8839 11.1718L13.5558 13.4999ZM10.0636 12.9179V15.246L14.1378 11.1718L10.0636 7.09765V9.48395C7.15352 9.48395 5.11644 8.55271 3.66138 6.51562C4.2434 9.42575 5.98947 12.3359 10.0636 12.9179Z" fill="white" />
    </svg>
  </SvgIcon>
);

export default NotSentIcon;
