import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { exportLocation } from '../../actions/locations.actions';

import CfDatePicker from '../../../shared/components/common/CfDatePicker/CfDatePicker';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';

const style = () => ({
  grid: {
    marginRight: '50px',
  },
});

class NodeLocationExportDialog extends Component {
  constructor(props) {
    super(props);
    this.startDate = moment(props.nodeLocation.from).startOf('day');
    this.endDate = moment(props.nodeLocation.to).endOf('day') || moment().endOf('day');

    this.state = {
      dateFrom: this.startDate,
      dateTo: this.endDate,
      errorProps: {},
    };
  }

  onDateFromChange = value => {
    this.setState({
      dateFrom: value,
    });
    if (!value || value.isAfter(moment(this.state.dateTo))) {
      this.setErrorToTrue();
    } else {
      this.setErrorToFalse();
    }
  };

  onDateToChange = value => {
    let newValue = value;
    if (moment.isMoment(value)) {
      // datepicker returns date value with start of the day, so we need to manually set it to the end of the day
      newValue = moment(value).endOf('day');
    }
    this.setState({
      dateTo: newValue,
    });
    if (!newValue || newValue.isBefore(moment(this.state.dateFrom))) {
      this.setErrorToTrue();
    } else {
      this.setErrorToFalse();
    }
  };

  setErrorToTrue = () => {
    this.setState({
      errorProps: {
        error: true,
        helperText: <FormattedMessage id="DatePicker.wrongDateRange" />,
      },
    });
  };

  setErrorToFalse = () => {
    this.setState({
      errorProps: {},
    });
  };

  resetDates = () => {
    this.setState({
      dateFrom: this.startDate,
      dateTo: this.endDate,
    });
    this.setErrorToFalse();
  };

  resetAndClose = () => {
    this.resetDates();
    this.props.onCancel();
  };

  exportLocation = () => {
    this.props.exportLocation(this.props.nodeLocation.id, this.state.dateFrom, this.state.dateTo);
    this.resetDates();
    this.props.onAccept();
  };

  render() {
    const { classes, nodeLocation, onCancel, opened } = this.props;
    const dialogTitle = `${this.props.intl.formatMessage({ id: 'NodeLocationExportDialog.header' })} ${nodeLocation.name}`;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.export" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        data-test="node-export"
        dialogHeight={'95px'}
        disabled={this.state.errorProps.error}
        onAccept={this.exportLocation}
        onCancel={this.resetAndClose}
        onClose={onCancel}
        opened={opened}
        title={dialogTitle}
      >
        <Grid container spacing={2}>
          <Grid className={classes.grid} item xs={5}>
            <CfDatePicker
              label={<FormattedMessage id="common.date-from" />}
              maxDate={this.endDate}
              maxDateMessage={<FormattedMessage id="NodeLocationExportDialog.maxDateMessage" />}
              minDate={this.startDate}
              minDateMessage={<FormattedMessage id="NodeLocationExportDialog.minDateMessage" />}
              name="exportFrom"
              input={{
                value: this.state.dateFrom,
                onChange: this.onDateFromChange,
              }}
              {...this.state.errorProps}
            />
          </Grid>
          <Grid item xs={5}>
            <CfDatePicker
              label={<FormattedMessage id="common.date-to" />}
              maxDate={this.endDate}
              maxDateMessage={<FormattedMessage id="NodeLocationExportDialog.maxDateMessage" />}
              minDate={this.startDate}
              minDateMessage={<FormattedMessage id="NodeLocationExportDialog.minDateMessage" />}
              name="exportTo"
              input={{
                value: this.state.dateTo,
                onChange: this.onDateToChange,
              }}
              {...this.state.errorProps}
            />
          </Grid>
        </Grid>
      </CfDialog>
    );
  }
}

NodeLocationExportDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  nodeLocation: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  exportLocation: PropTypes.func.isRequired,
};

NodeLocationExportDialog.defaultProps = {
  classes: {},
  opened: false,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportLocation,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withStyles(style)(injectIntl(NodeLocationExportDialog)));
