import React, { Component } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import loginImg from '../../../assets/img/login-compressed.jpg';
import logoFarmaSense from '../../../assets/img/logo-farmasense.svg';
import logo from '../../../assets/img/logo-small-min.png';
import LangSwitch from '../../components/specific/LangSwitch/LangSwitch';

const style = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    backgroundImage: `url(${loginImg})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    backgroundSize: 'cover',
    margin: 0,
    padding: 16,
  },
  paper: {
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.95)',
    padding: '0px 24px 32px 24px',
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    zIndex: 999,
    top: 0,
    borderRadius: '2px',
    backgroundColor: 'rgba(236,236,236,0.9)',
  },
  logo: {
    maxWidth: (theme.version === 0 ? 74 : 230),
    content: `url(${theme.version === 0 ? logo : logoFarmaSense})`,
    marginTop: (theme.version === 0 ? 0 : 16),
  },
  headerGrid: {
    width: '100%',
  },
  langSwitch: {
    marginRight: -24,
  },
});

class EntryContainer extends Component {
  header() {
    const header = this.props.header;
    if (header !== undefined) {
      return (
        <Grid item>
          <h3>{}</h3>
        </Grid>
      );
    }
    return null;
  }

  render() {
    const { children, classes, isFetching, large, onLangChange } = this.props;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper} style={{ maxWidth: large ? 700 : 450 }}>
          <Grid alignItems="center" container justifyContent="center" spacing={2}>
            <Grid
              alignItems="stretch"
              className={classes.headerGrid}
              container
              item
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item>
                <img alt="company logo" className={classes.logo} src={logo} />
              </Grid>
              {this.header()}
              <Grid className={classes.langSwitch} item>
                <LangSwitch onLangChange={onLangChange} />
              </Grid>
            </Grid>
            <Grid item sm={10} xs={12}>
              {children}
            </Grid>
            {isFetching && (
              <div className={classes.spinner} id="spinner">
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

EntryContainer.propTypes = {
  isFetching: PropTypes.any,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onLangChange: PropTypes.func.isRequired,
  large: PropTypes.bool,
  header: PropTypes.node,
};

EntryContainer.defaultProps = {
  isFetching: false,
  header: undefined,
  large: false,
};

export default withStyles(style)(EntryContainer);
