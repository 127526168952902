import React, { Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getErrorRules } from '../../../shared/api/automation/rules/rules.selectors';
import {
  getNotificationListSelectedOnPage,
  getNotificationListTextFilter,
} from '../../selectors/notificationList.selectors';

import { fetchRules, deleteRules } from '../../actions/notificationList.actions';

import { NAMESPACE as namespace } from '../../reducer/notificationList.reducer';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import TableActionButtons from '../../../shared/components/tables/TableActionButtons/TableActionButtons';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import NotificationsTable from '../NotificationsTable/NotificationsTable';

const styles = theme => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  filtersHeader: {
    display: 'flex',
  },
  textFilter: {
    width: 400,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  tableActionButtons: {},
  button: {
    color: theme.palette.common.white,
  },
});

export class NotificationList extends Component {
  componentDidMount() {
    this.props.fetchRules();
  }

  render() {
    const { classes, errorRules, farmId, history, langId, selectedOnPage, textFilter } = this.props;
    return (
      <CfErrorPage error={errorRules}>
        <div className={classes.wrapper}>
          <PageHeader
            actionButtons={
              <Tooltip title={<FormattedMessage id="Notifications.createNotification" />}>
                <Fab
                  aria-label="create notification"
                  className={classes.button}
                  color="secondary"
                  disabled={false}
                  size="medium"
                  onClick={() => {
                    history.push(`/farm/${farmId}/notifications/new`);
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            }
            classes={{
              header: classes.header,
            }}
            heading={
              <PageHeading dataTest="notifications-heading" value={<FormattedMessage id="common.notifications" />} />
            }
          />
          <div>
            <div className={classes.filtersHeader}>
              <div className={classes.tableActionButtons}>
                <TableActionButtons
                  selected={selectedOnPage}
                  title={<FormattedMessage id="NotificationList.deleteModalAction" />}
                  onDeleteActions={() => {
                    this.props.deleteRules(selectedOnPage);
                  }}
                />
              </div>
              <div className={classes.textFilter}>
                <CfTextFilter
                  initialValue={textFilter}
                  name="notification-list-text-filter"
                  namespace={namespace}
                  translId="NotificationList.textFilterPlaceholder"
                />
              </div>
            </div>
            <NotificationsTable
              farmId={farmId}
              langId={langId}
              goToNotificationDetail={notificationId => {
                history.push(`/farm/${farmId}/notifications/${notificationId}`);
              }}
            />
          </div>
        </div>
      </CfErrorPage>
    );
  }
}

NotificationList.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  fetchRules: PropTypes.func.isRequired,
  deleteRules: PropTypes.func.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  textFilter: PropTypes.string.isRequired,
  errorRules: PropTypes.object,
};

NotificationList.defaultProps = {
  errorRules: null,
};

const mapStateToProps = state => ({
  selectedOnPage: getNotificationListSelectedOnPage(state),
  textFilter: getNotificationListTextFilter(state),
  errorRules: getErrorRules(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRules,
      deleteRules,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationList));
