import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './subtractableAreas.constants';

/*
* Gets subtractable areas on parcel defined in past
* e.g. by drawing on the map
* or previously used subtractable areas on the parcel
*/
export const getPredefinedSubtractableAreas = (parcelId, parcelIds) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtraction-areas?parcelIds=${parcelIds}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_SUBTRACTABLE_AREAS, types.GET_SUBTRACTABLE_AREAS_SUCCESS, types.GET_SUBTRACTABLE_AREAS_FAILURE],
  },
});

/*
* Gets parcel subtractable areas based on chemistries/fertilizers application
*/
export const getMaterialSubtractableAreas = (parcelId, actionMaterialsTo) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/materials-subtraction-areas?`,
    method: methods.POST,
    body: JSON.stringify(actionMaterialsTo),
    module: modules.CORE,
    types: [
      types.GET_MATERIALS_SUBTRACTABLE_AREAS,
      types.GET_MATERIALS_SUBTRACTABLE_AREAS_SUCCESS,
      types.GET_MATERIALS_SUBTRACTABLE_AREAS_FAILURE,
    ],
  },
});

/*
* Returns subtractable area for given restriction type (border/water) and its numeric value (in m):
  {
    parcelId: "foo",
    "restrictionType": "Boundary",
    "restrictionValue": 5.0,
    "geometry": GeoJSON,
    "area": 0.75
  }
*/
export const calculateSubtractableArea = (restrictionType, parcelId, restrictionValue, parcelIds) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtraction-area?restrictionType=${restrictionType}&restrictionValue=${
      restrictionValue
    }&parcelIds=${parcelIds}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_SUBTRACTABLE_AREA, types.GET_SUBTRACTABLE_AREA_SUCCESS, types.GET_SUBTRACTABLE_AREA_FAILURE],
  },
});

/*
* Returns total area (ha) of restriction areas for all parcels
* This cant be just sumned, because the restrictions areas of different parcels
* might be e.g. overlaping
*/
export const calculateTotalSubtractableArea = (parcelId, parcelsSubtractionTos, parcelIds) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/total-subtraction-area?parcelIds=${parcelIds}&`,
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(parcelsSubtractionTos),
    types: [
      types.GET_TOTAL_SUBTRACTABLE_AREA,
      types.GET_TOTAL_SUBTRACTABLE_AREA_SUCCESS,
      types.GET_TOTAL_SUBTRACTABLE_AREA_FAILURE,
    ],
  },
});

export const resetTotalSubtractableArea = () => ({
  type: types.RESET_TOTAL_SUBTRACTABLE_AREA,
});

/*
* Stores parcels subtractions in order to use
* them in next application (EPH action)
*/
export const createParcelSubtractionHistory = (parcelId, parcelSubtractionTo) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtraction-areas?`,
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(parcelSubtractionTo),
    types: [
      types.CREATE_PARCEL_SUBTRACTION,
      types.CREATE_PARCEL_SUBTRACTION_SUCCESS,
      types.CREATE_PARCEL_SUBTRACTION_FAILURE,
    ],
  },
});

/*
* Updates all parcels subtractions in context of all parcels added in the action
*/
export const updateParcelsSubtractionAreas = (parcelIds, parcelsSubtractionTos) => ({
  [RSAA]: {
    endpoint: `parcels/subtraction-area?parcelIds=${parcelIds.join(',')}&`,
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(parcelsSubtractionTos),
    types: [
      types.UPDATE_PARCELS_SUBTRACTIONS,
      types.UPDATE_PARCELS_SUBTRACTIONS_SUCCESS,
      types.UPDATE_PARCELS_SUBTRACTIONS_FAILURE,
    ],
  },
});

export const resetSubtractableAreas = () => ({
  type: types.RESET_SUBTRACTABLE_AREAS,
});
