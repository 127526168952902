/**
 * Created by ondrejzvara on 4.3.16.
 */

/* @ngInject */
export default function UnitsResource(Api, ResourceUtils) {
  const baseAll = Api.setBaseAll('units');
  const baseAllPor = Api.setBaseAll('units/por');

  const service = {
    getUnits,
    getUnitsPor
  };
  return service;

  // ///////////////////////

  function getUnits(ngTparams, custParams) {
    const params = ResourceUtils.composeParams(ngTparams, custParams);

    return ResourceUtils.getItems(baseAll, params, ngTparams);
  }

  function getUnitsPor(ngTparams, custParams) {
    const params = ResourceUtils.composeParams(ngTparams, custParams);

    return ResourceUtils.getItems(baseAllPor, params, ngTparams);
  }
}
