import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HelpIcon from '../../../../shared/icons/navbar/HelpIcon';
import HelpMenu from '../HelpMenu/HelpMenu';
import MainMenu from '../MainMenu/MainMenu';
import ToolsMenuControl from '../ToolsMenuControl/ToolsMenuControl';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserMenu from '../UserMenu/UserMenu';

const styles = {
  toolbar: {
    display: 'flex',
    height: '100%',
    marginLeft: 5,
    marginRight: 10,
  },
};

function DesktopMenu(props) {
  const { classes, ngProps } = props;
  const { currState, farm, user } = ngProps;
  return (
    <Fragment>
      <MainMenu currState={currState} farmId={farm.id} />
      <div className={classes.toolbar}>
        <ToolsMenuControl
          buttonId="desktop-help-menu"
          icon={<HelpIcon style={{ width: 28, height: 28 }} />}
          tooltipMessage={<FormattedMessage id="DesktopMenu.help" />}
        >
          <HelpMenu />
        </ToolsMenuControl>
        <ToolsMenuControl
          buttonId="desktop-user-menu"
          icon={<UserAvatar user={user} />}
          tooltipMessage={<FormattedMessage id="DesktopMenu.user" />}
        >
          <UserMenu ngProps={ngProps} />
        </ToolsMenuControl>
      </div>
    </Fragment>
  );
}

DesktopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  ngProps: PropTypes.object.isRequired,
};

export default withStyles(styles)(DesktopMenu);
