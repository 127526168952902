import React, { useContext } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getActionTypesIsFetching, getOtherActionTypes } from '../../../../api/actionTypes/actionTypes.selectors';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';
import { FormContext } from '../../actionOther.context';

function ActionSelector({ actionTypes, isFetching }) {
  const { isEditing } = useContext(FormContext);

  if (isFetching) {
    return (
      <LinearProgress color="secondary" />
    );
  }

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing || isFetching}
        label={<FormattedMessage id="common.action" />}
        name="actionType"
        select
        validate={validators.required}
        validateOnBlur={true}
      >
        {actionTypes.map(option => (
          <MenuItem key={option.id} value={option.code}>
            <FormattedMessage id={`common.${option.code}`} />
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
}

ActionSelector.propTypes = {
  actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  actionTypes: getOtherActionTypes(state),
  isFetching: getActionTypesIsFetching(state),
});

export default connect(mapStateToProps, null)(ActionSelector);
