import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import differenceBy from 'lodash/differenceBy';
import head from 'lodash/head';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { getGrowthVariabilitiesForVRA } from '../../../../core/parcels/detail/management/selectors/management.selectors';
import { getValidationFailure } from '../../../../shared/api/core/actions/actions.selectors';
import {
  getGeometry,
  getIsFetching as getIsFetchingGeometry,
} from '../../../../shared/api/core/geometry/geometry.selectors';
import { getManagementZonesIsFetching } from '../../../../shared/api/sentinel/management/management.selectors';
import { getIsExportingVA, getItems as getVariableFertilizationParcels } from '../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import { updateParcelGeometry, updateFertilizationType, updateVariableExpense } from '../actions/actions.actions';

import { indicesList } from '../../../../core/parcels/detail/monitoring/selectors/indices';
import * as monitoringStatus from '../../../../core/parcels/detail/monitoring/selectors/monitoringStatus';
import { getParcelGeometryById, resetParcelGeometry } from '../../../../shared/api/core/geometry/geometry.api';
import { getManagementZones, resetManagementZones } from '../../../../shared/api/sentinel/management/management.api';
import { getMonitoringData, resetMonitoringData } from '../../../../shared/api/sentinel/monitoring/monitoring.api';
import {
  getVariableParcelIds,
  resetVariableParcelIds,
  getVariableActionExpenses,
} from '../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import CfErrorPage from '../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../../shared/components/common/CfLoader/CfLoader';
import SidebarMapWrapper from '../../../../shared/components/specific/SidebarMapWrapper/SidebarMapWrapper';
import { getDisplayName } from '../../../../shared/hocs/hocHelpers';
import VariableFertilizationExpired from '../../vrf/components/VariableFertilizationExpired/VariableFertilizationExpired';
import VariableFertilizationMap from '../../vrf/containers/VariableFertilizationMap/VariableFertilizationMap';
import ActionFormButtons from '../components/ActionFormButtons/ActionFormButtons';
import ActionFormHeader from '../components/ActionFormHeader/ActionFormHeader';
import ActionValidationInfo from '../components/ActionValidationInfo/ActionValidationInfo';
import { parcelsCountChanged, resolveTargetCrop, expenseCountChanged } from '../misc/action.helpers';

const monitoringDataTypes = indicesList.map(i => i.id);
const historyDataTypes = ['HIST'];

const variableActionForm = ({
  getApiError,
  getIsLoading,
  getCreateVaIsLoading,
  onExport,
  satelliteDataTypes = historyDataTypes,
}) => WrappedComponent => {
  class VariableActionForm extends Component {
    constructor(props) {
      super(props);

      // set init satellite data values to null
      const initSatelliteData = {};
      satelliteDataTypes.forEach(type => {
        initSatelliteData[type] = null;
        initSatelliteData[[`${type}_isFetched`]] = false;
      });

      this.state = {
        // stands for VaRiableApplication
        isVraAllowed: false,
        // we have to make sure we display WrappedComponent
        // after all init data have been fetched (prop `isLoading`)
        isDataLoaded: false,
        currMapZones: null,
        ...initSatelliteData,
        satellites_isFetched: false,
      };
    }

    componentDidMount() {
      this.props.getVariableParcelIds().then(res => {
        if (!res.error) {
          this.setState({
            isVraAllowed: Boolean(res.payload.length),
          });
        }
      });

      const { existingAction } = this.props;
      if (existingAction) {
        this.fetchVariableExpenses(existingAction.id);
      }
    }

    componentDidUpdate(prevProps) {
      const {
        expenses: prevExpenses,
        geometry: prevGeometry,
        historySatelliteData: prevHistorySatelliteData,
        isLoading: prevIsLoading,
        isManagementZonesLoading: prevIsManagementZonesLoading,
        parcels: prevParcels,
        satellite: prevSatellite,
        targetCrop: prevTargetCrop,
      } = prevProps;

      const {
        existingAction: newExistingAction,
        expenses: newExpenses,
        fieldsManuallyChanged: newFieldsManuallyChanged,
        formName: newFormName,
        geometry: newGeometry,
        historySatelliteData: newHistorySatelliteData,
        isLoading: newIsLoading,
        isManagementZonesLoading: newIsManagementZonesLoading,
        minMappingUnit: newMinMappingUnit,
        parcels: newParcels,
        satellite: newSatellite,
        targetCrop: newTargetCrop,
      } = this.props;

      const { satellites_isFetched: newSatellites_isFetched } = this.state;

      // Comparison booleans to detect the change
      const targetCropManualDiff = Boolean(newFieldsManuallyChanged.targetCrop);
      const parcelsCountDiff = parcelsCountChanged(prevParcels, newParcels);
      const resolvedTargetCrop = resolveTargetCrop(newParcels);
      const expenseCountDiff = expenseCountChanged(prevExpenses, newExpenses);

      this.isSatelliteDataFetched();

      if (newIsLoading !== prevIsLoading) {
        this.setState({
          isDataLoaded: !newIsLoading,
        });
      }

      if (!newIsManagementZonesLoading && prevIsManagementZonesLoading) {
        this.setState({
          [`${head(historyDataTypes)}_isFetched`]: true,
        });
      }

      if (prevHistorySatelliteData !== newHistorySatelliteData) {
        this.setState({
          [head(historyDataTypes)]: newHistorySatelliteData,
        });
      }

      /*
       * targetCrop RULES
       */

      /*
       * Touch target crop in order to get validated. Do when:
       * 1) prev target crop was not set
       * 2) new target crop is set
       * 3) action is already existing
       */
      if (newExistingAction && !prevTargetCrop && prevTargetCrop !== newTargetCrop) {
        this.props.touch('targetCrop');
      }

      /*
       * Update target crop when:
       * 1) parcels count changed
       * 2) and resolved target crop is set
       * 3) and new target crop doesnt is not set or is not manually edited yet
       * 5) or resolved target crop id does not equal new target crop id
       * 6) wtf is this ... ?!
       */
      if (!targetCropManualDiff && !newTargetCrop && parcelsCountDiff && newParcels.length) {
        if (resolvedTargetCrop && resolvedTargetCrop.id !== newTargetCrop?.id) {
          this.props.change('targetCrop', resolvedTargetCrop);
        }
      }

      /*
       * Set target crop to null when:
       * 1) parcels count changed
       * 2) and there are no parcels
       * 3) and target crop was not changed manually yet
       */
      if (parcelsCountDiff && !newParcels.length && !targetCropManualDiff) {
        this.props.change('targetCrop', null);
        this.props.untouch('targetCrop');
      }

      /*
       * minMappingUnit & zonesCount RULES
       */

      /*
       * Set MMU and zonesCount when:
       * 1) expense was added
       * 2) MMU is not set yet
       */
      if (newExpenses.length && head(newExpenses)?.variableExpense?.mmu && !newMinMappingUnit) {
        this.props.change('minMappingUnit', head(newExpenses).variableExpense.mmu);
        this.props.change('zonesCount', head(newExpenses).variableExpense.applicationZones.length);
      }

      /*
       * Reset MMU and zonesCount when:
       * 1) expenses were removed
       */
      if (expenseCountDiff && !newExpenses.length) {
        this.props.change('minMappingUnit', null);
        this.props.change('zonesCount', null);
      }

      /*
       * variabilities RULES
       */

      /*
       * Fetch variabilities when:
       * 1) new parcel is added; only one parcel can be added
       */
      if (parcelsCountDiff && newParcels.length) {
        const parcel = head(differenceBy(newParcels, prevParcels, 'id'));
        this.props.getParcelGeometryById(parcel.id);

        // loading of satellite data should be moved to `VaMapSourceDialog` and called when it is open
        this.fetchManagementData(parcel);
        this.fetchMonitoringData(parcel);
      }

      /*
       * Set zonesCount & satellite to nulls, expenses to [] when:
       * 1) parcels count changed
       * 2) and there are no parcels
       */
      if (parcelsCountDiff && !newParcels.length) {
        this.props.resetManagementZones();
        this.props.resetMonitoringData();
        this.props.resetParcelGeometry();
      }

      if (
        newExistingAction &&
        newSatellites_isFetched &&
        newExpenses.length &&
        head(newExpenses)?.variableExpense &&
        !newSatellite &&
        !prevSatellite
      ) {
        const variableExpense = head(newExpenses).variableExpense;
        const satellite = variableExpense.index;
        this.props.change('satellite', satellite);
      }

      /*
       * expenses RULES
       */

      /*
       * Reset expenses when:
       * 1) satellite was removed
       */
      if (prevSatellite !== newSatellite && !newSatellite) {
        this.props.change('expenses', []);
      }

      /*
       * geometry RULES
       */
      if (prevGeometry !== newGeometry && newGeometry) {
        this.props.updateParcelGeometry(newGeometry, 0, newFormName);
      }
    }

    componentWillUnmount() {
      this.props.resetManagementZones();
      this.props.resetMonitoringData();
      this.props.resetVariableParcelIds();
      this.props.resetParcelGeometry();
    }

    setCurrMapZones = currMapZones => {
      this.setState({ currMapZones });
    };

    isSatelliteDataFetched() {
      let res = true;
      satelliteDataTypes.forEach(type => {
        if (!this.state[[`${type}_isFetched`]]) {
          res = false;
        }
      });

      if (this.state.satellites_isFetched !== res) {
        this.setState({
          satellites_isFetched: res,
        });
      }
    }

    fetchVariableExpenses(actionId) {
      this.props.getVariableActionExpenses(actionId).then(res => {
        if (!res.error) {
          const { expenses, fertilizationType, formName } = this.props;
          let indexError = false;
          res.payload.forEach(va => {
            const expense = expenses.find(e => e.material.id === va.materialId);
            const index = expenses.indexOf(expense);
            if (index !== -1) {
              if (!fertilizationType.id && va.type) {
                this.props.updateFertilizationType(formName, { id: va.type });
              }
              this.setCurrMapZones(va?.applicationZones || null);
              this.props.updateVariableExpense(va, index, formName);
            } else {
              indexError = true;
            }
          });
          if (indexError) {
            throw new Error('Unable to find expense index related to the VA');
          }
        }
      });
    }

    fetchManagementData(parcel) {
      const types = satelliteDataTypes.filter(satelliteDataType => historyDataTypes.includes(satelliteDataType));
      types.forEach(() => {
        this.props.getManagementZones(parcel.id);
      });
    }

    fetchMonitoringData(parcel) {
      const promises = [];
      const types = satelliteDataTypes.filter(satelliteDataType => monitoringDataTypes.includes(satelliteDataType));
      const status = [monitoringStatus.OK, monitoringStatus.LAI_ONLY].join(',');
      types.forEach(type => {
        const promise = this.props.getMonitoringData(parcel.id, type, '', '', status, 5).then(res => {
          // the service is turned off
          if (res.error) {
            return { [type]: null };
          }

          // the service is turned on and has data
          if (res.payload) {
            const intervals = res.payload;
            return {
              [type]: intervals
                .splice(0, 5)
                .map(interval => ({
                  dateFrom: interval.dateFrom,
                  dateTo: interval.dateTo,
                  crop: interval.crop,
                  averageQuality: interval.averageQuality,
                  zones: head(interval.snapshots)?.zones.map(z => ({
                    geometry: z.geometry,
                    zoneNumber: Number(z.name) || z.name,
                    color: z.color,
                  })),
                  type,
                }))
                .filter(item => item.zones && item.zones.length > 0),
            };
          }

          // the service is turned on and has no data
          return { [type]: [] };
        });
        promises.push(promise);
      });

      Promise.all(promises)
        .then(data => {
          data.forEach(result => {
            const entries = Object.entries(result);
            const [key, val] = head(entries);
            this.setState({
              [key]: val,
            });
          });
        })
        .finally(() => {
          types.forEach(type => {
            this.setState({
              [`${type}_isFetched`]: true,
            });
          });
        });
    }

    render() {
      const { currMapZones, isDataLoaded, isVraAllowed } = this.state;

      const satelliteData = {};
      satelliteDataTypes.forEach(type => {
        satelliteData[type] = this.state[type];
      });

      const {
        apiError,
        fieldsManuallyChanged,
        formName,
        geometry,
        handleSubmit,
        history,
        isCreateVaLoading,
        isEditing,
        isExisting,
        isExportingVA,
        isFetchingGeometry,
        isLoading,
        isPristine,
        isSubmitting,
        minMappingUnit,
        ngGoToActions,
        onCancel,
        onEditingStart,
        onReset,
        parcels,
        validationFailure,
        ...rest
      } = this.props;
      return (
        <CfErrorPage error={apiError}>
          <SidebarMapWrapper
            map={
              <VariableFertilizationMap
                currMapZones={currMapZones}
                geometry={geometry}
                isFetchingGeom={isFetchingGeometry}
                parcel={head(parcels) || {}}
              />
            }
          >
            {!isDataLoaded ? (
              <CfLoader />
            ) : (
              <Fragment>
                <ActionFormHeader
                  history={history}
                  isDisabled={isEditing}
                  isExisting={isExisting}
                  isLoading={isExportingVA}
                  ngGoToActions={ngGoToActions}
                  onClick={onEditingStart}
                  onExport={exportType => onExport(this.props, exportType)}
                  title={`${startCase(formName).replace(/\s/g, '')}.title`}
                />
                <Grid alignItems="center" container justifyContent="center" spacing={0}>
                  {!isVraAllowed && (
                    <Grid item xs={8}>
                      <VariableFertilizationExpired onSubscribe={() => {}} />
                    </Grid>
                  )}
                  <Grid item lg={10} xl={9} xs={11}>
                    <form onSubmit={handleSubmit}>
                      <Grid container justifyContent="center" spacing={5}>
                        <WrappedComponent
                          currMapZones={currMapZones}
                          formName={formName}
                          geometry={geometry}
                          isCreateVaLoading={isCreateVaLoading}
                          isEditing={isEditing}
                          parcels={parcels}
                          satelliteData={satelliteData}
                          setCurrMapZones={this.setCurrMapZones}
                          {...rest}
                        />
                        <ActionValidationInfo validationDetails={validationFailure} />
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
                {isEditing && (
                  <ActionFormButtons
                    formName={formName}
                    isExisting={isExisting}
                    isLoading={isCreateVaLoading}
                    isPristine={isPristine}
                    isSubmitting={isSubmitting}
                    onCancel={onCancel}
                    onReset={onReset}
                    withExport={false}
                  />
                )}
              </Fragment>
            )}
          </SidebarMapWrapper>
        </CfErrorPage>
      );
    }
  }

  const mapStateToProps = (state, props) => {
    const selector = formValueSelector(props.formName);

    return {
      apiError: getApiError(state),
      geometry: getGeometry(state),
      isLoading: getIsLoading(state),
      isFetchingGeometry: getIsFetchingGeometry(state),
      isCreateVaLoading: getCreateVaIsLoading(state),
      isManagementZonesLoading: getManagementZonesIsFetching(state),
      validationFailure: getValidationFailure(state),
      variableParcelIds: getVariableFertilizationParcels(state),
      historySatelliteData: getGrowthVariabilitiesForVRA(state),
      isExportingVA: getIsExportingVA(state),
      actionDate: selector(state, 'actionDate'),
      satellite: selector(state, 'satellite'),
      fertilizationType: selector(state, 'fertilizationType'),
      parcels: selector(state, 'parcels'),
      targetCrop: selector(state, 'targetCrop'),
      minMappingUnit: selector(state, 'minMappingUnit'),
      expenses: selector(state, 'expenses'),
    };
  };

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        getVariableParcelIds,
        resetVariableParcelIds,
        getParcelGeometryById,
        resetParcelGeometry,
        getManagementZones,
        resetManagementZones,
        getMonitoringData,
        resetMonitoringData,
        updateParcelGeometry,
        getVariableActionExpenses,
        updateFertilizationType,
        updateVariableExpense,
      },
      dispatch,
    );

  VariableActionForm.propTypes = {
    apiError: PropTypes.object.isRequired,
    isFetchingGeometry: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isExisting: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isCreateVaLoading: PropTypes.bool.isRequired,
    fieldsManuallyChanged: PropTypes.object.isRequired,
    variableParcelIds: PropTypes.array.isRequired,
    isManagementZonesLoading: PropTypes.bool.isRequired,
    updateVariableExpense: PropTypes.func.isRequired,
    // Functions
    getVariableParcelIds: PropTypes.func.isRequired,
    resetVariableParcelIds: PropTypes.func.isRequired,
    getParcelGeometryById: PropTypes.func.isRequired,
    resetParcelGeometry: PropTypes.func.isRequired,
    getManagementZones: PropTypes.func.isRequired,
    getMonitoringData: PropTypes.func.isRequired,
    resetMonitoringData: PropTypes.func.isRequired,
    resetManagementZones: PropTypes.func.isRequired,
    onEditingStart: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    untouch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    updateParcelGeometry: PropTypes.func.isRequired,
    getVariableActionExpenses: PropTypes.func.isRequired,
    updateFertilizationType: PropTypes.func.isRequired,
    ngGoToActions: PropTypes.func.isRequired,
    // Non-required
    historySatelliteData: PropTypes.array,
    validationFailure: PropTypes.object,
    existingAction: PropTypes.object,
    geometry: PropTypes.object,
    parcels: PropTypes.array,
    expenses: PropTypes.array,
    targetCrop: PropTypes.object,
    minMappingUnit: PropTypes.number,
    satellite: PropTypes.object,
    actionDate: PropTypes.object,
    fertilizationType: PropTypes.object,
  };

  VariableActionForm.defaultProps = {
    parcels: [],
    expenses: [],
    actionDate: {},
    historySatelliteData: null,
    validationFailure: null,
    existingAction: null,
    geometry: null,
    targetCrop: null,
    minMappingUnit: null,
    satellite: null,
    fertilizationType: {},
  };

  VariableActionForm.displayName = `VariableActionForm(${getDisplayName(WrappedComponent)})`;

  return compose(connect(mapStateToProps, mapDispatchToProps))(VariableActionForm);
};

export default variableActionForm;
