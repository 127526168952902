export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR';

export const ACTION_VALIDATE = 'ACTION_VALIDATE';
export const ACTION_VALIDATE_SUCCESS = 'ACTION_VALIDATE_SUCCESS';
export const ACTION_VALIDATE_FAILURE = 'ACTION_VALIDATE_FAILURE';
export const RESET_VALIDATE_FAILURE = 'RESET_VALIDATE_FAILURE';

export const ACTION_SAVE = 'ACTION_SAVE';
export const ACTION_SAVE_SUCCESS = 'ACTION_SAVE_SUCCESS';
export const ACTION_SAVE_FAILURE = 'ACTION_SAVE_FAILURE';

export const GET_ACTION_BY_TRANSACTION_ID = 'GET_ACTION_BY_TRANSACTION_ID';
export const GET_ACTION_BY_TRANSACTION_ID_SUCCESS = 'GET_ACTION_BY_TRANSACTION_ID_SUCCESS';
export const GET_ACTION_BY_TRANSACTION_ID_ERROR = 'GET_ACTION_BY_TRANSACTION_ID_ERROR';

export const ACTION_UPDATE = 'ACTION_UPDATE';
export const ACTION_UPDATE_SUCCESS = 'ACTION_UPDATE_SUCCESS';
export const ACTION_UPDATE_FAILURE = 'ACTION_UPDATE_FAILURE';

export const GET_ACTION = 'GET_ACTION';
export const GET_ACTION_SUCCESS = 'GET_ACTION_SUCCESS';
export const GET_ACTION_ERROR = 'GET_ACTION_ERROR';

export const DELETE_ACTIONS = 'DELETE_ACTIONS';
export const DELETE_ACTIONS_SUCCESS = 'DELETE_ACTIONS_SUCCESS';
export const DELETE_ACTIONS_ERROR = 'DELETE_ACTIONS_ERROR';
