import { decode } from 'jsog';
import queryString from 'query-string';
import { RSAA, getJSON } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './zones.constants';

export const getZones = ({ name, page = 1, perPage = 500, sortCol, sortDir } = {}, context) => {
  const params = {
    page,
    'per-page': perPage,
    ...(sortDir ? { 'sort-dir': sortDir } : {}),
    ...(sortCol ? { 'sort-col': sortCol } : {}),
    ...(name ? { name } : {}),
  };

  return {
    [RSAA]: {
      context,
      endpoint: `zones?${queryString.stringify(params)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_ZONES,
        {
          type: types.GET_ZONES_SUCCESS,
          payload: (action, state, res) => getJSON(res).then(json => decode(json)),
        },
        types.GET_ZONES_FAILURE,
      ],
    },
  };
};

export const resetZones = context => ({
  type: types.RESET_ZONES,
  context,
});

export const getZonesStatistics = context => ({
  [RSAA]: {
    endpoint: 'zones/statistics?',
    method: methods.GET,
    context,
    module: modules.CORE,
    types: [types.GET_ZONES_STATISTICS, types.GET_ZONES_STATISTICS_SUCCESS, types.GET_ZONES_STATISTICS_FAILURE],
  },
});

export const resetZonesStatistics = () => ({
  type: types.RESET_ZONES_STATISTICS,
});

export const updateZone = zonePutDto => ({
  [RSAA]: {
    endpoint: `zones/${zonePutDto.id}?`,
    method: methods.PUT,
    body: JSON.stringify(zonePutDto),
    module: modules.CORE,
    types: [types.PUT_ZONE, types.PUT_ZONE_SUCCESS, types.PUT_ZONE_ERROR],
  },
});

export const createZone = zoneDto => ({
  [RSAA]: {
    endpoint: 'zones?',
    method: methods.POST,
    body: JSON.stringify(zoneDto),
    module: modules.CORE,
    types: [types.POST_ZONE, types.POST_ZONE_SUCCESS, types.POST_ZONE_ERROR],
  },
});

export const deleteZone = zone => ({
  [RSAA]: {
    endpoint: `zones/${zone.id}?`,
    method: methods.DELETE,
    module: modules.CORE,
    types: [types.DELETE_ZONE, types.DELETE_ZONE_SUCCESS, types.DELETE_ZONE_ERROR],
  },
});
