import { AnyAction } from 'redux';

import * as types from './sectionStatus.constants';

import { TelematicsStatusState } from './sectionStatus.types';

export default () => (
  state: TelematicsStatusState = {
    isAllowed: false,
    verified: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case types.GET_TELEMATIC_STATUS_SUCCESS:
      return {
        ...state,
        isAllowed: action.payload.telematics,
        verified: true,
      };
    default:
      return state;
  }
};
