import { OTHER_ACTIONS_TYPES, OTHER_ACTION_TYPE_CODES } from '../actionOther.constants';

export default class OtherActionsService {
  static getOtherActionTypeCodes(actionTypes) {
    return actionTypes.filter(type => OTHER_ACTION_TYPE_CODES.includes(type.code));
  }

  static getFormType(actionTypeCode) {
    const type = OTHER_ACTIONS_TYPES.find(({ typeCodes }) => typeCodes.includes(actionTypeCode));
    return type?.formType;
  }
}
