import React from 'react';

import { InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';

import { createComponent } from '../../reduxFormUtils';
import ValidationStatusIcon from '../../specific/ValidationStatusIcon/ValidationStatusIcon';
import CfReduxFormTextField from '../CfReduxFormTextField/CfReduxFormTextField';

const style = {
  positionStart: {
    position: 'absolute',
    left: '-30px',
  },
  separator: {
    margin: '10px 0 10px 0',
  },
  root: {},
};

const jsonStringify = (items, value, idPropertyName) => {
  if (items.length > 0) {
    return JSON.stringify(items.find(item => item[idPropertyName] === value[idPropertyName]));
  }
  return '';
};

const isObject = value => typeof value === 'object';
const isComplexMessage = (error, warning) => (error && error.message) || (warning && warning.message);
const resolveIconType = (error, warning) => {
  if (error) {
    return error.icon ? error.icon : 'error';
  }

  if (warning) {
    return warning.icon ? warning.icon : 'warning';
  }

  return undefined;
};

export default withStyles(style)(
  createComponent(
    CfReduxFormTextField,
    ({
      items = items ? items.filter(item => item) : [],
      idPropertyName = 'id',
      labelPropertyFactory = option => option.name,
      input: { onChange, value, ...inputProps },
      meta: { error, touched, warning } = {},
      classes,
      children = items.map(option =>
        (option ? (
          <MenuItem
            key={isObject(option) ? option[idPropertyName] : option}
            value={isObject(option) ? JSON.stringify(option) : option}
            classes={{
              root: classes.root,
            }}
          >
            {isObject(option) ? labelPropertyFactory(option) : option}
          </MenuItem>
        ) : (
          <hr className={classes.separator} key="separator" />
        )),
      ),
      ...props
    }) => ({
      select: true,
      fullWidth: true,
      InputProps: {
        startAdornment: isComplexMessage(error, warning) && (
          <InputAdornment classes={{ positionStart: classes.positionStart }} position="start">
            <ValidationStatusIcon id={inputProps.name} type={resolveIconType(error, warning)}>
              {(error && error.message) || (warning && warning.message)}
            </ValidationStatusIcon>
          </InputAdornment>
        ),
      },
      children,
      input: {
        value: isObject(value)
          ? jsonStringify(
            items.filter(item => item),
            value,
            idPropertyName,
          )
          : value,
        ...inputProps,
      },
      meta: !isComplexMessage(error, warning) ? { touched, error, warning } : undefined,
      onChange: event => {
        const selection = JSON.parse(event.target.value);
        onChange(selection);
      },
      onBlur: () => {
        if (inputProps.onBlur) inputProps.onBlur(inputProps.value);
      },
      ...props,
    }),
  ),
);
