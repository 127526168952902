import { RSAAAction } from 'redux-api-middleware';

import { patchParcel, putParcelsAssignCenterApi } from '../../../../shared/api/core/parcels/parcels.api';
import {
  createZone as createZoneApi,
  updateZone as updateZoneApi,
  deleteZone as deleteZoneApi,
} from '../../../../shared/api/core/zones/zones.api';

import { Zone } from '../../../../shared/api/core/parcels/parcels.types';

export const assignCenter = (centerId: string, payload: string[]) => (
  dispatch: (action: RSAAAction) => void,
) =>
  dispatch(putParcelsAssignCenterApi(centerId, payload) as unknown as RSAAAction);

export const assignZone = (zoneId: string, parcelId: string) => (
  dispatch: (action: RSAAAction) => void,
) =>
  dispatch(patchParcel(parcelId, { zone: zoneId ? { id: zoneId } : null }) as unknown as RSAAAction);

export const updateZone = (zone: Zone) => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(updateZoneApi(zone) as unknown as RSAAAction);

export const createZone = (zone: Zone) => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(createZoneApi(zone) as unknown as RSAAAction);

export const deleteZone = (zone: Zone) => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(deleteZoneApi(zone) as unknown as RSAAAction);
