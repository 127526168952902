import { NOT_SOWN_PARCEL_SEED_NAME } from '../actionOther.constants';

import OtherActionsService from '../services/OtherActions.service';

export const getFormType = (action) => {
  const actionTypeCode = action.actionType.code;
  return OtherActionsService.getFormType(actionTypeCode);
};

export const isMatchingFormType = (action, formTypeToMatch) => {
  const formType = getFormType(action);
  return formType === formTypeToMatch;
};

export const isOtherAction = (action) => {
  if (!action || !action.id) return false;
  const formType = getFormType(action);
  return !!formType;
};

export const isParcelSown = (parcel) => {
  if (parcel) {
    return parcel?.currentSeedApplication?.seed?.name !== NOT_SOWN_PARCEL_SEED_NAME;
  }

  return false;
};

export const isAnyParcelSown = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.some(parcel => isParcelSown(parcel));
  }
};

export const isAnyParcelNotSown = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.some(parcel => !isParcelSown(parcel));
  }
};

export const filterOnlySownParcels = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.filter(parcel => isParcelSown(parcel));
  }
};
