/**
 * Created by brasko on 31.8.2015.
 */

/* @ngInject */
export default function cfDatepicker($rootScope, $locale, $timeout) {
  const directive = {
    restrict: 'E',
    template: require('./cf-datepicker.html'),
    scope: {
      target: '=',
      min: '=?',
      max: '=?',
      validators: '@?',
      name: '@?',
      label: '@?',
      onChange: '&?',
      onBlurDecorator: '&?'
    },
    link(scope) {
      scope.isOpened = false;
      scope.min = scope.min || 0;
      scope.max = scope.max || 0;
      scope.label = scope.label || '';
      scope.name = scope.name || 'date';
      scope.validators = !!scope.validators; // by default it is not required
      scope.onChange = scope.onChange || angular.noop;
      scope.onBlurDecorator = scope.onBlurDecorator || angular.noop;
      scope.invalidDate = false;

      // bugfix of the ui-bootstrap directive
      if (typeof scope.target === 'string') {
        scope.target = new Date(scope.target);
      }

      scope.date = {
        today: new Date()
      };

      scope.dateOptions = {
        startingDay: 1,
        minDate: scope.min,
        maxDate: scope.max
      };

      scope.format = $locale.DATETIME_FORMATS.mediumDate;

      scope.open = function () {
        scope.isOpened = true;
      };

      scope.onFocus = function () {
        scope.invalidDate = false;
      };

      scope.onBlur = function () {
        if (scope.target !== null) {
          const inputDate = new Date(scope.target);
          if (isNaN(inputDate.getTime())) {
            scope.invalidDate = true;
          } else {
            const newDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
            scope.target = newDate;
            scope.invalidDate = false;
            scope.onBlurDecorator();
          }
        }
      };

      // not sure with this one - @mpenak why is it here?
      /* scope.initWatch = true;
            scope.$watch('target', function() {
                if (!scope.initWatch) {
                    scope.onChange();
                }

                //only notify when a date is really picked, not upon the initialization
                scope.initWatch = false;
            }); */

      $rootScope.$on('$translateChangeSuccess', () => {
        $timeout(() => {
          scope.format = $locale.DATETIME_FORMATS.mediumDate;
        });
      });
    }
  };
  return directive;
}
