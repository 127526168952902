import DeviceConfig from '../../../../sensors/services/DeviceConfig.service';
import FeatureConfig from '../../../../sensors/services/FeatureConfig.service';
import Logger from '../../../services/Logger.service';

const RAINFAILL_FEATURE_TYPE = 'Rainfall';

export default class NodesApi {
  static filterSupportedSensors(node, modules) {
    let cumulativeNodeSorted = [];

    if (Array.isArray(node.sensors)) {
      const featuresOrder = FeatureConfig.getSupportedFeatureNames();
      const filterNode = node.sensors.filter(sensor => FeatureConfig.isFeatureSupported(sensor))
        .map(sensor => ({
          lastLocationId: node.lastLocationId,
          ...sensor,
        }));

      const filterModules = modules.flatMap(modul => (
        modul.sensors.filter(sensor => FeatureConfig.isFeatureSupported(sensor))
          .map(sensor => ({
            lastLocationId: modul.lastLocationId,
            ...sensor,
          }))));

      let cumulativeNode;

      if (filterModules.length > 0
        && filterModules.some(m => m.feature === RAINFAILL_FEATURE_TYPE)
        && filterNode.some(m => m.feature === RAINFAILL_FEATURE_TYPE)
      ) {
        const filterNodefromRainfall = filterNode.filter(m => m.feature !== RAINFAILL_FEATURE_TYPE);
        cumulativeNode = [...filterNodefromRainfall, ...filterModules];
      } else {
        cumulativeNode = [...filterNode, ...filterModules];
      }
      cumulativeNodeSorted = cumulativeNode.sort((a, b) => {
        const aIndex = featuresOrder.findIndex(item => item === a.feature.toUpperCase());
        const bIndex = featuresOrder.findIndex(item => item === b.feature.toUpperCase());
        return aIndex - bIndex;
      });
    }
    return {
      ...node,
      signal: this.getNodeFeatureValue(node, 'LQI'),
      voltage: this.getNodeFeatureValue(node, 'Voltage'),
      sensors: cumulativeNodeSorted,
    };
  }

  static getNodeFeatureValue(node, feature) {
    if (node.sensors.length > 0) {
      const sensorFeature = node.sensors.find(s => s.feature === feature);
      return sensorFeature ? sensorFeature.value : null;
    }
    return null;
  }

  static filterSupportedDevices(nodes, ignoreUnknownTypes) {
    if (!ignoreUnknownTypes) {
      return nodes;
    }

    const supportedDevices = DeviceConfig.getSupportedDevices();
    return nodes.filter(node => {
      const includes = supportedDevices.includes(node.deviceType.toUpperCase());
      if (!includes) {
        Logger.warn(`Invalid device type: ${node.deviceType}. Skipping node with ID ${node.id}`);
      }

      return includes;
    });
  }

  static isSupportedDevice(node) {
    const supportedDevices = DeviceConfig.getSupportedDevices();
    const includes = supportedDevices.includes(node.deviceType.toUpperCase());
    if (!includes) {
      Logger.warn(`Invalid device type: ${node.deviceType}. Skipping node with ID ${node.id}`);
    }

    return includes;
  }
}
