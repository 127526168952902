import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfTextFilter from '../../../../../shared/containers/CfTextFilter/CfTextFilter';

const styles = {
  wrapper: {
    margin: 0,
  },
};

export const SensorAdminTextFilter = (props) => {
  const { classes, namespace } = props;
  return (
    <div className={classes.wrapper}>
      <CfTextFilter name="sensors-admin-text-filter" namespace={namespace} translId={'SensorsAdmin.textFilter'} />
    </div>
  );
};

SensorAdminTextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default withStyles(styles)(SensorAdminTextFilter);
