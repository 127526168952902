import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import areaDevicesUI from '../irrigation/reducer/areaDevices.reducer';
import areasUI from '../irrigation/reducer/areas.reducer';
import commonUI from '../irrigation/reducer/common.reducer';
import createOrEditAreaUI from '../irrigation/reducer/createOrEditArea.reducer';
import deviceUI from '../irrigation/reducer/device.reducer';
import devicesListUI from '../irrigation/reducer/devicesList.reducer';
import irrMapUI from '../irrigation/reducer/map.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import areasAPI from '../shared/api/irrigation/areas/areas.reducer';
import devicesAPI from '../shared/api/irrigation/devices/devices.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      common: commonUI(),
      map: mapUI(),
      layers: layersUI(),
      devicesList: devicesListUI(),
      areasList: areasUI(),
      areaDevicesList: areaDevicesUI(),
      createOrEditArea: createOrEditAreaUI(),
      irrMap: irrMapUI(),
      editor: editorUI(),
      device: deviceUI(),
    }),
    api: combineReducers({
      devices: devicesAPI(),
      areas: areasAPI(),
      layers: layersApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
