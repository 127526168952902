const TOGGLES = {
  IRRIGATION_AREAS: {
    name: 'IRRIGATION_AREAS',
    jira: 'CFD-96',
    description: 'Irrigation Areas Table',
    active: true,
    permanent: true,
  },
  TELEMATICS: {
    name: 'TELEMATICS',
    jira: 'CFD-53',
    description: 'Show telematics menu item',
    active: false,
    permanent: false,
  },
  // example:
  // MY_FIRST_TOGGLE: {
  //   name: 'MY_FIRST_TOGGLE', //should be same as key
  //   jira: 'JIRA ticket ID',
  //   active: false, //active by default
  //   permanent: true //overrides user preferences
  // }
};

export default TOGGLES;
