import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FarmIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M20.74,12.36,17.83,5.75a.94.94,0,0,0-.34-.4L12.32,2a.93.93,0,0,0-1,0L6.14,5.35a.92.92,0,0,0-.33.4L2.89,12.36a.93.93,0,0,0,.48,1.22.91.91,0,0,0,1.08-.28V22a.57.57,0,0,0,.57.59H6.91V16.05a.59.59,0,0,1,.57-.6h8.67a.58.58,0,0,1,.57.6v6.57h1.84a.57.57,0,0,0,.56-.59V13.22a1,1,0,0,0,.78.44,1,1,0,0,0,.37-.08A.92.92,0,0,0,20.74,12.36Zm-6.65-.21a.59.59,0,0,1-.59.59H10.13a.59.59,0,0,1-.58-.59V8.21a.58.58,0,0,1,.58-.59H13.5a.58.58,0,0,1,.59.59Z" />
    </svg>
  </SvgIcon>
);

export default FarmIcon;
