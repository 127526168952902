import layersConfigCZ from './layersCZ.config.json';
import layersConfigGlobal from './layersGlobal.config.json';

export default class LayersApiService {
  static getLayersConfig(global) {
    return new Promise((resolve) => {
      const wait = setTimeout(() => {
        clearTimeout(wait);
        resolve(global ? layersConfigGlobal : layersConfigCZ);
      }, 0);
    });
  }
}
