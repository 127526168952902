export const MAIN_MAP_TELEMATICS_PREFIX = 'MAIN_MAP_TELEMATICS';

export const SET_DATE_FROM = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_DATE_FROM`;
export const SET_DATE_TO = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_DATE_TO`;
export const SET_MACHINE_FILTER = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_MACHINE_FILTER`;
export const SET_DRIVER_FILTER = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_DRIVER_FILTER`;

export const RESET_FILTERS = `${MAIN_MAP_TELEMATICS_PREFIX}/RESET_FILTERS`;

export const SET_SELECTED_MACHINE_GPS_UNIT = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_SELECTED_MACHINE_GPS_UNIT`;
