import queryString from 'query-string';
import { RSAA, RSAAAction } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './drives.constants';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { ExportPayload, RsaaMethods, RsaaTypes } from '../../api.types';
import { DriveCreateTo, DriveRecalculateTo, State } from '../telematics.types';
import { ExportDrivesApiParams, ExportDrivesFormat, GetDriversApiParams, GetDrivesApiParams, GetEquipmentsApiParams, GetMachinesApiParams } from './drives.types';

export const getDrivesApi = (params: GetDrivesApiParams) => ({
  [RSAA]: {
    endpoint: `logbook?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_DRIVES_REQUEST, types.GET_DRIVES_SUCCESS, types.GET_DRIVES_ERROR] as RsaaTypes,
  },
});

export const createDriveApi = (drive: DriveCreateTo) => ({
  [RSAA]: {
    endpoint: 'logbook?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(drive),
    module: modules.TELEMATICS,
    types: [types.CREATE_DRIVE_REQUEST, types.CREATE_DRIVE_SUCCESS, types.CREATE_DRIVE_ERROR] as RsaaTypes,
  },
});

export const editDriveApi = (driveId: string, drive: DriveCreateTo) => ({
  [RSAA]: {
    endpoint: `logbook/${driveId}?`,
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(drive),
    module: modules.TELEMATICS,
    types: [types.EDIT_DRIVE_REQUEST, types.EDIT_DRIVE_SUCCESS, types.EDIT_DRIVE_ERROR] as RsaaTypes,
  },
});

export const calculateAreaFromEquipment = (driveId: number, equipment: DriveRecalculateTo) => ({
  [RSAA]: {
    endpoint: `logbook/${driveId}/area?`,
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(equipment),
    module: modules.TELEMATICS,
    types: [types.CALCULATE_AREA_REQUEST, types.CALCULATE_AREA_SUCCESS, types.CALCULATE_AREA_ERROR] as RsaaTypes,
  },
});

export const exportDrivesApi = (format: ExportDrivesFormat, params: ExportDrivesApiParams) => ({
  [RSAA]: {
    endpoint: `logbook/export/${format}?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.EXPORT_DRIVES_REQUEST,
      {
        type: types.EXPORT_DRIVES_SUCCESS,
        payload: (
          _action: RSAAAction,
          _state: TelematicsState,
          res: Response,
        ) => ({
          body: res.blob(),
          type: res.headers.get('Content-Type'),
          disposition: res.headers.get('Content-Disposition'),
        } as ExportPayload),
      },
      types.EXPORT_DRIVES_ERROR,
    ] as RsaaTypes,
  },
});

export const getDriveDetailApi = (id: string) => ({
  [RSAA]: {
    endpoint: `logbook/${id}?`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_DRIVE_DETAIL_REQUEST, types.GET_DRIVE_DETAIL_SUCCESS, types.GET_DRIVE_DETAIL_ERROR] as RsaaTypes,
  },
});

export const resetDriveDetail = () => ({
  type: types.RESET_DRIVE_DETAIL,
});

export const getOperationsApi = () => ({
  [RSAA]: {
    endpoint: 'operations?',
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_OPERATIONS_REQUEST, types.GET_OPERATIONS_SUCCESS, types.GET_OPERATIONS_ERROR] as RsaaTypes,
  },
});

export const changeDriveStateApi = (driveIds: string[], state: State) => ({
  [RSAA]: {
    endpoint: 'logbook/state?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify({
      drive: driveIds,
      state,
    }),
    types: [
      types.CHANGE_DRIVE_STATE_REQUEST,
      types.CHANGE_DRIVE_STATE_SUCCESS,
      types.CHANGE_DRIVE_STATE_SUCCESS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetOperations = () => ({
  type: types.RESET_OPERATIONS,
});

export const getProductionOperationsApi = () => ({
  [RSAA]: {
    endpoint: 'operations/production?',
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_PRODUCTION_OPERATIONS_REQUEST,
      types.GET_PRODUCTION_OPERATIONS_SUCCESS,
      types.GET_PRODUCTION_OPERATIONS_ERROR] as RsaaTypes,
  },
});

export const resetProductionOperations = () => ({
  type: types.RESET_PRODUCTION_OPERATIONS,
});

export const getDriversApi = (params: GetDriversApiParams) => ({
  [RSAA]: {
    endpoint: `drivers?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_DRIVERS_REQUEST, types.GET_DRIVERS_SUCCESS, types.GET_DRIVERS_ERROR] as RsaaTypes,
  },
});

export const resetDrivers = () => ({
  type: types.RESET_DRIVERS,
});

export const getMachinesApi = (params: GetMachinesApiParams) => ({
  [RSAA]: {
    endpoint: `machines?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_MACHINES_REQUEST, types.GET_MACHINES_SUCCESS, types.GET_MACHINES_ERROR] as RsaaTypes,
  },
});

export const resetMachines = () => ({
  type: types.RESET_MACHINES,
});

export const getEquipmentsApi = (params: GetEquipmentsApiParams) => ({
  [RSAA]: {
    endpoint: `equipments?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_EQUIPMENTS_REQUEST, types.GET_EQUIPMENTS_SUCCESS, types.GET_EQUIPMENTS_ERROR] as RsaaTypes,
  },
});

export const resetEquipments = () => ({
  type: types.RESET_EQUIPMENTS,
});
