import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './landuse.constants';

export const getLanduse = params => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    // 'sort-dir': params.order,
    // 'sort-col': params.orderBy,
    search_str: params.textFilter || undefined,
  };

  return {
    [RSAA]: {
      endpoint: `landUse?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_LANDUSE, types.GET_LANDUSE_SUCCESS, types.GET_LANDUSE_ERROR],
    },
  };
};

export const resetLanduse = () => ({
  type: types.RESET_LANDUSE,
});
