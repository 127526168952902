import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

import CfChartTooltipLabelWrapper from '../../../../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipNoData from '../../../../../../shared/components/charts/CfChartTooltipNoData/CfChartTooltipNoData';
import CfChartTooltipWrapper from '../../../../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import CfFormattedNumber from '../../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfStaticMap from '../../../../../../shared/components/specific/CfStaticMap/CfStaticMap';
import BioMonitoringImageErrorWrapper from '../BioMonitoringImageErrorWrapper/BioMonitoringImageErrorWrapper';

const style = theme => ({
  customTooltipLabelWrapper: {
    padding: '2px 3px',
  },
  images: {
    display: 'flex',
    flexDirection: 'column',
  },
  zonesMap: {
    width: 120,
    height: 120,
    marginTop: 1,
    marginBottom: 1,
    backgroundColor: theme.palette.grey[100],
  },
  map: {
    borderRadius: 0,
    '& .ol-viewport': {
      borderRadius: 0,
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  tooltipValue: {
    padding: 5,
    fontSize: 14,
    fontWeight: 500,
  },
});

export const BioMonitoringTooltip = props => {
  const { active, classes, payload, tooltipValueColor } = props;
  if (active && payload?.[0]?.payload) {
    const item = payload[0].payload;
    return (
      <CfChartTooltipWrapper>
        <CfChartTooltipLabelWrapper>
          <div className={classes.label}>
            <FormattedDate day="numeric" month="numeric" value={item.dateFrom} />
            {' \u2013 '}
            <FormattedDate day="numeric" month="numeric" value={item.dateTo} />
          </div>
        </CfChartTooltipLabelWrapper>
        <CfChartTooltipNoData classes={{ tooltipValueWrapper: classes.customTooltipLabelWrapper }} item={payload[0]}>
          <div className={classes.tooltipValue} style={{ color: tooltipValueColor }}>
            <CfFormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={item.value} />
          </div>
          <div className={classes.images}>
            <div className={classes.zonesMap}>
              <BioMonitoringImageErrorWrapper item={item}>
                <CfStaticMap
                  interactive={false}
                  mapId={'monitoring-tooltip-index-map'}
                  classes={{
                    map: classes.map,
                  }}
                  image={{
                    url: item.imgPath,
                    extent: [item.imgExtent?.minX, item.imgExtent?.minY, item.imgExtent?.maxX, item.imgExtent?.maxY],
                  }}
                />
              </BioMonitoringImageErrorWrapper>
            </div>
            <div className={classes.zonesMap}>
              <BioMonitoringImageErrorWrapper isZonesImage={true} item={item}>
                <CfStaticMap
                  geometries={item.zones}
                  interactive={false}
                  mapId={'monitoring-tooltip-zones-map'}
                  classes={{
                    map: classes.map,
                  }}
                />
              </BioMonitoringImageErrorWrapper>
            </div>
          </div>
        </CfChartTooltipNoData>
      </CfChartTooltipWrapper>
    );
  }
  return null;
};

BioMonitoringTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  tooltipValueColor: PropTypes.string.isRequired,
};

export default withStyles(style)(BioMonitoringTooltip);
