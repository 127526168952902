import angular from 'angular';

import IrrigationController from './irrigation.controller';

const irrigationComponent = {
  bindings: {
    farmId: '<',
    farm: '<',
  },
  controller: IrrigationController,
};

export default angular
  .module('app.irrigation', [])
  .config(config)
  .component('irrigationComponent', irrigationComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('farm.active.irrigation', {
      url: '/irrigation',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.devices', {
      url: '/devices',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.areas', {
      url: '/areas',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.device', {
      url: '/device',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.device.detail', {
      url: '/:deviceId',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.device.detail.activate', {
      url: '/activate',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.area', {
      url: '/area',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.area.create', {
      url: '/create',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.area.plan', {
      url: '/:areaId/plan',
      views: {
        farm: {
          component: 'irrigationComponent',
        },
      },
    })
    .state('farm.active.irrigation.area.devices', {
      url: '/:areaId/devices',
      farm: {
        component: 'irrigationComponent',
      },
    })
    .state('farm.active.irrigation.area.add', {
      url: '/:areaId/add',
      farm: {
        component: 'irrigationComponent',
      },
    });
}
