import React, { FC, useMemo, useContext } from 'react';

import { MenuItem } from '@mui/material';
import { Field, Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTelematicsAdminSelectedFarms } from '../selectors/telematicsAdmin.selectors';

import { fetchAccountFarms } from '../selectors/telematicsAdmin.actions';

import { UpdateFarmsPayload, updateTelematicsAccountFarmsApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';
import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../../types';

import useTelematicsAdminStyles from './styles/telematicsAdmin.styles';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AccountTo } from '../../../../shared/api/telematics/telematics.types';

interface ActivationFormValues {
    externalId: string;
    farmId: string;
    name: string;
}

const validate = (values: ActivationFormValues) => {
  const errors: Record<string, string | React.ReactNode> = {};
  if (!values?.externalId) {
    errors.externalId = <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />;
  }
  return errors;
};

interface Props {
  farm: AccountTo;
  fetchAccountFarms: () => void,
  handleClose: () => void;
  opened: boolean;
  updateFarms: (payload: UpdateFarmsPayload) => void;
}

const EditDialog: FC<Props> = ({
  farm,
  fetchAccountFarms,
  handleClose,
  opened,
  updateFarms,
}) => {
  const classes = useTelematicsAdminStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(() => ({
    farmId: farm.farmId,
    name: farm.name,
    externalId: farm.externalId,
  }), [farm]);

  const handleSubmit = ({ externalId, farmId }: ActivationFormValues) => {
    const payload = [{
      farmId,
      externalId,
      // hack until EP is improved
      enabled: farm.enabled,
    }];

    (updateFarms as AsyncFn<UpdateFarmsPayload>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.dialog.error" />, isError: true });
        return;
      }
      fetchAccountFarms();
      showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.edit.dialog.success" values={{ count: 1 }} />, isSuccess: true });
    });
    handleClose();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
     >
      {({ resetForm, submitForm }) => {
        const { farmId, name } = farm;
        const closeForm = () => {
          resetForm();
          handleClose();
        };
        return (
          <CfDialog
            acceptText={<FormattedMessage id="TelematicsAdmin.edit.dialog.acceptBtn" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onCancel={closeForm}
            opened={opened}
            title={<FormattedMessage id="TelematicsAdmin.edit.dialog.title" />}
        >
            <div className={classes.formContainer}>
              <Form>
                <div className={classes.formRow} key={farmId}>
                  <div className={classes.farmInfo}>
                    <p className={classes.farmName}>{name}</p>
                    <p className={classes.farmId}>{farmId}</p>
                  </div>
                  <Field
                    component={CfFormikTextField}
                    customClasses={{ root: classes.companyId }}
                    fullWidth={false}
                    label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.companyId" />}
                    name="externalId"
                      />
                  <Field
                    component={CfFormikTextField}
                    customClasses={{ root: classes.classifier }}
                    defaultValue="winfas"
                    label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.classifier" />}
                    name="todo-classifier"
                    select
                      >
                    <MenuItem classes={{ root: classes.classifierMenuItem }} value="winfas">
                      <span>Winfas</span>
                    </MenuItem>
                  </Field>
                </div>
              </Form>
            </div>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selectedFarms: getTelematicsAdminSelectedFarms(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  updateFarms: updateTelematicsAccountFarmsApi,
  fetchAccountFarms,
},
dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
