import React from 'react';

import ReactDOM from 'react-dom';

import LoginWrapper from './containers/LoginWrapper/LoginWrapper';

LoginController.$inject = ['$element', '$state', '$scope', '$rootScope', '$stateParams', 'Localization', 'Auth'];

export default function LoginController($element, $state, $scope, $rootScope, $stateParams, Localization, Auth) {
  const props = {
    isApiError: $stateParams.isApiError,
    ngLogin,
    ngRedirectToSignup,
    ngRedirectToReset,
    ngChangeLanguage,
    langId: null,
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    Auth.clearAllUserData();
    renderWithLang();
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      render();
    });
  }

  function ngChangeLanguage(langId) {
    Localization.setCurrLangId(langId);
  }

  function ngLogin(credentials) {
    return Auth.login(credentials).then(() => {
      $state.go('farm');
    });
  }

  function ngRedirectToSignup() {
    $state.go('signup');
  }

  function ngRedirectToReset() {
    $state.go('reset');
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<LoginWrapper {...props} />, $element[0]);
  }
}
