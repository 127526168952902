import { refreshDefaultStyles } from '../style/style.actions';

import * as types from './crops.constants';

import { getParcelsStatistics } from '../../../../shared/api/core/parcels/parcels.api';

export const storeInitialCrops = (crops, cropsOrder) => ({
  type: types.STORE_INITIAL_CROPS,
  crops,
  cropsOrder,
});

export const fetchCrops = () => dispatch =>
  dispatch(getParcelsStatistics()).then(response => {
    if (response && response.payload && response.payload.cropStatistics) {
      const cropsStats = response.payload.cropStatistics;
      const crops = {};
      const cropsOrder = cropsStats.map(cropStats => {
        const crop = {};
        crop.id = cropStats.crop.id;
        crop.name = cropStats.crop.name;
        crop.visible = true;
        crop.color = cropStats.crop.color;
        crop.legislativeCode = cropStats.crop.legislativeCode;
        crops[crop.id] = crop;
        return crop.id;
      });
      return dispatch(storeInitialCrops(crops, cropsOrder));
    }
  });

export const setCropVisibility = (crop, visible) => dispatch => {
  dispatch({
    type: types.SET_CROP_VISIBILITY,
    cropId: crop.id,
    visible,
  });
  dispatch(refreshDefaultStyles());
};

export const setAllCropsVisibility = visible => dispatch => {
  dispatch({
    type: types.SET_ALL_CROPS_VISIBILITY,
    visible,
  });
  dispatch(refreshDefaultStyles());
};
