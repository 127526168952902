import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  icon: {
    color: '#78D3F0',
  },
  tooltipTitle: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
}));

const AreaButtonsHeader = () => {
  const classes = useStyles();
  return (
    <Tooltip title={(
      <>
        <div className={classes.tooltipTitle}>
          <FormattedMessage id="IrrigationAreaList.area" />
        </div>
        <div>
          <FormattedMessage id="IrrigationAreaList.areaDescription" />
        </div>
      </>
    )}
    >
      <InfoIcon className={classes.icon} />
    </Tooltip>
  );
};

export default AreaButtonsHeader;
