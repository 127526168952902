import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './users.constants';

export const getUsers = farmId => ({
  [RSAA]: {
    endpoint: `users?farmId=${farmId}`,
    method: methods.GET,
    module: modules.GATEWAY,
    types: [types.GET_USERS, types.GET_USERS_SUCCESS, types.GET_USERS_ERROR],
  },
});

export const resetUsers = () => ({
  type: types.RESET_USERS,
});
