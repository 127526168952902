import React from 'react';

import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';

import { NAMESPACE as namespace } from '../../../shared/reducer/parcelDetail.reducer';

import CfTableBodyEmpty from '../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableCell from '../../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../../../shared/containers/CfTableHead/CfTableHead';
import Localization from '../../../../../../shared/services/Localization.service';
import ParcelDetailSection from '../ParcelDetailSection/ParcelDetailSection';

const styles = {
  firstColumn: {
    fontWeight: 500,
    paddingLeft: 15,
  },
};

const getColDesc = (label, style) => ({
  align: 'inherit',
  sortable: false,
  label,
  style,
});

const columns = {
  crop: getColDesc(
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="common.crop" />
    </span>,
  ),
  variety: getColDesc(<FormattedMessage id="common.variety" />),
  period: getColDesc(<FormattedMessage id="ParcelDetailOverview.crops.period" />),
  application: getColDesc(<FormattedMessage id="ParcelDetailOverview.crops.applied" />),
  yield: getColDesc(<FormattedMessage id="common.yield" values={{ unit: '(t/ha)' }} />),
};

function ParcelDetailCropTable(props) {
  const { classes, intl, langId, seedingInfo } = props;
  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        count={seedingInfo.length}
        langId={langId}
        namespace={namespace}
        selectable={false}
        setTableSelected={() => {}}
        setTableSorting={() => {}}
      />
      {seedingInfo.length ? (
        <TableBody>
          {seedingInfo.map(item => {
            const seedApplication = item.seedApplication;
            const fertilization = item.fertilization;
            return (
              <TableRow key={seedApplication.id}>
                <CfTableCell name={'name'}>
                  <span className={classes.firstColumn}>{seedApplication.seed.name}</span>
                </CfTableCell>
                <CfTableCell name={'variety'}>
                  {seedApplication.seed.variety ? seedApplication.seed.variety.name : '-'}
                </CfTableCell>
                <CfTableCell name={'date'}>
                  {seedApplication.dateStart ? <FormattedDate value={seedApplication.dateStart} /> : ''}
                  {' \u2013 '}
                  {seedApplication.dateEnd ? <FormattedDate value={seedApplication.dateEnd} /> : ''}
                </CfTableCell>
                <CfTableCell name={'applications'}>
                  {fertilization && !item.dateEnd ? (
                    <Grid container>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          label={'N'}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.accountableN, intl.locale)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.p2o5, intl.locale)}
                          label={
                            <span>
                              P<sub>2</sub>O<sub>5</sub>
                            </span>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.k2o, intl.locale)}
                          label={
                            <span>
                              K<sub>2</sub>O
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <span />
                  )}
                </CfTableCell>
                <CfTableCell name={'yield'}>
                  {item.revenueHa ? Localization.num2str(item.revenueHa, intl.locale) : '-'}
                </CfTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
    </CfTableWrapper>
  );
}

ParcelDetailCropTable.propTypes = {
  langId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  seedingInfo: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(ParcelDetailCropTable));
