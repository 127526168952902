import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray } from 'redux-form';

import {
  getTotalSubtractableArea,
  getTotalSubtractableAreaIsFetching,
} from '../../../../../shared/api/core/subtractableAreas/totalSubtractableAreas.selectors';
import {
  getParcelsArea,
  getVAIsError,
  getVAIsLoading,
  getCreateVAIsLoading,
  getDriftClass,
} from '../../../shared/selectors/actions.selectors';

import {
  updateVariableExpense,
  calculateParcelRestrictedArea,
  exportVariableApplications,
} from '../../../shared/actions/actions.actions';

import {
  getParcelExpectedFertilization,
  resetParcelExpectedFertilization,
} from '../../../../../shared/api/core/fertilzation/fertilization.api';
import { resetTotalSubtractableArea } from '../../../../../shared/api/core/subtractableAreas/subtractableAreas.api';
import {
  createVariableExpense,
  resetVariableExpense,
  resetVariableActionExpenses,
} from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import unitsMock from '../../../../../shared/mocks/units.mock.json';
import ActionDate from '../../../shared/components/ActionDate/ActionDate';
import ActionNote from '../../../shared/components/ActionNote/ActionNote';
import VaMapSourceDialog from '../../../shared/components/VaMapSourceDialog/VaMapSourceDialog';
import ActionParcelsControl from '../../../shared/containers/ActionParcelsControl/ActionParcelsControl';
import actionForm from '../../../shared/hocs/actionForm';
import variableActionForm from '../../../shared/hocs/variableActionForm';
import {
  parcelsCountChanged,
  parcelsAreaChanged,
  expenseCountChanged,
  variableFertilizersDosageChanged,
} from '../../../shared/misc/action.helpers';
import ActionToMapper from '../../../shared/services/ActionToMapper.service';
import {
  getInitialValues,
  validate,
  warn,
  lsTransformFnc,
  STRONG_ZONES_SEEDING,
} from '../../helpers/vrs.helpers';
import SeedSelector from '../SeedSelector/SeedSelector';
import VrsExpenseControl from '../VrsExpenseControl/VrsExpenseControl';

const FORM_NAME = 'vrs';

const q = unitsMock.units.find(u => u.id === 'q');

/*
 * KEEP THE COMMENTS UP TO DATE!
 */
class Vrs extends Component {
  componentDidUpdate(prevProps) {
    const {
      existingAction,
      expenses: oldExpenses,
      isEditing,
      parcels: oldParcels,
      parcelsArea: oldParcelsArea,
      satellite: oldSatellite,
    } = prevProps;

    const {
      expenses: newExpenses,
      formName,
      formValues: newFormValues,
      geometry: newGeometry,
      isRestrictedAreaFeching,
      parcels: newParcels,
      parcelsArea: newParcelsArea,
      satellite: newSatellite,
      totalSubtractableArea: newTotalSubtractableArea,
    } = this.props;

    // Comparison booleans to detect the change
    const parcelsCountDiff = parcelsCountChanged(oldParcels, newParcels);
    const expenseCountDiff = expenseCountChanged(oldExpenses, newExpenses);

    /*
     * Set zonesCount & satellite to nulls, expenses to [] when:
     * 1) parcels count changed
     * 2) and there are no parcels
     */
    if (parcelsCountDiff && !newParcels.length) {
      this.props.change('satellite', null);
      this.props.change('expenses', []);
      this.props.resetTotalSubtractableArea();
    }

    /*
     * Recalculates expected fertilization on all selected parcels when:
     * 1) total dose on fertilizers changed
     * 2) parcels area changed - it includes subtractions
     * 3) fertilization unit changed
     * 4) fertilizers count changed
     * 5) and target crop is set or resolved
     * 6) and there is at least one fertilizer
     */
    const fertDosageDiff = variableFertilizersDosageChanged(oldExpenses, newExpenses);
    const parcelsAreaDiff = parcelsAreaChanged(oldParcelsArea, newParcelsArea);
    // if (
    //   (fertDosageDiff || parcelsAreaDiff || expenseCountDiff) &&
    //   (resolvedTargetCrop || newTargetCrop) &&
    //   newExpenses.length > 0
    // ) {
    //   // all expenses have fetched their variable fertilizations
    //   if (newExpenses.filter(expense => expense.variableExpense).length === newExpenses.length) {
    //     this.updateExpectedNitrogen({ ...newFormValues, targetCrop: { ...(resolvedTargetCrop || newTargetCrop) } });
    //   }
    // }

    if (fertDosageDiff && !newExpenses.length) {
      this.props.resetParcelExpectedFertilization();
    }

    /*
     * Fetch history potential data when:
     * 1) new parcel is added; only one parcel can be added
     */
    if (parcelsCountDiff && newParcels.length) {
      const parcel = head(newParcels);
      if (parcel.subtractableAreas) {
        this.props.calculateParcelRestrictedArea(parcel.id, parcel.subtractableAreas, [parcel.id]);
      }
    }

    if (oldSatellite !== newSatellite && !newExpenses.length) {
      this.props.setCurrMapZones(newSatellite?.zones || null);
    }

    /*
     * Fetch  expense's variableExpense if its not present yet in the expense object;
     * Store it in expense. Happens for all expenses with no variableExpense; This occurs
     * when new fertilizer is added or when cached and unsaved action is loaded; variableExpense is
     * later used in VariableExpenseCard; Triggered when:
     * 1) parcel, satellite and parcel geometry are available
     * 2) there are some expenses without variableExpense property
     */
    if (newGeometry && newParcels.length && newSatellite && !isRestrictedAreaFeching && isEditing) {
      const miss = newExpenses.filter(expense => !expense.variableExpense);
      if (miss.length) {
        this.updateVariableExpenses(
          miss,
          newSatellite,
          newExpenses,
          head(newParcels),
          newTotalSubtractableArea?.geometry?.geometry || newGeometry,
          newTotalSubtractableArea?.subtractionGeometry?.geometry,
          formName,
        );
      }
    }

    /*
     * Update expense's variableExpense if parcel subtractable zones changed;
     * Store it in expense as a variableExpense prop. Triggered when:
     * 1) parcel, satellite and parcel geometry are available
     * 2) some parcel has changed its restrictedArea
     */
    if (parcelsAreaDiff && newGeometry && newParcels.length && newSatellite && newExpenses.length) {
      this.updateVariableExpenses(
        newExpenses,
        newSatellite,
        newExpenses,
        head(newParcels),
        newTotalSubtractableArea?.geometry?.geometry || newGeometry,
        newTotalSubtractableArea?.subtractionGeometry?.geometry,
        formName,
      );
    }

    if (expenseCountDiff) {
      if (newExpenses.length) {
        const lastExpense = newExpenses[newExpenses.length - 1];
        this.props.setCurrMapZones(lastExpense?.variableExpense?.applicationZones || newSatellite?.zones || null);

        this.props.change('fertilizationType', { id: lastExpense.strategy });
      }
      if (newExpenses.length === 0) {
        this.props.setCurrMapZones(newSatellite?.zones || null);
        this.props.change('fertilizationType', { id: null });
      }
    }

    /*
     * Updates initial state of form.
     * Existing variable action should be considered to be loaded only when
     * satellite zones and all variable expenses are loaded as well.
     * Then it can be saved as real initial state.
     */
    if (existingAction && !isEditing) {
      if (
        newSatellite &&
        oldExpenses.some(exp => !exp.variableExpense) &&
        newExpenses.every(exp => !!exp.variableExpense)
      ) {
        this.props.initialize(newFormValues);
      }

      if (!oldSatellite && newSatellite && newExpenses.every(exp => !!exp.variableExpense)) {
        this.props.initialize(newFormValues);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetVariableActionExpenses();
    this.props.resetTotalSubtractableArea();
  }

  updateExpectedNitrogen(values) {
    this.props.getParcelExpectedFertilization(ActionToMapper.createNewVrsActionTo(values));
  }

  updateVariableExpenses(
    missingVariableExpenses,
    satellite,
    expenses,
    parcel,
    geometry,
    subtractionGeometry,
    formName,
  ) {
    const promises = [];
    missingVariableExpenses.forEach(expense => {
      const {
        crop: { legislativeCode },
        dateFrom,
        dateTo,
        type: indexType,
      } = satellite;
      const {
        doseUnit: { id: unitId },
        material: { id },
        maxDose,
        minDose,
        strategy,
        variableExpense,
      } = expense;

      const index = expenses.indexOf(expense);
      if (index === -1) {
        throw new Error('Unable to find expense index related to the VA');
      }

      const promise = this.props.updateVariableExpense({ isFetching: true }, index, formName);
      this.props
        .createVariableExpense({
          parcelId: parcel.id,
          geometry,
          ...(subtractionGeometry ? { subtractionGeometry } : {}),
          materialId: id,
          minDoseHa: minDose,
          maxDoseHa: maxDose,
          cropLegCode: legislativeCode,
          unit: unitId,
          type: strategy,
          indexType,
          dateFrom,
          dateTo,
          ...(variableExpense ? { applicationZones: variableExpense.applicationZones } : {}),
        })
        .then(res => {
          let payload = res.payload;
          if (res.error) {
            payload = { isError: true };
          }
          this.props.updateVariableExpense(payload, index, formName);
          this.props.setCurrMapZones(payload?.applicationZones || null);
        });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      this.props.resetVariableExpense();
    });
  }

  render() {
    const {
      actionDate,
      currMapZones,
      driftClass,
      existingAction,
      expenses,
      formName,
      historySatelliteData,
      initParcelIds,
      isCreateVaLoading,
      isEditing,
      isManagementZonesLoading,
      langId,
      parcels,
      parcelsArea,
      satellite,
      targetCrop,
      variableParcelIds,
    } = this.props;

    return (
      <Fragment>
        <Grid item xs={12}>
          <FieldArray
            actionDate={actionDate}
            component={ActionParcelsControl}
            driftClass={driftClass}
            existingAction={existingAction}
            expenses={expenses}
            filter={variableParcelIds}
            formName={formName}
            initParcelIds={initParcelIds}
            isEditing={isEditing && !isCreateVaLoading}
            maxParcelCount={1}
            name="parcels"
            parcelsArea={parcelsArea}
            parcelsOnly={true}
            placeholder={'VariableFertilization.parcelSelector'}
            subtractableAreasIds={['Boundary', 'Water']}
            targetCrop={targetCrop}
          />
        </Grid>
        <Grid item lg={5} md={6} sm={7} xl={4} xs={12}>
          <ActionDate disabled={!isEditing} />
          <ActionNote disabled={!isEditing} isLast={true} />
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems={'center'} container justifyContent="center">
            <VaMapSourceDialog
              currMapZones={currMapZones}
              disabled={!isEditing || isCreateVaLoading || !historySatelliteData?.length}
              isManagementZonesLoading={isManagementZonesLoading}
              onClick={this.props.setCurrMapZones}
              parcels={parcels}
              satellite={satellite}
              satelliteData={{ HIST: historySatelliteData }}
              onAccept={v => {
                this.props.change('satellite', v);
              }}
              onRemove={() => {
                this.props.change('satellite', null);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            component={VrsExpenseControl}
            currMapZones={currMapZones}
            driftClass={driftClass}
            expenses={expenses}
            formName={formName}
            isEditing={isEditing}
            langId={langId}
            maxExpenseCount={1}
            name="expenses"
            onMapClick={this.props.setCurrMapZones}
            parcels={parcels}
            satellite={satellite}
            targetCrop={targetCrop}
            SelectorProps={{
              component: SeedSelector,
              placeholder: 'Vrs.seedSelector',
              disabled: Boolean(!satellite),
              seedAdditionalProps: {
                minDose: null,
                maxDose: null,
                doseUnit: q,
                strategy: STRONG_ZONES_SEEDING,
                zones:
                  satellite?.zones.map(z => ({
                    ...z,
                    dose: null,
                  })) || [],
              },
            }}
          />
        </Grid>
      </Fragment>
    );
  }
}

Vrs.propTypes = {
  formValues: PropTypes.object.isRequired,
  targetCrop: PropTypes.object,
  existingAction: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  getParcelExpectedFertilization: PropTypes.func.isRequired,
  resetParcelExpectedFertilization: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  historySatelliteData: PropTypes.array,
  driftClass: PropTypes.string,
  parcels: PropTypes.array.isRequired,
  expenses: PropTypes.array.isRequired,
  satellite: PropTypes.object,
  initParcelIds: PropTypes.array,
  actionDate: PropTypes.object.isRequired,
  // farm: PropTypes.object.isRequired,
  variableParcelIds: PropTypes.array.isRequired,
  geometry: PropTypes.object,
  createVariableExpense: PropTypes.func.isRequired,
  resetVariableExpense: PropTypes.func.isRequired,
  updateVariableExpense: PropTypes.func.isRequired,
  resetVariableActionExpenses: PropTypes.func.isRequired,
  resetTotalSubtractableArea: PropTypes.func.isRequired,
  calculateParcelRestrictedArea: PropTypes.func.isRequired,
  totalSubtractableArea: PropTypes.object,
  isCreateVaLoading: PropTypes.bool.isRequired,
  isRestrictedAreaFeching: PropTypes.bool.isRequired,
  langId: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  setCurrMapZones: PropTypes.func.isRequired,
  isManagementZonesLoading: PropTypes.bool.isRequired,
  currMapZones: PropTypes.array,
};

Vrs.defaultProps = {
  targetCrop: null,
  existingAction: null,
  initParcelIds: [],
  initZoneIds: [],
  satellite: null,
  driftClass: '',
  geometry: null,
  totalSubtractableArea: null,
  historySatelliteData: null,
  currMapZones: null,
};

const mapStateToProps = state => ({
  driftClass: getDriftClass(FORM_NAME, state),
  parcelsArea: getParcelsArea(FORM_NAME, state),
  totalSubtractableArea: getTotalSubtractableArea(state),
  isRestrictedAreaFeching: getTotalSubtractableAreaIsFetching(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getParcelExpectedFertilization,
      resetParcelExpectedFertilization,
      createVariableExpense,
      resetVariableExpense,
      updateVariableExpense,
      resetVariableActionExpenses,
      resetTotalSubtractableArea,
      calculateParcelRestrictedArea,
      exportVariableApplications,
    },
    dispatch,
  );

const onUpdateCallback = (values, dispatch, props) => {
  const { existingAction } = props;
  props
    .saveVariableExpense({
      actionId: existingAction.id,
      variableExpenseDTO: ActionToMapper.createNewVrfTo(values),
    })
    .then(result => Promise.resolve(result));
};

const onCreateCallback = (values, dispatch, props, createResult) =>
  props
    .saveVariableExpense({
      actionId: createResult.payload.id,
      variableExpenseDTO: ActionToMapper.createNewVrfTo(values),
    })
    .then(result =>
      Promise.resolve(result),
    );

const onExport = (props, exportType) => {
  const applications = props.expenses
    ?.filter(e => e.variableExpense)
    .map(e => ({ id: e.variableExpense.id, material: e.material.name }));
  const parcelName = props.parcels?.[0]?.localName || '';
  const blockNr = props.parcels?.[0]?.blockNr || '0';
  const parcelNumber = blockNr.replace('/', '_');
  props.exportVariableApplications(applications, exportType, parcelName, parcelNumber);
};

export default compose(
  actionForm({
    formName: FORM_NAME,
    validate,
    warn,
    lsTransformFnc,
    onUpdateCallback,
    onCreateCallback,
    createActionToFnc: ActionToMapper.createNewVrsActionTo,
    destroyOnUnmount: true,
    initialValues: getInitialValues(),
  }),
  variableActionForm({
    onExport,
    getApiError: getVAIsError,
    getIsLoading: getVAIsLoading,
    getCreateVaIsLoading: getCreateVAIsLoading,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(Vrs);
