import React from 'react';

import { createDevTools } from '@redux-devtools/core';
import { DockMonitor } from '@redux-devtools/dock-monitor';
import { InspectorMonitor } from '@redux-devtools/inspector-monitor';

const ReduxDevTools = createDevTools(
  <DockMonitor
    changeMonitorKey="ctrl-m"
    changePositionKey="alt-w"
    defaultIsVisible={false}
    defaultPosition="bottom"
    fluid
    toggleVisibilityKey="ctrl-h"
  >
    <InspectorMonitor />
  </DockMonitor>,
);

export const devtoolsEnhancer = ReduxDevTools.instrument();
export default ReduxDevTools;
