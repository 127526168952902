import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfFormattedNumber from '../../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';

export const ManagementZoneIcon = props => {
  const { color, rx, size, useStroke, ...rest } = props;
  return (
    <svg
      fill="none"
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect fill={`#${color}`} height={size} rx={rx} stroke="#E0E0E0" strokeWidth={useStroke ? 1 : 0} width={size} />
    </svg>
  );
};

ManagementZoneIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  rx: PropTypes.number,
  useStroke: PropTypes.bool,
};

ManagementZoneIcon.defaultProps = {
  size: 10,
  rx: 2,
  useStroke: false,
};

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '15%',
    padding: '0 10px',
  },
  zone: {
    margin: '0 3px 0 10px',
    fontSize: 12,
  },
  icon: {
    width: '10px',
    height: '10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ManagementZonesLegend = props => {
  const { classes, zones } = props;
  return (
    <div className={classes.wrapper}>
      {zones.map(zone => (
        <span className={classes.item} data-test="management-zones-legend" key={zone.zoneNumber}>
          <span className={classes.icon}>
            <ManagementZoneIcon color={zone.color} display={'block'} />
          </span>
          <span className={classes.zone} style={{ color: `#${zone.color}` }}>
            <div>
              <div style={{ float: 'left', marginRight: 5 }}>
                {`${props.intl.formatMessage({ id: 'ManagementZones.CropQualityGraph.zone' })} ${zone.zoneNumber}`}{' '}
              </div>
              <div style={{ float: 'left' }}>
                {'('}
                <CfFormattedNumber maximumFractionDigits={1} minimumFractionDigits={1} value={zone.areaHa} /> ha
                {')'}
              </div>
            </div>
          </span>
        </span>
      ))}
    </div>
  );
};

ManagementZonesLegend.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
};

export default injectIntl(withStyles(styles)(ManagementZonesLegend));
