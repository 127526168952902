import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import actionListUI from '../evidence/actions/list/reducer/actionList.reducer';
import actionTypesApi from '../evidence/api/actionTypes/actionTypes.reducer';
import productsApi from '../evidence/api/products/products.reducer';
import actionsApi from '../shared/api/core/actions/actions.reducer';
import chemistryApi from '../shared/api/core/chemistry/chemistry.reducer';
import chemistryApplicationsApi from '../shared/api/core/chemistry/chemistryApplications.reducer';
import cropsApi from '../shared/api/core/crops/crops.reducer';
import fertilizersApi from '../shared/api/core/fertilizers/fertilizers.reducer';
import fertilizerStatsApi from '../shared/api/core/fertilizerStats/fertilizerStats.reducer';
import fertilizationApi from '../shared/api/core/fertilzation/fertilization.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import parcelsApi from '../shared/api/core/parcels/parcels.reducer';
import seedsApi from '../shared/api/core/seeds/seeds.reducer';
import parcelSubtractableAreasApi from '../shared/api/core/subtractableAreas/parcelSubtractableAreas.reducer';
import subtractableAreaApi from '../shared/api/core/subtractableAreas/subtractableArea.reducer';
import totalSubtractableAreasApi from '../shared/api/core/subtractableAreas/totalSubtractableAreas.reducer';
import unitsApi from '../shared/api/core/units/units.reducer';
import zonesApi from '../shared/api/core/zones/zones.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import managementApi from '../shared/api/sentinel/management/management.reducer';
import variableApplicationApi from '../shared/api/sentinel/variableApplication/variableApplication.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      eph: combineReducers({
        additions: parcelsApi('additions'),
        suggestions: parcelsApi('suggestions'),
      }),
      vrf: combineReducers({
        additions: parcelsApi('additions'),
        suggestions: parcelsApi('suggestions'),
      }),
      vrs: combineReducers({
        additions: parcelsApi('additions'),
        suggestions: parcelsApi('suggestions'),
      }),
      other: combineReducers({
        additions: parcelsApi('additions'),
        suggestions: parcelsApi('suggestions'),
      }),
      actionList: actionListUI(),
      editor: editorUI(),
      map: mapUI(),
      layers: layersUI(),
    }),
    api: combineReducers({
      crops: cropsApi(),
      seeds: seedsApi(),
      actions: actionsApi(),
      actionTypes: actionTypesApi(),
      products: productsApi(),
      zones: zonesApi(),
      fertilizers: fertilizersApi(),
      fertilization: fertilizationApi(),
      fertilizerStats: fertilizerStatsApi(),
      chemistry: chemistryApi(),
      chemistryApplications: chemistryApplicationsApi(),
      subtractableArea: subtractableAreaApi(),
      parcelSubtractableAreas: parcelSubtractableAreasApi(),
      totalSubtractableAreas: totalSubtractableAreasApi(),
      management: managementApi(),
      geometry: geometryApi(),
      layers: layersApi(),
      variableApplication: variableApplicationApi(),
      units: unitsApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
