import React, { Component, Fragment } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchLocations } from '../../actions/notificationList.actions';

import NodeDeviceType from '../../../shared/components/common/NodeDeviceType/NodeDeviceType';
import SelectionItem from '../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import ListSelectorError from '../../../shared/components/form/ListSelectorError/ListSelectorError';
import SensorGroup from '../../../shared/components/specific/SensorGroup/SensorGroup';
import SensorsLastUpdate from '../../../shared/components/specific/SensorsLastUpdate/SensorsLastUpdate';

const styles = theme => ({
  sensorLabel: {
    color: theme.palette.grey[500],
  },
  selectionItemColumn: {
    fontWeight: 400,
  },
  sensorFeatures: {
    display: 'flex',
    alignItems: 'center',
  },
  locationIdSuggestion: {
    color: theme.palette.grey[500],
    marginRight: 8,
  },
});

export class NotificationSensorsControl extends Component {
  componentDidMount() {
    const { isEditing } = this.props;
    if (isEditing) {
      this.props.fetchLocations();
    }
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props;
    if (!prevProps.isEditing && isEditing) {
      this.props.fetchLocations();
    }
  }

  render() {
    const { classes, error, handleRemove, isEditing, locationsSuggestions, nodeLocation, onChange } = this.props;
    return (
      <div>
        {nodeLocation ? (
          <div className={classes.sensorLabel}>
            <FormattedMessage id={'common.sensor'} />:
          </div>
        ) : (
          <Fragment>
            <Autocomplete
              blurOnSelect={true}
              disabled={!isEditing}
              getOptionLabel={option => `${option.node.externalId} ${option.name}`}
              id="sensor-selector"
              multiple
              name="sensorToAdd"
              options={locationsSuggestions}
              value={[]}
              onChange={(event, values) => {
                if (values?.length > 0) {
                  const newValue = values[0];
                  onChange(newValue);
                }
              }}
              renderInput={params => (
                <TextField {...params} label={<FormattedMessage id="NotificationSensorsControl.placeholder" />} />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <span className={classes.locationIdSuggestion}>{option.node.externalId}</span>
                    <span>{option.name}</span>
                  </div>
                </li>
              )}
            />
            {error && <ListSelectorError error={error} />}
          </Fragment>
        )}
        {nodeLocation && (
          <div>
            <SelectionItem editing={isEditing} onRemoveItem={handleRemove}>
              <Grid container spacing={0}>
                <Grid item sm={3} xs={12}>
                  <SelectionItemHeading
                    reversed={true}
                    subheading={nodeLocation.node ? nodeLocation.node.externalId : nodeLocation.externalId}
                  >
                    <div>{nodeLocation.name}</div>
                  </SelectionItemHeading>
                </Grid>
                {nodeLocation.node && (
                  <Fragment>
                    <Grid item sm={3} xs={12}>
                      <SelectionItemColumn
                        classes={{ column: classes.selectionItemColumn }}
                        label={<FormattedMessage id={'common.type'} />}
                      >
                        <NodeDeviceType displayIcon={false} node={nodeLocation.node} />
                      </SelectionItemColumn>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <SelectionItemColumn
                        classes={{ column: classes.selectionItemColumn, content: classes.sensorFeatures }}
                        label={<FormattedMessage id={'common.measuredFeatures'} />}
                      >
                        <SensorGroup displayValues={false} isDetail={false} node={nodeLocation.node} />
                      </SelectionItemColumn>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <SelectionItemColumn
                        classes={{ column: classes.selectionItemColumn }}
                        label={<FormattedMessage id={'common.lastUpdate'} />}
                      >
                        <div>
                          <SensorsLastUpdate displayIcon={false} lastUpdate={nodeLocation.lastUpdate} />
                        </div>
                      </SelectionItemColumn>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </SelectionItem>
          </div>
        )}
      </div>
    );
  }
}

NotificationSensorsControl.propTypes = {
  nodeLocation: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isExisting: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchLocations: PropTypes.func.isRequired,
  locationsSuggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

NotificationSensorsControl.defaultProps = {
  nodeLocation: null,
  error: undefined,
  isEditing: false,
};

const mapStateToProps = state => ({
  locationsSuggestions: state.api.locations.items,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationSensorsControl));
