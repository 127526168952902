import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfReduxFormTextField from '../../../../../shared/components/form/CfReduxFormTextField/CfReduxFormTextField';
import * as validators from '../../../../../shared/misc/validators';

function ProfileForm(props) {
  const { changeField, disabled } = props;
  return (
    <Fragment>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled
          fullWidth
          label={<FormattedMessage id="Profile.username" />}
          name="login"
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled={disabled}
          fullWidth
          label={<FormattedMessage id="Profile.name" />}
          name="firstName"
          validate={[validators.required]}
          onBlur={(event, newValue) => {
            changeField('firstName', newValue.trim());
            event.preventDefault();
          }}
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled={disabled}
          fullWidth
          label={<FormattedMessage id="Profile.surname" />}
          name="lastName"
          validate={[validators.required]}
          onBlur={(event, newValue) => {
            changeField('lastName', newValue.trim());
            event.preventDefault();
          }}
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfReduxFormTextField}
          disabled={disabled}
          fullWidth
          label={<FormattedMessage id="Profile.email" />}
          name="email"
          validate={[validators.required, validators.email]}
          onBlur={(event, newValue) => {
            changeField('email', newValue.trim());
            event.preventDefault();
          }}
        />
      </CfFormControl>
    </Fragment>
  );
}

ProfileForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  changeField: PropTypes.func.isRequired,
};

export default ProfileForm;
