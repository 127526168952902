import { AnyAction } from 'redux';

import * as commonTableTypes from '../../shared/actions/table.constants';
import * as sortOrder from '../../shared/constants/sortOrder.constants';
import * as createAreaTypes from '../actions/createOrEditArea.constants';
import { AREA_FORM_FIELDS } from '../irrigation.constants';

import { REDUCER_HELPERS } from '../services/CreateOrEditArea.services';

export const CREATE_OR_EDIT_AREA_NAMESPACE = 'createOrEditArea';

const initialState = {
  areaName: '',
  order: sortOrder.ASC,
  orderBy: AREA_FORM_FIELDS.NAME,
  selected: [],
  devicesToEdit: [],
  fieldErrors: {},
  isFormTouched: false,
  coordSelector: {
    deviceId: null,
    active: false,
  },
  hoveredDevice: null,
};

export default (reducerNamespace = CREATE_OR_EDIT_AREA_NAMESPACE) => (state = initialState, action: AnyAction) => {
  if (!action.namespace || action.namespace !== reducerNamespace) {
    return state;
  }

  switch (action.type) {
    case commonTableTypes.SET_TABLE_ORDER:
      return { ...state, order: action.order, page: 0 };

    case commonTableTypes.SET_TABLE_ORDER_BY:
      return { ...state, orderBy: action.orderBy, page: 0 };

    case commonTableTypes.SET_TABLE_SELECTED:
      return { ...state, selected: action.selected };

    case createAreaTypes.SET_DEVICES_TO_EDIT:
      return { ...state, devicesToEdit: REDUCER_HELPERS.mapDevices(action.payload) };

    case createAreaTypes.EDIT_DEVICE:
      return {
        ...state,
        devicesToEdit: REDUCER_HELPERS.editDevice(state, action.deviceId, action.field, action.payload),
      };

    case createAreaTypes.SORT_DEVICES:
      return { ...state, devicesToEdit: REDUCER_HELPERS.sortDevices(state.devicesToEdit, action.payload) };

    case createAreaTypes.SET_AREA_NAME:
      return {
        ...state,
        [AREA_FORM_FIELDS.AREA_NAME]: action.payload,
      };

    case createAreaTypes.SET_FIELD_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload,
      };

    case createAreaTypes.SET_FORM_TOUCHED:
      return {
        ...state,
        isFormTouched: action.status,
      };

    case createAreaTypes.ACTIVATE_COORDINATES_SELECTOR:
      return {
        ...state,
        coordSelector: {
          deviceId: action.deviceId,
          active: true,
        },
      };

    case createAreaTypes.SET_HOVERED_DEVICE:
      return {
        ...state,
        hoveredDevice: action.deviceId,
      };

    case createAreaTypes.RESET_COORDINATES_SELECTOR:
      return {
        ...state,
        coordSelector: {
          ...initialState.coordSelector,
        },
      };

    case createAreaTypes.RESET_FORM:
      return { ...initialState };

    default:
      return state;
  }
};
