import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getProducts, getProductsIsFetching } from '../../../../api/products/products.selectors';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';

function ProductSelector({ isEditing, isFetching, products }) {
  if (isFetching) {
    return (
      <LinearProgress color="secondary" />
    );
  }

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing || isFetching}
        label={<FormattedMessage id="action.mowingProduct" />}
        name="productId"
        select
        validate={validators.required}
        validateOnBlur
      >
        {products.map(product => (
          <MenuItem key={product.id} value={product.id}>
            {product.name}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
}

ProductSelector.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  products: getProducts(state),
  isFetching: getProductsIsFetching(state),
});

export default connect(mapStateToProps)(ProductSelector);
