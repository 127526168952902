export default class SubtractableAreasService {
  static getCheckedSubtractableAreas = subtractableAreas =>
    (Array.isArray(subtractableAreas) ? subtractableAreas.filter(sa => sa.checked) : []);

  // do not forget that the currentSubtractableAreas can contain material subtractions from existing action object
  static uniteSubtractableAreas = (currentSubtractableAreas, materialSubtractableAreas) => {
    const materialSas = materialSubtractableAreas.map(area => ({ ...area, material: true, checked: true }));
    const updatedSubtractableAreas = materialSas.slice();
    currentSubtractableAreas.forEach((currentSubtractableArea) => {
      const currentSa = { ...currentSubtractableArea };
      const sameRestrictionTypeArea = materialSas.find(
        materialSa => materialSa.restrictionType === currentSa.restrictionType,
      );
      // if it is an existing action, the currentSubtractableAreas array can contain subtractions from materials,
      // that may or may not be used - if they are used, it would have the checked param set to true
      // If they are not used - do not display them, as we still display them from the materialSubtractableAreas
      if (!(sameRestrictionTypeArea &&
        sameRestrictionTypeArea.restrictionValue === currentSa.restrictionValue &&
        currentSa.material === true)) {
        if (sameRestrictionTypeArea && currentSa.checked === true) {
          if (sameRestrictionTypeArea.restrictionValue >= currentSa.restrictionValue) {
            currentSa.checked = false;
          } else {
            sameRestrictionTypeArea.checked = false;
          }
        }
        updatedSubtractableAreas.push(currentSa);
      }
    });
    return updatedSubtractableAreas;
  };

  static updateAreaInSubtractableArea = (parcelId, subtractableAreas, oldSubtractableArea) => {
    const match = subtractableAreas[parcelId].find(
      newSa =>
        newSa.restrictionType === oldSubtractableArea.restrictionType &&
        newSa.restrictionValue === oldSubtractableArea.restrictionValue,
    );

    return match
      ? {
        ...oldSubtractableArea,
        restrictionType: match.restrictionType,
        restrictionValue: match.restrictionValue,
        restrictionArea: match.area,
        area: match.area,
      }
      : {
        ...oldSubtractableArea,
        restrictionValue: null,
      };
  };
}
