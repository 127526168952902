import React from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionParcelSubtractableAreasSection from '../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection';
import SubtractableAreaWithCheckbox from '../SubtractableAreaWithCheckbox/SubtractableAreaWithCheckbox';

const styles = theme => ({
  noAreas: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    margin: '0px 0px 10px 10px',
  },
});

function AbsoluteParcelSubtractableAreas(props) {
  const { classes, isEditing, maxValue, parcelId, parcelIds, parcelIndex } = props;
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.absolute`}>
      {({ form, push }) => (
        <ActionParcelSubtractableAreasSection
          calculateAndGetSubtractableArea={props.calculateAndGetSubtractableArea}
          isEditing={isEditing}
          maxValue={maxValue}
          parcelId={parcelId}
          parcelIds={parcelIds}
          subtractableAreas={form.values.parcels[parcelIndex].subtractableAreas.absolute}
          subtractableAreaType={'Absolute'}
          handleAdd={(value) => {
            push({ ...value, checked: true });
          }}
        >
          <Grid container data-test="area-subtraction-list">
            {form.values.parcels[parcelIndex].subtractableAreas.absolute.length ? (
              form.values.parcels[parcelIndex].subtractableAreas.absolute.map((sa, index) => (
                <Grid data-test={'absolute-subtraction-item'} item key={`absolute-${sa.internalId}`} md={3} sm={4} xs={12}>
                  <Field
                    component={SubtractableAreaWithCheckbox}
                    isEditing={isEditing}
                    name={`parcels.${parcelIndex}.subtractableAreas.absolute[${index}]`}
                    parcelIndex={parcelIndex}
                  />
                </Grid>
              ))
            ) : (
              <div className={classes.noAreas}>
                <FormattedMessage id="SubtractableAreaDialog.noSubtractableAreas" />
              </div>
            )}
          </Grid>
        </ActionParcelSubtractableAreasSection>
      )}
    </FieldArray>
  );
}

AbsoluteParcelSubtractableAreas.propTypes = {
  isEditing: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  calculateAndGetSubtractableArea: PropTypes.func.isRequired,
};

AbsoluteParcelSubtractableAreas.defaultProps = {
  isEditing: false,
};

export default withStyles(styles)(AbsoluteParcelSubtractableAreas);
