import get from 'lodash/get';

import * as types from './seeds.constants';

export default reducerContext => (
  state = {
    isFetching: false,
    error: {},
    items: [],
  },
  action,
) => {
  const actionContext = get(action, 'meta.context');
  if (actionContext && actionContext !== reducerContext) return state;

  switch (action.type) {
    case types.GET_SEEDS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_SEEDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_SEEDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_SEEDS:
      return {
        ...state,
        isFetching: false,
        items: [],
      };

    default:
      return state;
  }
};
