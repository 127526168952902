import React, { Fragment } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import PageHeading from '../../../../shared/components/common/PageHeading/PageHeading';

const CSV = 'csv';

const styles = theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  heading2: {
    color: theme.palette.grey[500],
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    marginLeft: 10,
  },
});

function SowingPlanCsvExport(props) {
  const {
    classes,
    intl: { formatMessage },
    isFetching,
    onCsvExport,
  } = props;
  const exportLabel = formatMessage({ id: 'common.export' });
  return (
    <Fragment>
      <span className={classes.heading}>
        <span>
          <PageHeading dataTest="report=csv-heading" translationId="Report.sowingPlan" />
        </span>
        <span className={classes.heading2}>
          <PageHeading dataTest="report=csv-heading" translationId="Reports.sowingPlanHeading.part2" />
        </span>
      </span>
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.sowingPlanInfo" />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button color="primary" disabled={isFetching} id="csv" onClick={onCsvExport} variant="contained">
          {`${exportLabel} ${CSV}`}
          {isFetching && <CircularProgress className={classes.progress} size={21} />}
        </Button>
      </div>
    </Fragment>
  );
}

SowingPlanCsvExport.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onCsvExport: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

SowingPlanCsvExport.defaultProps = {
  classes: {},
};

export default injectIntl(withStyles(styles)(SowingPlanCsvExport));
