import * as aggregations from '../../shared/constants/aggregations.constants';

import FeatureConfigService, { FEATURE_TYPES } from '../../sensors/services/FeatureConfig.service';

export default class NotificationsSensors {
  static getNodeFeatures(node) {
    const features = [];
    if (node) {
      node.config.charts.forEach(chartConfig => {
        chartConfig.features.forEach(feature => {
          const featureType = feature.name;
          let transformedType = featureType;
          let notifications = feature.notifications;
          if (featureType === `${FEATURE_TYPES.FEATURE_STORAGE}_${aggregations.AVG}`) {
            transformedType = `${FEATURE_TYPES.FEATURE_TEMPERATURE}_${aggregations.AVG}`;
            notifications = true;
          }
          if (notifications) {
            const featureNameWithoutPrefix = FeatureConfigService.getFeatureNameWithoutPrefix(transformedType);
            const featureName = featureNameWithoutPrefix.replace(`_${feature.aggregation}`, '');
            features.push(featureName);
          }
        });
      });
    }
    return features;
  }
}
