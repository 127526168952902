module.exports = function (id) {
  const h = window;
  const o = document;
  const t = 'https://static.hotjar.com/c/hotjar-';
  const j = '.js?sv=';
  let a;
  let r;
  h.hj = h.hj || function () {
    (h.hj.q = h.hj.q || []).push(arguments)
  };
  h._hjSettings = {
    hjid: id,
    hjsv: 6
  };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};
