/**
 * Created by ondrejzvara on 9.12.15.
 */

/**
 * isFalse filter
 * @namespace Filters
 */

/**
 * @public
 * @memberof Filters
 * @param {Boolean} bool
 * @param {String} [patternT=2]
 * @param {String} [patternF=2]
 * @returns {Boolean}
 */

export function isFalse() {
    return function (bool, patternT, patternF) {
        if(_.isBoolean(bool)){
            return bool ? (patternT ? patternT : "Ano") : (patternF ? patternF : "Ne");
            // return bool ? (patternT ? patternT : $filter('translate')('shared.yes')) : (patternF ? patternF : $filter('translate')('shared.no'));
        }
        else {
            return bool;
        }
    };
}
