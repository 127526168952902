import React, { Component } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setTableSelected } from '../../actions/table.actions';

export class CfTableCheckbox extends Component {
  handleCheckboxClick = (evt, id) => {
    // to stop it from going to detail on row click
    evt.stopPropagation();

    const { namespace, selected } = this.props;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.props.setTableSelected(newSelected, namespace);
  };

  isSelected = id => this.props.selected.indexOf(id) !== -1;

  render() {
    const { id } = this.props;
    return (
      <TableCell
        key="checkbox"
        onClick={evt => this.handleCheckboxClick(evt, id)}
        padding="checkbox"
        rowSpan={this.props.rowSpan}
      >
        <Checkbox checked={this.isSelected(id)} color="primary" data-test="table-checkbox" />
      </TableCell>
    );
  }
}

CfTableCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selected: PropTypes.array.isRequired,
  namespace: PropTypes.string.isRequired,
  setTableSelected: PropTypes.func.isRequired,
  rowSpan: PropTypes.number,
};

CfTableCheckbox.defaultProps = {
  rowSpan: 1,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTableSelected,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CfTableCheckbox);
