import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import * as indices from '../../../../../core/parcels/detail/monitoring/selectors/indices';
import CfStatusPanel from '../../../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import { links } from '../../../../../shared/constants/links';
import withWidth from '../../../../../shared/hocs/withWidth';
import SatelliteIcon from '../../../../../shared/icons/SatelliteIcon';

const VaMapSourceNotAvailable = props => {
  const {
    intl: { formatMessage },
    langId,
    source,
    type,
    width,
  } = props;

  const isPurchased = Array.isArray(source) && !source.length;

  return (
    <CfStatusPanel
      dense={true}
      elevation={0}
      fullWidth={true}
      grey={true}
      horizontal={width !== 'xs'}
      icon={SatelliteIcon}
      linkHref={langId === 'cs-CZ' ? links.cs.precisionFarming : links.en.precisionFarming}
      linkText={isPurchased ? null : <FormattedMessage id="common.findOutMore" />}
      content={
        <FormattedMessage
          id={isPurchased ? 'VaMapSourceNotAvailable.needToWait' : 'VaMapSourceNotAvailable.needToBuy'}
          values={{
            type:
              type === indices.CAB || type === indices.LAI
                ? 'monitoring'
                : formatMessage({ id: `VaMapSourceDialog.${type}` }).toLowerCase(),
          }}
        />
      }
    />
  );
};

VaMapSourceNotAvailable.propTypes = {
  type: PropTypes.oneOf(['HIST', indices.LAI, indices.CAB]).isRequired,
  intl: PropTypes.object.isRequired,
  source: PropTypes.any,
  width: PropTypes.string.isRequired,
  langId: PropTypes.string,
};

VaMapSourceNotAvailable.defaultProps = {
  source: null,
  langId: 'cs-CZ',
};

export default compose(injectIntl, withWidth())(VaMapSourceNotAvailable);
