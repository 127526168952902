import React from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfInfoDialog from '../../../../../../shared/components/common/CfInfoDialog/CfInfoDialog';
import CfSectionHeading from '../../../../../../shared/components/common/CfSectionHeading/CfSectionHeading';
import withWidth from '../../../../../../shared/hocs/withWidth';

const styles = {
  heading: {
    margin: '0 15px 0 15px',
    lineHeight: 1.25,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    padding: '4px',
    minHeight: '48px',
  },
  upperHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childrenWrapper: {
    flexGrow: 1,
  },
};

export const SectionHeader = props => {
  const {
    children,
    classes,
    dialogHeadingTranslationId,
    headingTranslationId,
    hintTranslationId,
    infoDialogContent,
    maxDialogWidth,
    testId,
    width,
  } = props;
  return (
    <div className={classes.wrapper} data-test={testId}>
      <div className={classes.upperHeading}>
        <div className={classes.heading}>
          <CfSectionHeading>
            <FormattedMessage id={headingTranslationId} />
          </CfSectionHeading>
        </div>
        {width !== 'xs' && <div className={classes.childrenWrapper}>{children}</div>}
        {(hintTranslationId || infoDialogContent) && (
          <CfInfoDialog
            heading={<FormattedMessage id={dialogHeadingTranslationId || headingTranslationId} />}
            maxWidth={maxDialogWidth}
          >
            {hintTranslationId && (
              <Grid alignItems="flex-start" container>
                <Grid item xs={12}>
                  <Typography>
                    <FormattedMessage id={hintTranslationId} />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {infoDialogContent}
          </CfInfoDialog>
        )}
      </div>
      {width === 'xs' && <div className={classes.childrenWrapper}>{children}</div>}
    </div>
  );
};

SectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  headingTranslationId: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  hintTranslationId: PropTypes.string,
  infoDialogContent: PropTypes.node,
  children: PropTypes.node,
  testId: PropTypes.string,
  dialogHeadingTranslationId: PropTypes.string,
  maxDialogWidth: PropTypes.string,
};

SectionHeader.defaultProps = {
  hintTranslationId: null,
  infoDialogContent: null,
  children: null,
  testId: undefined,
  dialogHeadingTranslationId: null,
  maxDialogWidth: 'sm',
};

export default compose(withStyles(styles), withWidth())(SectionHeader);
