export const API_MODULES = {
  GATEWAY: 'gateway',
  CORE: 'core',
  IOT: 'iot',
  STORES: 'stores',
  WEATHER: 'weather',
  SENTINEL: 'sentinel',
  AUTOMATION: 'automation',
  IRRIGATION: 'irrigation',
  TELEMATICS: 'telematics',
};

export const API_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};
