/**
 * Created by ondrejzvara on 30.3.17.
 */

import angular from 'angular';
import Table from './Table.service';

export default angular.module('app.table', [])
    .factory('Table', Table)
    .name;

