import moment from 'moment';

export default class IrrigationService {
  static isActive(device) {
    return device.status === 'ACTIVE';
  }

  static getChartDatetimePositionInPixels(datetime, graphWidth, datetimeStart, datetimeEnd) {
    const pixelsMax = graphWidth;
    const pixelsMin = 1;

    const datetimeMoment = moment(datetime);
    const datetimeStartMoment = moment(datetimeStart);
    const datetimeEndMoment = moment(datetimeEnd);
    if (!(datetimeMoment.isValid() && datetimeStartMoment.isValid() && datetimeEndMoment.isValid())) {
      return NaN;
    }

    const unixValue = datetimeMoment.unix();
    const unixMin = datetimeStartMoment.unix();
    const unixMax = datetimeEndMoment.unix();

    if (unixValue < unixMin) {
      return 0;
    }
    if (unixValue > unixMax) {
      return pixelsMax;
    }

    return ((unixValue - unixMin) * (pixelsMax - pixelsMin)) / (unixMax - unixMin) + pixelsMin;
  }
}
