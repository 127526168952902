import React from 'react';

import { withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

const styles = theme => ({
  label: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  column: {
    margin: 8,
  },
  content: {
    fontSize: 16,
  },
});

function SelectionItemColumn(props) {
  const { children, classes, label } = props;
  return (
    <div className={classes.column} data-test="item-column">
      {label && (
        <div className={classes.label} data-test="item-column-label">
          {label}
        </div>
      )}
      {!isEmpty(children) && (
        <div className={classes.content} data-test="item-column-content">
          {children}
        </div>
      )}
    </div>
  );
}

SelectionItemColumn.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

SelectionItemColumn.defaultProps = {
  children: {},
  classes: {},
  label: '',
};

export default withStyles(styles)(SelectionItemColumn);
