import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MeasurementIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M21.2088 7.57209L16.4279 2.79119C16.0482 2.41151 15.4283 2.41151 15.0487 2.79119L2.79033 15.0495C2.41065 15.4292 2.41065 16.0491 2.79033 16.4288L7.57123 21.2097C7.95091 21.5894 8.5708 21.5894 8.95049 21.2097L21.2088 8.95135C21.5885 8.57166 21.5885 7.95177 21.2088 7.57209ZM8.26086 18.8773L5.12267 15.7391L6.86223 13.9996L8.25698 15.3943C8.55143 15.6888 9.03185 15.6888 9.32629 15.3943C9.62074 15.0999 9.62074 14.6195 9.32629 14.325L7.93154 12.9303L9.89194 10.9699L11.2867 12.3646C11.5811 12.6591 12.0616 12.6591 12.356 12.3646C12.6505 12.0702 12.6505 11.5898 12.356 11.2953L10.9613 9.90055L12.9217 7.94015L14.297 9.31553C14.5915 9.60998 15.0719 9.60998 15.3663 9.31553C15.6608 9.02108 15.6608 8.54067 15.3663 8.24622L13.991 6.87084L15.7383 5.12353L18.8765 8.26172L8.26086 18.8773Z" />
    </svg>
  </SvgIcon>
);

export default MeasurementIcon;
