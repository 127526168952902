import React, { Component, Fragment } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSelectedParcels,
  getMergeParcelsSuggestions,
  getAvailableIds,
  getLastCurrentSeedDate,
  getValidFrom,
  getValidFromError,
} from '../../selectors/editor.selectors';

import { setValidFrom, validateValidFrom } from '../../actions/editor/editor.actions';
import { onMouseSelect, onMouseDeselect } from '../../actions/merge/merge.actions';
import { refreshMergeStyles } from '../../actions/style/style.actions';

import { NO_CROP_LEGISLATIVE_CODE } from '../../constants/crops.constants';
import * as stages from '../../constants/stages.constants';

import { getParcelsMapActionable, resetParcelsMapActionable } from '../../../../shared/api/core/parcels/parcels.api';
import MapDatePicker from '../../components/MapDatePicker/MapDatePicker';
import SelectParcelForm from '../../components/SelectParcelForm/SelectParcelForm';
import ToolbarBtnCancel from '../../components/ToolbarBtnCancel/ToolbarBtnCancel';
import ToolbarBtnSubmit from '../../components/ToolbarBtnSubmit/ToolbarBtnSubmit';

class MergeToolbar extends Component {
  constructor(props) {
    super(props);

    this.prevCallPromise = Promise.resolve();
    this.todayMoment = moment().startOf('day');
  }

  componentDidUpdate(prevProps) {
    const { availableIds, lastCurrentSeedDate, validFrom } = this.props;
    if (prevProps.availableIds && availableIds !== prevProps.availableIds) {
      this.onSuggestionFetch();
    }

    if (
      validFrom !== prevProps.validFrom ||
      (moment.isMoment(lastCurrentSeedDate) && !lastCurrentSeedDate.isSame(prevProps.lastCurrentSeedDate))
    ) {
      this.props.validateValidFrom();
    }
  }

  onSuggestionFetch = textFilter => {
    this.prevCallPromise.then(() => {
      this.prevCallPromise = this.props.getParcelsMapActionable({
        neighborsMoreThan: 0,
        ids: this.props.availableIds.join(','),
        ...(textFilter && textFilter.length ? { localNameBlockNr: textFilter } : {}),
      });
    });
  };

  onSuggestionClear = () => {
    this.props.resetParcelsMapActionable();
  };

  onSuggestionsReset = () => {
    this.props.resetParcelsMapActionable();
  };

  onParcelSelect = suggestion => {
    const feature = {
      get(prop) {
        return this[prop];
      },
      id: suggestion.id,
      local_name: suggestion.localName,
      lpis_parcel_count: suggestion.lpisParcelCount,
      crop_legislative_code: NO_CROP_LEGISLATIVE_CODE,
      lpis_block_id: suggestion.lpisBlockId,
      seed_date_start: suggestion.currentSeedDate,
      lpis_neighbors: suggestion.lpisNeighbors,
    };
    this.props.onMouseSelect(feature, this.props.countryCode).then(() => {
      this.props.refreshMergeStyles();
    });
  };

  onParcelRemove = parcel => {
    const feature = {
      get(prop) {
        return this[prop];
      },
      id: parcel.id,
    };
    this.props.onMouseDeselect(feature, this.props.countryCode);
  };

  filterSuggestions = suggestions => {
    const { availableIds } = this.props;
    if (availableIds.length) {
      return suggestions.filter(i => availableIds.includes(i.id));
    }
    return suggestions;
  };

  render() {
    const {
      availableIds,
      error,
      langId,
      lastCurrentSeedDate,
      onCancel,
      onSubmit,
      parcels,
      stage,
      suggestions,
      validFrom,
      validFromError,
    } = this.props;

    return (
      <Fragment>
        <SelectParcelForm
          disabled={stage !== stages.STAGE_1 && !availableIds.length}
          langId={langId}
          onParcelRemove={this.onParcelRemove}
          onParcelSelect={this.onParcelSelect}
          onSuggestionClear={this.onSuggestionClear}
          onSuggestionFetch={this.onSuggestionFetch}
          onSuggestionsReset={this.onSuggestionsReset}
          parcels={parcels}
          shouldFetchAfterClear={false}
          suggestions={this.filterSuggestions(suggestions)}
        />
        {stage === stages.STAGE_3 && (
          <MapDatePicker
            error={!!validFromError}
            minDate={lastCurrentSeedDate}
            setDate={this.props.setValidFrom}
            validFrom={validFrom}
          />
        )}
        <span>
          <ToolbarBtnCancel onCancel={onCancel} />
          <ToolbarBtnSubmit
            disabled={stage !== stages.STAGE_3 || !!error || !!validFromError}
            onSubmit={onSubmit}
            translationId={'tools.MERGE'}
          />
        </span>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  parcels: getSelectedParcels(state),
  suggestions: getMergeParcelsSuggestions(state),
  availableIds: getAvailableIds(state),
  lastCurrentSeedDate: getLastCurrentSeedDate(state),
  validFrom: getValidFrom(state),
  validFromError: getValidFromError(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getParcelsMapActionable,
      resetParcelsMapActionable,
      onMouseSelect,
      onMouseDeselect,
      refreshMergeStyles,
      setValidFrom,
      validateValidFrom,
    },
    dispatch,
  );

MergeToolbar.propTypes = {
  langId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getParcelsMapActionable: PropTypes.func.isRequired,
  resetParcelsMapActionable: PropTypes.func.isRequired,
  onMouseSelect: PropTypes.func.isRequired,
  onMouseDeselect: PropTypes.func.isRequired,
  refreshMergeStyles: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
  stage: PropTypes.string,
  parcels: PropTypes.array,
  availableIds: PropTypes.array,
  lastCurrentSeedDate: PropTypes.object,
  setValidFrom: PropTypes.func.isRequired,
  validFrom: PropTypes.string,
  error: PropTypes.string,
  validateValidFrom: PropTypes.func.isRequired,
  validFromError: PropTypes.string,
};

MergeToolbar.defaultProps = {
  stage: null,
  parcels: [],
  availableIds: [],
  lastCurrentSeedDate: null,
  validFrom: null,
  error: null,
  validFromError: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(MergeToolbar);
