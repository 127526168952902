import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './seeds.constants';

export const getSeeds = () => ({
  [RSAA]: {
    endpoint: 'seeds?per-page=500&',
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_SEEDS, types.GET_SEEDS_SUCCESS, types.GET_SEEDS_FAILURE],
  },
});

export const getSeedsByName = name => ({
  [RSAA]: {
    endpoint: `seeds/refs?per-page=500&name=${name}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_SEEDS, types.GET_SEEDS_SUCCESS, types.GET_SEEDS_FAILURE],
  },
});

export const resetSeeds = () => ({
  type: types.RESET_SEEDS,
});
