import { calculateSubtractableArea, calculateTotalSubtractableArea } from '../../../../shared/api/core/subtractableAreas/subtractableAreas.api';
// Actions for new subtractable areas (now in others action)
export const calculateAndGetSubtractableArea =
  (subtractableAreaType, parcelId, restrictionValue, parcelIds) => dispatch =>
    dispatch(calculateSubtractableArea(subtractableAreaType, parcelId, restrictionValue, parcelIds))
      .then(res => res.payload.area);

export const calculateAllSubtractableAreas =
  (parcelId, parcelsSubtractionTos, parcelIds) => dispatch =>
    dispatch(calculateTotalSubtractableArea(parcelId, parcelsSubtractionTos, parcelIds))
      .then(res => res.payload.geometry.area);
