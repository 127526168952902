export const GET_REPORT_TYPES = 'GET_REPORT_TYPES';
export const GET_REPORT_TYPES_SUCCESS = 'GET_REPORT_TYPES_SUCCESS';
export const GET_REPORT_TYPES_ERROR = 'GET_REPORT_TYPES_ERROR';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

export const GET_SOWING_PLAN = 'GET_SOWING_PLAN';
export const GET_SOWING_PLAN_SUCCESS = 'GET_SOWING_PLAN_SUCCESS';
export const GET_SOWING_PLAN_ERROR = 'GET_SOWING_PLAN_ERROR';
