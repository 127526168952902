import React, { useEffect, useState, useContext, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';
import Localization from '../../../../../shared/services/Localization.service';
import ActionsService from '../../../shared/services/Actions.service';
import { FormContext } from '../../actionOther.context';

function Revenues() {
  const [totalArea, setTotalArea] = useState(0);
  const [dirtyInput, setDirtyInput] = useState('');
  const { setFieldValue, values: { parcels, totalGain } } = useFormikContext();
  const { isEditing } = useContext(FormContext);
  const intl = useIntl();

  const updateFormValues = (value) => {
    // this is triggered on value change.
    // Computation is skipped for all values that ends with non-numerical characters.
    const lastCharInValue = value[value.length - 1];
    const isNotValidValue = isNaN(lastCharInValue);

    // convert localization-formatted value strings to numbers
    const normalizedVal = Localization.str2numNonFixed(value, intl.locale);
    const normalizedTotalArea = Localization.str2numNonFixed(totalArea, intl.locale);

    if (dirtyInput === 'totalGain') {
      if (isNotValidValue) {
        setFieldValue('totalGain', value);
      } else {
        const hectarGain = normalizedVal / normalizedTotalArea;
        setFieldValue('totalGain', Localization.num2strNonFixed(normalizedVal, intl.locale));
        setFieldValue('hectarGain', Localization.num2str(hectarGain, intl.locale, 3));
      }
    } else if (dirtyInput === 'hectarGain') {
      if (isNotValidValue) {
        setFieldValue('hectarGain', value);
      } else {
        const totalGain = normalizedVal * normalizedTotalArea;
        setFieldValue('hectarGain', Localization.num2strNonFixed(normalizedVal, intl.locale));
        setFieldValue('totalGain', Localization.num2str(totalGain, intl.locale, 3));
      }
    }
  };

  const onChangeValue = (e) => {
    const value = e.currentTarget.value;
    updateFormValues(value);
  };

  const onFocus = (e) => {
    setDirtyInput(e.currentTarget.name);
  };

  useEffect(() => {
    if (parcels && parcels.length) {
      const area = ActionsService.getTotalArea(parcels);
      setTotalArea(area.toFixed(2));
    }
  }, [parcels]);

  useEffect(() => {
    if (totalArea && totalGain) {
      const normalizedTotalGain = Localization.str2numNonFixed(totalGain, intl.locale);
      const normalizedTotalArea = Localization.str2numNonFixed(totalArea, intl.locale);
      const hectarGain = normalizedTotalGain / normalizedTotalArea;
      setFieldValue('hectarGain', Localization.num2str(hectarGain, intl.locale, 3));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, totalArea, intl.locale]);

  const localizedNumberValidator = useMemo(() => validators.localizedNumber(intl.locale), [intl.locale]);

  return (
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={<FormattedMessage id="action.total-gain" />}
            name="totalGain"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t</span>
      </Grid>
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={<FormattedMessage id="action.gain-per-hectare" />}
            name="hectarGain"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t/ha</span>
      </Grid>
    </Grid>
  );
}

export default Revenues;
