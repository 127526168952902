import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import errorPage500 from '../../../../assets/img/error-page-500.svg';
import errorPage503 from '../../../../assets/img/error-page-503.svg';

const styles = theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  image: {
    width: '200px',
    height: 'auto',
  },
  heading: {
    marginBottom: '10px',
  },
});

const images = {
  500: errorPage500,
  503: errorPage503,
};

export class CfErrorPage extends Component {
  componentDidMount() {}

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     error: {}
  //   };
  // }

  // componentDidUpdate(prevProps) {
  //   const { error } = this.props;
  //   if (prevProps.error !== error) {
  //     this.setState({ error });
  //   }
  // }

  // // TODO this should be extracted as an error boundary to the CfErrorPage in future
  // // however now, bude to lack of time, iis tested only here
  // componentDidCatch() {
  //   this.setState({
  //     error: {
  //       isError: true,
  //       code: 503
  //     }
  //   });
  // }

  render() {
    const { children, classes, error } = this.props;
    const { code } = error;

    return !(error.isError && (error.code === 500 || error.code === 503)) ? (
      children
    ) : (
      <div className={classes.wrapper}>
        <Grid alignItems="center" container direction="column" justifyContent="center" spacing={2}>
          <Grid item>
            <img alt={`Error ${code}`} className={classes.image} src={images[code]} />
          </Grid>
          <Grid item>
            <h1 className={classes.heading}>
              <FormattedMessage id={`error.heading.${code}`} />
            </h1>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item style={{ textAlign: 'center' }} xs={8}>
                <FormattedMessage id={`error.message.${code}`} />{' '}
                <a href="mailto:help@cleverfarm.cz">help@cleverfarm.cz</a>
                {'.'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CfErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
};

CfErrorPage.defaultProps = {
  error: {},
};

export default withStyles(styles)(CfErrorPage);
