/**
 * Created by ondrejzvara on 2.11.15.
 */


/*@ngInject*/
export default function FertilizersResource(Api, ResourceUtils, MaterialToMapper) {

    var baseAll = Api.setBaseAll("fertilizers");
    var baseNCats = Api.setBaseAll("fertilizers/nitrogenCategories");

    var service = {
        getFertilizer: getFertilizer,
        getFertilizers: getFertilizers,
        getNitrogenCategories: getNitrogenCategories,
        createFertilizer: createFertilizer,
        editFertilizer: editFertilizer,
        deleteFertilizers: deleteFertilizers,
        addFertilizerToFarm: addFertilizerToFarm
    };
    return service;

    /////////////////////////

    function getFertilizer(fertId) {
        var baseOne = Api.setBaseOne("fertilizers", fertId);
        return ResourceUtils.getItem(baseOne, {});
    }

    function getFertilizers(ngTparams, custParams) {
        var params = ResourceUtils.composeParams(ngTparams, custParams);
        return ResourceUtils.getItems(baseAll, params, ngTparams);
    }

    function getNitrogenCategories() {
        return ResourceUtils.getItems(baseNCats);
    }

    function createFertilizer(item) {
        item = Api.restangularizeOne(null, item, "fertilizers");
        return ResourceUtils.createItem(baseAll, MaterialToMapper.modelToDto(item, "FertilizerTo"), true)
            .then(function (response) {
                return ResourceUtils.getIdAfterCreate(baseAll, response);
            });
    }

    function editFertilizer(item) {
        item = Api.restangularizeOne(null, item, "fertilizers");
        return ResourceUtils.editItem(item);
    }

    function deleteFertilizers(items) {
        return ResourceUtils.deleteItems(items);
    }

    function addFertilizerToFarm(legislativeCode) {
        return ResourceUtils.addToFarm(baseAll, legislativeCode, true)
            .then(function (response) {
                return ResourceUtils.getIdAfterCreate(baseAll, response);
            });
    }

}
