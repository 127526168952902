/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * GeoJSON representation of a Geometry.
 */
export interface Geometry {
  /** @example LineString */
  type?: string;
  coordinates?: number[][];
}

export interface DriveCreateTo {
  parcelId?: string;

  /** @format date-time */
  timeFrom: string;

  /** @format date-time */
  timeTo: string;
  driver?: string;

  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example S711A33264
   */
  gpsUnit: string;

  /**
   * Equipment code (dallas chip).
   * @example 021467745FFF
   */
  equipment?: string;
  operation: TelematicsOperation;

  /**
   * Production operation code.
   * @example 6020-VO
   */
  productionOperation?: string;

  /** Working width [m] for this drive. */
  workingWidth?: number;

  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;

  /** Distance [km] which the machine travelled. */
  distance?: number;

  /** Driver bonus [%} for this operation. */
  bonus: number;
}

/**
 * @example Transfer
 */
export enum TelematicsOperation {
  PARCEL = "PARCEL",
  TRANSFER = "TRANSFER",
  TRANSPORT = "TRANSPORT",
  REPAIR = "REPAIR",
}

export interface ActionDriveDetailTo {
  activityId?: string;
  area?: number;
  distance?: number;
}

export enum Affiliation {
  COMPANY = "COMPANY",
  EXTERNAL = "EXTERNAL",
  NONE = "NONE",
}

export interface DriveDetailFarmTo {
  /** @format int64 */
  farmId?: number;

  /** @format int32 */
  companyNumber?: number;
}

export interface DriveDetailOperationTo {
  code?: string;
  name?: string;
}

export interface DriveDetailTo {
  /** @format int32 */
  id?: number;

  /** GeoJSON representation of a Geometry. */
  geometry?: Geometry;

  /**
   * Azimuth of the trajectory start, expressed in radians.
   * @example 0.78539816
   */
  geometryStartAzimuth?: number;

  /** GeoJSON representation of a Geometry. */
  geometryStart?: Geometry;

  /** GeoJSON representation of a Geometry. */
  geometryEnd?: Geometry;

  /** GeoJSON representation of a Geometry. */
  geometryPerDay?: Geometry;
  parcelId?: string;
  centerKey?: string;

  /** @format int32 */
  cropKey?: number;
  farm: DriveDetailFarmTo;

  /** @format date-time */
  timeFrom: string;

  /** @format date-time */
  timeTo: string;
  driver: DriverDriveDetailTo;
  machineName?: string;
  machineKeyCombination?: string;
  machineCategoryCode?: string;
  gpsUnit?: string;
  equipmentKeyCombination?: string;
  equipmentCode?: string;
  workingWidth?: number;
  operation: TelematicsOperation;
  productionOperation?: DriveDetailOperationTo;
  parcel?: ParcelInfoTo;
  action: ActionDriveDetailTo;
  source: Source;
  state: State;
}

export interface DriverDriveDetailTo {
  name?: string;
  code?: string;
  personalNumber?: string;
  bonus?: number;
  isEmployee: boolean;
}

export interface ParcelInfoTo {
  /** GeoJSON representation of a Geometry. */
  geometry?: Geometry;
  localName?: string;
  blockNumber?: string;
  square?: string;
  subjectName?: string;
  affiliation?: Affiliation;
}

export enum Source {
  MANUAL = "MANUAL",
  EUROWAG = "EUROWAG",
}

export enum State {
  NOT_APPROVED = "NOT_APPROVED",
  APPROVED = "APPROVED",
  THIRD_PARTY_ACKNOWLEDGED = "THIRD_PARTY_ACKNOWLEDGED",
}

export interface DriveUpdateTo {
  parcelId?: string;

  /** @format date-time */
  timeFrom: string;

  /** @format date-time */
  timeTo: string;
  driver?: string;

  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example S711A33264
   */
  gpsUnit: string;

  /**
   * Equipment code (dallas chip).
   * @example 021467745FFF
   */
  equipment?: string;
  operation: TelematicsOperation;

  /**
   * Production operation code.
   * @example 6020-VO
   */
  productionOperation?: string;

  /** Working width [m] of this drive. */
  workingWidth?: number;

  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;

  /** Distance [km] which the machine travelled. */
  distance?: number;

  /** Driver bonus [%} for this operation. */
  bonus: number;
}

export interface DriveRecalculateTo {
  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example S711A33264
   */
  gpsUnit: string;

  /**
   * Equipment code (dallas chip).
   * @example 021467745FFF
   */
  equipment?: string;

  /** Working width [m] of this drive. */
  workingWidth?: number;
}

export interface DriveAreaTo {
  area?: number;
}

export interface TosExportCreateTo {
  drives: number[];
}

export interface DriveUpdateStateTo {
  drive: number[];
  state: State;
}

export interface AccountCreateTo {
  farmId: string;
  name: string;
  externalId: string;
  countryCode: string;
  enabled: boolean;
}

export interface AccountTo {
  farmId: string;
  name: string;
  externalId?: string;
  countryCode: string;
  enabled: boolean;

  /** @format date-time */
  activated?: string;

  /** @format date-time */
  deactivated?: string;
  zoneId?: string;
}

export interface AccountUpdateTo {
  farmId: string;
  externalId: string;
  enabled: boolean;
}

export interface OperationTo {
  code: TelematicsOperation;
  name: string;
}

export interface ProductionOperationTo {
  /** @format int32 */
  id?: number;
  code?: string;
  name?: string;
  bonus?: number;
}

export interface DeviceCompanyTo {
  code?: string;
  name?: string;
  id?: string;
}

export interface DeviceOperationTo {
  id?: string;
  name?: string;
}

export enum MachineGroupCode {
  COMBINE = "COMBINE",
  LOADER = "LOADER",
  TRUCK = "TRUCK",
  PASSENGER = "PASSENGER",
  TRACTOR = "TRACTOR",
  OTHER = "OTHER",
}

export interface MachineTo {
  /** @format int32 */
  id?: number;
  machineCode?: string;
  keyCombination?: string;
  operation?: DeviceOperationTo;
  type?: string;
  licencePlate?: string;
  name?: string;

  /** @format double */
  workingWidth?: number;
  gpsUnit: string;
  unitPhoneNo?: string;
  company?: DeviceCompanyTo;

  /** @format date */
  validFrom: string;
  profileCode?: string;
  group: MachineGroupCode;
}

export interface PositionDetailTo {
  /** @format date-time */
  time: string;

  /** @format date-time */
  movementTime?: string;

  /** GeoJSON representation of a Geometry. */
  location: Geometry;
  machine?: PositionMachineTo;
  driver?: PositionDriverTo;
  speed?: number;
  azimuth?: number;
}

export interface PositionDriverTo {
  name?: string;
  code: string;
}

export interface PositionMachineTo {
  name?: string;
  machineCode?: string;
  type?: string;
  operation?: string;
  licencePlate?: string;
  gpsUnit: string;
  group?: MachineGroupCode;
}

export interface DailyPositionTo {
  /** @format date-time */
  date: string;

  /** GeoJSON representation of a Geometry. */
  geometry: Geometry;
  gpsUnit: string;
  machineName?: string;
  licencePlate?: string;
  driverName?: string;
  driverCode?: string;
}

export interface MachineGroupTo {
  code: MachineGroupCode;
  value: string;
}

export enum LogbookSortColumn {
  FROM = "FROM",
  DRIVER_NAME = "DRIVER_NAME",
  OPERATION_NAME = "OPERATION_NAME",
  MACHINE_NAME = "MACHINE_NAME",
  CULTIVATED = "CULTIVATED",
}

export interface LogbookFilterCriteriaTo {
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState: string[];
  operation: TelematicsOperation[];
  productionOperation: string[];
  actionType: string[];
  parcel: number[];
  driver: string[];
  gpsUnit: string[];
  state: State[];
}

export interface DriverIdentityTo {
  name?: string;
  code?: string;
  affiliation?: Affiliation;
}

export interface EquipmentLogbookItemTo {
  code?: string;

  /**
   * Human-readable equipment name.
   * @example ZDT, Galan, PV 15
   */
  name?: string;
  affiliation?: Affiliation;
  width?: number;
}

export interface LogbookItemTo {
  id: string;

  /** @format date-time */
  from: string;

  /** @format date-time */
  to: string;
  driver?: DriverIdentityTo;

  /**
   * Operation type (localized).
   * @example Transfer
   */
  operation?: string;
  productionOperation?: OperationLogbookItemTo;
  parcel?: ParcelInfoTo;

  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;

  /** Total parcel area [ha]. */
  parcelArea?: number;

  /** Distance [m] which the machine travelled. */
  distance?: number;

  /** Driver bonus [%}. */
  bonus?: number;
  gpsUnit?: string;
  machineName?: string;
  machineLicencePlate?: string;
  equipment?: EquipmentLogbookItemTo;
  source: Source;
  state: State;
}

export interface OperationLogbookItemTo {
  code?: string;

  /**
   * Human-readable operation name.
   * @example VO-ŽV-Převoz dobytka
   */
  name?: string;
}

export interface EquipmentTo {
  /** @format int32 */
  id?: number;
  name?: string;
  code: string;

  /** @format double */
  workingWidth?: number;

  /** @format date */
  validFrom: string;
  profileCode?: string;
}

export interface DriverCompanyTo {
  id?: string;
  code?: string;
  name?: string;
}

export interface DriverTo {
  /** @format int32 */
  id?: number;
  name?: string;
  code: string;
  company?: DriverCompanyTo;

  /** @format date */
  validFrom: string;
}

export enum AccountSortColumn {
  FARM_ID = "FARM_ID",
  NAME = "NAME",
  EXTERNAL_ID = "EXTERNAL_ID",
  COUNTRY_CODE = "COUNTRY_CODE",
  ENABLED = "ENABLED",
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface AccountFilterCriteriaTo {
  search?: string;
  countryCode?: string[];
  enabled?: boolean;
}

export interface AccountStatusTo {
  telematics: boolean;
}
