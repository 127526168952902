import React, { useEffect, useContext, useCallback, useRef } from 'react';

import { Grid, Button, Card, Icon, Typography, Switch, FormControlLabel } from '@mui/material';
import { Form, Field, FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getValidationFailure } from '../../../../../shared/api/core/actions/actions.selectors';

import { FORM_TYPES, OTHER_ACTION_HARVEST_CODE, OTHER_ACTION_MOWING_CODE, OTHER_ACTION_SOWING_CODE } from '../../actionOther.constants';

import { validateAction, resetValidationFailure } from '../../../../../shared/api/core/actions/actions.api';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikDatePicker from '../../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';
import ActionValidationInfo from '../../../shared/components/ActionValidationInfo/ActionValidationInfo';
import ParcelsControl from '../../../shared/containers/ParcelsControl/ParcelsControl';
import ActionToMapper from '../../../shared/services/ActionToMapper.service';
import { FormContext } from '../../actionOther.context';
import Revenues from '../../components/ActionHarvest/Revenues';
import ActionSelector from '../../components/ActionOther/ActionSelector';
import AmountSelector from '../../components/ActionOther/AmountSelector';
import ProductSelector from '../../components/ActionOther/ProductSelector';
import SeedSelector from '../../components/ActionOther/SeedSelector';
import { isAnyParcelNotSown, isAnyParcelSown } from '../../helpers/others.helpers';

function ActionOther({ classes, resetValidationFailure, validateAction, validationFailure }) {
  const { isValid, isValidating, resetForm, setFieldValue, values } = useFormikContext();
  const {
    formType,
    handleLsReset,
    handleSaveToLs,
    isEditing,
    isExisting,
    onEditingEnd,
    resetFormValues,
  } = useContext(FormContext);
  const timeout = useRef(null);

  const intl = useIntl();

  useEffect(() => {
    resetValidationFailure();

    return () => {
      if (timeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isExisting) return;
    handleSaveToLs(values);

    // TODO fix this
    if (formType === FORM_TYPES.HARVEST) {
      setFieldValue('actionType', OTHER_ACTION_HARVEST_CODE);
    } else if (formType === FORM_TYPES.MOWING) {
      setFieldValue('actionType', OTHER_ACTION_MOWING_CODE);
    } else if (formType === FORM_TYPES.SOWING) {
      setFieldValue('actionType', OTHER_ACTION_SOWING_CODE);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const apiValidation = () => {
    const data = ActionToMapper.createNewOtherActionTo(values, intl.locale);
    validateAction(data);
  };

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (isValidating || !isValid) {
      return;
    }

    if (formType !== FORM_TYPES.SOWING) {
      return;
    }

    if (!values.seedId) {
      return;
    }

    timeout.current = setTimeout(apiValidation, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValidating, isValid]);

  const handleReset = useCallback(
    () => {
      if (!isExisting) {
        handleLsReset();
        resetFormValues();
      } else {
        onEditingEnd();
        resetForm();
      }
    },
    [handleLsReset, isExisting, onEditingEnd, resetForm, resetFormValues],
  );

  const handleHarvestCropChange = (e, value) => {
    setFieldValue('harvestCrop', value);
  };

  const handleIsCatchCropChange = (e, value) => {
    setFieldValue('isCatchCrop', value);
  };

  const isNewHarvestForm = formType === FORM_TYPES.HARVEST && !isExisting;
  const isNewMowingForm = formType === FORM_TYPES.MOWING && !isExisting;
  const allMustBeSown = isNewHarvestForm || isNewMowingForm;

  const hasUnsownParcels = isAnyParcelNotSown(values.parcels);
  const isParcelSelected = values.parcels.length > 0;
  const showWarningMessage = allMustBeSown && hasUnsownParcels && isParcelSelected;

  const hasAllUnsownParcels = !isAnyParcelSown(values.parcels);

  let warningMessage = '';
  if (showWarningMessage) {
    if (formType === FORM_TYPES.HARVEST) {
      warningMessage = hasAllUnsownParcels ? 'action.harvest.catchAllParcelsNotSown' : 'action.harvest.catchSomeParcelsNotSown';
    } else if (formType === FORM_TYPES.MOWING) {
      warningMessage = hasAllUnsownParcels ? 'action.mowing.catchAllParcelsNotSown' : 'action.mowing.catchSomeParcelsNotSown';
    }
  }

  return (
    <Form>
      <Grid alignItems="center" container justifyContent="center" spacing={1}>
        <Grid item lg={8} md={10} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FieldArray name="parcels">
                {({ form, push, remove }) => (
                  <ParcelsControl
                    allMustBeSown={allMustBeSown}
                    form={form}
                    formType={formType}
                    handleInsert={push}
                    handleRemove={remove}
                    isEditing={isEditing}
                    isExisting={isExisting}
                  />
                )}
              </FieldArray>
            </Grid>
          </Grid>
          <Grid alignItems="center" className={classes.date} container justifyContent="center" spacing={1}>
            <Grid item md={5} sm={7} xl={4} xs={10}>
              <CfFormControl>
                <Field
                  component={CfFormikDatePicker}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.date" />}
                  name="date"
                  validate={validators.required}
                />
              </CfFormControl>
            </Grid>
          </Grid>

          {formType === FORM_TYPES.MOWING && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <ProductSelector isEditing={isEditing} />
              </Grid>
            </Grid>
          )}

          {(formType === FORM_TYPES.HARVEST || formType === FORM_TYPES.MOWING) && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <Revenues />
              </Grid>
            </Grid>
          )}

          {formType === FORM_TYPES.OTHER && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <ActionSelector isEditing={isEditing} />
              </Grid>
            </Grid>
          )}

          {
            // todo: temporary hide switch, remove "false"
          }
          {false && formType === FORM_TYPES.MOWING && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <FormControlLabel
                  className={classes.checkboxContainer}
                  disabled={!isEditing}
                  label={<FormattedMessage id="action.harvestCrop" />}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={values.harvestCrop}
                      color="primary"
                      id="harvestCrop"
                      onChange={handleHarvestCropChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}

          {formType === FORM_TYPES.SOWING && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <SeedSelector isEditing={isEditing} />
              </Grid>
            </Grid>
          )}

          {formType === FORM_TYPES.SOWING && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <AmountSelector />
              </Grid>
            </Grid>
          )}

          {formType === FORM_TYPES.SOWING && (
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <FormControlLabel
                  className={classes.checkboxContainer}
                  disabled={!isEditing}
                  label={<FormattedMessage id="action.isCatchCrop" />}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={values.isCatchCrop}
                      color="primary"
                      id="isCatchCrop"
                      onChange={handleIsCatchCropChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}

          <Grid alignItems="center" container justifyContent="center" spacing={1}>
            <Grid item md={5} sm={7} xl={4} xs={10}>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.note" />}
                  name="description"
                />
              </CfFormControl>
            </Grid>
          </Grid>
          {showWarningMessage && (
            <Grid alignItems="center" className={classes.actionInfo} container justifyContent="center">
              <Card className={classes.card}>
                <div className={classes.header}>
                  <Icon>warning</Icon>
                </div>
                <Typography className={classes.content} component="p">
                  <FormattedMessage id={warningMessage} />
                </Typography>
              </Card>
            </Grid>)}
          <Grid item xs={12}>
            <Grid alignItems="center" container justifyContent="center" spacing={0}>
              <ActionValidationInfo validationDetails={validationFailure} />
            </Grid>
          </Grid>
          {isEditing && (
            <Grid item xs={12}>
              <Grid alignItems="center" className={classes.formButtons} container justifyContent="center" spacing={0}>
                <Button className={classes.button} disabled={!isEditing} id="reset" onClick={handleReset} type="reset" variant="contained">
                  <FormattedMessage id={isExisting ? 'common.cancel' : 'common.reset'} />
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={(allMustBeSown && hasAllUnsownParcels) ? true : !isEditing}
                  id="create"
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id={isExisting ? 'common.save' : 'common.create'} />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

ActionOther.propTypes = {
  classes: PropTypes.object.isRequired,
  validateAction: PropTypes.func.isRequired,
  resetValidationFailure: PropTypes.func.isRequired,
  validationFailure: PropTypes.object,
};

ActionOther.defaultProps = {
  validationFailure: null,
};

const mapStateToProps = (state) => ({
  validationFailure: getValidationFailure(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      validateAction,
      resetValidationFailure,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionOther);
