import * as types from './layers.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_LAYERS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_LAYERS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    case types.GET_LAYERS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case types.RESET_LAYERS:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: [],
      };
    default:
      return state;
  }
};
