import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './IrrigationPlanCalendar.css';
import { getApiError, getAreaProgram, getIsFetchingAreaProgram } from '../../../shared/api/irrigation/areas/areas.selectors';

import { createAreaProgram, deleteAreaProgram, fetchAreaProgram, resetAreaProgram, setAreaProgram, updateAreaProgram } from '../../actions/areasList.actions';

import { IRRIGATION_PLAN } from '../../irrigation.constants';

import { resetApiError } from '../../../shared/api/irrigation/areas/areas.api';
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar';
import IrrigationPlanCalendarModal from '../IrrigationPlanCalendarModal/IrrigationPlanCalendarModal';

const weekdays = {
  cs: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
  es: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  sr: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
  el: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
};
moment.updateLocale('cs', { weekdays: weekdays.cs });
moment.updateLocale('es', { weekdays: weekdays.es });
moment.updateLocale('ru', { weekdays: weekdays.ru });
moment.updateLocale('sr', { weekdays: weekdays.sr });
moment.updateLocale('el', { weekdays: weekdays.el });
const localizer = momentLocalizer(moment);

const formats = {
  dayFormat: (date, culture, localizer) =>
    localizer.format(date, 'dddd', culture),
  dayHeaderFormat: (date, culture, localizer) =>
    localizer.format(date, 'dddd', culture),
  eventTimeRangeFormat: range =>
    localizer.format(range.start, 'HH:mm'),
};

const CALENDAR_PLAN_DAYS = {
  1: IRRIGATION_PLAN.DAY_CODES.MONDAY,
  2: IRRIGATION_PLAN.DAY_CODES.TUESDAY,
  3: IRRIGATION_PLAN.DAY_CODES.WEDNESDAY,
  4: IRRIGATION_PLAN.DAY_CODES.THURSDAY,
  5: IRRIGATION_PLAN.DAY_CODES.FRIDAY,
  6: IRRIGATION_PLAN.DAY_CODES.SATURDAY,
  7: IRRIGATION_PLAN.DAY_CODES.SUNDAY,
};

const useStyles = makeStyles(theme => ({
  displayCalendar: {
    [theme.breakpoints.down(undefined)]: {
      display: 'none',
    },
  },
  displayCalendarMobile: {
    [theme.breakpoints.up('480')]: {
      display: 'none',
    },
  },
}));

const IrrigationPlanCalendar = ({
  apiError,
  areaDetail,
  areaProgram,
  areaPrograms,
  createAreaProgram,
  deleteAreaProgram,
  fabCloseModal,
  fetchAreaProgram,
  isFetchingAreaProgram,
  langId,
  resetApiError,
  resetAreaProgram,
  setAreaProgram,
  showDialogFromFab,
  updateAreaProgram,
}) => {
  const classes = useStyles();
  const areaId = areaDetail.id;

  const [showDialog, setShowDialog] = useState(showDialogFromFab);
  const [isExistingEvent, setIsExistingEvent] = useState(null);
  const [allEvents, setAllEvents] = useState([]);

  // set culture depends on langID - 'cs', 'en', 'sr', 'es', 'ru', 'el'
  const culture = langId.substr(0, 2);

  const minTimeDayView = new Date(1970, 1, 0, 7, 0, 0);
  const maxTimeDayView = new Date(1970, 1, 0, 21, 0, 0);

  useEffect(() => {
    setAllEvents(areaPrograms);
  }, [areaPrograms]);

  useEffect(() => {
    if (showDialogFromFab) {
      const areaDevices = areaDetail.devices;
      setAreaProgram(areaDevices);
      setIsExistingEvent(false);
      setShowDialog(showDialogFromFab);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialogFromFab]);

  const handleCreateProgramOpenModal = ({ start }) => {
    const startTimes = start.toTimeString().substr(0, 5);
    const irrigationDays = CALENDAR_PLAN_DAYS[start.getDay()];
    const areaDevices = areaDetail.devices;
    setAreaProgram(areaDevices, startTimes, irrigationDays);
    setIsExistingEvent(false);
    setShowDialog(true);
  };

  const handleEditProgramOpenModal = ({ programId }) => {
    const areaDevices = areaDetail.devices;
    setAreaProgram(areaDevices);
    fetchAreaProgram(areaId, programId);
    setIsExistingEvent(true);
    setShowDialog(true);
  };

  const handleCloseModal = () => {
    resetAreaProgram();
    fabCloseModal();
    setShowDialog(false);
  };

  const handleCreateModal = (newProgram) => {
    createAreaProgram(areaId, newProgram, handleCloseModal);
  };

  const handleUpdateModal = (updateProgram) => {
    const programId = updateProgram.programId;
    updateAreaProgram(areaId, programId, updateProgram, handleCloseModal);
  };

  const handleDeleteModal = (programId) => {
    deleteAreaProgram(areaId, programId, handleCloseModal);
  };

  return (
    <div className="container" data-test="calendar-container">
      <div className={classes.displayCalendar}>
        <Calendar
          culture={culture}
          defaultView={Views.WEEK}
          endAccessor="end"
          events={allEvents}
          formats={formats}
          localizer={localizer}
          onSelectEvent={handleEditProgramOpenModal}
          onSelectSlot={handleCreateProgramOpenModal}
          selectable={true}
          startAccessor="start"
          style={{ marginTop: '15px' }}
          toolbar={false}
        />
      </div>
      <div className={classes.displayCalendarMobile}>
        <Calendar
          culture={culture}
          defaultView={Views.DAY}
          endAccessor="end"
          events={allEvents}
          formats={formats}
          localizer={localizer}
          max={maxTimeDayView}
          messages={{ previous: <FormattedMessage id="Irrigation.calendarDayView.back" />, today: <FormattedMessage id="Irrigation.calendarDayView.today" />, next: <FormattedMessage id="Irrigation.calendarDayView.next" /> }}
          min={minTimeDayView}
          onSelectEvent={handleEditProgramOpenModal}
          onSelectSlot={handleCreateProgramOpenModal}
          selectable={true}
          startAccessor="start"
          style={{ marginTop: '15px' }}
          toolbar={true}
          views={['day']}
        />
      </div>
      {showDialog && !isFetchingAreaProgram &&
        <IrrigationPlanCalendarModal
          areaProgram={areaProgram}
          isExistingEvent={isExistingEvent}
          onClose={handleCloseModal}
          onCreate={handleCreateModal}
          onDelete={handleDeleteModal}
          onEdit={handleUpdateModal}
          showDialog={showDialog}
        />
      }
      <ErrorSnackbar
        handleClose={resetApiError}
        open={apiError}
      />
    </div>
  );
};

IrrigationPlanCalendar.propTypes = {
  langId: PropTypes.string.isRequired,
  showDialogFromFab: PropTypes.bool.isRequired,
  fabCloseModal: PropTypes.func.isRequired,
  areaDetail: PropTypes.object.isRequired,
  areaPrograms: PropTypes.array.isRequired,
  areaProgram: PropTypes.object.isRequired,
  isFetchingAreaProgram: PropTypes.bool.isRequired,
  setAreaProgram: PropTypes.func.isRequired,
  resetAreaProgram: PropTypes.func.isRequired,
  fetchAreaProgram: PropTypes.func.isRequired,
  createAreaProgram: PropTypes.func.isRequired,
  updateAreaProgram: PropTypes.func.isRequired,
  deleteAreaProgram: PropTypes.func.isRequired,
  apiError: PropTypes.bool.isRequired,
  resetApiError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  areaProgram: getAreaProgram(state),
  isFetchingAreaProgram: getIsFetchingAreaProgram(state),
  apiError: getApiError(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAreaProgram,
      resetAreaProgram,
      fetchAreaProgram,
      createAreaProgram,
      updateAreaProgram,
      deleteAreaProgram,
      resetApiError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IrrigationPlanCalendar);
