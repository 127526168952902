import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const HelpIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M21.73,1.9H8.27A1.93,1.93,0,0,0,6.34,3.82V17.29a1.94,1.94,0,0,0,1.93,1.93h3.84L15,22.1l2.89-2.88h3.84a1.94,1.94,0,0,0,1.93-1.93V3.82A1.94,1.94,0,0,0,21.73,1.9ZM16,16.62H13.9V14.49H16Zm1.66-6.52-.75.76a2.61,2.61,0,0,0-.9,2.19v.23H13.9v-.65a3.58,3.58,0,0,1,1-2.52l1-1a1.37,1.37,0,0,0,.43-1,1.43,1.43,0,0,0-2.86,0v.23H11.39V8.06a3.57,3.57,0,1,1,7.13,0A2.82,2.82,0,0,1,17.69,10.1Z" />
    </svg>
  </SvgIcon>
);

export default HelpIcon;
