import React from 'react';

import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfDatePickerTranslator from '../CfDatePickerTranslator/CfDatePickerTranslator';

const style = {
  positionEnd: {
    position: 'absolute',
    right: '-42px',
  },
};

function CfFormikDatePicker(props) {
  const {
    children,
    classes,
    field,
    form,
    format,
    intl: { formatMessage },
    maxDateMessage,
    minDateMessage,
    propagateInvalidDate,
    validateOnBlur,
    ...restProps } = props;
  const error = form.errors[field.name];
  return (
    <CfDatePickerTranslator
      cancelLabel={formatMessage({ id: 'common.cancel' })}
      clearLabel={formatMessage({ id: 'common.clear' })}
      error={Boolean(error)}
      format={format}
      helperText={error}
      invalidDateMessage={''}
      maxDateMessage
      minDateMessage
      momentLocale={moment().locale()}
      name={field.name}
      okLabel={formatMessage({ id: 'common.select' })}
      placeholder={moment().startOf('year').format('L')}
      value={field.value}
      {...restProps}
      onChange={inputVal => {
        const isBeingTriggeredInForm = field.name === 'date';
        const isUserInputValid = (moment.isMoment(inputVal) && inputVal.isValid());

        const isCurrentFormValueDate = moment.isMoment(field.value);

        if (propagateInvalidDate || inputVal === null || isUserInputValid) {
          form.setFieldValue('date', inputVal);
        } else if (isCurrentFormValueDate && isBeingTriggeredInForm) {
          // [CFD-206] Add {else if} for fixing bug with DataPicker keyboard editing
          form.setFieldValue('date', null);
        }
      }}
    >
      {children}
    </CfDatePickerTranslator>
  );
}

CfFormikDatePicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  format: PropTypes.string,
  minDateMessage: PropTypes.any,
  maxDateMessage: PropTypes.any,
  propagateInvalidDate: PropTypes.bool,
};

CfFormikDatePicker.defaultProps = {
  children: null,
  validateOnBlur: false,
  format: 'MM/DD/YYYY',
  minDateMessage: PropTypes.any,
  maxDateMessage: PropTypes.any,
  propagateInvalidDate: false,
};

export default injectIntl(withStyles(style)(CfFormikDatePicker));
