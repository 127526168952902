import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfTextFilter from '../../../../../shared/containers/CfTextFilter/CfTextFilter';

const styles = theme => {
  const { breakpoints } = theme;
  return {
    wrapper: {
      margin: 0,
    },
    [breakpoints.up('sm')]: {
      wrapper: {
        margin: theme.spacing(1),
      },
    },
  };
};

export const ParcelListTextFilter = props => {
  const { classes, namespace, textFilter } = props;
  return (
    <div className={classes.wrapper}>
      <CfTextFilter
        initialValue={textFilter}
        name="parcel-list-text-filter"
        namespace={namespace}
        translId={'ParcelListTextFilter.placeholder'}
      />
    </div>
  );
};

ParcelListTextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  textFilter: PropTypes.string,
  namespace: PropTypes.string.isRequired,
};

ParcelListTextFilter.defaultProps = {
  textFilter: undefined,
};

export default withStyles(styles)(ParcelListTextFilter);
