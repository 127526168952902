import React from 'react';

import countBy from 'lodash/countBy';
import every from 'lodash/every';
import get from 'lodash/get';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { isEph, isInvalidCatchCropApplication } from '../../eph/misc/eph.helpers';
import { isOtherAction } from '../../others/helpers/others.helpers';
import { isVrf } from '../../vrf/helpers/vrf.helpers';
import { isVrs } from '../../vrs/helpers/vrs.helpers';

export const targetCropChanged = (oldTargetCrop, newTargetCrop) =>
  newTargetCrop?.legislativeCode !== oldTargetCrop?.legislativeCode;

// deprecated, because it was not reliable
export const targetCropChangedManually = (metaTargetCrop, newTargetCrop) =>
  Boolean(metaTargetCrop?.touched && newTargetCrop);

export const parcelsCountChanged = (oldParcels, newParcels) => newParcels.length !== oldParcels.length;

export const expenseCountChanged = (oldExpenses, newExpenses) => newExpenses.length !== oldExpenses.length;

export const parcelsAreaChanged = (oldParcelsArea, newParcelsArea) => oldParcelsArea !== newParcelsArea;

export const getFertilizers = expenses =>
  (expenses ? expenses.filter(expense => expense.material.materialTypeId === 'FR') : []);

export const getFertilizersDosage = expenses => sumBy(getFertilizers(expenses), 'dosage');

export const fertilizersDosageChanged = (oldExpenses, newExpenses) =>
  getFertilizersDosage(oldExpenses) !== getFertilizersDosage(newExpenses);

export const fertilizationTypeChanged = (oldIsCatchCrop, newIsCatchCrop, oldIsStrawDecay, newIsStrawDecay) =>
  oldIsCatchCrop !== newIsCatchCrop || oldIsStrawDecay !== newIsStrawDecay;

export const actionDateChanged = (oldActionDate, newActionDate) => !moment(newActionDate).isSame(moment(oldActionDate));

export const fertilizersUnitChanged = (oldExpenses, newExpenses) => {
  const oldFertilizers = getFertilizers(oldExpenses);
  const newFertilizers = getFertilizers(newExpenses);

  return newFertilizers.some(nextFert => {
    const currFert = oldFertilizers.find(f => f.material.id === nextFert.material.id);
    if (!currFert) {
      return false;
    }

    return currFert.doseUnit?.id !== nextFert.doseUnit?.id || currFert.dosageUnit?.id !== nextFert.dosageUnit?.id;
  });
};

export const parcelsRestrictedAreaChanged = (oldParcels, newParcels) =>
  newParcels.some(newParcel => {
    const currParcel = oldParcels.find(p => p.id === newParcel.id);
    if (!currParcel) {
      return false;
    }

    return currParcel.restrictedArea !== newParcel.restrictedArea;
  });

export const tryAsyncValuesValidation = (existingAction, validateAction, dto) => {
  if (existingAction) {
    dto.id = existingAction.id;
  }
  return validateAction(dto);
};

export const resolveTargetCrop = parcels => (parcels ? get(parcels[0], 'currentSeedApplication.seed.crop') : undefined);

export const isAllParcelsSameCrop = (parcelList, cropPredicate) => {
  const numParcelsByCropCodes = countBy(parcelList, 'currentSeedApplication.seed.crop.legislativeCode');
  return size(numParcelsByCropCodes) === 1 && (cropPredicate ? every(parcelList, cropPredicate) : true);
};

export const warnTargetCrop = values => {
  let result = null;
  const { parcels, targetCrop } = values;

  if (targetCrop) {
    //  TODO make for all action types
    const allParcelsSameCrop = isAllParcelsSameCrop(parcels);
    const defaultTargetCrop = resolveTargetCrop(parcels);
    if (
      defaultTargetCrop &&
      (defaultTargetCrop.legislativeCode !== targetCrop.legislativeCode || !allParcelsSameCrop)
    ) {
      result = {
        // advanced error structure supported by some Cf-components (like CfSelector)
        icon: 'info',
        message: (
          <FormattedMessage
            id={allParcelsSameCrop ? 'action.targetCropWarning' : 'action.targetCropWarningDiffCrops'}
            values={{
              crop: targetCrop.name,
            }}
          />
        ),
      };
    }
  }

  return result;
};

export const validateParcels = values => {
  const { parcels } = values;
  return !parcels || !parcels.length ? { _error: <FormattedMessage id="ParcelControl.chooseParcel" /> } : null;
};

export const validateActionDate = values => {
  const { actionDate } = values;
  return !actionDate ? { _error: <FormattedMessage id="action.selectDate" /> } : null;
};

export const validateExpenses = (values, msg = 'ActionExpensesControl.chooseMaterial') => {
  const { expenses } = values;
  return !expenses || !expenses.length ? { _error: <FormattedMessage id={msg} /> } : null;
};

export const validateMinDose = values => {
  const { maxDose, minDose } = values;
  return maxDose && minDose >= maxDose
    ? { _error: <FormattedMessage id="VariableFertilization.minDoseValidation" /> }
    : null;
};

export const validateMaxDose = values => {
  const { maxDose, minDose } = values;
  return minDose && maxDose <= minDose
    ? { _error: <FormattedMessage id="VariableFertilization.maxDoseValidation" /> }
    : null;
};

export const validateTargetCrop = values => {
  const { parcels, targetCrop } = values;

  if (!targetCrop) {
    return { _error: <FormattedMessage id="validation.required" /> };
  }

  return isInvalidCatchCropApplication(parcels, targetCrop)
    ? {
      message: <FormattedMessage id="Eph.catchCropParcelsError" />,
    }
    : null;
};

export const variableFertilizersDosageChanged = (oldExpenses, newExpenses) =>
  newExpenses.some(newExpense => {
    const currExpense = oldExpenses.find(e => e.material?.id === newExpense.material?.id);
    if (!currExpense) {
      return false;
    }

    return currExpense.variableExpense?.totalDose !== newExpense.variableExpense?.totalDose;
  });

export const zoneTotalDoseChanged = (oldZones, newZones) =>
  newZones.some(newZone => {
    const currZone = oldZones.find(z => z.zoneId === newZone.zoneId);
    if (!currZone) {
      return false;
    }

    return currZone.totalDose !== newZone.totalDose;
  });

export const getRouting = (action, variableActionIds) =>
  ((isEph(action, variableActionIds)
    || isVrf(action, variableActionIds)
    || isVrs(action, variableActionIds)
    || isOtherAction(action)) ?
    'actions.action' : 'action');
