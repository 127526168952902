/**
 * Created by brasko on 24.7.2015.
 */


/*@ngInject*/
export default function cfCheckboxAll() {

    var directive = {
        restrict: 'E',
        template: require('./cf-checkbox-all.html'),
        scope: {
            items: '=',
            checkboxes: '=',
            elemId: '='
        },
        link: function(scope){

            scope.hasChecked = false;

            scope.$watch( function() { return scope.checkboxes.checked; }, function(newValue, oldVal) {
                if (newValue !== oldVal) {
                    angular.forEach(scope.items, function(item) {
                        if (angular.isDefined(item.id)) {
                            scope.checkboxes.items[item.id] = newValue;
                        }
                    });
                }
            });

            // watch for data checkboxes
            scope.$watch( function() { return scope.checkboxes.items; }, function() {
                if (!scope.items || scope.items === 0) {
                    return;
                }
                var checked = 0,
                    unchecked = 0,
                    total = scope.items.length;
                angular.forEach(scope.items, function(item) {
                    checked   +=  (scope.checkboxes.items[item.id]) || 0;
                    unchecked += (!scope.checkboxes.items[item.id]) || 0;
                });
                if ((unchecked === 0) || (checked === 0)) {
                    scope.checkboxes.checked = (checked === total);
                }
                // grayed checkbox
                angular.element(document.getElementById(scope.elemId)).prop("indeterminate", (checked !== 0 && unchecked !== 0));

                scope.hasChecked = (checked > 0) ? true : false;

                //scope.checked = getCheckedItems();

            }, true);


           /* function getCheckedItems() {
                var itemsToBeProcessed = [];
                _.each(scope.items, function (item) {
                    if (scope.checkboxes.items[item.id]) {
                        itemsToBeProcessed.push(item);
                    }
                });
                return itemsToBeProcessed;
            }*/


        }
    };
    return directive;

    //////////////////////////
}
