import * as types from './management.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    item: {},
    isFetchingActivate: false,
    errorActivating: {},
    activated: false,
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_MANAGEMENT_ZONES:
      return {
        ...state,
        isFetching: true,
        error: {},
        errorActivating: {},
      };
    case types.GET_MANAGEMENT_ZONES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_MANAGEMENT_ZONES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.ACTIVATE_MANAGEMENT_ZONES:
      return {
        ...state,
        isFetchingActivate: true,
        errorActivating: {},
      };
    case types.ACTIVATE_MANAGEMENT_ZONES_SUCCESS:
      return {
        ...state,
        isFetchingActivate: false,
        activated: true,
      };
    case types.ACTIVATE_MANAGEMENT_ZONES_ERROR:
      return {
        ...state,
        isFetchingActivate: false,
        errorActivating: action.payload,
      };
    case types.RESET_MANAGEMENT_ZONES:
      return {
        ...state,
        isFetching: false,
        error: {},
        item: {},
      };
    default:
      return state;
  }
};
