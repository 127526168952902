import React, { FC } from 'react';

import { Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import TelematicsParcelSelector from '../TelematicsParcelSelector/TelematicsParcelSelector';

import { Parcel } from '../../../shared/api/core/parcels/parcels.types';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.grey[500],
  },
  parcel: {
    fontWeight: 'normal',
  },
}));

interface Props {
  isEditing: boolean;
  isFetchingParcel: boolean;
  parcel?: Parcel | null;
  setParcelId(parcelId: string | null | undefined): void;
}

const TelematicsParcel: FC<Props> = ({
  isEditing,
  isFetchingParcel,
  parcel,
  setParcelId,
}) => {
  const classes = useStyles();

  const handleChangeParcel = (value: Parcel | null) => {
    setParcelId(value?.id);
  };

  const handleRemoveParcel = () => {
    setParcelId(null);
  };

  const getCropLabel = () => {
    const seedApplication = parcel?.actionSeedApplication ?? parcel?.currentSeedApplication;
    return seedApplication?.seedApplicationType === 'CATCH_CROP' ? 'common.intercrop' : 'common.crop';
  };

  if (isFetchingParcel) {
    return (
      <>
        <div className={classes.label}>
          <FormattedMessage id="common.parcel" />:
        </div>
        <CircularProgress color="primary" />
      </>
    );
  }

  if (parcel) {
    return (
      <>
        <div className={classes.label}>
          <FormattedMessage id="common.parcel" />:
        </div>
        <SelectionItem
          editing={isEditing}
          onRemoveItem={handleRemoveParcel}
        >
          <Grid container spacing={0}>
            <Grid item sm={3} xs={12}>
              <SelectionItemHeading
                reversed={true}
                subheading={parcel.blockNr}
              >
                <span className={classes.parcel}>{parcel.localName}</span>
              </SelectionItemHeading>
            </Grid>
            <Grid item sm={2} xs={6}>
              <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
                <div>
                  <CfFormattedNumber value={parcel.area} />
                </div>
              </SelectionItemColumn>
            </Grid>
            <Grid item sm={3} xs={6}>
              <SelectionItemColumn label={<FormattedMessage id={getCropLabel()} />}>
                <div>
                  {parcel.actionSeedApplication
                    ? parcel.actionSeedApplication.seed.name
                    : parcel.currentSeedApplication.seed.name}
                </div>
              </SelectionItemColumn>
            </Grid>
          </Grid>
        </SelectionItem>
      </>
    );
  }

  return (
    <TelematicsParcelSelector
      isEditing={isEditing}
      label={<FormattedMessage id="TelematicsList.selectParcel" />}
      onChange={handleChangeParcel}
    />
  );
};

export default TelematicsParcel;
