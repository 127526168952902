import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import SubtractableArea from '../SubtractableArea/SubtractableArea';

const styles = theme => ({
  saRow: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

function SubtractableAreaWithCheckbox(props) {
  const {
    classes,
    field,
    form,
    handleItemRemove,
    isEditing,
    parcelIndex,
    validateOnBlur,
    ...restProps
  } = props;
  return (
    <div className={props.classes.saRow}>
      <Checkbox
        {...restProps}
        checked={field.value?.checked === true}
        color="primary"
        disabled={!isEditing}
        type="checkbox"
        onChange={(event, value) => {
          form.setFieldValue(field.name, { ...field.value, checked: value });
        }}
      />
      <SubtractableArea sa={field.value} />
    </div>
  );
}

SubtractableAreaWithCheckbox.propTypes = {
  parcelIndex: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  isEditing: PropTypes.bool,
  handleItemRemove: PropTypes.func,
};

SubtractableAreaWithCheckbox.defaultProps = {
  isEditing: false,
  validateOnBlur: false,
  handleItemRemove: () => {},
};

export default withStyles(styles)(SubtractableAreaWithCheckbox);
