/**
 * Created by ondrejzvara on 10.4.16. Inspired by: https://github.com/giannico/ag-logger/blob/master/src/logger.service.js
 */


/*@ngInject*/
export default function LogLevelManager(LOG_LEVELS, LoggerFncProvider) {

    var currLogLevel;

    var service = {
        setLogLevel: setLogLevel,
        getLogLevel: getLogLevel
    };
    return service;

    /////////////////////////

    function setLogLevel(logLevelName, service) {
        currLogLevel = getLogLevelObj(logLevelName);
        refreshLogFns(service);
    }

    function getLogLevel() {
        return currLogLevel;
    }

    function getLogLevelObj(logLevelName) {
        if (logLevelName === null) {
            throw new Error('Provided logLevelName cannot be null!');
        }

        var foundLogLevel = null;

        angular.forEach(LOG_LEVELS, function(value, key) {
            if (value.name === logLevelName) {
                foundLogLevel = LOG_LEVELS[key];
            }
        });

        if (foundLogLevel === null) {
            throw new Error('Invalid log level name provided: ' + logLevelName);
        }

        return foundLogLevel;
    }

    function refreshLogFns(service) {
        service.printa = getLogFn(LOG_LEVELS.PRINTA);
        service.error = getLogFn(LOG_LEVELS.ERROR);
        service.warn = getLogFn(LOG_LEVELS.WARN);
        service.info = getLogFn(LOG_LEVELS.INFO);
        service.log = getLogFn(LOG_LEVELS.LOG);
        service.debug = getLogFn(LOG_LEVELS.DEBUG);
    }

    function getLogFn(logLevelObj) {
        if (!willLog(logLevelObj.name)) { return angular.noop; }
        return LoggerFncProvider[logLevelObj.name];
    }

    function willLog(logLevelName) {
        var logLevelObj = getLogLevelObj(logLevelName);
        return logLevelObj.value >= currLogLevel.value;
    }

}
