/**
 * Created by ondrejzvara on 6.11.15.
 */


/*@ngInject*/
export default function Validation($validation) {

    var service = {
        configure: configure,
        checkForm: checkForm,
        validate: validate,
        reset: reset
    };
    return service;

    /////////////////////////

    function configure(config){
        if(config){
            $validation.showErrorMessage = config.hasOwnProperty("showErrorMessage") ? config.showErrorMessage : $validation.showErrorMessage;
        }
    }

    function checkForm(form){

        if(!_.isObject(form) || _.isEmpty(form)){
            return false;
        }

        return $validation.checkValid(form);
    }

    function validate(form){

        if(!_.isObject(form) || _.isEmpty(form)){
            return false;
        }

        return $validation.validate(form);
    }
    
    function reset(form){
        
        if(!_.isObject(form) || _.isEmpty(form)){
            return false;
        }

        return $validation.reset(form);
        
    }
}