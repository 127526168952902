import React, { Fragment } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfFormattedNumber from '../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';

const styles = theme => ({
  measurementItem: {
    display: 'flex',
    borderRadius: 20,
  },
  measurementValues: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: 10,
    lineHeight: '28px',
  },
  dividerWrapper: {
    width: '1px',
    height: '28px',
    padding: '3px 8px',
  },
  divider: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  removeButton: {
    padding: 0,
    width: 28,
    height: 28,
    marginLeft: '4px',
  },
});

const MeasurementItem = props => {
  const {
    classes,
    measurement: { area, length },
    onRemove,
  } = props;

  return (
    <ToolbarSection>
      <div className={classes.measurementItem}>
        <div className={classes.measurementValues} data-test={'measured-result'}>
          {(length || length === 0) && (
            <span>
              <CfFormattedNumber decimalDigits={length > 1000 ? 0 : 1} value={length} /> m
            </span>
          )}
          {(area || area === 0) && (
            <Fragment>
              <div className={`${classes.dividerWrapper}`}>
                <div className={`${classes.divider}`} />
              </div>
              <span>
                <CfFormattedNumber decimalDigits={area > 1000 ? 1 : 3} value={area} /> ha
              </span>
            </Fragment>
          )}
        </div>
        <IconButton
          aria-label="Remove measurement"
          onClick={onRemove}
          size="large"
          classes={{
            root: classes.removeButton,
          }}>
          <CancelIcon />
        </IconButton>
      </div>
    </ToolbarSection>
  );
};

MeasurementItem.propTypes = {
  classes: PropTypes.object.isRequired,
  measurement: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

MeasurementItem.defaultProps = {};

export default withStyles(styles)(MeasurementItem);
