/* eslint-disable max-len */
import React, { Fragment, Component } from 'react';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles } from '@mui/styles';
import { Field } from 'formik';
import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getPredefinedSubtractions,
  updateParcelsSubtractionsNewForm,
  getAllParcelsInZone,
} from '../../actions/actions.actions';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItemAccordion from '../../../../../shared/components/common/SelectionItemAccordion/SelectionItemAccordion';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import ValidationStatusIcon from '../../../../../shared/components/specific/ValidationStatusIcon/ValidationStatusIcon';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import * as validators from '../../../../../shared/misc/validators';
import { isParcelSown } from '../../../others/helpers/others.helpers';
import TotalActivityArea from '../../components/TotalActivityArea/TotalActivityArea';
import ActionsService from '../../services/Actions.service';
import ActionParcelSubtractableAreas from '../ActionParcelSubractableAreas/ActionParcelSubtractableAreas';
import ParcelsZoneAutocompleteSelector from '../ParcelsZoneAutocompleteSelector/ParcelsZoneAutocompleteSelector';

const styles = theme => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: '12px 24px',
    overflow: 'hidden',
    marginTop: 10,
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: 'right',
  },
  parcelSelector: {
    marginBottom: 15,
  },
  warning: {
    position: 'relative',
    top: 10,
  },
  area: {
    display: 'flex',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
});
export class ParcelsControl extends Component {
  constructor(props) {
    super(props);
    const { form } = this.props;
    const parcelIds = form.values.parcels.map(p => p.id);
    if (props.isExisting) this.updateParcelSubtractions(parcelIds, form.values.parcels, form);
  }

  componentDidUpdate(prevProps) {
    const { form } = this.props;
    const { form: prevForm } = prevProps;
    const parcelIds = form.values.parcels.map(p => p.id);
    if ((form.values.parcels.length !== prevForm.values.parcels.length) && prevForm.values.parcels.length) {
      // update subtractable areas already in form, hence calling with prevForm.values
      this.updateParcelSubtractions(parcelIds, prevForm.values.parcels, form);
    }
    if (form.values.parcels.length > prevForm.values.parcels.length) {
      const parcelsAdded = differenceBy(form.values.parcels.length, prevForm.values.parcels.length, 'id');
      parcelsAdded.forEach(parcel => {
        const index = form.values.parcels.findIndex(option => option.id === parcel.id);
        this.props.getPredefinedSubtractions(parcel.id, parcelIds).then(predefinedSas => {
          const mappedParcel = {
            ...parcel,
            subtractableAreas: this.mapSubtractableAreas(parcel, predefinedSas),
          };
          form.setFieldValue(`parcels.${index}.subtractableAreas`, mappedParcel);
        });
      });
    }
  }

  updateParcelSubtractions(parcelIds, parcels, form) {
    this.props.updateParcelsSubtractionsNewForm(parcelIds, parcels).then((updatedParcels) => {
      updatedParcels.forEach(parcel => {
        const index = form.values.parcels.findIndex(option => option.id === parcel.id);
        form.setFieldValue(`parcels.${index}.subtractableAreas`, parcel.subtractableAreas);
      });
    });
  }

  handleSuggestionSelected = selected => {
    const isZone = 'parcelCount' in selected;
    const { form } = this.props;
    if (isZone) {
      this.props.getAllParcelsInZone(selected).then(parcelsInZone => {
        if (parcelsInZone.length) {
          const parcelsInForm = form.values.parcels;
          const parcelsInFormIds = parcelsInForm.map(p => p.id);
          const checkedParcels = this.checkSelectedParcelsAgainstParcelsInZone(parcelsInZone, parcelsInFormIds);
          form.setFieldValue('parcels', parcelsInForm.concat(checkedParcels.map(p => this.initializeParcel(p))));
        }
      });
    } else {
      this.props.handleInsert(this.initializeParcel(selected));
    }
  };

  checkSelectedParcelsAgainstParcelsInZone = (
    parcelsInZone,
    parcelsInFormIds,
  ) => (
    parcelsInZone.filter(parcelInZone => !(parcelsInFormIds.some(id => parcelInZone.id === id)))
  );

  // call predefined subtractions when parcel is added and map them
  initializeParcel = (parcel) => ({
    ...parcel,
    subtractableAreas: this.initializeSubtractableAreas(),
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  initializeSubtractableAreas = () => {
    const subtractableAreas = {
      absolute: [],
      boundary: [],
      water: [],
    };
    subtractableAreas.boundaryChecked = 0;
    subtractableAreas.waterChecked = 0;
    return subtractableAreas;
  };

  mapSubtractableAreas = (parcel, predefinedSas) => ({
    absolute: predefinedSas.filter(sa => sa.restrictionType === 'Absolute'),
    boundary: predefinedSas.filter(sa => sa.restrictionType === 'Boundary'),
    water: [predefinedSas.filter(sa => sa.restrictionType === 'Water')],
    boundaryChecked: parcel.subtractableAreas.boundaryChecked,
    waterChecked: parcel.subtractableAreas.water.checked,
  });

  handleItemRemove = index => {
    this.props.handleRemove(index);
  };

  render() {
    const { allMustBeSown, classes, form, formType, isEditing, isExisting, placeholder } = this.props;
    const parcels = form.values.parcels;
    const parcelsArea = ActionsService.getTotalArea(parcels, formType, isExisting);
    const { errors } = form;
    const fieldError = errors.parcels;
    const isParcelSelected = parcels.length > 0;

    return (
      <Fragment>
        {!isEditing ? (
          <div className={classes.parcelsLabel}>
            <FormattedMessage id="common.parcels" />
          </div>
        ) : (
          <div className={classes.parcelSelector}>
            <ParcelsZoneAutocompleteSelector
              formType={this.props.formType}
              label={<FormattedMessage id={placeholder} />}
              onChange={this.handleSuggestionSelected}
              parcels={parcels}
            />
          </div>
        )}
        {fieldError && <div className={classes.error}><FormattedMessage id="validation.required" tagName="span" /></div>}
        {parcels.map((parcel, index) => {
          const isSown = isParcelSown(parcel);
          return (
            <SelectionItemAccordion
              editing={isEditing}
              formType={this.props.formType}
              isExisting={isExisting}
              isSown={isSown}
              key={parcel.id}
              details={
                <ActionParcelSubtractableAreas
                  form={form}
                  isEditing={isEditing}
                  maxValue={parcel.area}
                  name={`parcels.${index}`}
                  parcel={parcel}
                  parcelId={parcel.id}
                  parcelIds={parcels.map(p => p.id)}
                  parcelIndex={index}
                  subtractableAreas={parcel.subtractableAreas}
                />
              }
              onRemoveItem={() => {
                this.handleItemRemove(index);
              }}
            >
              <Grid container spacing={0}>
                <Grid item sm={4} xs={12}>
                  <Link to={`/farm/${this.props.farm.id}/parcels/${parcel.id}/overview`}>
                    <SelectionItemHeading
                      classes={{ subheading: this.props.classes.subheading }}
                      reversed={true}
                      subheading={parcel.blockNr}
                    >
                      <div>{parcel.localName}</div>
                    </SelectionItemHeading>
                  </Link>
                </Grid>
                <Grid item sm={4} xs={6}>
                  <SelectionItemColumn
                    classes={{ content: classes.area }}
                    label={<FormattedMessage id="common.area-ha" />}
                  >
                    <Fragment>
                      {(allMustBeSown && !isSown)
                        ? <CfFormattedNumber decimalDigits={2} value={0} />
                        : <Field
                            name={`parcels.${index}.actionParcelTotalArea`}
                            validate={validators.requiredAndPositiveNumber}
                        >
                          {({
                            field,
                          }) => <CfFormattedNumber decimalDigits={2} value={field.value} />}
                        </Field>
                      }
                      {' / '}
                      <CfFormattedNumber decimalDigits={2} value={parcel.area} />
                      {form.errors.parcels &&
                    form.errors.parcels[index]?.actionParcelTotalArea &&
                    <InputAdornment classes={{ positionEnd: classes.warning }} position="end">
                      <ValidationStatusIcon id={`parcels.${index}.actionParcelTotalArea`} type={'warning'}>
                        {form.errors.parcels[index]?.actionParcelTotalArea}
                      </ValidationStatusIcon>
                    </InputAdornment>}
                    </Fragment>
                  </SelectionItemColumn>
                </Grid>
                <Grid item sm={4} xs={6}>
                  <SelectionItemColumn label={<FormattedMessage id={ActionsService.getCropLabel(parcel)} />}>
                    <div>
                      {parcel.actionSeedApplication
                        ? parcel.actionSeedApplication.seed.name
                        : parcel.currentSeedApplication.seed.name}
                    </div>
                  </SelectionItemColumn>
                </Grid>
              </Grid>
            </SelectionItemAccordion>
          );
        })}
        {isParcelSelected && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item lg={6} md={7} sm={8} xs={12}>
              <TotalActivityArea parcelsArea={parcelsArea} />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

ParcelsControl.propTypes = {
  farm: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleInsert: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  allMustBeSown: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  getPredefinedSubtractions: PropTypes.func.isRequired,
  updateParcelsSubtractionsNewForm: PropTypes.func.isRequired,
  getAllParcelsInZone: PropTypes.func.isRequired,
};

ParcelsControl.defaultProps = {
  placeholder: 'ParcelZoneSelector.placeholder',
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllParcelsInZone,
      getPredefinedSubtractions,
      updateParcelsSubtractionsNewForm,
    },
    dispatch,
  );

export default compose(connect(null, mapDispatchToProps), withFarm(), withStyles(styles), injectIntl)(ParcelsControl);
