import React, { Component, Fragment } from 'react';

import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import { Field } from 'formik';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { calculateAndGetSubtractableArea, calculateAllSubtractableAreas } from '../../actions/subtractableAreas.actions';

import AbsoluteParcelSubtractableAreas from '../../components/AbsoluteParcelSubtractableAreas/AbsoluteParcelSubtractableAreas';
import BoundaryParcelSubtractableAreas from '../../components/BoundaryParcelSubtractableAreas/BoundaryParcelSubtractableAreas';
import WaterParcelSubtractableAreas from '../../components/WaterParcelSubtractableAreas/WaterParcelSubtractableAreas';

const styles = {
  divider: {
    margin: 10,
  },
};

class ActionParcelSubtractableAreas extends Component {
  componentDidUpdate(prevProps) {
    const { form, intl, parcel, parcelId, parcelIds, parcelIndex: index } = this.props;
    const { parcel: prevParcel } = prevProps;
    const prevSubtractableAreas = prevParcel.subtractableAreas;
    const subtractableAreas = parcel.subtractableAreas;
    if (prevSubtractableAreas !== subtractableAreas) {
      const absoluteChecked = parcel.subtractableAreas.absolute.filter(sa => sa.checked === true);
      const boundaryChecked = {
        parcelId,
        restrictionType: 'Boundary',
        restrictionValue: toNumber(parcel.subtractableAreas.boundaryChecked || 0),
      };
      const waterChecked = {
        parcelId,
        restrictionType: 'Water',
        restrictionValue: toNumber(parcel.subtractableAreas.waterChecked || 0),
      };
      const parcelsSubtractionTos = [...absoluteChecked, boundaryChecked, waterChecked];
      this.props.calculateAllSubtractableAreas(parcelId, parcelsSubtractionTos, parcelIds).then(area => {
        form.setFieldValue(`parcels.${index}.actionParcelTotalArea`, Math.max(area, 0));
        if (area <= 0) {
          form.setFieldError(`parcels.${index}.actionParcelTotalArea`, intl.formatMessage({ id: 'SubtractableArea.error' }));
        } else {
          form.setFieldError(`parcels.${index}.actionParcelTotalArea`, undefined);
        }
      });
    }
  }

  render() {
    const { classes, isEditing, maxValue, name, parcelId, parcelIds, parcelIndex } = this.props;
    return (
      <Fragment>
        <Field data-test={'subtraction-section'} name={`${name}.subtractableAreas`}>
          {({ form }) => (
            <Fragment>
              <AbsoluteParcelSubtractableAreas
                calculateAndGetSubtractableArea={this.props.calculateAndGetSubtractableArea}
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
              <Divider className={classes.divider} />
              <BoundaryParcelSubtractableAreas
                calculateAndGetSubtractableArea={this.props.calculateAndGetSubtractableArea}
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
              <Divider className={classes.divider} />
              <WaterParcelSubtractableAreas
                calculateAndGetSubtractableArea={this.props.calculateAndGetSubtractableArea}
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
            </Fragment>
          )}
        </Field>
      </Fragment>
    );
  }
}

ActionParcelSubtractableAreas.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  parcel: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  calculateAndGetSubtractableArea: PropTypes.func.isRequired,
  calculateAllSubtractableAreas: PropTypes.func.isRequired,
};

ActionParcelSubtractableAreas.defaultProps = {
  classes: {},
  formValues: {},
  isEditing: false,
  maxArea: 0,
  parcelId: '',
  subtractableArea: {},
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      calculateAndGetSubtractableArea,
      calculateAllSubtractableAreas,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(ActionParcelSubtractableAreas)));
