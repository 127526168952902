import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import irrigationAdminUI from '../admin/manager/irrigation/reducer/admin.devices.reducer';
import notificationsAdminUI from '../admin/manager/notifications/reducer/notifications.reducer';
import sensorsAdminUI from '../admin/manager/sensors/reducer/sensors.reducer';
import sentinelAdminUI from '../admin/manager/sentinel/reducer/sentinel.reducer';
import adminManagerUI from '../admin/manager/shared/reducer/manager.reducer';
import telematicsAdminUI from '../admin/manager/telematics/reducer/telematicsAdmin.reducer';
import notificationsAdminApi from '../shared/api/automation/notificationsAdmin/notificationsAdmin.reducer';
import farmsApi from '../shared/api/core/farms/farms.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import nodesApi from '../shared/api/iot/nodes/nodes.reducer';
import sensorsApi from '../shared/api/iot/sensors/sensors.reducer';
import devicesApi from '../shared/api/irrigation/devices/devices.reducer';
import adminSentinelApi from '../shared/api/sentinel/admin/admin.reducer';
import telematicsAdminApi from '../shared/api/telematics/admin/telematicsAdmin.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      manager: adminManagerUI(),
      iot_admin: sensorsAdminUI(),
      sentinel_admin: sentinelAdminUI(),
      notifications_admin: notificationsAdminUI(),
      irrigation_admin: irrigationAdminUI(),
      telematics: telematicsAdminUI(),
    }),
    api: combineReducers({
      nodes: nodesApi(),
      sensors: sensorsApi(),
      devices: devicesApi(),
      farms: farmsApi(),
      notificationsAdmin: notificationsAdminApi(),
      sentinelParcels: adminSentinelApi('table'),
      parcelsSuggestions: adminSentinelApi('suggestions'),
      telematics: telematicsAdminApi,
    }),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
