import angular from 'angular';

import ActionsController from './actions.controller';

const actionsComponent = {
  bindings: {
    farmId: '<',
    farm: '<',
    parcelIds: '<',
    zoneIds: '<',
  },
  controller: ActionsController,
};

export default angular
  .module('app.core.actions', [])
  .config(config)
  .component('actionsComponent', actionsComponent).name;

config.$inject = ['$stateProvider', '$urlRouterProvider'];

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/farm/:farmId/actions2', '/farm/:farmId/actions');
  $urlRouterProvider.when('/farm/:farmId/actions2/eph', '/farm/:farmId/actions/eph');

  $stateProvider
    .state('farm.active.actions', {
      url: '/actions',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
    })
    .state('farm.active.actions.action', {
      url: '/:actionId',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
    })
    .state('farm.active.actions.eph', {
      url: '/eph',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.otherNew', {
      url: '/other',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.mowing', {
      url: '/mowing',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.sowing', {
      url: '/sowing',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.harvest', {
      url: '/harvest',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.vrf', {
      url: '/variable-fertilization',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    })
    .state('farm.active.actions.vrs', {
      url: '/variable-seeding',
      views: {
        'farm@farm.active': {
          component: 'actionsComponent',
        },
      },
      params: {
        parcelIds: null,
        zoneIds: null,
      },
      resolve: {
        parcelIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.parcelIds;
          },
        ],
        zoneIds: [
          '$stateParams',
          function ($stateParams) {
            return $stateParams.zoneIds;
          },
        ],
      },
    });
}
