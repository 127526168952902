import PropTypes from 'prop-types';

import consumerFactory from './helpers/consumerFactory';
import contextFactory from './helpers/contextFactory';
import providerFactory from './helpers/providerFactory';

const propName = 'farm';
const hocName = 'withFarm';
const componentPrefix = 'CfFarm';

const propTypes = {
  [propName]: PropTypes.shape({
    boundingBox: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
    crs: PropTypes.number.isRequired,
    customer: PropTypes.object.isRequired,
    farmSettings: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    localSrid: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const ctx = contextFactory(componentPrefix);
const Provider = providerFactory(ctx, propName, propTypes);
const Consumer = consumerFactory(ctx.Consumer, propName, componentPrefix, hocName);

export const CfFarmProvider = Provider;
export default Consumer;
