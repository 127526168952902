import { AnyAction } from 'redux';
import { RSAAAction } from 'redux-api-middleware';

import {
  getSensorsAdminPage,
  getSensorsAdminOrder,
  getSensorsAdminOrderBy,
  getSensorsAdminRowsPerPage,
  getSensorsAdminTextFilter,
  getSensorsAdminDisplayInactive,
  getSensorsAdminStatusFilter,
  getSensorsAdminNodeTypeFilter,
  getSensorsAdminNetworkTypeFilter,
  getSensorsAdminCountryFilter,
  getSensorsAdminFarmFilter,
} from '../selectors/sensors.selectors';

import { setTableSelected } from '../../../../shared/actions/table.actions';

import * as types from './sensors.constants';

import { NAMESPACE as namespace } from '../reducer/sensors.reducer';

import {
  getNodes as getNodesApi,
  createNode as createNodeApi,
  getDefaultFarm as getDefaultFarmApi,
  patchNode as patchNodeApi,
  resetNodes,
  getNodeTypes,
  resetNodeTypes,
  resetDefaultFarm,
  getNetworkTypes,
  resetNetworkTypes,
  exportNodes as exportNodesApi,
  getAdminAvailableBaraniBasesApi,
} from '../../../../shared/api/iot/nodes/nodes.api';
import { patchSensor as patchSensorApi } from '../../../../shared/api/iot/sensors/sensors.api';
import FileService from '../../../../shared/services/File.service';

import { SensorsAdminNode, AdminFarm, CreateNodeValues, SensorsAdminState, AdminCountry, AdminSensorsState } from '../admin.sensors.types';

export const fetchNodes = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => SensorsAdminState,
) => {
  const state = getState();
  const params = {
    page: getSensorsAdminPage(state),
    order: getSensorsAdminOrder(state),
    orderBy: getSensorsAdminOrderBy(state),
    rowsPerPage: getSensorsAdminRowsPerPage(state),
    textFilter: getSensorsAdminTextFilter(state),
    displayInactive: getSensorsAdminDisplayInactive(state),
    status: getSensorsAdminStatusFilter(state).map((item: AdminSensorsState) => item.name),
    deviceType: getSensorsAdminNodeTypeFilter(state).map((item: AdminSensorsState) => item.name),
    networkType: getSensorsAdminNetworkTypeFilter(state).map((item: AdminSensorsState) => item.name),
    countryCode: getSensorsAdminCountryFilter(state).code,
    farmIds: getSensorsAdminFarmFilter(state).id,
  };
  return dispatch(getNodesApi(params, false) as unknown as RSAAAction);
};

export const fetchNodeTypes = () => (
  dispatch: (action: RSAAAction) => void,
) => {
  dispatch(getNodeTypes() as unknown as RSAAAction);
};

export const fetchNetworkTypes = () => (
  dispatch: (action: RSAAAction) => void,
) => {
  dispatch(getNetworkTypes() as unknown as RSAAAction);
};

export const fetchDefaultFarm = () => (
  dispatch: (action: RSAAAction) => void,
) => {
  dispatch(getDefaultFarmApi() as unknown as RSAAAction);
};

export const patchSensor = (node: SensorsAdminNode, type: string) => (
  dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>,
  getState: () => SensorsAdminState,
) => {
  const sensorId = node?.sensors?.find(sensor => sensor.feature === 'Rainfall')?.id;
  dispatch(patchSensorApi(sensorId, type) as unknown as RSAAAction).then(() => {
    fetchNodes()(dispatch, getState);
  });
};

export const createNode = (values: CreateNodeValues) => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(createNodeApi(values) as unknown as RSAAAction);

export const assignNodesToFarm = (nodeIds: string[], farm: AdminFarm) => (
  dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>,
  getState: () => SensorsAdminState,
) => {
  const promises = [] as Promise<unknown>[];
  nodeIds.forEach((nodeId: string) => {
    promises.push(
      dispatch(patchNodeApi({ id: nodeId, farm }) as unknown as RSAAAction),
    );
  });
  Promise.all(promises).then(() => {
    dispatch(setTableSelected([], namespace) as unknown as RSAAAction);
    fetchNodes()(dispatch, getState);
  });
};

export const changeDeviceType = (nodeId: string[], deviceType: string) => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(patchNodeApi({ id: nodeId, deviceType }) as unknown as RSAAAction);

export const setDisplayInactive = (displayInactive: boolean) => ({
  type: types.SET_STORE_ADMIN_DISPLAY_INACTIVE,
  displayInactive,
});

export const setFarmFilter = (farm: AdminFarm) => ({
  type: types.SET_ADMIN_FARM_FILTER,
  farm,
});

export const setCountryFilter = (country: AdminCountry) => ({
  type: types.SET_ADMIN_COUNTRY_FILTER,
  country,
});

export const resetNodesApi = () => (
  dispatch: (action: AnyAction) => void,
) => {
  dispatch(resetNodes());
  dispatch(resetNodeTypes());
  dispatch(resetNetworkTypes());
  dispatch(resetDefaultFarm());
};

export const exportNodes = () => (
  dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>,
) => {
  dispatch(exportNodesApi() as unknown as RSAAAction).then(res => {
    FileService.processFileResponse(res);
  });
};

export const getAvailableBaraniBases = (textFilter: string, params: string, moduleId: string) => (
  dispatch: (action: RSAAAction) => void,
) => {
  dispatch(getAdminAvailableBaraniBasesApi({ textFilter, params, moduleId }) as unknown as RSAAAction);
};
