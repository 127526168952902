import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WeatherRadarIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M17.54,12.66a5.54,5.54,0,1,0-8.33,4.79l.92-1.58a3.72,3.72,0,1,1,3.71,0l.91,1.58A5.54,5.54,0,0,0,17.54,12.66Z" />
      <path d="M12,3.13A9.51,9.51,0,0,0,7.24,20.86l.91-1.58a7.67,7.67,0,1,1,7.66,0l.92,1.57A9.5,9.5,0,0,0,12,3.13Z" />
      <circle className="cls-1" cx="12" cy="12.66" r="1.85" />
    </svg>
  </SvgIcon>
);

export default WeatherRadarIcon;
