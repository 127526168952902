import { Parcel } from '../../core/parcels/parcels.types';
import { DriveDetailTo, DriverIdentityTo, DriverTo, EquipmentTo, LogbookItemTo, MachineTo, OperationTo, ProductionOperationTo, State, TelematicsOperation } from '../telematics.types';

export enum ActionState {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export interface GetDriversApiParams {
  dateFrom?: string;
  dateTo?: string;
}

export interface GetMachinesApiParams {
  dateFrom?: string;
  dateTo?: string;
}

export interface GetEquipmentsApiParams {
  dateFrom?: string;
  dateTo?: string;
}
export interface GetDrivesApiParams {
  dateFrom?: string;
  dateTo?: string;
  driver?: DriverIdentityTo['code'][];
  gpsUnit?: MachineTo['gpsUnit'][];
  operation?: TelematicsOperation[];
  page?: number;
  parcel?: Parcel['id'][];
  'per-page'?: number;
  productionOperation?: ProductionOperationTo['code'];
  search?: string;
  'sort-col'?: string;
  'sort-dir'?: string;
  state?: State[];
}

export interface ExportDrivesApiParams {
  dateFrom?: string;
  dateTo?: string;
  driver?: DriverIdentityTo['code'][];

  gpsUnit?: MachineTo['gpsUnit'][];
  operation?: TelematicsOperation[];
  parcel?: Parcel['id'][];
  productionOperation?: ProductionOperationTo['code'];
  search?: string;
  'sort-col'?: string;
  'sort-dir'?: string;
  state?: State[];
}

export enum ExportDrivesFormat {
  Excel = 'excel',
  Pdf = 'pdf',
}

export interface FetchError {
  message: string;
  name: string;
  stack: string;
}

export interface DrivesState {
  driveDetail?: DriveDetailTo;
  drivers: DriverTo[];
  equipments: EquipmentTo[];
  error?: FetchError;
  errorDriveDetail?: FetchError | undefined;
  errorDrivers: FetchError | undefined;
  errorEquipments: FetchError | undefined;
  errorMachines: FetchError | undefined;
  errorOperations: FetchError | undefined;
  errorProductionOperations: FetchError | undefined;
  isFetching: boolean;
  isFetchingDriveDetail: boolean;
  isFetchingDrivers: boolean;
  isFetchingEquipments: boolean;
  isFetchingMachines: boolean;
  isFetchingOperations: boolean;
  isFetchingProductionOperations: boolean;
  items: LogbookItemTo[];
  machines: MachineTo[];
  operations: OperationTo[];
  productionOperations: ProductionOperationTo[];
  totalCount: number;
}
