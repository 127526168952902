import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getRadarList, getRadarListError, getIsFetchingRadarList } from '../../../../shared/api/other/radarList/radarList.selectors';
import {
  getWeather as getWeatherApi,
  getWeatherError,
  getIsFetchingWeather,
} from '../../../../shared/api/weather/weather.selectors';
import { getContext } from '../../selectors/map.selectors';
import { getWeatherType, getWeatherCoordinates, getIsWeather, getIsRadar } from '../../selectors/weather.selectors';

import { setMapContext } from '../../actions/map/map.actions';
import {
  fetchWeather,
  fetchRadar,
  setWeatherType,
  unsetWeatherLayer,
  setWeatherCoordinatesPin,
  setRadarLayers,
  unsetRadarLayers,
  moveRadarLayer,
} from '../../actions/weather/weather.actions';

import * as contexts from '../../constants/contexts.constants';
import * as weatherTypes from '../../constants/weatherTypes.constants';

import MapBottomPanel from '../../../../shared/components/specific/MapBottomPanel/MapBottomPanel';
import MapBottomPanelHeader from '../../../../shared/components/specific/MapBottomPanelHeader/MapBottomPanelHeader';
import WeatherCurrently from '../../components/WeatherCurrently/WeatherCurrently';
import WeatherForecast from '../../components/WeatherForecast/WeatherForecast';
import WeatherRadar from '../../components/WeatherRadar/WeatherRadar';
import Weather from '../../services/Weather.service';

const styles = theme => ({
  weatherHeader: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
  },
  weatherTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  weatherBody: {
    padding: '10px 0px',
    width: '100%',
    height: 120,
  },
  weatherTypeIconDiv: {
    display: 'flex',
    marginRight: 10,
  },
  weatherTypeIcon: {
    width: 28,
    height: 28,
    maxWidth: 'inherit',
    color: theme.palette.grey[400],
  },
  spinner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  attribution: {
    position: 'absolute',
    bottom: 1,
    right: 6,
    zIndex: 2,
  },
  attributionLink: {
    fontSize: 10,
    color: '#444444',
    fontWeight: 100,
    fontStyle: 'italic',
    '&:hover': {
      textDecoration: 'none',
      color: '#000000',
    },
    '&:focus': {
      textDecoration: 'none',
      color: '#000000',
    },
  },
  unavailable: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
    fontSize: 14,
  },
});

export class WeatherControl extends Component {
  constructor(props) {
    super(props);
    this.length = 1;
  }

  componentDidMount() {
    const { coordinates, langId } = this.props;
    if (!coordinates?.length) return;

    this.props.setWeatherCoordinatesPin(coordinates);
    this.props.fetchWeather(coordinates, langId);
  }

  componentDidUpdate(prevProps) {
    const { context, coordinates, isRadar, isWeather, langId } = this.props;

    if (context === contexts.WEATHER) {
      // language switch
      if (langId !== prevProps.langId && isWeather) {
        this.props.fetchWeather(coordinates, langId);
      }

      // changing coordinates
      if (
        coordinates.length &&
        (coordinates[0] !== prevProps.coordinates[0] || coordinates[1] !== prevProps.coordinates[1])
      ) {
        this.props.setWeatherCoordinatesPin(coordinates);
        this.props.fetchWeather(coordinates, langId);
      }

      // turning on radar
      if (isRadar && prevProps.weatherType !== weatherTypes.RADAR) {
        this.props.fetchRadar();
      }
    }
  }

  render() {
    const {
      classes,
      isFetchingRadarList,
      isFetchingWeather,
      isWeather,
      radarList,
      radarListError,
      weather,
      weatherError,
      weatherType,
    } = this.props;
    const weatherTypeConfig = Weather.getWeatherTypeConfig(weatherType);
    const WeatherTypeIcon = weatherTypeConfig ? weatherTypeConfig.icon : null;

    return (
      <div>
        {weatherTypeConfig && (
          <MapBottomPanel
            onPanelClose={() => {
              this.props.setMapContext(contexts.PREVIEW);
            }}
          >
            <Fragment>
              <MapBottomPanelHeader classes={{ header: classes.weatherHeader }} testId="weather-header">
                <Fragment>
                  <div className={classes.weatherTypeIconDiv}>
                    <WeatherTypeIcon className={classes.weatherTypeIcon} />
                  </div>
                  <div className={classes.weatherTitle}>
                    <FormattedMessage id={weatherTypeConfig.translationId} />
                  </div>
                </Fragment>
              </MapBottomPanelHeader>
              <div className={classes.weatherBody} data-test="weather-body">
                {isWeather && weatherError && (
                  <div className={classes.unavailable}>
                    <FormattedMessage id="WeatherControl.weatherIsUnavailable" />
                  </div>
                )}
                {!weatherError && weatherType === weatherTypes.CURRENTLY && (
                  <WeatherCurrently currently={weather.currently} isFetching={isFetchingWeather} />
                )}
                {!weatherError && (weatherType === weatherTypes.DAY || weatherType === weatherTypes.WEEK) && (
                  <WeatherForecast
                    forecast={weatherType === weatherTypes.DAY ? weather.hourly : weather.daily}
                    isDayForecast={weatherType === weatherTypes.DAY}
                    isFetching={isFetchingWeather}
                  />
                )}
                {weatherType === weatherTypes.RADAR && (
                  <Fragment>
                    {radarListError ? (
                      <div className={classes.unavailable}>
                        <FormattedMessage id="WeatherControl.radarIsUnavailable" />
                      </div>
                    ) : (
                      <WeatherRadar
                        isFetching={isFetchingRadarList}
                        moveRadarLayer={this.props.moveRadarLayer}
                        radarList={radarList}
                        setRadarLayers={this.props.setRadarLayers}
                        unsetRadarLayers={this.props.unsetRadarLayers}
                      />
                    )}
                  </Fragment>
                )}
              </div>
              {weatherTypeConfig && (
                <div className={classes.attribution}>
                  {weatherType === weatherTypes.RADAR ? (
                    <a
                      className={classes.attributionLink}
                      href="http://www.radareu.cz/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Powered by radareu.cz
                    </a>
                  ) : (
                    <a
                      className={classes.attributionLink}
                      href="https://darksky.net/poweredby/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Powered by Dark Sky
                    </a>
                  )}
                </div>
              )}
            </Fragment>
          </MapBottomPanel>
        )}
      </div>
    );
  }
}

WeatherControl.propTypes = {
  classes: PropTypes.object.isRequired,
  weather: PropTypes.object.isRequired,
  isFetchingWeather: PropTypes.bool.isRequired,
  isFetchingRadarList: PropTypes.bool.isRequired,
  coordinates: PropTypes.array.isRequired,
  weatherType: PropTypes.string.isRequired,
  radarList: PropTypes.array.isRequired,
  langId: PropTypes.string.isRequired,
  fetchWeather: PropTypes.func.isRequired,
  fetchRadar: PropTypes.func.isRequired,
  setMapContext: PropTypes.func.isRequired,
  setWeatherCoordinatesPin: PropTypes.func.isRequired,
  setRadarLayers: PropTypes.func.isRequired,
  unsetRadarLayers: PropTypes.func.isRequired,
  moveRadarLayer: PropTypes.func.isRequired,
  isWeather: PropTypes.bool.isRequired,
  isRadar: PropTypes.bool.isRequired,
  radarListError: PropTypes.object,
  weatherError: PropTypes.object,
  context: PropTypes.string.isRequired,
};

WeatherControl.defaultProps = {
  radarListError: null,
  weatherError: null,
};

const mapStateToProps = state => ({
  weather: getWeatherApi(state),
  weatherError: getWeatherError(state),
  isFetchingWeather: getIsFetchingWeather(state),
  radarList: getRadarList(state),
  radarListError: getRadarListError(state),
  isFetchingRadarList: getIsFetchingRadarList(state),
  weatherType: getWeatherType(state),
  coordinates: getWeatherCoordinates(state),
  isWeather: getIsWeather(state),
  isRadar: getIsRadar(state),
  context: getContext(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWeather,
      fetchRadar,
      setWeatherType,
      unsetWeatherLayer,
      setWeatherCoordinatesPin,
      setRadarLayers,
      unsetRadarLayers,
      moveRadarLayer,
      setMapContext,
    },
    dispatch,
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WeatherControl)));
