import React, { Component } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import UnknownIcon from '@mui/icons-material/Help';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as sensorsStatus from '../../../../../shared/constants/sensorsStatus.constants';

import { COLOR_GREY } from '../../../../../theme';

const styles = () => ({
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class SensorStatus extends Component {
  resolveIconClass() {
    const { sensor, testId } = this.props;
    switch (sensor.status) {
      case sensorsStatus.ERROR:
        return <ErrorIcon color="error" data-test={testId} />;
      case sensorsStatus.TRANSFER:
        return <ShuffleIcon color="secondary" data-test={testId} />;
      case sensorsStatus.UNKNOWN:
        return <UnknownIcon color="secondary" data-test={testId} />;
      case sensorsStatus.INACTIVE:
        return <CheckIcon data-test={testId} style={{ color: COLOR_GREY[400] }} />;
      default:
        return <CheckIcon color="primary" data-test={testId} />;
    }
  }

  resolveTooltip(sensor) {
    const message =
      sensor.status === sensorsStatus.UNKNOWN ? (
        <FormattedMessage id="SensorsAdmin.unknownStatusMessage" />
      ) : (
        `STATUS: ${sensor.status}`
      );
    const voltage = sensor.voltage ? sensor.voltage / 1000 : '?';
    return (
      <div className={this.props.classes.tooltip}>
        <div>{sensor.statusMessage ? sensor.statusMessage : message}</div>
        <div>{`VOLTAGE: ${voltage}`}</div>
      </div>
    );
  }

  render() {
    const { sensor } = this.props;
    return (
      <Tooltip placement="bottom" title={this.resolveTooltip(sensor)}>
        {this.resolveIconClass()}
      </Tooltip>
    );
  }
}

SensorStatus.propTypes = {
  classes: PropTypes.object,
  sensor: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

SensorStatus.defaultProps = {
  classes: {},
  testId: 'sensor-status-icon',
};

export default withStyles(styles)(SensorStatus);
