import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPredictionData } from '../../../shared/api/iot/locations/locations.selectors';
import { getMapColumns } from '../../selectors/map.selectors';

import { fetchPredictionData } from '../../actions/predictions.actions';

import * as dataTypes from '../../constants/dataTypes.constants';
import * as occurrences from '../../constants/occurrences.constants';
import * as pestTypes from '../../constants/pestTypes.constants';

import { getPredictionConfig } from '../../../shared/api/iot/predictions/predictions.api';
import SensorsService from '../../../shared/services/Sensors.service';
import FusariumCalculatorDialog from '../../components/FusariumCalculatorDialog/FusariumCalculatorDialog';
import PestPredictionGraphHeader from '../../components/PestPredictionGraphHeader/PestPredictionGraphHeader';
import PestPredictionGraphLabels from '../../components/PestPredictionGraphLabels/PestPredictionGraphLabels';
import PestPredictionGraphLimits from '../../components/PestPredictionGraphLimits/PestPredictionGraphLimits';
import PestPredictionGraphOccurrences from '../../components/PestPredictionGraphOccurences/PestPredictionGraphOccurences';
import PestPredictionValueLabel from '../../components/PestPredictionValueLabel/PestPredictionValueLabel';
import SensorsPestPredictionService from '../../services/SensorsPestPrediction.service';

const styles = theme => ({
  graphBar: {
    height: 12,
    display: 'inline-block',
    borderRadius: 25,
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
  },
  progressBar: {
    height: 12,
    display: 'inline-block',
    borderRadius: 25,
    position: 'absolute',
  },
  pestGraph: {
    position: 'relative',
    marginTop: 5,
    marginBottom: 15,
  },
  progressWrapper: {
    marginTop: 2,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center',
  },
  chartHeading: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginTop: 4,
  },
  fusariumCalculatorWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const GRAPH_WIDTH = 300;
const DAYS_COUNT = 28;

class PestPredictionGraph extends Component {
  constructor(props) {
    super(props);
    this.graphWrapperRef = React.createRef();
    this.state = {
      graphWidth: GRAPH_WIDTH,
    };
  }

  componentDidMount() {
    this.fetchPrediction();
    this.updateGraphWidth();
    window.addEventListener('resize', this.updateGraphWidth);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapColumns !== this.props.mapColumns) {
      this.updateGraphWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateGraphWidth);
  }

  getProgressBarWidth(pestPredictionValue, maxValue) {
    const { graphWidth } = this.state;
    pestPredictionValue = pestPredictionValue > maxValue ? maxValue : pestPredictionValue;
    const k = graphWidth / maxValue;
    return pestPredictionValue * k;
  }

  getIconColor = (pestPredictionName, pestPredictionValue, additionalValue) => {
    let value = pestPredictionValue;
    let isAdditional = false;
    if (additionalValue && additionalValue < pestPredictionValue) {
      value = additionalValue;
      isAdditional = true;
    }
    const pestPredictionValueLimit = SensorsPestPredictionService.getPredictionTypeDescriptionByValue(
      pestPredictionName,
      value,
      isAdditional,
    );

    return pestPredictionValueLimit?.color;
  };

  fetchPrediction() {
    const {
      nodeLocation,
      pestPrediction: {
        featureLocationId,
        id,
        name,
        values: { lastValue },
      },
    } = this.props;
    const isHistoric = SensorsService.isHistoric(nodeLocation);
    if (!isHistoric && name === pestTypes.FUSARIUM_HEAD_BLIGHT) {
      this.props.getPredictionConfig(name, featureLocationId);
    }

    if (SensorsPestPredictionService.shouldDisplayOccurrences(name)) {
      const dateToMoment = nodeLocation.to ? moment(nodeLocation.to) : moment();
      const dateTo = dateToMoment.toISOString();
      const dateFrom = dateToMoment.subtract(DAYS_COUNT, 'days').toISOString();
      this.props.fetchPredictionData(id, nodeLocation.id, dateFrom, dateTo, dataTypes.NOW);
      if (lastValue === 0) {
        this.props.fetchPredictionData(id, nodeLocation.id, null, null, dataTypes.HISTORY, occurrences.LAST);
      }
    }
  }

  updateGraphWidth = () => {
    this.setState({
      graphWidth: this.graphWrapperRef.current.offsetWidth,
    });
  };

  renderGraphBarWithContent = (content, headingTranslationId) => {
    const { graphWidth } = this.state;
    const { classes } = this.props;
    return (
      <div data-test="chart">
        {headingTranslationId && (
          <div className={classes.chartHeading}>
            <FormattedMessage id={headingTranslationId} />
          </div>
        )}
        <span className={classes.graphBar} data-test="graph-bar" style={{ width: graphWidth }}>
          {content}
        </span>
      </div>
    );
  };

  renderGraphBarWithOccurrences = (
    data,
    pestPredictionName,
    occurrenceValues,
    isAdditional,
    headingTranslationId = 'PestPrediction.occurrenceGraph',
  ) => {
    const { graphWidth } = this.state;
    return this.renderGraphBarWithContent(
      <Fragment>
        {data.length > 0 && (
          <PestPredictionGraphOccurrences
            data={data}
            graphWidth={graphWidth}
            isAdditional={isAdditional}
            occurrenceValues={occurrenceValues}
            pestPredictionName={pestPredictionName}
          />
        )}
      </Fragment>,
      headingTranslationId,
    );
  };

  renderWheatBlotch = () => {
    const { graphWidth } = this.state;
    const { data } = this.props;
    return (
      <Fragment>
        {this.renderGraphBarWithOccurrences(data, pestTypes.WHEAT_BLOTCH)}
        {data.length > 0 && <PestPredictionGraphLabels data={data} graphWidth={graphWidth} />}
      </Fragment>
    );
  };

  renderFusarium = () => {
    const { graphWidth } = this.state;
    const { data } = this.props;
    return (
      <Fragment>
        {this.renderGraphBarWithOccurrences(data, pestTypes.FUSARIUM_HEAD_BLIGHT, [1, 3])}
        {this.renderGraphBarWithOccurrences(
          data,
          pestTypes.FUSARIUM_HEAD_BLIGHT,
          [2, 3],
          true,
          'PestPrediction.spreadGraph',
        )}
        {data.length > 0 && <PestPredictionGraphLabels data={data} graphWidth={graphWidth} />}
      </Fragment>
    );
  };

  renderCornBorer = pestPredictionValue => {
    const { graphWidth } = this.state;
    const { classes } = this.props;
    const maxValue = SensorsPestPredictionService.getPredictionMaxLimit(pestTypes.CORN_BORER);
    const limits = SensorsPestPredictionService.getLimits(pestTypes.CORN_BORER);
    const progressBarWidth = this.getProgressBarWidth(pestPredictionValue, maxValue);
    return this.renderGraphBarWithContent(
      <Fragment>
        <span
          className={classes.progressBar}
          data-test="progress-bar"
          style={{ width: progressBarWidth, background: '#B1B1B1' }}
        />
        {limits.length > 0 && <PestPredictionGraphLimits graphWidth={graphWidth} limits={limits} maxValue={maxValue} />}
      </Fragment>,
    );
  };

  renderPhomaFoveata = () => {
    const { graphWidth } = this.state;
    const { data } = this.props;
    return (
      <Fragment>
        {this.renderGraphBarWithOccurrences(data, pestTypes.PHOMA_FOVEATA)}
        {data.length > 0 && <PestPredictionGraphLabels data={data} graphWidth={graphWidth} />}
      </Fragment>
    );
  };

  renderGraphProgressLabel = (predictionValue, isAdditional = false) => {
    const {
      historyData,
      pestPrediction: { name: pestPredictionName },
    } = this.props;
    return (
      <PestPredictionValueLabel
        historyData={historyData}
        isAdditional={isAdditional}
        pestPredictionName={pestPredictionName}
        predictionValue={predictionValue}
      />
    );
  };

  render() {
    const {
      classes,
      historyData,
      key,
      pestPrediction: { name: pestPredictionName, validAfterDay, validAfterMonth, values },
      testId,
    } = this.props;
    const pestPredictionValue = SensorsPestPredictionService.getLastValue(values);
    const additionalValue = SensorsPestPredictionService.getSpreadingValue(values);
    const pestPredictionConfig = SensorsPestPredictionService.getPredictionTypeConfig(pestPredictionName);
    const iconColor = this.getIconColor(pestPredictionName, pestPredictionValue, additionalValue);

    return (
      <div className={classes.pestGraph} data-test={testId} key={`pestGraph${key}`} ref={this.graphWrapperRef}>
        {
          <PestPredictionGraphHeader
            color={iconColor}
            pestPredictionConfig={pestPredictionConfig}
            validAfterDay={validAfterDay}
            validAfterMonth={validAfterMonth}
          />
        }
        <Grid container>
          <Grid item sm={9} xs={12}>
            <div className={classes.progressWrapper}>
              {this.renderGraphProgressLabel(pestPredictionValue)}
              {pestPredictionName === pestTypes.FUSARIUM_HEAD_BLIGHT &&
                !!additionalValue &&
                !(pestPredictionValue === 0 && historyData.length === 0) &&
                this.renderGraphProgressLabel(additionalValue, true)}
            </div>
          </Grid>
          <Grid item sm={3} xs={12}>
            <div className={classes.fusariumCalculatorWrapper}>
              {pestPredictionName === pestTypes.FUSARIUM_HEAD_BLIGHT && <FusariumCalculatorDialog />}
            </div>
          </Grid>
        </Grid>
        {pestPredictionValue !== 0 && (
          <Fragment>
            {pestPredictionName === pestTypes.CORN_BORER && this.renderCornBorer(pestPredictionValue)}
            {pestPredictionName === pestTypes.WHEAT_BLOTCH && this.renderWheatBlotch()}
            {pestPredictionName === pestTypes.FUSARIUM_HEAD_BLIGHT && this.renderFusarium()}
            {pestPredictionName === pestTypes.PHOMA_FOVEATA && this.renderPhomaFoveata()}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: getPredictionData(state, props.pestPrediction.id, dataTypes.NOW),
  historyData: getPredictionData(state, props.pestPrediction.id, dataTypes.HISTORY),
  mapColumns: getMapColumns(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPredictionData,
      getPredictionConfig,
    },
    dispatch,
  );

PestPredictionGraph.propTypes = {
  classes: PropTypes.object,
  key: PropTypes.string,
  data: PropTypes.array,
  historyData: PropTypes.array,
  pestPrediction: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object.isRequired,
  testId: PropTypes.string,
  mapColumns: PropTypes.number.isRequired,
  fetchPredictionData: PropTypes.func.isRequired,
  getPredictionConfig: PropTypes.func.isRequired,
};

PestPredictionGraph.defaultProps = {
  classes: {},
  data: [],
  historyData: [],
  key: '',
  testId: 'prediction',
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PestPredictionGraph)));
