import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = theme => ({
  tooltipLabelWrapper: {
    padding: 8,
    backgroundColor: theme.palette.grey[100],
  },
});

export const CfChartTooltipLabelWrapper = props => {
  const { children, classes } = props;
  return <div className={classes.tooltipLabelWrapper}>{children}</div>;
};

CfChartTooltipLabelWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(style)(CfChartTooltipLabelWrapper);
