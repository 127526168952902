import isEmpty from 'lodash/isEmpty';

export default class FilterService {
  /** Use to update local state of the advanced filter
   *
   * @param currentAdvancedFilter - the local state
   * @param filterPart - the filter part to be updated
   * @param items - new items for the filter
   */
  static getUpdatedFilterState(currentAdvancedFilter, filterPart, items) {
    if (filterPart === null) {
      return {};
    }
    const newAdvancedFilter = { ...currentAdvancedFilter };
    delete newAdvancedFilter[filterPart];
    if (items.length === 0 && isEmpty(newAdvancedFilter)) {
      return {};
    }
    return {
      ...newAdvancedFilter,
      [filterPart]: items,
    };
  }
}
