import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingZones, getZones as getZonesSelector } from '../../../../../shared/api/core/zones/zones.selectors';

import { getZones, resetZones } from '../../../../../shared/api/core/zones/zones.api';
import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';

export class ZoneSelector extends Component {
  constructor(props) {
    super(props);
    this.fetchZones('');
  }

  componentWillUnmount() {
    this.props.resetZones();
  }

  onSuggestionClear = () => {
    this.props.resetZones();
  };

  fetchZones = name => {
    this.props.getZones({ name });
  };

  render() {
    const { defaultZones, isZoneFetching, label, onChange } = this.props;
    return (
      <CfAutocomplete
        defaultValues={defaultZones}
        id="zone-selector"
        isMultiple={true}
        label={label}
        loading={isZoneFetching}
        loadOptions={this.fetchZones}
        onChange={value => onChange('zones', value)}
        suggestions={this.props.zoneSuggestions}
        testId="zone-filter"
      />
    );
  }
}

ZoneSelector.propTypes = {
  zoneSuggestions: PropTypes.array.isRequired,
  getZones: PropTypes.func.isRequired,
  resetZones: PropTypes.func.isRequired,
  defaultZones: PropTypes.array,
  isZoneFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

ZoneSelector.defaultProps = {
  defaultZones: [],
};

const mapStateToProps = state => ({
  zoneSuggestions: getZonesSelector()(state),
  isZoneFetching: getIsFetchingZones()(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getZones,
      resetZones,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ZoneSelector);
