import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MergeIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M20,8h-4V4c0-0.5-0.4-1-1-1h-11C3.4,3,3,3.4,3,4v11c0,0.5,0.4,1,1,1h4v4c0,0.5,0.4,1,1,1h11c0.5,0,1-0.4,1-1V9C21,8.4,20.5,8,20,8z" />
    </svg>
  </SvgIcon>
);

export default MergeIcon;
