import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getParcelListAdvancedFilter } from '../../selectors/parcelList.selectors';

import CfFilter from '../../../../../shared/containers/CfFilter/CfFilter';
import ZoneSelector from '../ZoneSelector/ZoneSelector';

const styles = {
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
};

export class ParcelFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedFilter: {},
    };
  }

  setFilterPart = (filterPart, items) => {
    if (filterPart === null) {
      return {};
    }
    const currentAdvancedFilter = this.state.advancedFilter;
    delete currentAdvancedFilter[filterPart];
    return {
      ...currentAdvancedFilter,
      [filterPart]: items,
    };
  };

  setFilterState = newValues => {
    this.setState({
      advancedFilter: newValues,
    });
  };

  handleChange = (filterPart, items) => {
    const newValues = this.setFilterPart(filterPart, items);
    this.setFilterState(newValues);
  };

  render() {
    const { advancedFilter, classes, langId, namespace } = this.props;
    return (
      <CfFilter
        filterState={this.state.advancedFilter}
        langId={langId}
        namespace={namespace}
        setAdvancedFilterState={this.setFilterState}
      >
        <div className={classes.container}>
          <Grid container>
            <Grid className={classes.select} item xs={12}>
              <ZoneSelector
                defaultZones={advancedFilter.zones}
                label={<FormattedMessage id="common.zone" />}
                namespace={namespace}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        </div>
      </CfFilter>
    );
  }
}

ParcelFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  namespace: PropTypes.string,
  advancedFilter: PropTypes.object,
  langId: PropTypes.string.isRequired,
};

ParcelFilter.defaultProps = {
  anchorEl: null,
  namespace: null,
  advancedFilter: {},
};

const mapStateToProps = state => ({
  advancedFilter: getParcelListAdvancedFilter(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(ParcelFilter));
