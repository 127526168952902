export const STATISTICS_TYPES = {
  AVERAGE: {
    id: 'average',
    name: 'avgValue',
  },
  MINIMUM: {
    id: 'minimum',
    name: 'minValue',
  },
  MAXIMUM: {
    id: 'maximum',
    name: 'maxValue',
  },
};
