import * as types from './transactions.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    notesError: {},
    isFetchingNotes: false,
    totalCount: 0,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.RESET_TRANSACTIONS:
      return {
        ...state,
        items: [],
        error: {},
      };
    case types.GET_TRANSACTIONS_NOTES:
      return {
        ...state,
        isFetchingNotes: true,
        notesError: {},
      };
    case types.GET_TRANSACTIONS_NOTES_SUCCESS:
      return {
        ...state,
        isFetchingNotes: false,
        notesError: {},
        items: assignTransactionNote(state, action),
      };
    case types.GET_TRANSACTIONS_NOTES_ERROR:
      return {
        ...state,
        isFetchingNotes: false,
        notesError: action.payload,
      };
    default:
      return state;
  }
};

const assignTransactionNote = (state, action) => {
  const notes = [...action.payload];
  const transactions = [...state.items];
  return transactions.map(transaction => ({
    ...transaction,
    note: notes.find(note => String(note.storeTransactionId) === String(transaction.id)) || transaction.note,
  }));
};
