/**
 * Created by ondrejzvara on 31.5.16.
 */

/*@ngInject*/
export default function LogStack() {

    var service = {
        get: get
    };
    return service;

    /////////////////////////

    function get() {
        return StackTrace.get();
    }

}
