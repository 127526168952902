import { AREA_FORM_FIELDS } from '../irrigation.constants';

import { DeviceToEdit, FieldErrors } from '../reducer/createArea.reducer.types';

interface Payload {
  areaName: string;
  devices: DeviceToEdit[];
}

export const isNotEmptyString = (value: string) => !!(value && typeof value === 'string' && value.trim());
export const isNotZero = (value: string) => !!parseFloat(value);
export const isCorrectLatitude = (value: string) => !!(parseFloat(value) >= -90 && parseFloat(value) <= 90);
export const isCorrectLongitude = (value: string) => !!(parseFloat(value) >= -180 && parseFloat(value) <= 180);

const VALIDATIONS = {
  [AREA_FORM_FIELDS.AREA_NAME]: [isNotEmptyString],
  [AREA_FORM_FIELDS.NAME]: [isNotEmptyString],
  [AREA_FORM_FIELDS.LATITUDE]: [isCorrectLatitude, isNotZero],
  [AREA_FORM_FIELDS.LONGITUDE]: [isCorrectLongitude, isNotZero],
};

export const VALIDATION_MESSAGES_INTL_IDS = {
  [AREA_FORM_FIELDS.AREA_NAME]: 'Irrigation.createArea.validation.areaName',
  [AREA_FORM_FIELDS.NAME]: 'Irrigation.createArea.validation.name',
  [AREA_FORM_FIELDS.LATITUDE]: 'Irrigation.createArea.validation.latitude',
  [AREA_FORM_FIELDS.LONGITUDE]: 'Irrigation.createArea.validation.longitude',
};

export const validateField = (fieldName: string, value: string) => {
  if (!VALIDATIONS[fieldName]) return null;

  const results = VALIDATIONS[fieldName].map(validation => validation(value));
  const didNotPass = results.includes(false);

  if (didNotPass) {
    return VALIDATION_MESSAGES_INTL_IDS[fieldName];
  }
  return null;
};

export const validateForm = (payload: Payload): [boolean, FieldErrors] => {
  const areaNameError = validateField(AREA_FORM_FIELDS.AREA_NAME, payload.areaName);
  const devicesErrors = payload.devices.reduce((acc, device) => {
    acc[`${device.id}-${AREA_FORM_FIELDS.NAME}`] = validateField(
      AREA_FORM_FIELDS.NAME,
      device.name);
    acc[`${device.id}-${AREA_FORM_FIELDS.LATITUDE}`] = validateField(
      AREA_FORM_FIELDS.LATITUDE,
      device.geometry.coordinates[0],
    );
    acc[`${device.id}-${AREA_FORM_FIELDS.LONGITUDE}`] = validateField(
      AREA_FORM_FIELDS.LONGITUDE,
      device.geometry.coordinates[1]);
    return acc;
  }, {} as FieldErrors);

  const fieldErrors = {
    [AREA_FORM_FIELDS.AREA_NAME]: areaNameError,
    ...devicesErrors,
  };
  const hasAtLeastOneError = Object.values(fieldErrors).some(Boolean);

  if (hasAtLeastOneError) {
    return [false, fieldErrors];
  }
  return [true, {}];
};
