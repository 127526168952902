import { Component, createElement } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Localization from '../../../services/Localization.service';
import CfReduxFormTextField from '../CfReduxFormTextField/CfReduxFormTextField';

class CfFormattedTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props;

    const valueAsNum = this.str2num(value); // returns the same number or NaN
    const currStateAsNum = this.str2num(this.state.value);

    if (valueAsNum !== currStateAsNum) {
      this.update(this.num2str(valueAsNum));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      input: { value },
    } = this.props;

    const valueAsNum = this.str2num(value); // returns the same number or NaN
    const currStateAsNum = this.str2num(this.state.value);
    const prevStateAsNum = this.str2num(prevState.value);

    if (valueAsNum !== currStateAsNum && valueAsNum !== prevStateAsNum && !isNaN(valueAsNum)) {
      this.update(this.num2str(valueAsNum));
    }
  }

  componentWillUnmount() {
    this.update('');
  }

  update = value => {
    this.setState({
      value,
    });
  };

  str2num = val => Localization.str2num(val, this.props.intl.locale);

  num2str = val => Localization.num2str(val, this.props.intl.locale);

  render() {
    const {
      input: { onBlur, onChange, value, ...inputProps },
      intl,
      ...props
    } = this.props;
    return createElement(CfReduxFormTextField, {
      value: this.state.value,
      onChange: (evt, newValue) => {
        this.update(evt.target.value);
        if (onChange) {
          onChange(evt, newValue);
        }
      },
      onBlur: evt => {
        const val = evt.target.value;
        const formatted = this.num2str(this.str2num(val));

        if (formatted) {
          this.update(formatted);
        }

        if (onBlur) {
          onBlur(evt, evt.target.value);
        }
      },
      input: { ...inputProps },
      ...props,
    });
  }
}

CfFormattedTextField.propTypes = {
  intl: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default injectIntl(CfFormattedTextField);
