import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import EffectivenessFields from '../EffectivenessFields/EffectivenessFields';

const styles = () => ({
  field: {
    paddingTop: 10,
  },
  grid: {
    display: 'flex',
  },
  gridParcelName: {
    display: 'flex',
    alignItems: 'center',
  },
});

class ParcelChemistryEffectiveness extends Component {
  renderParcelChemistryEffectiveness = (fieldName, index, field) => (
    <div className={this.props.classes.field} data-test="parcel-chemistry-effectiveness" key={fieldName}>
      <Grid container spacing={0}>
        <Grid className={this.props.classes.gridParcelName} item xs={4}>
          <div data-test="parcel-name">{field.parcelName}</div>
        </Grid>
        <Grid className={this.props.classes.grid} item xs={8}>
          <Field
            component={EffectivenessFields}
            disabled={!this.props.editing}
            name={`${fieldName}.value`}
            selected={field.value}
            handleChange={(value) => {
              this.props.change(`${fieldName}.value`, value);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  render() {
    const { fields } = this.props;
    return (
      <div id="chemistry-effectiveness-per-parcel">
        {this.props.fields.map((fieldName, index) =>
          this.renderParcelChemistryEffectiveness(fieldName, index, fields.get(index)),
        )}
      </div>
    );
  }
}

ParcelChemistryEffectiveness.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func,
  editing: PropTypes.bool,
};

ParcelChemistryEffectiveness.defaultProps = {
  editing: false,
  change: () => {},
};

export default withStyles(styles)(ParcelChemistryEffectiveness);
