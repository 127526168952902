import { useState, useEffect, useCallback } from 'react';

import LocalStorage from '../services/LocalStorage.service';

const FARMASENSE_DOMAIN = /farmasense.gr/;

export const isFarmaSenseDomain = !!window.location?.hostname.match(FARMASENSE_DOMAIN);

export const getAppTheme = () => LocalStorage.loadFromLocalStorage('theme') || 0;

const useAppTheming = () => {
  const [theme, setTheme] = useState<0|1>(LocalStorage.loadFromLocalStorage('theme') || 0);

  // TODO [from CFD-1004] - remove this override when farmasense domain is deployed
  const [override, setOverride] = useState<boolean>(LocalStorage.loadFromLocalStorage('override') || false);

  // TODO [from CFD-1004] - remove global helpers when domain is deployed
  window.setFarmaSenseTheme = useCallback((bool: boolean) => {
    if (bool) {
      setTheme(1);
      setOverride(true);
      LocalStorage.saveToLocalStorage(true, 'override');
    } else {
      setTheme(0);
      setOverride(false);
      LocalStorage.saveToLocalStorage(false, 'override');
    }
    setTimeout(() => location.reload(), 2000);
    return 'Browser will be reloaded to switch the theme';
  }, []);

  useEffect(() => {
    if (override) return;
    if (isFarmaSenseDomain) {
      if (theme !== 1) {
        setTheme(1);
      }
    } else {
      setTheme(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LocalStorage.saveToLocalStorage(theme, 'theme');
  }, [theme]);

  return theme;
};

export default useAppTheming;
