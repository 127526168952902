/**
 * Created by ondrejzvara on 29.3.17.
 */


import angular from 'angular';
import clPublic from './public/classifiers.public.module';
import clPrivate from './private/classifiers.private.module';


import cfPorBatch from './cf-por-batch/cf-por-batch.directive.js';
import cfPorEditor from './cf-por-editor/cf-por-editor.directive.js';
import cfClassTdBtns from './cf-class-td-btns/cf-class-td-btns.directive.js';
import cfClassThBtns from './cf-class-th-btns/cf-class-th-btns.directive.js';


export default angular.module('app.components.farm.classifiers', [
    clPublic,
    clPrivate
])
    .config(config)
    .directive('cfPorBatch', cfPorBatch)
    .directive('cfPorEditor', cfPorEditor)
    .directive('cfClassTdBtns', cfClassTdBtns)
    .directive('cfClassThBtns', cfClassThBtns)
    .name;


/*@ngInject*/
function config($stateProvider) {

    $stateProvider
        .state('farm.active.classifiers', {
            url: '/classifiers',
            abstract: true
        });
}