import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfChartTooltipDaytime from '../../../../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime';
import CfChartTooltipLabelWrapper from '../../../../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipNoData from '../../../../../../shared/components/charts/CfChartTooltipNoData/CfChartTooltipNoData';
import CfChartTooltipWrapper from '../../../../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import CfLazyImage from '../../../../../../shared/components/common/CfLazyImage/CfLazyImage';

const style = theme => ({
  customTooltipLabelWrapper: {
    padding: 3,
  },
  images: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 120,
    height: 120,
    backgroundColor: theme.palette.grey[100],
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    imageRendering: 'pixelated',
    '&': {
      imageRendering: '-moz-crisp-edges', // fallback for FF
    },
  },
});

const CropVariabilityTooltip = props => {
  const { active, classes, label, payload } = props;
  if (active && payload && payload[0]) {
    return (
      <CfChartTooltipWrapper>
        <CfChartTooltipLabelWrapper>
          <CfChartTooltipDaytime label={label} />
        </CfChartTooltipLabelWrapper>
        <CfChartTooltipNoData classes={{ tooltipValueWrapper: classes.customTooltipLabelWrapper }} item={payload[0]}>
          <div className={classes.images}>
            <div className={classes.imageWrapper}>
              <CfLazyImage
                alt={`History Snap from ${payload[0].payload.date}`}
                classes={{ custom: classes.image }}
                src={payload[0].payload.managementZoneImgPath}
                testId="satellite"
              />
            </div>
            <div className={classes.imageWrapper}>
              <CfLazyImage
                alt={`History Snap from ${payload[0].payload.date}`}
                classes={{ custom: classes.image }}
                src={payload[0].payload.involvementImgPath}
                testId="involvement"
              />
            </div>
          </div>
        </CfChartTooltipNoData>
      </CfChartTooltipWrapper>
    );
  }
  return null;
};

CropVariabilityTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

CropVariabilityTooltip.defaultProps = {
  label: '',
};

export default withStyles(style)(CropVariabilityTooltip);
