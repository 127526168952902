import React, { FC } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';

import NotSentIcon from '../../../shared/icons/NotSentIcon';

const TelematicsNotSendInfo: FC = () => (
  <Tooltip title={<FormattedMessage id={'TelematicsList.notSent'} />}>
    <span>
      <NotSentIcon />
    </span>
  </Tooltip>
);

export default TelematicsNotSendInfo;
