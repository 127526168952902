import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './IrrigationAreasMap.css';

import { getAreaDetailPoints } from '../../../shared/api/irrigation/areas/areas.selectors';
import { getIsFetchingDevice } from '../../../shared/api/irrigation/devices/devices.selectors';
import { getCreateOrEditAreaAvailablePoints, getCreateOrEditAreaCoordSelectorId, getCreateOrEditAreaCoordSelectorStatus, getCreateOrEditAreaHoveredDevice, getCreateOrEditAreaSelectedDevices } from '../../selectors/createOrEditArea.selectors';
import { getMapColumns, getMapExtent } from '../../selectors/map.selectors';

import { storeInitialLayers } from '../../../core/map/actions/layersUI/layersUI.actions';
import { storeServiceWrapper, resetMap, zoomToExtent } from '../../../core/map/actions/map/map.actions';
import { editDevice, resetCoordSelector } from '../../actions/createOrEditArea.actions';
import { setDefaultExtent } from '../../actions/map.actions';

import MapHintControl from '../../../core/map/components/MapHintControl/MapHintControl';
import MapLoader from '../../../core/map/components/MapLoader/MapLoader';
import { fetchLayersConfig, resetLayers } from '../../../shared/api/other/layers/layers.api';
import withConfig from '../../../shared/hocs/context/withConfig';
import withFarm from '../../../shared/hocs/context/withFarm';
import MapLayerControl from '../../components/MapLayerControl/MapLayerControl';
import IrrigationAreasMapService, { LAYERS } from '../../services/IrrigationAreasMap.service';
import { MAP_ZOOM_SCALE_FACTOR } from '../IrrigationList/devices/IrrigationDevicesList';

const styles = theme => ({
  map: {
    height: '100%',
    position: 'relative',
  },
  showHint: {
    '& #infoBox': {
      [theme.breakpoints.up('sm')]: {
        // TODO rework MapHintControl, so this hack is not necessary
        visibility: 'visible !important',
      },
    },
  },
  layerControl: {
    position: 'absolute',
    top: 14,
    right: 16,
    width: 'fit-content',
    height: 40,
    zIndex: 2,
    margin: 0,
    paddingRight: 16,
    backgroundColor: '#fff',
    fontSize: 13,
    lineHeight: 15,

  },
});

export class IrrigationAreasMap extends Component {
  componentDidMount() {
    const { areaPoints, config, farm, history, hoveredFeatureId, points, selected, withAssignedDevices } = this.props;

    this.irrMapService = new IrrigationAreasMapService(
      config,
      farm,
      history,
      this.props.setDefaultExtent,
    );
    this.map = this.irrMapService.getMap();
    this.props.fetchLayersConfig(farm.customer.countryCode !== 'CZ');
    this.irrMapService.setPointsToLayer(points, LAYERS.UNASSIGNED_DEVICES);
    this.irrMapService.styleUnassignedDevicesLayer(selected, hoveredFeatureId);

    if (withAssignedDevices && areaPoints) {
      this.irrMapService.setPointsToLayer(areaPoints, LAYERS.ASSIGNED_DEVICES);
    }

    this.props.storeServiceWrapper('main', this.map);
  }

  componentDidUpdate(prevProps) {
    const {
      areaPoints,
      coordSelectorDeviceId,
      editDevice,
      hoveredFeatureId,
      isCoordSelectorActive,
      layersConfig: newLayersConfig,
      mapColumns,
      mapExtent,
      points,
      resetCoordSelector,
      selected,
      withAssignedDevices,
    } = this.props;

    const {
      areaPoints: prevAreaPoints,
      hoveredFeatureId: prevHoveredFeatureId,
      isCoordSelectorActive: prevIsCoordSelectorActive,
      layersConfig: prevLayersConfig,
      mapExtent: prevMapExtent,
      points: prevPoints,
      selected: prevSelected,
    } = prevProps;

    if (isCoordSelectorActive !== prevIsCoordSelectorActive) {
      if (isCoordSelectorActive) {
        this.irrMapService.selectNewCoordinatesFromMap(coordSelectorDeviceId, editDevice, resetCoordSelector);
      } else {
        this.irrMapService.disableCoordinatesSelection();
      }
    }

    if (mapColumns !== prevProps.mapColumns) {
      this.map.updateSize();
    }
    if (mapExtent !== prevMapExtent) {
      this.props.zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR);
    }

    if (points !== prevPoints) {
      this.irrMapService.setPointsToLayer(points, LAYERS.UNASSIGNED_DEVICES);
    }

    if (selected !== prevSelected) {
      this.irrMapService.styleUnassignedDevicesLayer(selected, hoveredFeatureId);
    }

    if (hoveredFeatureId !== prevHoveredFeatureId) {
      this.irrMapService.styleUnassignedDevicesLayer(selected, hoveredFeatureId);
    }

    if (!prevLayersConfig.length && newLayersConfig.length) {
      this.layers = this.irrMapService.getLayers();
      this.irrMapService.setLayersWithConfig(newLayersConfig, this.props.storeInitialLayers);

      if (withAssignedDevices) {
        this.layers.addLayer(this.irrMapService.assignedDevicesLayer, LAYERS.ASSIGNED_DEVICES);
        this.irrMapService.enableHoverInteraction();
      }

      this.layers.addLayer(this.irrMapService.unassignedDevicesLayer, LAYERS.UNASSIGNED_DEVICES);
    }

    if (withAssignedDevices && (areaPoints !== prevAreaPoints)) {
      this.irrMapService.setPointsToLayer(areaPoints, LAYERS.ASSIGNED_DEVICES);
    }
  }

  componentWillUnmount() {
    this.props.resetMap();
    this.props.resetLayers();
  }

  render() {
    const { classes, isCoordSelectorActive, isFetchingPoints, withAssignedDevices } = this.props;
    return (
      <div className={classnames({ [classes.map]: true, [classes.showHint]: isCoordSelectorActive })} id="irrigation-areas-map">
        <MapLoader isFetching={isFetchingPoints} />
        <MapHintControl />
        {withAssignedDevices &&
          <MapLayerControl
            className={classes.layerControl}
            handleChange={this.irrMapService?.toggleAssignedDevicesLayer}
          />}
      </div>
    );
  }
}

IrrigationAreasMap.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  storeServiceWrapper: PropTypes.func.isRequired,
  storeInitialLayers: PropTypes.func.isRequired,
  fetchLayersConfig: PropTypes.func.isRequired,
  layersConfig: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  hoveredFeatureId: PropTypes.string,
  isFetchingPoints: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  setDefaultExtent: PropTypes.func.isRequired,
  mapColumns: PropTypes.number.isRequired,
  mapExtent: PropTypes.array.isRequired,
  zoomToExtent: PropTypes.func.isRequired,
  editDevice: PropTypes.func.isRequired,
  isCoordSelectorActive: PropTypes.bool.isRequired,
  coordSelectorDeviceId: PropTypes.string,
  resetCoordSelector: PropTypes.func.isRequired,
  resetMap: PropTypes.func.isRequired,
  resetLayers: PropTypes.func.isRequired,
  areaPoints: PropTypes.array,
  withAssignedDevices: PropTypes.bool,
};

IrrigationAreasMap.defaultProps = {
  geometries: null,
  selectionCoords: [],
  hoveredFeatureId: null,
  coordSelectorDeviceId: null,
  withAssignedDevices: false,
};

const mapStateToProps = state => ({
  layersConfig: state.api.layers.items,
  hoveredFeatureId: getCreateOrEditAreaHoveredDevice(state),
  selected: getCreateOrEditAreaSelectedDevices(state),
  points: getCreateOrEditAreaAvailablePoints(state),
  isFetchingPoints: getIsFetchingDevice(state),
  mapColumns: getMapColumns(state),
  mapExtent: getMapExtent(state),
  isCoordSelectorActive: getCreateOrEditAreaCoordSelectorStatus(state),
  coordSelectorDeviceId: getCreateOrEditAreaCoordSelectorId(state),
  areaPoints: getAreaDetailPoints(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      storeServiceWrapper,
      resetMap,
      zoomToExtent,
      storeInitialLayers,
      fetchLayersConfig,
      resetLayers,
      setDefaultExtent,
      editDevice,
      resetCoordSelector,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfig(),
  withFarm(),
  withStyles(styles),
)(IrrigationAreasMap);
