import { AnyAction } from 'redux';

import * as types from './mainMapTelematics.constants';

import { MainMapTelematicsApiState } from './mainMapTelematics.types';

export default () => (
  state: MainMapTelematicsApiState = {
    machinePositions: [],
    isFetchingMachinePositions: false,
    errorMachinePositions: false,

    machineDrivesHistory: [],
    isFetchingMachineDrivesHistory: false,
    errorMachineDrivesHistory: false,

    machines: [],
    isFetchingMachines: false,
    errorMachines: false,
    machineSelectorFilter: '',

    machinesGroups: [],
    isFetchingMachinesGroups: false,
    errorMachinesGroups: false,

    drivers: [],
    isFetchingDrivers: false,
    errorDrivers: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case types.GET_MACHINE_POSITIONS_REQUEST:
      return {
        ...state,
        isFetchingMachinePositions: true,
        errorMachinePositions: false,
      };
    case types.GET_MACHINE_POSITIONS_SUCCESS:
      return {
        ...state,
        machinePositions: action.payload,
        isFetchingMachinePositions: false,
      };
    case types.GET_MACHINE_POSITIONS_ERROR:
      return {
        ...state,
        errorMachinePositions: true,
        isFetchingMachinePositions: false,
      };

    case types.GET_MACHINE_DRIVES_HISTORY_REQUEST:
      return {
        ...state,
        isFetchingMachineDrivesHistory: true,
        errorMachineDrivesHistory: false,
      };
    case types.GET_MACHINE_DRIVES_HISTORY_SUCCESS:
      return {
        ...state,
        machineDrivesHistory: action.payload,
        isFetchingMachineDrivesHistory: false,
      };
    case types.GET_MACHINE_DRIVES_HISTORY_ERROR:
      return {
        ...state,
        errorMachineDrivesHistory: true,
        isFetchingMachineDrivesHistory: false,
      };

    case types.RESET_MACHINE_DRIVES_HISTORY:
      return {
        ...state,
        errorMachineDrivesHistory: false,
        isFetchingMachineDrivesHistory: false,
        machineDrivesHistory: [],
      };

    case types.GET_MACHINES_REQUEST:
      return {
        ...state,
        isFetchingMachines: true,
        errorMachines: false,
      };
    case types.GET_MACHINES_SUCCESS:
      return {
        ...state,
        machines: action.payload,
        isFetchingMachines: false,
      };
    case types.GET_MACHINES_ERROR:
      return {
        ...state,
        errorMachines: true,
        isFetchingMachines: false,
      };
    case types.RESET_MACHINES:
      return {
        ...state,
        machines: [],
      };
    case types.SET_MACHINE_SELECTOR_FILTER:
      return {
        ...state,
        machineSelectorFilter: action.machineSelectorFilter,
      };

    case types.GET_MACHINES_GROUPS_REQUEST:
      return {
        ...state,
        isFetchingMachinesGroups: true,
        errorMachinesGroups: false,
      };
    case types.GET_MACHINES_GROUPS_SUCCESS:
      return {
        ...state,
        machinesGroups: action.payload,
        isFetchingMachinesGroups: false,
      };
    case types.GET_MACHINES_GROUPS_ERROR:
      return {
        ...state,
        errorMachinesGroups: true,
        isFetchingMachinesGroups: false,
      };
    case types.RESET_MACHINES_GROUPS:
      return {
        ...state,
        machinesGroups: [],
      };

    case types.GET_DRIVERS_REQUEST:
      return {
        ...state,
        isFetchingDrivers: true,
        errorDrivers: false,
      };
    case types.GET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        isFetchingDrivers: false,
      };
    case types.GET_DRIVERS_ERROR:
      return {
        ...state,
        errorDrivers: true,
        isFetchingDrivers: false,
      };
    case types.RESET_DRIVERS:
      return {
        ...state,
        drivers: [],
      };

    default:
      return state;
  }
};
