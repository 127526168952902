import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import BufferInvertIcon from '../../../../shared/icons/mapTools/BufferInvertIcon';

const styles = {
  root: {
    padding: 2,
  },
};

class BufferInvertButton extends Component {
  renderIconButton = () => {
    const { callback, classes, isDisabled } = this.props;
    return (
      <IconButton
        classes={{ root: classes.root }}
        data-test={'invert-buffer'}
        disabled={isDisabled}
        onClick={callback}
        size="large">
        <BufferInvertIcon />
      </IconButton>
    );
  };

  render() {
    const { classes, isDisabled } = this.props;
    return (
      <ToolbarSection>
        {isDisabled ? (
          this.renderIconButton()
        ) : (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="bottom"
            title={<FormattedMessage id="BufferInvertButton.invertBuffer" />}
          >
            {this.renderIconButton()}
          </Tooltip>
        )}
      </ToolbarSection>
    );
  }
}

BufferInvertButton.propTypes = {
  classes: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

BufferInvertButton.defaultProps = {
  isDisabled: false,
};

export default withStyles(styles)(BufferInvertButton);
