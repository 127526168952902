import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';

import HorizontalScrolling from '../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling';
import HorizontalScrollingItem from '../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem';
import YesNo from '../../../../shared/components/common/YesNo/YesNo';
import Localization from '../../../../shared/services/Localization.service';

const styles = theme => ({
  wrapper: {
    width: '100%',
    height: 120,
    padding: '10px 0px',
    lineHeight: 1.7,
  },
  detailRowLabel: {
    color: theme.palette.grey[500],
  },
  detailRowValue: {
    fontWeight: 500,
  },
});

class MapParcelDetailBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshScrolling: false,
    };
  }

  componentDidMount() {
    // wait until dom elements are drawn
    setTimeout(() => {
      this.setState({
        refreshScrolling: !this.state.refreshScrolling,
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (this.props.parcel && (!prevProps.parcel || this.props.parcel.id !== prevProps.parcel.id)) {
      // wait until dom elements are drawn
      setTimeout(() => {
        this.setState({
          refreshScrolling: !this.state.refreshScrolling,
        });
      }, 100);
    }
  }

  getLastParcelActionText(parcel, isFetching) {
    if (isFetching) {
      return '...';
    }
    return parcel.lastAction ? parcel.lastAction.actionType.name : '-';
  }

  renderParcelDetailSection = sectionRows => (
    <div data-test="parcel-detail" style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 15 }}>
        {sectionRows.map(row => (
          <div className={this.props.classes.detailRowLabel} key={`${row.label}-label`}>
            {row.label ? <FormattedMessage id={row.label} /> : '\u00A0'}
          </div>
        ))}
      </div>
      <div style={{ marginRight: 30 }}>
        {sectionRows.map(row => (
          <div className={this.props.classes.detailRowValue} key={`${row.label}-value`}>
            {row.value ? row.value : '\u00A0'}
          </div>
        ))}
      </div>
    </div>
  );

  render() {
    const { classes, countryCode, intl, isFetching, parcel } = this.props;
    return (
      <div className={classes.wrapper}>
        <HorizontalScrolling refreshScrolling={this.state.refreshScrolling}>
          <HorizontalScrollingItem>
            {this.renderParcelDetailSection([
              {
                label: 'common.crop',
                value: parcel.currentSeedApplication ? parcel.currentSeedApplication.seed.crop.name : 'common.noCrop',
              },
              {
                label: 'common.area',
                value: `${Localization.num2str(parcel.area, intl.locale)} ha`,
              },
              { label: 'common.culture', value: parcel.parcelBlockList[0].lpisBlock.landUse.name },
            ])}
          </HorizontalScrollingItem>
          <HorizontalScrollingItem>
            {this.renderParcelDetailSection([
              {
                label: 'ParcelDetail.lastAction',
                value: this.getLastParcelActionText(parcel, isFetching),
              },
              {
                label: 'common.validFrom',
                value: <FormattedDate day="numeric" month="numeric" value={parcel.validFrom} year="numeric" />,
              },
              { label: 'common.zone', value: parcel.zone ? parcel.zone.name : '-' },
            ])}
          </HorizontalScrollingItem>
          <HorizontalScrollingItem>
            {this.renderParcelDetailSection([
              {
                label: 'common.altitude',
                value: `${Localization.num2str(parcel.parcelBlockList[0].lpisBlock.altitude, intl.locale)} m`,
              },
              {
                label: 'common.slope',
                value: `${Localization.num2str(parcel.parcelBlockList[0].lpisBlock.slope, intl.locale)}\xB0`,
              },
              ...(countryCode === 'CZ'
                ? [{ label: 'term.NSA', value: <YesNo value={parcel.nitrateVulnerable} /> }]
                : [{}]),
            ])}
          </HorizontalScrollingItem>
        </HorizontalScrolling>
      </div>
    );
  }
}

MapParcelDetailBody.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default injectIntl(withStyles(styles)(MapParcelDetailBody));
