/**
 * Created by ondrejzvara on 16.3.17.
 */


/*@ngInject*/
export default function cfDatepickerStart($rootScope, $timeout) {

    var directive = {
        restrict: 'E',
        template: require('./cf-datepicker-start.html'),
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            minStartDate: '=?min',
            maxStartDate: '=?max',
            validators: "@?",
            label: "@?",
            onChange: "&?"
        },
        link: function (scope) {

            scope.validators = scope.validators ? scope.validators : undefined; // by default it is not required
            scope.onChange = scope.onChange || angular.noop;
            scope.onDateStartBlur = onDateStartBlur;

            $rootScope.$on('dateEndChange', function(evt, dateEnd){
                if(new Date(dateEnd).getTime() < new Date(scope.model).getTime() && scope.model !== null){
                    scope.model = dateEnd;
                }
            });

            activate();

            function activate(){
                $timeout(function(){
                    $rootScope.$broadcast('dateStartChange', scope.model);
                });
            }

            function onDateStartBlur(){
                $timeout(function(){
                    $rootScope.$broadcast('dateStartChange', scope.model);
                    scope.onChange(scope.model);
                });
            }
        }
    };
    return directive;
}
