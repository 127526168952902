import React, { Component } from 'react';

import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ReferenceLine, Cell, Tooltip } from 'recharts';

import CfChartWrapper from '../../../../../../shared/components/charts/CfChartWrapper/CfChartWrapper';
import { colorLuminance } from '../../../../../../shared/misc/colorLuminance';
import XAxisLabel from '../../../shared/components/XAxisLabel/XAxisLabel';
import CropQualityTooltip from '../CropQualityTooltip/CropQualityTooltip';

const styles = () => ({
  wrapper: {
    height: '250px',
  },
});

const ReferenceLineLabel = props => {
  // eslint-disable-next-line
  const {
    value,
    viewBox: { x, y },
  } = props;

  return (
    <text
      dy={-5}
      style={{ fontSize: 12, fontWeight: 'bold', fill: grey[400] }}
      textAnchor="start"
      transform="translate(5)"
      x={x}
      y={y}
    >
      {value}
    </text>
  );
};

ReferenceLineLabel.propTypes = {
  viewBox: PropTypes.object,
  value: PropTypes.any,
};

ReferenceLineLabel.defaultProps = {
  viewBox: {},
  value: PropTypes.any,
};

const EllipseBar = props => {
  // eslint-disable-next-line
  const { zoneColor, zoneValue, zoneNumber, ...rest } = props;
  return <rect {...rest} rx="2" ry="2" />;
};

class CropQualityGraph extends Component {
  constructor(props) {
    super(props);

    this.yAxisProps = {
      ticks: [],
      tickLine: false,
      tick: { fontSize: 12 },
      allowDataOverflow: true,
      padding: { top: 1, bottom: 1 },
      domain: ['auto', 'auto'],
      width: 40,
    };

    this.xAxisProps = {
      dataKey: 'zoneNumber',
      tickLine: false,
      domain: ['dataMin', 'dataMax'],
    };

    this.innerChartProps = {
      isAnimationActive: false,
      dataKey: 'zoneValue',
      fillOpacity: 1,
    };

    this.tooltipProps = {
      filterNull: false,
      cursor: false,
    };

    this.state = {
      hoverIndex: null,
    };
  }

  setHoverIndex = hoverIndex => {
    this.setState({ hoverIndex });
  };

  formatZoneName = value =>
    `${this.props.intl.formatMessage({ id: 'ManagementZones.CropQualityGraph.zone' })} ${value}`;

  formatAvgName = () => this.props.intl.formatMessage({ id: 'ManagementZones.CropQualityGraph.avg' });

  render() {
    const { hoverIndex } = this.state;
    const { classes, isFetching } = this.props;
    const zonesData = this.props.data;
    const { average, data } = zonesData;

    return (
      <CfChartWrapper
        data={data}
        isFetching={isFetching}
        classes={{
          wrapper: classes.wrapper,
        }}
      >
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            barCategoryGap="20%"
            data={data}
            isAbove={false}
            margin={{ top: 0, right: 20, left: 0, bottom: 40 }}
            onMouseMove={bar => {
              if (bar.isTooltipActive) {
                this.setHoverIndex(bar.activeTooltipIndex);
              } else {
                this.setHoverIndex(null);
              }
            }}
          >
            <Tooltip {...this.tooltipProps} content={<CropQualityTooltip />} />

            <XAxis
              tick={
                <XAxisLabel
                  color={p => `#${data[p.payload?.index].zoneColor}`}
                  formatter={this.formatZoneName}
                  rotation={-45}
                />
              }
              {...this.xAxisProps}
            />
            <YAxis {...this.yAxisProps} />
            <Bar {...this.innerChartProps} shape={<EllipseBar />}>
              {data &&
                data.map((entry, index) => (
                  <Cell
                    fill={hoverIndex === index ? colorLuminance(`#${entry.zoneColor}`, -0.1) : `#${entry.zoneColor}`}
                    key={entry.zoneNumber}
                  />
                ))}
            </Bar>

            <ReferenceLine isFront stroke="#999999" strokeDasharray="2 2" y={0} />
            <ReferenceLine
              ifOverflow="extendDomain"
              isFront
              label={<ReferenceLineLabel value={this.formatAvgName()} />}
              stroke={grey[400]}
              y={average}
            />
          </BarChart>
        </ResponsiveContainer>
      </CfChartWrapper>
    );
  }
}

CropQualityGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default injectIntl(withStyles(styles)(CropQualityGraph));
