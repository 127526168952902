import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Localization from '../../../../../../shared/services/Localization.service';

const indexDataMap = {
  CW: {
    unit: 'cm',
    fractionDigits: 4,
    min: 0.0005,
    max: 0.07,
  },
  CAB: {
    unit: (
      <span>
        μg/cm<sup>2</sup>
      </span>
    ),
    fractionDigits: 0,
    min: 2,
    max: 80,
  },
  LAI: {
    unit: (
      <span>
        m<sup>2</sup>/m<sup>2</sup>
      </span>
    ),
    fractionDigits: 1,
    min: 0.1,
    max: 10,
  },
};

export const BioMonitoringGraphInfoDialog = props => {
  const { index, intl } = props;

  return (
    <p>
      <FormattedMessage
        id={`BioMonitoring.${index}.subheading`}
        values={{
          min: Localization.num2str(indexDataMap[index].min, intl.locale, indexDataMap[index].fractionDigits),
          max: Localization.num2str(indexDataMap[index].max, intl.locale, indexDataMap[index].fractionDigits),
          sup: chunks => <sup>{chunks}</sup>,
        }}
      />
    </p>
  );
};

BioMonitoringGraphInfoDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  index: PropTypes.string,
};

BioMonitoringGraphInfoDialog.defaultProps = {
  index: null,
};

export default injectIntl(BioMonitoringGraphInfoDialog);
