import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  spinner: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 114px)',
    zIndex: 999,
    borderRadius: '2px',
    backgroundColor: 'white',
    left: 0,
    top: '57px',
  },
});

const CfTableBodyLoader = (props) => {
  const { classes, columns } = props;
  const style = {
    top: props.top || '57px',
    height: `calc(100% - ${props.heightDiff || '114px'}`,
  };
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={Object.keys(columns).length}>
          <div className={classes.spinner} style={style}>
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

CfTableBodyLoader.propTypes = {
  columns: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  top: PropTypes.string,
  heightDiff: PropTypes.string,
};

CfTableBodyLoader.defaultProps = {
  top: null,
  heightDiff: null,
};

export default withStyles(styles)(CfTableBodyLoader);
