import React, { Fragment } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ValveFilledIcon from '../../../shared/icons/ValveFilledIcon';
import ValveOutlineIcon from '../../../shared/icons/ValveOutlinedIcon';

const styles = theme => ({
  valveStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
  },
  valveInactive: {
    color: theme.palette.grey[400],
  },
  valveActive: {
    color: '#296AF6',
  },
  valveName: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
});

function ValvesStatus(props) {
  const { classes, installed, valvesStatus } = props;
  return (
    <div className={classes.valveStatusWrapper}>
      {valvesStatus
        ? valvesStatus.map(({ isOpen, valveNumber }) => {
          let valveClass = classes.valveInactive;
          let ValveIcon = ValveOutlineIcon;
          let valveTooltipTranslationId = 'noninstalledUnit';
          if (installed) {
            valveClass = classes.valveActive;
            ValveIcon = isOpen ? ValveFilledIcon : ValveOutlineIcon;
            valveTooltipTranslationId = isOpen ? 'valveOpen' : 'valveClose';
          }
          return (
            <Tooltip
              key={valveNumber}
              title={
                <Fragment>
                  <div className={classes.valveName}>
                    <FormattedMessage id="IrrigationList.valve" /> {valveNumber + 1}
                  </div>
                  <div>
                    <FormattedMessage id={`ValvesStatus.${valveTooltipTranslationId}`} />
                  </div>
                </Fragment>
              }
            >
              <span className={classes.iconWrapper}>
                <ValveIcon className={`${classes.icon} ${valveClass}`} />
              </span>
            </Tooltip>
          );
        })
        : '-'}
    </div>
  );
}

ValvesStatus.propTypes = {
  valvesStatus: PropTypes.array,
  classes: PropTypes.object.isRequired,
  installed: PropTypes.bool,
};

ValvesStatus.defaultProps = {
  valvesStatus: [],
  installed: true,
};

export default withStyles(styles)(ValvesStatus);
