import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Cell } from 'recharts';

import CfChartWrapper from '../../../../../../shared/components/charts/CfChartWrapper/CfChartWrapper';
import { colorLuminance } from '../../../../../../shared/misc/colorLuminance';
import XAxisLabel from '../../../shared/components/XAxisLabel/XAxisLabel';
import CropVariabilityTooltip from '../CropVariabilityTooltip/CropVariabilityTooltip';

const styles = () => ({
  wrapper: {
    height: '250px',
  },
});

const StickBar = props => {
  // eslint-disable-next-line
  const { stroke, x, y, width, height } = props;
  return <line stroke={stroke} x1={x + width / 2 - 5} x2={x + width / 2 + 5} y1={y} y2={y} />;
};

const LineBar = props => {
  // eslint-disable-next-line
  const { stroke, x, y, width, height } = props;
  return <line stroke={stroke} strokeDasharray="4 3" x1={x + width / 2} x2={x + width / 2} y1={y} y2={y + height} />;
};

const EllipseBar = props => {
  // eslint-disable-next-line
  const { fill, x, y, width, height } = props;

  return <rect fill={fill} height={height} rx="2" ry="2" width={20} x={x + width / 2 - 10} y={y} />;
};

export class CropVariabilityGraph extends Component {
  constructor(props) {
    super(props);

    this.yAxisProps = {
      ticks: [],
      tickLine: false,
      tick: { fontSize: 12 },
      allowDataOverflow: true,
      padding: { top: 1, bottom: 1 },
      width: 40,
    };

    this.xAxisProps = {
      dataKey: 'date',
      tickLine: false,
      domain: ['dataMin', 'dataMax'],
      interval: 0,
    };

    this.innerChartProps = {
      isAnimationActive: false,
      fillOpacity: 1,
      strokeWidth: 2,
    };

    this.tooltipProps = {
      filterNull: false,
      cursor: false,
    };

    this.state = {
      hoverIndex: null,
    };
  }

  setHoverIndex = hoverIndex => {
    this.setState({ hoverIndex });
  };

  formatDateTick = value => this.props.intl.formatDate(value, { day: 'numeric', month: 'numeric', year: 'numeric' });

  render() {
    const { hoverIndex } = this.state;
    const { classes, data, isFetching } = this.props;
    const min = Math.min(...data.map(i => i.min));
    return (
      <CfChartWrapper
        data={data}
        isFetching={isFetching}
        classes={{
          wrapper: classes.wrapper,
        }}
      >
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            data={data}
            margin={{ top: 0, right: 20, left: 0, bottom: 40 }}
            onMouseMove={bar => {
              if (bar.isTooltipActive) {
                this.setHoverIndex(bar.activeTooltipIndex);
              } else {
                this.setHoverIndex(null);
              }
            }}
          >
            <Tooltip {...this.tooltipProps} content={<CropVariabilityTooltip />} />

            <XAxis tick={<XAxisLabel formatter={this.formatDateTick} rotation={-45} />} {...this.xAxisProps} />
            <YAxis {...this.yAxisProps} domain={[() => min, 'auto']} />
            <Bar dataKey="min" shape={<StickBar />} stackId="a" stroke="#BFBFBF" {...this.innerChartProps} />
            <Bar dataKey="q1" shape={<LineBar />} stackId="a" stroke="#BFBFBF" {...this.innerChartProps} />
            <Bar dataKey="median" shape={<EllipseBar />} stackId="a" {...this.innerChartProps}>
              {data &&
                data.map((entry, index) => (
                  <Cell fill={hoverIndex === index ? colorLuminance('#EBA607', -0.1) : '#EBA607'} key={entry.date} />
                ))}
            </Bar>

            <Bar dataKey="q3" shape={<LineBar />} stackId="a" stroke="#BFBFBF" {...this.innerChartProps} />
            <Bar dataKey="max" shape={<StickBar />} stackId="a" stroke="#BFBFBF" {...this.innerChartProps} />
          </BarChart>
        </ResponsiveContainer>
      </CfChartWrapper>
    );
  }
}

CropVariabilityGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default injectIntl(withStyles(styles)(CropVariabilityGraph));
