import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { getItems } from '../../shared/api/automation/rules/rules.selectors';

export const getNotificationListPage = state => state.ui.notificationList.page;
export const getNotificationListOrder = state => state.ui.notificationList.order;
export const getNotificationListOrderBy = state => state.ui.notificationList.orderBy;
export const getNotificationListRowsPerPage = state => state.ui.notificationList.rowsPerPage;
export const getNotificationListSelected = state => state.ui.notificationList.selected;
export const getNotificationListTextFilter = state => state.ui.notificationList.textFilter;

export const getRulesListItems = state => getItems(state);

export const getNotificationListSelectedOnPage = createSelector(
  getRulesListItems,
  getNotificationListSelected,
  (rules, selected) =>
    intersection(
      rules.map(rule => rule.id),
      selected,
    ),
);
