import { setTableSelected } from '../../../../../shared/actions/table.actions';

import * as types from './parcelActivities.constants';

import { NAMESPACE as namespace } from '../reducer/parcelActivities.reducer';

import { deleteActions as deleteActionsApi } from '../../../../../shared/api/core/actions/actions.api';
import { getParcelActions, getParcelSeedingInfo } from '../../../../../shared/api/core/parcels/parcels.api';
import { deleteVariableExpenses as deleteVariableExpensesApi } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';

export const fetchActivities = (parcelId, params) => dispatch => {
  dispatch(getParcelActions(null, parcelId, params));
};

export const setDateFilter = dateFilter => ({
  type: types.SET_PARCEL_ACTIVITIES_DATE_FILTER,
  dateFilter,
});

export const deleteActivities = (parcelId, actionIds, variableActionIds) => (dispatch, getState) => {
  dispatch(deleteActionsApi(actionIds))
    .then(() => {
      const promises = [];
      if (variableActionIds?.length) {
        variableActionIds.forEach(variableActionId => {
          if (actionIds.includes(variableActionId)) {
            promises.push(dispatch(deleteVariableExpensesApi(variableActionId)));
          }
        });
      }

      return Promise.all(promises).then(() => {
        const state = getState();
        dispatch(getParcelActions(null, parcelId, state.ui.parcelActivities));
        dispatch(getParcelSeedingInfo(null, parcelId));
      });
    })
    .finally(() => {
      dispatch(setTableSelected([], namespace));
    });
};
