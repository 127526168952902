export const handleEagriError = e => {
  let result = null;
  if (e.status === 503) {
    result = 'eagri.unavailable';
  }
  if (e.status === 403) {
    result = 'eagri.forbidden';
  }
  return errorObject(result, e.status);
};

function errorObject(msgCode, status) {
  return msgCode && { handled: true, msgCode, status };
}
