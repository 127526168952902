import React from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  durationTime: {
    fontWeight: '500',
    fontSize: ({ checked, manualAction }) => ((checked || manualAction) ? '0.875rem' : '0.75rem'),
    lineHight: '140%',
    textAlign: 'center',
    color: ({ active, checked }) => ((checked && !active) ? theme.palette.grey[400] : theme.palette.primary.main),
    margin: ({ checked, manualAction }) => ((checked || manualAction) ? '0rem 0.375rem' : '0rem 0.3125rem'),
  },
  buttonIcon: {
    padding: 0,
    color: theme.palette.grey[400],
  },
  icon: {
    width: ({ checked, manualAction }) => ((checked || manualAction) ? '1.25rem' : '1rem'),
    height: ({ checked, manualAction }) => ((checked || manualAction) ? '1.25rem' : '1rem'),
  },
}));

const IrrigationCounter = ({ active, checked, count, handleDecrement, handleIncrement, manualAction }) => {
  const classes = useStyles({ checked, active, manualAction });

  const increment = () => {
    handleIncrement();
  };

  const decrement = () => {
    handleDecrement();
  };

  return <>
    <IconButton
      aria-label="reduce"
      className={classes.buttonIcon}
      onClick={decrement}
      size="large">
      <RemoveCircleOutlineIcon className={classes.icon} />
    </IconButton>
    <span className={classes.durationTime} data-test={'event-duration'}>
      {count / 60} <FormattedMessage id="Irrigation.planCalendarModal.min" />
    </span>
    <IconButton
      aria-label="increase"
      className={classes.buttonIcon}
      onClick={increment}
      size="large">
      <AddCircleOutlineIcon className={classes.icon} />
    </IconButton>
  </>;
};

IrrigationCounter.propTypes = {
  count: PropTypes.number.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  manualAction: PropTypes.bool,
};

IrrigationCounter.defaultProps = {
  active: false,
  manualAction: false,
};

export default IrrigationCounter;
