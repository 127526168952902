import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  homeControl: {
    zIndex: 9,
    position: 'absolute',
    right: '15px',
    bottom: 103,
  },
};

function HomeControl(props) {
  const { classes, zoomToHome } = props;
  return (
    <div className={`ol-control ${classes.homeControl}`}>
      <button data-test="map-home" onClick={zoomToHome}>
        <HomeIcon />
      </button>
    </div>
  );
}

HomeControl.propTypes = {
  classes: PropTypes.object.isRequired,
  zoomToHome: PropTypes.func.isRequired,
};

export default withStyles(styles)(HomeControl);
