import { getTopLeft, getWidth } from 'ol/extent';
import MVT from 'ol/format/MVT';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorTile from 'ol/layer/VectorTile';
import { get as getProjection } from 'ol/proj';
import BingMaps from 'ol/source/BingMaps';
import Static from 'ol/source/ImageStatic';
import TileWMS from 'ol/source/TileWMS';
import VectorTileSource from 'ol/source/VectorTile';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';

export const PUBLIC_LPIS_CONFIG = {
  TYPE: 'lpis_block_public',
  LAYER_ID: 'lpis_block_public',
  MAX_RESOLUTION: 12,
};

export const PRIVATE_LPIS_CONFIG = {
  TYPE: 'vector_tile',
  LAYER_ID: 'lpis_block',
};

export default class LayersConfigService {
  constructor(geoserverWorkspace, geoserverUrl, farmId, farmExtent, bingKey, lpisLayerName) {
    this.geoserverWorkspace = geoserverWorkspace;
    this.geoserverUrl = geoserverUrl;
    this.farmId = farmId;
    this.farmExtent = farmExtent;
    this.bingKey = bingKey;
    this.layersOptions = this.setupLayersOptions();
    this.lpisLayerName = lpisLayerName;
  }

  setupLayersOptions() {
    return {
      bing: layerConfig => LayersConfigService.getBingLayer(this.bingKey, layerConfig.name),
      wms: layerConfig =>
        LayersConfigService.getWMSLayer(
          this.geoserverWorkspace,
          this.geoserverUrl,
          this.farmId,
          layerConfig.shared,
          layerConfig.name,
        ),
      wmts: layerConfig => LayersConfigService.getWMTS(layerConfig.name, layerConfig.config),
      vector_tile: (layerConfig, layerStyle) =>
        LayersConfigService.getVectorTileLayer(
          this.geoserverWorkspace,
          this.geoserverUrl,
          this.farmId,
          layerConfig.name,
          this.farmExtent,
          layerStyle,
          layerConfig.config,
          layerConfig.zIndex,
        ),
      image: layerConfig => LayersConfigService.getImageLayer(layerConfig),
      [PUBLIC_LPIS_CONFIG.TYPE]: (layerConfig, layerStyle) =>
        LayersConfigService.getVectorTileLayerPublicLpis(
          this.geoserverUrl,
          layerStyle,
          layerConfig.config,
          layerConfig.zIndex,
          this.lpisLayerName,
        ),
    };
  }

  getMapLayerFromConfig(layerConfig, layerStyle = null) {
    return this.layersOptions[layerConfig.type](layerConfig, layerStyle);
  }

  static getBingLayer(bingKey, name, zIndex) {
    return new TileLayer({
      preload: Infinity,
      zIndex,
      source: new BingMaps({
        key: bingKey,
        imagerySet: name,
        maxZoom: 19,
      }),
    });
  }

  static getWMTS(name, wmtsConfig) {
    const { format, matrixLevels, matrixSet, maxResolution, style, url, zIndex } = wmtsConfig;
    const projection = getProjection('EPSG:3857');
    const size = getWidth(projection.getExtent()) / 256;

    const resolutions = new Array(matrixLevels);
    const matrixIds = new Array(matrixLevels);
    for (let z = 0; z < matrixLevels; z += 1) {
      resolutions[z] = size / 2 ** z;
      matrixIds[z] = z;
    }

    return new TileLayer({
      maxResolution,
      zIndex,
      source: new WMTS({
        url,
        layer: name,
        matrixSet,
        format: format || 'image/png',
        style: style || 'default',
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(projection.getExtent()),
          resolutions,
          matrixIds,
        }),
      }),
    });
  }

  static getVectorTileLayer(geoserverWorkspace, geoserverUrl, farmId, name, layerExtent, style, config, zIndex) {
    const declutter = config ? config.declutter : false;
    const geoserverLayerName = `${geoserverWorkspace}:${geoserverWorkspace}_${farmId}_${name}`;
    return new VectorTile({
      renderMode: 'hybrid',
      zIndex,
      source: new VectorTileSource({
        format: new MVT(),
        cacheSize: 0,
        url: `${geoserverUrl}/gwc/service/tms/1.0.0/${geoserverLayerName}@${'EPSG:3857'}@pbf/{z}/{x}/{-y}.pbf?cache=${Date.now()}`,
      }),
      extent: layerExtent,
      style,
      declutter,
    });
  }

  static getVectorTileLayerPublicLpis(geoserverUrl, style, config, zIndex, lpisLayerName) {
    const declutter = config ? config.declutter : false;
    return new VectorTile({
      renderMode: 'hybrid',
      zIndex,
      source: new VectorTileSource({
        format: new MVT(),
        cacheSize: 2000,
        url: `${geoserverUrl}/gwc/service/tms/1.0.0/${lpisLayerName}@${'EPSG:3857'}@pbf/{z}/{x}/{-y}.pbf?cache=${Date.now()}`,
      }),
      style,
      declutter,
      maxResolution: PUBLIC_LPIS_CONFIG.MAX_RESOLUTION,
    });
  }

  static getWMSLayer(geoserverWorkspace, geoserverUrl, farmId, shared, name, zIndex) {
    const farmPrefix = shared ? 'shared_cz' : farmId;
    const geoserverLayerName = `${geoserverWorkspace}:${geoserverWorkspace}_${farmPrefix}_${name}`;
    return new TileLayer({
      zIndex,
      source: new TileWMS({
        url: `${geoserverUrl}/wms`,
        params: { LAYERS: geoserverLayerName },
        serverType: 'geoserver',
      }),
    });
  }

  static getImageLayer({ attributions, imageExtent, url, zIndex }) {
    const layer = new ImageLayer({
      zIndex,
      source: new Static({
        attributions,
        url,
        projection: getProjection('EPSG:3857'),
        imageExtent,
      }),
    });

    layer.on('precompose', evt => {
      evt.context.imageSmoothingEnabled = false;
      evt.context.webkitImageSmoothingEnabled = false;
      evt.context.mozImageSmoothingEnabled = false;
      evt.context.msImageSmoothingEnabled = false;
    });

    layer.on('postcompose', evt => {
      evt.context.imageSmoothingEnabled = false;
      evt.context.webkitImageSmoothingEnabled = false;
      evt.context.mozImageSmoothingEnabled = false;
      evt.context.msImageSmoothingEnabled = false;
    });

    return layer;
  }
}
