import localStorageService from 'local-storage';

export default class LocalStorage {
  static saveToLocalStorage(data, id) {
    try {
      const result = localStorageService.set(id, data);
      if (!result) { throw new Error('Value was not set into local storage'); }
    } catch (err) {
      throw new Error(err);
    }
  }

  static loadFromLocalStorage(id) {
    try {
      const data = localStorageService.get(id);
      if (data === null) {
        return undefined;
      }
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  static removeFromLocalStorage(id) {
    try {
      return localStorageService.remove(id);
    } catch (err) {
      throw new Error(err);
    }
  }
}
