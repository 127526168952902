import React, { FC } from 'react';

import { makeStyles } from '@mui/styles';

import CfTextFilter from '../../../../../shared/containers/CfTextFilter/CfTextFilter';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 0,
  },
}));

interface Props {
  namespace: string
}

const IrrigationAdminTextFilter: FC<Props> = ({
  namespace,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CfTextFilter name="irrigation-admin-text-filter" namespace={namespace} translId="IrrigationAdmin.textFilter" />
    </div>
  );
};

export default IrrigationAdminTextFilter;
