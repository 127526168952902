import React from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

const styles = {
  tooltip: {
    marginBottom: 4,
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  spanMessageMargin: {
    marginLeft: '3px',
  },
  date: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
};

function DeviceActivityInfo(props) {
  const { classes, dateFrom, dateTo, displayIcon, isActive, isHistoric } = props;

  let dateActivityMessage = isActive ? 'dateOfActivation' : 'dateOfDeactivation';
  dateActivityMessage = isHistoric ? 'periodOfActivity' : dateActivityMessage;

  return (
    <Tooltip
      placement="bottom"
      classes={{
        tooltip: classes.tooltip,
      }}
      title={
        <span>
          <div>
            <FormattedMessage id={`DeviceActivityInfo.${dateActivityMessage}`} />
          </div>
          <div className={classes.date}>
            {dateFrom && (
              <span>
                <FormattedDate day="numeric" month="long" value={dateFrom} year="numeric" />
                <span>, </span>
                <FormattedTime hour="2-digit" minute="2-digit" value={dateFrom} />
              </span>
            )}
            {dateTo && <span> &#8211; </span>}
            {dateTo && (
              <span>
                <FormattedDate day="numeric" month="long" value={dateTo} year="numeric" />
                <span>, </span>
                <FormattedTime hour="2-digit" minute="2-digit" value={dateTo} />
              </span>
            )}
          </div>
        </span>
      }
    >
      <span className={classes.itemContent}>
        {displayIcon && <DateRangeIcon />}
        <span className={classes.spanMessageMargin}>
          {dateFrom && <FormattedDate day="numeric" month="numeric" value={dateFrom} year="numeric" />}
          {dateTo && <span> &#8211; </span>}
          {dateTo && <FormattedDate day="numeric" month="numeric" value={dateTo} year="numeric" />}
        </span>
      </span>
    </Tooltip>
  );
}

DeviceActivityInfo.propTypes = {
  isActive: PropTypes.bool,
  isHistoric: PropTypes.bool,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  classes: PropTypes.object,
  displayIcon: PropTypes.bool,
};

DeviceActivityInfo.defaultProps = {
  isActive: true,
  isHistoric: false,
  dateFrom: null,
  dateTo: null,
  classes: {},
  displayIcon: true,
};

export default withStyles(styles)(DeviceActivityInfo);
