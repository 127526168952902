import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => {
  const { palette } = theme;

  return {
    greyItem: {
      color: palette.grey[600],
    },
  };
};

class TableNoteCell extends Component {
  constructor(props) {
    super(props);

    this.maxCount = 5;
    this.itemSeparator = ',';
    this.state = {
      parcels: [],
      crop: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { note } = this.props;
    if (prevProps.note !== note && note?.parcelNames?.length) {
      this.setState({
        parcels: note.parcelNames.sort(),
        crop: note.cropName,
      });
    }
  }

  renderFieldItem() {
    const { parcels } = this.state;
    const maxKey = parcels.length >= this.maxCount ? this.maxCount : parcels.length;
    return parcels.slice(0, maxKey).map((elm, index) => (
      <span className="parcel-item" key={index.toString()}>
        {elm}
        {index !== maxKey - 1 ? `${this.itemSeparator} ` : null}
      </span>
    ));
  }

  renderCropItem() {
    return <span className={this.props.classes.greyItem}>{this.state.crop || null}</span>;
  }

  render() {
    const { parcels } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <div>
          {this.renderFieldItem()}
          {parcels.length > this.maxCount && (
            <span>
              {`${this.itemSeparator} `}
              <span className={classes.greyItem}>
                <FormattedMessage
                  id="common.noteMoreWord"
                  values={{
                    count: parcels.length - this.maxCount,
                  }}
                />
              </span>
            </span>
          )}
        </div>
        <div>{this.renderCropItem()}</div>
      </div>
    );
  }
}

TableNoteCell.propTypes = {
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.object.isRequired,
};

TableNoteCell.defaultProps = {
  note: null,
};

export default withStyles(styles)(TableNoteCell);
