import React, { Component, Fragment } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import CfDialogTabs from '../../../shared/components/common/CfDialogTabs/CfDialogTabs';
import CfInfoDialog from '../../../shared/components/common/CfInfoDialog/CfInfoDialog';
import SensorsPestPredictionService from '../../services/SensorsPestPrediction.service';
import InfoList from '../InfoList/InfoList';

const style = theme => ({
  infoIcon: {
    width: 20,
    height: 20,
  },
  subheading: {
    color: theme.palette.text.secondary,
  },
  infoButton: {
    margin: '0px 6px',
  },
  header: {
    marginBottom: 24,
  },
});

class PestPredictionDialog extends Component {
  constructor(props) {
    super(props);
    this.tabsInfo = [
      {
        id: 'overview',
        labelId: 'PestPrediction.dialogTab.basicOverview',
      },
      {
        id: 'infoTable',
        labelId: 'PestPrediction.dialogTab.phases',
      },
    ];
    this.tabs = {
      overview: 'overview',
      infoTable: 'infoTable',
    };
    this.state = {
      activeTab: this.tabs.overview,
    };
  }

  activateTab(tabId) {
    this.setState({
      activeTab: tabId,
    });
  }

  renderOverview(pestPredictionConfig) {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.header}>
          <FormattedMessage id={`${pestPredictionConfig.general1}`} />{' '}
          {pestPredictionConfig.general2 && (
            <span className={classes.subheading}>
              <FormattedMessage id={`${pestPredictionConfig.general2}`} />
            </span>
          )}
        </div>
        {pestPredictionConfig.general3 && (
          <div className={classes.subheading}>
            <FormattedMessage id={`${pestPredictionConfig.general3}`} />
          </div>
        )}
        {pestPredictionConfig.limitDescription &&
          pestPredictionConfig.colorsAndDescriptions.map((description, index) =>
            this.renderPestDescription(description, index),
          )}
      </Fragment>
    );
  }

  renderPestDescription(description, index) {
    return (
      <span key={index}>
        <Grid alignItems="flex-start" container justifyContent="center" spacing={3}>
          <Grid item style={{ color: description.color, fontWeight: 'bold' }} xs={3}>
            {index === 0 ? `0 - ${description.value}` : description.value}
          </Grid>
          <Grid item xs={9}>
            <FormattedMessage id={description.translationId} />
          </Grid>
        </Grid>
      </span>
    );
  }

  renderContentWithTabs(pestPredictionConfig, infoTable) {
    const { classes } = this.props;
    const { activeTab } = this.state;
    return (
      <Fragment>
        <div className={classes.header}>
          <CfDialogTabs activateTab={tabId => this.activateTab(tabId)} tabsInfo={this.tabsInfo} tabValue={activeTab} />
        </div>
        {activeTab === this.tabs.overview && this.renderOverview(pestPredictionConfig)}
        {activeTab === this.tabs.infoTable && (
          <Fragment>
            <div className={classes.header}>
              <FormattedMessage id={`${pestPredictionConfig.name}.general.table.1`} />
              <p className={classes.subheading}>
                <FormattedMessage id={`${pestPredictionConfig.name}.general.table.2`} />
              </p>
            </div>
            <Divider className={classes.divider} variant="fullWidth" />
            <InfoList list={infoTable} />
          </Fragment>
        )}
      </Fragment>
    );
  }

  render() {
    const { classes, pestPredictionConfig } = this.props;
    const { formatMessage } = this.props.intl;
    const pestName = formatMessage({ id: pestPredictionConfig.name });
    const infoTable = SensorsPestPredictionService.getInfoTable(pestPredictionConfig.id);
    return (
      <CfInfoDialog
        classes={{ infoIcon: classes.infoIcon, infoButton: classes.infoButton }}
        heading={<FormattedMessage id="PestPrediction.header" values={{ name: pestName }} />}
      >
        {infoTable.length > 1
          ? this.renderContentWithTabs(pestPredictionConfig, infoTable)
          : this.renderOverview(pestPredictionConfig)}
      </CfInfoDialog>
    );
  }
}

PestPredictionDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  pestPredictionConfig: PropTypes.object.isRequired,
};

PestPredictionDialog.defaultProps = {
  classes: {},
  opened: false,
};

export default withStyles(style)(injectIntl(PestPredictionDialog));
