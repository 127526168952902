import React, { useCallback } from 'react';

import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

function CfFormikTimePicker(props) {
  const {
    children,
    customDate,
    customSeconds,
    field,
    form,
    format,
    maxDateMessage,
    minDateMessage,
    validateOnBlur,
    ...restProps } = props;

  const error = form.errors[field.name];
  const intl = useIntl();

  const parseKeyval = useCallback((keyboardValue) => {
    const timeSplit = keyboardValue.split(/:/);
    const hours = parseInt(timeSplit[0]);
    const minutes = parseInt(timeSplit[1]);
    const date = customDate || moment();
    const isValidTimeString = timeSplit.length === 2 && hours >= 0 && minutes >= 0;
    if (isValidTimeString) {
      const val = moment(date).hours(hours).minutes(minutes).seconds(customSeconds);
      form.setFieldValue(
        field.name,
        val);
    }
  }, [field.name, form, customDate, customSeconds]);

  const handleChange = useCallback((value, keyboardValue) => {
    form.setFieldValue(field.name, value);
    if (keyboardValue?.length === 5) {
      // to handle time edited by keyboard not the native selector
      parseKeyval(keyboardValue);
    }
  }, [field.name, form, parseKeyval]);

  return (
    <TimePicker
      ampm={false}
      cancelLabel={intl.formatMessage({ id: 'common.cancel' })}
      clearable={true}
      clearLabel={intl.formatMessage({ id: 'common.clear' })}
      inputFormat={format}
      invalidDateMessage={''}
      maxDateMessage
      minDateMessage
      name={field.name}
      okLabel={intl.formatMessage({ id: 'common.select' })}
      placeholder="09:00"
      value={field.value}
      {...restProps}
      onChange={handleChange}
      renderInput={(props) =>
        <TextField
          {...props}
          error={!!error}
          helperText={!!error && <FormattedMessage id={error} />}
          name={field.name}
      />}
    >
      {children}
    </TimePicker>
  );
}

CfFormikTimePicker.propTypes = {
  children: PropTypes.node,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  format: PropTypes.string,
  minDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  customDate: PropTypes.object,
  customSeconds: PropTypes.number,
};

CfFormikTimePicker.defaultProps = {
  children: null,
  validateOnBlur: false,
  format: 'HH:mm',
  minDateMessage: null,
  maxDateMessage: null,
  customSeconds: 0,
};

export default CfFormikTimePicker;
