import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as pestTypes from '../../constants/pestTypes.constants';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SensorsPestPredictionService from '../../services/SensorsPestPrediction.service';

const styles = () => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
  },
  popper: {
    opacity: 1,
  },
  bigCircle: {
    borderRadius: 13,
    width: 'fit-content',
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 'bold',
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
    marginRight: 6,
  },
});

class PestPredictionValueLabel extends Component {
  getLastHistoryDayCount = (pestPredictionName, historyData, isAdditional = false) => {
    if (historyData && historyData.length > 0) {
      let filtered = historyData;
      if (pestPredictionName === pestTypes.FUSARIUM_HEAD_BLIGHT) {
        const validValues = isAdditional ? [2, 3] : [1, 3];
        filtered = historyData.filter(item => validValues.includes(item.value));
      }
      const daysCount = filtered.map(item => moment().diff(item.date, 'days'));
      if (!daysCount.length) return undefined;
      return Math.min(...daysCount);
    }
    return undefined;
  };

  render() {
    const { classes, historyData, isAdditional, pestPredictionName, predictionValue } = this.props;
    const pestPredictionValueLimit = SensorsPestPredictionService.getPredictionTypeDescriptionByValue(
      pestPredictionName,
      predictionValue,
      isAdditional,
    );

    const { color: pestColor, translationId } = pestPredictionValueLimit || {};
    const maxPossibleValue = SensorsPestPredictionService.getPredictionMaxLimit(pestPredictionName);
    const dayCount = this.getLastHistoryDayCount(pestPredictionName, historyData, isAdditional);
    const maxPestPredictionValue = predictionValue > maxPossibleValue ? maxPossibleValue : predictionValue;
    return (
      <span className={classes.bigCircle} data-test="current-progress" style={{ backgroundColor: pestColor }}>
        {pestPredictionName === pestTypes.CORN_BORER && (
          <span>
            <CfFormattedNumber maximumFractionDigits={0} minimumFractionDigits={0} value={maxPestPredictionValue} />
            {'\u00A0-\u00A0'}
          </span>
        )}
        {pestPredictionName !== pestTypes.CORN_BORER && predictionValue === 0 && dayCount ? (
          <FormattedMessage id="PestPrediction.conditionsOccurrence" values={{ count: dayCount }} />
        ) : (
          <FormattedMessage
            id={translationId}
            values={
              predictionValue !== undefined
                ? {
                  count: predictionValue,
                }
                : {}
            }
          />
        )}
      </span>
    );
  }
}

PestPredictionValueLabel.propTypes = {
  classes: PropTypes.object,
  predictionValue: PropTypes.number.isRequired,
  pestPredictionName: PropTypes.string.isRequired,
  historyData: PropTypes.array,
  isAdditional: PropTypes.bool,
};

PestPredictionValueLabel.defaultProps = {
  classes: {},
  historyData: [],
  isAdditional: false,
};

export default withStyles(styles)(PestPredictionValueLabel);
