import * as types from './countries.constants';

export default () => (
  state = {
    isFetchingCountries: false,
    errorCountries: {},
    errorCountry: {},
    items: [],
    defaultCountry: '',
    isFetchingCountry: false,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return {
        ...state,
        isFetchingCountries: true,
        errorCountries: {},
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingCountries: false,
        items: action.payload,
      };
    case types.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingCountries: false,
        errorCountries: action.payload,
      };
    case types.RESET_COUNTRIES:
      return {
        ...state,
        items: [],
      };
    case types.GET_COUNTRY:
      return {
        ...state,
        isFetchingCountry: true,
        errorCountry: {},
      };
    case types.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetchingCountry: false,
        defaultCountry: action.payload.data,
      };
    case types.GET_COUNTRY_FAILURE:
      return {
        ...state,
        isFetchingCountry: false,
        defaultCountry: '',
        errorCountry: action.payload,
      };
    case types.RESET_COUNTRY:
      return {
        ...state,
        defaultCountry: '',
      };
    default:
      return state;
  }
};
