import { Component, createElement } from 'react';

import { Button } from '@mui/material';
import PropTypes from 'prop-types';

export default class CfButton extends Component {
  render() {
    const { ...props } = this.props;
    return createElement(Button, {
      ref: 'component',
      ...props,
    });
  }
}

CfButton.propTypes = {
  classes: PropTypes.object,
};

CfButton.defaultProps = {
  classes: {},
};
