import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_METHODS as methods, API_MODULES as modules } from '../../api.constants';
import * as types from './rules.constants';

export const getRules = params => ({
  [RSAA]: {
    endpoint: `rules?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.AUTOMATION,
    types: [types.GET_RULES, types.GET_RULES_SUCCESS, types.GET_RULES_ERROR],
  },
});

export const resetRules = () => ({
  type: types.RESET_RULES,
});

export const getRule = ruleId => ({
  [RSAA]: {
    endpoint: `rules/${ruleId}?`,
    method: methods.GET,
    module: modules.AUTOMATION,
    types: [types.GET_RULE, types.GET_RULE_SUCCESS, types.GET_RULE_ERROR],
  },
});

export const resetRule = () => ({
  type: types.RESET_RULE,
});

export const deleteRules = rulesIds => ({
  [RSAA]: {
    endpoint: `rules/${rulesIds.join(',')}?`,
    method: methods.DELETE,
    module: modules.AUTOMATION,
    types: [types.DELETE_RULES, types.DELETE_RULES_SUCCESS, types.DELETE_RULES_ERROR],
  },
});

export const updateRule = (ruleId, ruleDto) => ({
  [RSAA]: {
    endpoint: `rules/${ruleId}?`,
    method: methods.PUT,
    body: JSON.stringify(ruleDto),
    module: modules.AUTOMATION,
    types: [types.UPDATE_RULE, types.UPDATE_RULE_SUCCESS, types.UPDATE_RULE_ERROR],
  },
});

export const patchRule = (ruleId, newRuleProperties) => ({
  [RSAA]: {
    endpoint: `rules/${ruleId}?`,
    method: methods.PATCH,
    body: JSON.stringify(newRuleProperties),
    module: modules.AUTOMATION,
    types: [types.PATCH_RULE, types.PATCH_RULE_SUCCESS, types.PATCH_RULE_ERROR],
  },
});

export const createRule = ruleDto => ({
  [RSAA]: {
    endpoint: 'rules?',
    method: methods.POST,
    body: JSON.stringify(ruleDto),
    module: modules.AUTOMATION,
    types: [types.CREATE_RULE, types.CREATE_RULE_SUCCESS, types.CREATE_RULE_ERROR],
  },
});
