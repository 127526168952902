import React from 'react';

import PropTypes from 'prop-types';

import ToolbarBtn from '../ToolbarBtn/ToolbarBtn';

export default function ToolbarBtnSubmit(props) {
  const { disabled, onSubmit, translationId } = props;
  return (
    <ToolbarBtn active={true} callback={onSubmit} disabled={disabled} testId="confirm" translationId={translationId} />
  );
}

ToolbarBtnSubmit.propTypes = {
  translationId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ToolbarBtnSubmit.defaultProps = {
  disabled: false,
};
