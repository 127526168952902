import toNumber from 'lodash/toNumber';
import moment from 'moment';

import UnitService from '../../../../../shared/services/Unit.service';

const transactionTypes = [
  { id: 'PURCHASE', name: 'Stores.PURCHASE', sign: '+' },
  { id: 'PRODUCTION', name: 'Stores.PRODUCTION', sign: '+' },
  { id: 'CONSUMPTION_SERVICE', name: 'Stores.CONSUMPTION_SERVICE', sign: '-' },
  { id: 'SALE', name: 'Stores.SALE', sign: '-' },
  { id: 'OTHER_OUT', name: 'Stores.OTHER_OUT', sign: '-' },
];

export default class StoresService {
  static getAllowedUnits(materialTypeId) {
    const units = UnitService.getUnits();
    let allowedUnitsKeys = [];
    switch (materialTypeId) {
      case 'CHEMISTRY':
      case 'FERTILIZER':
        allowedUnitsKeys = ['kg', 't', 'q', 'l', 'm3'];
        break;
      default:
        allowedUnitsKeys = ['kg', 't', 'q', 'vj'];
    }

    return units.filter(unit => allowedUnitsKeys.includes(unit.id));
  }

  static getTransactionDto(values, transaction) {
    // store all values with the current time
    const timeSource = transaction ? moment(transaction.date) : moment();
    values.date.set({
      hour: timeSource.get('hour'),
      minute: timeSource.get('minute'),
      second: timeSource.get('second'),
    });

    const dto = {
      material: {
        id: values.expense.id,
        type: values.expense.type,
        name: values.expense.name,
      },
      type: values.type.id,
      date: values.date.toISOString(),
      amount: toNumber(values.amount),
      unitId: values.unit.id,
      note: values.note || '',
    };

    if (values.id) dto.id = values.id;
    return dto;
  }

  static getTransactionTypes(sign) {
    return transactionTypes.filter(type => type.sign === sign);
  }

  static getTransactionType(typeId) {
    return transactionTypes.find(type => type.id === typeId);
  }
}
