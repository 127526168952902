import React, { Component, Fragment } from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSentinelAdminParcelsSuggestions } from '../../selectors/sentinel.selectors';

import { fetchParcelsSuggestions, clearParcelsSuggestions } from '../../actions/sentinel.actions';

import ParcelSelector from '../../../../../shared/components/specific/ParcelSelector/ParcelSelector';
import ParcelSelectorList from '../../../../../shared/components/specific/ParcelSelectorList/ParcelSelectorList';

export class SentinelAdminParcelsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };
  }

  isParcelActive = suggestion =>
    suggestion.historyPotentialEnabled || suggestion.biomonitoringEnabled || suggestion.variableApplicationEnabled;

  handleSuggestionSelected = suggestion => {
    if (!this.isParcelInList(suggestion) && !this.isParcelActive(suggestion)) {
      this.props.fields.push(suggestion);
    }
  };

  isParcelInList = parcelToCheck =>
    find(this.props.fields.getAll(), ['parcelId', parcelToCheck.parcelId]) !== undefined;

  handleItemRemove = index => {
    this.props.fields.remove(index);
  };

  render() {
    const { editing, suggestions } = this.props;
    return (
      <div>
        <Fragment>
          {editing && (
            <ParcelSelector
              autoFocus={true}
              disableSuggestion={this.isParcelActive}
              onSuggestionsClearRequested={this.props.clearParcelsSuggestions}
              onSuggestionSelected={this.handleSuggestionSelected}
              onSuggestionsFetchRequested={this.props.fetchParcelsSuggestions}
              suggestions={suggestions}
            />
          )}
          <ParcelSelectorList editing={editing} fields={this.props.fields} onItemRemove={this.handleItemRemove} />
        </Fragment>
      </div>
    );
  }
}

SentinelAdminParcelsControl.propTypes = {
  fetchParcelsSuggestions: PropTypes.func.isRequired,
  clearParcelsSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
  fields: PropTypes.object.isRequired,
  editing: PropTypes.bool,
};

SentinelAdminParcelsControl.defaultProps = {
  suggestions: [],
  editing: false,
};

const mapStateToProps = state => ({
  suggestions: getSentinelAdminParcelsSuggestions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchParcelsSuggestions,
      clearParcelsSuggestions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SentinelAdminParcelsControl);
