import { uniq } from 'lodash';
import { createSelector } from 'reselect';

import { IrrigationState } from '../../reducers/irrigation.reducer.types';

export const getCreateOrEditAreaOrder = (state: IrrigationState) => state.ui.createOrEditArea.order;
export const getCreateOrEditAreaOrderBy = (state: IrrigationState) => state.ui.createOrEditArea.orderBy;
export const getCreateOrEditAreaSelectedDevices = (state: IrrigationState) => state.ui.createOrEditArea.selected;
export const getCreateOrEditAreaDevicesToEdit = (state: IrrigationState) => state.ui.createOrEditArea.devicesToEdit;
export const getCreateOrEditAreaName = (state: IrrigationState) => state.ui.createOrEditArea.areaName;
export const getCreateOrEditAreaFieldErrors = (state: IrrigationState) => state.ui.createOrEditArea.fieldErrors;
export const getCreateOrEditAreaIsFormTouched = (state: IrrigationState) => state.ui.createOrEditArea.isFormTouched;
export const getCreateOrEditAreaCoordSelectorStatus = (state: IrrigationState) =>
  state.ui.createOrEditArea.coordSelector.active;
export const getCreateOrEditAreaCoordSelectorId = (state: IrrigationState) =>
  state.ui.createOrEditArea.coordSelector.deviceId;
export const getCreateOrEditAreaHoveredDevice = (state: IrrigationState) => state.ui.createOrEditArea.hoveredDevice;

export const getCreateOrEditAreaErrorMessages = createSelector(getCreateOrEditAreaFieldErrors,
  (deviceErrors) => uniq(Object.values(deviceErrors)).filter(Boolean));

export const getUserSelectedDevices = createSelector([
  getCreateOrEditAreaDevicesToEdit,
  getCreateOrEditAreaSelectedDevices,
],
(devicesToEdit, selected) => devicesToEdit.filter(dev => selected.includes(dev.id)));

export const getCreateOrEditAreaUserSelectedData = createSelector([
  getCreateOrEditAreaName,
  getUserSelectedDevices,
],
(areaName, selectedDevices) => ({
  areaName,
  devices: selectedDevices,
}));

export const getCreateOrEditAreaAvailablePoints = createSelector(
  getCreateOrEditAreaDevicesToEdit,
  (devices) => devices.filter(device =>
    device?.geometry?.coordinates && device.geometry.coordinates.some(coord => parseFloat(coord) > 0)),
);
