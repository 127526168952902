import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { getTool, getStage, getError } from '../../selectors/editor.selectors';
import { getContext } from '../../selectors/map.selectors';

import { editorToolStart, editorToolSubmit, editorToolEnd } from '../../actions/editor/editor.actions';

import * as contexts from '../../constants/contexts.constants';
import * as tools from '../../constants/tools.constants';

import ToolbarIconBtn from '../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn';
import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import BufferIcon from '../../../../shared/icons/mapTools/BufferIcon';
import DrawIcon from '../../../../shared/icons/mapTools/DrawIcon';
import MergeIcon from '../../../../shared/icons/mapTools/MergeIcon';
import SplitIcon from '../../../../shared/icons/mapTools/SplitIcon';
import DrawToolbar from '../../components/DrawToolbar/DrawToolbar';
import MapHoverHint from '../../components/MapHoverHint/MapHoverHint';
import BufferToolbar from '../BufferToolbar/BufferToolbar';
import MergeToolbar from '../MergeToolbar/MergeToolbar';
import SnackbarHint from '../SnackbarHint/SnackbarHint';
import SplitToolbar from '../SplitToolbar/SplitToolbar';

const icons = {
  [tools.DRAW]: DrawIcon,
  [tools.MERGE]: MergeIcon,
  [tools.SPLIT]: SplitIcon,
  [tools.BUFFER]: BufferIcon,
};

const styles = theme => ({
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 3,
    },
  },
});

export class EditorToolbar extends Component {
  constructor(props) {
    super(props);
    this.editTools = this.filterTools();
  }
  componentDidUpdate(prevProps) {
    const { context } = this.props;
    if (context !== contexts.PREVIEW && prevProps.context === contexts.PREVIEW) {
      this.endTool();
    }
  }

  getToolIcon = (tool, active) => (
    <ToolbarIconBtn
      active={active}
      aria-label={tool}
      callback={() => this.switchTool(tool)}
      data-test={tool}
      icon={icons[tool]}
      key={tool}
      product-fruits={`${tool.toString().toLowerCase()} parcel`}
      tooltipTitle={`ToolbarIconBtn.${tool}-tooltip`}
    />
  );

  switchTool = tool => {
    if (tool !== this.props.tool) {
      this.endTool();
      this.props.editorToolStart(tool, this.props.countryCode);
    }
  };

  submitTool = () => {
    const { tool } = this.props;

    if (tool) {
      this.props.editorToolSubmit(tool);
    }
  };

  endTool = () => {
    const { tool } = this.props;

    if (tool) {
      this.props.editorToolEnd(tool);
    }
  };

  filterTools = () =>
    Object.keys(tools).filter(
      tool => (this.props.countryCode === 'CZ' ? tool !== tools.DRAW : true) && tool !== tools.MEASURE,
    );

  render() {
    const { classes, countryCode, drawParcelCulture, drawParcelName, error, langId, stage, tool } = this.props;

    return (
      <Fragment>
        {this.editTools.includes(tool) ? (
          <ToolbarSection background={false}>{this.getToolIcon(tool, true)}</ToolbarSection>
        ) : (
          <ToolbarSection background={true}>{this.editTools.map(key => this.getToolIcon(key, false))}</ToolbarSection>
        )}

        <span className={classes.toolbar}>
          {tool === tools.DRAW && (
            <DrawToolbar
              drawParcelCulture={drawParcelCulture}
              drawParcelName={drawParcelName}
              error={error}
              langId={langId}
              onCancel={this.endTool}
              onSubmit={this.submitTool}
              stage={stage}
            />
          )}
          {tool === tools.MERGE && (
            <MergeToolbar
              countryCode={countryCode}
              error={error}
              langId={langId}
              onCancel={this.endTool}
              onSubmit={this.submitTool}
              stage={stage}
            />
          )}
          {tool === tools.SPLIT && (
            <SplitToolbar
              error={error}
              langId={langId}
              onCancel={this.endTool}
              onSubmit={this.submitTool}
              stage={stage}
            />
          )}
          {tool === tools.BUFFER && (
            <BufferToolbar
              error={error}
              langId={langId}
              onCancel={this.endTool}
              onSubmit={this.submitTool}
              stage={stage}
            />
          )}
        </span>

        <SnackbarHint langId={langId} />
        <MapHoverHint langId={langId} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editorToolStart,
      editorToolSubmit,
      editorToolEnd,
    },
    dispatch,
  );

const selector = formValueSelector('new_parcel');

const mapStateToProps = state => ({
  tool: getTool(state),
  stage: getStage(state),
  error: getError(state),
  drawParcelName: selector(state, 'name'),
  drawParcelCulture: selector(state, 'culture'),
  context: getContext(state),
});

EditorToolbar.propTypes = {
  tool: PropTypes.string,
  stage: PropTypes.string,
  error: PropTypes.string,
  drawParcelName: PropTypes.string,
  drawParcelCulture: PropTypes.object,
  langId: PropTypes.string.isRequired,
  editorToolStart: PropTypes.func.isRequired,
  editorToolSubmit: PropTypes.func.isRequired,
  editorToolEnd: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

EditorToolbar.defaultProps = {
  tool: null,
  stage: null,
  error: null,
  drawParcelName: '',
  drawParcelCulture: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditorToolbar));
