import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import {
  getParcels as getItems,
  getParcelStatistics,
  isParcelStatisticsFetching,
  isParcelFetching,
} from '../../../../shared/api/core/parcels/parcels.selectors';
import {
  getZones,
  getIsFetchingZones,
  getZonesStatistics,
  isZonesStatisticsFetching,
} from '../../../../shared/api/core/zones/zones.selectors';

export const getParcelListPage = state => state.ui.parcelList.page;
export const getParcelListOrder = state => state.ui.parcelList.order;
export const getParcelListOrderBy = state => state.ui.parcelList.orderBy;
export const getParcelListRowsPerPage = state => state.ui.parcelList.rowsPerPage;
export const getParcelListSelected = state => state.ui.parcelList.selected;
export const getParcelListTextFilter = state => state.ui.parcelList.textFilter;
export const getParcelListAdvancedFilterZones = state => state.ui.parcelList.advancedFilter?.zones || [];
export const getParcelListAdvancedFilter = state => state.ui.parcelList.advancedFilter;

// TODO remove view definition
export const getParcelListItems = state => getItems()(state);
export const getParcelListStatistics = state => getParcelStatistics()(state);
export const getParcelListSelectedOnPage = createSelector(
  getParcelListItems,
  getParcelListSelected,
  (parcels, selected) => intersection(parcels.map(parcel => parcel.id), selected),
);

export const isFetchingStatistics = state =>
  isParcelStatisticsFetching()(state) ||
  isZonesStatisticsFetching('ui.statistics.zones')(state) ||
  getIsFetchingZones('ui.statistics.zones')(state);

export const getStatistics = createSelector(
  getParcelStatistics(),
  getZonesStatistics('ui.statistics.zones'),
  getZones('ui.statistics.zones'),
  (parcelStats, zonesStats, zones) => ({
    parcels: {
      ...(!isEmpty(parcelStats)
        ? {
          items: get(parcelStats, 'cropStatistics', []).map(i => ({
            id: i.crop.id,
            name: i.crop.name,
            count: i.count,
            totalArea: i.totalArea,
          })),
          totalArea: parcelStats.totalArea,
          totalParcelCount: parcelStats.totalParcelCount,
        }
        : { items: [] }),
    },
    zones: {
      ...(!isEmpty(zones)
        ? {
          items: zones.map(i => ({
            id: i.id,
            name: i.name,
            count: i.parcelCount,
            totalArea: i.totalArea,
          })),
        }
        : { items: [] }),
      ...(!isEmpty(zonesStats)
        ? { totalArea: zonesStats.totalArea, totalParcelCount: zonesStats.totalParcelCount }
        : {}),
    },
  }),
);

export const isFetchingParcels = state => isParcelFetching()(state);
