import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfChartTooltipDaytime from '../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime';
import CfChartTooltipLabel from '../../../shared/components/charts/CfChartTooltipLabel/CfChartTooltipLabel';
import CfChartTooltipLabelWrapper from '../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipWrapper from '../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import DateTime from '../../../shared/services/DateTime.service';
import Localization from '../../../shared/services/Localization.service';
import FeatureData from '../../services/FeatureData.service';
import SensorChartTooltipValueItem from '../SensorChartTooltipValueItem/SensorChartTooltipValueItem';

class SensorChartTooltipRainfall extends Component {
  getLabel = () => this.props.payload[0].payload?.dateTime || '';

  startDateTime = () => FeatureData.getStartOfCumulativeRainfall(this.getLabel(), this.props.granularity);

  endDateTime = () => DateTime.endDateTimeByDuration(this.props.granularity, this.getLabel());

  endOfCumulativeRainfallFormatted = () => {
    const endDateTime = this.endDateTime();
    return Localization.formatDate(endDateTime, this.props.intl.locale, {
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  startOfCumulativeRainfallFormatted = () => {
    const startDateTime = this.startDateTime();
    return Localization.formatDate(startDateTime, this.props.intl.locale, {
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  render() {
    const {
      active,
      payload,
      granularity,
      features: [sum, increment],
    } = this.props;
    if (active && payload && payload[0]) {
      const sumPayload = payload.find(item => item.name === sum.name);
      const incrementPayload = payload.find(item => item.name === increment.name);
      const label = this.getLabel();

      return (
        <CfChartTooltipWrapper>
          <CfChartTooltipLabelWrapper>
            <CfChartTooltipDaytime granularity={granularity} label={label} />
          </CfChartTooltipLabelWrapper>

          <SensorChartTooltipValueItem feature={increment} payload={incrementPayload} />

          <CfChartTooltipLabelWrapper>
            <CfChartTooltipLabel>
              {`${this.startOfCumulativeRainfallFormatted()}`}
              <span> &#8211; </span>
              {`${this.endOfCumulativeRainfallFormatted()}`}
            </CfChartTooltipLabel>
          </CfChartTooltipLabelWrapper>

          <SensorChartTooltipValueItem feature={sum} payload={sumPayload} />
        </CfChartTooltipWrapper>
      );
    }
    return null;
  }
}

SensorChartTooltipRainfall.propTypes = {
  intl: PropTypes.object.isRequired,
  granularity: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
};

SensorChartTooltipRainfall.defaultProps = {
  payload: [],
  active: false,
  classes: {},
};

export default injectIntl(SensorChartTooltipRainfall);
