import * as types from '../actions/map/map.constants';
import * as contexts from '../constants/contexts.constants';

export default () => (
  state = {
    main: null,
    style: null,
    ia: null,
    overlay: null,
    layers: null,
    weather: null,
    context: contexts.PREVIEW,
    isFetching: false,
    isInitialized: false,
  },
  action = {},
) => {
  switch (action.type) {
    case types.STORE_SERVICE_WRAPPER:
      return {
        ...state,
        [action.key]: action.value,
      };
    case types.SET_MAP_CONTEXT:
      return {
        ...state,
        context: action.context,
      };
    case types.SET_MAP_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case types.MAP_INIT_SUCCESS:
      return {
        ...state,
        isInitialized: true,
      };
    case types.RESET_MAP:
      return {
        ...state,
        main: null,
        style: null,
        ia: null,
        overlay: null,
        layers: null,
        weather: null,
      };
    default:
      return state;
  }
};
