import SubtractableAreasService from '../../eph/services/SubtractableAreas.service';

export default class ParcelSubtractionMapper {
  static createParcelsSubtractions(parcels) {
    const parcelsSubtractions = {};
    parcels.forEach((parcel) => {
      parcelsSubtractions[parcel.id] = parcel.subtractableAreas.map(sa => this.createParcelSubtractionTo(sa));
    });
    return parcelsSubtractions;
  }

  static createNewFormParcelsSubtractions(parcels) {
    const parcelsSubtractions = {};
    parcels.forEach((parcel) => {
      parcelsSubtractions[parcel.id] = this.mapParcelsSubtractionNewForm(parcel);
    });
    return parcelsSubtractions;
  }

  static mapParcelsSubtractionNewForm(parcel) {
    return [
      ...parcel.subtractableAreas.absolute.map(sa => this.createParcelSubtractionTo(sa)),
      ...parcel.subtractableAreas.boundary.map(sa => this.createParcelSubtractionTo(sa)),
      ...parcel.subtractableAreas.water.map(sa => this.createParcelSubtractionTo(sa)),
    ];
  }

  static createNewFormParcelSubtractions(parcel) {
    const areas = parcel.subtractableAreas.absolute.filter(sa => sa.checked === true).map(sa =>
      ({
        parcelId: parcel.id,
        restrictionType: sa.restrictionType,
        restrictionValue: sa.restrictionValue,
      }),
    );
    if (!(parcel.subtractableAreas.boundaryChecked === 0 || parcel.subtractableAreas.boundaryChecked === undefined)) {
      areas.push({
        parcelId: parcel.id,
        restrictionType: 'Boundary',
        restrictionValue: parcel.subtractableAreas.boundaryChecked,
      });
    }
    if (!(parcel.subtractableAreas.waterChecked === 0 || parcel.subtractableAreas.waterChecked === undefined)) {
      areas.push({
        parcelId: parcel.id,
        restrictionType: 'Water',
        restrictionValue: parcel.subtractableAreas.waterChecked,
      });
    }
    return areas;
  }

  static createNewParcelSubtractionTos(subtractableAreas) {
    return SubtractableAreasService.getCheckedSubtractableAreas(subtractableAreas)
      .filter(sa => sa.newlyAdded === true)
      .map(sa => this.createParcelSubtractionTo(sa));
  }

  static createParcelSubtractionTo(sa) {
    return {
      restrictionType: sa.restrictionType,
      restrictionValue: sa.restrictionValue,
    };
  }

  static createActionMaterials(expenses) {
    return expenses.map(expense => ({
      materialId: expense.material.id,
      chemistriesOrganismLegCodes:
        expense.material.materialTypeId === 'CH' ? expense.pests.map(pest => pest.legislativeCode) : [],
      unitId: expense.doseUnit.id,
    }));
  }

  static createActionMaterialsTo(materialIds, parcelIds, driftClass, cropLegCode, expenses) {
    return {
      materials: this.createActionMaterials(expenses),
      parcelIds,
      driftClass,
      cropLegCode,
    };
  }
}
