export const GET_ZONES = 'GET_ZONES';
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS';
export const GET_ZONES_FAILURE = 'GET_ZONES_FAILURE';
export const RESET_ZONES = 'RESET_ZONES';

export const GET_ZONES_STATISTICS = 'GET_ZONES_STATISTICS';
export const GET_ZONES_STATISTICS_SUCCESS = 'GET_ZONES_STATISTICS_SUCCESS';
export const GET_ZONES_STATISTICS_FAILURE = 'GET_ZONES_STATISTICS_FAILURE';
export const RESET_ZONES_STATISTICS = 'RESET_ZONES_STATISTICS';

export const PUT_ZONE = 'PUT_ZONE';
export const PUT_ZONE_SUCCESS = 'PUT_ZONE_SUCCESS';
export const PUT_ZONE_ERROR = 'PUT_ZONE_ERROR';

export const POST_ZONE = 'POST_ZONE';
export const POST_ZONE_SUCCESS = 'POST_ZONE_SUCCESS';
export const POST_ZONE_ERROR = 'POST_ZONE_ERROR';

export const DELETE_ZONE = 'DELETE_ZONE';
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS';
export const DELETE_ZONE_ERROR = 'DELETE_ZONE_ERROR';
