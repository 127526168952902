import React, { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  snackbar: {
    display: 'flex',
    flexWrap: 'nowrap',
    bottom: 0,
  },
  snackbarContent: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.secondary.light,
  },
  snackbarContentSuccess: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  snackbarContentError: {
    color: '#fff',
    backgroundColor: theme.palette.error.main,
  },
});

/**
 * Our wrapper for MUI Snackbar used to display information about success/error action.
 * @version 1.0.0
 */
function CfSnackbar(props) {
  const { autoHideDuration, classes, isError, isSuccess, message, onClose, open } = props;

  const handleClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      className={classes.snackbar}
      message={message}
      onClose={handleClose}
      open={open}
      action={
        <Fragment>
          <IconButton aria-label="close" color="inherit" onClick={handleClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Fragment>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ContentProps={{
        classes: {
          root: classnames({
            [classes.snackbarContentSuccess]: isSuccess,
            [classes.snackbarContentError]: isError,
            [classes.snackbarContent]: !isSuccess && !isError,
          }),
        },
      }}
    />
  );
}

CfSnackbar.propTypes = {
  /** Style classes. */
  classes: PropTypes.object.isRequired,
  /** Duration in ms to automatically hide snackbar after it is open. */
  autoHideDuration: PropTypes.number,
  /** If true, snackbar is open. */
  open: PropTypes.bool.isRequired,
  /** Snackbar message. */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** If true, success style is used. */
  isSuccess: PropTypes.bool,
  /** If true, error style is used. */
  isError: PropTypes.bool,
  /** Action to perform after clicking close button. */
  onClose: PropTypes.func.isRequired,
};

CfSnackbar.defaultProps = {
  autoHideDuration: 6000,
  message: '',
  isSuccess: false,
  isError: false,
};

export default withStyles(styles)(CfSnackbar);
