/**
 * Created by ondrejzvara on 6.3.17.
 */

import localStorageService from 'local-storage';

/*@ngInject*/
export default function FarmsStorage() {

    var service = {
        setFarms: setFarms,
        getFarms: getFarms,
        removeFarms: removeFarms
    };
    return service;

    /////////////////////////

    function setFarms(farms) {
        localStorageService.set('farms', farms);
    }

    function getFarms() {
        return localStorageService.get('farms');
    }

    function removeFarms() {
        localStorageService.remove('farms');
    }


}