import { RSAA } from 'redux-api-middleware';

import {
  getAccessToken,
  setAccessToken,
  saveRefreshTokenPromise,
  clearRefreshTokenPromise,
} from '../api/gateway/auth/auth.api';

let refreshAttempts = 0;

const isRefreshCall = (action, refreshAction) =>
  action[RSAA].endpoint.includes(refreshAction[RSAA].endpoint) && action[RSAA].method === refreshAction[RSAA].method;

/**
 * Checks whether the response is 401 due to the expired access token,
 * requests for the new access token and retries the previously failed
 * request with the new access token in the authorization header.
 *
 * @param settings
 * @returns {Function}
 */
export const attemptRefresh = settings => {
  // TODO refactor this sh*t
  const { action, authHeaderSupplier, failure, next, refreshReducerKey = 'auth', store } = settings;

  return response => {
    // The API call returned unauthorized user (access token is expired)
    if (
      response &&
      response.error &&
      // refresh access on both 401 and 403 (it wont hurt that much)
      response.payload.status === 401 &&
      !isRefreshCall(action, getAccessToken()) &&
      refreshAttempts === 0
    ) {
      // We check if there is already dispatched a call to refresh the token,
      // if so we can simply queue the call until the refresh request completes
      let refreshPromise = store.getState()[refreshReducerKey]?.refreshTokenPromise;
      refreshAttempts = 1;

      if (!refreshPromise) {
        // play the refresh RSAA from scratch
        refreshPromise = store.dispatch(getAccessToken());
        next(saveRefreshTokenPromise(refreshPromise));
      }

      return refreshPromise.then(resp => {
        next(clearRefreshTokenPromise());

        // Refresh was successful
        if (!resp.error) {
          next(setAccessToken(resp.payload));

          const authHeaderValue = authHeaderSupplier();
          if (authHeaderValue) {
            action[RSAA].headers.Authorization = authHeaderValue;
          }

          // replay the queued actions
          return store.dispatch(action);
        }

        return failure();
      });
    }

    return response;
  };
};
