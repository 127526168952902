/**
 * Created by ondrejzvara on 11.11.16.
 */


/*@ngInject*/
export default function Spinner($rootScope) {

    var domains = [],
        whitelistLocalRequestsStorage = false;

    var service = {
        whitelistDomain: whitelistDomain,
        whitelistLocalRequests: whitelistLocalRequests,
        requestSpinnerInterceptor: requestSpinnerInterceptor,
        responseSpinnerInterceptor: responseSpinnerInterceptor,
        responseErrorSpinnerInterceptor: responseErrorSpinnerInterceptor
    };
    return service;

    /////////////////////////

    function whitelistDomain(domain) {
        domains.push(domain);
    }

    function whitelistLocalRequests() {
        whitelistLocalRequestsStorage = true;
    }

    function isUrlOnWhitelist(url) {
        if (url.substring(0, 2) !== '//' &&
            url.indexOf('://') === -1 &&
            whitelistLocalRequestsStorage) {
            return true;
        }

        for (var i = domains.length; i--;) {
            if (url.indexOf(domains[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    function requestSpinnerInterceptor(url, operation, params) {
        if (isUrlOnWhitelist(url)) {
            $rootScope.$emit('loaderShow', { methods: operation, url: url, params: params });
        }
    }

    function responseSpinnerInterceptor(url, operation, params) {
        if (isUrlOnWhitelist(url)) {
            $rootScope.$emit('loaderHide', { methods: operation, url: url, params: params });
        }
    }

    function responseErrorSpinnerInterceptor(url, operation, params) {
        if (isUrlOnWhitelist(url)) {
            $rootScope.$emit('loaderHide', { methods: operation, url: url, params: params });
        }
    }

}