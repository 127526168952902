import * as types from './units.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    amount: null,
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case types.GET_UNITS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_UNITS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: action.payload,
      };
    case types.GET_UNITS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.GET_CONVERT_UNIT:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_CONVERT_UNIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {},
        amount: action.payload,
      };
    case types.GET_CONVERT_UNIT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_CONVERT_UNIT:
      return {
        ...state,
        amount: null,
        error: {},
      };

    default:
      return state;
  }
};
