import React from 'react';

import PropTypes from 'prop-types';

const XAxisLabel = props => {
  const { color, formatter, payload, rotation, textAnchor, x, y } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fill={color?.(props) || '#666'}
        fontSize={12}
        textAnchor={textAnchor}
        transform={`rotate(${rotation})`}
        x={0}
        y={0}
      >
        {formatter(payload.value)}
      </text>
    </g>
  );
};

XAxisLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  formatter: PropTypes.func,
  rotation: PropTypes.number,
  textAnchor: PropTypes.string,
  color: PropTypes.func,
};

XAxisLabel.defaultProps = {
  x: undefined,
  y: undefined,
  payload: undefined,
  formatter: val => val,
  rotation: 0,
  textAnchor: 'end',
  color: null,
};

export default XAxisLabel;
