import { getWeather, getMainMap } from '../../selectors/map.selectors';

import { setMouseClickEL, removeEL } from '../eventListener/eventListener.actions';

import * as weatherTypes from '../../constants/weatherTypes.constants';
import * as types from './weather.constants';

import { getRadar as getRadarApi } from '../../../../shared/api/other/radarList/radarList.api';
import { getWeather as getWeatherApi } from '../../../../shared/api/weather/weather.api';

export const setWeatherType = weatherType => ({
  type: types.SET_WEATHER_TYPE,
  weatherType,
});

export const setWeatherCoordinates = coords => (dispatch, getState) => {
  const main = getMainMap(getState());
  const coordinates = coords || main.getCenterCoordinates();
  dispatch({
    type: types.SET_WEATHER_COORDINATES,
    coordinates,
  });
};

export const fetchWeather = (coords, langId) => (dispatch, getState) => {
  const main = getMainMap(getState());
  const { featureProjection } = main.getTransformOptions();
  const tranformed = main.transformCoords(coords, featureProjection, 'EPSG:4326');
  dispatch(getWeatherApi(tranformed[1], tranformed[0], langId));
};

export const setWeatherLayer = () => (_, getState) => {
  const weather = getWeather(getState());
  weather.setWeatherLayer();
};

export const unsetWeatherLayer = () => (_, getState) => {
  const weather = getWeather(getState());
  if (!weather) return;
  weather?.unsetWeatherLayer();
};

export const setWeatherCoordinatesPin = coords => (_, getState) => {
  const weather = getWeather(getState());
  weather.setWeatherCoordinatesPin(coords);
};

export const unsetRadarLayers = () => (_, getState) => {
  const weather = getWeather(getState());
  return weather.unsetRadarLayers();
};

export const moveRadarLayer = dateNext => (_, getState) => {
  const weather = getWeather(getState());
  return weather.moveRadarLayer(dateNext);
};

export const setRadarLayers = (dateNow, dateNext) => (_, getState) => {
  const weather = getWeather(getState());
  return weather.setRadarLayers(dateNow, dateNext);
};

export const enableWeather = () => (dispatch) => {
  dispatch(setWeatherType(weatherTypes.CURRENTLY));
  dispatch(setWeatherLayer());
  dispatch(setWeatherCoordinates());

  dispatch(setMouseClickEL(onMouseClick, () => {}, 'weatherClickELKey'));
};

export const disableWeather = () => (dispatch) => {
  dispatch(removeEL('weatherClickELKey'));
  dispatch(setWeatherType(weatherTypes.OFF));
  dispatch(unsetWeatherLayer());
  dispatch(setWeatherCoordinates([]));
};

export const fetchRadar = () => (dispatch, getState) => {
  const main = getMainMap(getState());
  dispatch(getRadarApi());
  dispatch(main.zoomToFarm());
};

const onMouseClick = (feature, evt) => (dispatch) => {
  const { coordinate } = evt;
  dispatch(setWeatherCoordinates(coordinate));
  dispatch(setWeatherCoordinatesPin(coordinate));
};
