import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AdminIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M12,17.52l6.15,3.57-1.63-6.73L22,9.84,14.8,9.25,12,2.91,9.2,9.25,2,9.84l5.44,4.52L5.85,21.09Z" />
    </svg>
  </SvgIcon>
);

export default AdminIcon;
