import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SwpIcon = props => (
  <SvgIcon {...props}>
    <path d="M12,2.2c-3.1,4.9-7.3,7-7.3,12.2H3v2h1.9c0.4,1.2,1,2.3,1.9,3.2c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.8,5.2-2.2 c0.9-0.9,1.5-2,1.9-3.2H21v-1.9h-1.6C19.4,9.1,15.1,7.1,12,2.2z M6.8,14.3c0-2.7,1.4-4.3,3.5-6.7c0.6-0.6,1.1-1.3,1.8-2 c0.6,0.7,1.2,1.4,1.8,2c2,2.3,3.5,4,3.5,6.7H6.8z" />
  </SvgIcon>
);

export default SwpIcon;
