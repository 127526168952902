import moment from 'moment';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './fertilization.constants';

export const getParcelCurrentFertilization = (parcelId, date, targetCropCode, actionId) => (dispatch) => {
  let endpoint = `parcels/${parcelId}/nitrogen?date=${moment(date).toISOString()}&targetCropCode=${targetCropCode}&`;
  if (actionId) {
    endpoint += `&actionId=${actionId}&`;
  }
  dispatch({
    [RSAA]: {
      endpoint,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_PARCEL_FERTILIZATION_CURRENT,
        types.GET_PARCEL_FERTILIZATION_CURRENT_SUCCESS,
        types.GET_PARCEL_FERTILIZATION_CURRENT_FAILURE,
      ],
    },
  });
};

export const getParcelExpectedFertilization = actionDto => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: 'parcels/fertilization?',
      method: methods.POST,
      module: modules.CORE,
      body: JSON.stringify(actionDto),
      types: [
        types.GET_PARCEL_FERTILIZATION_EXPECTED,
        types.GET_PARCEL_FERTILIZATION_EXPECTED_SUCCESS,
        types.GET_PARCEL_FERTILIZATION_EXPECTED_FAILURE,
      ],
    },
  });

export const resetParcelExpectedFertilization = () => dispatch =>
  dispatch({
    type: types.RESET_PARCEL_FERTILIZATION_EXPECTED,
  });
