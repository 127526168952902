import angular from 'angular';

import SensorsController from './sensors.controller';

const sensorsComponent = {
  bindings: {
    farm: '<',
  },
  controller: SensorsController,
};

export default angular
  .module('app.sensors', [])
  .config(config)
  .component('sensorsComponent', sensorsComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('farm.active.sensors', {
      url: '/sensors',
      views: {
        farm: {
          component: 'sensorsComponent',
        },
      },
    })
    .state('farm.active.sensors.sensor', {
      url: '/:sensorId',
      views: {
        farm: {
          component: 'sensorsComponent',
        },
      },
    })
    .state('farm.active.sensors.sensor.activate', {
      url: '/activate',
      views: {
        farm: {
          component: 'sensorsComponent',
        },
      },
    })
    .state('farm.active.sensors.sensor.predictionsSettings', {
      url: '/predictions-settings',
      views: {
        farm: {
          component: 'sensorsComponent',
        },
      },
    });
}
