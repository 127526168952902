import React, { useEffect, useState, FC } from 'react';

import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCreateOrEditAreaSelectedDevices, getCreateOrEditAreaFieldErrors, getCreateOrEditAreaIsFormTouched, getCreateOrEditAreaCoordSelectorStatus } from '../../../selectors/createOrEditArea.selectors';

import { editDevice, setFormTouched, checkForm, resetCoordSelector, setHoveredDevice } from '../../../actions/createOrEditArea.actions';

import { AREA_FORM_FIELDS, GPS_COORDINATES_PRECISION } from '../../../irrigation.constants';

import { CREATE_OR_EDIT_AREA_NAMESPACE } from '../../../reducer/createOrEditArea.reducer';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableCheckbox from '../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import usePrevious from '../../../../shared/hooks/usePrevious';
import { Thunk } from '../../../../types';
import useAvailableDevicesStyles from '../styles/useAvailableDevices.styles';

import TargetIcon from './TargetIcon';

import { IrrigationState } from '../../../../reducers/irrigation.reducer.types';
import { DeviceToEdit, FieldErrors } from '../../../reducer/createArea.reducer.types';

interface Props {
  checkForm: () => unknown;
  device: DeviceToEdit;
  editDevice: (id: string, field: string, value: string) => void;
  fieldErrors: FieldErrors;
  isCoordSelectorActive: boolean;
  isFormTouched: boolean;
  resetCoordSelector: () => void;
  selected: string[];
  setFormTouched: (bool: boolean) => void;
  setHoveredDevice: (id: string | null) => void;
}

const DeviceRow: FC<Props> = ({
  checkForm,
  device,
  editDevice,
  fieldErrors,
  isCoordSelectorActive,
  isFormTouched,
  resetCoordSelector,
  selected,
  setFormTouched,
  setHoveredDevice,
}) => {
  const intl = useIntl();
  const classes = useAvailableDevicesStyles();

  // save values locally when editing to save resources
  const [latitude, setLatitude] = useState(device.geometry.coordinates[0]);
  const [longitude, setLongitude] = useState(device.geometry.coordinates[1]);
  const [name, setName] = useState(device.name);

  const isSelected = selected.includes(device.id);
  const prevIsSelected = usePrevious(isSelected);
  const wasJustUnselected = !isSelected && prevIsSelected;

  const prevLatitude = usePrevious(latitude);
  const prevLongitude = usePrevious(longitude);

  useEffect(() => {
    if (wasJustUnselected && isCoordSelectorActive) {
      resetCoordSelector();
    }
  }, [isCoordSelectorActive, resetCoordSelector, wasJustUnselected]);

  useEffect(() => {
    if (device.geometry.coordinates[0] !== prevLatitude) {
      setLatitude(device.geometry.coordinates[0]);
    }
    if (device.geometry.coordinates[1] !== prevLongitude) {
      setLongitude(device.geometry.coordinates[1]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const nameError = fieldErrors[`${device.id}-${AREA_FORM_FIELDS.NAME}`];
  const hasNameError = !!nameError;
  const latError = fieldErrors[`${device.id}-${AREA_FORM_FIELDS.LATITUDE}`];
  const hasLatError = !!latError;
  const longError = fieldErrors[`${device.id}-${AREA_FORM_FIELDS.LONGITUDE}`];
  const hasLongError = !!longError;
  const hasError = hasNameError || hasLatError || hasLongError;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFormTouched) {
      setFormTouched(true);
    }
    const fieldType = e.currentTarget.id;
    const value = e.currentTarget.value;

    if (fieldType === AREA_FORM_FIELDS.LATITUDE) {
      setLatitude(value);
    } else if (fieldType === AREA_FORM_FIELDS.LONGITUDE) {
      setLongitude(value);
    } else {
      setName(value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldType = e.currentTarget.id;

    if (fieldType === AREA_FORM_FIELDS.LATITUDE ||
       fieldType === AREA_FORM_FIELDS.LONGITUDE
    ) {
      // save local values to store
      let value = parseFloat(latitude).toFixed(GPS_COORDINATES_PRECISION);
      if (fieldType === AREA_FORM_FIELDS.LONGITUDE) {
        value = parseFloat(longitude).toFixed(GPS_COORDINATES_PRECISION);
      }
      editDevice(device.id, fieldType, value);
    } else {
      editDevice(device.id, fieldType, e.currentTarget.value);
    }

    if (hasError) {
      checkForm();
    }
  };

  const handleMouseEnter = () => {
    if (isSelected) {
      setHoveredDevice(device.id);
    }
  };
  const handleMouseLeave = () => {
    if (isSelected || wasJustUnselected) {
      setHoveredDevice(null);
    }
  };

  return (
    <TableRow
      classes={{ root: classes.tableRow }}
      hover
      key={device.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      selected={isSelected}
    >
      <CfTableCheckbox id={device.id} namespace={CREATE_OR_EDIT_AREA_NAMESPACE} selected={selected} />
      <CfTableCell name={AREA_FORM_FIELDS.NAME}>
        <TextField
          disabled={!isSelected}
          error={hasNameError}
          id={AREA_FORM_FIELDS.NAME}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'Irrigation.createArea.form.deviceName' })}
          value={name}
          classes={{
            root: classnames(
              classes.textField,
              classes.textFieldLong,
              hasNameError && classes.textFieldInError,
            ) }}
          helperText={hasNameError ?
            intl.formatMessage({ id: nameError as string }) :
            device.externalId}
        />
      </CfTableCell>
      <CfTableCell classes={{ root: classes.tableCell }} name={'coordinates'}>
        <TargetIcon deviceId={device.id} />
        <Box className={classes.coordinatesContainer}>
          <TextField
            disabled={!isSelected}
            error={hasLatError}
            helperText={hasLatError && intl.formatMessage({ id: latError as string })}
            id={AREA_FORM_FIELDS.LATITUDE}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'Irrigation.createArea.form.latitude' })}
            value={parseFloat(latitude) > 0 ? latitude : ''}
            variant="standard"
            classes={{
              root: classnames(
                classes.textField,
                classes.textFieldShort,
                classes.withMargin,
                hasLatError && classes.textFieldInError,
              ) }}
          />
          <TextField
            disabled={!isSelected}
            error={hasLongError}
            helperText={hasLongError && intl.formatMessage({ id: longError as string })}
            id={AREA_FORM_FIELDS.LONGITUDE}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'Irrigation.createArea.form.longitude' })}
            value={parseFloat(longitude) > 0 ? longitude : ''}
            variant="standard"
            classes={{
              root: classnames(
                classes.textField,
                classes.textFieldShort,
                hasLongError && classes.textFieldInError,
              ) }}
          />
        </Box>
      </CfTableCell>
    </TableRow>);
};

const mapStateToProps = (state: IrrigationState) => ({
  selected: getCreateOrEditAreaSelectedDevices(state),
  fieldErrors: getCreateOrEditAreaFieldErrors(state),
  isFormTouched: getCreateOrEditAreaIsFormTouched(state),
  isCoordSelectorActive: getCreateOrEditAreaCoordSelectorStatus(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) => bindActionCreators(
  {
    editDevice,
    setFormTouched,
    checkForm,
    resetCoordSelector,
    setHoveredDevice,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRow);
