export const fusariumCalculatorOptions = [
  {
    id: '1',
    nameTranslationId: 'precedingCrop',
    options: [
      {
        id: '1',
        nameTranslationId: 'corn',
        score: 15,
      },
      {
        id: '2',
        nameTranslationId: 'wheat',
        score: 10,
      },
      {
        id: '3',
        nameTranslationId: 'other',
        score: 0,
      },
    ],
  },
  {
    id: '2',
    nameTranslationId: 'postHarvestCropDebris',
    options: [
      {
        id: '1',
        nameTranslationId: 'debrisLeft',
        score: 10,
      },
      {
        id: '2',
        nameTranslationId: 'noInfectedDebris',
        score: 0,
      },
    ],
  },
  {
    id: '3',
    nameTranslationId: 'waterSource',
    options: [
      {
        id: '1',
        nameTranslationId: 'yes',
        score: 5,
      },
      {
        id: '2',
        nameTranslationId: 'no',
        score: 0,
      },
    ],
  },
  {
    id: 4,
    nameTranslationId: 'pickledGrain',
    options: [
      {
        id: '1',
        nameTranslationId: 'yes',
        score: 10,
      },
      {
        id: '2',
        nameTranslationId: 'no',
        score: 0,
      },
    ],
  },
  {
    id: 5,
    nameTranslationId: 'diseaseResistance',
    options: [
      {
        id: '1',
        nameTranslationId: 'sensitive',
        score: 10,
      },
      {
        id: '2',
        nameTranslationId: 'durable',
        score: 0,
      },
    ],
  },
  {
    id: 6,
    nameTranslationId: 'microclimate',
    options: [
      {
        id: '1',
        nameTranslationId: 'low',
        score: 5,
      },
      {
        id: '2',
        nameTranslationId: 'moderate',
        score: 10,
      },
      {
        id: '3',
        nameTranslationId: 'high',
        score: 15,
      },
    ],
  },
  {
    id: 7,
    nameTranslationId: 'lodgingDetected',
    options: [
      {
        id: '1',
        nameTranslationId: 'yes',
        score: 5,
      },
      {
        id: '2',
        nameTranslationId: 'no',
        score: 0,
      },
    ],
  },
  {
    id: 8,
    nameTranslationId: 'priorConditions',
    options: [
      {
        id: '1',
        nameTranslationId: 'wet',
        score: 5,
      },
      {
        id: '2',
        nameTranslationId: 'dry',
        score: 0,
      },
    ],
  },
];
