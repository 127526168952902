import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import NodeLocationSelectGroups from '../NodeLocationSelectGroups/NodeLocationSelectGroups';

export function NodeLocationEditGroupDialog(props) {
  const { groups, onAccept, onCancel, opened, selectedGroups, setGroups } = props;
  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.save" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      data-test="edit-group"
      dialogHeight={'95px'}
      maxWidth="xs"
      onAccept={onAccept}
      onCancel={onCancel}
      onClose={onCancel}
      opened={opened}
      title={<FormattedMessage id="NodeGroups.edit" />}
    >
      <NodeLocationSelectGroups
        groups={groups}
        label={<FormattedMessage id="NodeGroups.chooseGroup" />}
        selectedGroups={selectedGroups}
        setGroups={(filterPart, value) => setGroups(value)}
      />
    </CfDialog>
  );
}

NodeLocationEditGroupDialog.propTypes = {
  opened: PropTypes.bool,
  groups: PropTypes.array,
  selectedGroups: PropTypes.array,
  setGroups: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

NodeLocationEditGroupDialog.defaultProps = {
  classes: {},
  opened: false,
  groups: [],
  selectedGroups: [],
};

export default NodeLocationEditGroupDialog;
