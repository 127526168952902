import { RSAAAction } from 'redux-api-middleware';

import { getTelematicsAdminOrder, getTelematicsAdminOrderBy, getTelematicsAdminPage, getTelematicsAdminRowsPerPage, getTelematicsAdminAdvancedFilter, getTelematicsAdminTextFilter } from './telematicsAdmin.selectors';

import * as commonTableTypes from '../../../../shared/actions/table.constants';

import { getTelematicsAccountFarmsApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';

import { AdminState } from '../../../../reducers/admin.reducer.types';

export const fetchAccountFarms = () => (dispatch: (action: RSAAAction) => void, getState: () => AdminState) => {
  const state = getState();

  const params = {
    'sort-col': getTelematicsAdminOrderBy(state),
    'sort-dir': getTelematicsAdminOrder(state),
    page: getTelematicsAdminPage(state) + 1,
    'per-page': getTelematicsAdminRowsPerPage(state),
    countryCode: getTelematicsAdminAdvancedFilter(state)?.countryCodes?.map(({ code }) => code),
    enabled: getTelematicsAdminAdvancedFilter(state)?.enabled?.value,
    search: getTelematicsAdminTextFilter(state),
  };

  dispatch(getTelematicsAccountFarmsApi(params));
};

export const resetSelectedFarms = () => ({
  type: commonTableTypes.SET_TABLE_SELECTED,
  selected: [],
});
