/**
 * Farm.classifier.public module, manages classifier publishing requests
 */

import angular from 'angular';
import tpl from './classifiers.public.html';
import clPublicPors from './pors/pors.public.module';
import ClassifiersPublicController from './classifiers.public.controller';

export default angular
  .module('app.components.farm.classifiers.public', [clPublicPors])
  .controller('ClassifiersPublicController', ClassifiersPublicController)
  .config(config).name;

/* @ngInject */
function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/farm/classifiers/public', '/farm/classifiers/public/pors');

  $stateProvider.state('farm.active.classifiers.public', {
    url: '/public',
    abstract: true,
    views: {
      'farm@farm.active': {
        controller: 'ClassifiersPublicController as ClassifiersPublicCtrl',
        template: tpl
      }
    }
  });
}
