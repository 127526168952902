import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues, getFormSyncErrors, getFormSyncWarnings, getFormMeta } from 'redux-form';

import { saveParcelsSubtractions, exportVariableApplications } from '../actions/actions.actions';

import { validateAction, saveAction, updateAction, resetValidationFailure } from '../../../../shared/api/core/actions/actions.api';
import { saveVariableExpense } from '../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import BaseContainer from '../../../../shared/containers/BaseContainer/BaseContainer';
import { getDisplayName } from '../../../../shared/hocs/hocHelpers';
import withEditing from '../../../../shared/hocs/withEditing';
import LocalStorage from '../../../../shared/services/LocalStorage.service';
import { tryAsyncValuesValidation } from '../misc/action.helpers';
import ActionStateMapper from '../services/ActionStateMapper.service';

let timerId = null;

const actionForm = ({
  formName,
  validate,
  warn,
  onChange,
  onSubmit,
  initialValues,
  createActionToFnc,
  destroyOnUnmount = false,
  onUpdateCallback,
  onCreateCallback,
  lsTransformFnc = arg => arg,
}) => WrappedComponent => {
  class ActionForm extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isExisting: Boolean(props.existingAction),
        // a map of a manually edited fields; we do not use touched prop of redux-form, because
        // in RF field gets touched even if validation fails without prior user edits
        fieldsManuallyChanged: {},
      };
    }

    componentDidMount() {
      const { existingAction, farmId, initParcelIds, initialize } = this.props;
      let record = null;
      if (existingAction) {
        if (existingAction.isEph) {
          record = ActionStateMapper.mapEphActionState(existingAction);
        } else if (existingAction.isVrf) {
          record = ActionStateMapper.mapVrfActionState(existingAction);
        } else if (existingAction.isVrs) {
          record = ActionStateMapper.mapVrsActionState(existingAction);
        }
      } else {
        const lsRecord = LocalStorage.loadFromLocalStorage(`state_${formName}_${farmId}`);
        record =
          !lsRecord || initParcelIds?.length
            ? initialValues
            : {
              ...lsRecord,
              ...(lsRecord.actionDate ? { actionDate: moment(lsRecord.actionDate) } : {}),
            };
      }
      initialize(record);
    }

    componentDidUpdate(prevProps) {
      const { existingAction, initialized: oldInitialized } = prevProps;
      const { formValues: newFormValues, initialized: newInitialized } = this.props;

      /*
       * Validate form. Do when:
       * 1) form is initialized
       */
      if (!oldInitialized && newInitialized) {
        const isValid = isEmpty(validate(newFormValues));
        if (isValid) {
          tryAsyncValuesValidation(existingAction, this.props.validateAction, createActionToFnc(newFormValues));
        }

        if (!existingAction) {
          this.props.onEditingStart();
        }
      }
    }

    handleFieldManualChange = fieldName => {
      this.setState(state => ({
        fieldsManuallyChanged: {
          ...state.fieldsManuallyChanged,
          [fieldName]: true,
        },
      }));
    };

    handleReset = () => {
      this.props.initialize(initialValues);
      LocalStorage.removeFromLocalStorage(`state_${formName}_${this.props.farmId}`);
    };

    handleCancel = () => {
      this.props.onEditingEnd();
      this.props.reset();
    };

    render() {
      const { fieldsManuallyChanged, isExisting } = this.state;
      const { langId, pristine, submitting, translations, ...restProps } = this.props;
      return (
        <BaseContainer langId={langId} translations={translations}>
          <WrappedComponent
            fieldsManuallyChanged={fieldsManuallyChanged}
            formName={formName}
            initVals={initialValues}
            isExisting={isExisting}
            isPristine={pristine}
            isSubmitting={submitting}
            langId={langId}
            onCancel={this.handleCancel}
            onFieldManualChange={this.handleFieldManualChange}
            onReset={this.handleReset}
            {...restProps}
          />
        </BaseContainer>
      );
    }
  }

  const mapStateToProps = state => ({
    formValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
    formWarnings: getFormSyncWarnings(formName)(state),
    formMeta: getFormMeta(formName)(state),
  });

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        saveAction,
        updateAction,
        validateAction,
        resetValidationFailure,
        saveParcelsSubtractions,
        // TODO this doesnt belong here, bet no other solution
        // found for now
        saveVariableExpense,
        exportVariableApplications,
      },
      dispatch,
    );

  ActionForm.propTypes = {
    saveParcelsSubtractions: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    langId: PropTypes.string.isRequired,
    initialize: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    farmId: PropTypes.string.isRequired,
    existingAction: PropTypes.object,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    onEditingEnd: PropTypes.func.isRequired,
    saveAction: PropTypes.func.isRequired,
    updateAction: PropTypes.func.isRequired,
    validateAction: PropTypes.func.isRequired,
    resetValidationFailure: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    onEditingStart: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    initParcelIds: PropTypes.array,
  };

  ActionForm.defaultProps = {
    existingAction: null,
    formValues: {},
    initParcelIds: [],
  };

  ActionForm.displayName = `ActionForm(${getDisplayName(WrappedComponent)})`;

  const defaultOnSubmit = (values, dispatch, props) => {
    const { existingAction, formValues } = props;
    const dto = createActionToFnc(values);

    if (existingAction) {
      return props.updateAction(existingAction.id, dto).then(result => {
        if (!result.error) {
          props.onEditingEnd();
          props.initialize(formValues);
          if (onUpdateCallback) {
            return onUpdateCallback(values, dispatch, props, result);
          }

          return Promise.resolve(result);
        }
        return Promise.reject(new Error('Failed updating action'));
      });
    }

    return props.saveAction(dto).then(result => {
      if (!result.error) {
        if (onCreateCallback) {
          return onCreateCallback(values, dispatch, props, result);
        }

        return Promise.resolve(result);
      }
      return Promise.reject(new Error('Failed creating action'));
    });
  };

  const extendedOnSubmit = (values, dispatch, props) => {
    const { dirty } = props;
    // true when the current form values are different from the initialValues, false otherwise.
    if (!dirty) {
      props.onEditingEnd();
    } else {
      props.saveParcelsSubtractions(values.parcels);

      let promise = null;
      if (onSubmit) {
        promise = onSubmit(values, dispatch, props);
      } else {
        promise = defaultOnSubmit(values, dispatch, props);
      }

      return promise
        .then(result => {
          if (!result.error) {
            if (result?.payload?.[0]?.actionId) {
              const actionId = result.payload[0].actionId;
              props.ngGoToAction(actionId, 'actions.action');
            } else {
              // fallback
              props.ngGoToActions();
            }
          }
          LocalStorage.removeFromLocalStorage(`state_${formName}_${props.farmId}`);
        })
        .catch(() => {});
    }
  };

  const extendedOnChange = (values, dispatch, props) => {
    const { pristine } = props;
    if (pristine) {
      // reset all errors from the action validation/saving
      props.resetValidationFailure();
      return;
    }

    if (onChange) {
      onChange(values, dispatch, props);
    } else {
      const { existingAction } = props;
      if (timerId) {
        clearTimeout(timerId);
        timerId = null;
      }

      // a bit of hack because of missing redux-form functionality to trigger asyncValidate on change instead of on blur
      // in this moment props.valid does not contain results from the changed form fields,
      // so we call validate explicitly
      const isValid = isEmpty(validate(values));

      if (!isValid) {
        props.resetValidationFailure();
      }

      // debounce the action validation requests, there could be multiple form changes in the batch (like when parcels
      // change the target crop changes accordingly)
      timerId = setTimeout(() => {
        if (isValid) {
          tryAsyncValuesValidation(existingAction, props.validateAction, createActionToFnc(values));
        }
      }, 1000);
    }

    if (!props.existingAction) {
      LocalStorage.saveToLocalStorage(lsTransformFnc(values), `state_${formName}_${props.farmId}`);
    }
  };

  return compose(
    withEditing,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: formName,
      validate,
      warn,
      onChange: extendedOnChange,
      onSubmit: extendedOnSubmit,
      destroyOnUnmount,
    }),
  )(ActionForm);
};

export default actionForm;
