import React from 'react';

import Tab from '@mui/material/Tab';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  tab: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.grey[500],
    fontSize: 14,
  },
  selected: {
    backgroundColor: theme.palette.common.white,
  },
});

/**
 * Tab with predefined styles to be used inside CfPanelTabs.
 * Other extra props not defined here will be propagated to MUI Tab component.
 * @version 1.0.0
 */
const CfPanelTab = props => {
  const { classes, label, ...otherProps } = props;

  return (
    <Tab
      label={label}
      classes={{
        root: classes.tab,
        selected: classes.selected,
      }}
      {...otherProps}
    />
  );
};

CfPanelTab.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** Label of tab visible to the user */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(CfPanelTab);
