import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  wrapper: {
    position: 'relative',
    float: 'left',
    marginRight: '2px',
    marginBottom: '5px',
    height: '28px',
    width: 'max-content',
  },
  bgr: {
    borderRadius: '20px',
    backgroundColor: theme.palette.common.white,
  },
  custom: {},
});

const ToolbarSection = props => {
  const { background, children, classes, productFruits } = props;

  return (
    <div
      className={`${classes.wrapper} ${classes.custom} ${background ? classes.bgr : ''}`}
      data-test="toolbar-section"
      product-fruits={productFruits}
    >
      {children}
    </div>
  );
};

ToolbarSection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  background: PropTypes.bool,
  productFruits: PropTypes.string,
};

ToolbarSection.defaultProps = {
  background: true,
  productFruits: 'parcel edit',
};

export default withStyles(styles)(ToolbarSection);
