import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BioMonitoringImageErrorService from '../../services/BioMonitoringImageError.service';

const styles = theme => ({
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 38,
    height: 38,
    color: theme.palette.grey[500],
  },
  tooltip: {
    marginTop: -40,
  },
});

function BioMonitoringImageErrorWrapper(props) {
  const { children, classes, isZonesImage, item } = props;
  const error = BioMonitoringImageErrorService.getSnapshotError(item, isZonesImage);
  const Icon = error ? error.icon : null;

  return error ? (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<FormattedMessage id={`BioMonitoringImageError.status.${error.id}`} />}
    >
      <div className={classes.errorWrapper}>
        <Icon className={classes.icon} />
      </div>
    </Tooltip>
  ) : (
    children
  );
}

BioMonitoringImageErrorWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  item: PropTypes.object.isRequired,
  isZonesImage: PropTypes.bool,
};

BioMonitoringImageErrorWrapper.defaultProps = {
  isZonesImage: false,
};

export default withStyles(styles)(BioMonitoringImageErrorWrapper);
