import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getDateFilter,
  getIsFetching,
  getOrder,
  getOrderBy,
  getPage,
  getParcelActivitiesTotalCount,
  getProcessedActivities,
  getRowsPerPage,
  getSelected,
  getSelectedOnPage,
  getTextFilter,
} from '../../selectors/parcelActivities.selectors';

import { deleteActivities, fetchActivities, setDateFilter } from '../../actions/parcelActivities.actions';

import { NAMESPACE as namespace } from '../../reducer/parcelActivities.reducer';

import CfTableBody from '../../../../../../shared/components/tables/CfTableBody/CfTableBody';
import CfTableBodyEmpty from '../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../../shared/containers/CfTableHead/CfTableHead';
import { COLOR_GREY } from '../../../../../../theme';

const getColDesc = (align, sortable, label) => ({
  align,
  sortable,
  label,
});

const ELEMENT_UNIT = '(kg/ha)   ';

const columns = {
  'dateStart,id': getColDesc('inherit', true, <FormattedMessage id="common.date" />),
  'actionType.localizedNames.value': getColDesc('inherit', false, <FormattedMessage id="common.action-name" />),
  activityCrop: getColDesc('inherit', false, <FormattedMessage id="common.crop" />),
  area: getColDesc(
    'right',
    false,
    <span>
      <div>
        <FormattedMessage id="common.activityArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.parcelArea" />
      </div>
    </span>,
  ),
  material: getColDesc('right', false, <FormattedMessage id="common.material" />),
  dose: getColDesc('right', false, <FormattedMessage id="common.totalDose" />),
  dosePerHectare: getColDesc('right', false, <FormattedMessage id="common.dosePerHectare" />),
  n: getColDesc(
    'right',
    false,
    <span>
      <FormattedMessage id="common.accountableN" />
      {` ${ELEMENT_UNIT}`}
    </span>,
  ),
  p2o5: getColDesc(
    'right',
    false,
    <span>
      P<sub>2</sub>O<sub>5</sub>
      {` ${ELEMENT_UNIT}`}
    </span>,
  ),
  k2o: getColDesc(
    'right',
    false,
    <span>
      K<sub>2</sub>O{` ${ELEMENT_UNIT}`}
    </span>,
  ),
  mgo: getColDesc('right', false, `MgO ${ELEMENT_UNIT}`),
  cao: getColDesc('right', false, `CaO ${ELEMENT_UNIT}`),
  s: getColDesc('right', false, `S ${ELEMENT_UNIT}`),
};

export class ParcelActivitiesTable extends Component {
  componentDidMount() {
    this.getParcelActivities();
  }

  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      'parcelId',
      'order',
      'orderBy',
      'page',
      'rowsPerPage',
      'textFilter',
      'dateFilter',
    ]);
    const newProps = pick(this.props, [
      'parcelId',
      'order',
      'orderBy',
      'page',
      'rowsPerPage',
      'textFilter',
      'dateFilter',
    ]);

    if (!isEqual(newProps, oldProps)) {
      this.getParcelActivities(newProps);
    }
  }

  getParcelActivities() {
    const { dateFilter, order, orderBy, page, parcelId, rowsPerPage, textFilter } = this.props;
    this.props.fetchActivities(parcelId, { page, rowsPerPage, order, orderBy, textFilter, dateFilter });
  }

  render() {
    const {
      activities,
      count,
      isFetching,
      langId,
      ngGoToAction,
      order,
      orderBy,
      page,
      rowsPerPage,
      selected,
      selectedOnPage,
    } = this.props;
    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          items={activities}
          langId={langId}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
          selected={selected}
          selectedOnPage={selectedOnPage}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}
        {activities.length ? (
          activities.map(row => (
            <CfTableBody
              columns={columns}
              hover
              key={row.id}
              namespace={namespace}
              onClick={() => ngGoToAction(row.id, row.route)}
              row={row}
              selected={selected}
            />
          ))
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter count={count} langId={langId} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
      </CfTableWrapper>
    );
  }
}

ParcelActivitiesTable.propTypes = {
  parcelId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  fetchActivities: PropTypes.func.isRequired,
  activities: PropTypes.array,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  textFilter: PropTypes.string.isRequired,
  dateFilter: PropTypes.any,
  count: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  ngGoToAction: PropTypes.func.isRequired,
};

ParcelActivitiesTable.defaultProps = {
  activities: [],
  dateFilter: null,
};

const mapStateToProps = (state, props) => ({
  activities: getProcessedActivities(state, props),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  order: getOrder(state),
  orderBy: getOrderBy(state),
  selected: getSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFilter: getDateFilter(state),
  isFetching: getIsFetching(state),
  count: getParcelActivitiesTotalCount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActivities,
      setDateFilter,
      deleteActivities,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelActivitiesTable);
