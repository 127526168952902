/**
 * Created by ondrejzvara on 9.3.16.
 */


/*@ngInject*/ 
export default function cfFertSelector() {

    var directive = {
        restrict: 'E',
        template: require('./cf-fert-selector.html'),
        require: ['ngModel', 'cfClassifierSelector'],
        bindToController: {
            model: '=ngModel',
            external: '=?',
            allowCreate: '=?',
            onStateChange: '=?', //EMPTY, TYPING, SELECTED, CREATE
            disabled: '=?',
            errId: '@?',
            validators: '@?',
        },
        controller: cfFertSelectorController,
        controllerAs: 'cfFertSelectorCtrl'
    };

    return directive;
}


/*@ngInject*/
function cfFertSelectorController(FertilizersResource) {
    var vm = this;

    vm.errId = vm.errId ? vm.errId : null;
    vm.validators = vm.validators ? vm.validators : 'none';
    vm.createOptionTemplateUrl = vm.allowCreate ? "cf-fert-selector-create-option-template.html" : null;
    vm.optionTemplateUrl = "cf-fert-selector-option-template.html";
    vm.getFertsByName = getFertsByName;
    vm.optionFormat = optionFormat;

    function getFertsByName(search) {
        return FertilizersResource.getFertilizers( {}, { external: vm.external, search: search} ).then(function(result) {
            var results = result.plain();
            return vm.allowCreate ? [{ name: search }].concat(results) : results;
        });
    }

    function optionFormat(option) {
        if (option) {
            var arr = _.remove([option.name, option.producent], function(val) {
                return !_.isNil(val) && !_.isEmpty(val);
            } );
            return _.join(arr, ", ");
        }
        return null;
    }

}


