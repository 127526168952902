import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';

class SensorAdminFilterByNetworkType extends Component {
  mapSuggestions() {
    const { networkTypes } = this.props;
    return [...new Set(networkTypes.map(network => network.networkType))].map(networkType => ({ name: networkType }));
  }

  render() {
    const { label, selectedNetworkType, setNetworkTypeFilter } = this.props;

    return (
      <CfAutocomplete
        defaultValues={selectedNetworkType}
        id="network-type-filter"
        isMultiple={true}
        label={label}
        onChange={value => setNetworkTypeFilter('networkType', value)}
        suggestions={this.mapSuggestions()}
        testId="network-type-filter"
      />
    );
  }
}

SensorAdminFilterByNetworkType.propTypes = {
  selectedNetworkType: PropTypes.array,
  setNetworkTypeFilter: PropTypes.func.isRequired,
  networkTypes: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
};

SensorAdminFilterByNetworkType.defaultProps = {
  selectedNetworkType: [],
};

export default SensorAdminFilterByNetworkType;
