import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

import IrrigationAdminAssignFarm from '../../containers/IrrigationAdminAssignFarm/IrrigationAdminAssignFarm';

import { AdminFarm } from '../../admin.irrigation.types';

interface Props {
  onAssignDevicesToFarm: (selected: string[], farm: AdminFarm) => void;
  selected: string[];
}

const IrrigationAdminBulkActions: FC<Props> = ({
  onAssignDevicesToFarm,
  selected,
}) => {
  const handleAssignDevicesToFarm = (
    selected: string[],
    farm: AdminFarm,
  ) => {
    onAssignDevicesToFarm(selected, farm);
  };

  const isDisabled = selected.length === 0;

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item xs={12}>
        <IrrigationAdminAssignFarm
          disabled={isDisabled}
          fontSize={16}
          noFarmLabel={<FormattedMessage id="SensorsAdmin.assign-farm" />}
          onAssignDevicesToFarm={(farm: AdminFarm) => handleAssignDevicesToFarm(selected, farm)}
          selectedLength={selected.length}
          />
      </Grid>
    </Grid>
  );
};

export default IrrigationAdminBulkActions;
