import React from 'react';

import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = {
  tooltip: {
    marginBottom: 4,
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  spanMessageMargin: {
    marginLeft: '3px',
  },
};

function LocationCoordinatesInfo(props) {
  const { classes, geometry, locationOn } = props;
  const coordinates = geometry?.coordinates ? (
    `${parseFloat(geometry.coordinates[1].toFixed(9))}, ${parseFloat(geometry.coordinates[0].toFixed(9))}`
  ) : (
    <FormattedMessage id="LocationCoordinatesInfo.locationUnavailable" />
  );

  return (
    <Tooltip
      placement="bottom"
      title={<FormattedMessage id="common.location" />}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <span className={classes.itemContent}>
        {locationOn ? <LocationOnIcon /> : <LocationOffIcon />}
        <span className={classes.spanMessageMargin} data-test="location-coordinates">
          {locationOn ? coordinates : <FormattedMessage id="LocationCoordinatesInfo.noLocation" />}
        </span>
      </span>
    </Tooltip>
  );
}

LocationCoordinatesInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  locationOn: PropTypes.bool,
  geometry: PropTypes.object,
};

LocationCoordinatesInfo.defaultProps = {
  locationOn: true,
  geometry: null,
};

export default withStyles(styles)(LocationCoordinatesInfo);
