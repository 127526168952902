import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SolarRadiation = props => (
  <SvgIcon {...props}>
    <svg height="13" viewBox="0 0 20 13" width="20" x="0px" xmlns="http://www.w3.org/2000/svg" y="4px">
      <path d="M1.4 4.19995L0 5.69995L5.3 11H0V13H20V11H8.2L1.4 4.19995Z" />
      <path d="M14 0.599976V2.59998H16.6L11 8.19998L3.4 0.599976L2 1.99998L11 11L18 3.99998V6.59998H20V0.599976H14Z" />
    </svg>
  </SvgIcon>
);

export default SolarRadiation;
