import React, { ComponentType } from 'react';

import usePopover from '../hooks/usePopover';

function withPopover<T>(WrappedComponent: ComponentType<T>) {
  return (hocProps: Omit<T, 'isOpen' | 'anchorEl' | 'handlePopoverOpen' | 'handlePopoverClose'>) => {
    const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } = usePopover();

    return (
      <WrappedComponent
        {...(hocProps as T)}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        handlePopoverOpen={handlePopoverOpen}
        isOpen={isOpen}
      />
    );
  };
}

export default withPopover;
