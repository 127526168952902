import React from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import faviconDefault from '../../assets/img/favicon.svg';
import faviconFarmasense from '../../assets/img/faviconFarmasense.svg';
import useAppTheming from '../../shared/hooks/useAppTheming';

const METADATA_TRANSLATION_VERSIONS = {
  0: {
    theme: 'default',
    author: 'common.metadata.title',
    description: 'common.metadata.description',
    title: 'common.metadata.title',
    favicon: faviconDefault,
  },
  1: {
    theme: 'farmasense',
    author: 'common.metadata.farmasense.title',
    description: 'common.metadata.farmasense.description',
    title: 'common.metadata.farmasense.title',
    favicon: faviconFarmasense,
  },
};

const Metadata = () => {
  const intl = useIntl();
  const theme = useAppTheming();
  return (
    <Helmet>
      <meta content={intl.formatMessage({ id: METADATA_TRANSLATION_VERSIONS[theme].author })} name="author" />
      <meta content={intl.formatMessage({ id: METADATA_TRANSLATION_VERSIONS[theme].description })} name="description" />
      <title>{intl.formatMessage({ id: METADATA_TRANSLATION_VERSIONS[theme].title })}</title>/
      <link href={METADATA_TRANSLATION_VERSIONS[theme].favicon} rel="icon" type="image/x-icon" />
    </Helmet>
  );
};

export default Metadata;
