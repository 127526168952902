import { routerMiddleware } from 'connected-react-router';
import trim from 'lodash/trim';
import queryString from 'query-string';
import { applyMiddleware, createStore, compose } from 'redux';
import { ApiError, apiMiddleware, RSAA, getJSON } from 'redux-api-middleware';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { attemptRefresh } from '../shared/actions/auth.actions';

import { API_MODULES as modules } from '../shared/api/api.constants';

import commonSaga from '../saga/common.saga';
import { devtoolsEnhancer } from '../shared/redux-devtools';
import Jwt from '../shared/services/Jwt.service';

/*
 Middleware for RSAA actions
 */
const setupHeaders = (headers, disableAuthHeader, noContentType) => {
  const newHeaders = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
    ...headers,
  };
  if (noContentType) {
    delete newHeaders['Content-Type'];
  }

  const authHeader = getAuthorizationHeader();
  if (authHeader && !disableAuthHeader) {
    newHeaders.Authorization = authHeader;
  }

  return newHeaders;
};

const enrichMeta = (meta, metaParams, enrichment) => ({
  ...(meta && typeof meta === 'function' ? meta(...metaParams) : meta),
  ...enrichment,
});

const setupContext = (context, types) => {
  // inject context property to types meta
  if (context) {
    return types.map(type => ({
      ...(typeof type === 'object' ? type : { type }),
      meta: (action, state, res) => enrichMeta(type.meta, [action, state, res], { context }),
    }));
  }
  return types;
};

const setupResponseHandlers = (types, apiConfig) =>
  types.map((type, index) => ({
    ...(typeof type === 'object' ? type : { type }),
    payload: (action, state, res) => {
      if (res) {
        // we have to clone the response in order to access the JSON body in meta resolver
        const resClone = res.clone();
        if (index === 2) {
          // error type
          return getJSON(resClone).then(json => new ApiError(res.status, res.statusText, json));
        }
        return type.payload ? type.payload(action, state, res) : getJSON(resClone);
      }
      return type.payload ? type.payload(action, state, res) : undefined;
    },
    meta: (action, state, res) => {
      let enrichment = null;
      if (res) {
        // handle potential response errors
        if (apiConfig.handleError) {
          apiConfig.handleError(res, apiConfig.displayErrorModal);
        }

        // adds response headers to meta
        enrichment = {
          headers: res.headers,
        };
      }

      return enrichMeta(type.meta, [action, state, res], enrichment);
    },
  }));

const setupEndpoint = (endpoint, mod, proxy, apiConfig) => {
  const { beOpts, farmId, langId } = apiConfig;
  const params = {
    farmIds: proxy || mod === modules.GATEWAY ? undefined : farmId || undefined,
    language: proxy || mod === modules.GATEWAY ? undefined : getLangKey(langId) || undefined,
  };

  return `${getBaseUrl(mod, mapRouteConfig(beOpts))}/${trimParamsWhitespace(
    `${endpoint}${queryString.stringify(params)}`,
  )}`;
};

export const trimParamsWhitespace = endpoint => {
  try {
    const [path, query] = endpoint.split('?');
    const params = queryString.parse(query);
    Object.keys(params).forEach(key => {
      params[key] = trim(params[key]);
    });
    return `${path}${query ? `?${queryString.stringify(params)}` : ''}`;
  } catch (e) {
    return endpoint;
  }
};

function getBaseUrl(mod, opts) {
  if (!opts[mod]) {
    throw new Error('Unknown API module requried. Please define `module` property of RSAA action.');
  }
  return opts[mod];
}

function getAuthorizationHeader() {
  const token = Jwt.getToken();
  return Jwt.hasValidToken() ? `Bearer ${token.id_token}` : null;
}

function mapRouteConfig(config) {
  return {
    [modules.GATEWAY]: config.gatewayUrl,
    [modules.CORE]: config.apiUrl,
    [modules.IOT]: config.iotUrl,
    [modules.AUTOMATION]: config.automationUrl,
    [modules.STORES]: config.storesUrl,
    [modules.WEATHER]: config.weatherUrl,
    [modules.SENTINEL]: config.sentinelUrl,
    [modules.IRRIGATION]: config.irrigationUrl,
    [modules.TELEMATICS]: config.telematicsUrl,
  };
}

// eslint-disable-next-line no-unused-vars
const cfApiMiddleware = apiConfig => store => next => action => {
  const rsaa = action ? action[RSAA] : action;
  if (rsaa && rsaa.module) {
    const { context, module, noAuthHeader, noContentType, proxy } = rsaa;
    delete rsaa.proxy;
    delete rsaa.module;
    delete rsaa.noAuthHeader;
    delete rsaa.noContentType;
    delete rsaa.context;

    // URL shape
    rsaa.endpoint = setupEndpoint(rsaa.endpoint, module, proxy, apiConfig);

    if (!proxy) {
      // inject necessary headers, including authorization
      rsaa.headers = setupHeaders(rsaa.headers, noAuthHeader, noContentType);

      // inject context property to the types' meta
      rsaa.types = setupContext(context, rsaa.types);

      // inject error interceptor
      rsaa.types = setupResponseHandlers(rsaa.types, apiConfig);

      return next(action).then(
        // retry the request when the access token expires
        attemptRefresh({
          action,
          next,
          store,
          authHeaderSupplier: getAuthorizationHeader,
          failure: apiConfig.logout,
        }),
      );
    }
  }

  return action ? next(action) : action;
};

function getLangKey(language) {
  let langKey;
  const indexOfLangSeparator = language.indexOf('-');
  if (indexOfLangSeparator > 0) {
    langKey = language.substring(0, indexOfLangSeparator);
  }

  return langKey;
}

function init(history, apiConfig, reducer, preloadedState = null, loggerSetup = {}, environment = 'production') {
  let store;
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunk, routerMiddleware(history), sagaMiddleware];
  if (apiConfig) {
    middleware.unshift(cfApiMiddleware(apiConfig), apiMiddleware);
  }
  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger(loggerSetup));
  }

  let enhancers = applyMiddleware(...middleware);
  if (environment !== 'prod') {
    // eslint-disable-next-line no-console
    console.info(`ENV:${environment} --> ReduxDevTools applied`);
    enhancers = compose(applyMiddleware(...middleware), devtoolsEnhancer);
  }

  if (preloadedState) {
    store = createStore(reducer(history), preloadedState, enhancers);
  }
  store = createStore(reducer(history), enhancers);

  sagaMiddleware.run(commonSaga, store.dispatch);

  return store;
}

export default init;
