import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';

const styles = {
  wrapper: {
    display: 'flex',
    borderRadius: 20,
  },
  content: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: 10,
    lineHeight: '28px',
  },
  removeButton: {
    padding: 0,
    width: 28,
    height: 28,
    marginLeft: '4px',
  },
};

const MeasurementsOverLimit = props => {
  const { classes, onRemove, overLimit } = props;

  return (
    <ToolbarSection>
      <div className={classes.wrapper}>
        <div className={classes.content} data-test={'over-limit-measurement'}>
          {'+\u00A0'}
          <FormattedMessage
            id="common.noteMoreWord"
            values={{
              count: overLimit,
            }}
          />
        </div>
        <IconButton
          aria-label="Remove measurement"
          onClick={onRemove}
          size="large"
          classes={{
            root: classes.removeButton,
          }}>
          <CancelIcon />
        </IconButton>
      </div>
    </ToolbarSection>
  );
};

MeasurementsOverLimit.propTypes = {
  classes: PropTypes.object.isRequired,
  overLimit: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

MeasurementsOverLimit.defaultProps = {};

export default withStyles(styles)(MeasurementsOverLimit);
