import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { getParcels } from '../../../shared/api/core/parcels/parcels.selectors';
import { getZones } from '../../../shared/api/core/zones/zones.selectors';

export const getParcelsAndZonesSuggestions = (filter) =>
  createSelector(getParcels('ui.reportParcels.suggestions'), getZones(), (parcels, zones) =>
    [
      {
        title: 'common.parcels',
        items: filter ? parcels.filter?.(filter) : parcels,
      },
      {
        title: 'common.zones',
        items: filter ? zones.filter?.(filter) : zones,
      },
    ].filter(section => section.items?.length > 0),
  );

export const getParcelsToAdd = (state) => getParcels('ui.reportParcels.additions')(state);

export const getCounts = (formName, state) => {
  const reports = formValueSelector(formName)(state, 'categories');
  if (reports) {
    return Object.entries(reports).map(report => ({
      category: report[0],
      count: Object.values(report[1])?.filter(val => val === true).length,
    }));
  }
  return [];
};

export const getParcelIds = (values) => {
  const parcels = values.parcels || [];
  return parcels.map(parcel => parcel.id);
};

export const getNoContentReports = state => state.ui.reports.noContentReports;
