import React, { Component, Fragment } from 'react';

import GroupWorkIcon from '@mui/icons-material/GroupWork';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NodeLocationEditGroupDialog from '../NodeLocationEditGroupDialog/NodeLocationEditGroupDialog';

const styles = {
  button: {
    minWidth: 'unset',
  },
};

class NodeLocationEditGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpened: false,
      selected: props.nodeLocation.groups || [],
    };
  }

  setSelectedGroups = value => {
    this.setState({ selected: value });
  };

  handleClick = () => {
    this.setState({ isDialogOpened: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpened: false });
  };

  handleDialogConfirm = () => {
    this.props.setGroups(this.state.selected, this.props.nodeLocation.id);
    this.handleDialogClose();
  };

  render() {
    const { classes, groups, menuItem, nodeLocation } = this.props;
    return (
      <Fragment>
        {menuItem ? (
          <MenuItem aria-label="edit-group" onClick={this.handleClick}>
            <FormattedMessage id="NodeGroups.edit" />
          </MenuItem>
        ) : (
          <Tooltip title={<FormattedMessage id="NodeGroups.edit" />}>
            <IconButton
              aria-label="edit-group"
              className={classes.button}
              onClick={this.handleClick}
              size="large">
              <GroupWorkIcon />
            </IconButton>
          </Tooltip>
        )}
        <NodeLocationEditGroupDialog
          groups={groups}
          nodeLocation={this.props.nodeLocation}
          onAccept={this.handleDialogConfirm}
          onCancel={this.handleDialogClose}
          opened={this.state.isDialogOpened}
          selectedGroups={nodeLocation.groups || []}
          setGroups={this.setSelectedGroups}
        />
      </Fragment>
    );
  }
}

NodeLocationEditGroup.propTypes = {
  nodeLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  setGroups: PropTypes.func.isRequired,
  menuItem: PropTypes.bool,
};

NodeLocationEditGroup.defaultProps = {
  menuItem: false,
};

export default withStyles(styles)(NodeLocationEditGroup);
