export const GET_DRIVES_REQUEST = 'TELEMATICS/GET_DRIVES_REQUEST';
export const GET_DRIVES_SUCCESS = 'TELEMATICS/GET_DRIVES_SUCCESS';
export const GET_DRIVES_ERROR = 'TELEMATICS/GET_DRIVES_ERROR';

export const EXPORT_DRIVES_REQUEST = 'TELEMATICS/EXPORT_DRIVES_REQUEST';
export const EXPORT_DRIVES_SUCCESS = 'TELEMATICS/EXPORT_DRIVES_SUCCESS';
export const EXPORT_DRIVES_ERROR = 'TELEMATICS/EXPORT_DRIVES_ERROR';

export const CHANGE_DRIVE_STATE_REQUEST = 'TELEMATICS/CHANGE_DRIVE_STATE_REQUEST';
export const CHANGE_DRIVE_STATE_SUCCESS = 'TELEMATICS/CHANGE_DRIVE_STATE_SUCCESS';
export const CHANGE_DRIVE_STATE_SUCCESS_ERROR = 'TELEMATICS/CHANGE_DRIVE_STATE_ERROR';

export const GET_DRIVE_DETAIL_REQUEST = 'TELEMATICS/GET_DRIVE_DETAIL_REQUEST';
export const GET_DRIVE_DETAIL_SUCCESS = 'TELEMATICS/GET_DRIVE_DETAIL_SUCCESS';
export const GET_DRIVE_DETAIL_ERROR = 'TELEMATICS/GET_DRIVE_DETAIL_ERROR';
export const RESET_DRIVE_DETAIL = 'TELEMATICS/RESET_DRIVE_DETAIL';

export const CREATE_DRIVE_REQUEST = 'TELEMATICS/CREATE_DRIVE_REQUEST';
export const CREATE_DRIVE_SUCCESS = 'TELEMATICS/CREATE_DRIVE_SUCCESS';
export const CREATE_DRIVE_ERROR = 'TELEMATICS/CREATE_DRIVE_ERROR';

export const EDIT_DRIVE_REQUEST = 'TELEMATICS/EDIT_DRIVE_REQUEST';
export const EDIT_DRIVE_SUCCESS = 'TELEMATICS/EDIT_DRIVE_SUCCESS';
export const EDIT_DRIVE_ERROR = 'TELEMATICS/EDIT_DRIVE_ERROR';

export const GET_ACTION_TYPES_REQUEST = 'TELEMATICS/GET_ACTION_TYPES_REQUEST';
export const GET_ACTION_TYPES_SUCCESS = 'TELEMATICS/GET_ACTION_TYPES_SUCCESS';
export const GET_ACTION_TYPES_ERROR = 'TELEMATICS/GET_ACTION_TYPES_ERROR';
export const RESET_ACTION_TYPES = 'TELEMATICS/RESET_ACTION_TYPES';

export const GET_OPERATIONS_REQUEST = 'TELEMATICS/GET_OPERATIONS_REQUEST';
export const GET_OPERATIONS_SUCCESS = 'TELEMATICS/GET_OPERATIONS_SUCCESS';
export const GET_OPERATIONS_ERROR = 'TELEMATICS/GET_OPERATIONS_ERROR';
export const RESET_OPERATIONS = 'TELEMATICS/RESET_OPERATIONS';

export const GET_PRODUCTION_OPERATIONS_REQUEST = 'TELEMATICS/GET_PRODUCTION_OPERATIONS_REQUEST';
export const GET_PRODUCTION_OPERATIONS_SUCCESS = 'TELEMATICS/GET_PRODUCTION_OPERATIONS_SUCCESS';
export const GET_PRODUCTION_OPERATIONS_ERROR = 'TELEMATICS/GET_PRODUCTION_OPERATIONS_ERROR';
export const RESET_PRODUCTION_OPERATIONS = 'TELEMATICS/RESET_PRODUCTION_OPERATIONS';

export const GET_DRIVERS_REQUEST = 'TELEMATICS/GET_DRIVERS_REQUEST';
export const GET_DRIVERS_SUCCESS = 'TELEMATICS/GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_ERROR = 'TELEMATICS/GET_DRIVERS_ERROR';
export const RESET_DRIVERS = 'TELEMATICS/RESET_DRIVERS';

export const GET_MACHINES_REQUEST = 'TELEMATICS/GET_MACHINES_REQUEST';
export const GET_MACHINES_SUCCESS = 'TELEMATICS/GET_MACHINES_SUCCESS';
export const GET_MACHINES_ERROR = 'TELEMATICS/GET_MACHINES_ERROR';
export const RESET_MACHINES = 'TELEMATICS/RESET_MACHINES';

export const GET_EQUIPMENTS_REQUEST = 'TELEMATICS/GET_EQUIPMENTS_REQUEST';
export const GET_EQUIPMENTS_SUCCESS = 'TELEMATICS/GET_EQUIPMENTS_SUCCESS';
export const GET_EQUIPMENTS_ERROR = 'TELEMATICS/GET_EQUIPMENTS_ERROR';
export const RESET_EQUIPMENTS = 'TELEMATICS/RESET_EQUIPMENTS';

export const CALCULATE_AREA_REQUEST = 'TELEMATICS/CALCULATE_AREA_REQUEST';
export const CALCULATE_AREA_SUCCESS = 'TELEMATICS/CALCULATE_AREA_SUCCESS';
export const CALCULATE_AREA_ERROR = 'TELEMATICS/CALCULATE_AREA_ERROR';
