import { call, all } from 'redux-saga/effects';

import { Thunk } from '../types';

import MainMapSaga from './mainMap.saga';
import TelematicsSaga from './telematics.saga';

import { MainMapState } from '../reducers/map.reducer.types';

export default function* commonSaga(dispatch: Thunk<unknown>): Generator {
  yield all([
    call(TelematicsSaga),
    call(MainMapSaga, dispatch as Thunk<MainMapState>),
  ]);
}
