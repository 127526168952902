import angular from 'angular';

import ResetController from './reset.controller';

// eslint-disable-next-line import/prefer-default-export
const resetComponent = {
  bindings: {},
  controller: ResetController,
};

export default angular
  .module('app.core.reset', [])
  .config(config)
  .component('resetComponent', resetComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('reset', {
    url: '/reset?key',
    views: {
      app: {
        component: 'resetComponent',
      },
    },
  });
}
