import { PUBLIC_LPIS_CONFIG } from '../../../../shared/services/LayersConfig.service';

import CommonLabelStyle from './CommonLabelStyle.service';
import CommonPolygonStyle from './CommonPolygonStyle.service';

export default class DefaultStyles {
  static getParcelStyle(feature, hoveredId, selectedId, color) {
    if (feature.get('id') === selectedId) {
      return CommonPolygonStyle.getDetail(color);
    }
    if (feature.get('id') === hoveredId) {
      return CommonPolygonStyle.getHovered(color);
    }
    return CommonPolygonStyle.getDefault(color);
  }

  static getLabelStyle(feature, resolution, color) {
    if (resolution < 50) {
      const style = CommonLabelStyle.getDefault(color);
      style.getText().setText(CommonLabelStyle.getLabelContent(feature, resolution));
      return style;
    }
    return null;
  }

  static getPublicLpisStyle(feature) {
    const blockName = feature.getProperties()?.zkod_dpb;
    return CommonPolygonStyle.getLpis('public', blockName);
  }

  static getStyle(layerId) {
    switch (layerId) {
      case 'parcel':
        return this.getParcelStyle;
      case 'parcel_label':
        return this.getLabelStyle;
      case PUBLIC_LPIS_CONFIG.TYPE:
        return this.getPublicLpisStyle;
      default:
        return CommonPolygonStyle.getLpis();
    }
  }
}
