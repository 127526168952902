import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetching, getError, getTotalCount } from '../../../../../shared/api/core/actions/actions.selectors';
import {
  getPage,
  getRowsPerPage,
  getOrder,
  getOrderBy,
  getSelected,
  getSelectedOnPage,
  getProcessedActions,
  getTextFilter,
  getDateFilter,
} from '../../selectors/actionList.selectors';

import { fetchActions } from '../../actions/actionList.actions';

import { NAMESPACE as namespace } from '../../reducer/actionList.reducer';

import CfTableBody from '../../../../../shared/components/tables/CfTableBody/CfTableBody';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import { COLOR_GREY } from '../../../../../theme';

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const getColDesc = (align, sortable, label) => ({
  align,
  sortable,
  label,
});

const columns = {
  'dateStart,id': getColDesc('inherit', true, <FormattedMessage id="common.date" />),
  'actionType.localizedNames.value': getColDesc('inherit', true, <FormattedMessage id="common.action-name" />),
  landUse: getColDesc(
    'inherit',
    false,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.culture" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.crop" />
      </p>
    </span>,
  ),
  localName: getColDesc('inherit', false, <FormattedMessage id="common.parcel" />),
  material: getColDesc('inherit', false, <FormattedMessage id="common.material" />),
  dose: getColDesc('center', false, <FormattedMessage id="common.totalDose" />),
  dosePerHectare: getColDesc('center', false, <FormattedMessage id="common.dosePerHectare" />),
};

class ActionsTable extends Component {
  componentDidMount() {
    this.getActions();
  }

  componentDidUpdate(prevProps) {
    const oldProps = _.pick(prevProps, ['order', 'orderBy', 'page', 'rowsPerPage', 'textFilter', 'dateFilter']);
    const newProps = _.pick(this.props, ['order', 'orderBy', 'page', 'rowsPerPage', 'textFilter', 'dateFilter']);

    if (!_.isEqual(newProps, oldProps)) {
      this.getActions();
    }
  }

  getActions() {
    const { dateFilter, order, orderBy, page, rowsPerPage, textFilter } = this.props;
    this.props.fetchActions({ page, rowsPerPage, order, orderBy, textFilter, dateFilter });
  }

  render() {
    const {
      actions,
      count,
      isFetching,
      langId,
      ngGoToAction,
      order,
      orderBy,
      page,
      rowsPerPage,
      selected,
      selectedOnPage,
    } = this.props;

    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          items={actions}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
          selected={selected}
          selectedOnPage={selectedOnPage}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {actions.length ? (
          actions.map(row => (
            <CfTableBody
              columns={columns}
              hover={true}
              key={row.id}
              namespace={namespace}
              onClick={() => ngGoToAction(row.id, row.route)}
              row={row}
              selected={selected}
            />
          ))
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter count={count} langId={langId} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
      </CfTableWrapper>
    );
  }
}

ActionsTable.propTypes = {
  langId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  textFilter: PropTypes.string.isRequired,
  fetchActions: PropTypes.func.isRequired,
  dateFilter: PropTypes.any,
  ngGoToAction: PropTypes.func.isRequired,
};

ActionsTable.defaultProps = {
  dateFilter: null,
  history: {},
};

const mapStateToProps = (state, props) => ({
  actions: getProcessedActions(state, props),
  isFetching: getIsFetching(state),
  count: getTotalCount(state),
  error: getError(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  order: getOrder(state),
  orderBy: getOrderBy(state),
  selected: getSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFilter: getDateFilter(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionsTable);
