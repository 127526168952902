import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';

const styles = theme => ({
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: '12px 24px',
    overflow: 'hidden',
    marginTop: 10,
    fontWeight: 'bold',
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: 'right',
  },
});

function TotalActivityArea(props) {
  const { classes, parcelsArea } = props;
  return (
    <div className={classes.totalActivityArea}>
      <span className={classes.totalAreaLabel}>
        <FormattedMessage id="ParcelZoneSelector.totalActivityArea" />:{' '}
      </span>
      <span className={classes.totalAreaValue}>{<CfFormattedNumber decimalDigits={2} value={parcelsArea} />} ha</span>
    </div>
  );
}

TotalActivityArea.propTypes = {
  classes: PropTypes.object.isRequired,
  parcelsArea: PropTypes.number.isRequired,
};

export default withStyles(styles)(TotalActivityArea);
