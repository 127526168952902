import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isParcelFetching, getParcels } from '../../../../shared/api/core/parcels/parcels.selectors';

import { getParcels as getParcelsApi, resetParcels as resetParcelsApi } from '../../../../shared/api/core/parcels/parcels.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { Parcel } from '../../../../shared/api/core/parcels/parcels.types';

type GetParcelsParams = {
  page: number;
  rowsPerPage: number;
}
interface ParcelSelectorProps {
  defaultValues?: Parcel[];
  getParcels(params: GetParcelsParams): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: Parcel[]): void;
  options: Parcel[];
  resetParcels(): void;
}

const handleGetLabel = (option?: Parcel | null) =>
  option?.localName || '';

const handleGetSelected = (option: Parcel, value?: Parcel | null) =>
  option.blockNr === value?.blockNr;

const handleFilterOptions = createFilterOptions({
  stringify: ({ blockNr, localName }) => `${localName} ${blockNr}`,
});

const ParcelSelector: FC<ParcelSelectorProps> = (
  { defaultValues = [],
    options,
    isFetching = false,
    label,
    getParcels,
    resetParcels,
    onChange,
  }) => {
  const classes = useAdvancedFilterStyles();

  const handleRenderOption = useCallback((option: Parcel) => (
    <div>
      <div>{option.localName}</div>
      <div className={classes.subtext}>{option.blockNr}</div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: Parcel[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          label={<>{value.localName} <span className={classes.subtext}>{value.blockNr}</span></>}
        />
      ))}
    </>, [classes]);

  useEffect(() => {
    getParcels({ page: 0, rowsPerPage: 1000 });
    return () => {
      resetParcels();
    };
  }, [getParcels, resetParcels]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="parcel-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getParcels()(state),
  isFetching: isParcelFetching()(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcels: getParcelsApi,
      resetParcels: resetParcelsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelSelector);
