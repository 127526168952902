import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

import CfStaticMap from '../../../../../../shared/components/specific/CfStaticMap/CfStaticMap';
import withWidth from '../../../../../../shared/hocs/withWidth';
import BioMonitoringImageErrorWrapper from '../BioMonitoringImageErrorWrapper/BioMonitoringImageErrorWrapper';

const styles = theme => ({
  wrapper: {
    margin: '10px 5px 5px 5px',
  },
  dateHeading: {
    textAlign: 'center',
    padding: 2,
    fontSize: 13,
  },
  images: {
    display: 'flex',
  },
  zonesMap: {
    width: 150,
    height: 150,
    margin: 1,
    backgroundColor: theme.palette.grey[100],
  },
  map: {
    borderRadius: 0,
    '& .ol-viewport': {
      borderRadius: 0,
    },
  },
  mapSelected: {
    borderRadius: 0,
  },
});

const getValId = (val = {}, zones = true) => {
  const {
    crop: { legislativeCode },
    dateFrom: from,
    dateTo: to,
  } = val;

  return `biomonitoring_${legislativeCode}_${from || ''}_${to || ''}_${zones ? 'zones' : 'index'}`;
};

export class BioMonitoringHistoryImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        hidden: false,
      });
    }, this.props.order * 250);
  }

  getImage = item => ({
    url: item.imgPath,
    extent: [item.imgExtent?.minX, item.imgExtent?.minY, item.imgExtent?.maxX, item.imgExtent?.maxY],
  });

  render() {
    const { classes, displayedMap, geometries, item, mapImage, onMapClick, width } = this.props;

    const { hidden } = this.state;
    return (
      <div className={classes.wrapper} data-test="history-item">
        <div className={classes.images} style={{ flexDirection: width === 'xs' ? 'row' : 'column' }}>
          <div className={classes.zonesMap}>
            {!hidden && (
              <BioMonitoringImageErrorWrapper item={item}>
                <a
                  className={classes.href}
                  href="#"
                  onClick={() => onMapClick(mapImage?.url === item.imgPath ? null : this.getImage(item), true)}
                >
                  <CfStaticMap
                    image={this.getImage(item)}
                    isSelected={displayedMap && mapImage?.url === item.imgPath}
                    mapId={getValId(item, false)}
                    classes={{
                      map: classes.map,
                      mapSelected: classes.mapSelected,
                    }}
                  />
                </a>
              </BioMonitoringImageErrorWrapper>
            )}
          </div>
          <div className={classes.zonesMap}>
            {!hidden && (
              <BioMonitoringImageErrorWrapper isZonesImage={true} item={item}>
                <a
                  className={classes.href}
                  href="#"
                  onClick={() => onMapClick(geometries === item.zones ? null : item.zones)}
                >
                  <CfStaticMap
                    geometries={item.zones}
                    isSelected={displayedMap && geometries === item.zones}
                    mapId={getValId(item)}
                    classes={{
                      map: classes.map,
                      mapSelected: classes.mapSelected,
                    }}
                  />
                </a>
              </BioMonitoringImageErrorWrapper>
            )}
          </div>
        </div>
        <div className={classes.dateHeading} data-test="date">
          <FormattedDate day="numeric" month="numeric" value={item.dateFrom} />
          {' \u2013 '}
          <FormattedDate day="numeric" month="numeric" value={item.dateTo} />
        </div>
      </div>
    );
  }
}

BioMonitoringHistoryImage.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onMapClick: PropTypes.func.isRequired,
  displayedMap: PropTypes.bool.isRequired,
  geometries: PropTypes.array,
  mapImage: PropTypes.object,
  order: PropTypes.number,
};

BioMonitoringHistoryImage.defaultProps = {
  geometries: null,
  mapImage: null,
  order: 0,
};

export default withStyles(styles)(withWidth()(BioMonitoringHistoryImage));
