import * as types from '../actions/weather/weather.constants';
import * as weatherTypes from '../constants/weatherTypes.constants';

export default () => (
  state = {
    weatherType: weatherTypes.OFF,
    coordinates: [],
  },
  action,
) => {
  switch (action.type) {
    case types.SET_WEATHER_TYPE:
      return {
        ...state,
        weatherType: action.weatherType,
      };
    case types.SET_WEATHER_COORDINATES:
      return {
        ...state,
        coordinates: action.coordinates,
      };
    case types.UNSET_WEATHER_COORDINATES:
      return {
        ...state,
        coordinates: [],
      };
    default:
      return state;
  }
};
