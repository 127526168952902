/**
 * Created by ondrejzvara on 2.11.15.
 */


/*@ngInject*/
export default function CropsResource( Api, ResourceUtils ) {

    var baseAll =  Api.setBaseAll("crops");
    
    var service = {
        getUsedCrops: getUsedCrops,
        getAllCurrentCrops: getAllCurrentCrops,
        getCrops: getCrops,
        searchCrops: searchCrops
    };
    return service;

    /////////////////////////

    function getUsedCrops() {
        return getCrops({}, {count: 500, seedCropOnly: true });
    }

    function getAllCurrentCrops() {
        var params = ResourceUtils.composeParams({}, {count: 500});
        return ResourceUtils.customGet(baseAll, "current", params);
    }

    function getCrops(ngTparams, custParams) {

        var params = ResourceUtils.composeParams(ngTparams, custParams);

        if(custParams && custParams.varietyLegislativeCode){
            params.varietyLegislativeCode = custParams.varietyLegislativeCode;
        }

        if(custParams && custParams.seedCropOnly){
            params.seedCropOnly = custParams.seedCropOnly;
        }

        return ResourceUtils.getItems( baseAll, params, ngTparams );
    }

    function searchCrops(ngTparams, custParams){

        var params = ResourceUtils.composeParams(ngTparams, custParams);

        /*jshint camelcase: false */
        if (custParams && custParams.search) {
            params.search = custParams.search;
        }

        return ResourceUtils.getItems(baseAll, params, ngTparams);

    }
}
