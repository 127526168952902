import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { compose } from 'react-recompose';

import withWidth from '../../../shared/hocs/withWidth';

const LABEL_RADIUS = 18;

const styles = theme => ({
  wrapper: {
    position: 'relative',
    color: theme.palette.grey[500],
    fontSize: 13,
  },
  firstLastDates: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerLabel: {
    position: 'absolute',
    top: 0,
  },
});

class PestPredictionGraphLabels extends Component {
  constructor(props) {
    super(props);
    this.labelsCount = {
      xs: 3,
      sm: 4,
      md: 4,
      lg: 5,
      xl: 6,
    };
  }

  getLabelsToDisplay = () => {
    const { data, width } = this.props;
    const count = this.labelsCount[width];
    const labelIndexStep = (data.length - 1) / (count - 1);
    const filtered = [];
    data.forEach((item, index) => {
      if (index !== 0 && index !== data.length - 1 && index % labelIndexStep < 1) {
        const itemToAdd = { ...item };
        itemToAdd.index = index;
        filtered.push(itemToAdd);
      }
    });
    return filtered;
  };

  render() {
    const { classes, data, graphWidth } = this.props;
    const filtered = this.getLabelsToDisplay();
    const labelStep = graphWidth / (data.length - 1);

    return (
      <div className={classes.wrapper}>
        <div className={classes.firstLastDates}>
          <span>
            <FormattedDate day="numeric" month="numeric" value={data[0].date} />
          </span>
          <span>
            <FormattedDate day="numeric" month="numeric" value={data[data.length - 1].date} />
          </span>
        </div>
        {filtered.map(item => (
          <span className={classes.innerLabel} key={item.date} style={{ left: item.index * labelStep - LABEL_RADIUS }}>
            <FormattedDate day="numeric" month="numeric" value={item.date} />
          </span>
        ))}
      </div>
    );
  }
}

PestPredictionGraphLabels.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  graphWidth: PropTypes.number.isRequired,
};

PestPredictionGraphLabels.defaultProps = {
  classes: {},
};

export default compose(withStyles(styles), withWidth())(PestPredictionGraphLabels);
