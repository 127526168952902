import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import parcelsApi from '../shared/api/core/parcels/parcels.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import drivesApi from '../shared/api/telematics/drives/drives.reducer';
import SectionStatusUIReducer from '../shared/api/telematics/sectionStatus/sectionStatus.reducer';
import telematicsDetailReducer from '../telematics/reducer/telematicsDetail.reducer';
import telematicsListReducer from '../telematics/reducer/telematicsList.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      telematicsList: telematicsListReducer(),
      telematicsDetail: telematicsDetailReducer(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
    }),
    api: combineReducers({
      drives: drivesApi(),
      parcels: parcelsApi(),
      geometry: geometryApi(),
      layers: layersApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
    telematicsStatus: SectionStatusUIReducer(),
  });
