/**
 * SeedsController
 */


/*@ngInject*/
export default function SeedsController($scope, $controller, SeedsResource, VarietiesResource, farm) {

    var vm = this;
    vm.isCollapsed = true;
    vm.cropToAdd = null;
    vm.varietyToAdd = null;

    vm.cropSelectorState = 'EMPTY';
    vm.varietySelectorState = 'EMPTY';
    vm.onCropsSelectorStateChange = onCropsSelectorStateChange;
    vm.onVarietySelectorStateChange = onVarietySelectorStateChange;

    vm.canAddVariety = canAddVariety;
    vm.canCreateSeed = canCreateSeed;
    vm.createSeed = createSeed;

    vm.clsCtrl = $controller('ClassifiersController', {$scope: $scope});
    vm.clsCtrl.setResource(wrapResource(SeedsResource));

    vm.config = {
        edit: true,
        delete: true
    };

    vm.clsCtrl.activate(farm.id, {
        page: 1,
        count: 10,
        sorting: {
            'name': 'asc'
        }
    });

    /**********************************************************************/

    function wrapResource(r) {
        var resource = {
            getItems: r.getSeeds,
            editItems: editSeed,
            deleteItems: r.deleteSeeds
        };
        return resource;
    }

    function onCropsSelectorStateChange(status, crop) {
        vm.cropSelectorState = status;
        if (status === 'SELECTED') {
            VarietiesResource.searchVarieties(null, crop.legislativeCode, true)
                .then(function (varieties) {
                    vm.varieties = varieties;
                });
        }
    }

    function onVarietySelectorStateChange(status, variety) {
        vm.varietySelectorState = status;
        if (status === 'CREATE') {
            VarietiesResource.createVariety(variety).then(function (newVarietyId) {
                variety.id = newVarietyId;
                vm.varietyToAdd = variety;
            });
        }
    }

    function canCreateSeed() {
        return vm.cropSelectorState === 'SELECTED' && vm.varietySelectorState !== 'TYPING';
    }

    function canAddVariety() {
        return vm.cropSelectorState === 'SELECTED';
    }

    function editSeed(seeds) {
        var seedsToEdit = _.map(seeds, function(seed) {
            return createSeedItem(seed.name, seed.crop, seed.variety, seed.id);
        });
        return SeedsResource.editSeeds(seedsToEdit).then(function() {
            vm.cropToAdd = null;
            vm.varietyToAdd = null;
            vm.varietySelectorState = 'EMPTY';
        });
    }

    function createSeed() {
        return SeedsResource.addToFarm(createSeedItem(null, vm.cropToAdd, vm.varietyToAdd))
            .then(function () {
                vm.isCollapsed = true;
                vm.cropToAdd = null;
                vm.varietyToAdd = null;
                vm.cropSelectorState = 'EMPTY';
                vm.clsCtrl.tableParams.reload();
            });
    }

    function createSeedItem(name, crop, variety, id) {
        return {
            "@class": "SeedTo",
            id: id,
            name: name ? name : (crop.name + (variety ? " - " + variety.name : "")),
            crop: {
                id: crop.id,
                legislativeCode: crop.legislativeCode
            },
            variety: variety ? {
                id: variety.id,
                legislativeCode: variety.legislativeCode
            } : undefined
        };
    }
}