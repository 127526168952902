import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getGroups } from '../../../shared/api/iot/groups/groups.selectors';
import {
  getLocationsOrder,
  getLocationsOrderBy,
  getLocationsPage,
  getLocationsRowsPerPage,
  getLocationsTotalCount,
  getLocationsTextFilter,
  getLocationsAdvancedFilter,
  getLocationsHistoryFilter,
  getIsFetchingLocations,
} from '../../selectors/locations.selectors';

import { assignGroups, fetchLocationsWithPoints } from '../../actions/locations.actions';
import { storeHoveredFeatureId } from '../../actions/map.actions';

import { NAMESPACE as namespace } from '../../reducer/locations.reducer';

import NodeDeviceType from '../../../shared/components/common/NodeDeviceType/NodeDeviceType';
import DeviceActivityInfo from '../../../shared/components/specific/DeviceActivityInfo/DeviceActivityInfo';
import SensorsLastUpdate from '../../../shared/components/specific/SensorsLastUpdate/SensorsLastUpdate';
import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableRowTools from '../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import SensorsService from '../../../shared/services/Sensors.service';
import { COLOR_GREY } from '../../../theme';
import NodeLastValues from '../../components/NodeLastValues/NodeLastValues';
import NodeLocationEditGroup from '../../components/NodeLocationEditGroup/NodeLocationEditGroup';
import NodeLocationGroupsInfo from '../../components/NodeLocationGroupsInfo/NodeLocationGroupsInfo';
import NodeLocationStatus from '../../components/NodeLocationStatus/NodeLocationStatus';

const getColDesc = (sortable, label, style) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  status: getColDesc(true, <FormattedMessage id="common.state" />, { width: '70px' }),
  name: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.name" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.id" />
      </p>
    </span>,
  ),
  lastUpdate: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.lastUpdate" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.lastValues" />
      </p>
    </span>,
    { width: '30%' },
  ),
  deviceType: getColDesc(true, <FormattedMessage id="common.type" />),
  groups: getColDesc(false, <FormattedMessage id="NodeGroups.group" />, { width: '20%' }),
};

const styles = theme => ({
  greyText: {
    color: theme.palette.grey[500],
  },
  cellRow: {
    margin: 0,
  },
  lastUpdate: {
    color: theme.palette.grey[500],
  },
  lastValues: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    left: -5,
  },
  activateButton: {
    padding: '3px 8px',
    marginBottom: 4,
    width: 'max-content',
  },
  name: {
    fontWeight: 500,
  },
  tableRow: {
    transform: 'scale(1)',
    '&:hover $tableRowTools': {
      visibility: 'visible',
    },
    cursor: 'pointer',
  },
  tableRowTools: {
    visibility: 'hidden',
    background: theme.palette.grey[100],
  },
});

export class NodeLocationsTable extends Component {
  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      'page',
      'rowsPerPage',
      'order',
      'orderBy',
      'textFilter',
      'historyFilter',
      'advancedFilter',
    ]);
    const newProps = pick(this.props, [
      'page',
      'rowsPerPage',
      'order',
      'orderBy',
      'textFilter',
      'historyFilter',
      'advancedFilter',
    ]);

    if (!isEqual(newProps, oldProps)) {
      this.props.fetchLocationsWithPoints();
    }
  }

  setGroups = (items, locationId) => {
    this.props.assignGroups(items, locationId);
  };

  renderLocation = nodeLocation => {
    const { classes } = this.props;
    const isActive = SensorsService.isActive(nodeLocation);
    const isHistoric = SensorsService.isHistoric(nodeLocation);

    return (
      <span className={classes.lastValues}>
        <NodeLastValues
          hideValues={!nodeLocation.lastUpdate}
          isActive={isActive}
          isDetail={false}
          isHistoric={isHistoric}
          node={nodeLocation.node}
          pestPredictions={nodeLocation.computedFeatures || []}
        />
      </span>
    );
  };

  render() {
    const {
      classes,
      count,
      groups,
      history,
      isFetching,
      langId,
      locations,
      match,
      order,
      orderBy,
      page,
      rowsPerPage,
    } = this.props;

    return (
      <CfTableWrapper testId={'table'}>
        <CfTableHead columns={columns} namespace={namespace} order={order} orderBy={orderBy} />
        {isFetching && <CfTableBodyLoader columns={columns} />}
        {locations.length ? (
          <TableBody>
            {locations.map(nodeLocation => {
              const isHistoric = SensorsService.isHistoric(nodeLocation);
              const isInactive = SensorsService.isInactive(nodeLocation);
              const isActive = SensorsService.isActive(nodeLocation);
              return (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={nodeLocation.id}
                  onMouseEnter={() => this.props.storeHoveredFeatureId(nodeLocation.id)}
                  onMouseLeave={() => this.props.storeHoveredFeatureId(null)}
                  onClick={() => {
                    history.push(`/farm/${match.params.farmId}/sensors/${nodeLocation.id}`);
                  }}
                >
                  <CfTableCell name={'status'}>
                    <NodeLocationStatus nodeLocation={nodeLocation} />{' '}
                  </CfTableCell>
                  <CfTableCell name={'name'}>
                    <span>
                      <p className={`${classes.cellRow}`}>
                        {isInactive ? (
                          <Button
                            className={classes.activateButton}
                            disableElevation
                            size="small"
                            variant="contained"
                            onClick={evt => {
                              history.push(`/farm/${match.params.farmId}/sensors/${nodeLocation.id}/activate`);
                              evt.stopPropagation();
                            }}
                          >
                            <FormattedMessage id="NodeActivation.placeSensor" />
                          </Button>
                        ) : (
                          <span className={classes.name}>{nodeLocation.name}</span>
                        )}
                      </p>
                      <p className={`${classes.cellRow} ${classes.greyText}`}>{nodeLocation.node.externalId}</p>
                    </span>
                  </CfTableCell>
                  <CfTableCell name={'lastUpdate'}>
                    <span>
                      <div className={`${classes.cellRow}`}>
                        <span className={classes.lastUpdate}>
                          {isHistoric && (
                            <DeviceActivityInfo
                              dateFrom={nodeLocation.from}
                              dateTo={nodeLocation.to}
                              displayIcon={false}
                              isActive={isActive}
                              isHistoric={isHistoric}
                            />
                          )}
                          {!isHistoric && !isInactive && (
                            <SensorsLastUpdate displayIcon={false} lastUpdate={nodeLocation.lastUpdate} />
                          )}
                          {!isHistoric && isInactive && <FormattedMessage id="NodeLocationsTable.inactive" />}
                        </span>
                      </div>
                      <div className={`${classes.cellRow}`}>{this.renderLocation(nodeLocation)}</div>
                    </span>
                  </CfTableCell>
                  <CfTableCell name={'deviceType'}>
                    <NodeDeviceType displayIcon={false} node={nodeLocation.node} />
                  </CfTableCell>
                  <CfTableCell name={'groups'}>
                    <NodeLocationGroupsInfo displayIcon={false} nodeLocation={nodeLocation} />
                  </CfTableCell>
                  <CfTableRowTools key={'group-edits-btns'} toolsClass={classes.tableRowTools} withHover={true}>
                    {isActive && !isHistoric && (
                      <NodeLocationEditGroup groups={groups} nodeLocation={nodeLocation} setGroups={this.setGroups} />
                    )}
                  </CfTableRowTools>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter count={count} langId={langId} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
      </CfTableWrapper>
    );
  }
}

NodeLocationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  locations: PropTypes.array,
  groups: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  langId: PropTypes.string.isRequired,
  fetchLocationsWithPoints: PropTypes.func.isRequired,
  storeHoveredFeatureId: PropTypes.func.isRequired,
  assignGroups: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

NodeLocationsTable.defaultProps = {
  locations: [],
  groups: [],
  history: {},
  match: {},
  textFilter: '',
  advancedFilter: {},
  historyFilter: {},
};

const mapStateToProps = state => ({
  page: getLocationsPage(state),
  order: getLocationsOrder(state),
  orderBy: getLocationsOrderBy(state),
  rowsPerPage: getLocationsRowsPerPage(state),
  count: getLocationsTotalCount(state),
  textFilter: getLocationsTextFilter(state),
  advancedFilter: getLocationsAdvancedFilter(state),
  historyFilter: getLocationsHistoryFilter(state),
  isFetching: getIsFetchingLocations(state),
  groups: getGroups(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocationsWithPoints,
      storeHoveredFeatureId,
      assignGroups,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeLocationsTable));
