import React, { Fragment } from 'react';

import _ from 'lodash';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';

import { getActions, getIsFetching as getActionsError } from '../../../../shared/api/core/actions/actions.selectors';
import { getVariableActionIds } from '../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import FormattedAmount from '../../../../shared/components/common/FormattedAmount/FormattedAmount';
import { COLOR_GREY } from '../../../../theme';
import { getRouting } from '../../shared/misc/action.helpers';
import ActionLabel from '../components/ActionLabel/ActionLabel';

export const getPage = state => state.ui.actionList.page;
export const getRowsPerPage = state => state.ui.actionList.rowsPerPage;
export const getOrder = state => state.ui.actionList.order;
export const getOrderBy = state => state.ui.actionList.orderBy;
export const getSelected = state => state.ui.actionList.selected;
export const getTextFilter = state => state.ui.actionList.textFilter;
export const getDateFilter = state => state.ui.actionList.dateFilter;

export const getActionListErrors = createSelector(
  getActionsError,
  (...errors) => errors.find(err => err.isError === true) || {},
);

const getFarmId = (state, props) => props.match.params.farmId;
export const getProcessedActions = createSelector(
  getActions,
  getFarmId,
  getVariableActionIds,
  (actions, farmId, variableActionIds) => processValues(actions, farmId, variableActionIds || []),
);

export const getSelectedOnPage = createSelector(getActions, getSelected, (items, selected) =>
  _.intersection(
    items.map(item => item.id),
    selected,
  ),
);

const styles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryValue: {
      color: COLOR_GREY[500],
    },
  },
};

const ItemWrapper = props => (
  <span>
    <p style={styles.head.p}>{props.children}</p>
  </span>
);

const getActionCrop = (action, parcelId) => {
  const result = null;

  if (!action || _.isEmpty(action)) {
    return result;
  }

  if (action.actionType.actionClass.code === 'CHEM' || action.actionType.actionClass.code === 'FERT') {
    return _.get(action, 'targetCrop.name', null);
  }
  if (!parcelId) {
    return result;
  }

  const parcelListItem = _.find(action.parcelList, parcel => parcel.parcel.id === parcelId);
  if (!parcelListItem) {
    return result;
  }

  const cropNamePath =
    action.actionType.code === 'HARVEST' || action.actionType.code === 'PLOW'
      ? 'seedApplication.seedingActionSeed.crop.name'
      : 'seedApplication.seed.crop.name';

  return _.get(parcelListItem, cropNamePath, null);
};

const getActionMaterial = expense => (expense ? expense.material.name : '-');

const getActionDose = expense => <FormattedAmount expense={expense} param={'amount'} />;
const getActionDosage = expense => <FormattedAmount expense={expense} param={'dose'} perHa={true} />;

ItemWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

const processValues = (actions, farmId, variableActionIds) =>
  actions.map(a => ({
    id: a.id,
    route: getRouting(a, variableActionIds),
    single: {
      'dateStart,id': {
        val: <FormattedDate value={a.dateStart} />,
        rowspan: a.expenseList.length,
      },
      'actionType.localizedNames.value': {
        val: (
          <Fragment>
            <span style={{ fontWeight: 500 }}>
              <FormattedMessage id={`common.${a.actionType.code}`} />
            </span>
            {variableActionIds.includes?.(a.id) && <ActionLabel />}
          </Fragment>
        ),
        rowspan: a.expenseList.length,
      },
      landUse: {
        val: (
          <span>
            <p style={styles.head.p}>{head(a.parcelList)?.parcel.parcelBlockList[0].lpisBlock.landUse.name}</p>
            <p style={{ ...styles.head.p, ...styles.head.secondaryValue }}>
              {getActionCrop(a, head(a.parcelList)?.parcel.id)}
            </p>
          </span>
        ),
        rowspan: a.expenseList.length,
      },
      localName: {
        val: (
          <span>
            {a.parcelList.map(parcel => (
              <p key={parcel.id} style={styles.head.p}>
                <Link key={parcel.id} to={`/farm/${farmId}/parcels/${parcel.parcel.id}/overview`}>
                  {`${parcel.parcel.blockNr ? `${parcel.parcel.blockNr} ` : ''}${parcel.parcel.localName}`}
                </Link>
              </p>
            ))}
          </span>
        ),
        rowspan: a.expenseList.length,
      },
      material: {
        val: <ItemWrapper>{getActionMaterial(a.expenseList[0])}</ItemWrapper>,
      },
      dose: {
        val: <ItemWrapper>{getActionDose(a.expenseList[0])}</ItemWrapper>,
      },
      dosePerHectare: {
        val: <ItemWrapper>{getActionDosage(a.expenseList[0])}</ItemWrapper>,
      },
    },
    multi: a.expenseList.slice(1).map(expense => ({
      material: {
        val: <ItemWrapper key={expense.amount}>{getActionMaterial(expense)}</ItemWrapper>,
      },
      dose: {
        val: <ItemWrapper key={expense.amount}>{getActionDose(expense)}</ItemWrapper>,
      },
      dosePerHectare: {
        val: <ItemWrapper key={expense.amount}>{getActionDosage(expense)}</ItemWrapper>,
      },
    })),
  }));
