import React from 'react';

import { LANG_KEYS } from '../../../../../shared/lang/lang.constants';

import BaseContainer from '../../../../../shared/containers/BaseContainer/BaseContainer';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';
import ActionsList from '../ActionsList/ActionsList';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
};

const ActionsListWrapper = props => {
  const { langId } = props;

  return (
    <BaseContainer langId={langId} translations={translations}>
      <ActionsList {...props} />
    </BaseContainer>
  );
};

export default ActionsListWrapper;
