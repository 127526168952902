import * as types from '../actions/management.constants';

export default () => (
  state = {
    cropLegislativeCode: null,
  },
  action = {},
) => {
  switch (action.type) {
    case types.SET_CROP_LEGISLATIVE_CODE:
      return {
        ...state,
        cropLegislativeCode: action.cropLegislativeCode,
      };
    case types.RESET_CROP_LEGISLATIVE_CODE:
      return {
        ...state,
        cropLegislativeCode: null,
      };
    default:
      return state;
  }
};
