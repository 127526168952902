import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

import { LANG_KEYS } from '../lang/lang.constants';

const DECIMAL_NUM = 3;

export default class Localization {
  static getSeparator(locale, separatorType) {
    const num = 10000.1;
    const intl = Intl.NumberFormat(locale);

    // for safari
    if (!intl.formatToParts) {
      const separators = num.toLocaleString(locale).replace(/\d/g, '');

      if (separatorType === 'decimal') {
        return separators[1] || ','; // fallback to comma if not successfull
      }

      if (separatorType === 'group' && separators[0]) {
        return separators[0] || ' '; // fallback to space if not successfull
      }
    }

    const parts = intl.formatToParts(num);

    return parts.find(part => part.type === separatorType).value;
  }

  static getDecimalSeparator(locale) {
    return Localization.getSeparator(locale, 'decimal');
  }

  static getGroupSeparator(locale) {
    return Localization.getSeparator(locale, 'group');
  }

  static num2str(val, locale, formatDecimal = DECIMAL_NUM) {
    return val !== '' && !isNil(val) && !isNaN(Number(val))
      ? Intl.NumberFormat(locale, {
        minimumFractionDigits: formatDecimal,
        maximumFractionDigits: formatDecimal,
      }).format(val)
      : '';
  }

  static num2strNonFixed(val, locale, minDecimal = 0, maxDecimal = 10) {
    return val !== '' && !isNil(val) && !isNaN(Number(val))
      ? Intl.NumberFormat(locale, {
        minimumFractionDigits: minDecimal,
        maximumFractionDigits: maxDecimal,
      }).format(val)
      : '';
  }

  static transformStr2Num(str, locale) {
    let groupRegExPattern = Localization.getGroupSeparator(locale);
    groupRegExPattern = groupRegExPattern.replace(/\s/g, '').length === 0 ? '\\s' : groupRegExPattern;
    groupRegExPattern = groupRegExPattern === '.' ? '\\.' : groupRegExPattern;

    const result = toNumber(
      str.replace(new RegExp(groupRegExPattern, 'g'), '').replace(Localization.getDecimalSeparator(locale), '.'),
    );

    return result;
  }

  static str2num(val, locale, formatDecimal = DECIMAL_NUM) {
    if (typeof val === 'number') {
      return toNumber(val.toFixed(formatDecimal));
    }

    const str = val ? val.replace(/ /g, '') : val;

    if (!str || !isString(str)) {
      return '';
    }

    const result = this.transformStr2Num(str, locale);

    return isNaN(Number(result)) ? val : toNumber(result.toFixed(formatDecimal));
  }

  static str2numNonFixed(val, locale) {
    if (typeof val === 'number') {
      return val;
    }

    const str = val ? val.replace(/ /g, '') : val;

    if (!str || !isString(str)) {
      return '';
    }

    const result = this.transformStr2Num(str, locale);

    return isNaN(Number(result)) ? val : result;
  }

  static formatDate(
    date,
    locale,
    opts = {
      day: 'numeric',
      month: 'numeric',
    },
  ) {
    return Intl.DateTimeFormat(locale, opts).format(date);
  }

  static formatTime(date, locale) {
    return Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }

  static mergeTranslations = (...sources) => {
    const res = {};
    // eslint-disable-next-line
    Object.values(LANG_KEYS).map(langId => {
      res[langId] = {};
      sources.forEach(src => {
        res[langId] = {
          ...res[langId],
          ...src[langId],
        };
      });
    });

    return res;
  };
}
