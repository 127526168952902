/**
 * Created by ondrejzvara on 7.11.15.
 */


/*@ngInject*/
export default function PorsController($controller, $scope, ChemistriesResource, $state, farm) {
    var vm = this;

    vm.porToAdd = null;
    vm.isCollapsed = true;

    vm.selectorState = 'EMPTY';
    vm.onSelectorStateChange = onSelectorStateChange;
    vm.canAdd = canAdd;
    vm.canCreate = canCreate;
    vm.showAddForm = showAddForm;
    vm.createChemistry = createChemistry;
    vm.addChemistry = addChemistry;
    vm.addChemistryWithRedirect = addChemistryWithRedirect;

    vm.clsCtrl = $controller('ClassifiersController', {$scope: $scope});
    vm.clsCtrl.setResource(wrapResource(ChemistriesResource));

    vm.config = {
        edit: false,
        delete: true,
        create: false
    };

    vm.clsCtrl.activate(farm.id, {
        page: 1,
        count: 10,
        filter: {
           search: ''
        }
    });

    /**********************************************************************/

    function wrapResource(r){
        var resource = {
            getItems: r.getChemistries,
            deleteItems: r.deleteChemistries
        };
        return resource;
    }

    function onSelectorStateChange(status) {
        vm.selectorState = status;
        if (status === 'CREATE') {
            createChemistry();
        }
    }

    function canAdd() {
        return vm.selectorState === 'SELECTED';
    }

    function canCreate() {
        return vm.selectorState === 'TYPING' || vm.selectorState === 'CREATE';
    }

    function showAddForm(){
        vm.isCollapsed = false;
    }

    function isPublic(por) {
        return por.usageInfo && por.usageInfo.state !== 'USED';
    }

    function createChemistry() {
        $state.go('farm.active.classifiers.private.pors.por', {por: vm.porToAdd});
    }

    function addChemistry(){
        if(!_.isObject(vm.porToAdd) || _.isEmpty(vm.porToAdd)){
            return false;
        }

        var isPorPublic = isPublic(vm.porToAdd);
        var id = isPorPublic ? vm.porToAdd.id : vm.porToAdd.legislativeCode;

        return ChemistriesResource.addChemistryToFarm( id, isPorPublic )
            .then(function() {
                vm.isCollapsed = true;
                vm.porToAdd = null;
                vm.clsCtrl.tableParams.reload();
                return true;
            });
    }

    function addChemistryWithRedirect(){
        var isPorPublic = isPublic(vm.porToAdd);
        var id = isPorPublic ? vm.porToAdd.id : vm.porToAdd.legislativeCode;

        return ChemistriesResource.addChemistryToFarm( id, isPorPublic)
            .then(function(porId) {
                vm.isCollapsed = true;
                vm.porToAdd = null;
                $state.go('farm.active.classifiers.private.pors.por', {por: porId});
            });
    }
}