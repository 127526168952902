import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { change } from 'redux-form';

import { getParcelIds } from '../selectors/reports.selectors';

import * as types from './reports.constants';

import {
  addParcels,
  getParcelsByName,
  getParcelsInZone,
  resetParcels,
} from '../../../shared/api/core/parcels/parcels.api';
import { getReport, getSowingPlan } from '../../../shared/api/core/reports/reports.api';
import { getZones, resetZones } from '../../../shared/api/core/zones/zones.api';
import FileService from '../../../shared/services/File.service';

export const fetchParcelsAndZonesSuggestions = (searchInput, parcelsOnly = false, zonesOnly = false, isSawn = false) =>
  dispatch => {
    if (parcelsOnly) {
      return dispatch(getParcelsByName('suggestions', searchInput, isSawn));
    }

    if (zonesOnly) {
      return dispatch(getZones({ name: searchInput }));
    }

    dispatch(getParcelsByName('suggestions', searchInput, isSawn));
    dispatch(getZones({ name: searchInput }));
  };

export const clearParcelsAndZonesSuggestions = (parcelsOnly = false, zonesOnly = false) => dispatch => {
  if (parcelsOnly) {
    return dispatch(resetParcels('suggestions'));
  }

  if (zonesOnly) {
    return dispatch(resetZones());
  }

  dispatch(resetParcels('suggestions'));
  dispatch(resetZones());
};

export const addParcelOrZoneParcelsToAdd = parcelOrZone => dispatch => {
  const isZone = 'parcelCount' in parcelOrZone;
  if (isZone && parcelOrZone.parcelCount > 0) {
    dispatch(getParcelsInZone('additions', [parcelOrZone.id]));
  } else if (!isZone) {
    dispatch(addParcels('additions', [parcelOrZone]));
  }
};

export const clearParcelsToAdd = () => dispatch => {
  dispatch(resetParcels('additions'));
};

export const updateParcelsList = (parcels, form) => dispatch => {
  dispatch(change(form, 'parcels', parcels));
};

export const getReports = (values, format) => (dispatch) => {
  let params = {
    from: values.from,
    to: values.to,
    format,
  };
  if (!values.allParcels) {
    params = {
      ...params,
      parcelIds: getParcelIds(values),
    };
  }
  const promises = [];
  if (values.categories) {
    Object.entries(values.categories).forEach(report => {
      const reportsToExport = keys(pickBy(report[1], r => r === true));
      reportsToExport.forEach(toExport => {
        params = {
          ...params,
          id: toExport,
        };
        const promise = exportReport(params)(dispatch);
        promises.push(promise);
      });
    });
    Promise.all(promises).then((actions) => {
      const noContentReports = actions.filter(action => action.payload.status === 204).map(action => action.payload.id);
      dispatch(setNoContentReports(noContentReports));
    });
  }
};

export const exportReport = params => dispatch =>
  dispatch(getReport(params)).then(res => {
    FileService.processFileResponse(res);
    return res;
  });

export const exportSowingPlan = params => dispatch =>
  dispatch(getSowingPlan(params)).then(res => {
    const noContentReports = res.payload.status === 204 ? [res.payload.id] : [];
    dispatch(setNoContentReports(noContentReports));
    FileService.processFileResponse(res);
    return res;
  });

export const setNoContentReports = noContentReports => ({
  type: types.SET_NO_CONTENT_REPORTS,
  noContentReports,
});

export const clearNoContentReport = () => ({ type: types.CLEAR_NO_CONTENT_REPORTS });
