import get from 'lodash/get';
import { createSelector } from 'reselect';

import ErrorService from '../../../services/Error.service';

const DEFAULT_PATH = 'api.parcels';

const resolveStatePath = (state, path) => get(state, path || DEFAULT_PATH);

export const getParcels = path => state => resolveStatePath(state, path)?.items || [];

export const getParcelsMapActionable = path => state => resolveStatePath(state, path)?.mapActionable;

export const getParcel = path => state => resolveStatePath(state, path).item;

export const isParcelFetching = path => state => resolveStatePath(state, path).isFetching;

export const getIsParcelItemFetching = path => state => resolveStatePath(state, path).isFetchingItem;

export const getParcelActions = path => state => resolveStatePath(state, path).itemActions;

export const isParcelActionsFetching = path => state => resolveStatePath(state, path).isFetchingActions;

export const getTotalCount = path => state => resolveStatePath(state, path).totalCount;

export const getParcelActionsTotalCount = path => state => resolveStatePath(state, path).itemActionsTotalCount;

export const getError = state => ErrorService.getResErrorDto(state.api.parcels.error);

export const getParcelItemError = state => ErrorService.getResErrorDto(state.api.parcels.itemError);

export const getParcelStatistics = path => state => resolveStatePath(state, path).statistics;

export const isParcelStatisticsFetching = path => state => resolveStatePath(state, path).isFetchingStatistics;

export const getParcelStatisticsError = state => ErrorService.getResErrorDto(state.api.parcels.statisticsError);

export const getParcelsFertilization = state => state.api.parcels.fertilization;

export const getParcelsFertilizationIsFetching = state => state.api.parcels.isFetchingFertilization;

export const getParcelDetailSeedingInfo = state => state.api.parcels.seedingInfo;

export const isParcelSeedingInfoFetching = state => state.api.parcels.isFetchingSeedingInfo;

export const getParcelDetailRestrictions = state => state.api.parcels.restrictions;

export const isParcelRestrictionsFetching = state => state.api.parcels.isFetchingRestrictions;

export const getCenters = state => state.api.parcels.centers;

export const isCentersFetching = state => state.api.parcels.isFetchingCenters;

export const getParcelsSuggestions = filterFnc =>
  createSelector(getParcels(), items => (filterFnc ? items.filter(filterFnc) : items));
