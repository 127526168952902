import React, { FC } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { changeDriveStateSaga } from '../../actions/telematicsList.actions';

import withPopover from '../../../shared/hocs/withPopover';

import { State } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    paddingRight: 6,
    marginRight: 8,
  },
  rightIcon: {
    position: 'relative',
    top: -1,
  },
  selectedLabel: {
    paddingTop: 2,
  },
}));

interface TelematicsListStateSwitcherProps {
  anchorEl: HTMLElement;
  changeDriveStateSaga: (state: State) => void,
  handlePopoverClose(): void;
  handlePopoverOpen(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  isOpen: boolean;
  selected: string[];
}

const TelematicsListStateSwitcher: FC<TelematicsListStateSwitcherProps> = ({
  anchorEl,
  changeDriveStateSaga,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  selected,
}) => {
  const classes = useStyles();

  const handleApprove = () => {
    changeDriveStateSaga(State.APPROVED);
    handlePopoverClose();
  };
  const handleUnapprove = () => {
    changeDriveStateSaga(State.NOT_APPROVED);
    handlePopoverClose();
  };

  return (
    <>
      <Button
        aria-label="telematics list actions button"
        className={classnames(classes.button, { [classes.activeButton]: !!selected.length })}
        disabled={!selected.length}
        onClick={handlePopoverOpen}
      >
        <span className={classes.selectedLabel}>
          <FormattedMessage id="TelematicsList.markAs" />
          {selected.length > 0 && ` (${selected.length})`}
        </span>
        <ArrowDropDownIcon className={classes.rightIcon} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="telematics-list-actions-menu"
        onClose={handlePopoverClose}
        open={isOpen}
      >
        <ListItem button data-test={'approve'} onClick={handleApprove}>
          <ListItemText>
            <FormattedMessage id="TelematicsList.APPROVED" />
          </ListItemText>
        </ListItem>
        <ListItem button data-test={'unapprove'} onClick={handleUnapprove}>
          <ListItemText>
            <FormattedMessage id="TelematicsList.NOT_APPROVED" />
          </ListItemText>
        </ListItem>
      </Menu>
    </>
  );
};

const mapDispatchToProps = {
  changeDriveStateSaga,
};

export default connect(null, mapDispatchToProps)(withPopover(TelematicsListStateSwitcher));
