import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  uploadInput: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  uploadButton: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  buttonLabel: {
    width: '100%',
  },
  filename: {
    textTransform: 'none',
    fontSize: 12,
  },
});

export class CfFileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: '',
    };
  }

  onChange = e => {
    if (e.target.files.length > 0) {
      this.setState({
        filename: e.target.files[0] ? e.target.files[0].name : '',
      });
      this.props.onChange(e);
    }
  };

  /**
   * WORKAROUND!!
   * FF has different target when clicking on a button with input inside than chrome
   * @param e
   */
  clickUpload = e => {
    if (e.target.localName === 'button') {
      e.stopPropagation();
      this.fileInput.click();
    }
  };

  render() {
    const { btnTranslId, classes } = this.props;
    const { filename } = this.state;
    return (
      <Button
        classes={{ root: classes.uploadButton, label: classes.buttonLabel }}
        id="file-name-button"
        onClick={e => this.clickUpload(e)}
      >
        {filename.length === 0 ? (
          <FormattedMessage id={btnTranslId} />
        ) : (
          <Typography className={classes.filename} noWrap={true}>
            {filename}
          </Typography>
        )}
        <input
          className={classes.uploadInput}
          id="file-name"
          onChange={this.onChange}
          type="file"
          ref={fileInput => {
            this.fileInput = fileInput;
          }}
        />
      </Button>
    );
  }
}

CfFileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  btnTranslId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CfFileUpload.defaultProps = {
  btnTranslId: 'common.select-file',
};

export default withStyles(styles)(CfFileUpload);
