import React from 'react';

import FormControl from '@mui/material/FormControl';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  formControl: {
    margin: '5px 0px 25px 0px',
  },
};

function CfFormControl(props) {
  const { classes } = props;
  return (
    <FormControl className={classes.formControl} fullWidth={true}>
      {props.children}
    </FormControl>
  );
}

CfFormControl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object,
};

CfFormControl.defaultProps = {
  children: {},
  classes: {},
};

export default withStyles(styles)(CfFormControl);
