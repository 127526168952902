export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';
export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';

export const GET_INACTIVE_DEVICES_SUCCESS = 'GET_INACTIVE_DEVICES_SUCCESS';
export const GET_INACTIVE_DEVICES_ERROR = 'GET_INACTIVE_DEVICES_ERROR';
export const GET_INACTIVE_DEVICES_REQUEST = 'GET_INACTIVE_DEVICES_REQUEST';

export const GET_IRRIGATIONS_POINTS = 'GET_IRRIGATIONS_POINTS';
export const GET_IRRIGATIONS_POINTS_SUCCESS = 'GET_IRRIGATIONS_POINTS_SUCCESS';
export const GET_IRRIGATIONS_POINTS_ERROR = 'GET_IRRIGATIONS_POINTS_ERROR';
export const RESET_IRRIGATIONS_POINTS = 'RESET_IRRIGATIONS_POINTS';

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_ERROR = 'GET_DEVICE_ERROR';

export const GET_DEVICE_VALVES_REQUEST = 'GET_DEVICE_VALVES_REQUEST';
export const GET_DEVICE_VALVES_SUCCESS = 'GET_DEVICE_VALVES_SUCCESS';
export const GET_DEVICE_VALVES_ERROR = 'GET_DEVICE_VALVES_ERROR';

export const PATCH_DEVICE_REQUEST = 'PATCH_DEVICE_REQUEST';
export const PATCH_DEVICE_SUCCESS = 'PATCH_DEVICE_SUCCESS';
export const PATCH_DEVICE_ERROR = 'PATCH_DEVICE_ERROR';

export const GET_DEVICE_INTERVALS_REQUEST = 'GET_DEVICE_INTERVALS_REQUEST';
export const GET_DEVICE_INTERVALS_SUCCESS = 'GET_DEVICE_INTERVALS_SUCCESS';
export const GET_DEVICE_INTERVALS_ERROR = 'GET_DEVICE_INTERVALS_ERROR';

export const POST_VALVES_ACTION_REQUEST = 'POST_VALVES_ACTION_REQUEST';
export const POST_VALVES_ACTION_SUCCESS = 'POST_VALVES_ACTION_SUCCESS';
export const POST_VALVES_ACTION_ERROR = 'POST_VALVES_ACTION_ERROR';

export const GET_ADMIN_DEVICES_REQUEST = 'GET_ADMIN_DEVICES_REQUEST';
export const GET_ADMIN_DEVICES_SUCCESS = 'GET_ADMIN_DEVICES_SUCCESS';
export const GET_ADMIN_DEVICES_ERROR = 'GET_ADMIN_DEVICES_ERROR';

export const PATCH_ADMIN_DEVICE_REQUEST = 'PATCH_ADMIN_DEVICE_REQUEST';
export const PATCH_ADMIN_DEVICE_SUCCESS = 'PATCH_ADMIN_DEVICE_SUCCESS';
export const PATCH_ADMIN_DEVICE_ERROR = 'PATCH_ADMIN_DEVICE_ERROR';

export const RESET_ADMIN_DEVICES = 'RESET_ADMIN_DEVICES';

export const POST_ADMIN_SYNC_PC_REQUEST = 'POST_ADMIN_SYNC_PC_REQUEST';
export const POST_ADMIN_SYNC_PC_SUCCESS = 'POST_ADMIN_SYNC_PC_SUCCESS';
export const POST_ADMIN_SYNC_PC_ERROR = 'POST_ADMIN_SYNC_PC_ERROR';

export const GET_DEVICE_TYPES_REQUEST = 'GET_DEVICE_TYPES_REQUEST';
export const GET_DEVICE_TYPES_SUCCESS = 'GET_DEVICE_TYPES_SUCCESS';
export const GET_DEVICE_TYPES_ERROR = 'GET_DEVICE_TYPES_ERROR';

export const RESET_DEVICE_TYPES = 'RESET_DEVICE_TYPES';
