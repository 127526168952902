/**
 * Created by ondrejzvara on 17.1.16.
 */

export function cfPorCropList() {
    return function(array) {

        var result = '-';
        if(_.isArray(array)){

            result = "";


            _.each(array, function(restriction){

                if(_.isArray(restriction.clCropList)){

                    _.each(restriction.clCropList, function(item){
                        if(item.name) {
                            result += item.name;
                            result += ', ';
                        }
                    });

                } else {

                    if(restriction.name) {
                        result += restriction.name;
                        result += ', ';
                    }

                }

            });

            if(result){
                result = _.trimEnd(result, ', ');
            } else {
                result = "-";
            }

        }

        return result;

    };
}
