import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfChartTooltipDaytime from '../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime';

const style = theme => ({
  customTooltip: {
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    '-webkit-border-radius': 3,
    '-moz-border-radius': 3,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-moz-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: 0,
  },
  tooltipLabelWrapper: {
    padding: '10px 10px 10px 10px',
    backgroundColor: '#F7F7F7',
  },
  tooltipValue: {
    margin: 0,
    padding: '10px 10px 10px 10px',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
});

function PestPredictionGraphTooltip(props) {
  const { classes, day, dayCount, messageId, progressValue } = props;
  if (messageId) {
    return (
      <div className={classes.customTooltip}>
        <div>
          {day && (
            <div className={classes.tooltipLabelWrapper}>
              <CfChartTooltipDaytime dayCount={dayCount} label={day} pestChart={true} />
            </div>
          )}
          <p className={classes.tooltipValue}>
            <FormattedMessage
              id={messageId}
              values={
                progressValue !== undefined
                  ? {
                    count: progressValue,
                  }
                  : {}
              }
            />
          </p>
        </div>
      </div>
    );
  }
}

PestPredictionGraphTooltip.propTypes = {
  messageId: PropTypes.string.isRequired,
  classes: PropTypes.object,
  day: PropTypes.string,
  dayCount: PropTypes.number,
  progressValue: PropTypes.number,
};

PestPredictionGraphTooltip.defaultProps = {
  classes: {},
  day: '',
  dayCount: 0,
  progressValue: undefined,
};

export default withStyles(style)(PestPredictionGraphTooltip);
