import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const BufferInvertIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M12.0016 17.3453C11.5716 17.3453 11.2266 17.0003 11.2266 16.5703C11.2266 16.1403 11.5716 15.7953 12.0016 15.7953C12.4316 15.7953 12.7766 16.1403 12.7766 16.5703C12.7766 17.0003 12.4316 17.3453 12.0016 17.3453ZM12.0016 14.1453C11.5716 14.1453 11.2266 13.8003 11.2266 13.3703C11.2266 12.9403 11.5716 12.5953 12.0016 12.5953C12.4316 12.5953 12.7766 12.9403 12.7766 13.3703C12.7766 13.8003 12.4316 14.1453 12.0016 14.1453ZM12.0016 10.9453C11.5716 10.9453 11.2266 10.6003 11.2266 10.1703C11.2266 9.74031 11.5716 9.39531 12.0016 9.39531C12.4316 9.39531 12.7766 9.74031 12.7766 10.1703C12.7766 10.6003 12.4316 10.9453 12.0016 10.9453ZM12.0016 7.74531C11.5716 7.74531 11.2266 7.40031 11.2266 6.97031C11.2266 6.54031 11.5716 6.19531 12.0016 6.19531C12.4316 6.19531 12.7766 6.54031 12.7766 6.97031C12.7766 7.40031 12.4316 7.74531 12.0016 7.74531Z" />
      <path d="M21.4287 7.1035C21.0803 6.93577 20.6715 6.98236 20.3696 7.22463L15.3762 11.2128C15.1393 11.4038 15 11.6926 15 12.0001C15 12.3076 15.1393 12.5965 15.3762 12.7875L20.3696 16.7803C20.5508 16.9247 20.7691 16.9992 20.9967 16.9992C21.1453 16.9992 21.294 16.9666 21.4333 16.9014C21.7817 16.7337 22 16.3796 22 15.9929V8.00735C21.9954 7.62065 21.777 7.27122 21.4287 7.1035Z" />
      <path d="M3.63039 7.22073C3.32847 6.97846 2.91506 6.93187 2.57133 7.09959C2.22296 7.26732 2 7.6214 2 8.0081V15.9937C2 16.3804 2.22296 16.7344 2.56669 16.9022C2.70604 16.9674 2.85468 17 3.00332 17C3.22628 17 3.44924 16.9255 3.63039 16.781L8.62376 12.7883C8.86065 12.5972 9 12.3084 9 12.0009C9 11.6934 8.86065 11.4045 8.62376 11.2135L3.63039 7.22073ZM4.00664 13.9064V10.1L6.38487 12.0009L4.00664 13.9064Z" />
      <path d="M12.0016 18.9961C11.5716 18.9961 11.2266 19.3411 11.2266 19.7711C11.2266 20.2011 11.5716 20.5461 12.0016 20.5461C12.4316 20.5461 12.7766 20.2011 12.7766 19.7711C12.7766 19.3411 12.4316 18.9961 12.0016 18.9961Z" />
      <path d="M12.0016 3C11.5716 3 11.2266 3.345 11.2266 3.775C11.2266 4.205 11.5716 4.55 12.0016 4.55C12.4316 4.55 12.7766 4.205 12.7766 3.775C12.7766 3.345 12.4316 3 12.0016 3Z" />
    </svg>
  </SvgIcon>
);

export default BufferInvertIcon;
