export const RETIRED_PARCEL = 'RETIRED_PARCEL';
export const NON_IDENTIC_LPIS_ERROR = 'NON_IDENTIC_LPIS_ERROR';

export const SPLIT_IS_SIMPLE_ERROR = 'SPLIT_IS_SIMPLE_ERROR';
export const SPLIT_IS_DISJOINT_ERROR = 'SPLIT_IS_DISJOINT_ERROR';
export const SPLIT_PARTIAL_CROSSING_ERROR = 'SPLIT_PARTIAL_CROSSING_ERROR';

export const MERGE_NON_CONSISTENT_ERROR = 'MERGE_NON_CONSISTENT_ERROR';

export const DRAW_REDUCTION_IS_SIMPLE_ERROR = 'DRAW_REDUCTION_IS_SIMPLE_ERROR';
export const DRAW_PARCEL_IS_SIMPLE_ERROR = 'DRAW_PARCEL_IS_SIMPLE_ERROR';
export const DRAW_IS_DISJOINT_ERROR = 'DRAW_IS_DISJOINT_ERROR';
export const DRAW_OVERLAP_ERROR = 'DRAW_OVERLAP_ERROR';
export const DRAW_CONTAINED_ERROR = 'DRAW_CONTAINED_ERROR';
export const DRAW_IS_MUTLIPLE_ERROR = 'DRAW_IS_MUTLIPLE_ERROR';

export const DATE_VALID_FROM_ERROR = 'DATE_VALID_FROM_ERROR';

export const BUFFER_SIZE_INVALID_ERROR = 'BUFFER_SIZE_INVALID_ERROR';
export const BUFFER_SIZE_TOO_BIG_ERROR = 'BUFFER_SIZE_TOO_BIG_ERROR';
export const BUFFER_TOO_MANY_PARCELS_ERROR = 'BUFFER_TOO_MANY_PARCELS_ERROR';
