import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => (
  {
    wrapper: {
      margin: 15,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: 10,
    },
    filtersHeader: {
      display: 'flex',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    actionButtons: {
      paddingBottom: 6,
      [theme.breakpoints.down('sm')]: {
        order: 3,
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: 14,
      },
    },
    textFilter: {
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '450px',
      },
    },
    fab: {
      height: '28px',
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.down(700)]: {
        display: 'none',
      },
    },
    fabMobile: {
      height: '28px',
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.up(700)]: {
        display: 'none',
      },
    },
  }
));
