import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TargetIconSelected = props => (
  <SvgIcon {...props}>
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" fill="#EBA607" r="12" />
      <path d="M12 8.72727C10.1918 8.72727 8.72727 10.1918 8.72727 12C8.72727 13.8082 10.1918 15.2727 12 15.2727C13.8082 15.2727 15.2727 13.8082 15.2727 12C15.2727 10.1918 13.8082 8.72727 12 8.72727ZM19.3145 11.1818C18.9382 7.77 16.23 5.06182 12.8182 4.68545V3H11.1818V4.68545C7.77 5.06182 5.06182 7.77 4.68545 11.1818H3V12.8182H4.68545C5.06182 16.23 7.77 18.9382 11.1818 19.3145V21H12.8182V19.3145C16.23 18.9382 18.9382 16.23 19.3145 12.8182H21V11.1818H19.3145ZM12 17.7273C8.83364 17.7273 6.27273 15.1664 6.27273 12C6.27273 8.83364 8.83364 6.27273 12 6.27273C15.1664 6.27273 17.7273 8.83364 17.7273 12C17.7273 15.1664 15.1664 17.7273 12 17.7273Z" fill="white" />
    </svg>
  </SvgIcon>
);

export default TargetIconSelected;
