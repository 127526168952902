import React, { useEffect, FC, useState, useCallback } from 'react';

import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setActionButtonHidden } from '../shared/actions/manager.actions';

import { NAMESPACE } from './reducer/telematicsAdmin.reducer';

import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { Thunk } from '../../../types';

import ActivationDialog from './components/ActivationDialog';
import ActivationSelector from './components/ActivationSelector';
import AdvancedFilter from './components/AdvancedFilter';
import DeactivationDialog from './components/DeactivationDialog';
import TelematicsAdminTable from './components/TelematicsAdminTable';

import { AdminState } from '../../../reducers/admin.reducer.types';

interface Props {
  langId: string;
  setActionButtonHidden: (bool: boolean) => void;
}

const useStyles = makeStyles({
  filterBarContainer: {
    display: 'flex',
    marginBottom: 6,
  },
  textFilter: {
    maxWidth: 400,
  },
  item: {
    marginRight: 12,
  },
});

const TelematicsAdmin: FC<Props> = ({ langId, setActionButtonHidden }) => {
  const classes = useStyles();
  const [showActivationDialog, setActivationDialog] = useState(false);
  const [showDeactivationDialog, setDeactivationDialog] = useState(false);

  useEffect(() => {
    setActionButtonHidden(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActivationDialogOpen = useCallback(() => setActivationDialog(true), [setActivationDialog]);
  const handleActivationDialogClose = useCallback(() => setActivationDialog(false), [setActivationDialog]);
  const handleDeactivationDialogOpen = useCallback(() => setDeactivationDialog(true), [setDeactivationDialog]);
  const handleDeactivationDialogClose = useCallback(() => setDeactivationDialog(false), [setDeactivationDialog]);

  return (
    <div>
      <div className={classes.filterBarContainer}>
        <ActivationSelector
          className={classes.item}
          handleActivation={handleActivationDialogOpen}
          handleDeactivation={handleDeactivationDialogOpen}
        />
        <div className={classnames(classes.textFilter, classes.item)}>
          <CfTextFilter name="telematics-admin-text-filter" namespace={NAMESPACE} translId="TelematicsAdmin.textFilter" />
        </div>
        <AdvancedFilter langId={langId} />
      </div>
      <TelematicsAdminTable />
      <ActivationDialog handleClose={handleActivationDialogClose} opened={showActivationDialog} />
      <DeactivationDialog handleClose={handleDeactivationDialogClose} opened={showDeactivationDialog} />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<AdminState>) =>
  bindActionCreators(
    {
      setActionButtonHidden,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TelematicsAdmin);
