import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import KnowledgebaseIcon from '../../../../shared/icons/navbar/KnowledgebaseIcon';
import SupportIcon from '../../../../shared/icons/navbar/SupportIcon';
import Intercom from '../../../../shared/services/Intercom.service';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';

// eslint-disable-next-line
const HelpMenu = React.forwardRef((props, ref) => {
  const { mobile, onMenuItemClick } = props;
  return (
    <div>
      <ContextMenuItem
        component={'a'}
        href={'http://help.cleverfarm.cz'}
        icon={<KnowledgebaseIcon />}
        id="help-menu-knowledgebase"
        label={<FormattedMessage id="HelpMenu.knowledgebase" />}
        mobile={mobile}
        target="_blank"
        onClick={() => {
          onMenuItemClick();
        }}
      />
      <ContextMenuItem
        icon={<SupportIcon />}
        id="help-menu-support"
        label={<FormattedMessage id="HelpMenu.support" />}
        mobile={mobile}
        onClick={() => {
          onMenuItemClick();
          Intercom.show();
        }}
      />
    </div>
  );
});

HelpMenu.propTypes = {
  onMenuItemClick: PropTypes.func,
  mobile: PropTypes.bool,
};

HelpMenu.defaultProps = {
  onMenuItemClick: () => {},
  mobile: false,
};

export default HelpMenu;
