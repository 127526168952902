import moment from 'moment/moment';

import { FORM_TYPES } from '../../others/actionOther.constants';

import Localization from '../../../../shared/services/Localization.service';
import { isMatchingFormType } from '../../others/helpers/others.helpers';

export default class ActionStateMapper {
  static mapEphActionState(action) {
    const actionDateMoment = moment(action.dateStart).startOf('day');

    return {
      isCatchCrop: this.mapCatchCrop(action.fertilizationTarget),
      isStrawDecay: this.mapStrawDecay(action.fertilizationTarget),
      actionDate: actionDateMoment,
      actionNote: action.note,
      parcels: this.mapParcelsFrom(action.parcelList),
      expenses: this.mapExpensesFrom(action.parcelChemistryEffectiveness, action.expenseList, actionDateMoment),
      targetCrop: action.targetCrop,
    };
  }

  static mapOtherActionState(action, locale) {
    const actionDateMoment = moment(action.dateStart).startOf('day');

    const isHarvestAction = isMatchingFormType(action, FORM_TYPES.HARVEST);
    const isMowingAction = isMatchingFormType(action, FORM_TYPES.MOWING);
    const isSowingAction = isMatchingFormType(action, FORM_TYPES.SOWING);
    const hasHarvestOrMowingFields = isHarvestAction || isMowingAction;
    const hasSowingFields = isSowingAction;

    const standardFields = {
      parcels: this.mapActionOtherParcelsFrom(action.parcelList),
      date: actionDateMoment,
      description: action.note,
    };

    const harvestOrMowingFields = {
      totalGain: Localization.num2strNonFixed(action?.revenueList?.[0]?.amount, locale) || '0',
      hectarGain: '0',
      productId: action?.revenueList?.[0]?.productId || '',
      harvestCrop: true,
    };

    const sowingFields = {
      isCatchCrop: action.parcelList?.[0]?.seedApplication?.seedApplicationType !== 'MAIN_CROP',
      seedId: action.expenseList?.[0]?.material?.id || '',
      totalAmount: Localization.num2strNonFixed(action.expenseList?.[0]?.amount, locale) || '0',
      hectarAmount: '0',
      unitId: action.expenseList?.[0]?.unit?.id || '',
    };

    return {
      actionType: action.actionType.code,
      ...standardFields,
      ...(hasHarvestOrMowingFields && harvestOrMowingFields),
      ...(hasSowingFields && sowingFields),
    };
  }

  static mapActionOtherParcelsFrom(parcels) {
    return parcels.map(p => ({
      ...p.parcel,
      id: p.parcel.id,
      area: p.area + p.restrictedArea,
      subtractableAreas: this.mapNewSubtractableAreasFrom(p),
      actionParcelTotalArea: p.area,
      restrictedArea: p.restrictedArea,
    }));
  }

  // Reminder: subtractableAreas in parcel object from BE does not contain restrictionArea parameter
  // need to call update for calc
  static mapNewSubtractableAreasFrom(p) {
    // areas saved for parcel are in p.subtractionAreas
    // p.restrictions are actually used restrictions for parcel
    const subtractableAreas = p.restrictions.map(sa => ({ ...sa, checked: true, parcelId: p.id }));
    p.subtractionAreas.forEach(predefinedSa => {
      const matchedSa = subtractableAreas.find(
        sa =>
          sa.restrictionType === predefinedSa.restrictionType && sa.restrictionValue === predefinedSa.restrictionValue,
      );
      if (!matchedSa) {
        subtractableAreas.push({ ...predefinedSa, parcelId: p.id });
      }
    });

    const areas = {
      absolute: subtractableAreas.filter(sa => sa.restrictionType === 'Absolute'),
      boundary: [
        ...subtractableAreas.filter(sa => sa.restrictionType === 'Boundary'),
      ],
      water: [
        ...subtractableAreas.filter(sa => sa.restrictionType === 'Water'),
      ],
    };
    areas.boundaryChecked = subtractableAreas.filter(sa => sa.restrictionType === 'Boundary').find(sa => sa.checked === true)?.restrictionValue || 0;
    areas.waterChecked = subtractableAreas.filter(sa => sa.restrictionType === 'Water').find(sa => sa.checked === true)?.restrictionValue || 0;
    return areas;
  }

  static mapVrfActionState(action) {
    const actionDateMoment = moment(action.dateStart).startOf('day');

    return {
      actionDate: actionDateMoment,
      actionNote: action.note,
      parcels: this.mapParcelsFrom(action.parcelList),
      expenses: this.mapExpensesFrom(action.parcelChemistryEffectiveness, action.expenseList, actionDateMoment),
      targetCrop: action.targetCrop,
    };
  }

  // TODo maybe adjust if neede
  static mapVrsActionState(action) {
    const actionDateMoment = moment(action.dateStart).startOf('day');
    return {
      actionDate: actionDateMoment,
      actionNote: action.note,
      parcels: this.mapParcelsFrom(action.parcelList),
      expenses: this.mapExpensesFrom(action.parcelChemistryEffectiveness, action.expenseList, actionDateMoment),
      targetCrop: action.targetCrop,
    };
  }

  static mapParcelsFrom(parcelList) {
    return parcelList.map(parcelObj => {
      const parcel = parcelObj.parcel;
      const materialsSas = parcelObj.materialsSubtractionAreas.map(area => {
        const newArea = area;
        newArea.material = true;
        return newArea;
      });
      const subtractableAreas = materialsSas.concat(parcelObj.subtractionAreas);

      parcelObj.restrictions.forEach(restriction => {
        const matchedSa = subtractableAreas.find(
          sa =>
            sa.restrictionType === restriction.restrictionType && sa.restrictionValue === restriction.restrictionValue,
        );
        if (matchedSa) {
          matchedSa.checked = true;
        }
      });
      parcel.actionParcelTotalArea = parcelObj.area + parcelObj.restrictedArea;
      parcel.restrictedArea = parcelObj.restrictedArea;
      parcel.subtractableAreas = subtractableAreas;
      parcel.actionSeedApplication = parcelObj.seedApplication;

      return parcel;
    });
  }

  static mapExpenseEffectiveness(parcelChemistryEffectiveness, materialId) {
    if (!parcelChemistryEffectiveness) return [];
    const parcelChemEffectiveness = parcelChemistryEffectiveness.filter(
      parcelChemistryEff => parcelChemistryEff.chemistry.id === materialId,
    );
    return parcelChemEffectiveness.map(parcelChemEff => ({
      parcelName: parcelChemEff.parcel.localName,
      parcelId: parcelChemEff.parcel.id,
      value: parcelChemEff.effectiveness,
    }));
  }

  static mapExpensesFrom(parcelChemistryEffectiveness, expenses, actionDateMoment) {
    return expenses.map(expObj => ({
      dosage: expObj.amount,
      dose: expObj.dose,
      doseUnit: expObj.unit,
      dosageUnit: expObj.unit,
      note: expObj.notes,
      material: this.mapMaterialFrom(expObj.material),
      effectiveness: this.mapExpenseEffectiveness(parcelChemistryEffectiveness, expObj.material.id),
      driftClass: expObj.driftClass ? expObj.driftClass : 'DRIFT_NONE',
      pests: expObj.targetOrganisms,
      incorporationDay: this.mapDateSoilIncorporation(expObj.dateSoilIncorporation, actionDateMoment),
      n: expObj.n,
      p2o5: expObj.p2o5,
      k2o: expObj.k2o,
    }));
  }

  static mapMaterialFrom(material) {
    let matType = null;
    switch (material['@class']) {
      case 'ChemistryTo':
        matType = 'CH';
        break;
      case 'FertilizerTo':
        matType = 'FR';
        break;
      default:
        matType = '';
    }
    return {
      materialTypeId: matType,
      id: material.id,
      name: material.name,
      marketingAuth: material.marketingAuth,
      aktualState: material.aktualState,
      regNumber: material.regNumber,
      producent: material.producent,
      n: material.n,
      p2o5: material.p2o5,
      k2o: material.k2o,
    };
  }

  static mapCatchCrop(fertilizationTarget) {
    return fertilizationTarget === 'CATCH_CROP';
  }

  static mapStrawDecay(fertilizationTarget) {
    return fertilizationTarget === 'STRAW';
  }

  static mapDateSoilIncorporation(dateSoilIncorporation, actionDateMoment) {
    const incorporationDateMoment = moment(dateSoilIncorporation);
    if (incorporationDateMoment.isValid()) {
      if (incorporationDateMoment.isSame(actionDateMoment, 'days')) {
        return 'SAME_DAY';
      } else if (
        incorporationDateMoment
          .clone()
          .subtract(1, 'd')
          .isSame(actionDateMoment, 'days')
      ) {
        return 'NEXT_DAY';
      }
    }
    return 'NO';
  }
}
