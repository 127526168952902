/**
 * Created by mpenak on 7/26/17.
 */

import React from 'react';

import { Theme, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';

const style = (theme: Theme) => ({
  dialogTitle: {
    fontSize: 21,
    fontWeight: 500,
  },
  container: {
    marginTop: 16,
  },
  copy: {
    color: theme.palette.grey[500],
  },
  link: {
    color: theme.palette.primary.main,
  },
});

interface Props {
  classes: Record<string, string>;
  copy: string;
  fileUrl: string;
  linkCopy: string;
  onOpen?: () => void;
  title: string;
  withGutter?: boolean;
}

interface State {
  open: boolean;
}

class ImportHelp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, fileUrl, withGutter } = this.props;

    return (
      <div className={withGutter ? classes.container : ''}>
        <Typography className={classes.copy} component="p" variant="caption">{this.props.copy}{' '}<a className={classes.link} href="#" onClick={this.handleOpen}>{this.props.linkCopy}</a></Typography>
        <Dialog maxWidth="md" onClose={this.handleClose} open={this.state.open}>
          <DialogTitle classes={{ root: classes.dialogTitle }}>
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            <img alt="help" src={fileUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style)(ImportHelp);
