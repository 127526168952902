import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './farms.constants';

export const getFarms = params => {
  const p = {
    page: params.page || 1,
    'per-page': params.rowsPerPage || 500,
  };

  if (params.textFilter) {
    p.name = params.textFilter;
  }

  return {
    [RSAA]: {
      endpoint: `farms?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_FARMS, types.GET_FARMS_SUCCESS, types.GET_FARMS_FAILURE],
    },
  };
};

export const getCountries = params => {
  const p = {
    page: params.page || 1,
    'per-page': params.rowsPerPage || 500,
  };

  return {
    [RSAA]: {
      endpoint: `farms/countries?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_COUNTRIES, types.GET_COUNTRIES_SUCCESS, types.GET_COUNTRIES_FAILURE],
    },
  };
};

export const getFarmById = farmId => ({
  [RSAA]: {
    endpoint: `farms?id=${farmId}&`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_FARM, types.GET_FARM_SUCCESS, types.GET_FARM_FAILURE],
  },
});

export const getEagriFarmName = () => ({
  [RSAA]: {
    endpoint: 'farms/eagri/name?',
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_EAGRI_FARM_NAME,
      {
        type: types.GET_EAGRI_FARM_NAME_SUCCESS,
        payload: (action, state, res) => res.text().then(str => str),
      },
      types.GET_EAGRI_FARM_NAME_FAILURE,
    ],
  },
});

export const createEmptyFarm = (farmName, countryCode) => ({
  [RSAA]: {
    endpoint: `farms?farmName=${farmName}&countryCode=${countryCode}&`,
    method: methods.POST,
    module: modules.CORE,
    types: [types.CREATE_EMPTY_FARM, types.CREATE_EMPTY_FARM_SUCCESS, types.CREATE_EMPTY_FARM_ERROR],
  },
});

export const createEagriFarm = farmName => ({
  [RSAA]: {
    endpoint: `farms/eagri?farmName=${farmName}&countryCode=CZ&`,
    method: methods.POST,
    module: modules.CORE,
    types: [types.CREATE_EAGRI_FARM, types.CREATE_EAGRI_FARM_SUCCESS, types.CREATE_EAGRI_FARM_ERROR],
  },
});

export const validateFarmFile = (countryCode, formData) => ({
  [RSAA]: {
    endpoint: `farms/file/validate?countryCode=${countryCode}&`,
    method: methods.POST,
    noContentType: true,
    body: formData,
    module: modules.CORE,
    types: [types.VALIDATE_FARM_FILE, types.VALIDATE_FARM_FILE_SUCCESS, types.VALIDATE_FARM_FILE_ERROR],
  },
});

export const createFarmByFile = (countryCode, farmName, formData) => ({
  [RSAA]: {
    endpoint: `farms/file?countryCode=${countryCode}&farmName=${farmName}&`,
    method: methods.POST,
    noContentType: true,
    body: formData,
    module: modules.CORE,
    types: [types.CREATE_FARM_BY_FILE, types.CREATE_FARM_BY_FILE_SUCCESS, types.CREATE_FARM_BY_FILE_ERROR],
  },
});

export const resetFarms = () => ({
  type: types.RESET_FARMS,
});

export const resetCountries = () => ({
  type: types.RESET_COUNTRIES,
});
