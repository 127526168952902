import React, { FC, ReactElement } from 'react';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

interface Props {
    handleClose: () => void;
    mainMessage?: ReactElement,
    message?: ReactElement,
    open: boolean;
}

const useStyles = makeStyles({
  highlighted: {
    fontWeight: 700,
    marginRight: '7px',
  },
  normal: {
    fontWeight: 400,
  },
});

const ErrorSnackbar: FC<Props> = ({
  open,
  handleClose,
  mainMessage = <FormattedMessage id="Irrigation.apiError.main" />,
  message = <FormattedMessage id="Irrigation.apiError.sub" />,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleClose}
      open={open}
  >
      <MuiAlert icon={false} severity="error" variant="filled">
        <span className={classes.highlighted}>{mainMessage}</span>
        <span className={classes.normal}>{message}</span>
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
