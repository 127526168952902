import React, { Suspense } from 'react';

import CfLoaderPage from '../../../../../shared/components/common/CfLoaderPage/CfLoaderPage';
import Actions from '../Actions/Actions';

const ActionsWrapper = props => (
  <Suspense fallback={<CfLoaderPage />}>
    <Actions {...props} />
  </Suspense>
);

export default ActionsWrapper;
