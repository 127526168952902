/**
 * Created by ondrejzvara on 2.11.15.
 */


/*@ngInject*/
export default function ChemistriesResource(Api, ResourceUtils, MaterialToMapper) {

    var baseOne = null;
    var baseAll = Api.setBaseAll("por");
    var baseAllPublic = Api.setBaseAll("por/public");
    var baseAllBio = Api.setBaseAll("por/bioFunction");
    var baseAllActiveSubst = Api.setBaseAll("por/activeSubstance");

    var service = {
        getChemistry: getChemistry,
        getChemistries: getChemistries,
        editChemistry: editChemistry,
        createChemistry: createChemistry,
        deleteChemistries: deleteChemistries,
        publishChemistry: publishChemistry,
        verifyChemistry: verifyChemistry,
        getPublicChemistry: getPublicChemistry,
        getPublicChemistries: getPublicChemistries,
        editPublicChemistry: editPublicChemistry,
        addChemistryToFarm: addChemistryToFarm,
        getChemistryLabel: getChemistryLabel,
        getChemistryBioFuntions: getChemistryBioFuntions,
        getChemistryActiveSubstance: getChemistryActiveSubstance
    };
    return service;

    /////////////////////////

    function getChemistry(chemistryId) {
        var baseOne = Api.setBaseOne("por", chemistryId);
        return ResourceUtils.getItem(baseOne, {});
    }

    function getPublicChemistry(chemistryId) {
        var baseOne = Api.setBaseOne("por/public", chemistryId);
        return ResourceUtils.getItem(baseOne, {});
    }

    function getChemistries(ngTparams, custParams) {

        var params = ResourceUtils.composeParams(ngTparams, custParams);

        if (custParams && custParams.cropId) {
            params.cropId = custParams.cropId;
        }

        return ResourceUtils.getItems(baseAll, params, ngTparams);
    }

    function getPublicChemistries(ngTparams, custParams) {

        var params = ResourceUtils.composeParams(ngTparams, custParams);
        return ResourceUtils.getItems(baseAllPublic, params, ngTparams);
    }

    function editChemistry(item) {
        item = Api.restangularizeOne(null, item, "por");
        return ResourceUtils.editItem(item);
    }

    function createChemistry(item) {
        item = Api.restangularizeOne(null, item, "por");
        return ResourceUtils.createItem(baseAll, MaterialToMapper.modelToDto(item, "ChemistryTo"), true)
            .then(function (response) {
                return ResourceUtils.getIdAfterCreate(baseAll, response);
            });
    }

    function editPublicChemistry(item) {
        item = Api.restangularizeOne(baseAll, item, "public");
        return ResourceUtils.editItem(item);
    }

    function deleteChemistries(items) {
        return ResourceUtils.deleteItems(items);
    }

    function addChemistryToFarm(legislativeCode, isPublic) {
        return ResourceUtils.addToFarm(isPublic ? baseAllPublic : baseAll, legislativeCode, true)
            .then(function (response) {
                return ResourceUtils.getIdAfterCreate(baseAll, response);
            });
    }

    function publishChemistry(chemistryId) {
        return ResourceUtils.customPost(baseAllPublic, null, chemistryId);
    }

    function verifyChemistry(publicChemistryId) {
        var baseOne = Api.setBaseOne("por/public", publicChemistryId);
        return ResourceUtils.customPost(baseOne, null, "verify");
    }

    function getChemistryLabel(porId) {
        baseOne = Api.setBaseOne("por", porId);
        return ResourceUtils.customGet(baseOne, "label");
    }

    function getChemistryBioFuntions(ngTparams, custParams) {
        var params = ResourceUtils.composeParams(ngTparams, custParams);

        /*jshint camelcase: false */
        if (custParams && custParams.search) {
            params.search = custParams.search;
        }

        return ResourceUtils.getItems(baseAllBio, params, ngTparams);
    }

    function getChemistryActiveSubstance(ngTparams, custParams) {
        var params = ResourceUtils.composeParams(ngTparams, custParams);

        /*jshint camelcase: false */
        if (custParams && custParams.search) {
            params.search = custParams.search;
        }

        return ResourceUtils.getItems(baseAllActiveSubst, params, ngTparams);
    }
}
