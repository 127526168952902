import React, { Component } from 'react';

import { Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import PasswordField from '../../signup/components/PasswordField/PasswordField';

const style = {
  textField: {
    textAlign: 'left',
    width: '100%',
    marginTop: 0,
  },
  header: {
    marginTop: 0,
    fontWeight: 500,
    fontSize: 24,
  },
  button: {
    width: '100%',
  },
};

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordLengthError: false,
    };
  }

  onInputChange = e => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  onInputFocus = () => {
    this.setState({
      passwordLengthError: false,
    });
  };

  validateLength = callback => {
    const password = this.state.password;
    if (password.trim().length < 6) {
      this.setState({
        passwordLengthError: true,
      });
      return;
    }
    this.setState(
      {
        passwordLengthError: false,
      },
      callback,
    );
  };

  submit = () => {
    this.validateLength(() => {
      if (!this.state.passwordLengthError) {
        this.props.onNewPassword(this.state.password);
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { password, passwordLengthError } = this.state;

    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <h3 className={classes.header}>
            <FormattedMessage id="reset.password.header" />
          </h3>
        </Grid>
        <Grid item xs={12}>
          <FormattedMessage id="reset.password.guide" />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            errorText={passwordLengthError && <FormattedMessage id="reset.password.length.error" />}
            fullWidth={true}
            hintText={formatMessage({ id: 'reset.password.hint' })}
            label={<FormattedMessage id="reset.password.label" />}
            onBlur={() => this.validateLength()}
            onChange={e => this.onInputChange(e)}
            onFocus={e => this.onInputFocus(e)}
            value={password}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            color="primary"
            id="new-password"
            onClick={() => this.submit()}
            variant="contained"
          >
            <FormattedMessage id="reset.password.button" />
          </Button>
        </Grid>
      </Grid>
    );
  }
}

NewPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onNewPassword: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(style)(NewPasswordForm));
