import {
  getAdminParcels,
  getAdminParcelsTotalCount,
  isFetchingAdminParcels,
} from '../../../../shared/api/sentinel/admin/admin.selectors';

export const getSentinelAdminPage = state => state.ui.sentinel_admin.page;
export const getSentinelAdminOrder = state => state.ui.sentinel_admin.order;
export const getSentinelAdminOrderBy = state => state.ui.sentinel_admin.orderBy;
export const getSentinelAdminRowsPerPage = state => state.ui.sentinel_admin.rowsPerPage;
export const getSentinelAdminTextFilter = state => state.ui.sentinel_admin.textFilter;
export const getSentinelAdminFarm = state => state.ui.sentinel_admin.farm;

export const getSentinelAdminParcelsSuggestions = state => state.api.parcelsSuggestions.items;

export const getActiveSentinelParcels = state => getAdminParcels(state);
export const isFetchingActiveSentinelParcels = state => isFetchingAdminParcels(state);
export const getSentinelAdminTotalCount = state => getAdminParcelsTotalCount(state);
