import { createSelector } from 'reselect';

import {
  getItems as getFarmItems,
  getCountries as getCountriesItems,
} from '../../../../shared/api/core/farms/farms.selectors';

export const getFarmsSuggestions = createSelector(getFarmItems, farms => farms);

export const getCountriesSuggestions = createSelector(getCountriesItems, countries => countries);

export const isDialogOpen = state => state.ui.manager.dialogOpen;
export const isActionButtonEnabled = state => state.ui.manager.actionButtonEnabled;
export const isActionButtonHidden = state => state.ui.manager.actionButtonHidden;
export const isSensorsTabActive = state => state.ui.manager.sensorsTabActive;
export const isIrrigationTabActive = state => state.ui.manager.irrigationTabActive;
