/**
 * Created by brasko on 16.9.2015.
 */

/*@ngInject*/
export default function FarmStorage(Storage) {

    var farmObj = {};

    var service = {
        setFarm: setFarm,
        getFarm: getFarm,
        getFarmId: getFarmId
    };
    return service;

    /////////////////////////

    function setFarm(f) {
        var defer = Storage.getUnresolvedDefer(farmObj);
        defer.resolve(f);
        Storage.setResolvedDefer(farmObj);
    }

    function getFarm() {
        var defer = Storage.getDefer(farmObj);
        return defer.promise;
    }

    function getFarmId(){
        return getFarm().then(function(farm){
            return farm.id;
        });
    }
    
}