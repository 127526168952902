import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';

import SnackbarText from '../SnackbarText/SnackbarText';

const tool = tools.DRAW;

class DrawHint extends Component {
  constructor(props) {
    super(props);
    this.platform = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'MAC' : 'OTHER';
  }

  getHint = (stage) => {
    switch (stage) {
      case stages.STAGE_1:
        return (
          <SnackbarText color="light">
            <FormattedMessage id={`hint.${tool}_${stage}_PT1`} />
          </SnackbarText>
        );
      case stages.STAGE_2:
        return (
          <SnackbarText color="light">
            <FormattedMessage
              id={`hint.${tool}_${stage}_PT1`}
              values={{
                pt2: (
                  <SnackbarText color="dark">
                    <FormattedMessage id={`hint.${tool}_${this.platform}_${stage}_PT2`} />
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );
      default:
        return null;
    }
  };

  render() {
    const { data, stage } = this.props;
    return this.getHint(stage, data);
  }
}

DrawHint.propTypes = {
  stage: PropTypes.string,
  data: PropTypes.object,
};

DrawHint.defaultProps = {
  stage: null,
  data: null,
};

export default DrawHint;
