import get from 'lodash/get';

import * as types from './parcels.constants';

export default reducerContext => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    mapActionable: [],
    mapActionableError: {},
    isFetchingMapActionable: false,
    isFetchingItem: false,
    itemError: {},
    item: undefined,
    isFetchingActions: false,
    actionsError: {},
    itemActions: [],
    statistics: {},
    statisticsError: {},
    isFetchingStatistics: false,
    totalCount: 0,
    isFetchingSeedingInfo: false,
    itemActionsTotalCount: 0,
    isFetchingSeedingApplications: false,
    isFetchingRestrictions: false,
    fertilization: {},
    isFetchingFertilization: false,
    errorFertilization: {},
    seedingInfo: [],
    seedingInfoError: {},
    restrictions: {},
    restrictionsError: {},
    errorPatchParcel: {},
    isPatchingParcel: false,
    centers: [],
    isFetchingCenters: false,
  },
  action = {},
) => {
  const actionContext = get(action, 'meta.context');
  if (actionContext && actionContext !== reducerContext) return state;

  switch (action.type) {
    case types.GET_PARCELS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_PARCELS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_PARCELS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.GET_PARCELS_MAP_ACTIONABLE:
      return {
        ...state,
        isFetchingMapActionable: true,
        mapActionableError: {},
      };
    case types.GET_PARCELS_MAP_ACTIONABLE_SUCCESS:
      return {
        ...state,
        isFetchingMapActionable: false,
        mapActionable: action.payload,
      };
    case types.GET_PARCELS_MAP_ACTIONABLE_FAILURE:
      return {
        ...state,
        isFetchingMapActionable: false,
        mapActionableError: action.payload,
      };
    case types.GET_PARCEL:
      return {
        ...state,
        isFetching: true,
        isFetchingItem: true,
        itemError: {},
      };
    case types.GET_PARCEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingItem: false,
        item: action.payload,
      };
    case types.GET_PARCEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingItem: false,
        itemError: action.payload,
      };
    case types.GET_PARCEL_ACTIONS:
      return {
        ...state,
        isFetchingActions: true,
        actionsError: {},
      };
    case types.GET_PARCEL_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingActions: false,
        itemActions: action.payload,
        itemActionsTotalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_PARCEL_ACTIONS_FAILURE:
      return {
        ...state,
        isFetchingActions: false,
        actionsError: action.payload,
        itemActionsTotalCount: 0,
      };
    case types.GET_PARCELS_STATISTICS:
      return {
        ...state,
        isFetchingStatistics: true,
        statisticsError: {},
      };
    case types.GET_PARCELS_STATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingStatistics: false,
        statistics: action.payload,
      };
    case types.GET_PARCELS_STATISTICS_FAILURE:
      return {
        ...state,
        isFetchingStatistics: false,
        statisticsError: action.payload,
      };
    case types.RESET_PARCELS_STATISTICS:
      return {
        ...state,
        statistics: {},
        isFetchingStatistics: false,
        statisticsError: {},
      };
    case types.GET_PARCELS_FERTILIZATION:
      return {
        ...state,
        isFetchingFertilization: true,
        errorFertilization: {},
      };
    case types.GET_PARCELS_FERTILIZATION_SUCCESS:
      return {
        ...state,
        fertilization: action.payload,
        isFetchingFertilization: false,
      };
    case types.GET_PARCELS_FERTILIZATION_FAILURE:
      return {
        ...state,
        errorFertilization: action.payload,
        isFetchingFertilization: false,
      };
    case types.GET_PARCEL_SEEDINGS:
      return {
        ...state,
        isFetchingSeedingInfo: true,
        seedingInfoError: {},
      };
    case types.GET_PARCEL_SEEDINGS_SUCCESS:
      return {
        ...state,
        isFetchingSeedingInfo: false,
        seedingInfo: action.payload,
      };
    case types.GET_PARCEL_SEEDINGS_FAILURE:
      return {
        ...state,
        isFetchingSeedingInfo: false,
        seedingInfoError: action.payload,
      };

    case types.GET_PARCEL_RESTRICTIONS:
      return {
        ...state,
        isFetchingRestrictions: true,
        restrictionsError: {},
      };
    case types.GET_PARCEL_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        isFetchingRestrictions: false,
        restrictions: action.payload,
      };
    case types.GET_PARCEL_RESTRICTIONS_FAILURE:
      return {
        ...state,
        isFetchingRestrictions: false,
        restrictionsError: action.payload,
      };
    case types.PATCH_PARCEL:
      return {
        ...state,
        isPatchingParcel: true,
        errorPatchParcel: {},
      };
    case types.PATCH_PARCEL_SUCCESS:
      return {
        ...state,
        isPatchingParcel: false,
        item: action.payload,
      };
    case types.PATCH_PARCEL_FAILURE:
      return {
        ...state,
        isPatchingParcel: false,
        errorPatchParcel: action.payload,
      };
    case types.ADD_PARCELS:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };
    case types.RESET_PARCELS:
      return {
        ...state,
        items: [],
        totalCount: 0,
      };
    case types.RESET_PARCELS_MAP_ACTIONABLE:
      return {
        ...state,
        mapActionable: [],
      };
    case types.RESET_PARCEL:
      return {
        ...state,
        item: null,
      };
    case types.RESET_PARCELS_FERTILIZATION:
      return {
        ...state,
        fertilization: {},
        isFetchingFertilization: false,
        errorFertilization: {},
      };

    case types.GET_CENTERS_REQUEST:
      return {
        ...state,
        isFetchingCenters: true,
      };
    case types.GET_CENTERS_SUCCESS:
      return {
        ...state,
        centers: action.payload,
        isFetchingCenters: false,
      };
    case types.GET_CENTERS_ERROR:
      return {
        ...state,
        isFetchingCenters: false,
      };
    case types.RESET_CENTERS:
      return {
        ...state,
        isFetchingCenters: false,
        centers: [],
      };

    default:
      return state;
  }
};
