import { updateLpis } from '../../../../../shared/api/core/lpis/lpis.api';
import { getParcelById, patchParcel } from '../../../../../shared/api/core/parcels/parcels.api';

export const updateParcelNote = (parcelId, parcelNotes) => dispatch =>
  dispatch(patchParcel(parcelId, { notes: parcelNotes }));

export const updateLpisYieldGrade = (parcel, newYieldGrade) => (dispatch) => {
  const lpis = parcel.parcelBlockList[0].lpisBlock;
  dispatch(updateLpis(lpis.id, { userYieldGrade: newYieldGrade })).then(() => {
    dispatch(getParcelById(null, parcel.id));
  });
};
