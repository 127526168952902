import * as types from './monitoring.constants';

export const setMonitoringInterval = (dateFrom, dateTo) => dispatch =>
  dispatch({
    type: types.SET_MONITORING_INTERVAL,
    dateFrom,
    dateTo,
  });

export const resetMonitoringInterval = () => dispatch =>
  dispatch({
    type: types.SET_MONITORING_INTERVAL,
    dateFrom: '',
    dateTo: '',
  });

export const setMonitoringIndex = index => dispatch =>
  dispatch({
    type: types.SET_MONITORING_INDEX,
    index: index.id,
  });
