import React from 'react';

import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfSelector from '../../../../../shared/components/form/CfSelector/CfSelector';
import { createComponent } from '../../../../../shared/components/reduxFormUtils';
import StoresService from '../../services/StoresService/StoresService';

const style = {};

const TransactionTypeSelector = createComponent(CfSelector, ({ classes, ...props }) => ({
  label: <FormattedMessage id="Stores.transaction-type" />,
  labelPropertyFactory: option => <FormattedMessage id={option.name} />,
  items: StoresService.getTransactionTypes('+')
    .concat([null])
    .concat(StoresService.getTransactionTypes('-')),
  ...props,
}));

export default withStyles(style)(TransactionTypeSelector);
