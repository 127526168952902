import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getDevice,
  getIsFetchingDevice,
} from '../../../shared/api/irrigation/devices/devices.selectors';
import { getNewDevice } from '../../selectors/device.selectors';

import { editorSetHoveredId } from '../../../core/map/actions/editor/editor.actions';
import {
  fetchDevice,
} from '../../actions/devices.actions';

import { IRRIGATION_URLS } from '../../irrigation.constants';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import withWidth from '../../../shared/hocs/withWidth';
import IrrigationActivation from '../IrrigationActivation/IrrigationActivation';
import IrrigationDetail from '../IrrigationDetail/IrrigationDetail';

export class IrrigationDeviceRoutes extends Component {
  componentDidMount() {
    const { match } = this.props;
    const deviceId = match.params.deviceId;
    this.props.fetchDevice(deviceId);
    this.props.editorSetHoveredId(null);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const deviceId = match.params.deviceId;

    if (deviceId !== prevProps.match.params.deviceId) {
      this.props.fetchDevice(deviceId);
      this.props.editorSetHoveredId(null);
      this.props.editorSetSelected({ id: deviceId });
    }
  }

  render() {
    const { device, farmId, isMapDisplayed, newDevice } = this.props;

    return (
      <CfErrorPage>
        <Fragment>
          <Switch>
            <Route
              path={`/farm/:farmId/${IRRIGATION_URLS.DEVICE}/:deviceId/${IRRIGATION_URLS.ACTIVATE}`}
              render={routerProps => (
                <IrrigationActivation
                  device={device}
                  farmId={farmId}
                  newDevice={newDevice}
                  {...routerProps}
                />
              )}
            />
            <Route
              path={`/farm/:farmId/${IRRIGATION_URLS.DEVICE}/:deviceId`}
              render={routerProps => (
                <IrrigationDetail farmId={farmId} isMapDisplayed={isMapDisplayed} {...routerProps} />
              )}
            />
          </Switch>
        </Fragment>
      </CfErrorPage>
    );
  }
}

IrrigationDeviceRoutes.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  newDevice: PropTypes.object,
  width: PropTypes.string.isRequired,
  device: PropTypes.object.isRequired,
  fetchDevice: PropTypes.func.isRequired,
  isMapDisplayed: PropTypes.bool.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
};

IrrigationDeviceRoutes.defaultProps = {
  newDevice: {},
};

const mapStateToProps = state => ({
  device: getDevice(state),
  newDevice: getNewDevice(state),
  isFetchingDevice: getIsFetchingDevice(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDevice,
      editorSetHoveredId,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(IrrigationDeviceRoutes));
