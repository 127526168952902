import React, { CSSProperties, FC, ReactNode, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Theme, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getSensorsAdminNodes,
  getSensorsAdminPage,
  getSensorsAdminOrder,
  getSensorsAdminOrderBy,
  getSensorsAdminRowsPerPage,
  getSensorsAdminTextFilter,
  getSensorsAdminIsFetching,
  getSensorsAdminTotalCount,
  getSensorsAdminSelected,
  getSensorsAdminSelectedOnPage,
  getSensorsAdminDisplayInactive,
  getSensorsAdminAdvancedFilter,
  getSensorsAdminFarmFilter,
  getSensorsAdminCountryFilter,
} from '../../selectors/sensors.selectors';

import { getUsersByFarm } from '../../../../impersonation/actions/impersonation.actions';
import { fetchNodes } from '../../actions/sensors.actions';

import { NAMESPACE as namespace } from '../../reducer/sensors.reducer';

import SensorGroup from '../../../../../shared/components/specific/SensorGroup/SensorGroup';
import LastUpdate from '../../../../../shared/components/specific/SensorsLastUpdate/SensorsLastUpdate';
import SensorsSignalInfo from '../../../../../shared/components/specific/SensorsSignalInfo/SensorsSignalInfo';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableRowToolButton from '../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton';
import CfTableRowTools from '../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableCheckbox from '../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import CfTableFooter from '../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import SensorsService from '../../../../../shared/services/Sensors.service';
import { AsyncFn, Thunk } from '../../../../../types';
import SensorStatus from '../../components/SensorStatus/SensorStatus';
import SensorTypeLabel from '../../components/SensorTypeLabel/SensorTypeLabel';
import SensorAdminAssignBase from '../SensorAdminAssignBase/SensorAdminAssignBase';
import SensorAdminAssignFarm from '../SensorAdminAssignFarm/SensorAdminAssignFarm';
import SensorAdminChangeSensorType from '../SensorAdminChangeSensorType/SensorAdminChangeSensorType';

import { AdminFarm, SensorsAdminAdvancedFilter, SensorsAdminNode, SensorsAdminState } from '../../admin.sensors.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const columns = {
  id: getColDesc(true, <FormattedMessage id="common.id" />, { width: '80px' }),
  external_id: getColDesc(true, <FormattedMessage id="SensorsAdmin.external_id" />),
  base_external_id: getColDesc(true, <FormattedMessage id="SensorsAdmin.base_external_id" />),
  lastLocationName: getColDesc(true, <FormattedMessage id="SensorsAdmin.locationName" />),
  farm: getColDesc(false, <FormattedMessage id="common.farm" />),
  device_type: getColDesc(true, <FormattedMessage id="common.type" />),
  status: getColDesc(true, <FormattedMessage id="common.state" />),
  network_type: getColDesc(true, <FormattedMessage id="SensorsAdmin.network" />),
  last_update: getColDesc(true, <FormattedMessage id="common.lastUpdate" />),
  signal: getColDesc(false, <FormattedMessage id="SensorsAdmin.signal" />),
  last_values: getColDesc(false, <FormattedMessage id="common.lastValues" />, { maxWidth: '200px' }),
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: 13,
    marginLeft: -9,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  tableRow: {
    transform: 'scale(1)',
    '&:hover $tableRowTools': {
      visibility: 'visible',
    },
  },
  tableRowTools: {
    visibility: 'hidden',
  },
}));

export interface SensorsAdminTableProps {
  adminNodes: SensorsAdminNode[];
  advancedFilter: SensorsAdminAdvancedFilter;
  count: number;
  country: {
    code: string;
  };
  defaultFarm: AdminFarm;
  displayInactive: boolean;
  farm: AdminFarm;
  fetchNodes: () => void;
  getUsersByFarm: (farmId: string) => void;
  isFetching: boolean;
  langId: string;
  ngImpersActivate: (farm: AdminFarm, userId: string, state: string, sensorId: {sensorId: string}) => void;
  onAssignNodesToFarm: (nodesIds: string[], farm: AdminFarm) => void;
  openNodeEditDialog: (adminNode: SensorsAdminNode) => void;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
  textFilter: string;
}

const SensorsAdminTable: FC<SensorsAdminTableProps> = ({
  adminNodes,
  advancedFilter,
  count,
  country,
  defaultFarm,
  displayInactive,
  farm,
  fetchNodes,
  getUsersByFarm,
  isFetching,
  langId,
  ngImpersActivate,
  onAssignNodesToFarm,
  openNodeEditDialog,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  textFilter,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchNodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNodes();
  }, [page, rowsPerPage, order, orderBy, textFilter, displayInactive, advancedFilter, farm, country, fetchNodes]);

  const impersonateToLocation = (adminNode: SensorsAdminNode) => {
    const state = 'farm.active.sensors.sensor';
    const { farm, lastLocationId } = adminNode;

    (getUsersByFarm as AsyncFn<string>)(farm.id).then(res => {
      const { payload } = res;
      if (Array.isArray(payload) && payload[0]) {
        ngImpersActivate(farm, payload[0].userId, state, { sensorId: lastLocationId });
      }
    });
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={adminNodes}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
        />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {adminNodes.length ? (
        <TableBody>
          {adminNodes.map(adminNode => {
            const meteoType = adminNode.sensors.find(sensor => sensor.feature === 'Rainfall')?.type || '';
            const isMeteo = adminNode.deviceType.startsWith('METEO');
            const isBarani = adminNode.module;

            return (
              <TableRow className={classes.tableRow} key={adminNode.id}>
                <CfTableCheckbox id={adminNode.id} namespace={namespace} selected={selected} />
                <CfTableCell name={'id'}>{adminNode.id}</CfTableCell>
                <CfTableCell name={'external_id'}>{adminNode.externalId}</CfTableCell>
                <CfTableCell name={'base_external_id'}>
                  {isBarani ? (
                    <SensorAdminAssignBase
                      isBarani={isBarani}
                      item={adminNode}
                    />
                  ) : (
                    <Tooltip
                      disableInteractive
                      title={
                        <span>
                          <FormattedMessage id={'SensorAdmin.changeBase.tooltip'} />
                        </span>
                      }
                    >
                      <span className={classes.secondaryText}><FormattedMessage id={'SensorAdmin.changeBase.none'} /></span>
                    </Tooltip>
                  )}
                </CfTableCell>
                <CfTableCell name={'lastLocationName'}>
                  {adminNode.farm.id !== defaultFarm.id ? (
                    <Link onClick={() => impersonateToLocation(adminNode)} to={'#'}>
                      {adminNode.lastLocationName}
                    </Link>
                  ) : (
                    <span>{adminNode.lastLocationName}</span>
                  )}
                </CfTableCell>
                <CfTableCell name={'farm'}>
                  <SensorAdminAssignFarm
                    classes={{ button: classes.button, icon: classes.icon }}
                    defaultFarm={defaultFarm}
                    item={adminNode}
                    key={langId}
                    onAssignNodesToFarm={(farm: AdminFarm) => onAssignNodesToFarm([adminNode.id], farm)}
                    />
                </CfTableCell>
                <CfTableCell name={'device_type'}>
                  <SensorAdminChangeSensorType item={adminNode} key={langId} />
                  {meteoType === 'pronamic' && <SensorTypeLabel sensorType={meteoType} />}
                </CfTableCell>
                <CfTableCell name={'status'}>
                  <SensorStatus sensor={adminNode} testId="sensor-status" />
                </CfTableCell>
                <CfTableCell name={'network_type'}>
                  {adminNode.networkType ? adminNode.networkType : <span className={classes.secondaryText}>{'-'}</span>}
                </CfTableCell>
                <CfTableCell name={'last_update'}>
                  <span className={classes.secondaryText}>
                    <LastUpdate displayIcon={false} lastUpdate={adminNode.lastUpdate} />
                  </span>
                </CfTableCell>
                <CfTableCell name={'signal'}>
                  <SensorsSignalInfo showTooltip={true} signal={adminNode.signal} />
                </CfTableCell>
                <CfTableCell name={'last_values'}>
                  {SensorsService.isCorrectNode(adminNode) && adminNode.sensors.length > 0 ? (
                    <SensorGroup isDetail={false} node={adminNode} />
                  ) : (
                    <span className={classes.secondaryText}>{'-'}</span>
                  )}
                </CfTableCell>
                <CfTableRowTools toolsClass={classes.tableRowTools}>
                  {isMeteo && (
                  <CfTableRowToolButton
                    icon={<EditIcon />}
                    onClick={() => openNodeEditDialog(adminNode)}
                    tooltipMessage={<FormattedMessage id="SensorsAdmin.editNode" />}
                      />
                  )}
                </CfTableRowTools>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: SensorsAdminState) => ({
  adminNodes: getSensorsAdminNodes(state),
  page: getSensorsAdminPage(state),
  order: getSensorsAdminOrder(state),
  orderBy: getSensorsAdminOrderBy(state),
  rowsPerPage: getSensorsAdminRowsPerPage(state),
  textFilter: getSensorsAdminTextFilter(state),
  isFetching: getSensorsAdminIsFetching(state),
  count: getSensorsAdminTotalCount(state),
  selected: getSensorsAdminSelected(state),
  selectedOnPage: getSensorsAdminSelectedOnPage(state),
  displayInactive: getSensorsAdminDisplayInactive(state),
  advancedFilter: getSensorsAdminAdvancedFilter(state),
  farm: getSensorsAdminFarmFilter(state),
  country: getSensorsAdminCountryFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<SensorsAdminState>) =>
  bindActionCreators(
    {
      fetchNodes,
      getUsersByFarm,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SensorsAdminTable);
