import React, { Component } from 'react';

import FilterList from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setAdvancedFilter } from '../../actions/filter.actions';

import CfSwitcherButton from '../../components/common/CfSwitcherButton/CfSwitcherButton';
import withPopover from '../../hocs/withPopover';
import { getAdvancedFilter } from '../../selectors/advancedFilter.selector';

const styles = theme => ({
  popoverPaper: {
    overflow: 'visible',
    marginTop: 7,
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
  filterIcon: {
    color: theme.palette.grey[500],
    marginRight: 6,
  },
  selectedFilter: {
    width: 'max-content',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttons: {
    float: 'right',
    margin: '0px 8px 4px',
  },
});

export class CfFilter extends Component {
  getFilterCount = () => {
    const { advancedFilter } = this.props;
    let count = 0;
    Object.keys(advancedFilter).forEach(key => {
      if (!isEmpty(advancedFilter[`${key}`])) {
        count += 1;
      }
    });
    return count;
  };

  handleFilterAccept = () => {
    this.updateAdvancedFilter(this.props.filterState);
    this.props.handlePopoverClose();
  };

  handleFilterCancel = () => {
    this.updateAdvancedFilter({});
    this.props.setAdvancedFilterState({});
    this.props.handlePopoverClose();
  };

  updateAdvancedFilter = values => {
    this.props.setAdvancedFilter({ ...values }, this.props.namespace);
  };

  render() {
    const { anchorEl, children, classes, handlePopoverClose, handlePopoverOpen, isOpen } = this.props;
    const filterCount = this.getFilterCount();
    const filterValue = filterCount > 0 ? `(${filterCount})` : '';
    return (
      <div>
        <CfSwitcherButton
          className={filterCount > 0 ? classes.selectedFilter : ''}
          data-test="advanced-filter"
          onClick={handlePopoverOpen}
          classes={{
            root: filterCount > 0 ? classes.selectedFilter : '',
          }}
        >
          <FilterList className={classes.filterIcon} style={filterCount > 0 ? { color: '#FFFFFF' } : {}} />
          <span className={classes.switcherButtonLabel}>
            <FormattedMessage id="CfFilter.placeholder" values={{ count: filterValue }} />
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.popoverPaper }}
          onClose={handlePopoverClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {children}
          <div className={classes.buttons}>
            <Button id="cancel" onClick={this.handleFilterCancel}>
              <FormattedMessage id="CfFilter.cancelFilter" />
            </Button>
            <Button color="primary" id="accept" onClick={this.handleFilterAccept}>
              <FormattedMessage id="CfFilter.filter" />
            </Button>
          </div>
        </Popover>
      </div>
    );
  }
}

CfFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.any,
  namespace: PropTypes.string,
  setAdvancedFilter: PropTypes.func.isRequired,
  setAdvancedFilterState: PropTypes.func.isRequired,
  advancedFilter: PropTypes.object,
  filterState: PropTypes.object.isRequired,
};

CfFilter.defaultProps = {
  anchorEl: null,
  namespace: null,
  advancedFilter: {},
};

const mapStateToProps = (state, props) => ({
  advancedFilter: getAdvancedFilter(props.namespace)(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAdvancedFilter,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(compose(withStyles(styles), withPopover)(CfFilter)));
