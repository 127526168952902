import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ParcelsIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M16.71,12.29H27.77V3.55a2.32,2.32,0,0,0-2.32-2.32H16.71Z" />
      <path d="M13.29,12.29V1.23H4.55A2.32,2.32,0,0,0,2.23,3.55v8.74Z" />
      <path d="M16.71,15.71V26.77h8.74a2.32,2.32,0,0,0,2.32-2.32V15.71Z" />
      <path d="M13.29,15.71H2.23v8.74a2.32,2.32,0,0,0,2.32,2.32h8.74Z" />
    </svg>
  </SvgIcon>
);

export default ParcelsIcon;
