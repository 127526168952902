import React from 'react';

import PropTypes from 'prop-types';

import useToggles, { TogglesContext } from './toggles';

const WithToggles = ({ children }) => {
  const [toggles, setToggle, isToggleActive] = useToggles();

  window.setCfToggle = setToggle;
  window.viewCfToggles = () => toggles;
  window.isCfToggleActive = isToggleActive;

  return (
    <TogglesContext.Provider value={{ toggles, setToggle, isToggleActive }}>
      {children}
    </TogglesContext.Provider>
  );
};

WithToggles.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithToggles;
