/**
 * Created by jenc on 3.1.2018
 */
import moment from 'moment';

import { OTHER_ACTION_HARVEST_CODE, OTHER_ACTION_MOWING_CODE, OTHER_ACTION_SOWING_CODE } from '../../others/actionOther.constants';

import Localization from '../../../../shared/services/Localization.service';
import SubtractableAreasService from '../../eph/services/SubtractableAreas.service';

import ParcelSubtractionMapper from './ParcelSubtractionMapper.service';

export default class ActionToMapper {
  static createNewEphActionTo(values) {
    const fixedActionDateMoment = ActionToMapper.getFixedDateTime(values.actionDate);

    return {
      actionTypeCode: 'EPH',
      dateStart: fixedActionDateMoment.toISOString(),
      dateEnd: fixedActionDateMoment.toISOString(),
      note: values.actionNote,
      parcels: ActionToMapper.mapParcels(values.parcels),
      expenses: ActionToMapper.mapEphExpenses(values.expenses, fixedActionDateMoment),
      parcelChemistryEffectiveness: ActionToMapper.mapEffectiveness(values.expenses),
      fertilizationTarget: ActionToMapper.mapFertilizationTarget(values.isCatchCrop, values.isStrawDecay),
      targetCropId: values.targetCrop.legislativeCode,
    };
  }

  static createNewVrfActionTo(values) {
    const fixedActionDateMoment = ActionToMapper.getFixedDateTime(values.actionDate);

    return {
      actionTypeCode: 'EPH',
      dateStart: fixedActionDateMoment?.toISOString(),
      dateEnd: fixedActionDateMoment?.toISOString(),
      note: values.actionNote,
      parcels: ActionToMapper.mapParcels(values.parcels),
      expenses: ActionToMapper.mapVrfExpenses(values.expenses, fixedActionDateMoment),
      fertilizationTarget: ActionToMapper.mapFertilizationTarget(values.isCatchCrop, values.isStrawDecay),
      targetCropId: values.targetCrop.legislativeCode,
    };
  }

  static createNewVrsActionTo(values) {
    const fixedActionDateMoment = ActionToMapper.getFixedDateTime(values.actionDate);

    return {
      actionTypeCode: 'SOWING',
      dateStart: fixedActionDateMoment?.toISOString(),
      dateEnd: fixedActionDateMoment?.toISOString(),
      note: values.actionNote,
      parcels: ActionToMapper.mapParcels(values.parcels),
      expenses: ActionToMapper.mapVrsExpenses(values.expenses),
      fertilizationTarget: null,
      targetCropId: values.targetCrop?.legislativeCode,
      seedApplicationType: 'MAIN_CROP',
    };
  }

  static createNewVrfTo(values) {
    return values.expenses.map(expObj => expObj.variableExpense);
  }

  static createNewOtherActionTo(values, locale) {
    const fixedActionDateMoment = ActionToMapper.getFixedDateTime(values.date);
    const { actionType } = values;

    const isHarvest = actionType === OTHER_ACTION_HARVEST_CODE;
    const isMowing = actionType === OTHER_ACTION_MOWING_CODE;
    const isSowing = actionType === OTHER_ACTION_SOWING_CODE;

    const payload = {
      actionTypeCode: values.actionType,
      dateStart: fixedActionDateMoment.toISOString(),
      dateEnd: fixedActionDateMoment.toISOString(),
      note: values.description,
      parcels: ActionToMapper.mapActionOtherParcels(values.parcels),
    };

    if (isHarvest || isMowing) {
      return {
        ...payload,
        revenues: [{
          amount: Localization.str2numNonFixed(values.totalGain, locale) || 0,
          productId: isMowing ? parseInt(values.productId, 10) : undefined,
        }],
        harvestCrop: isMowing ? values.harvestCrop : undefined,
      };
    }

    if (isSowing) {
      return {
        ...payload,
        expenses: [{
          amount: Localization.str2numNonFixed(values.totalAmount, locale),
          unitId: values.unitId,
          material: {
            '@class': 'MaterialSeedReference',
            id: values.seedId,
          },
        }],
        seedApplicationType: values.isCatchCrop ? 'CATCH_CROP' : 'MAIN_CROP',
      };
    }

    return payload;
  }

  static mapParcels(parcels) {
    return parcels.map(pObj => ({
      parcel: {
        id: pObj.id,
      },
      area: pObj.area,
      restrictedArea: 0,
      restrictions: ActionToMapper.mapParcelRestrictions(pObj),
    }));
  }

  static mapActionOtherParcels(parcels) {
    return parcels.map(pObj => ({
      parcel: {
        id: pObj.id,
      },
      area: pObj.area,
      restrictedArea: 0,
      restrictions: ActionToMapper.mapNewSubtractableAreas(pObj),
    }));
  }

  static mapEphExpenses(expenses, actionDateMoment) {
    return expenses.map(expObj => ({
      amount: expObj.dosage,
      dose: expObj.dose,
      unitId: expObj.dosageUnit.id,
      notes: expObj.note,
      material: ActionToMapper.mapEphMaterial(expObj.material),
      targetOrganismIds: ActionToMapper.mapPests(expObj.pests),
      driftClass: expObj.driftClass,
      dateSoilIncorporation: ActionToMapper.mapIncorporationDay(expObj.incorporationDay, actionDateMoment),
    }));
  }

  static mapVrfExpenses(expenses, actionDateMoment) {
    return expenses.map(expObj => ({
      amount: expObj.variableExpense?.totalDose,
      dose: expObj.variableExpense?.doseHa,
      unitId: expObj.doseUnit?.id,
      notes: expObj.note,
      material: ActionToMapper.mapEphMaterial(expObj.material),
      targetOrganismIds: ActionToMapper.mapPests(expObj.pests),
      driftClass: expObj.driftClass,
      dateSoilIncorporation: ActionToMapper.mapIncorporationDay(expObj.incorporationDay, actionDateMoment),
    }));
  }

  static mapVrsExpenses(expenses) {
    return expenses.map(expObj => ({
      amount: expObj.variableExpense?.totalDose,
      dose: expObj.variableExpense?.doseHa,
      unitId: expObj.doseUnit?.id,
      material: ActionToMapper.mapVrsMaterial(expObj.material),
      targetOrganismIds: null,
    }));
  }

  static mapEffectiveness(expenses) {
    const result = [];
    expenses.forEach(expObj => {
      const mappedEffectiveness =
        expObj.material.materialTypeId === 'CH' && expObj.effectiveness
          ? expObj.effectiveness.map(eff => ({
            parcel: {
              id: eff.parcelId,
            },
            chemistry: {
              id: expObj.material.id,
              '@class': 'MaterialChemistryReference',
            },
            effectiveness: eff.value,
          }))
          : [];
      result.push(...mappedEffectiveness);
    });
    return result;
  }

  // temporary fix (until midnight time is fixed in BE)
  static getFixedDateTime(dateTime) {
    const dateTimeMoment = moment(dateTime);
    return dateTimeMoment.isValid() ? dateTimeMoment.startOf('day').add(dateTimeMoment.utcOffset(), 'm') : null;
  }

  static mapEphMaterial(material) {
    let matType = null;
    switch (material.materialTypeId) {
      case 'CH':
        matType = 'MaterialChemistryReference';
        break;
      case 'FR':
        matType = 'MaterialFertilizerReference';
        break;
      default:
        matType = 'MaterialSeedReference';
    }
    return {
      '@class': matType,
      id: material.id,
    };
  }

  static mapVrsMaterial(material) {
    return {
      '@class': 'MaterialSeedReference',
      id: material.id,
    };
  }

  static mapPests(pests) {
    return pests ? pests.map(pest => pest.legislativeCode) : undefined;
  }

  static mapParcelRestrictions(pObj) {
    const subtractableAreas = SubtractableAreasService.getCheckedSubtractableAreas(pObj.subtractableAreas);
    const parcelRestrictions = [];
    subtractableAreas.forEach(sa => {
      const restriction = {
        restrictionType: sa.restrictionType,
        restrictionValue: sa.restrictionValue,
      };
      parcelRestrictions.push(restriction);
    });
    return parcelRestrictions;
  }

  static mapNewSubtractableAreas(pObj) {
    return ParcelSubtractionMapper.createNewFormParcelSubtractions(pObj);
  }

  static mapFertilizationTarget(isCatchCrop, isStrawDecay) {
    if (isCatchCrop) {
      return 'CATCH_CROP';
    } else if (isStrawDecay) {
      return 'STRAW';
    }
    return 'MAIN_CROP';
  }

  static mapIncorporationDay(incorporationDay, actionDateMoment) {
    switch (incorporationDay) {
      case 'SAME_DAY':
        return actionDateMoment.toISOString();
      case 'NEXT_DAY':
        return actionDateMoment
          .clone()
          .add(1, 'd')
          .toISOString();
      default:
        return null;
    }
  }
}
