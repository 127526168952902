import React, { Component, Fragment } from 'react';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray, reduxForm } from 'redux-form';

import {
  getMaterialIds,
  getParcelsIds,
  getSubtractableAreas,
  getSubtractableAreasTotalArea,
} from '../../../shared/selectors/actions.selectors';

import {
  addSubtractionArea,
  getRestrictedArea,
  resetRestrictedArea,
  updateSubtractableAreas,
  updateParcelRestrictedArea,
} from '../../../shared/actions/actions.actions';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import Localization from '../../../../../shared/services/Localization.service';
import SubtractableAreasList from '../../components/SubtractableAreasList/SubtractableAreasList';
import NewSubtractableAreaForm from '../NewSubtractableAreaForm/NewSubtractableAreaForm';

export const subtractableAreasTypes = [
  {
    id: 'Absolute',
    name: 'absolute',
  },
  {
    id: 'Boundary',
    name: 'boundary',
  },
  {
    id: 'Water',
    name: 'water',
  },
];

const styles = theme => ({
  totalAreaRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px 0px 0px 0px',
    alignItems: 'center',
  },
  formControl: {
    margin: '5px 0px 0px 0px',
  },
  subHeading: {
    color: theme.palette.grey[500],
  },
  textField: {
    width: 50,
  },
  areasLabel: {
    color: theme.palette.grey[500],
    marginTop: 15,
  },
  warningText: {
    fontSize: 12,
    color: theme.palette.secondary.dark,
    paddingBottom: 10,
  },
  noAreas: {
    textAlign: 'center',
    fontSize: 14,
    paddingTop: 10,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  totalAreaValues: {
    display: 'flex',
    alignItems: 'center',
  },
});

class SubtractableAreaDialog extends Component {
  onEnter = () => {
    this.props.initialize({
      subtractableAreas: this.props.parcel.subtractableAreas,
      restrictedArea: this.props.parcel.restrictedArea,
    });
  };

  onSubmit = values => {
    if (values.subtractableAreas && this.getEffectiveArea() > 0) {
      this.props.updateSubtractableAreas(values.subtractableAreas, this.props.index, this.props.formName);
      this.props.updateParcelRestrictedArea(this.props.restrictedArea, this.props.index, this.props.formName);
      this.props.onAccept();
      this.props.reset();
      this.props.destroy();
    }
  };

  onClose = () => {
    this.props.handleClose();
    this.props.destroy();
  };

  onNewTypeAdd = newType => {
    this.props.addSubtractionArea({
      ...newType,
      checked: true,
      newlyAdded: true,
    });
  };

  getEffectiveArea = () => this.props.parcel.actionParcelTotalArea - (this.props.restrictedArea || 0);

  validateSubtractedArea = () =>
    (this.getEffectiveArea() < 0 ? <FormattedMessage id="SubtractableAreaDialog.areaValidation" /> : undefined);

  displayNoMaterialWarning = () =>
    (this.props.materialIds.length === 0 ? <FormattedMessage id="SubtractableAreaDialog.addMaterial" /> : undefined);

  render() {
    const effectiveArea = this.getEffectiveArea();
    const { classes, handleSubmit, intl, opened, parcel, parcelIds, subtractableAreas } = this.props;
    const area = parcel.area;
    const parcelName = parcel ? parcel.localName : '';

    return (
      <CfDialog
        acceptText={this.props.editing ? <FormattedMessage id="common.save" /> : <FormattedMessage id="common.close" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={this.props.editing ? handleSubmit(this.onSubmit) : this.onClose}
        onCancel={this.props.editing ? this.onClose : undefined}
        onClose={this.onClose}
        onEnter={this.onEnter}
        opened={opened}
        title={parcelName}
      >
        <div>
          <FormControl className={classes.totalAreaRow} fullWidth={true}>
            <div className={classes.subHeading}>
              <FormattedMessage id="SubtractableAreaDialog.area-summary" />
            </div>
            <div className={classes.totalAreaValues}>
              <FormControl className={classes.textField} disabled>
                <Input id="subtractedResult" value={Localization.num2str(effectiveArea, intl.locale)} />
              </FormControl>
              {' / '}
              <CfFormattedNumber value={area} />
              {' ha'}
            </div>
          </FormControl>
          {this.props.editing ? (
            <Fragment>
              <FormControl className={classes.formControl} fullWidth={true}>
                <div className={classes.warningText}>{this.validateSubtractedArea()}</div>
                <div className={classes.subHeading}>
                  <FormattedMessage id="SubtractableAreaDialog.add.label" />
                </div>
              </FormControl>
              <NewSubtractableAreaForm
                maxArea={effectiveArea}
                onNewTypeAdd={this.onNewTypeAdd}
                parcelId={parcel.id}
                parcelIds={parcelIds}
                subtractableAreas={subtractableAreas}
                subtractableAreasIds={this.props.subtractableAreasIds}
              />
              <div className={classes.warningText}>{this.displayNoMaterialWarning()}</div>
            </Fragment>
          ) : (
            <div className={classes.areasLabel}>
              <FormattedMessage id="common.subtractableAreas" />:
              {!subtractableAreas.length && (
                <div className={classes.noAreas}>
                  <FormattedMessage id="SubtractableAreaDialog.noSubtractableAreas" />
                </div>
              )}
            </div>
          )}
          <form id="substractable-areas-list">
            <FormControl fullWidth={true}>
              <FieldArray
                component={SubtractableAreasList}
                editing={this.props.editing}
                name="subtractableAreas"
                parcelId={parcel.id}
                refreshTotalArea={sa => this.props.getRestrictedArea(parcel.id, sa, this.props.parcelIds.join(','))}
                resetTotalArea={() => this.props.resetRestrictedArea()}
              />
            </FormControl>
          </form>
        </div>
      </CfDialog>
    );
  }
}

SubtractableAreaDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  opened: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  parcel: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  addSubtractionArea: PropTypes.func.isRequired,
  updateSubtractableAreas: PropTypes.func.isRequired,
  updateParcelRestrictedArea: PropTypes.func.isRequired,
  getRestrictedArea: PropTypes.func.isRequired,
  resetRestrictedArea: PropTypes.func.isRequired,
  subtractableAreas: PropTypes.array,
  subtractableAreasIds: PropTypes.array,
  restrictedArea: PropTypes.number.isRequired,
  materialIds: PropTypes.array.isRequired,
  parcelIds: PropTypes.array,
  editing: PropTypes.bool,
  formName: PropTypes.string.isRequired,
};

SubtractableAreaDialog.defaultProps = {
  classes: {},
  opened: false,
  parcelIds: [],
  subtractableAreas: [],
  restrictedArea: 0,
  editing: false,
  subtractableAreasIds: undefined,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addSubtractionArea,
      updateSubtractableAreas,
      updateParcelRestrictedArea,
      getRestrictedArea,
      resetRestrictedArea,
    },
    dispatch,
  );

const mapStateToProps = (state, props) => ({
  subtractableAreas: getSubtractableAreas('subtractableAreas', state),
  restrictedArea: getSubtractableAreasTotalArea('subtractableAreas', state),
  materialIds: getMaterialIds(props.formName, state),
  parcelIds: getParcelsIds(props.formName, state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'subtractableAreas',
  })(injectIntl(withStyles(styles)(SubtractableAreaDialog))),
);
