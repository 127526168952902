import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfPrimaryTab from '../CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../CfPrimaryTabs/CfPrimaryTabs';

const style = theme => ({
  headerWrapper: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
});

class CfDialogTabs extends Component {
  handleChange = (event, value) => {
    const { activateTab } = this.props;
    activateTab(value);
  };

  render() {
    const { classes, tabValue, tabsInfo } = this.props;
    return (
      <div className={classes.headerWrapper}>
        <CfPrimaryTabs onChange={this.handleChange} tabValue={tabValue}>
          {tabsInfo.map(tab => (
            <CfPrimaryTab
              data-test={tab.id}
              key={tab.id}
              label={<FormattedMessage id={tab.labelId} />}
              style={{ flexGrow: 1 }}
              value={tab.id}
              wrapped={true}
            />
          ))}
        </CfPrimaryTabs>
      </div>
    );
  }
}

CfDialogTabs.propTypes = {
  classes: PropTypes.object,
  tabValue: PropTypes.string.isRequired,
  tabsInfo: PropTypes.array.isRequired,
  activateTab: PropTypes.func.isRequired,
};

CfDialogTabs.defaultProps = {
  classes: {},
  opened: false,
};

export default withStyles(style)(CfDialogTabs);
