import { RSAA } from 'redux-api-middleware';

import { API_METHODS as methods, API_MODULES as modules } from '../../api.constants';
import * as types from './eagriAuth.constants';

export const getEagriAuth = () => ({
  [RSAA]: {
    endpoint: 'eagri-auth?',
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_EAGRI_AUTH, types.GET_EAGRI_AUTH_SUCCESS, types.GET_EAGRI_AUTH_ERROR],
  },
});

export const setEagriAuth = eagriAuth => ({
  [RSAA]: {
    endpoint: 'eagri-auth?',
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(eagriAuth),
    types: [types.SET_EAGRI_AUTH, types.SET_EAGRI_AUTH_SUCCESS, types.SET_EAGRI_AUTH_ERROR],
  },
});
