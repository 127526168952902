import React, { Component } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfAutosuggest from '../../common/CfAutosuggest/CfAutosuggest';

const styles = {
  container: {
    height: '100%',
  },
  suggestionsContainerOpen: {
    position: 'static',
    margin: 0,
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
  },
  suggestionsContainer: {
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    height: 'calc(100% - 52px)',
  },
  suggestionsList: {
    maxHeight: '200px',
  },
  suggestionItem: {
    overflow: 'inherit',
  },
  input: {
    padding: '15px 15px 5px 15px',
  },
  positionEnd: {
    right: '8px',
  },
};

export class ZoneAutosuggest extends Component {
  getSuggestionValue = suggestion => suggestion.name;

  render() {
    const { classes, iconButton, requiredLength } = this.props;
    const { formatMessage } = this.props.intl;
    const placeholder = formatMessage({ id: 'ZoneAutosuggest.enterZoneName' });

    return (
      <CfAutosuggest
        alwaysRenderSuggestions={true}
        autoFocus={this.props.autoFocus}
        clearInputOnAdornmentClick={true}
        clearSuggestions={this.props.onSuggestionClear}
        disableUnderline={true}
        focusInputOnSuggestionClick={false}
        getSuggestions={this.props.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced={true}
        multiSection={false}
        onSuggestionSelected={this.props.onSuggestionSelect}
        placeholder={placeholder}
        requiredLength={requiredLength}
        scrollable={true}
        scrollHeightMax={200}
        suggestions={this.props.suggestions}
        testData="zone-selector"
        adornment={
          iconButton ? (
            <IconButton disabled={true} size="large">
              <SearchIcon />
            </IconButton>
          ) : (
            <div />
          )
        }
        classes={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsContainer: classes.suggestionsContainer,
          suggestionsList: classes.suggestionsList,
          suggestionItem: classes.suggestionItem,
          input: classes.input,
          positionEnd: classes.positionEnd,
        }}
      />
    );
  }
}

ZoneAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  onSuggestionSelect: PropTypes.func.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  requiredLength: PropTypes.number,
  iconButton: PropTypes.bool,
};

ZoneAutosuggest.defaultProps = {
  suggestions: [],
  autoFocus: false,
  requiredLength: 0,
  iconButton: true,
};

export default injectIntl(withStyles(styles)(ZoneAutosuggest));
