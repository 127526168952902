import React, { Component } from 'react';

import AppBar from '@mui/material/AppBar';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { Link } from 'react-router-dom';

import logoFarmaSense from '../../../../assets/img/logo-farmasense-inverted.svg';
import logo from '../../../../assets/img/logo-horizontal-white.svg';
import withWidth from '../../../../shared/hocs/withWidth';
import DesktopMenu from '../DesktopMenu/DesktopMenu';
import MobileMenu from '../MobileMenu/MobileMenu';

const styles = theme => ({
  // hack to display navbar correctly with Product Fruits banner
  '@global': {
    '.productfruits--banners-shown #appbar': {
      top: '48px !important',
    },
  },
  wrapper: {
    height: 60,
    zIndex: 1030,
    // quick fix, but would be nice to explore the reason, because it is not the first case
    // styles are sometimes overridden, it seems to be related to lazy loading
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: 10,
    marginRight: 5,
    padding: 10,
  },
  logoImg: {
    width: (theme.version === 0 ? 130 : 170),
    content: `url(${theme.version === 0 ? logo : logoFarmaSense})`,
  },
  sectionDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    color: 'white',
  },
  sectionMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    color: 'white',
  },
});

class Navbar extends Component {
  renderLogoLink = () => {
    const { classes, ngProps } = this.props;
    return (
      <Link className={classes.logoWrapper} to={`/farm/${ngProps.farm.id}/map`}>
        <img alt="logo" className={classes.logoImg} />
      </Link>
    );
  };

  render() {
    const { classes, ngProps, width } = this.props;

    return (
      <AppBar className={classes.wrapper} color="primary" elevation={2} id="appbar" position="fixed">
        {width === 'xs' || width === 'sm' ? (
          <div className={classes.sectionMobile}>
            {this.renderLogoLink()}
            <MobileMenu ngProps={ngProps} />
          </div>
        ) : (
          <div className={classes.sectionDesktop}>
            {this.renderLogoLink()}
            <DesktopMenu ngProps={ngProps} />
          </div>
        )}
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  ngProps: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(Navbar);
