import * as commonFilterTypes from '../../../../shared/actions/filter.constants';
import * as commonTableTypes from '../../../../shared/actions/table.constants';
import { ROWS_PER_PAGE } from '../../../../shared/constants/rowsPerPage.constants';
import * as sortOrder from '../../../../shared/constants/sortOrder.constants';
import * as types from '../actions/actionList.constants';

export const NAMESPACE = 'actionList';

export default (reducerNamespace = NAMESPACE) => (
  state = {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE,
    order: sortOrder.DESC,
    orderBy: 'dateStart,id',
    selected: [],
    textFilter: '',
    dateFilter: null,
  },
  action,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case commonTableTypes.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
      };
    case commonTableTypes.SET_TABLE_ORDER:
      return {
        ...state,
        order: action.order,
        page: 0,
      };
    case commonTableTypes.SET_TABLE_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
        page: 0,
      };
    case commonTableTypes.SET_TABLE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    case commonFilterTypes.SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: action.textFilter,
        page: 0,
      };
    case types.SET_ACTIONS_LIST_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.dateFilter,
        page: 0,
      };
    default:
      return state;
  }
};
