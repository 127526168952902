import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';

import CfNoData from '../../../../../../shared/components/charts/CfNoData/CfNoData';
import CfStaticMap from '../../../../../../shared/components/specific/CfStaticMap/CfStaticMap';
import ManagementZonesLegend from '../ManagementZonesLegend/ManagementZonesLegend';

const styles = () => ({
  wrapper: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  href: {
    height: '85%',
    width: '100%',
  },
  map: {
    borderRadius: 0,
    '& .ol-viewport': {
      borderRadius: 0,
    },
  },
  mapSelected: {
    borderRadius: 0,
  },
});

export const ManagementZonesMap = props => {
  const { classes, displayedMap, geometries, onMapClick, zones } = props;
  return (
    <div className={classes.wrapper}>
      {zones.length ? (
        <Fragment>
          <a className={classes.href} href="#" onClick={() => onMapClick(geometries === zones ? null : zones)}>
            <CfStaticMap
              displayedMap={displayedMap}
              geometries={zones}
              isSelected={displayedMap && geometries === zones}
              mapId={'management-zones-map'}
              classes={{
                map: classes.map,
                mapSelected: classes.mapSelected,
              }}
            />
          </a>
          <ManagementZonesLegend zones={zones} />
        </Fragment>
      ) : (
        <CfNoData />
      )}
    </div>
  );
};

ManagementZonesMap.propTypes = {
  classes: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
  onMapClick: PropTypes.func.isRequired,
  displayedMap: PropTypes.bool.isRequired,
  geometries: PropTypes.array,
};

ManagementZonesMap.defaultProps = {
  geometries: null,
};

export default compose(withStyles(styles))(ManagementZonesMap);
