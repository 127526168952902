/**
 * Created by ondrejzvara on 30.3.17.
 */


import angular from 'angular';
import Tabs from './Tabs.service';

export default angular.module('app.tabs', [])
    .factory('Tabs', Tabs)
    .name;
