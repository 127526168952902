import { createSelector } from 'reselect';

import { getError as getLocationsErrorApi } from '../../shared/api/iot/locations/locations.selectors';
import {
  getNodes as getNodesApi,
  getIsFetchingNodes as getIsFetchingNodesApi,
  getNodesError as getNodesErrorApi,
  getNodesStatistics as getNodesStatisticsApi,
  getIsFetchingStatistics as getIsFetchingStatisticsApi,
  getNodeTypes,
} from '../../shared/api/iot/nodes/nodes.selectors';

export const getIsFetchingNodes = state => getIsFetchingNodesApi(state);
export const getNodesStatistics = state => getNodesStatisticsApi(state);
export const getIsFetchingStatistics = state => getIsFetchingStatisticsApi(state);
export const getIsModuleAvailable = createSelector(
  getNodesStatisticsApi,
  statistics => statistics.count && statistics.count !== '0',
);

export const getNodes = createSelector(getNodesApi, nodes => nodes);
export const getDeviceTypes = state => getNodeTypes(state);

export const getIsError = createSelector(getNodesErrorApi, getLocationsErrorApi, (nodesError, locationsError) => {
  const errors = [nodesError, locationsError];
  return errors.find(err => err.isError === true) || errors[0];
});
