import React, { Component } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';

import createReduxStore from '../../../reducers/store';
import { CfConfigProvider } from '../../hocs/context/withConfig';
import { CfFarmProvider } from '../../hocs/context/withFarm';
import TableCache from '../../services/TableCache.service';
import WithToggles from '../../toggles/WithToggles';
import WithReduxdevtools from '../../with-reduxdevtools';

/*
 * For now `farm` prop is required, however it is not being always used by childs
 * (e.g. reset & signup), thus for now, it is mocked. However in future this wrapper
 * should be splitted into wrapper for out-of-the-app views (reset, signup, login) &
 * in-the-app views (the rest...)
 * */
export default class CfApp extends Component {
  constructor(props) {
    super(props);

    const {
      apiProps,
      cacheKey,
      config,
      farm: { id: farmId },
      reducer,
    } = props;

    this.isCached = Boolean(cacheKey);

    if (this.isCached && farmId) {
      const cachedState = TableCache.retrieveTableParams(farmId, cacheKey);
      this.cache = cachedState ? { ui: { [cacheKey]: cachedState } } : null;
    }

    const history = createHistory();
    const store = createReduxStore(history, apiProps, reducer, this.isCached && this.cache ? this.cache : null, {
      collapsed: (getState, action, logEntry) => !logEntry.error,
    }, config.environment);

    this.unsubscribe = store.subscribe(() => {
      if (this.isCached && farmId) {
        const { ui } = store.getState();
        if (ui[cacheKey]) {
          TableCache.storeTableParams(farmId, cacheKey, ui[cacheKey]);
        }
      }
    });

    this.state = {
      store,
      history,
    };
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    const { history, store } = this.state;
    const { apiProps, config, farm, reducer, render, ...rest } = this.props;
    return (
      <ReduxProvider store={store}>
        <WithReduxdevtools environment={config.environment}>
          <WithToggles>
            <CfConfigProvider config={config}>
              <CfFarmProvider farm={farm}>
                <ConnectedRouter history={history}>{render({ history, config, farm, ...rest })}</ConnectedRouter>
              </CfFarmProvider>
            </CfConfigProvider>
          </WithToggles>
        </WithReduxdevtools>
      </ReduxProvider>
    );
  }
}

CfApp.propTypes = {
  render: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  reducer: PropTypes.func.isRequired,
  apiProps: PropTypes.object.isRequired,
  // in some places, `farm` prop is not needed, see comment above
  farm: PropTypes.object.isRequired,
  // weather the tree's redux state is cached
  cacheKey: PropTypes.string,
};

CfApp.defaultProps = {
  farm: {
    boundingBox: {},
    code: '',
    crs: 0,
    customer: {},
    farmSettings: [],
    id: '',
    localSrid: 0,
    name: '',
  },
  cacheKey: null,
};
