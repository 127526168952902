import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

const styles = {
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 8,
    marginTop: 5,
  },
  label: {
    fontSize: 14,
  },
};

export const StoreDisplayNull = props => {
  const { classes } = props;
  return (
    <div className={classes.alignRight}>
      <FormControlLabel
        label={<FormattedMessage id="Stores.show-zero-items" />}
        labelPlacement="start"
        classes={{
          label: classes.label,
        }}
        control={
          <Switch
            checked={props.showNullValues}
            color="primary"
            id="display-null-checkbox"
            onChange={e => props.setStoreShowNullValues(e.target.checked)}
          />
        }
      />
    </div>
  );
};

StoreDisplayNull.propTypes = {
  classes: PropTypes.object.isRequired,
  showNullValues: PropTypes.bool.isRequired,
  setStoreShowNullValues: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(StoreDisplayNull);
