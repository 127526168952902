export const COLOR_PRIMARY = {
  light: '#BAE6DB',
  main: '#00a179',
  dark: '#008267'
};

export const COLOR_PRIMARY_V2 = {
  light: '#BAE6DB',
  main: '#57AB46',
  dark: '#4A913B'
};

export const COLOR_SECONDARY = {
  light: '#FFEDB8',
  main: '#EBA607',
  dark: '#E68E27'
};

export const COLOR_ERROR = {
  light: '#FFCDD2',
  main: '#DB4437'
};

export const COLOR_FONT = {
  main: '#333333'
};

//our preferred grey colors to use instead of similar ones defined in default palette
export const COLOR_GREY = {
  100: '#F2F2F2',
  200: '#E0E0E0',
  400: '#ABABAB',
  500: '#848484',
  A100: "#D5D5D5",
};

export const DEFAULT_FONT = {
  fontSize: '0.8125rem',
  fontWeight: 400
};

// app theming - [0] default, [1] FarmaSense theme
export default (version: 0|1) => ({
  version: version,
  spacing: 8,
  palette: {
    primary: version === 0 ? COLOR_PRIMARY : COLOR_PRIMARY_V2,
    secondary: COLOR_SECONDARY,
    error: COLOR_ERROR,
    grey: {
      ...COLOR_GREY
    }
  },
  typography: {
    useNextVariants: true
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: 224,
          '@media (min-width: 600px)': {
            minWidth: 150,
            flexShrink: 1
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          backgroundColor: '#333333',
          margin: '8px 0px !important'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          fontWeight: 400
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 42,
          paddingTop: 9,
          paddingBottom: 9
        },
        dense: {
          minHeight: 36,
          paddingTop: 4,
          paddingBottom: 4
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        dense: {
          minHeight: 36,
          paddingTop: 4,
          paddingBottom: 4
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 10,
          color: COLOR_GREY[500],
          minWidth: 'unset'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        dense: {
          padding: 0,
          fontSize: '0.875rem'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '42px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: COLOR_FONT.main
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 6px',
          lineHeight: 1.25,
          ...DEFAULT_FONT
        },
        sizeSmall: {
          padding: '9px 6px',
        },
        paddingCheckbox: {
          padding: '3px',
          paddingLeft: '3px'
        },
        head: {
          lineHeight: 1.25,
          fontWeight: 500,
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: COLOR_GREY[500],
          p: {
            ...DEFAULT_FONT,
            margin: 0
          },
        },
        caption: {
          ...DEFAULT_FONT
        },
        select: {
          ...DEFAULT_FONT
        },
        selectIcon: {
          // height: 1em;
          // display: inline-block;
          fontSize: '24px'
        },
        actions: {
          ...DEFAULT_FONT
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px',
          margin: 0,
        }
      }
    },
  }
});
