import React, { FC, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getParcelDetailRestrictions, getParcelDetailSeedingInfo } from '../../../../../../shared/api/core/parcels/parcels.selectors';
import { getIsFetchingParcelDetails } from '../../selectors/parcelDetailOverview.selectors';

import { assignZone, assignCenter } from '../../../../shared/actions/parcels.actions';
import { updateParcelNote, updateLpisYieldGrade } from '../../actions/parcelDetailOverview.actions';

import { getParcelById } from '../../../../../../shared/api/core/parcels/parcels.api';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import YesNo from '../../../../../../shared/components/common/YesNo/YesNo';
import Localization from '../../../../../../shared/services/Localization.service';
import { AsyncFn, Thunk } from '../../../../../../types';
import ParcelCenterAssign from '../../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign';
import ParcelZoneAssign from '../../../../shared/containers/ParcelZoneAssign/ParcelZoneAssign';
import ParcelsService from '../../../../shared/services/Parcels.service';
import EditableNote from '../../components/EditableNote/EditableNote';
import HistoricalParcelsPanel from '../../components/HistoricalParcelsPanel/HistoricalParcelsPanel';
import ParcelDetailCropTable from '../../components/ParcelDetailCropTable/ParcelDetailCropTable';
import ParcelDetailRestrictions from '../../components/ParcelDetailRestrictions/ParcelDetailRestrictions';
import ParcelDetailSection from '../../components/ParcelDetailSection/ParcelDetailSection';
import YieldGradeSelector from '../../components/YieldGradeSelector/YieldGradeSelector';

import { Parcel, ParcelsState, Restrictions, SeedingInfo } from '../../../../../../shared/api/core/parcels/parcels.types';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: 10,
    fontSize: 18,
  },
  paper: {
    padding: '10px 15px',
    fontSize: '13px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
  },
  panelItem: {
    marginBottom: 20,
  },
  overviewPanelItem: {
    marginBottom: 10,
  },
}));

export interface ParcelDetailOverviewProps {
  assignCenter: (centerId: string, payload: string[]) => void,
  assignZone: (zoneId: string, parcelId: string) => void,
  countryCode: string;
  displayedMap: boolean,
  getParcelById: (context: string | null, parcelId: string) => void,
  goToParcel: (parcel: Parcel) => void,
  isFetchingParcelDetails: boolean,
  langId: string;
  parcel: Parcel,
  restrictions: Restrictions,
  seedingInfo: SeedingInfo[],
  updateLpisYieldGrade: (parcel: Parcel, chosenYieldGrade: string) => void,
  updateParcelNote: (parcelId: string, newNote: string) => void,
}

export const ParcelDetailOverview: FC<ParcelDetailOverviewProps> = ({
  assignCenter,
  assignZone,
  countryCode,
  displayedMap,
  getParcelById,
  goToParcel,
  isFetchingParcelDetails,
  langId,
  parcel,
  restrictions,
  seedingInfo,
  updateLpisYieldGrade,
  updateParcelNote,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const confirmEditingNote = (newNote: string) => updateParcelNote(parcel.id, newNote);

  const onAssignCenter = (centerId: string, payload: string[]) => {
    const parselId = payload[0];
    (assignCenter as AsyncFn<string, string[]>)(centerId, payload).then(() => {
      getParcelById(null, parselId);
    });
  };

  const renderHeading = (messageId: string) => (
    <Grid data-test="heading" item xs={12}>
      <Typography className={classes.heading} variant="h5">
        <FormattedMessage id={messageId} />
      </Typography>
    </Grid>
  );

  const renderParcelDetailSection = (translationId: string, value: Element | string | unknown) => {
    const gridItem = displayedMap ? 4 : 3;
    return (
      <Grid className={classes.gridItem} item md={gridItem} sm={4} xs={6}>
        <ParcelDetailSection translationId={translationId} valueItem={value} />
      </Grid>
    );
  };

  const renderOverview = () => (
    <Grid className={classes.overviewPanelItem} data-test="parcel-overview" item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {renderParcelDetailSection('common.area', `${Localization.num2str(parcel.area, intl.locale)} ha`)}
          {renderParcelDetailSection('common.crop',
            parcel.currentSeedApplication ? (
              parcel.currentSeedApplication.seed.crop.name
            ) : (
              <FormattedMessage id="common.noCrop" />
            ),
          )}
          {renderParcelDetailSection('common.zone', <ParcelZoneAssign onZoneChange={assignZone} parcel={parcel} />)}
          {renderParcelDetailSection('common.culture', parcel.parcelBlockList[0].lpisBlock.landUse.name)}
          {renderParcelDetailSection('common.variety',
            parcel.currentSeedApplication.seed.variety ? parcel.currentSeedApplication.seed.variety.name : '-',
          )}
          {renderParcelDetailSection('common.center',
            <ParcelCenterAssign
              onCenterChange={(centerId) => onAssignCenter(centerId, [parcel.id])}
              parcel={parcel}
            />,
          )}
          {renderParcelDetailSection('common.validity',
            <span>
              <FormattedDate value={parcel.validFrom} />
              {' \u2013 '}
              {parcel.validTo ? <FormattedDate value={parcel.validTo} /> : ''}
            </span>,
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const renderCzechSpecifics = () => {
    const lpisBlock = parcel.parcelBlockList[0].lpisBlock;
    return (
      <Grid className={classes.panelItem} data-test="czech-specific-overview" item xs={12}>
        <div>
          <Paper className={classes.paper}>
            <Grid container>
              {renderParcelDetailSection('term.NSA', <YesNo value={parcel.nitrateVulnerable} />)}
              {renderParcelDetailSection(
                'common.yieldGrade',
                parcel.nitrateVulnerable
                  ? renderEditableYieldGrade(lpisBlock.yieldGrade, lpisBlock.userYieldGrade)
                  : '-',
              )}
              {renderParcelDetailSection('common.applicationZone', lpisBlock.applicationZone || '-')}
              {renderParcelDetailSection(
                'common.altitude',
                `${Localization.num2str(lpisBlock.altitude, intl.locale)} m`,
              )}
              {renderParcelDetailSection(
                'common.slope',
                `${Localization.num2str(lpisBlock.slope, intl.locale)}\xB0`,
              )}
              {renderParcelDetailSection(
                'ParcelDetailOverview.restrictions',
                restrictions.items && restrictions.items.length > 0 ? (
                  <ParcelDetailRestrictions restrictions={restrictions} />
                ) : (
                  '-'
                ),
              )}
            </Grid>
          </Paper>
        </div>
      </Grid>
    );
  };

  const renderCropsTable = () => (
    <Grid className={classes.panelItem} data-test="crops-table" item xs={12}>
      <ParcelDetailCropTable langId={langId} seedingInfo={seedingInfo} />
    </Grid>
  );

  const renderHistoricalParcels = () => (
    <Grid className={classes.panelItem} data-test="historical-parcels" item xs={12}>
      <HistoricalParcelsPanel goToParcel={goToParcel} historicalParcels={parcel.predecessors} />
    </Grid>
  );

  const renderNote = () => (
    <Grid className={classes.panelItem} data-test="note" item lg={6} md={9} xs={12}>
      <EditableNote
        defaultValue={intl.formatMessage({ id: 'ParcelDetailOverview.noNote' })}
        initialValue={parcel.notes ? parcel.notes : ''}
        inputTestId="parcel-note"
        onConfirmEditing={(newValue: string) => confirmEditingNote(newValue)}
        />
    </Grid>
  );

  const renderEditableYieldGrade = (yieldGrade: string | null, userYieldGrade: string | null) => {
    const isHistorical = ParcelsService.isParcelHistorical(parcel);
    return (
      <YieldGradeSelector
        isHistorical={isHistorical}
        updateParcelYieldGrade={(chosenYieldGrade: string) => updateLpisYieldGrade(parcel, chosenYieldGrade)}
        userYieldGrade={userYieldGrade}
        yieldGrade={yieldGrade}
      />
    );
  };

  const czechFarm = countryCode === 'CZ';
  return (
    <Fragment>
      {isFetchingParcelDetails ? (
        <CfLoader />
      ) : (
        <Fragment>
          {parcel.currentSeedApplication && (
          <Grid container>
            {renderHeading('ParcelDetailOverview.basicOverview')}
            {renderOverview()}
            {czechFarm && renderCzechSpecifics()}
            {renderHeading('ParcelDetailOverview.crops')}
            {renderCropsTable()}
            {renderHeading('ParcelDetailOverview.previousHistoricalParcels')}
            {renderHistoricalParcels()}
            {renderHeading('common.note')}
            {renderNote()}
          </Grid>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  seedingInfo: getParcelDetailSeedingInfo(state),
  isFetchingParcelDetails: getIsFetchingParcelDetails(state),
  restrictions: getParcelDetailRestrictions(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      updateParcelNote,
      assignZone,
      assignCenter,
      getParcelById,
      updateLpisYieldGrade,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelDetailOverview);
