import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import countBy from 'lodash/countBy';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  description: {
    fontSize: 14,
    color: theme.palette.grey[500],
  },
});

const isAll = (sensors, feature) => sensors.every((val, i, arr) => val === arr[0] && val === feature);
const countFeature = (sensors, feature) => countBy(sensors)[feature];

const getDescription = sensors => {
  if (isAll(sensors, 'Temperature') && sensors.length > 1) {
    return <FormattedMessage id="SensorsAdmin.sensorCount" values={{ count: sensors.length }} />;
  }
  if (countFeature(sensors, 'Valve') > 1 && sensors.length > 1) {
    return <FormattedMessage id="SensorsAdmin.valveCount" values={{ count: countFeature(sensors, 'Valve') }} />;
  }
  // For BARANI_METEO removing duplicate of Rainfall sensors
  if (countFeature(sensors, 'Rainfall') > 1) {
    sensors = sensors.filter((sensor, id) => sensors.indexOf(sensor) === id);
  }
  return sensors.map((sensor, index) => (
    <Fragment key={sensor}>
      <FormattedMessage id={`SensorsAdmin.${sensor}`} />
      {index < sensors.length - 1 && ', '}
    </Fragment>
  ));
};

const SensorTypeMenuItem = props => {
  const { classes, option } = props;
  return (
    <div key={option.name}>
      <div id="node-type-name">{option.name}</div>
      <div className={classes.description} id="node-type-sensors">
        {getDescription(option.sensors)}
      </div>
    </div>
  );
};

SensorTypeMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
};

export default withStyles(styles)(SensorTypeMenuItem);
