import React, { useEffect, FC, ReactNode } from 'react';

import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Intercom from '../../../../shared/services/Intercom.service';

enum NOTIFICATION_TYPES {
  ERROR= 'error',
  SUCCESS = 'success'
}

interface Props {
    buttonCopy: ReactNode;
    description: ReactNode;
    handleLogout: () => void;
    heading: ReactNode;
    type?: 'error' | 'success';
}
const useStyles = makeStyles({
  heading: {
    fontWeight: 500,
    marginBottom: 8,
  },
  button: {
    width: '100%',
    marginTop: 36,
  },
});

const EAgriImportNotification: FC<Props> = ({
  buttonCopy,
  description,
  heading,
  type = NOTIFICATION_TYPES.SUCCESS,
  handleLogout,
}) => {
  const classes = useStyles();

  useEffect(() => {
    handleLogout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => Intercom.show();

  return (
    <div>
      <Typography classes={{ root: classes.heading }} variant="h5">{heading}</Typography>
      <Typography component="p" variant="subtitle1">{description}</Typography>
      {type === NOTIFICATION_TYPES.ERROR &&
      <Button
        className={classes.button}
        color="primary"
        onClick={handleClick}
        variant="contained">
          {buttonCopy}
      </Button>
      }
    </div>
  );
};

export default EAgriImportNotification;
