import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './areas.constants';

import { AreaTo, AreaProgram, DeviceTo } from './area.types';

type Params = 'page' | 'per-page' | 'sort-col' | 'sort-dir'

export const getAreasListApi = (params: Record<Params, string>) => ({
  [RSAA]: {
    endpoint: `areas?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [types.GET_AREAS_REQUEST, types.GET_AREAS_SUCCESS, types.GET_AREAS_ERROR],
  },
});

export const createAreaApi = (payload: AreaTo) => ({
  [RSAA]: {
    endpoint: 'areas?',
    method: methods.POST,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [types.CREATE_AREA_REQUEST, types.CREATE_AREA_SUCCESS, types.CREATE_AREA_ERROR],
  },
});

export const addDevicesToAreaApi = (areaId: string, payload: DeviceTo[]) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/devices?`,
    method: methods.POST,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [types.EDIT_AREA_REQUEST, types.EDIT_AREA_SUCCESS, types.EDIT_AREA_ERROR],
  },
});

export const deleteDevicesFromAreaApi = (areaId: string, deviceIds: string[]) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/devices/${deviceIds.join(',')}?`,
    method: methods.DELETE,
    module: modules.IRRIGATION,
    types: [types.DELETE_AREA_DEVICE_REQUEST, types.DELETE_AREA_DEVICE_SUCCESS, types.DELETE_AREA_DEVICE_ERROR],
  },
});

export const getAreaApi = (areaId: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}?`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [types.GET_AREA_REQUEST, types.GET_AREA_SUCCESS, types.GET_AREA_ERROR],
  },
});

export const getAreaProgramsApi = (areaId: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/programs?`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [types.GET_AREA_PROGRAMS_REQUEST, types.GET_AREA_PROGRAMS_SUCCESS, types.GET_AREA_PROGRAMS_ERROR],
  },
});

export const deleteAreaApi = (areaId: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}?`,
    method: methods.DELETE,
    module: modules.IRRIGATION,
    types: [types.DELETE_AREA_REQUEST, types.DELETE_AREA_SUCCESS, types.DELETE_AREA_ERROR],
  },
});

export const getAreaProgramApi = (areaId: string, programId: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/programs/${programId}?`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [types.GET_AREA_PROGRAM_REQUEST, types.GET_AREA_PROGRAM_SUCCESS, types.GET_AREA_PROGRAM_ERROR],
  },
});

export const createAreaProgramApi = (areaId: string, payload: AreaProgram) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/programs?`,
    method: methods.POST,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [types.CREATE_AREA_PROGRAM_REQUEST, types.CREATE_AREA_PROGRAM_SUCCESS, types.CREATE_AREA_PROGRAM_ERROR],
  },
});

export const updateAreaProgramApi = (areaId: string, programId: string, payload: AreaProgram) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/programs/${programId}?`,
    method: methods.PUT,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [types.UPDATE_AREA_PROGRAM_REQUEST, types.UPDATE_AREA_PROGRAM_SUCCESS, types.UPDATE_AREA_PROGRAM_ERROR],
  },
});

export const deleteAreaProgramApi = (areaId: string, programId: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}/programs/${programId}?`,
    method: methods.DELETE,
    module: modules.IRRIGATION,
    types: [types.DELETE_AREA_PROGRAM_REQUEST, types.DELETE_AREA_PROGRAM_SUCCESS, types.DELETE_AREA_PROGRAM_ERROR],
  },
});

export const updateAreaName = (areaId: string, name: string) => ({
  [RSAA]: {
    endpoint: `areas/${areaId}?`,
    method: methods.PATCH,
    module: modules.IRRIGATION,
    body: JSON.stringify({ name }),
    types: [types.EDIT_AREA_NAME_REQUEST, types.EDIT_AREA_NAME_SUCCESS, types.EDIT_AREA_NAME_ERROR],
  },
});

export const resetApiError = () => ({
  type: types.RESET_API_ERROR,
});
