import { getGroupsOrder, getGroupsOrderBy } from '../selectors/groups.selectors';

import { setLocationsFilter } from './locations.actions';

import {
  resetGroups as resetGroupsApi,
  getGroups as getGroupsApi,
  updateGroup as updateGroupApi,
  createGroup as createGroupApi,
  deleteGroup as deleteGroupApi,
} from '../../shared/api/iot/groups/groups.api';

export const fetchGroups = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    order: getGroupsOrder(state),
    orderBy: getGroupsOrderBy(state),
  };
  dispatch(getGroupsApi(params));
};

export const resetGroups = () => dispatch => dispatch(resetGroupsApi());

export const updateGroup = group => dispatch => dispatch(updateGroupApi(group));

export const createGroup = group => dispatch => dispatch(createGroupApi(group));

export const deleteGroup = group => dispatch => dispatch(deleteGroupApi(group));

export const filterGroup = group => dispatch => dispatch(setLocationsFilter('groups', [group]));
