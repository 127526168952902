import React from 'react';

import useWidth from '../hooks/useWidth';

const withWidth = () => (WrappedComponent) => (props) => {
  const width = useWidth();
  return <WrappedComponent width={width} {...props} />;
};

export default withWidth;
