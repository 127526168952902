import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WeatherWeekIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M18.52,4.83h-1V3H15.7V4.83H8.3V3H6.48V4.83h-1a1.79,1.79,0,0,0-1.84,1.8V19.24A1.79,1.79,0,0,0,5.48,21h13a1.79,1.79,0,0,0,1.84-1.8V6.63A1.79,1.79,0,0,0,18.52,4.83Zm0,14.36h-13v-10h13Z" />
      <polygon points="11.68 17.99 13.27 17.99 13.27 10.49 13.11 10.49 9.92 11.61 9.92 13.02 11.68 12.47 11.68 17.99" />
    </svg>
  </SvgIcon>
);

export default WeatherWeekIcon;
