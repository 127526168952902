import React, { Fragment, Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getStatistics, isFetchingStatistics } from '../../selectors/parcelList.selectors';

import { fetchParcelsStatistics, fetchZonesStatistics, resetStatistics } from '../../actions/parcelList.actions';

import CfLoader from '../../../../../shared/components/common/CfLoader/CfLoader';
import CfPanelTab from '../../../../../shared/components/common/CfPanelTab/CfPanelTab';
import CfPanelTabs from '../../../../../shared/components/common/CfPanelTabs/CfPanelTabs';
import NameEditDialog from '../../../../../shared/components/common/NameEditDialog/NameEditDialog';
import { StatsHeader, StatsFooter, StatsBody, StatsNewZone } from '../../components/ParcelZoneTable/ParcelZoneTable';

const styles = theme => ({
  wrapper: {
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  createZone: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 15px',
  },
  createZoneForm: {
    padding: '15px 15px',
  },
  container: {
    maxHeight: 400,
  },
});

const PARCEL_STATS_TAB = 0;
const ZONE_STATS_TAB = 1;

export class ParcelZoneStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: false,
      editing: false,
      zoneToEdit: {},
    };
  }

  componentDidMount() {
    this.setTabIndex(PARCEL_STATS_TAB);
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabIndex } = this.state;
    const { shouldReloadData } = this.props;

    if (tabIndex !== prevState.tabIndex && !isNaN(parseInt(tabIndex, 0))) {
      this.fetchStatistics(tabIndex);
    }

    if (shouldReloadData !== prevProps.shouldReloadData && tabIndex === ZONE_STATS_TAB) {
      this.fetchStatistics(tabIndex);
    }
  }

  componentWillUnmount() {
    this.props.resetStatistics();
  }

  onNewZoneCreate = () => {
    this.setState({
      addingNewZone: true,
    });
  };

  onCreateZoneDismiss = () => {
    this.setState({
      addingNewZone: false,
    });
  };

  onCreateZoneConfirm = zone =>
    this.props
      .onCreateZone(zone)
      .then(() => {
        this.fetchStatistics(ZONE_STATS_TAB);
      })
      .finally(() => {
        this.setState({
          addingNewZone: false,
        });
      });

  onEditZone = zone => {
    this.setState({
      editing: true,
      zoneToEdit: zone,
    });
  };

  onEditCancel = () => {
    this.setState({
      editing: false,
      zoneToEdit: {},
    });
  };

  onUpdateZone = zone => {
    this.onEditCancel();
    this.props.onUpdateZone(zone);
  };

  setTabIndex = tabIndex => {
    this.setState({ tabIndex });
  };

  fetchStatistics(tabIndex) {
    if (tabIndex === PARCEL_STATS_TAB) {
      this.props.fetchParcelsStatistics();
    } else if (tabIndex === ZONE_STATS_TAB) {
      this.props.fetchZonesStatistics();
    }
  }

  render() {
    const {
      classes,
      isFetching,
      onDeleteZone,
      stats: { parcels, zones },
    } = this.props;
    const { addingNewZone, editing, tabIndex, zoneToEdit } = this.state;
    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper>
              <CfPanelTabs onChange={this.setTabIndex} value={tabIndex}>
                <CfPanelTab data-test="parcel-list-crops-tab" label={<FormattedMessage id="common.crops" />} />
                <CfPanelTab data-test="parcel-list-zones-tab" label={<FormattedMessage id="common.zones" />} />
              </CfPanelTabs>
              {isFetching ? (
                <CfLoader classes={{ wrapper: classes.wrapper }} />
              ) : (
                <Fragment>
                  {tabIndex === PARCEL_STATS_TAB && parcels && (
                    <Table data-test="crop-list">
                      <StatsHeader />
                      <TableBody>
                        {parcels.items.map(item => (
                          <StatsBody item={item} key={item.id} />
                        ))}
                        {parcels.totalArea !== undefined && <StatsFooter stats={parcels} />}
                      </TableBody>
                    </Table>
                  )}

                  {tabIndex === ZONE_STATS_TAB && (
                    <Fragment>
                      <Table stickyHeader>
                        <StatsHeader />
                      </Table>
                      <Scrollbars autoHeight={true} autoHeightMax={400}>
                        <Table data-test="zone-list">
                          <TableBody>
                            {zones.items.map(item => (
                              <StatsBody
                                item={item}
                                key={item.id}
                                onDeleteZone={onDeleteZone}
                                onEditZone={this.onEditZone}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </Scrollbars>
                      <Table>
                        <TableBody>{zones.totalArea !== undefined && <StatsFooter stats={zones} />}</TableBody>
                      </Table>
                      <div className={classes.createZone}>
                        {!addingNewZone && (
                          <span className={classes.button}>
                            <Button
                              className={classes.label}
                              data-test="add-zone"
                              disabled={addingNewZone}
                              endIcon={<AddIcon />}
                              onClick={this.onNewZoneCreate}
                            >
                              <FormattedMessage id="ParcelZoneStatistics.create-zone" />
                            </Button>
                          </span>
                        )}
                        {addingNewZone && (
                          <div className={classes.createZoneForm}>
                            <StatsNewZone
                              onCreateZoneConfirm={this.onCreateZoneConfirm}
                              onCreateZoneDismiss={this.onCreateZoneDismiss}
                            />
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
        {editing && (
          <NameEditDialog
            item={zoneToEdit}
            onAccept={this.onUpdateZone}
            onClose={this.onEditCancel}
            opened={editing}
            title={<FormattedMessage id="ParcelZone.edit" />}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingStatistics(state),
  stats: getStatistics(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchParcelsStatistics,
      fetchZonesStatistics,
      resetStatistics,
    },
    dispatch,
  );

ParcelZoneStatistics.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onUpdateZone: PropTypes.func.isRequired,
  onDeleteZone: PropTypes.func.isRequired,
  onCreateZone: PropTypes.func.isRequired,
  fetchParcelsStatistics: PropTypes.func.isRequired,
  fetchZonesStatistics: PropTypes.func.isRequired,
  resetStatistics: PropTypes.func.isRequired,
  shouldReloadData: PropTypes.bool.isRequired,
};

ParcelZoneStatistics.defaultProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ParcelZoneStatistics);
