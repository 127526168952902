import angular from 'angular';

import NavbarController from './navbar.controller';

const navbarComponent = {
  bindings: {
    farm: '<',
    switchFarm: '&',
    impersEnable: '&',
  },
  controller: NavbarController,
};

export default angular.module('app.core.navbar', []).component('navbarComponent', navbarComponent).name;
