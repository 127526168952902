import ErrorService from '../../../services/Error.service';

export const getMonitoringData = state => state.api.monitoring.items;
export const getMonitoringDataIsFetching = state => state.api.monitoring.isFetching;
export const getMonitoringDataError = state => ErrorService.getResErrorDto(state.api.monitoring.error);

export const getMonitoringCropIntervals = state => state.api.monitoring.cropIntervals;
export const getMonitoringCropIntervalsIsFetching = state => state.api.monitoring.cropIntervalsIsFetching;
export const getMonitoringCropIntervalsError = state =>
  ErrorService.getResErrorDto(state.api.monitoring.cropIntervalsError);
