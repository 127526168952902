import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    backgroundColor: theme.palette.grey[100],
    padding: '4px 12px',
  },
  content: {
    flexGrow: 100,
    padding: 6,
  },
  removeButton: {
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
});

/**
 * Component to use typically in forms for displaying information about items, that user select and add to form.
 * It is wrapper allowing also remove this item from list.
 * Content can be made with use of `SelectionItemHeading` and `SelectionItemColumn`.
 * @version 1.0.0
 */
function SelectionItem(props) {
  const { classes } = props;
  return (
    <Paper className={classes.paper} data-test="item-root">
      <div className={classes.content}>{props.children}</div>
      <IconButton
        aria-label="Remove item"
        className={classes.removeButton}
        disabled={!props.editing}
        onClick={props.onRemoveItem}
        size="large">
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}

SelectionItem.propTypes = {
  /** Content of item. */
  children: PropTypes.object,
  /** Style classes. */
  classes: PropTypes.object,
  /** Action to be called when item is removed from list. */
  onRemoveItem: PropTypes.func,
  /** If true, removing is possible, otherwise disabled. */
  editing: PropTypes.bool,
};

SelectionItem.defaultProps = {
  children: {},
  classes: {},
  onRemoveItem: () => {},
  editing: false,
};

export default withStyles(styles)(SelectionItem);
