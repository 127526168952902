import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionValidationDetailsDialog from '../ActionValidationDetailsDialog/ActionValidationDetailsDialog';

const styles = theme => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    flex: '1',
    display: 'flex',
  },
  actions: {
    padding: '16px',
  },
  header: {
    padding: '16px',
    display: 'inline-flex',
    color: '#737373',
  },
});

class ActionValidationInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
    };
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpened: true,
    });
  };

  handleDialogBack = () => {
    this.setState({
      dialogOpened: false,
    });
  };

  render() {
    const { classes, validationDetails } = this.props;
    return validationDetails ? (
      <Grid item md={8} sm={10} xs={12}>
        <div id="validation-info">
          <Card className={classes.card}>
            <div className={classes.header}>
              <Icon>warning</Icon>
            </div>
            <CardContent className={classes.content}>
              <Typography component="p">
                <FormattedMessage id="ActionValidationInfo.title" />
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button color="primary" onClick={this.handleDialogOpen} size="small">
                <FormattedMessage id="common.show" />
              </Button>
            </CardActions>
          </Card>
          <ActionValidationDetailsDialog
            onBack={this.handleDialogBack}
            opened={this.state.dialogOpened}
            validationErrors={validationDetails.errors}
          />
        </div>
      </Grid>
    ) : null;
  }
}

ActionValidationInfo.propTypes = {
  classes: PropTypes.object,
  validationDetails: PropTypes.object,
};

ActionValidationInfo.defaultProps = {
  classes: {},
  validationDetails: {},
};

export default withStyles(styles)(ActionValidationInfo);
