import { useRef, useEffect } from 'react';

const usePrevious = (value) => {
  // https://usehooks.com/usePrevious/
  // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
