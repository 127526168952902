import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root: {
    display: 'flex',
    background: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    flexWrap: 'nowrap',
  },
  snackbar: {
    bottom: 0,
  },
});

function NoContentReportMessage(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    props.clearNoContentReports();
  };

  const { classes, intl: { formatMessage }, noContentReports } = props;
  const noDataMessage = formatMessage({ id: 'Report.noDataMessage' });
  const noDataReportsMessages = noContentReports.map(id =>
    formatMessage({ id: `Report.${id}` })).join(', ');
  return (
    <Snackbar
      autoHideDuration={10000}
      className={classes.snackbar}
      message={`${noDataMessage} ${noDataReportsMessages}`}
      onClose={handleClose}
      open={open}
      action={
        <IconButton aria-label="close" color="inherit" onClick={handleClose} size="small">
          <ClearIcon />
        </IconButton>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
    />
  );
}

NoContentReportMessage.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object.isRequired,
  noContentReports: PropTypes.array,
  clearNoContentReports: PropTypes.func.isRequired,
};

NoContentReportMessage.defaultProps = {
  classes: {},
  noContentReports: [],
};

export default injectIntl(withStyles(styles)(NoContentReportMessage));
