import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfChartTooltipLabel from '../../../../../../shared/components/charts/CfChartTooltipLabel/CfChartTooltipLabel';
import CfChartTooltipLabelWrapper from '../../../../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipWrapper from '../../../../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import CfFormattedNumber from '../../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';

const style = () => ({
  tooltipLabelWrapper: {
    borderRadius: 3,
  },
});

const CropQualityTooltip = props => {
  const { active, classes, payload } = props;
  if (active && payload && payload[0]) {
    return (
      <CfChartTooltipWrapper>
        <CfChartTooltipLabelWrapper classes={{ tooltipLabelWrapper: classes.tooltipLabelWrapper }}>
          <CfChartTooltipLabel>
            <span style={{ color: `#${payload[0].payload.zoneColor}` }}>
              <CfFormattedNumber maximumFractionDigits={1} minimumFractionDigits={1} value={payload[0].value} /> %
            </span>
          </CfChartTooltipLabel>
        </CfChartTooltipLabelWrapper>
      </CfChartTooltipWrapper>
    );
  }
  return null;
};

CropQualityTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
};

CropQualityTooltip.defaultProps = {
  payload: null,
  active: false,
};

export default withStyles(style)(CropQualityTooltip);
