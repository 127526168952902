import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  button: {
    color: 'white',
  },
};

const AdminFabButton = props => {
  const { classes, enabled, handleDialogOpen } = props;
  return (
    <div>
      <Fab
        aria-label="add sensor"
        className={classes.button}
        color="secondary"
        disabled={!enabled}
        onClick={() => handleDialogOpen(true)}
        size="medium"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

AdminFabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
};

AdminFabButton.defaultProps = {
  enabled: false,
};

export default withStyles(styles)(AdminFabButton);
