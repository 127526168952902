/**
 * Created by ondrejzvara on 24.3.17.
 */

import angular from 'angular';
import shared from './shared/shared.module';
import selectors from './selectors/selectors.module';

export default angular.module('app.directives', [
    shared,
    selectors
])
    .name;
