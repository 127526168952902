/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from 'angular';
import tpl from './por.public.html';
import PorPublicController from './por.public.controller';

export default angular
  .module('app.components.farm.classifiers.public.pors.por', [])
  .controller('PorPublicController', PorPublicController)
  .config(config).name;

/* @ngInject */
function config($stateProvider) {
  $stateProvider.state('farm.active.classifiers.public.pors.por', {
    url: '/{porId}',
    views: {
      'farm@farm.active': {
        controller: 'PorPublicController as PorCtrl',
        template: tpl
      }
    },
    resolve: {
      porId($stateParams) {
        return $stateParams.porId;
      }
    }
  });
}
