export const GET_SUBTRACTABLE_AREAS = 'GET_SUBTRACTABLE_AREAS';
export const GET_SUBTRACTABLE_AREAS_SUCCESS = 'GET_SUBTRACTABLE_AREAS_SUCCESS';
export const GET_SUBTRACTABLE_AREAS_FAILURE = 'GET_SUBTRACTABLE_AREAS_FAILURE';
export const GET_MATERIALS_SUBTRACTABLE_AREAS = 'GET_MATERIAL_SUBTRACTABLE_AREAS';
export const GET_MATERIALS_SUBTRACTABLE_AREAS_SUCCESS = 'GET_MATERIAL_SUBTRACTABLE_AREAS_SUCCESS';
export const GET_MATERIALS_SUBTRACTABLE_AREAS_FAILURE = 'GET_MATERIAL_SUBTRACTABLE_AREAS_FAILURE';
export const RESET_SUBTRACTABLE_AREAS = 'RESET_SUBTRACTABLE_AREAS';
export const GET_SUBTRACTABLE_AREA = 'GET_SUBTRACTABLE_AREA';
export const GET_SUBTRACTABLE_AREA_SUCCESS = 'GET_SUBTRACTABLE_AREA_SUCCESS';
export const GET_SUBTRACTABLE_AREA_FAILURE = 'GET_SUBTRACTABLE_AREA_FAILURE';
export const GET_TOTAL_SUBTRACTABLE_AREA = 'GET_TOTAL_SUBTRACTABLE_AREA';
export const GET_TOTAL_SUBTRACTABLE_AREA_SUCCESS = 'GET_TOTAL_SUBTRACTABLE_AREA_SUCCESS';
export const GET_TOTAL_SUBTRACTABLE_AREA_FAILURE = 'GET_TOTAL_SUBTRACTABLE_AREA_FAILURE';
export const RESET_TOTAL_SUBTRACTABLE_AREA = 'RESET_TOTAL_SUBTRACTABLE_AREA';
export const CREATE_PARCEL_SUBTRACTION = 'CREATE_PARCEL_SUBTRACTION';
export const CREATE_PARCEL_SUBTRACTION_SUCCESS = 'CREATE_PARCEL_SUBTRACTION_SUCCESS';
export const CREATE_PARCEL_SUBTRACTION_FAILURE = 'CREATE_PARCEL_SUBTRACTION_FAILURE';
export const UPDATE_PARCELS_SUBTRACTIONS = 'UPDATE_PARCELS_SUBTRACTIONS';
export const UPDATE_PARCELS_SUBTRACTIONS_SUCCESS = 'UPDATE_PARCELS_SUBTRACTIONS_SUCCESS';
export const UPDATE_PARCELS_SUBTRACTIONS_FAILURE = 'UPDATE_PARCELS_SUBTRACTIONS_FAILURE';
