import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfAutosuggest from '../../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

class PestSelector extends Component {
  getSuggestionValue = suggestion => suggestion.name; // suggestion.organism.name;

  render() {
    const { formatMessage } = this.props.intl;
    const pestPlaceholder = formatMessage({ id: 'ChemistryDialog.pestSelectorPlaceholder' });

    return (
      <CfAutosuggest
        autoFocus={this.props.autoFocus}
        focusInputOnSuggestionClick={false}
        getSuggestions={this.props.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        multiSection={true}
        onSuggestionSelected={this.props.onSuggestionSelected}
        placeholder={pestPlaceholder}
        requiredLength={0}
        suggestions={this.props.suggestions}
        testData="pest-selector"
      />
    );
  }
}

PestSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  onSuggestionSelected: PropTypes.func,
  getSuggestions: PropTypes.func,
  autoFocus: PropTypes.bool,
};

PestSelector.defaultProps = {
  suggestions: [],
  onSuggestionSelected: () => {},
  getSuggestions: () => {},
  autoFocus: false,
};

export default injectIntl(PestSelector);
