import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = {
  navigationContainer: {
    display: 'inline-flex',
  },
  headerContainer: {
    fontSize: '16px',
  },
};

function SensorsNavigationButton(props) {
  const { match } = props;
  const path = `/farm/${match.params.farmId}/sensors/${match.params.sensorId}`;

  return (
    <div className={props.classes.navigationContainer}>
      <IconButton
        className={props.classes.iconButton}
        color="primary"
        size="large"
        onClick={() => {
          props.history.push(path);
        }}>
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
}

SensorsNavigationButton.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

SensorsNavigationButton.defaultProps = {
  classes: {},
  history: {},
  match: {},
};

export default withStyles(style)(SensorsNavigationButton);
