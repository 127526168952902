import React from 'react';

import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = theme => ({
  icon: {
    cursor: 'help',
  },
  iconWarn: {
    color: theme.palette.secondary.main,
  },
  iconErr: {
    color: theme.palette.error.main,
  },
  iconInfo: {
    color: '#89D7F0',
  },
  iconInfoOutline: {
    color: theme.palette.grey[400],
  },
  tooltip: {
    padding: 0,
    color: '#4F4F4F',
    opacity: 1,
  },
  paperRoot: {
    padding: 8,
    fontWeight: 'normal',
  },
});

class ValidationStatusIcon extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    open: false,
  };

  handleIconButtonRequestClose = () => {
    this.setState({ open: false });
  };

  handleIconButtonRequestOpen = () => {
    this.setState({ open: true });
  };

  handleIconTooltipToggle = () => {
    this.setState({ open: !this.state.open });
  };

  resolveIconClass(type) {
    const { classes } = this.props;
    switch (type) {
      case 'error':
        return classes.iconErr;
      case 'warning':
        return classes.iconWarn;
      case 'info':
        return classes.iconInfo;
      case 'info_outline':
        return classes.iconInfoOutline;
      default:
        return classes.iconInfo;
    }
  }

  render() {
    const { children, classes, id, placement, type } = this.props;
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        enterDelay={500}
        id={`tooltip-${id}`}
        leaveDelay={300}
        onClose={this.handleIconButtonRequestClose}
        onOpen={this.handleIconButtonRequestOpen}
        open={this.state.open}
        placement={placement}
        title={<Paper className={classes.paperRoot}>{children}</Paper>}
      >
        <Icon
          classes={{ root: this.resolveIconClass(type) }}
          className={classes.icon}
          data-test={`tooltip-icon-${id}`}
          onClick={this.handleIconTooltipToggle}
        >
          {type}
        </Icon>
      </Tooltip>
    );
  }
}

ValidationStatusIcon.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.object,
};

ValidationStatusIcon.defaultProps = {
  placement: 'bottom',
  type: 'warning',
  classes: {},
};

export default withStyles(style)(ValidationStatusIcon);
