import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WetnessIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M14.6712 8.27241C13.7832 6.41641 12.4379 4.91629 11.1374 3.47022C11.0433 3.36661 10.9536 3.263 10.8594 3.15939C9.66656 1.83045 8.72035 0.938489 8.67999 0.90245L7.97594 0.240234L7.2674 0.897945C7.22704 0.933984 6.27186 1.82144 5.079 3.15488C4.98483 3.2585 4.89514 3.36211 4.80097 3.46572C3.50049 4.91629 2.15517 6.4119 1.26725 8.26791C0.168571 10.5699 -0.0646172 12.998 0.612529 15.1018C1.26277 17.1245 2.89958 18.7642 4.98931 19.4895C5.39291 19.6292 5.80548 19.7283 6.2584 19.8004C6.64855 19.8589 7.04318 19.8995 7.42884 19.922C7.81001 21.0482 8.42886 21.9537 9.29435 22.6655L10.5455 21.1248C10.1195 20.7734 9.77867 20.35 9.52754 19.8364C10.0253 19.7643 10.4962 19.6472 10.9401 19.494C13.0299 18.7733 14.6667 17.1335 15.3169 15.1063C16.003 13.0025 15.7743 10.5744 14.6712 8.27241ZM13.3483 14.4621C12.8953 15.8631 11.7473 17.0074 10.272 17.5164C9.89078 17.647 9.46476 17.7416 9.00735 17.7957C8.98044 17.4894 8.96251 17.1605 8.96251 16.8136V15.9577L11.5904 14.8765L10.837 13.0341L8.95802 13.8089V12.2187L11.5859 11.1375L10.8325 9.29502L8.96251 10.0608V7.37594H6.9804V10.0608L5.10142 9.28601L4.34804 11.1285L6.97591 12.2097V13.7999L5.09694 13.025L4.34356 14.8675L6.97143 15.9487V16.8046C6.97143 17.1425 6.98488 17.4668 7.0073 17.7822C6.85931 17.7642 6.71133 17.7461 6.56335 17.7236C6.23598 17.6741 5.94001 17.602 5.65749 17.5029C4.18212 16.9938 3.03411 15.8496 2.58118 14.4486C2.07445 12.8809 2.27625 10.9528 3.13277 9.15987C3.89064 7.57416 5.13282 6.19116 6.33464 4.85322C6.42881 4.74961 6.52298 4.64599 6.61716 4.53788C7.12838 3.96576 7.59924 3.47473 7.96248 3.10983C8.32123 3.47473 8.7921 3.96576 9.3078 4.53788C9.40198 4.64149 9.49615 4.7451 9.59032 4.84871C10.7921 6.18666 12.0343 7.56965 12.7922 9.15537C13.6532 10.9663 13.855 12.8944 13.3483 14.4621Z"
      transform="translate(3, 0)"
    />
  </SvgIcon>
);

export default WetnessIcon;
