import { createSelector } from 'reselect';

export const getChemistry = state => state.api.chemistry.items.map(item => ({ materialTypeId: 'CH', ...item }));

export const getChemistryApplications = state => state.api.chemistryApplications.items;

export const getChemistrySuggestions = createSelector(getChemistry, chemistry => [
  {
    title: 'common.chemistries',
    items: chemistry,
  },
]);
