import React, { Component } from 'react';

import FormGroup from '@mui/material/FormGroup';
import { withStyles } from '@mui/styles';
import countBy from 'lodash/countBy';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCrops, getCropsOrder } from '../../selectors/crops.selectors';
import { getTool } from '../../selectors/editor.selectors';
import { getParcelLayerVisibility } from '../../selectors/layers.selectors';

import { setCropVisibility, setAllCropsVisibility } from '../../actions/crops/crops.actions';

import MapSwitcherBody from '../../components/MapSwitcherBody/MapSwitcherBody';
import MapSwitcherCheckbox from '../../components/MapSwitcherCheckbox/MapSwitcherCheckbox';
import MapSwitcherHeader from '../../components/MapSwitcherHeader/MapSwitcherHeader';

const styles = {
  switcherRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cropColor: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    marginRight: 5,
  },
};

export class CropSwitcher extends Component {
  getVisibleCropsCount = () => countBy(this.props.crops, crop => crop.visible).true;

  handleCropSelect = (crop, visible) => {
    this.props.setCropVisibility(crop, visible);
  };

  handleAllCropsSelect = visible => {
    this.props.setAllCropsVisibility(visible);
  };

  isDisabled = () => !!this.props.tool || !this.props.parcelLayerVisibility;

  render() {
    const { classes, crops, cropsOrder, expanded, handleExpansion, testId } = this.props;
    const visibleCount = this.getVisibleCropsCount();

    return (
      <div data-test={testId}>
        <MapSwitcherHeader expanded={expanded} handleExpansion={handleExpansion} testId={testId}>
          <MapSwitcherCheckbox
            checked={visibleCount > 0 && cropsOrder.length === visibleCount}
            disabled={this.isDisabled()}
            header={true}
            indeterminate={visibleCount > 0 && visibleCount < cropsOrder.length}
            label={<FormattedMessage id="CropSwitcher.crops" />}
            onChange={event => this.handleAllCropsSelect(event.target.checked)}
          />
        </MapSwitcherHeader>
        <MapSwitcherBody expanded={expanded} testId={testId}>
          <FormGroup>
            {cropsOrder.map(cropId => {
              const crop = crops[cropId];
              return (
                <div className={classes.switcherRow} key={crop.id}>
                  <MapSwitcherCheckbox
                    checked={crop.visible}
                    disabled={this.isDisabled()}
                    label={crop.name}
                    onChange={event => this.handleCropSelect(crop, event.target.checked)}
                  />
                  <div
                    className={classes.cropColor}
                    style={{ backgroundColor: `rgba(${crop.color.red}, ${crop.color.green}, ${crop.color.blue}, 1)` }}
                  />
                </div>
              );
            })}
          </FormGroup>
        </MapSwitcherBody>
      </div>
    );
  }
}

CropSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object,
  cropsOrder: PropTypes.array,
  expanded: PropTypes.bool.isRequired,
  handleExpansion: PropTypes.func.isRequired,
  setCropVisibility: PropTypes.func.isRequired,
  setAllCropsVisibility: PropTypes.func.isRequired,
  testId: PropTypes.string,
  parcelLayerVisibility: PropTypes.bool,
  tool: PropTypes.string,
};

CropSwitcher.defaultProps = {
  classes: {},
  crops: {},
  cropsOrder: [],
  testId: '',
  parcelLayerVisibility: false,
  tool: null,
};

const mapStateToProps = state => ({
  crops: getCrops(state),
  tool: getTool(state),
  cropsOrder: getCropsOrder(state),
  parcelLayerVisibility: getParcelLayerVisibility(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCropVisibility,
      setAllCropsVisibility,
    },
    dispatch,
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CropSwitcher)));
