/**
 * Created by brasko on 23.9.2015.
 */


/*@ngInject*/
export default function Modal($uibModal) {

    var service = {
        activateModal: activateModal
    };
    return service;

    /////////////////////////

    function activateModal(config) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: config.templateUrl,
            controller: 'ModalController as ModalCtrl',
            backdrop: !_.isUndefined(config.backdrop) ? config.backdrop : true,
            size: config.size,
            resolve: {
                config: function () {
                    return config;
                }
            }
        });
        return modalInstance.result;
    }
}
