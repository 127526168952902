import React, { ChangeEvent, FC, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import IrrigationAdminAssignFarm from '../IrrigationAdminAssignFarm/IrrigationAdminAssignFarm';

import { AdminFarm, IrrigationAdminDevice } from '../../admin.irrigation.types';

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    minWidth: '300px',
    padding: '5px 0px',
  },
  button: {
    marginLeft: 24,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  textField: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: 20,
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '21px',
  },
  assignFarm: {
    marginBottom: 20,
  },
  assignFarmLable: {
    fontSize: 12,
    marginBottom: 5,
  },
}
));

interface Props {
  device: IrrigationAdminDevice,
  onAccept: (newFarm: AdminFarm, newName: string) => void,
  onClose: () => void,
  opened?: boolean
}

const IrrigationAdminDeviceEditDialog: FC<Props> = ({
  device,
  onAccept,
  onClose,
  opened = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [currentDevice, setCurrentDevice] = useState<IrrigationAdminDevice>(device);

  const onChangeDeviceName = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setCurrentDevice({ ...currentDevice, name: evt.target.value });
  };

  const onSuggestionSelect = (farmToAssign: AdminFarm) => {
    setCurrentDevice({ ...currentDevice, farm: farmToAssign });
  };

  const handleAccept = () => {
    onAccept(currentDevice.farm, currentDevice.name);
  };

  return (
    <Dialog data-test={'dialog'} open={opened}>
      <DialogTitle>
        <FormattedMessage id="IrrigationAdmin.editDevice" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <TextField
            className={classes.textField}
            disabled
            label={<FormattedMessage id="SensorsAdmin.external_id" />}
            name="deviceExternalId"
            onChange={onChangeDeviceName}
            placeholder={intl.formatMessage({ id: 'SensorsAdmin.external_id' })}
            value={currentDevice.externalId}
            variant="standard"
          />
          <div className={classes.assignFarm}>
            <DialogContentText className={classes.assignFarmLable}>
              <FormattedMessage id="common.farm" />
            </DialogContentText>
            <IrrigationAdminAssignFarm
              inModal
              item={currentDevice}
              onAssignDevicesToFarm={(farm) => onSuggestionSelect(farm)}
            />
          </div>
          <TextField
            className={classes.textField}
            label={<FormattedMessage id="common.name" />}
            name="deviceName"
            onChange={onChangeDeviceName}
            placeholder={intl.formatMessage({ id: 'common.name' })}
            value={currentDevice.name}
            variant="standard"
          />
          <TextField
            className={classes.textField}
            disabled
            label={<FormattedMessage id="common.type" />}
            name="deviceExternalId"
            onChange={onChangeDeviceName}
            placeholder={intl.formatMessage({ id: 'common.type' })}
            value={currentDevice.type}
            variant="standard"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid alignItems="center" container justifyContent="flex-end" spacing={1}>
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
              >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="save"
            onClick={handleAccept}
            type="submit"
            variant="text"
              >
            <FormattedMessage id="common.save" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default IrrigationAdminDeviceEditDialog;
