import React, { Component } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMaterialsSuggestions } from '../../selectors/stores.selectors';

import { getMaterialsByName, resetMaterials } from '../../actions/stores.actions';

import CfAutosuggest from '../../../../../shared/components/common/CfAutosuggest/CfAutosuggest';
import ListSelectorError from '../../../../../shared/components/form/ListSelectorError/ListSelectorError';

export class MaterialSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  onSuggestionReset = () => {
    this.setState({ disabled: true });
    this.props.onSuggestionReset();
  };

  onSuggestionSelect = value => {
    this.setState({ disabled: false });
    this.props.onSuggestionSelect(value);
  };

  getSuggestionValue = sugg =>
    (sugg.materialTypeId === 'CH'
      ? [sugg.name, sugg.marketingAuth, sugg.regNumber, sugg.aktualState]
      : [sugg.name, sugg.producent]
    )
      .filter(item => item)
      .join(', ');

  render() {
    const { formatMessage } = this.props.intl;
    const { error, submitFailed } = this.props.meta;
    const placeholder = formatMessage({ id: 'Stores.material-name' });

    return (
      <div>
        <CfAutosuggest
          autoFocus={true}
          clearInputOnAdornmentClick={true}
          clearInputOnSelect={false}
          clearSuggestions={this.props.resetMaterials}
          disabled={!this.state.disabled}
          getSuggestions={this.props.getMaterialsByName}
          getSuggestionValue={this.getSuggestionValue}
          onAdornmentClick={this.onSuggestionReset}
          onSuggestionSelected={this.onSuggestionSelect}
          placeholder={placeholder}
          requiredLength={1}
          suggestions={this.props.suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
          testData="material-selector"
          adornment={
            <IconButton disabled={this.state.disabled} size="large">
              <ClearIcon />
            </IconButton>
          }
        />
        {submitFailed && error && <ListSelectorError error={<FormattedMessage id="validation.required" />} />}
      </div>
    );
  }
}

MaterialSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  meta: PropTypes.object,
  suggestions: PropTypes.array,
  getMaterialsByName: PropTypes.func.isRequired,
  resetMaterials: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func,
  onSuggestionReset: PropTypes.func,
};

MaterialSelector.defaultProps = {
  meta: {},
  suggestions: [],
  onSuggestionSelect: () => {},
  onSuggestionReset: () => {},
};

const mapStateToProps = state => ({
  suggestions: getMaterialsSuggestions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMaterialsByName,
      resetMaterials,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MaterialSelector));
