import * as types from './users.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case types.GET_USERS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_USERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_USERS:
      return {
        isFetching: false,
        error: {},
        items: [],
      };

    default:
      return state;
  }
};
