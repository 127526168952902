import React, { Suspense } from 'react';

import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { LANG_KEYS } from '../../../../shared/lang/lang.constants';

import CfLoader from '../../../../shared/components/common/CfLoader/CfLoader';
import BaseContainer from '../../../../shared/containers/BaseContainer/BaseContainer';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';
import Reports from '../Reports/Reports';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
};

const ReportsWrapper = props => (
  <BaseContainer langId={props.langId} translations={translations}>
    <Suspense fallback={<CfLoader />}>
      <Switch>
        <Route path="/farm/:farmId/reports" render={routerProps => <Reports {...props} {...routerProps} />} />
      </Switch>
    </Suspense>
  </BaseContainer>
);

ReportsWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default ReportsWrapper;
