import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = theme => ({
  tooltipValue: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.secondary.dark,
  },
  custom: {},
});

const CfChartTooltipValue = (props) => {
  const { children, classes, color } = props;
  return (
    <p className={`${classes.tooltipValue} ${classes.custom}`} style={{ color }}>
      {children}
    </p>
  );
};

CfChartTooltipValue.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

CfChartTooltipValue.defaultProps = {
  color: undefined,
};

export default withStyles(style)(CfChartTooltipValue);
