import { createSelector } from 'reselect';

export const getFertilizers = state => state.api.fertilizers.items.map(item => ({ materialTypeId: 'FR', ...item }));

export const getFertilizersSuggestions = () =>
  createSelector(getFertilizers, fertilizers => [
    {
      title: 'common.fertilizers',
      items: fertilizers,
    },
  ]);
