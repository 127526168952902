import get from 'lodash/get';

import * as types from './zones.constants';

export default reducerContext => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    statistics: {},
    statisticsError: {},
    isFetchingStatistics: false,
  },
  action = {},
) => {
  const actionContext = get(action, 'meta.context');
  if (reducerContext && actionContext !== reducerContext) return state;

  switch (action.type) {
    case types.GET_ZONES:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_ZONES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_ZONES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_ZONES:
      return {
        ...state,
        items: [],
      };
    case types.GET_ZONES_STATISTICS:
      return {
        ...state,
        isFetchingStatistics: true,
        statisticsError: {},
      };
    case types.GET_ZONES_STATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingStatistics: false,
        statistics: action.payload,
      };
    case types.GET_ZONES_STATISTICS_FAILURE:
      return {
        ...state,
        isFetchingStatistics: false,
        statisticsError: action.payload,
      };
    case types.RESET_ZONES_STATISTICS:
      return {
        ...state,
        statistics: {},
        isFetchingStatistics: false,
        statisticsError: {},
      };
    default:
      return state;
  }
};
