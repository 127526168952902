import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => (
  {
    button: {
      fontSize: 13,
      marginLeft: -9,
    },
    icon: {
      color: theme.palette.primary.main,
    },
    secondaryText: {
      color: theme.palette.text.secondary,
    },
    tableRow: {
      transform: 'scale(1)',
      '&:hover $tableRowTools': {
        visibility: 'visible',
      },
    },
    tableRowTools: {
      visibility: 'hidden',
    },
  }
));
