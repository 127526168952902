import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedTime, FormattedDate, injectIntl } from 'react-intl';

import HorizontalScrolling from '../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling';
import HorizontalScrollingItem from '../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem';
import Localization from '../../../../shared/services/Localization.service';
import Weather from '../../services/Weather.service';

import WeatherForecastPlaceholder from './WeatherForecastPlaceholder';

const styles = theme => ({
  weatherForecast: {
    width: '100%',
    height: '100%',
    lineHeight: 1.7,
    flex: '1 1 180px',
  },
  forecastItem: {
    marginRight: 30,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  forecastIconDiv: {
    paddingRight: 10,
    alignSelf: 'center',
  },
  forecastIcon: {
    width: 36,
    height: 36,
  },
  forecastValues: {
    fontWeight: 500,
  },
  forecastTime: {
    fontWeight: 'normal',
    color: theme.palette.grey[500],
  },
  tempLow: {
    paddingLeft: 8,
    color: theme.palette.grey[500],
  },
});

function WeatherForecast(props) {
  const { classes, forecast, intl, isFetching } = props;
  let forecastData = [];

  if (forecast && forecast.data) {
    if (props.isDayForecast) {
      const endTime = moment()
        .add(1, 'd')
        .endOf('day');
      let checkpoint = false;
      for (let i = 0; i < forecast.data.length; i += 1) {
        const item = forecast.data[i];
        const itemTime = moment(item.time * 1000);
        if (itemTime.isBefore(endTime)) {
          if (!checkpoint) {
            if (itemTime.get('h') % 3 === 2) {
              checkpoint = true;
            }
            forecastData.push(item);
          } else if (itemTime.get('h') % 3 === 0) {
            const slicedArray = forecast.data.slice(i, i + 3);
            const newItem = { ...item };
            // average precipitation intensity
            newItem.precipIntensity = slicedArray.map(data => data.precipIntensity).reduce((a, b) => a + b) / 3;
            // maximum precipitation probability
            newItem.precipProbability = Math.max(...slicedArray.map(data => data.precipProbability));
            forecastData.push(newItem);
          }
        } else {
          break;
        }
      }
    } else {
      forecastData = forecast.data;
    }
  }

  return (
    <div className={classes.weatherForecast} data-test="weather-forecast-data">
      {isFetching ? (
        <WeatherForecastPlaceholder />
      ) : (
        <HorizontalScrolling>
          {forecastData.map(forecastItem => (
            <HorizontalScrollingItem key={forecastItem.time}>
              <div className={classes.forecastItem}>
                <div className={classes.forecastIconDiv}>
                  <img
                    alt="weather icon"
                    className={classes.forecastIcon}
                    src={Weather.getWeatherIcon(forecastItem.icon)}
                  />
                </div>
                <div className={classes.forecastValues}>
                  <div>
                    {props.isDayForecast ? (
                      <span>{Math.round(forecastItem.temperature)} °C</span>
                    ) : (
                      <Fragment>
                        <span>{Math.round(forecastItem.temperatureHigh)} °C</span>
                        <span className={classes.tempLow}>{Math.round(forecastItem.temperatureLow)} °C</span>
                      </Fragment>
                    )}
                  </div>
                  <div>{Localization.num2str(forecastItem.precipIntensity, intl.locale, 1)} mm/h</div>
                  <div>{Math.round(forecastItem.precipProbability * 100)} %</div>
                  <div className={classes.forecastTime}>
                    {props.isDayForecast ? (
                      <FormattedTime hour="numeric" minute="numeric" value={forecastItem.time * 1000} />
                    ) : (
                      <FormattedDate day="numeric" value={forecastItem.time * 1000} weekday="short" />
                    )}
                  </div>
                </div>
              </div>
            </HorizontalScrollingItem>
          ))}
        </HorizontalScrolling>
      )}
    </div>
  );
}

WeatherForecast.propTypes = {
  intl: PropTypes.object.isRequired,
  isDayForecast: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  forecast: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
};

WeatherForecast.defaultProps = {
  forecast: undefined,
};

export default injectIntl(withStyles(styles)(WeatherForecast));
