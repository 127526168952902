import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = theme => ({
  actionButton: {
    width: 30,
    height: 30,
    marginBottom: '5px',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 0 6px #303030',
    },
    marginTop: 16,
    minHeight: 0,
  },
  addIcon: {
    width: 15,
    height: 15,
  },
});

function InputActionButton(props) {
  const { classes, onClick, type } = props;
  return (
    <Fab aria-label="add" className={classes.actionButton} onClick={onClick} type={type}>
      <AddIcon className={classes.addIcon} />
    </Fab>
  );
}

InputActionButton.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

InputActionButton.defaultProps = {
  classes: {},
  type: 'submit',
  onClick: PropTypes.func,
};

export default withStyles(style)(InputActionButton);
