import React from 'react';

import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

/**
 * Tabs wrapper for primary navigation used when the page contains more categories of content.
 * It is typically located at the top of the page or dialog.
 * Other extra props not defined here will be propagated to MUI Tabs component.
 * @version 1.0.0
 */
function CfPrimaryTabs(props) {
  const { children, onChange, tabValue, ...otherProps } = props;
  return (
    <Tabs indicatorColor="primary" onChange={onChange} textColor="primary" value={tabValue} {...otherProps}>
      {children}
    </Tabs>
  );
}

CfPrimaryTabs.propTypes = {
  /** Tabs to be rendered inside of this component. CfPrimaryTab's are expected to be used. */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  /** Callback fired when user changes selected tab. */
  onChange: PropTypes.func.isRequired,
  /** Value of selected tab. */
  tabValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CfPrimaryTabs;
