import React, { Component } from 'react';

import { getDisplayName } from './hocHelpers';

const withEditing = WrappedComponent => {
  class WithEditing extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isEditing: false,
      };
    }

    handleEditingStart = () => {
      this.setState({
        isEditing: true,
      });
    };

    handleEditingEnd = () => {
      this.setState({
        isEditing: false,
      });
    };

    render() {
      const { isEditing } = this.state;
      return (
        <WrappedComponent
          isEditing={isEditing}
          onEditingEnd={this.handleEditingEnd}
          onEditingStart={this.handleEditingStart}
          {...this.props}
        />
      );
    }
  }

  WithEditing.displayName = `WithEditing(${getDisplayName(WrappedComponent)})`;

  return WithEditing;
};

export default withEditing;
