import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getIsFetchingAction as getIsFetchingActionApi,
  getAction as getActionApi,
} from '../../../../../shared/api/core/actions/actions.selectors';
import { getVariableActionIds as getVariableActionIdsSelector } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import { getAction } from '../../../../../shared/api/core/actions/actions.api';
import {
  getVariableActionIds,
  resetVariableActionIds,
} from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import CfLoaderPage from '../../../../../shared/components/common/CfLoaderPage/CfLoaderPage';
import Eph from '../../../eph/containers/Eph/Eph';
import { isEph } from '../../../eph/misc/eph.helpers';
import ActionOtherDetailWrapper from '../../../others/containers/ActionOtherDetailWrapper/ActionOtherDetailWrapper';
import { getFormType, isOtherAction } from '../../../others/helpers/others.helpers';
import Vrf from '../../../vrf/containers/Vrf/Vrf';
import { isVrf } from '../../../vrf/helpers/vrf.helpers';
import Vrs from '../../../vrs/containers/Vrs/Vrs';
import { isVrs } from '../../../vrs/helpers/vrs.helpers';
import { getRouting } from '../../misc/action.helpers';

export class ActionDetail extends Component {
  componentDidMount() {
    this.props.getVariableActionIds().then(() => {
      this.props.getAction(this.props.match.params.actionId);
    });
  }

  componentDidUpdate(prevProps) {
    const { action: newAction, variableActionIds: newVariableActionIds } = this.props;

    if (
      Array.isArray(newVariableActionIds) &&
      newAction &&
      (!prevProps.action || newAction.id !== prevProps.action.id)
    ) {
      if (
        !isEph(newAction, newVariableActionIds) &&
        !isVrf(newAction, newVariableActionIds) &&
        !isVrs(newAction, newVariableActionIds)
      ) {
        const route = getRouting(newAction, newVariableActionIds);
        this.props.ngGoToAction(newAction.id, route);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetVariableActionIds();
  }

  render() {
    const {
      action,
      ephTranslations,
      history,
      isFetching,
      match,
      ngGoToActions,
      othersTranslations,
      variableActionIds,
      vrfTranslations,
      vrsTranslations,
    } = this.props;
    const isEphAction = isEph(action, variableActionIds);
    const isVrfAction = isVrf(action, variableActionIds);
    const isVrsAction = isVrs(action, variableActionIds);
    const isNeitherAction = !isEphAction && !isVrfAction && !isVrsAction;

    return (
      <Fragment>
        {isFetching && <CfLoaderPage />}
        {!isFetching && isEphAction && (
          <Eph
            ngGoToActions={ngGoToActions}
            {...this.props}
            match={match}
            translations={ephTranslations}
            existingAction={{
              ...action,
              isEph: true,
            }}
          />
        )}
        {!isFetching && isVrfAction && (
          <Vrf
            history={history}
            {...this.props}
            match={match}
            translations={vrfTranslations}
            existingAction={{
              ...action,
              isVrf: true,
            }}
          />
        )}
        {!isFetching && isVrsAction && (
          <Vrs
            history={history}
            {...this.props}
            match={match}
            translations={vrsTranslations}
            existingAction={{
              ...action,
              isVrs: true,
            }}
          />
        )}
        {!isFetching && isNeitherAction && isOtherAction(action) && (
          <ActionOtherDetailWrapper
            history={history}
            {...this.props}
            existingAction={action}
            formType={getFormType(action)}
            isExisting={Boolean(action)}
            match={match}
            translations={othersTranslations}
          />
        )}
      </Fragment>
    );
  }
}

ActionDetail.propTypes = {
  getAction: PropTypes.func.isRequired,
  match: PropTypes.object,
  action: PropTypes.object,
  isFetching: PropTypes.bool,
  classes: PropTypes.object,
  ngGoToAction: PropTypes.func.isRequired,
  ngGoToActions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  ephTranslations: PropTypes.object,
  vrfTranslations: PropTypes.object,
  vrsTranslations: PropTypes.object,
  othersTranslations: PropTypes.object,
  getVariableActionIds: PropTypes.func.isRequired,
  resetVariableActionIds: PropTypes.func.isRequired,
  variableActionIds: PropTypes.array,
};

ActionDetail.defaultProps = {
  match: {},
  action: {},
  isFetching: true,
  classes: {},
  ephTranslations: {},
  vrfTranslations: {},
  vrsTranslations: {},
  othersTranslations: {},
  variableActionIds: null,
};

const mapStateToProps = state => ({
  action: getActionApi(state),
  isFetching: getIsFetchingActionApi(state),
  variableActionIds: getVariableActionIdsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAction,
      getVariableActionIds,
      resetVariableActionIds,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionDetail);
