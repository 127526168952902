/**
 * Created by ondrejzvara on 29.3.16.
 */
import localStorageService from 'local-storage';
import * as Sentry from "@sentry/browser";

/* @ngInject */
export default function UserStorage(UsersResource) {
  const service = {
    setUser,
    getUser,
    hasUser,
    removeUser
  };
  return service;

  function setUser(username) {
    return UsersResource.getUser(username).then((userData) => {
      const user = userData.plain();
      setupUserSentryContext(user);
      localStorageService.set('user', user);
      return user;
    });
  }

  function setupUserSentryContext(user) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id,
        email: user.email,
        username: user.login
      }); // attach user/email context
    });
  }

  function hasUser() {
    return !!getUser();
  }

  function getUser() {
    return localStorageService.get('user');
  }

  function removeUser() {
    localStorageService.remove('user');
  }
}
