import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import { getChemistryEffectiveness } from '../../../shared/selectors/actions.selectors';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import ParcelChemistryEffectiveness from '../../components/ParcelChemistryEffectiveness/ParcelChemistryEffectiveness';

const styles = theme => ({
  heading: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 13,
  },
});

class ChemistryEffectivenessDialog extends Component {
  onSubmit = (values) => {
    if (values.effectiveness) {
      this.props.onAccept(values);
      this.props.reset();
      this.props.destroy();
    }
  };

  onClose = () => {
    this.props.onClose();
    this.props.destroy();
  };

  initializeForm = () => {
    if (!this.props.chemistry.effectiveness || this.props.chemistry.effectiveness.length === 0) {
      this.props.initialize({
        effectiveness: this.props.getEffectiveness(this.props.parcels),
      });
    } else {
      this.props.initialize({
        effectiveness: this.props.chemistry.effectiveness,
      });
    }
  };

  render() {
    const { chemistry, classes, parcels } = this.props;
    const dialogTitle = (
      <FormattedMessage
        id="ChemistryEffectivenessDialog.heading"
        values={{
          por: chemistry && chemistry.material ? chemistry.material.name : '',
        }}
      />
    );
    return (
      <CfDialog
        acceptText={this.props.editing ? <FormattedMessage id="common.save" /> : <FormattedMessage id="common.close" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={this.props.editing ? this.props.handleSubmit(this.onSubmit) : this.onClose}
        onCancel={this.props.editing ? this.onClose : undefined}
        onClose={this.onClose}
        onEnter={() => this.initializeForm()}
        opened={this.props.opened}
        title={dialogTitle}
      >
        <div>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              {''}
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row">
                <Grid className={classes.heading} item xs={4}>
                  <FormattedMessage id="ChemistryEffectiveness.yes" />
                </Grid>
                <Grid className={classes.heading} item xs={4}>
                  <FormattedMessage id="ChemistryEffectiveness.no" />
                </Grid>
                <Grid className={classes.heading} item xs={4}>
                  <FormattedMessage id="ChemistryEffectiveness.not_specified" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <form id="chemistry-effectiveness-per-parcel">
            <CfFormControl>
              <FieldArray
                change={this.props.change}
                chemistryId={chemistry.material.id}
                component={ParcelChemistryEffectiveness}
                editing={this.props.editing}
                name="effectiveness"
                parcels={parcels}
              />
            </CfFormControl>
          </form>
        </div>
      </CfDialog>
    );
  }
}

ChemistryEffectivenessDialog.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  classes: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  chemistry: PropTypes.object.isRequired,
  effectiveness: PropTypes.array,
  parcels: PropTypes.array,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  getEffectiveness: PropTypes.func.isRequired,
};

ChemistryEffectivenessDialog.defaultProps = {
  opened: false,
  classes: {},
  change: () => {},
  initialize: () => {},
  chemistry: null,
  parcels: [],
  effectiveness: [],
  handleSubmit: () => {},
  editing: false,
};

const mapStateToProps = state => ({
  effectiveness: getChemistryEffectiveness('effectiveness', state),
});

export default connect(mapStateToProps, null)(
  reduxForm({
    form: 'effectiveness',
  })(withStyles(styles)(ChemistryEffectivenessDialog)),
);
