import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WindIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 40 40" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M30.6,21.9H13.2v-3.2h17.5c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2s-2.2,1-2.2,2.2h-3.2c0-3,2.4-5.4,5.4-5.4s5.4,2.4,5.4,5.4C36,19.4,33.6,21.9,30.6,21.9z" />
      <rect height="3.2" width="6" x="4" y="18.6" />
      <path d="M18.2,15.2H7.7V12h10.5c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2S16,8.6,16,9.8h-3.2c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4C23.6,12.8,21.2,15.2,18.2,15.2z" />
      <path d="M25.8,36.1v-3.2c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2H10.3v-3.2h15.5c3,0,5.4,2.4,5.4,5.4S28.8,36.1,25.8,36.1z" />
    </svg>
  </SvgIcon>
);

export default WindIcon;
