import React, { FC, ReactNode, useEffect } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingProductionOperations, getProductionOperations } from '../../../shared/api/telematics/drives/drives.selectors';

import { getProductionOperationsApi } from '../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { ProductionOperationTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: '-5px',
  },
}));

interface Props {
  defaultValue?: ProductionOperationTo,
  error: boolean;
  fetchProductionOperations: () => void;
  helperText: ReactNode;
  isFetching: boolean;
  label: ReactNode;
  onChange: (value: ProductionOperationTo) => void;
  productionOperations: ProductionOperationTo[];
}

const ProductionOperationSelector: FC<Props> = ({
  defaultValue,
  error,
  fetchProductionOperations,
  helperText,
  isFetching,
  label,
  onChange,
  productionOperations,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchProductionOperations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenderOption = (option: ProductionOperationTo) => (
    <div>
      <div>{option.name}</div>
      <div className={classes.id}>{option.code}</div>
    </div>
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValue}
      error={error}
      helperText={helperText}
      id="telematics-dialog-operation-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={productionOperations}
      testId="telematics-dialog-operation-selector"
    />
  );

  return null;
};

const mapStateToProps = (state: TelematicsState) => ({
  isFetching: getIsFetchingProductionOperations(state),
  productionOperations: getProductionOperations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  fetchProductionOperations: getProductionOperationsApi,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductionOperationSelector);
