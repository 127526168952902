import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMachines, getIsFetchingMachines } from '../../../../shared/api/telematics/drives/drives.selectors';

import { fetchMachines } from '../../../actions/telematicsList.actions';

import { resetMachines } from '../../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { MachineTo } from '../../../../shared/api/telematics/telematics.types';

interface MachineSelectorProps {
  defaultValues?: MachineTo[];
  fetchMachines(): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: MachineTo[]): void;
  options: MachineTo[];
  resetMachines(): void;
}

const defaultDefaultValues: MachineTo[] = [];

const handleGetSelected = (option: MachineTo, value?: MachineTo | null) =>
  option.machineCode === value?.machineCode;

const handleFilterOptions = createFilterOptions({
  stringify: ({ licencePlate, name }) => `${name} ${licencePlate}`,
});

const MachineSelector: FC<MachineSelectorProps> = (
  { defaultValues = defaultDefaultValues,
    options,
    isFetching,
    label,
    fetchMachines,
    resetMachines,
    onChange,
  }) => {
  const classes = useAdvancedFilterStyles();

  useEffect(() => {
    fetchMachines();
    return () => {
      resetMachines();
    };
  }, [fetchMachines, resetMachines]);

  const handleRenderOption = useCallback((option: MachineTo) => (
    <div>
      <div>{option.name ?? option.gpsUnit}</div>
      <div className={classes.subtext}>{option.licencePlate ?? option.gpsUnit}</div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: MachineTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          label={
            <>
              {value.name ?? value.gpsUnit}
              <span className={classes.subtext}>{value.licencePlate ?? value.gpsUnit}</span>
            </>
          }
        />
      ))}
    </>, [classes]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="machine-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="machine-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getMachines(state),
  isFetching: getIsFetchingMachines(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchMachines,
      resetMachines,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MachineSelector);
