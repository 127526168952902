import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfButtonPanel from '../../../../../../shared/components/common/CfButtonPanel/CfButtonPanel';
import CfSwitcher from '../../../../../../shared/components/common/CfSwitcher/CfSwitcher';
import { CfRadioGroup } from '../../../../../../shared/components/form/CfReduxFormRadioGroup/CfReduxFormRadioGroup';
import withWidth from '../../../../../../shared/hocs/withWidth';
import { indicesList } from '../../selectors/indices';

const styles = {
  indicesPanel: {
    padding: '0px 2px',
  },
  indexRadioLabel: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
  },
  indexRadioLabelText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  indexRadio: {
    padding: 4,
  },
};

function BioMonitoringIndexSwitcher(props) {
  const { classes, index, setMonitoringIndex, width } = props;
  return (
    <Fragment>
      {width === 'xs' ? (
        <CfSwitcher
          getItemId={item => item.id}
          getItemValue={item => item.id}
          getItemViewValue={item => <FormattedMessage id={`BioMonitoring.${item.id}`} />}
          items={indicesList}
          onMenuItemClick={item => setMonitoringIndex({ id: item.id })}
          testId={'index-switcher'}
        />
      ) : (
        <CfButtonPanel classes={{ root: classes.indicesPanel }} data-test="index-radio-switcher" uppercase={false}>
          <CfRadioGroup
            labelPropertyFactory={option => <FormattedMessage id={`BioMonitoring.${option.value}`} />}
            onChange={evt => setMonitoringIndex({ id: evt.target.value })}
            value={index}
            classes={{
              formControlLabel: classes.indexRadioLabel,
              formControlLabelText: classes.indexRadioLabelText,
            }}
            options={indicesList.map(indexItem => ({
              value: indexItem.id,
            }))}
            RadioProps={{
              classes: {
                root: classes.indexRadio,
              },
            }}
          />
        </CfButtonPanel>
      )}
    </Fragment>
  );
}

BioMonitoringIndexSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  setMonitoringIndex: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(BioMonitoringIndexSwitcher);
