import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import moment from 'moment';

import {
  isAllParcelsSameCrop,
  validateParcels,
  validateExpenses,
  validateTargetCrop,
  validateActionDate,
  warnTargetCrop,
} from '../../shared/misc/action.helpers';

export const FERT_CODE = 'FERT';
export const CHEM_CODE = 'CHEM';

export const isCatchCrop = parcel => parcel.currentSeedApplication.seedApplicationType === 'CATCH_CROP';

export const isToTargetCatchCrop = (parcels, targetCrop) => {
  const parcelsByCropCodes = groupBy(parcels, 'currentSeedApplication.seed.crop.legislativeCode');
  const targetCropParcels = targetCrop ? parcelsByCropCodes[targetCrop.legislativeCode] : undefined;
  return !isNil(targetCropParcels) && some(targetCropParcels, isCatchCrop);
};

export const isValidCatchCropApplication = (parcels, targetCrop) =>
  isToTargetCatchCrop(parcels, targetCrop) && isAllParcelsSameCrop(parcels, isCatchCrop);

export const isInvalidCatchCropApplication = (parcels, targetCrop) =>
  isToTargetCatchCrop(parcels, targetCrop) && !isAllParcelsSameCrop(parcels, isCatchCrop);

export const getInitialValues = () => ({
  actionDate: moment().startOf('day'),
  parcels: [],
  expenses: [],
  isCatchCrop: false,
  isStrawDecay: false,
  targetCrop: null,
  actionNote: '',
});

export const validate = values => {
  const parcels = validateParcels(values);
  const expenses = validateExpenses(values);
  const targetCrop = validateTargetCrop(values);
  const actionDate = validateActionDate(values);

  return {
    ...(parcels ? { parcels } : {}),
    ...(expenses ? { expenses } : {}),
    ...(targetCrop ? { targetCrop } : {}),
    ...(actionDate ? { actionDate } : {}),
  };
};

export const warn = values => {
  const targetCrop = warnTargetCrop(values);
  return {
    ...(targetCrop ? { targetCrop } : {}),
  };
};

export const isEph = (action, variableActionIds = []) => {
  if (!action || !action.id) return false;
  const actionClassId = action.actionType.actionClass.code;

  return (actionClassId === FERT_CODE || actionClassId === CHEM_CODE) && !variableActionIds?.includes(action.id);
};
