/**
 * Created by brasko on 3.10.2015.
 */


/*@ngInject*/ 
export default function cfClassTdBtns() {

    var directive = {
        restrict: 'E',
        template:  require('./cf-class-td-btns.html'),
        require: ['?^ngController'],
        scope: {
            item: '=',
            config: '='
        },
        link: function(scope, element, attrs, required){

            scope.editItems = editItems;
            scope.cancelEdit = cancelEdit;
            scope.startItemEdit = startItemEdit;
            scope.deleteItems = deleteItems;
            scope.createItems = createItems;
            scope.cancelItemsCreate = cancelItemsCreate;

            scope.ctrl = required[0].clsCtrl;
            scope.edit = scope.config.edit;
            scope.delete = scope.config.delete;
            scope.create = scope.config.create;

            function editItems(){
                return scope.ctrl.editItems([scope.item]);
            }
            
            function cancelEdit(){
                return scope.ctrl.cancelEdit(scope.item);
            }
            
            function startItemEdit(){
                return scope.ctrl.startItemEdit(scope.item);
            }

            function cancelItemsCreate(){
                return scope.ctrl.cancelItemsCreate([scope.item]);
            }

            function deleteItems(){
                return scope.ctrl.deleteItems([scope.item]);
            }

            function createItems(){
                return scope.ctrl.createItems([scope.item]);
            }
            
        }
    };

    return directive;
}