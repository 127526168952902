import OlOverlay from 'ol/Overlay';

export default class Overlay {
  constructor(map) {
    this.map = map;
    this.overlays = {};
  }

  addOverlay(overlay, key) {
    this.map.addOverlay(overlay);
    this.overlays[key] = overlay;
  }

  removeOverlay(key) {
    this.map.removeOverlay(this.overlays[key]);
    delete this.overlays[key];
  }

  removeOverlays() {
    Object.keys(this.overlays).forEach(key => {
      this.removeOverlay(key);
    });
    this.overlays = {};
  }

  getOverlayElement(elementId) {
    const element = document.getElementById(elementId);
    const copy = element.cloneNode(true);
    copy.style.display = 'block';
    return copy;
  }

  createOverlay(element, position, overlayOptions = {}) {
    return new OlOverlay({
      element,
      position,
      stopEvent: false,
      ...overlayOptions,
    });
  }

  updateOverlayPosition(key, coordinates) {
    if (this.overlays[key]) {
      this.overlays[key].setPosition(coordinates);
    }
  }
}
