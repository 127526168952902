import React, { Component } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';

class CollapseSwitchMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { currentItemId, icon, items, label, mobile, onMenuItemClick, switchId } = this.props;
    return (
      <div>
        <ContextMenuItem
          {...(switchId ? { id: `${switchId}-switch` } : {})}
          extraContent={this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          icon={icon}
          label={label}
          mobile={mobile}
          onClick={this.handleClick}
        />

        <Collapse in={this.state.open} timeout="auto">
          <Scrollbars autoHeight={true} autoHeightMax={200}>
            <List component="div" disablePadding>
              {items.map(item => (
                <ContextMenuItem
                  {...(switchId ? { id: `${switchId}-switch-${item.id}` } : {})}
                  icon={<CheckIcon />}
                  iconStyle={{ visibility: currentItemId === item.id ? 'visible' : 'hidden' }}
                  key={item.id}
                  label={item.name}
                  mobile={mobile}
                  onClick={() => onMenuItemClick(item.id)}
                  secondaryLevel={true}
                />
              ))}
            </List>
          </Scrollbars>
        </Collapse>
      </div>
    );
  }
}

CollapseSwitchMenu.propTypes = {
  currentItemId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mobile: PropTypes.bool,
  switchId: PropTypes.string,
};

CollapseSwitchMenu.defaultProps = {
  switchId: '',
  icon: null,
  label: '',
  mobile: false,
};

export default CollapseSwitchMenu;
