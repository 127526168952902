import {
  getParcelListPage,
  getParcelListOrder,
  getParcelListOrderBy,
  getParcelListRowsPerPage,
  getParcelListTextFilter,
  getParcelListAdvancedFilterZones,
} from '../selectors/parcelList.selectors';

import * as sortOrder from '../../../../shared/constants/sortOrder.constants';

import {
  getParcelsSearch,
  resetParcels as resetParcelsApi,
  resetParcel as resetParcelApi,
  getParcelsStatistics,
  resetParcelsStatistics as resetParcelsStatisticsApi,
  getParcelsFertilization,
} from '../../../../shared/api/core/parcels/parcels.api';
import {
  getZonesStatistics,
  getZones,
  resetZonesStatistics as resetZonesStatisticsApi,
  resetZones as resetZonesApi,
} from '../../../../shared/api/core/zones/zones.api';

export const fetchParcels = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    page: getParcelListPage(state),
    order: getParcelListOrder(state),
    orderBy: getParcelListOrderBy(state),
    rowsPerPage: getParcelListRowsPerPage(state),
    textFilter: getParcelListTextFilter(state),
    zoneIds: getParcelListAdvancedFilterZones(state).map(v => v.id),
  };
  return dispatch(getParcelsSearch(params));
};

export const resetParcels = () => dispatch => {
  dispatch(resetParcelsApi());
};

export const resetParcel = () => dispatch => {
  dispatch(resetParcelApi());
};

export const fetchParcelsStatistics = () => dispatch => dispatch(getParcelsStatistics());

export const fetchParcelsFertilization = parcels => dispatch => {
  const parcelIds = parcels.map(parcel => parcel.id).join(',');
  dispatch(getParcelsFertilization(parcelIds));
};

export const fetchZonesStatistics = () => dispatch => {
  dispatch(getZones({ sortDir: sortOrder.DESC, sortCol: 'parcelCount' }, 'statistics'));
  dispatch(getZonesStatistics('statistics'));
};

export const resetStatistics = () => dispatch => {
  dispatch(resetParcelsStatisticsApi());
  dispatch(resetZonesStatisticsApi('statistics'));
  dispatch(resetZonesApi('statistics'));
};
