import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DeviceActivityInfo from '../../../shared/components/specific/DeviceActivityInfo/DeviceActivityInfo';
import LocationCoordinatesInfo from '../../../shared/components/specific/LocationCoordinatesInfo/LocationCoordinatesInfo';
import SensorsService from '../../../shared/services/Sensors.service';
import NodeLocationGroupsInfo from '../NodeLocationGroupsInfo/NodeLocationGroupsInfo';

const style = theme => ({
  wrapper: {
    fontSize: 13,
    display: 'flex',
    color: theme.palette.grey[500],
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  rowItem: {
    display: 'flex',
    marginRight: 12,
    alignItems: 'center',
  },
});

function NodeLocationInfo(props) {
  const { classes, nodeLocation } = props;
  const isActive = SensorsService.isActive(nodeLocation);
  const isHistoric = SensorsService.isHistoric(nodeLocation);
  const hasZeroCoordinates = SensorsService.hasZeroCoordinates(nodeLocation);
  const isErrorOrTransfer = SensorsService.isErrorOrTransfer(nodeLocation);

  return (
    <div className={classes.wrapper}>
      <span className={classes.rowItem} data-test="location-activity-range">
        <DeviceActivityInfo
          dateFrom={nodeLocation.from}
          dateTo={nodeLocation.to}
          isActive={isActive}
          isHistoric={isHistoric}
        />
      </span>
      <span className={classes.rowItem} data-test="node-location">
        <LocationCoordinatesInfo
          geometry={nodeLocation.geometry}
          locationOn={isActive || (isErrorOrTransfer && !hasZeroCoordinates)}
        />
      </span>
      {nodeLocation.groups && nodeLocation.groups.length ? (
        <span className={classes.rowItem} data-test="location-groups">
          <NodeLocationGroupsInfo nodeLocation={nodeLocation} />
        </span>
      ) : (
        ''
      )}
    </div>
  );
}

NodeLocationInfo.propTypes = {
  nodeLocation: PropTypes.object,
  classes: PropTypes.object,
};

NodeLocationInfo.defaultProps = {
  nodeLocation: {},
  classes: {},
};

export default withStyles(style)(NodeLocationInfo);
