import React, { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../../../shared/components/common/CfDialog/CfDialog';

const useStyles = makeStyles({
  note: {
    marginBottom: 20,
  },
  noteHeader: {
    fontWeight: 700,
    marginRight: 5,
  },
});

interface Props {
  onAccept: () => void;
  onClose: () => void;
  opened: boolean;
}

const SensorAdminAssignBaseConfirm: FC<Props> = ({
  onAccept,
  onClose,
  opened = false,
}) => {
  const classes = useStyles();

  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.edit" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={onAccept}
      onCancel={onClose}
      onClose={onClose}
      opened={opened}
      title={<FormattedMessage id="SensorsAdmin.changeBaseConfirm" />}
  >
      <div>
        <div className={classes.note}> <FormattedMessage id="SensorsAdmin.changeBaseConfirm.note1" /> </div>
        <div>
          <span className={classes.noteHeader}><FormattedMessage id="SensorsAdmin.changeBaseConfirm.note2.header" /></span>
          <span><FormattedMessage id="SensorsAdmin.changeBaseConfirm.note2" /></span>
        </div>
        <div>
          <span className={classes.noteHeader}><FormattedMessage id="SensorsAdmin.changeBaseConfirm.note3.header" /></span>
          <span><FormattedMessage id="SensorsAdmin.changeBaseConfirm.note3" /></span>
        </div>
      </div>
    </CfDialog>
  );
};

export default SensorAdminAssignBaseConfirm;
