import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const styles = theme => ({
  detailRowLabel: {
    color: theme.palette.grey[500],
  },
  detailRowValue: {
    fontWeight: 500,
  },
  div: {
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
  },
  parcelDetail: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 10,
  },
});

function ParcelDetailSection(props) {
  const { classes, dense, label, translationId, valueBold, valueItem } = props;
  const marginStyle = dense ? { marginRight: 5 } : { marginRight: 15 };
  return (
    <div className={classes.parcelDetail} data-test="parcel-detail">
      <div className={classes.div} style={marginStyle}>
        <div className={props.classes.detailRowLabel} data-test="label">
          {translationId ? <FormattedMessage id={translationId} /> : label}
        </div>
      </div>
      <div className={classes.div} style={marginStyle}>
        <div className={valueBold ? props.classes.detailRowValue : {}} data-test="value">
          {valueItem || '\u00A0'}
        </div>
      </div>
    </div>
  );
}

ParcelDetailSection.propTypes = {
  classes: PropTypes.object.isRequired,
  translationId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  valueItem: PropTypes.any,
  valueBold: PropTypes.bool,
  dense: PropTypes.bool,
};

ParcelDetailSection.defaultProps = {
  translationId: null,
  label: '\u00A0',
  valueItem: '\u00A0',
  valueBold: true,
  dense: false,
};

export default injectIntl(withStyles(styles)(ParcelDetailSection));
