import React from 'react';

import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';

import ActionParcelSubtractableAreasSection
  from '../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection';
import SubtractableAreaWithRadioButton
  from '../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton';

function WaterParcelSubtractableAreas(props) {
  const { isEditing, maxValue, parcelId, parcelIds, parcelIndex } = props;
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.water`}>
      {({ form, push }) => (
        <ActionParcelSubtractableAreasSection
          calculateAndGetSubtractableArea={props.calculateAndGetSubtractableArea}
          isEditing={isEditing}
          maxValue={maxValue}
          parcelId={parcelId}
          parcelIds={parcelIds}
          subtractableAreas={form.values.parcels[parcelIndex].subtractableAreas.water}
          subtractableAreaType={'Water'}
          handleAdd={(value) => {
            push(value);
            form.setFieldValue(
              `parcels.${props.parcelIndex}.subtractableAreas.waterChecked`, value.restrictionValue);
          }}
        >
          <Field
            component={SubtractableAreaWithRadioButton}
            isEditing={isEditing}
            name={`parcels.${parcelIndex}.subtractableAreas.waterChecked`}
            options={form.values.parcels[parcelIndex].subtractableAreas.water}
          />
        </ActionParcelSubtractableAreasSection>
      )}
    </FieldArray>
  );
}

WaterParcelSubtractableAreas.propTypes = {
  isEditing: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  calculateAndGetSubtractableArea: PropTypes.func.isRequired,
};

WaterParcelSubtractableAreas.defaultProps = {
  isEditing: false,
};

export default WaterParcelSubtractableAreas;
