import * as types from './geometry.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    geometry: {},
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_PARCEL_GEOMETRY_BY_ID:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_PARCEL_GEOMETRY_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        geometry: action.payload,
      };
    case types.GET_PARCEL_GEOMETRY_BY_ID_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_PARCEL_GEOMETRY:
      return {
        ...state,
        isFetching: false,
        error: {},
        geometry: {},
      };

    default:
      return state;
  }
};
