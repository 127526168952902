import React, { FC, useEffect, useState } from 'react';

import TableBody from '@mui/material/TableBody';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  getDevicesOrder,
  getDevicesOrderBy,
  getDevicesPage,
  getDevicesRowsPerPage,
  getDevicesSelected,
  getDevicesSelectedOnPage,
  Namespace,
} from '../../selectors/devices.common.selectors';

import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import withFarm from '../../../shared/hocs/context/withFarm';
import { COLOR_GREY } from '../../../theme';

import DeviceTableRow from './components/DeviceTableRow';

import { IrrigationState } from '../../../reducers/irrigation.reducer.types';
import { Device } from '../../../shared/api/irrigation/devices/devices.types';

interface OwnProps {
  devices: Device[];
  handleContentRefresh: () => void;
  handleHover: (id: string | null) => void;
  isFetching: boolean;
  namespace: Namespace;
  selectableItems?: boolean;
  totalCount: number;
}

interface Props extends OwnProps {
  farm: {
    id: string;
  };
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
}

const statusColumn = {
  status: { sortable: false, label: <FormattedMessage id="common.state" />, style: { width: '70px' } },
};

const defaultColumns = {
  name: {
    sortable: true,
    label: (
      <div>
        <div>
          <FormattedMessage id="common.name" />
        </div>
        <div style={{ color: COLOR_GREY[400] }}>
          <FormattedMessage id="common.id" />
        </div>
      </div>
    ),
    style: {
      paddingLeft: '18px',
    },
  },
  currentValveStatus: {
    sortable: false,
    label: <FormattedMessage id="IrrigationList.currentValveStatus" />,
  },
  nextAction: {
    sortable: false,
    label: <FormattedMessage id="IrrigationList.nextAction" />,
  },
  manualAction: {
    sortable: false,
    label: <FormattedMessage id="IrrigationList.manualAction" />,
    style: { width: '90px' },
  },
};

const IrrigationDevicesTable: FC<Props> = ({
  devices,
  farm,
  handleContentRefresh,
  handleHover,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  rowsPerPage,
  selectableItems,
  selected,
  selectedOnPage,
  totalCount,
}) => {
  const isEmpty = !devices.length;
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    if (!selectableItems) {
      setColumns({ ...statusColumn, ...defaultColumns });
    }
  },
  [selectableItems]);

  useEffect(() => {
    handleContentRefresh();
  }, [page, rowsPerPage, order, orderBy, handleContentRefresh]);

  return (
    <CfTableWrapper testId={'table'}>
      <CfTableHead
        columns={columns}
        items={devices}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selectableItems ? selected : undefined}
        selectedOnPage={selectableItems ? selectedOnPage : undefined}
      />

      {isFetching && <CfTableBodyLoader columns={columns} />}
      {isEmpty && <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} /> }

      {!isEmpty && <TableBody>
        {devices.map(device => (
          <DeviceTableRow
            device={device}
            farmId={farm.id}
            handleContentRefresh={handleContentRefresh}
            handleHover={handleHover}
            isIrrigating={device.isIrrigating}
            key={device.id}
            namespace={namespace}
            selected={selectableItems ? selected : undefined}
          />))}
      </TableBody>}

      <CfTableFooter count={totalCount} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: IrrigationState, ownProps: OwnProps) => ({
  page: getDevicesPage(state, ownProps),
  rowsPerPage: getDevicesRowsPerPage(state, ownProps),
  order: getDevicesOrder(state, ownProps),
  orderBy: getDevicesOrderBy(state, ownProps),
  selected: getDevicesSelected(state, ownProps),
  selectedOnPage: getDevicesSelectedOnPage(state, ownProps),
});

export default connect(mapStateToProps)(withFarm()(IrrigationDevicesTable));
