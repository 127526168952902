import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, formValueSelector } from 'redux-form';

import { getValidationFailure } from '../../../../../shared/api/core/actions/actions.selectors';
import { getParcelsArea, getDriftClass } from '../../../shared/selectors/actions.selectors';

import {
  getParcelExpectedFertilization,
  resetParcelExpectedFertilization,
} from '../../../../../shared/api/core/fertilzation/fertilization.api';
import CfCheckbox from '../../../../../shared/components/form/CfCheckbox/CfCheckbox';
import CfCheckboxLabel from '../../../../../shared/components/form/CfCheckbox/CfCheckboxLabel';
import unitsMock from '../../../../../shared/mocks/units.mock.json';
import ActionDate from '../../../shared/components/ActionDate/ActionDate';
import ActionFormButtons from '../../../shared/components/ActionFormButtons/ActionFormButtons';
import ActionFormHeader from '../../../shared/components/ActionFormHeader/ActionFormHeader';
import ActionNote from '../../../shared/components/ActionNote/ActionNote';
import ActionTargetCrop from '../../../shared/components/ActionTargetCrop/ActionTargetCrop';
import ActionValidationInfo from '../../../shared/components/ActionValidationInfo/ActionValidationInfo';
import ActionParcelsControl from '../../../shared/containers/ActionParcelsControl/ActionParcelsControl';
import ChemFertSelector from '../../../shared/containers/ChemFertSelector/ChemFertSelector';
import actionForm from '../../../shared/hocs/actionForm';
import {
  targetCropChanged,
  parcelsCountChanged,
  parcelsAreaChanged,
  fertilizersUnitChanged,
  fertilizationTypeChanged,
  resolveTargetCrop,
  fertilizersDosageChanged,
  getFertilizers,
} from '../../../shared/misc/action.helpers';
import ActionToMapper from '../../../shared/services/ActionToMapper.service';
import { isValidCatchCropApplication, getInitialValues, validate, warn } from '../../misc/eph.helpers';
import EphExpensesControl from '../EphExpensesControl/EphExpensesControl';

const FORM_NAME = 'eph';
const defaultUnit = unitsMock.units[1];

/*
 * KEEP THE COMMENTS UP TO DATE!
 */
export class Eph extends Component {
  componentDidUpdate(prevProps) {
    const {
      existingAction,
      expenses: prevExpenses,
      isCatchCrop: prevIsCatchCrop,
      isStrawDecay: prevIsStrawDecay,
      parcels: prevParcels,
      parcelsArea: prevParcelsArea,
      targetCrop: prevTargetCrop,
    } = prevProps;

    const {
      expenses: newExpenses,
      fieldsManuallyChanged,
      formValues: newFormValues,
      isCatchCrop: newIsCatchCrop,
      isStrawDecay: newIsStrawDecay,
      parcels: newParcels,
      parcelsArea: newParcelsArea,
      targetCrop: newTargetCrop,
    } = this.props;

    // Comparison booleans to detect the change
    const targetCropDiff = targetCropChanged(prevTargetCrop, newTargetCrop);
    const targetCropManualDiff = Boolean(fieldsManuallyChanged.targetCrop);
    const parcelsCountDiff = parcelsCountChanged(prevParcels, newParcels);

    // Values calculated based on new inputs
    const resolvedTargetCrop = resolveTargetCrop(newParcels);
    const resolvedCatchCrop = isValidCatchCropApplication(newParcels, newTargetCrop);

    /*
     * Touch target crop in order to get validated. Do when:
     * 1) prev target crop was not set
     * 2) new target crop is set
     * 3) action is already existing
     */
    if (existingAction && !prevTargetCrop && prevTargetCrop !== newTargetCrop) {
      this.props.touch('targetCrop');
    }

    /*
     * Update catch crop when:
     * 1) target crop changed
     * 2) or parcels count changed
     * 3) and resolved target crop doest match current catch crop
     */
    if (targetCropDiff || parcelsCountDiff) {
      if (isValidCatchCropApplication(newParcels, newTargetCrop) !== prevIsCatchCrop) {
        this.props.change('isCatchCrop', resolvedCatchCrop);
      }
    }

    /*
     * Update target crop when:
     * 1) parcels count changed
     * 3) and new target crop is not set or is not manually edited yet
     */
    if (!targetCropManualDiff && !newTargetCrop && parcelsCountDiff && newParcels.length) {
      this.props.change('targetCrop', resolvedTargetCrop);
    }

    /*
     * Set target crop to null when:
     * 1) parcels count changed
     * 2) and there are no parcels
     * 3) and target crop was not changed manually yet
     */
    if (parcelsCountDiff && !newParcels.length && !targetCropManualDiff) {
      this.props.change('targetCrop', null);
      this.props.untouch('targetCrop');
    }

    /*
     * Recalculates expected fertilization on all selected parcels when:
     * 1) total dose on fertilizers changed
     * 2) parcels area changed - it includes subtractions
     * 3) fertilization type changed
     * 4) fertilization unit changed
     * 5) and target crop is set or resolved
     * 6) and there is at least one fertilizer
     */
    const fertDosageDiff = fertilizersDosageChanged(prevExpenses, newExpenses);
    const parcelsAreaDiff = parcelsAreaChanged(prevParcelsArea, newParcelsArea);
    const fertTypeDiff = fertilizationTypeChanged(prevIsCatchCrop, newIsCatchCrop, prevIsStrawDecay, newIsStrawDecay);
    const fertUnitDiff = fertilizersUnitChanged(prevExpenses, newExpenses);
    const newFertilizers = getFertilizers(newExpenses);
    if (
      (fertDosageDiff || parcelsAreaDiff || fertTypeDiff || fertUnitDiff) &&
      (newTargetCrop || resolvedTargetCrop) &&
      newFertilizers.length > 0
    ) {
      this.updateExpectedNitrogen({ ...newFormValues, targetCrop: { ...(newTargetCrop || resolvedTargetCrop) } });
    }

    if (fertDosageDiff && !newFertilizers.length) {
      this.props.resetParcelExpectedFertilization();
    }
  }

  updateExpectedNitrogen(values) {
    this.props.getParcelExpectedFertilization(ActionToMapper.createNewEphActionTo(values));
  }

  render() {
    const {
      actionDate,
      driftClass,
      existingAction,
      expenses,
      farmId,
      formErrors: { targetCrop: invalidCatchCropError },
      formName,
      handleSubmit,
      initParcelIds,
      initZoneIds,
      isCatchCrop,
      isEditing,
      isExisting,
      isPristine,
      isSubmitting,
      ngGoToActions,
      onCancel,
      onEditingStart,
      onFieldManualChange,
      onReset,
      parcels,
      parcelsArea,
      targetCrop,
      validationFailure,
    } = this.props;

    return (
      <div>
        <ActionFormHeader
          farmId={farmId}
          isDisabled={isEditing}
          isExisting={isExisting}
          ngGoToActions={ngGoToActions}
          onClick={onEditingStart}
          title={`${startCase(formName).replace(/\s/g, '')}.title`}
        />
        <Grid alignItems="center" container justifyContent="center" spacing={0}>
          <Grid item lg={8} md={10} xs={11}>
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="center" spacing={5}>
                <Grid item xs={12}>
                  <FieldArray
                    actionDate={actionDate}
                    component={ActionParcelsControl}
                    driftClass={driftClass}
                    existingAction={existingAction}
                    expenses={expenses}
                    farmId={farmId}
                    formName={FORM_NAME}
                    initParcelIds={initParcelIds}
                    initZoneIds={initZoneIds}
                    isEditing={isEditing}
                    name="parcels"
                    parcelsArea={parcelsArea}
                    targetCrop={targetCrop}
                  />
                </Grid>
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <ActionDate disabled={!isEditing} />
                  <ActionTargetCrop disabled={!isEditing} onChange={() => onFieldManualChange('targetCrop')} />
                  {!invalidCatchCropError && parcels.length > 0 && (
                    <div>
                      {
                        <CfCheckboxLabel
                          style={isEditing ? { color: 'inherit' } : {}}
                          control={
                            <Field
                              component={CfCheckbox}
                              disabled={isCatchCrop ? true : !isEditing}
                              name={isCatchCrop ? 'isCatchCrop' : 'isStrawDecay'}
                            />
                          }
                          label={
                            <FormattedMessage
                              id={`Eph.${isCatchCrop ? 'catchCropApplication' : 'strawDecayApplication'}`}
                            />
                          }
                        />
                      }
                    </div>
                  )}
                  <ActionNote disabled={!isEditing} />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    component={EphExpensesControl}
                    driftClass={driftClass}
                    expenses={expenses}
                    formName={formName}
                    isEditing={isEditing}
                    name="expenses"
                    parcels={parcels}
                    parcelsArea={parcelsArea}
                    targetCrop={targetCrop}
                    SelectorProps={{
                      component: ChemFertSelector,
                      placeholder: 'ChemFertSelector.placeholder',
                      fertAdditionalProps: {
                        incorporationDay: 'NO',
                        n: 0,
                        p2o5: 0,
                        k2o: 0,
                        doseUnit: defaultUnit,
                        dosageUnit: defaultUnit,
                      },
                      chemAdditionalProps: {
                        pests: [],
                        driftClass: 'DRIFT_NONE',
                        doseUnit: defaultUnit,
                        dosageUnit: defaultUnit,
                      },
                    }}
                  />
                </Grid>
                <ActionValidationInfo validationDetails={validationFailure} />
              </Grid>
            </form>
          </Grid>
        </Grid>
        {isEditing && (
          <ActionFormButtons
            formName={formName}
            isExisting={isExisting}
            isPristine={isPristine}
            isSubmitting={isSubmitting}
            onCancel={onCancel}
            onReset={onReset}
            withExport={false}
          />
        )}
      </div>
    );
  }
}

Eph.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  targetCrop: PropTypes.object,
  actionDate: PropTypes.object,
  validationFailure: PropTypes.object,
  existingAction: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  formName: PropTypes.string.isRequired,
  isCatchCrop: PropTypes.bool,
  isStrawDecay: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  getParcelExpectedFertilization: PropTypes.func.isRequired,
  resetParcelExpectedFertilization: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onEditingStart: PropTypes.func.isRequired,
  expenses: PropTypes.array,
  parcels: PropTypes.array,
  driftClass: PropTypes.string,
  initParcelIds: PropTypes.array,
  initZoneIds: PropTypes.array,
  farmId: PropTypes.string.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  onFieldManualChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line
  fieldsManuallyChanged: PropTypes.object.isRequired,
  ngGoToActions: PropTypes.func.isRequired,
};

Eph.defaultProps = {
  formValues: {},
  existingAction: null,
  validationFailure: null,
  initParcelIds: [],
  initZoneIds: [],
  targetCrop: null,
  parcels: [],
  expenses: [],
  driftClass: '',
  actionDate: {},
  isCatchCrop: false,
  isStrawDecay: false,
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  actionDate: selector(state, 'actionDate'),
  parcels: selector(state, 'parcels'),
  expenses: selector(state, 'expenses'),
  targetCrop: selector(state, 'targetCrop'),
  isCatchCrop: selector(state, 'isCatchCrop'),
  isStrawDecay: selector(state, 'isStrawDecay'),
  parcelsArea: getParcelsArea(FORM_NAME, state),
  driftClass: getDriftClass(FORM_NAME, state),
  validationFailure: getValidationFailure(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getParcelExpectedFertilization,
      resetParcelExpectedFertilization,
    },
    dispatch,
  );

export default compose(
  actionForm({
    formName: FORM_NAME,
    validate,
    warn,
    createActionToFnc: ActionToMapper.createNewEphActionTo,
    initialValues: getInitialValues(),
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(Eph);
