import React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';

import ExportButton from '../../../../../shared/components/common/ExportButton/ExportButton';
import withPopover from '../../../../../shared/hocs/withPopover';

const styles = theme => ({
  storeExport: {
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    marginLeft: 15,
  },
});

const formats = ['pdf', 'xlsx'];

export function StoreExport(props) {
  const { anchorEl, classes, handleExport, handlePopoverClose, handlePopoverOpen, isOpen, storeId } = props;
  return (
    <div className={classes.storeExport}>
      <ExportButton handleClick={handlePopoverOpen} />
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {formats.map(format => (
          <MenuItem
            key={format}
            onClick={() => {
              handleExport(format, storeId);
              handlePopoverClose();
            }}
          >
            {format.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

StoreExport.propTypes = {
  classes: PropTypes.object.isRequired,
  handleExport: PropTypes.func,
  storeId: PropTypes.number,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

StoreExport.defaultProps = {
  handleExport: () => {},
  storeId: null,
  anchorEl: null,
};

export default compose(withStyles(styles), withPopover)(StoreExport);
