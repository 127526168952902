import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ToolbarIconBtn from '../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn';
import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import Weather from '../../services/Weather.service';

class WeatherSwitcher extends Component {
  handleWeatherTypeChange = (weatherType) => {
    this.props.setWeatherType(weatherType);
  };

  render() {
    const { weatherType } = this.props;
    const weatherTypesConfig = Weather.getWeatherTypesConfig();

    return (
      <ToolbarSection>
        {weatherTypesConfig.map(typeConfig => (
          <ToolbarIconBtn
            active={weatherType === typeConfig.id}
            aria-label={typeConfig.translationId}
            callback={() => this.handleWeatherTypeChange(typeConfig.id)}
            data-test={typeConfig.id}
            icon={typeConfig.icon}
            key={typeConfig.id}
            tooltipTitle={typeConfig.translationId}
          />
        ))}
      </ToolbarSection>
    );
  }
}

WeatherSwitcher.propTypes = {
  weatherType: PropTypes.string.isRequired,
  setWeatherType: PropTypes.func.isRequired,
};

export default WeatherSwitcher;
