import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ExportIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 12.1 14" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <polygon points="12.1,5 9.1,5 9.1,0 3.1,0 3.1,5 0,5 6.1,11 " />
      <rect className="st0" height="2" width="12" x="0.1" y="12" />
    </svg>
  </SvgIcon>
);

export default ExportIcon;
