import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ElectricalConductivityIcon = props => (
  <SvgIcon {...props}>
    <path d="M21.9 13.4199C21.8 12.6199 21.5 11.9199 21 11.2199C20.4 10.3199 19.6 9.41987 18.6 8.81987C18.4 8.71987 18.2 8.51987 18 8.41987H21.8V5.61987H17C16.9 5.61987 15.4 5.61987 14.6 5.81987C13.4 6.01987 12.2 6.51987 11.2 7.21987C10.3 7.81987 9.59999 8.71987 9.09999 9.61987C8.39999 11.0199 8.19999 12.6199 8.59999 14.1199C8.89999 15.6199 9.59999 17.0199 10.6 18.2199C11.3 19.0199 12.2 19.5199 13.1 19.9199C13.8 20.2199 14.6 20.3199 15.4 20.3199C16 20.3199 16.6 20.2199 17.2 20.1199C19.2 19.6199 20.9 18.2199 21.6 16.2199C22 15.0199 22.1 14.3199 21.9 13.4199ZM11.5 13.5199C11.4 12.9199 11.4 12.2199 11.5 11.6199C11.7 9.91987 12.9 8.61987 14.5 8.31987C14.9 8.21987 15.3 8.21987 15.7 8.21987C15.7 8.21987 15.7 8.21987 15.8 8.21987C15.9 8.21987 16 8.21987 16.1 8.21987C16.8 8.91987 17.4 9.71987 18 10.6199C18.5 11.4199 18.8 12.3199 18.8 13.2199V13.9199C18.8 14.8199 18.7 15.8199 18.3 16.6199C18 17.3199 17.6 17.8199 17.1 18.2199C16.7 18.5199 16.2 18.6199 15.7 18.6199C15.4 18.6199 15.1 18.5199 14.8 18.4199C13.9 18.1199 13.2 17.5199 12.7 16.7199C12.1 15.9199 11.7 14.8199 11.5 13.5199Z" fill="#6A00B5" />
    <path d="M6.5 11.5198H2V13.0198H6.4C6.4 12.6198 6.4 12.0198 6.5 11.5198Z" fill="#6A00B5" />
    <path d="M6.6 14.5198H2V16.0198H7.1C6.9 15.6198 6.8 15.1198 6.6 14.5198C6.6 14.6198 6.6 14.6198 6.6 14.5198Z" fill="#6A00B5" />
    <path d="M2 17.4197V18.9197H8.7C8.3 18.4197 8 17.9197 7.7 17.4197H2Z" fill="#6A00B5" />
  </SvgIcon>
);

export default ElectricalConductivityIcon;
