import React, { FC, useContext, useMemo, useState } from 'react';

import { Theme } from '@mui/material';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getBaraniAvailableBases, getBaraniAvailableBasesIsFetching } from '../../../../../shared/api/iot/nodes/nodes.selectors';

import { fetchNodes, getAvailableBaraniBases } from '../../actions/sensors.actions';

import { deleteAdminBaraniUnassignApi, putAdminBaraniAssignApi, resetAdminAvailableBaraniBases } from '../../../../../shared/api/iot/nodes/nodes.api';
import CfSwitcherButton from '../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton';
import { SnackbarContext } from '../../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import withPopover from '../../../../../shared/hocs/withPopover';
import { AsyncFn, Thunk } from '../../../../../types';

import AdminBaraniBaseSelector from './components/AdminBaraniBaseSelector';
import SensorAdminAssignBaseConfirm from './components/SensorAdminAssignBaseConfirm';

import { BaraniAvailableBases, SensorsAdminNode, SensorsAdminState } from '../../admin.sensors.types';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 350,
    height: 350,
    overflow: 'hidden',
  },
  switcherLabel: {
    lineHeight: 1.2,
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
  button: {
    fontSize: 13,
    marginLeft: -9,
  },
}));

interface Props {
  anchorEl?: null | Element,
  assignBaraniBase: (moduleId: string, baseId: string) => void,
  fetchNodes: () => void,
  getAvailableBaraniBases: (filter: string, params: string, moduleId: string | null) => void,
  handlePopoverClose: () => void,
  handlePopoverOpen: () => void,
  isBarani: boolean,
  isFetchingBaraniBases: boolean,
  isOpen: boolean,
  item: SensorsAdminNode,
  resetAdminAvailableBaraniBases: () => void,
  suggestions?: BaraniAvailableBases[],
  unassignBaraniBase: (moduleId: string) => void
}

const SensorAdminAssignBase: FC<Props> = ({
  anchorEl = null,
  isBarani,
  getAvailableBaraniBases,
  assignBaraniBase,
  unassignBaraniBase,
  handlePopoverClose,
  handlePopoverOpen,
  isFetchingBaraniBases,
  isOpen,
  fetchNodes,
  item,
  resetAdminAvailableBaraniBases,
  suggestions = [],
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const showSnackbar = useContext(SnackbarContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [baseToAssign, setBaseToAssign] = useState<BaraniAvailableBases>({} as BaraniAvailableBases);

  const isBaseAssigned = item.base !== undefined;

  const mergedSuggestions = useMemo(() => ([
    {
      id: '0',
      externalId: intl.formatMessage({ id: 'SensorAdmin.changeBase.none' }),
      assignable: true,
    },
    ...suggestions,
  ]), [suggestions, intl]);

  const onSuggestionSelect = (baseToAssign: BaraniAvailableBases) => {
    setBaseToAssign(baseToAssign);
    setShowConfirmDialog(true);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
    resetAdminAvailableBaraniBases();
  };

  const handleDialogClose = () => {
    setBaseToAssign({} as BaraniAvailableBases);
    setShowConfirmDialog(false);
  };

  const handleDialogAccept = () => {
    const unAssignBase = baseToAssign.id === '0';
    if (unAssignBase) {
      (unassignBaraniBase as AsyncFn<string>)(item.id).then((res) => {
        if (res.error) {
          showSnackbar({ message: <FormattedMessage id="SensorsAdmin.changeBase.error" />, isError: true });
          return;
        }
        fetchNodes();
        showSnackbar({ message: <FormattedMessage id="SensorsAdmin.changeBase.success" values={{ externalId: item.externalId }} />, isSuccess: true });
      });
    } else {
      (assignBaraniBase as AsyncFn<string, string>)(item.id, baseToAssign.id).then((res) => {
        if (res.error) {
          showSnackbar({ message: <FormattedMessage id="SensorsAdmin.changeBase.error" />, isError: true });
          return;
        }
        fetchNodes();
        showSnackbar({ message: <FormattedMessage id="SensorsAdmin.changeBase.success" values={{ externalId: item.externalId }} />, isSuccess: true });
      });
    }
    handleDialogClose();
  };

  return (
    <div>
      <CfSwitcherButton
        disabled={!isBarani}
        onClick={handlePopoverOpen}
        transparent={true}
        classes={{
          custom: classnames(
            classes.button,
          ),
        }}
        >
        <span
          className={classnames(
            classes.switcherLabel,
          )}
          >
          {isBaseAssigned ? item?.base?.externalId : <FormattedMessage id={'SensorAdmin.changeBase.none'} />}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={onSuggestionClear}
        open={isOpen}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 350,
          vertical: 0,
        }}
        >
        <AdminBaraniBaseSelector
          isFetching={isFetchingBaraniBases}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={mergedSuggestions}
          getAvailableBaraniBases={(filter: string, params: string) =>
            getAvailableBaraniBases(filter, params, item.id)}
          />
      </Popover>
      <SensorAdminAssignBaseConfirm
        onAccept={handleDialogAccept}
        onClose={handleDialogClose}
        opened={showConfirmDialog}
        />
    </div>
  );
};

const mapStateToProps = (state: SensorsAdminState) => ({
  isFetchingBaraniBases: getBaraniAvailableBasesIsFetching(state),
  suggestions: getBaraniAvailableBases(state),
});

const mapDispatchToProps = (dispatch: Thunk<SensorsAdminState>) =>
  bindActionCreators(
    {
      fetchNodes,
      getAvailableBaraniBases,
      resetAdminAvailableBaraniBases,
      assignBaraniBase: putAdminBaraniAssignApi,
      unassignBaraniBase: deleteAdminBaraniUnassignApi,
    },
    dispatch,
  );

export default withPopover(connect(mapStateToProps, mapDispatchToProps)(SensorAdminAssignBase));
