import moment from 'moment';

import * as bufferTypes from '../actions/buffer/buffer.constants';
import * as drawTypes from '../actions/draw/draw.constants';
import * as editorTypes from '../actions/editor/editor.constants';
import * as measurementTypes from '../actions/measurement/measurement.constants';
import * as mergeTypes from '../actions/merge/merge.constants';

import { BUFFER_TYPES } from '../actions/buffer/bufferTypes';

export default () => (
  state = {
    tool: null,
    stage: null,
    error: null,
    hoveredId: null,
    isMapDragging: false,
    selected: [],
    hint: {
      action: null,
      refresh: 0,
      data: null,
    },
    merge: {
      reachableIds: [],
    },
    draw: {
      landUseFilter: null,
    },
    validFrom: moment()
      .startOf('day')
      .add(moment().utcOffset(), 'm')
      .toISOString(),
    validFromError: null,
    buffer: {
      type: BUFFER_TYPES.PARTIAL,
      size: null,
    },
    measurement: {
      items: [],
    },
  },
  action = {},
) => {
  switch (action.type) {
    case editorTypes.EDITOR_SET_TOOL:
      return {
        ...state,
        tool: action.tool,
      };
    case editorTypes.EDITOR_SET_STAGE:
      return {
        ...state,
        stage: action.stage,
      };
    case editorTypes.EDITOR_SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case editorTypes.EDITOR_SET_HOVERED_ID:
      return {
        ...state,
        hoveredId: action.hoveredId,
      };
    case editorTypes.EDITOR_SET_MAP_DRAGGING:
      return {
        ...state,
        isMapDragging: action.isMapDragging,
      };
    case editorTypes.EDITOR_SET_SELECTED:
      return {
        ...state,
        selected: updateArray(state.selected, action.selected, action.rewrite),
      };
    case editorTypes.EDITOR_HINT_SET_ACTION:
      return {
        ...state,
        hint: {
          data: action.data || state.hint.data,
          action: action.action,
          refresh: state.hint.refresh + 1,
        },
      };
    case editorTypes.EDITOR_SET_VALID_FROM:
      return {
        ...state,
        validFrom: action.validFrom,
      };
    case editorTypes.EDITOR_SET_VALID_FROM_ERROR:
      return {
        ...state,
        validFromError: action.error,
      };
    case mergeTypes.MERGE_UPDATE_REACHABLE_PARCELS:
      return {
        ...state,
        merge: {
          ...state.merge,
          reachableIds: updateArray(state.merge.reachableIds, action.parcelIds, action.rewrite),
        },
      };
    case drawTypes.DRAW_SET_LANDUSE_FILTER:
      return {
        ...state,
        draw: {
          ...state.draw,
          landUseFilter: action.landUseFilter,
        },
      };
    case bufferTypes.BUFFER_SET_SIZE:
      return {
        ...state,
        buffer: {
          ...state.buffer,
          size: action.size,
        },
      };
    case bufferTypes.BUFFER_SET_TYPE:
      return {
        ...state,
        buffer: {
          ...state.buffer,
          type: action.bufferType,
        },
      };
    case measurementTypes.MEASUREMENT_ADD_NEW:
      return {
        ...state,
        measurement: {
          ...state.measurement,
          items: [action.measurement, ...state.measurement.items],
        },
      };
    case measurementTypes.MEASUREMENT_UPDATE:
      return {
        ...state,
        measurement: {
          ...state.measurement,
          items: state.measurement.items.map(item => {
            if (item.id === action.measurement.id) {
              return action.measurement;
            }
            return item;
          }),
        },
      };
    case measurementTypes.MEASUREMENT_REMOVE:
      return {
        ...state,
        measurement: {
          ...state.measurement,
          items: state.measurement.items.filter(item => item.id !== action.measurementId),
        },
      };
    case measurementTypes.MEASUREMENT_CLEAR_ALL:
      return {
        ...state,
        measurement: {
          ...state.measurement,
          items: [],
        },
      };
    default:
      return state;
  }
};
const updateArray = (oldArray, value, rewrite) => {
  const newArray = Array.isArray(value) ? value : [value];
  return rewrite ? newArray : [...oldArray, ...newArray];
};
