/**
 * Created by brasko on 20.7.2015.
 */


/*@ngInject*/
export default function ModalController($uibModalInstance, config, Api) {
    var vm = this;
    var farmId = config.farmId ? config.farmId : null;

    vm.config = config;
    vm.items = [];

    /* jshint unused:false */
    /* jshint shadow:true */
    vm.confirm = confirm;
    vm.cancel = cancel;

    activate(config.baseStr);

    function activate(baseStr){

        if(baseStr){
            return getItems();
        }
    }

    function getItems(baseStr) {
        var base = Api.setBaseAll(baseStr);
        if(base){
            return Api.getItems(base, farmId)
                .then(function (result) {
                    if (result.data !== undefined) {
                        vm.items = result.data;
                    }
                    return vm.items;
                });
            }
    }

    function confirm() {
        $uibModalInstance.close(vm.config.data);
    }

    function cancel(arg) {
        arg = arg || 'cancel';
        $uibModalInstance.dismiss(arg);
    }



}