import { createSelector } from 'reselect';

import { IRRIGATION_URLS } from '../irrigation.constants';

import { IrrigationState } from '../../reducers/irrigation.reducer.types';

export const getActivePage = (state: IrrigationState) => state.ui.common.activePage;
export const getPrevPage = (state: IrrigationState) => state.ui.common.prevPage;

export const getIsAreasList = createSelector(getActivePage, (activePage) =>
  !!activePage.match(IRRIGATION_URLS.AREAS));

export const getIsAreaDetail = createSelector(getActivePage, (activePage) =>
  !!activePage.match(`${IRRIGATION_URLS.AREA}/`));

export const getIsPageWithAreas = createSelector([getIsAreasList, getIsAreaDetail], (isAreasList, isAreasDetail) =>
  isAreasList || isAreasDetail);
