import React from 'react';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import SendAllIcon from '../../../icons/SendAllIcon';

const useStyles = makeStyles({
  sendAllButton: {
    minWidth: 'unset',
  },
  sendAllIcon: {
    width: 20,
    height: 20,
  },
});

/**
 * Styled button used for sending all.
 * @version 1.0.0
 */
const SendAllButton = ({ badge, handleClick, isDisabled, tooltipTitle }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle}>
      <span>
        <IconButton
          aria-label="Send all"
          className={classes.sendAllButton}
          disabled={isDisabled}
          onClick={handleClick}
          size="large">
          <Badge badgeContent=" " color="error" invisible={!badge} variant="dot">
            <SendAllIcon className={classes.sendAllIcon} />
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
};

SendAllButton.propTypes = {
  /** Function to call after clicking the send all button. For example, a dialog pops up. */
  handleClick: PropTypes.func.isRequired,
  /** Prop for disabling */
  isDisabled: PropTypes.bool,
  /** Label */
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** Show badge */
  badge: PropTypes.bool,
};

SendAllButton.defaultProps = {
  isDisabled: false,
  badge: false,
};

export default SendAllButton;
