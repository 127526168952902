import React, { FC, useState, useEffect } from 'react';

import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { StaticDatePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { FormattedDate } from 'react-intl';

import usePopover from '../../../hooks/usePopover';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 16,
  },
  dateButton: {
    background: theme.palette.grey[300],
    padding: 0,
    borderRadius: '50px',
    boxShadow: 'none',
    marginLeft: 10,
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  dateContent: {
    margin: 'auto 0.5rem',
    paddingTop: 1,
  },
  icon: {
    margin: 'auto 0.75rem auto 0.4rem',
    width: '22px',
  },
  popoverPaper: {
    overflow: 'visible',
    marginTop: 7,
  },
}));

interface Props {
  dateFrom: string;
  setDateFrom: (date?: string) => void;
}

const DateSelector: FC<Props> = ({
  dateFrom,
  setDateFrom,
}) => {
  const classes = useStyles();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } = usePopover();
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment(dateFrom));

  useEffect(() => {
    setDateFrom(moment(selectedDateFrom).startOf('day').toISOString());
  }, [selectedDateFrom, setDateFrom]);

  return (
    <>
      <Button
        className={classes.dateButton}
        data-test={'advanced-calendar'}
        onClick={handlePopoverOpen}
        variant="contained"
      >
        <div className={classes.wrapper}>
          <span className={classes.dateContent} data-test={'telematics-date'}>
            <FormattedDate day="2-digit" month="2-digit" value={dateFrom} year="numeric" />
          </span>
          <InsertInvitationOutlinedIcon className={classes.icon} data-test={'telematics-calendar'} />
        </div>
      </Button>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        >
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              disableFuture
              displayStaticWrapperAs="desktop"
              renderInput={() => <div />}
              value={selectedDateFrom}
              views={['day']}
              onChange={(newValue) => {
                setSelectedDateFrom(newValue as Moment);
                handlePopoverClose();
              }}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </>
  );
};

export default DateSelector;
