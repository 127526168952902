import React from 'react';

import { withStyles } from '@mui/styles';
import { times } from 'lodash';
import PropTypes from 'prop-types';

const styles = theme => ({
  forecastPlaceholder: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    height: '100%',
    alignItems: 'center',
    margin: '0px 20px',
  },
  forecastItemPlaceholder: {
    height: '100%',
    display: 'flex',
    margin: '0px 10px',
    alignItems: 'center',
  },
  placeholderItem: {
    backgroundColor: theme.palette.grey[300],
  },
  placeholderIcon: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    margin: 10,
  },
  placeholderText: {
    height: 18,
    margin: 2,
    borderRadius: 9,
  },
});

function WeatherForecastPlaceholder(props) {
  const { classes } = props;
  return (
    <div className={classes.forecastPlaceholder}>
      {times(8, i => (
        <div className={classes.forecastItemPlaceholder} key={i}>
          <div className={`${classes.placeholderItem} ${classes.placeholderIcon}`} />
          <div>
            <div className={`${classes.placeholderItem} ${classes.placeholderText}`} style={{ width: 45 }} />
            <div className={`${classes.placeholderItem} ${classes.placeholderText}`} style={{ width: 70 }} />
            <div className={`${classes.placeholderItem} ${classes.placeholderText}`} style={{ width: 50 }} />
            <div className={`${classes.placeholderItem} ${classes.placeholderText}`} style={{ width: 37 }} />
          </div>
        </div>
      ))}
    </div>
  );
}

WeatherForecastPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WeatherForecastPlaceholder);
