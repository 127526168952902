import {
  getStage,
  getAvailableIds,
  getSelectedParcels,
  getSelectedParcelIds,
  getAllReachableIds,
  getFilteredReachableIds,
  getSelectedParcelLpisBlock,
  getValidFrom,
} from '../../selectors/editor.selectors';

import {
  editorSetStage,
  editorHintReopen,
  editorHintRefresh,
  editorHintClose,
  editorSetError,
  editorSetSelected,
  handleGenericError,
  showErrorHint,
  getPayload,
  editorClearDrawingError,
} from '../editor/editor.actions';
import { setHoverStyleEL, setMouseMoveEL, setMouseClickEL, removeEL } from '../eventListener/eventListener.actions';
import { zoomToGeometry, setMapFetching } from '../map/map.actions';
import {
  addAreaOverlay,
  addMapHintOverlay,
  removeOverlays,
  updateOverlayPosition,
  removeOverlay,
} from '../overlay/overlay.actions';
import { refreshMergeStyles } from '../style/style.actions';

import * as errors from '../../constants/errors.constants';
import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';
import * as types from './merge.constants';

import {
  mergeGeometries,
  getMergeNeighbors,
  getLpisParcelsByParcelId,
  getParcelGeometryById,
} from '../../../../shared/api/core/geometry/geometry.api';
import Feature from '../../services/Feature.service';

/*
 * STAGE_1 - tool opened, no parcels selected
 * STAGE_2 - tool opened, one parcel selected
 * STAGE_3 - tool opened, at least two parcels selected
 */

let LAST_PARCEL_OVERLAY = null;

export const updateReachableIds = (parcelIds, rewrite = true) => ({
  type: types.MERGE_UPDATE_REACHABLE_PARCELS,
  parcelIds,
  rewrite,
});

export const mergeStart = countryCode => dispatch => {
  dispatch(editorSetStage(stages.STAGE_1));
  dispatch(editorHintReopen());
  dispatch(refreshMergeStyles());
  dispatch(setHoverStyleEL(Feature.isFeature, refreshMergeStyles, 'mergeHoverStyleELKey'));
  dispatch(
    setMouseMoveEL((feature, evt) => onMouseMove(feature, evt, countryCode), refreshMergeStyles, 'mergeHoverHintELKey'),
  );
  dispatch(
    setMouseClickEL(feature => onMouseSelect(feature, countryCode), refreshMergeStyles, 'mergeClickSelectELKey'),
  );
  dispatch(
    setMouseClickEL(feature => onMouseDeselect(feature, countryCode), refreshMergeStyles, 'mergeClickDeselectELKey'),
  );
};

export const mergeSubmit = () => (dispatch, getState) => {
  const state = getState();
  const selectedIds = getSelectedParcelIds(state);
  const validFrom = getValidFrom(state);
  return dispatch(mergeGeometries(selectedIds, false, validFrom));
};

export const mergeEnd = () => dispatch => {
  dispatch(resetMergeTool());
  dispatch(editorHintClose());
  dispatch(removeEL('mergeHoverStyleELKey'));
  dispatch(removeEL('mergeHoverHintELKey'));
  dispatch(removeEL('mergeClickSelectELKey'));
  dispatch(removeEL('mergeClickDeselectELKey'));
  LAST_PARCEL_OVERLAY = null;
};

/** ************************************************************ */

export const onMouseSelect = (feature, countryCode) => (dispatch, getState) => {
  if (!Feature.isFeature(feature) || !Feature.isWithoutCrop(feature)) {
    return;
  }

  if (Feature.isCzUser(countryCode) && Feature.oneParcelLpis(feature)) {
    return;
  }

  const state = getState();
  const stage = getStage(state);
  const availableIds = getAvailableIds(state);
  const selectedIds = getSelectedParcelIds(state);
  const validFrom = getValidFrom(state);
  const featureId = feature.get('id');
  // feature should contain neighbors if not selected from map
  const featureNeighbors = feature.get('lpis_neighbors');

  if (selectedIds.length !== 0 && !availableIds.includes(featureId)) {
    return;
  }

  const promises = [];
  const newSelectedIds = [...selectedIds, featureId];

  dispatch(setMapFetching(true));
  promises.push(dispatch(getCachedReachableIds(featureId, countryCode)));

  promises.push(newSelectedIds.length > 1 ? dispatch(mergeGeometries(newSelectedIds, true, validFrom)) : null);
  promises.push(newSelectedIds.length === 1 ? dispatch(getParcelGeometryById(feature.get('id'))) : null);
  if (!featureNeighbors) {
    promises.push(newSelectedIds.length > 0 ? dispatch(fetchMergeNeighbors(featureId, countryCode, feature)) : null);
  }

  return Promise.all(promises)
    .then(handleResponses)
    .then(payload => {
      // eslint-disable-next-line
      const [fetchedReachablePay, mergeParcelsPay, parcelGeometryPay, getNeigboursPay] = payload;
      const newSelectedParcels = [
        ...getSelectedParcels(state),
        {
          id: featureId,
          localName: feature.get('local_name'),
          lpisBlockId: feature.get('lpis_block_id'),
          currentSeedDate: feature.get('seed_date_start'),
          neighbors: featureNeighbors || getNeigboursPay,
        },
      ];
      dispatch(editorSetSelected(newSelectedParcels));

      if (newSelectedIds.length === 1) {
        dispatch(editorSetStage(stages.STAGE_2));
      }

      if (mergeParcelsPay) {
        dispatch(updateMapHint(mergeParcelsPay, newSelectedParcels, stage));
        dispatch(updateAreaOverlays(mergeParcelsPay));
      }

      if (parcelGeometryPay) {
        dispatch(zoomToGeometry(parcelGeometryPay.geometry, 0.4));
      }

      dispatch(refreshMergeStyles());
      // dispatch(setMapFetching(false));
    })
    .catch(payload => dispatch(handleSameLpisError(payload, featureId)))
    .catch(payload => dispatch(handleGenericError(payload, tools.MERGE)))
    .catch(reason => dispatch(showErrorHint(reason)))
    .finally(() => dispatch(setMapFetching(false)));
};

export const onMouseDeselect = feature => (dispatch, getState) => {
  if (!Feature.isFeature(feature)) {
    return;
  }

  const state = getState();
  const stage = getStage(state);
  const featureId = feature.get('id');
  const selected = getSelectedParcels(state);
  const selectedIds = getSelectedParcelIds(state);
  const validFrom = getValidFrom(state);

  if (!selectedIds.includes(featureId)) {
    return;
  }

  const newSelectedIds = selectedIds.filter(id => id !== featureId);
  const newSelectedParcels = selected.filter(parcel => parcel.id !== featureId);

  if (newSelectedParcels.length > 0) {
    const promises = [];
    dispatch(setMapFetching(true));
    promises.push(dispatch(mergeGeometries(newSelectedIds, true, validFrom)));

    Promise.all(promises)
      .then(handleResponses)
      .then(payload => {
        const [mergeParcelsPay] = payload;
        dispatch(editorSetSelected(newSelectedParcels));
        dispatch(setMapFetching(false));

        if (newSelectedIds.length > 1) {
          dispatch(updateMapHint(mergeParcelsPay, newSelectedParcels, stage));
          dispatch(updateAreaOverlays(mergeParcelsPay));
        } else {
          dispatch(removeOverlays());
          if (newSelectedIds.length === 1) {
            dispatch(editorSetStage(stages.STAGE_2));
            dispatch(editorHintReopen());
          }
        }

        dispatch(refreshMergeStyles());
      })
      .catch(payload => dispatch(handleInvalidDeselect(payload)))
      .catch(payload => dispatch(handleGenericError(payload, tools.MERGE)))
      .catch(reason => {
        dispatch(showErrorHint(reason));
        setTimeout(() => dispatch(editorClearDrawingError()), 3000);
      })
      .finally(() => dispatch(setMapFetching(false)));
  } else {
    dispatch(editorSetStage(stages.STAGE_1));
    dispatch(resetMergeTool());
    dispatch(refreshMergeStyles());
  }
};

const onMouseMove = (feature, evt, countryCode) => (dispatch, getState) => {
  const state = getState();
  const elementId = resolveHoverElementId(feature, countryCode, state);

  if (elementId) {
    if (elementId !== LAST_PARCEL_OVERLAY) {
      dispatch(removeOverlay(LAST_PARCEL_OVERLAY));
      dispatch(addMapHintOverlay(evt, elementId, elementId));
      LAST_PARCEL_OVERLAY = elementId;
    } else {
      dispatch(updateOverlayPosition(LAST_PARCEL_OVERLAY, evt.coordinate));
    }
  } else {
    dispatch(removeOverlay(LAST_PARCEL_OVERLAY));
    LAST_PARCEL_OVERLAY = null;
  }
};

/** ********************************* */

const resolveHoverElementId = (feature, countryCode, state) => {
  if (!Feature.isFeature(feature)) {
    return null;
  }

  const stage = getStage(state);
  if (stage === stages.STAGE_1) {
    if (Feature.isCzUser(countryCode)) {
      if (Feature.oneParcelLpis(feature)) {
        return 'single-parcel-lpis';
      }
    }

    if (!Feature.isWithoutCrop(feature)) {
      return 'parcel-has-crop-merge';
    }

    return null;
  }

  if (stage === stages.STAGE_2 || stage === stages.STAGE_3) {
    const reachangleOnlyIds = getFilteredReachableIds(state);
    const selectedIds = getSelectedParcelIds(state);
    const availableIds = getAvailableIds(state);

    if (Feature.isCzUser(countryCode)) {
      const selectedParcelLpis = getSelectedParcelLpisBlock(state);
      if (selectedParcelLpis !== Feature.getLpisBlock(feature)) {
        return 'parcel-different-lpis';
      }
    }
    if (!Feature.isWithoutCrop(feature)) {
      return 'parcel-has-crop-merge';
    }
    if (
      reachangleOnlyIds.includes(feature.get('id')) ||
      ![...selectedIds, ...availableIds].includes(feature.get('id'))
    ) {
      return 'parcel-not-adjacent';
    }
  }

  return null;
};

const handleResponses = responses => {
  const payloads = responses.map(r => getPayload(r));
  let result = Promise.resolve(payloads);
  responses.some(response => {
    if (response?.error) {
      const payload = getPayload(response);
      result = Promise.reject(payload);
      return true;
    }
    return false;
  });

  return result;
};

const handleSameLpisError = (payload, featureId) => (dispatch, getState) => {
  const { response } = payload;
  if (response && response.message && response.message.toUpperCase().includes('SAME LPIS BLOCK')) {
    const newSelectedParcels = getSelectedParcels(getState()).map(parcel => {
      parcel.neighbors = parcel.neighbors.filter(neighbor => neighbor !== featureId);
      return parcel;
    });

    dispatch(editorSetSelected(newSelectedParcels));
    dispatch(refreshMergeStyles());
    return Promise.reject(errors.NON_IDENTIC_LPIS_ERROR);
  }
  return Promise.reject(payload);
};

const handleInvalidDeselect = payload => () => {
  const { response } = payload;
  if (response && response.exception && response.exception.toUpperCase().includes('GEOMETRYOPERATIONEXCEPTION')) {
    return Promise.reject(errors.MERGE_NON_CONSISTENT_ERROR);
  }
  return Promise.reject(payload);
};

const getCachedReachableIds = (featureId, countryCode) => (dispatch, getState) => {
  const reachableIds = getAllReachableIds(getState());
  return Feature.isCzUser(countryCode) && !reachableIds.length
    ? dispatch(getLpisParcelsByParcelId(featureId)).then(({ payload }) => {
      dispatch(updateReachableIds(Object.keys(payload), true));
    })
    : Promise.resolve({ payload: reachableIds });
};

const fetchMergeNeighbors = (featureId, countryCode, feature) => dispatch => {
  const lpisId = Feature.isCzUser(countryCode) ? feature.get('lpis_block_id') : null;
  return dispatch(getMergeNeighbors(featureId, lpisId));
};

const updateAreaOverlays = payload => dispatch => {
  dispatch(removeOverlays());
  dispatch(addAreaOverlay(payload, 'parcel-area', 'parcel-area'));
};

const updateMapHint = (payload, newSelectedParcels, stage) => dispatch => {
  const isEnteringStage3 = stage !== stages.STAGE_3;

  dispatch(editorSetError(null));
  if (isEnteringStage3) {
    dispatch(editorSetStage(stages.STAGE_3));
  }
  dispatch(editorHintRefresh({ parcels: newSelectedParcels, result: payload }));
};

const resetMergeTool = () => dispatch => {
  dispatch(editorSetSelected([]));
  dispatch(updateReachableIds([]));
  dispatch(removeOverlays());
};
