import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => {
  const { breakpoints } = theme;
  return {
    heading: {
      fontSize: '24px',
      fontWeight: 500,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(1),
    },
    [breakpoints.down('xs')]: {
      heading: {
        fontSize: '22px',
      },
    },
  };
};

function PageHeading(props) {
  return (
    <h1
      className={`${props.classes.heading} ${props.customClass}`}
      data-test={props.dataTest}
      style={{ justifyContent: props.align }}
    >
      {props.translationId ? <FormattedMessage id={props.translationId} /> : props.value}
    </h1>
  );
}

PageHeading.propTypes = {
  classes: PropTypes.object,
  customClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dataTest: PropTypes.string,
  translationId: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

PageHeading.defaultProps = {
  classes: {},
  customClass: '',
  dataTest: '',
  value: '',
  translationId: '',
  align: 'center',
};

export default withStyles(styles)(PageHeading);
