import React, { FC, useMemo, useContext } from 'react';

import { MenuItem } from '@mui/material';
import { Field, Formik, FormikErrors, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTelematicsAdminSelectedFarms } from '../selectors/telematicsAdmin.selectors';

import { fetchAccountFarms, resetSelectedFarms } from '../selectors/telematicsAdmin.actions';

import { UpdateFarmsPayload, updateTelematicsAccountFarmsApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';
import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../../types';

import useTelematicsAdminStyles from './styles/telematicsAdmin.styles';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AccountTo } from '../../../../shared/api/telematics/telematics.types';

interface ActivationFormValues {
  [farmId: string]: {
    externalId: string;
    farmId: string;
    name: string;
  }
}

const validate = (values: ActivationFormValues) => {
  const errors: FormikErrors<ActivationFormValues> = {};

  Object.keys(values).forEach((farmId) => {
    if (!values[farmId]?.externalId) {
      errors[farmId] = { externalId: 'validation.required' };
    }
  });

  return errors;
};

interface Props {
  fetchAccountFarms: () => void,
  handleClose: () => void;
  opened: boolean;
  resetSelectedFarms: () => void;
  selectedFarms: AccountTo[];
  updateFarms: (payload: UpdateFarmsPayload) => void;
}

const ActivationDialog: FC<Props> = ({
  fetchAccountFarms,
  handleClose,
  opened,
  resetSelectedFarms,
  selectedFarms,
  updateFarms,
}) => {
  const classes = useTelematicsAdminStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(() => selectedFarms.reduce((acc, farm) => {
    acc[farm.farmId] = {
      name: farm.name,
      farmId: farm.farmId,
      externalId: farm.externalId,
    };
    return acc;
  }, {} as {[k: string]: Partial<AccountTo>}), [selectedFarms]);

  const handleSubmit = (values: ActivationFormValues) => {
    const farmIds = Object.keys(values);
    const payload = farmIds.map((farmId) => ({
      farmId: values[farmId].farmId,
      externalId: values[farmId].externalId,
      enabled: true,
    }));
    (updateFarms as AsyncFn<UpdateFarmsPayload>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.dialog.error" />, isError: true });
        return;
      }
      fetchAccountFarms();
      resetSelectedFarms();
      showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.success" values={{ count: farmIds.length }} />, isSuccess: true });
    });
    handleClose();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
     >
      {({ errors, submitForm }) => {
        const hasErrors = !!Object.keys(errors).length;
        return (
          <CfDialog
            acceptText={<FormattedMessage id="TelematicsAdmin.activate.dialog.acceptBtn" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onCancel={handleClose}
            opened={opened}
            title={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.title" />}
        >
            <>
              <p className={classes.dislaimer}><FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.disclaimer" /></p>
              <div className={classes.formContainer}>
                <Form>
                  {selectedFarms.map(({ farmId, name }) => (
                    <div className={classes.formRow} key={farmId}>
                      <div className={classes.farmInfo}>
                        <p className={classes.farmName}>{name}</p>
                        <p className={classes.farmId}>{farmId}</p>
                      </div>
                      <Field
                        component={CfFormikTextField}
                        customClasses={{ root: classes.companyId }}
                        error={!!errors?.[farmId]?.externalId}
                        fullWidth={false}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.companyId" />}
                        name={`${farmId}.externalId`}
                      />
                      <Field
                        component={CfFormikTextField}
                        customClasses={{ root: classes.classifier }}
                        defaultValue="winfas"
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.classifier" />}
                        name={'todo-classifier'}
                        select
                      >
                        <MenuItem classes={{ root: classes.classifierMenuItem }} value="winfas">
                          <span>Winfas</span>
                        </MenuItem>
                      </Field>
                    </div>
                  ))}
                </Form>
              </div>
              {hasErrors &&
                <p className={classes.errorMessage}>
                  <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.validationError" />
                </p>
              }
            </>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selectedFarms: getTelematicsAdminSelectedFarms(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  updateFarms: updateTelematicsAccountFarmsApi,
  fetchAccountFarms,
  resetSelectedFarms,
},
dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivationDialog);
