/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from 'angular';

import './bootstrap-material-design/material.js';
import './bootstrap-material-design/ripples.js';

export default angular.module('app.vendor', []).name;
