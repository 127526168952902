import {
  getSentinelAdminPage,
  getSentinelAdminOrder,
  getSentinelAdminOrderBy,
  getSentinelAdminRowsPerPage,
  getSentinelAdminTextFilter,
  getSentinelAdminFarm,
} from '../selectors/sentinel.selectors';

import * as types from './sentinel.constants';

import {
  getAdminParcels,
  addParcelsServices,
  updateParcelServices,
  getParcelsByName,
  resetAdminParcels,
} from '../../../../shared/api/sentinel/admin/admin.api';

export const fetchActiveParcels = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    page: getSentinelAdminPage(state) + 1,
    'per-page': getSentinelAdminRowsPerPage(state),
    'sort-dir': getSentinelAdminOrder(state),
    'sort-col': getSentinelAdminOrderBy(state),
    localNameBlockNr: getSentinelAdminTextFilter(state),
    farmIds: getSentinelAdminFarm(state).id,
  };
  dispatch(getAdminParcels('table', params));
};

export const fetchParcelsSuggestions = searchInput => (dispatch, getState) => {
  const state = getState();
  const farmId = getSentinelAdminFarm(state).id;
  dispatch(getParcelsByName('suggestions', searchInput, farmId));
};

export const clearParcelsSuggestions = () => dispatch => {
  dispatch(resetAdminParcels('suggestions'));
};

export const setFarmFilter = farm => ({
  type: types.SET_FARM_FILTER,
  farm,
});

export const setActiveParcels = (adding, farmId, settings) => dispatch => {
  const servicesSettings = {
    historyPotentialEnabled: settings.historyPotentialEnabled,
    biomonitoringEnabled: settings.biomonitoringEnabled,
    variableApplicationEnabled: settings.variableApplicationEnabled,
  };
  if (adding) {
    const parcelsServicesSettings = settings.parcels.map(parcel => ({
      parcelId: parcel.parcelId,
      ...servicesSettings,
    }));
    dispatch(addParcelsServices(parcelsServicesSettings, farmId)).then(() => {
      dispatch(fetchActiveParcels());
    });
  } else {
    const parcelId = settings.parcels[0].parcelId;
    dispatch(updateParcelServices(servicesSettings, parcelId, farmId)).then(() => {
      dispatch(fetchActiveParcels());
    });
  }
};
