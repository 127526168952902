import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, change } from 'redux-form';

import VariableActionExport from '../VariableActionExport/VariableActionExport';

const styles = {
  container: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  button: {
    margin: '10px',
  },
};

class ActionFormButtons extends Component {
  handleSubmit = exportType => {
    const { formName, withExport } = this.props;
    if (withExport) {
      // TODO - obsolete?
      this.props.change(formName, 'exportTypeOnSubmit', exportType);
    }

    setTimeout(() => {
      this.props.submit(formName);
    });
  };

  render() {
    const { classes, isExisting, isLoading, isPristine, isSubmitting, onCancel, onReset, withExport } = this.props;

    const resetId = isExisting ? 'cancel' : 'reset';
    const createId = isExisting ? 'save' : 'create';
    const callback = isExisting ? onCancel : onReset;

    return (
      <Grid alignItems="center" className={classes.container} container justifyContent="center" spacing={0}>
        <Button
          className={classes.button}
          disabled={isSubmitting || isLoading}
          id={resetId}
          onClick={callback}
          type="reset"
          variant="contained"
        >
          <FormattedMessage id={`common.${resetId}`} />
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting || isLoading || (isExisting ? isPristine : false)}
          id={createId}
          onClick={() => this.handleSubmit('')}
          variant="contained"
        >
          <FormattedMessage id={`common.${createId}`} />
        </Button>
        {!isExisting && withExport && (
          <VariableActionExport
            classes={{ button: classes.button }}
            isDisabled={isSubmitting || isLoading}
            onExport={exportType => this.handleSubmit(exportType)}
          />
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submit,
      change,
    },
    dispatch,
  );

ActionFormButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  withExport: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

ActionFormButtons.defaultProps = {
  isLoading: false,
};

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(ActionFormButtons);
