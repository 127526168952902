/**
 * Created by ondrejzvara on 6.4.17.
 */

import 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-dynamic-locale';
import 'angular-file-saver';
import 'angular-file-upload';
import 'angular-http-auth';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-loader-static-files';
import 'angular-translate-loader-url';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-ui-bootstrap';
import '@uirouter/angularjs';
import 'angular-utf8-base64';
import 'angular-validation';
import 'bootstrap';
import 'arrive';
import 'jquery';
import 'jsog';
import 'jwt-decode';
import 'local-storage';
import 'lodash';
import 'messageformat';
import 'moment';
import 'ng-table';
import 'restangular';
import 'stacktrace-js';
import 'ui-select';
