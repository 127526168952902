export const GET_PARCELS = 'GET_PARCELS';
export const GET_PARCELS_SUCCESS = 'GET_PARCELS_SUCCESS';
export const GET_PARCELS_FAILURE = 'GET_PARCELS_FAILURE';
export const RESET_PARCELS = 'RESET_PARCELS';

export const GET_PARCELS_MAP_ACTIONABLE = 'GET_PARCELS_MAP_ACTIONABLE';
export const GET_PARCELS_MAP_ACTIONABLE_SUCCESS = 'GET_PARCELS_MAP_ACTIONABLE_SUCCESS';
export const GET_PARCELS_MAP_ACTIONABLE_FAILURE = 'GET_PARCELS_MAP_ACTIONABLE_FAILURE';
export const RESET_PARCELS_MAP_ACTIONABLE = 'RESET_PARCELS_MAP_ACTIONABLE';

export const GET_PARCEL = 'GET_PARCEL';
export const GET_PARCEL_SUCCESS = 'GET_PARCEL_SUCCESS';
export const GET_PARCEL_FAILURE = 'GET_PARCEL_FAILURE';
export const RESET_PARCEL = 'RESET_PARCEL';

export const GET_PARCEL_ACTIONS = 'GET_PARCEL_ACTIONS';
export const GET_PARCEL_ACTIONS_SUCCESS = 'GET_PARCEL_ACTIONS_SUCCESS';
export const GET_PARCEL_ACTIONS_FAILURE = 'GET_PARCEL_ACTIONS_FAILURE';

export const GET_PARCELS_STATISTICS = 'GET_PARCELS_STATISTICS';
export const GET_PARCELS_STATISTICS_SUCCESS = 'GET_PARCELS_STATISTICS_SUCCESS';
export const GET_PARCELS_STATISTICS_FAILURE = 'GET_PARCELS_STATISTICS_FAILURE';
export const RESET_PARCELS_STATISTICS = 'RESET_PARCELS_STATISTICS';

export const GET_PARCEL_FERTILIZATION = 'GET_PARCEL_FERTILIZATION';
export const GET_PARCEL_FERTILIZATION_SUCCESS = 'GET_PARCEL_FERTILIZATION_SUCCESS';
export const GET_PARCEL_FERTILIZATION_FAILURE = 'GET_PARCEL_FERTILIZATION_FAILURE';
export const GET_PARCELS_FERTILIZATION = 'GET_PARCELS_FERTILIZATION';
export const GET_PARCELS_FERTILIZATION_SUCCESS = 'GET_PARCELS_FERTILIZATION_SUCCESS';
export const GET_PARCELS_FERTILIZATION_FAILURE = 'GET_PARCELS_FERTILIZATION_FAILURE';
export const RESET_PARCELS_FERTILIZATION = 'RESET_PARCELS_FERTILIZATION';

export const GET_PARCEL_SEEDINGS = 'GET_PARCEL_SEEDINGS';
export const GET_PARCEL_SEEDINGS_SUCCESS = 'GET_PARCEL_SEEDINGS_SUCCESS';
export const GET_PARCEL_SEEDINGS_FAILURE = 'GET_PARCEL_SEEDINGS_FAILURE';

export const GET_PARCEL_RESTRICTIONS = 'GET_PARCEL_RESTRICTIONS';
export const GET_PARCEL_RESTRICTIONS_SUCCESS = 'GET_PARCEL_RESTRICTIONS_SUCCESS';
export const GET_PARCEL_RESTRICTIONS_FAILURE = 'GET_PARCEL_RESTRICTIONS_FAILURE';

export const PATCH_PARCEL = 'PATCH_PARCEL';
export const PATCH_PARCEL_SUCCESS = 'PATCH_PARCEL_SUCCESS';
export const PATCH_PARCEL_FAILURE = 'PATCH_PARCEL_FAILURE';

export const CREATE_PARCEL = 'CREATE_PARCEL';
export const CREATE_PARCEL_SUCCESS = 'CREATE_PARCEL_SUCCESS';
export const CREATE_PARCEL_ERROR = 'CREATE_PARCEL_ERROR';

export const ADD_PARCELS = 'ADD_PARCELS';

export const GET_CENTERS_REQUEST = 'GET_CENTERS_REQUEST';
export const GET_CENTERS_SUCCESS = 'GET_CENTERS_SUCCESS';
export const GET_CENTERS_ERROR = 'GET_CENTERS_ERROR';
export const RESET_CENTERS = 'RESET_CENTERS';

export const PUT_ASSIGN_CENTER_REQUEST = 'PUT_ASSIGN_CENTER_REQUEST';
export const PUT_ASSIGN_CENTER_SUCCESS = 'PUT_ASSIGN_CENTER_SUCCESS';
export const PUT_ASSIGN_CENTER_ERROR = 'PUT_ASSIGN_CENTER_ERROR';
