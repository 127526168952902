import React from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SensorsService from '../../../shared/services/Sensors.service';
import PestPredictionGraph from '../../containers/PestPredictionGraph/PestPredictionGraph';

const styles = theme => ({
  predictionsPaper: {
    padding: 20,
  },
  switcherButtonContent: {
    borderRadius: 50,
    backgroundColor: theme.palette.grey[300],
    padding: '4px 12px 4px 10px',
    marginLeft: 10,
    fontSize: 14,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  switcherButtonLabel: {
    height: 24,
  },
  settingsIcon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  wrapper: {
    marginBottom: 16,
  },
});

function NodeLocationPredictions(props) {
  const { classes, history, match, nodeLocation, pestPredictions } = props;
  const { farmId, sensorId } = match.params;
  const isHistoric = SensorsService.isHistoric(nodeLocation);
  const visiblePestPredictions = pestPredictions.filter(pestPrediction => pestPrediction.visible);
  return (
    <div className={classes.wrapper} data-class="pest-predictions">
      <div className={classes.header}>
        <FormattedMessage id="NodeDetailPredictions.predictions" />
        {!isHistoric && (
          <Button
            classes={{ label: classes.switcherButtonLabel }}
            className={classes.switcherButtonContent}
            data-test="pest-predictions-settings"
            size="small"
            variant="contained"
            onClick={() => {
              history.push(`/farm/${farmId}/sensors/${sensorId}/predictions-settings`);
            }}
          >
            <SettingsIcon className={classes.settingsIcon} />
            <FormattedMessage id="common.settings" />
          </Button>
        )}
      </div>
      {visiblePestPredictions.length > 0 && (
        <Paper className={classes.predictionsPaper}>
          {visiblePestPredictions.map(pestPrediction => (
            <PestPredictionGraph
              key={pestPrediction.name}
              nodeLocation={nodeLocation}
              pestPrediction={pestPrediction}
              testId={pestPrediction.name}
            />
          ))}
        </Paper>
      )}
    </div>
  );
}

NodeLocationPredictions.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  pestPredictions: PropTypes.array,
  nodeLocation: PropTypes.object.isRequired,
};

NodeLocationPredictions.defaultProps = {
  pestPredictions: [],
};

export default withStyles(styles)(NodeLocationPredictions);
