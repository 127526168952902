import * as types from '../actions/map.constants';

export default () => (
  state = {
    newDevice: undefined,
  },
  action,
) => {
  switch (action.type) {
    case types.EDIT_START:
      return {
        ...state,
        newDevice: action.newDevice,
      };
    case types.EDIT_CANCEL:
      return {
        ...state,
        newDevice: undefined,
      };
    case types.EDIT_UPDATE:
      return {
        ...state,
        newDevice: action.newDeviceVersion,
      };
    default:
      return state;
  }
};
