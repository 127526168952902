import { CfAction } from '../../types';

import ACTIONS from './commonContants';

export interface Action extends CfAction {
    path: string;
}

export const setActivePage = (path: string): Action => ({
  type: ACTIONS.SET_ACTIVE_PAGE,
  path,
});

export const setPrevPage = (path: string): Action => ({
  type: ACTIONS.SET_PREV_PAGE,
  path,
});
