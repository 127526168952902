import React, { Component } from 'react';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfSimpleDialog from '../../common/CfSimpleDialog/CfSimpleDialog';
import PageHeader from '../../common/PageHeader/PageHeader';
import PageHeading from '../../common/PageHeading/PageHeading';

const style = theme => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  heading: {
    fontSize: 20,
  },
  container: {
    padding: 10,
    textAlign: 'center',
    fontSize: '16px',
    alignContent: 'flex-start',
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  submitButton: {
    marginTop: '20px',
  },
  sensorPlacementItem: {
    alignSelf: 'center',
  },
});

export class DeviceActivation extends Component {
  static isEmpty(value) {
    return !(value && value.trim());
  }

  static isWgsCoordinate(value) {
    return !isNaN(value);
  }

  static isClientError(e) {
    return e.status === 400;
  }

  static getCoordsPart(geometry, index) {
    return geometry ? geometry.coordinates[index].toFixed(7).toString() : '';
  }

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: undefined,
      latitude: '',
      latitudeError: undefined,
      longitude: '',
      longitudeError: undefined,
      coordinatesValidationError: false,
    };
    props.setEnlargedVariant(true);
  }

  componentDidMount() {
    this.props.activateStart(this.props.device);
  }

  componentDidUpdate(prevProps) {
    if (this.props.device.id !== prevProps.device.id) {
      this.props.activateStart(this.props.device);
    }
    const { geometryWgs } = this.props;
    if (geometryWgs !== prevProps.geometryWgs) {
      this.setState({
        latitude: DeviceActivation.getCoordsPart(geometryWgs, 1),
        latitudeError: undefined,
        longitude: DeviceActivation.getCoordsPart(geometryWgs, 0),
        longitudeError: undefined,
        coordinatesValidationError: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.activateCancel();
  }

  onSubmit = event => {
    event.preventDefault();
    if (this.validate()) {
      this.props
        .activate(this.state.name)
        .catch(e => {
          this.setState({
            coordinatesValidationError: DeviceActivation.isClientError(e),
          });
        });
    }
  };

  onInputChange = (e, inputName) => {
    this.setState({
      [inputName]: e.target.value,
    });
  };

  onCoordinatesErrorClose() {
    this.setState({
      coordinatesValidationError: false,
    });
  }

  getCoordsFromState() {
    if (this.state.longitude && this.state.latitude) {
      return [parseFloat(this.state.longitude), parseFloat(this.state.latitude)];
    }
    return null;
  }

  validate() {
    const isNameOk = this.validateNodeName(this.state.name);
    const isLatitudeOk = this.validateLatitude(this.state.latitude);
    const isLongitudeOk = this.validateLongitude(this.state.longitude);
    return isNameOk && isLatitudeOk && isLongitudeOk;
  }

  validateNodeName = value => {
    if (!(value && value.trim())) {
      this.setState({
        nameError: <FormattedMessage id="DeviceActivation.errorName" />,
      });
      return false;
    }

    return true;
  };

  validateLatitude(value) {
    return this.validateCoord(value, 'latitude', 1);
  }

  validateLongitude(value) {
    return this.validateCoord(value, 'longitude', 0);
  }

  validateCoord = (value, name, index) => {
    const errorName = `${name}Error`;
    let isCoordOk = true;
    if (DeviceActivation.isEmpty(value)) {
      this.setState({
        [errorName]: <FormattedMessage id="DeviceActivation.errorGpsCoord" />,
      });
      isCoordOk = false;
    } else if (!DeviceActivation.isWgsCoordinate(value)) {
      this.setState({
        [errorName]: <FormattedMessage id="DeviceActivation.errorGpsFormat" />,
      });
      isCoordOk = false;
    }

    // update/clear selection node in map on form change
    if (value !== DeviceActivation.getCoordsPart(this.props.geometryWgs, index)) {
      this.props.updateCoordsFromForm(isCoordOk && this.isCoordinatesOk() ? this.getCoordsFromState() : null);
    }

    return isCoordOk;
  };

  resetErrorText = (e, inputName) => {
    const name = `${inputName}Error`;
    this.setState({
      [name]: undefined,
    });
  };

  isCoordinatesOk() {
    return !this.state.latitudeError && !this.state.longitudeError;
  }

  render() {
    const { backButton, buttonMessageId, classes, deviceId, pageHeadingMessageId } = this.props;
    return (
      <div className={classes.wrapper}>
        <CfSimpleDialog
          data-test="node-popup"
          heading={<FormattedMessage id="CoordinatesError.title" />}
          onDialogClose={this.onCoordinatesErrorClose}
          open={this.state.coordinatesValidationError}
        >
          <DialogContentText classes={{ root: classes.dialogContent }} data-test="node-popup-content">
            <FormattedMessage id="CoordinatesError.message" />
          </DialogContentText>
        </CfSimpleDialog>
        <PageHeader
          backButton={backButton}
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              classes={{ heading: classes.heading }}
              value={<FormattedMessage id={pageHeadingMessageId} />}
            />
          }
        />
        <div className={classes.formContainer}>
          <form onSubmit={e => this.onSubmit(e)}>
            <TextField disabled fullWidth={true} label="ID" name="device-id" value={deviceId} />
            <TextField
              error={this.state.nameError !== undefined}
              fullWidth={true}
              helperText={this.state.nameError}
              label={<FormattedMessage id="common.name" />}
              margin="dense"
              name="name"
              onBlur={e => this.validateNodeName(e.target.value)}
              onChange={e => this.onInputChange(e, 'name')}
              onFocus={e => this.resetErrorText(e, 'name')}
              value={this.state.name}
            />
            <TextField
              error={this.state.latitudeError !== undefined}
              fullWidth={true}
              helperText={this.state.latitudeError}
              label={<FormattedMessage id="DeviceActivation.sensorLatitude" />}
              margin="dense"
              name="latitude"
              onBlur={e => this.validateLatitude(e.target.value)}
              onChange={e => this.onInputChange(e, 'latitude')}
              onFocus={e => this.resetErrorText(e, 'latitude')}
              value={this.state.latitude}
            />
            <TextField
              error={this.state.longitudeError !== undefined}
              fullWidth={true}
              helperText={this.state.longitudeError}
              label={<FormattedMessage id="DeviceActivation.sensorLongitude" />}
              margin="dense"
              name="longitude"
              onBlur={e => this.validateLongitude(e.target.value)}
              onChange={e => this.onInputChange(e, 'longitude')}
              onFocus={e => this.resetErrorText(e, 'longitude')}
              value={this.state.longitude}
            />
            <Button
              className={classes.submitButton}
              color="primary"
              data-test="place-sensor"
              type="submit"
              variant="contained"
            >
              <FormattedMessage id={buttonMessageId} />
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

DeviceActivation.propTypes = {
  classes: PropTypes.object,
  pageHeadingMessageId: PropTypes.string.isRequired,
  buttonMessageId: PropTypes.string.isRequired,
  deviceId: PropTypes.string,
  device: PropTypes.object.isRequired,
  geometryWgs: PropTypes.object,
  activateStart: PropTypes.func.isRequired,
  activateCancel: PropTypes.func.isRequired,
  backButton: PropTypes.node.isRequired,
  activate: PropTypes.func.isRequired,
  updateCoordsFromForm: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
};

DeviceActivation.defaultProps = {
  classes: {},
  deviceId: '',
  geometryWgs: undefined,
};

export default withStyles(style)(DeviceActivation);
