import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import * as gran from '../../../../sensors/constants/granularity.constants';

import DateTime from '../../../services/DateTime.service';
import Localization from '../../../services/Localization.service';
import CfFormattedRelativeTime from '../../common/CfFormattedRelativeTime/CfFormattedRelativeTime';
import CfChartTooltipLabel from '../CfChartTooltipLabel/CfChartTooltipLabel';

export class CfChartTooltipDaytime extends Component {
  startDateTime = () => DateTime.dateObject(this.props.label);

  endDateTime = () => DateTime.endDateTimeByDuration(this.props.granularity, this.props.label);

  endDateTimePestChart = dayCount =>
    Localization.formatDate(DateTime.endDateTimeByNumberOfDays(dayCount - 1, this.props.label), this.props.intl.locale);

  isOverMidnight = () => this.shiftedEndDateMoment().isAfter(moment(this.startDateTime()), 'day');

  dayInWeek = dateTime => moment(dateTime).format('dddd');

  startDate = () => Localization.formatDate(this.startDateTime(), this.props.intl.locale);

  endDate = () => Localization.formatDate(this.endDateTime(), this.props.intl.locale);

  // can be used when we don't want midnight to behave as new day
  shiftedEndDateMoment = () => moment(this.endDateTime()).subtract(1, 's');

  startTime = () => Localization.formatTime(this.startDateTime(), this.props.intl.locale);

  endTime = () => Localization.formatTime(this.endDateTime(), this.props.intl.locale);

  dateWithWeekday = dateTime => (
    <span>
      {this.dayInWeek(dateTime)} {Localization.formatDate(dateTime, this.props.intl.locale)}
    </span>
  );

  endDateWithWeekday = () => {
    const shiftedEndDateMoment = this.shiftedEndDateMoment();
    const shiftedEndDateTime = shiftedEndDateMoment.toDate();
    return this.dateWithWeekday(shiftedEndDateTime);
  };

  endDatePeriod = () => (
    <span>
      {' '}
      <span> &#8211; </span> {this.endDateWithWeekday()}
    </span>
  );

  labelTimePeriod = () => (
    <span>
      {this.startTime()} <span> &#8211; </span> {this.endTime()}
    </span>
  );

  render() {
    const { dayCount, granularity, label, pestChart } = this.props;
    return (
      <div>
        <CfChartTooltipLabel>
          {!pestChart && (
            <span>
              {this.dateWithWeekday(this.startDateTime())}
              {granularity !== gran.G24H && this.isOverMidnight() && this.endDatePeriod()}
            </span>
          )}
        </CfChartTooltipLabel>
        <CfChartTooltipLabel>
          {pestChart && (
            <span>
              {this.startDate()}
              {dayCount > 1 && <span> &#8211; </span>}
              {dayCount > 1 && this.endDateTimePestChart(dayCount)}
            </span>
          )}
        </CfChartTooltipLabel>
        <CfChartTooltipLabel>
          {!pestChart &&
          granularity !== gran.G24H &&
          granularity !== gran.G1W ? this.labelTimePeriod() : ''}
          {pestChart && <CfFormattedRelativeTime dateTime={label} />}
        </CfChartTooltipLabel>
      </div>
    );
  }
}

CfChartTooltipDaytime.propTypes = {
  intl: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  granularity: PropTypes.string,
  dayCount: PropTypes.number,
  pestChart: PropTypes.bool,
};

CfChartTooltipDaytime.defaultProps = {
  dayCount: 0,
  pestChart: false,
  granularity: 'G30M',
};

export default injectIntl(CfChartTooltipDaytime);
