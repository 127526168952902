import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Dot, Line, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';

import CfChartWrapper from '../../../../../../shared/components/charts/CfChartWrapper/CfChartWrapper';
import XAxisLabel from '../../../shared/components/XAxisLabel/XAxisLabel';
import * as indices from '../../selectors/indices';
import { zonesColors } from '../../services/monitoringZonesColors';
import BioMonitoringTooltip from '../BioMonitoringTooltip/BioMonitoringTooltip';

// used for stroke and tooltip
const MAIN_COLOR_INDEX = 5;

const styles = () => ({
  wrapper: {
    height: '250px',
  },
});

export class BioMonitoringGraph extends Component {
  static getDomainByIndex(indexType) {
    switch (indexType) {
      case indices.CAB:
        return [2.0, 80.0];
      case indices.CW:
        return [0.0005, 0.07];
      case indices.LAI:
        return [0.1, 10];
      default:
        return [1.0, 100.0];
    }
  }

  static getTicksByIndex(indexType) {
    switch (indexType) {
      case indices.CAB:
        return [2.0, 21.5, 41.0, 60.5, 80.0];
      case indices.CW:
        return [0.0005, 0.0174, 0.0353, 0.0527, 0.07];
      case indices.LAI:
        return [0.1, 2.55, 5.0, 7.5, 10.0];
      default:
        return [1.0, 100.0];
    }
  }

  constructor(props) {
    super(props);

    this.yAxisProps = {
      ticks: [0, 10, 20],
      tickLine: false,
      tick: { fontSize: 12 },
      allowDataOverflow: true,
      padding: { top: 1, bottom: 1 },
      domain: [0, 0],
      width: 40,
    };

    this.xAxisProps = {
      dataKey: 'dateFrom',
      tickLine: false,
      domain: ['dataMin', 'dataMax'],
    };

    this.defaultCartesianGridProps = {
      strokeDasharray: '2 4',
      strokeLinecap: 'round',
      strokeWidth: 1,
      stroke: '#CACACA',
    };

    this.innerChartProps = {
      isAnimationActive: false,
      dataKey: 'value',
      type: 'monotoneX',
      fill: '#e37767',
      fillOpacity: 1,
      stroke: zonesColors[props.index][MAIN_COLOR_INDEX].color,
      strokeWidth: 2,
    };

    this.tooltipProps = {
      filterNull: false,
      offset: 50,
    };
  }

  formatDate = value => `${this.props.intl.formatDate(value)}`;

  renderDot(datapoint, data) {
    if (datapoint.payload.value) {
      const index = data.findIndex(item => item.dateFrom === datapoint.payload.dateFrom);
      const leftPoint = data[index - 1];
      const rightPoint = data[index + 1];
      if ((!leftPoint || !leftPoint.value) && (!rightPoint || !rightPoint.value)) {
        return <Dot {...datapoint} />;
      }
    }

    return undefined;
  }

  render() {
    const { classes, data, index, isFetching } = this.props;

    this.yAxisProps.domain = BioMonitoringGraph.getDomainByIndex(index);
    this.yAxisProps.ticks = BioMonitoringGraph.getTicksByIndex(index);

    return (
      <CfChartWrapper
        data={data}
        isFetching={isFetching}
        classes={{
          wrapper: classes.wrapper,
        }}
      >
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart data={data} isAbove={false} margin={{ top: 0, right: 40, left: 15, bottom: 10 }}>
            <Tooltip
              {...this.tooltipProps}
              content={<BioMonitoringTooltip tooltipValueColor={zonesColors[index][MAIN_COLOR_INDEX].color} />}
              wrapperStyle={{ zIndex: 1 }}
            />

            <XAxis tick={<XAxisLabel formatter={this.formatDate} textAnchor={'middle'} />} {...this.xAxisProps} />
            <YAxis {...this.yAxisProps} />
            <Line {...this.innerChartProps} dot={point => this.renderDot(point, data)} r={0.5} />
            <CartesianGrid {...this.defaultCartesianGridProps} />
          </ComposedChart>
        </ResponsiveContainer>
      </CfChartWrapper>
    );
  }
}

BioMonitoringGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default injectIntl(withStyles(styles)(BioMonitoringGraph));
