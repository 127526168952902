import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: '34px 50px 20px',
  },
  dialogContent: {
    padding: '0px 50px 24px 50px',
  },
  dialogContainer: {
    paddingRight: '10px',
  },
};

function CfDialog(props) {
  const { children, classes, dialogHeight, maxWidth, opened, title } = props;
  const { acceptText, cancelText, dataTest, disabled, onAccept, onCancel, onClose, onEnter } = props;

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test={dataTest}
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={onClose}
      open={opened}
      TransitionProps={{
        onEnter,
      }}>
      {!isEmpty(title) && (
        <DialogTitle className={classes.title} data-test="dialog-title">
          {title}
        </DialogTitle>
      )}
      <DialogContent
        classes={{ root: classes.dialogContent }}
        data-test="dialog-content"
        style={{ height: dialogHeight }}
      >
        {React.Children.only(children)}
      </DialogContent>
      <DialogActions data-test="dialog-actions">
        {onCancel && (
          <Button data-test="cancel-button" id="dismiss" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {onAccept && (
          <Button color="primary" data-test="accept-button" disabled={disabled} id="accept" onClick={onAccept}>
            {acceptText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

CfDialog.propTypes = {
  children: PropTypes.element.isRequired,
  maxWidth: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  cancelText: PropTypes.any,
  acceptText: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  opened: PropTypes.bool,
  classes: PropTypes.object,
  onEnter: PropTypes.func,
  onClose: PropTypes.func,
  dialogHeight: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

CfDialog.defaultProps = {
  title: '',
  maxWidth: 'sm',
  opened: false,
  cancelText: 'Cancel',
  acceptText: 'OK',
  onCancel: undefined,
  onAccept: undefined,
  onEnter: () => {},
  onClose: () => {},
  classes: {},
  dialogHeight: 'auto',
  dataTest: 'dialog',
  disabled: false,
};

export default withStyles(styles)(CfDialog);
