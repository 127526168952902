import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isParcelFetching, getParcels } from '../../../shared/api/core/parcels/parcels.selectors';

import { getParcelsSearch as getParcelsSearchApi, resetParcels as resetParcelsApi } from '../../../shared/api/core/parcels/parcels.api';
import CfAutocomplete from '../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { Parcel, ParcelsSearchParams } from '../../../shared/api/core/parcels/parcels.types';

interface Props {
  getParcelsSearch(params: ParcelsSearchParams): void;
  isEditing: boolean;
  isFetching: boolean;
  label: ReactNode;
  onChange(value: Parcel | null): void;
  parcelSuggestions: Parcel[];
  resetParcels(): void;
}

const handleRenderOption = (option: Parcel) =>
  option.localName;

const handleGetSelected = (option: Parcel, value?: Parcel | null) =>
  option.localName === value?.localName;

const handleGetLabel = (option?: Parcel | null) =>
  option?.localName || '';

const TelematicsParcelSelector: FC<Props> = ({
  getParcelsSearch,
  isEditing,
  isFetching,
  label,
  onChange,
  parcelSuggestions,
  resetParcels,
}) => {
  const fetchParcels = useCallback(localNameBlockNr => {
    getParcelsSearch({ localNameBlockNr, page: 0, rowsPerPage: 100 });
  }, [getParcelsSearch]);

  useEffect(() => {
    fetchParcels('');

    return () => {
      resetParcels();
    };
  }, [fetchParcels, resetParcels]);

  return (
    <CfAutocomplete
      disabled={!isEditing}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-selector"
      isFetching={isFetching}
      label={label}
      loadOptions={fetchParcels}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={parcelSuggestions}
      testId="parcel-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  parcelSuggestions: getParcels()(state),
  isFetching: isParcelFetching()(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelsSearch: getParcelsSearchApi,
      resetParcels: resetParcelsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsParcelSelector);
