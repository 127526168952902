import ErrorService from '../../../services/Error.service';

export const getItems = state => state.api.rules.items;
export const getTotalCount = state => state.api.rules.totalCount;
export const getIsFetchingRules = state => state.api.rules.isFetchingRules;
export const getErrorRules = state => ErrorService.getResErrorDto(state.api.rules.errorRules);

export const getRule = state => state.api.rules.item;
export const getIsFetchingRule = state => state.api.rules.isFetchingRule;
export const getErrorRule = state => ErrorService.getResErrorDto(state.api.rules.errorRule);
