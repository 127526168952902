import React, { Component } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

const styles = {
  scrollingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    height: '100%',
    alignItems: 'center',
  },
  scrollingContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  scrollingContentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
  },
  scrollingContent: {
    display: 'flex',
  },
  arrowButton: {
    width: 36,
    height: 36,
    padding: 0,
  },
  leftGradient: {
    zIndex: 1,
    width: 50,
    height: '100%',
    position: 'absolute',
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
  },
  rightGradient: {
    zIndex: 1,
    right: 0,
    width: 50,
    height: '100%',
    position: 'absolute',
    background: 'linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
  },
};

const SCROLL_STEP = 100;

class HorizontalScrolling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollMaxLeft: true,
      isScrollMaxRight: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onScrollUpdate);
    this.onScrollUpdate();
  }

  componentDidUpdate(prevProps) {
    const { refreshScrolling } = this.props;
    if (refreshScrolling !== prevProps.refreshScrolling) {
      this.onScrollUpdate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onScrollUpdate);
  }

  onScrollUpdate = () => {
    this.setState({
      isScrollMaxLeft: this.scrollbars && this.scrollbars.view && this.scrollbars.getValues().left === 0,
      isScrollMaxRight:
        this.scrollbars &&
        this.scrollbars.view &&
        (this.scrollbars.getValues().left === 1 ||
          this.scrollbars.getValues().clientWidth === this.scrollbars.getValues().scrollWidth),
    });
  };

  getBtnAppearence = isScrollMax => {
    const { removeButtons } = this.props;
    if (removeButtons) {
      return { display: isScrollMax ? 'none' : 'flex' };
    }
    return { visibility: isScrollMax ? 'hidden' : 'visible' };
  };

  scroll = scrollValue => {
    this.scrollbars.view.scroll({ left: this.scrollbars.view.scrollLeft + scrollValue, behavior: 'smooth' });
  };

  render() {
    const { children, classes, contentWrapperClass, refFnc } = this.props;
    const isScrollMaxLeft = this.state.isScrollMaxLeft;
    const isScrollMaxRight = this.state.isScrollMaxRight;

    return (
      <div className={classes.scrollingWrapper} ref={refFnc}>
        <IconButton
          className={classes.arrowButton}
          onClick={() => this.scroll(-SCROLL_STEP)}
          size="large"
          style={this.getBtnAppearence(isScrollMaxLeft)}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <div className={classes.scrollingContainer}>
          <div className={classes.leftGradient} style={{ display: isScrollMaxLeft ? 'none' : 'block' }} />
          <div className={classes.rightGradient} style={{ display: isScrollMaxRight ? 'none' : 'block' }} />
          <Scrollbars
            onScroll={this.onScrollUpdate}
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
            renderView={({ style, ...props }) => <div {...props} style={{ ...style, display: 'flex' }} />}
            ref={scrollbars => {
              this.scrollbars = scrollbars;
            }}
          >
            <div className={`${classes.scrollingContentWrapper} ${contentWrapperClass}`}>
              <div className={classes.scrollingContent}>{children}</div>
            </div>
          </Scrollbars>
        </div>
        <IconButton
          className={classes.arrowButton}
          onClick={() => this.scroll(SCROLL_STEP)}
          size="large"
          style={this.getBtnAppearence(isScrollMaxRight)}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    );
  }
}

HorizontalScrolling.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  refreshScrolling: PropTypes.bool,
  removeButtons: PropTypes.bool,
  refFnc: PropTypes.func,
  contentWrapperClass: PropTypes.string,
};

HorizontalScrolling.defaultProps = {
  refreshScrolling: false,
  removeButtons: false,
  refFnc: () => {},
  contentWrapperClass: '',
};

export default withStyles(styles)(HorizontalScrolling);
