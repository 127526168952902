import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  heading: {
    fontSize: 16,
  },
  item: {
    fontWeight: 500,
    margin: 8,
    display: 'flex',
  },
  subheading: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
});

function SelectionItemHeading(props) {
  const { classes, reversed } = props;
  return (
    <div className={classes.item} data-test="item" style={{ flexDirection: reversed ? 'column-reverse' : 'column' }}>
      <div className={classes.heading} data-test="item-heading">
        {props.children}
      </div>
      <div className={classes.subheading} data-test="item-subheading">
        {props.subheading}
      </div>
    </div>
  );
}

SelectionItemHeading.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reversed: PropTypes.bool,
};

SelectionItemHeading.defaultProps = {
  children: {},
  classes: {},
  subheading: '',
  reversed: false,
};

export default withStyles(styles)(SelectionItemHeading);
