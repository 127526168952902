import React from 'react';

import createHistory from 'history/createBrowserHistory';
import moment from 'moment';
import ReactDOM from 'react-dom';

import NavbarWrapper from './containers/NavbarWrapper/NavbarWrapper';

/* @ngInject */
export default function ImpersonationController(
  $element,
  $scope,
  $state,
  $transitions,
  $rootScope,
  BACKEND_OPTIONS,
  LOCALES,
  Localization,
  Auth,
  UserStorage,
  ResponseErrorInterceptor,
  FarmsStorage,
  PRODUCT_FRUITS_ID,
) {
  const props = {
    langId: null,
    farm: this.farm,
    currState: $state.current.name,
    logout: Auth.logout,
    user: UserStorage.getUser(),
    langs: LOCALES.langs,
    setCurrLangId: Localization.setCurrLangId,
    farms: FarmsStorage.getFarms(),
    ngSwitchFarm: farmId => {
      this.switchFarm({ farmId });
    },
    ngImpersEnable: this.impersEnable,
    productFruitsId: PRODUCT_FRUITS_ID,
    isAdmin: (() => {
      const user = UserStorage.getUser();
      const authorities = user.authorities;
      return authorities.includes('ROLE_ADMIN');
    })(),
  };

  let history = null;

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  const deregisterOnTransitionSuccess = $transitions.onSuccess({}, transition => {
    props.currState = transition.to().name;
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = () => {
    setTimeout(() => {
      history = createHistory();
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      moment.locale(langId);
      render();
    });
  };

  function render() {
    $element[0].click();
    ReactDOM.render(<NavbarWrapper history={history} ngProps={props} />, $element[0]);
  }
}
