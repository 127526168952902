/**
 * Created by ondrejzvara on 24.3.17.
 */

import head from 'lodash/head';

import Intercom from '../../shared/services/Intercom.service';
import Hotjar from '../../shared/services/Hotjar.service';
import GoogleAnalytics from '../../shared/services/GoogleAnalytics.service';

export default class FarmController {
  /* @ngInject */
  constructor($state, farms, farm, farmId, Auth, $timeout) {
    this.tempFarmId = null;
    this.items = [];
    this.currFarm = null;
    this.$state = $state;
    this.auth = Auth;
    this.farms = farms;
    this.farm = farm;
    this.farmId = farmId;
    this.$timeout = $timeout;
  }

  $onInit() {
    // we need to detect that user is still impersonating after page refresh
    this.isImpersEnabled = this.$state.params.isImpersEnabled || this.auth.isImpersActive();

    if (this.isImpersEnabled) {
      Hotjar.shutdown();
      GoogleAnalytics.disable();
    }
    Intercom.hideLauncher();

    this.items = _.cloneDeep(this.farms);
    this.setCurrFarm(this.farm.id);
  }

  getFarmById(id) {
    return _.find(this.items, obj => obj.id === id);
  }

  setCurrFarm(newId) {
    const active = this.getFarmById(newId) || null;

    const index = this.items.indexOf(active) || null;
    if (active && index > -1) {
      // move currFarm to items
      if (this.currFarm) {
        this.items.push(this.currFarm);
      }

      // copy active to currFarm
      this.currFarm = active;

      // remove from items
      this.items.splice(index, 1);

      // order items
      this.sortFarmsAsc();
    }
  }

  sortFarmsAsc() {
    this.items = _.sortBy(this.items, obj => obj.code);
  }

  updateState(newId) {
    if (newId === this.currFarm.id) {
      return;
    }

    const { isImpersEnabled } = this;

    let state = this.$state.current.name;
    const regex = /(^farm.active\.)([^\.]*)/g;
    state = head(state.match(regex));

    // similar to impersonation - these are cases when base state is a little different than normal
    switch (state) {
      case 'farm.active.classifiers':
        this.$state.go('farm.active.classifiers.private.seeds', { farmId: newId, isImpersEnabled });
        break;
      case 'farm.active.action':
        this.$state.go('farm.active.actions', { farmId: newId, isImpersEnabled });
        break;
      case 'farm.active.store':
        this.$state.go('farm.active.stores', { farmId: newId, isImpersEnabled });
        break;
      default:
        this.$state.transitionTo(
          state,
          { farmId: newId, isImpersEnabled },
          {
            reload: true,
            inherit: false,
            notify: true
          }
        ).then(() => this.setCurrFarm(newId));
        break;
    }
  }

  impersEnable() {
    this.$timeout(() => {
      this.isImpersEnabled = true;
    });
  }

  impersDisable() {
    this.$timeout(() => {
      this.isImpersEnabled = false;
    });
  }
}
