import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { OTHER_ACTION_HARVEST_CODE } from '../../actionOther.constants';

import LocalStorage from '../../../../../shared/services/LocalStorage.service';
import ActionStateMapper from '../../../shared/services/ActionStateMapper.service';
import ActionToMapper from '../../../shared/services/ActionToMapper.service';
import { FormContext } from '../../actionOther.context';
import ActionOther from '../../containers/ActionOther/ActionOther';
import { filterOnlySownParcels } from '../../helpers/others.helpers';

const styles = theme => ({
  formButtons: {},
  button: {
    margin: '10px',
  },
  helperText: {
    position: 'absolute',
    bottom: -22,
  },
  date: {
    marginTop: 10,
  },
  actionInfo: {
    marginTop: 30,
    marginBottom: 30,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    padding: '16px',
  },
  header: {
    padding: '16px',
    display: 'inline-flex',
    color: '#737373',
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
});

class ActionOtherFormWrapper extends Component {
  validateForm = values => {
    const errors = {};
    if (!values.parcels?.length > 0) {
      errors.parcels = 'error';
    }
    return errors;
  };

  // TODO: Saving of newly created subtractable areas
  // When deleting of subtractable areas is ready on back end,
  // we can save the newly created subtractableAres that are applied on the parcels in this action.
  // This mechanism is already implemented in EPH form,
  // but here, we will save the new subtractable areas when we can also delete them.
  saveAction = (values) => {
    const { actionType } = values;
    let parcelsToSave = values.parcels;

    // Filter only sown parcels for harvet action
    if (actionType === OTHER_ACTION_HARVEST_CODE) parcelsToSave = filterOnlySownParcels(values.parcels);

    const valuesToSave = {
      ...values,
      parcels: [...parcelsToSave],
    };

    const data = ActionToMapper.createNewOtherActionTo(valuesToSave, this.props.intl.locale);

    this.props.createAction(data).then(res => {
      const { lsName } = this.context;
      LocalStorage.removeFromLocalStorage(lsName);
      this.context.onEditingEnd();
      if (!res.error) {
        this.props.goToActions();
      }
    });
  };

  updateAction = values => {
    const data = ActionToMapper.createNewOtherActionTo(values, this.props.intl.locale);

    this.props.updateAction(this.props.action.id, data).then(res => {
      if (!res.error) {
        this.props.goToActions();
      }
    });
  };

  handleSubmit = (values) => {
    if (this.context.isExisting) {
      this.updateAction(values);
    } else {
      this.saveAction(values);
    }
  };

  render() {
    const { action, classes, intl } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        validate={this.validateForm}
        validateOnBlur={true}
        validateOnChange={false}
        initialValues={action ?
          ActionStateMapper.mapOtherActionState(action, intl.locale) : { ...this.context.initialValues }
        }
      >
        <ActionOther
          classes={classes}
        />
      </Formik>
    );
  }
}

ActionOtherFormWrapper.contextType = FormContext;

ActionOtherFormWrapper.propTypes = {
  action: PropTypes.object,
  createAction: PropTypes.func,
  updateAction: PropTypes.func,
  classes: PropTypes.object.isRequired,
  goToActions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ActionOtherFormWrapper.defaultProps = {
  action: null,
  createAction: () => {},
  updateAction: () => {},
};

export default injectIntl(withStyles(styles)(ActionOtherFormWrapper));
