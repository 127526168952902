import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const infoBox = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  width: 'fit-content',
  maxWidth: '70%',
  margin: 'auto',
  background: 'rgba(50, 50, 50, 0.9)',
  boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
  padding: '16px 24px',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'white',
  cursor: 'auto',
  zIndex: 9999,
  visibility: 'hidden',
};

export default function MapHintControl(props) {
  return (
    <div id="infoBox" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} style={infoBox}>
      <FormattedMessage id="Map.selectPlacement" />{' '}
      <span style={{ opacity: 0.5 }}>
        <FormattedMessage id="Map.placementMouseMove" />
      </span>
    </div>
  );
}

MapHintControl.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

MapHintControl.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};
