import React from 'react';

import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';

const styles = theme => ({
  root: {
    minHeight: '28px',
    minWidth: 'auto',
    height: '28px',
    borderRadius: '20px',
    padding: '0 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  label: {
    position: 'relative',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  light: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    color: theme.palette.text.primary,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const ToolbarBtn = props => {
  const { active, callback, classes, disabled, icon, light, productFruits, testId, translationId } = props;
  const Icon = icon;
  return (
    <ToolbarSection background={false}>
      <Button
        classes={{ root: classes.root, label: classes.label }}
        className={`${active ? classes.active : ''} ${light ? classes.light : ''}`}
        data-test={testId}
        disabled={disabled}
        onClick={callback}
        product-fruits={productFruits}
      >
        {Icon && <Icon className={classes.leftIcon} />}
        <FormattedMessage id={translationId} />
      </Button>
    </ToolbarSection>
  );
};

ToolbarBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool,
  translationId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  icon: PropTypes.func,
  light: PropTypes.bool,
  productFruits: PropTypes.string,
};

ToolbarBtn.defaultProps = {
  disabled: false,
  testId: 'remove-all-measurement',
  callback: () => {},
  icon: null,
  light: false,
  active: false,
};

export default withStyles(styles)(ToolbarBtn);
