/**
 )* Created by jenc
 */


/*@ngInject*/ 
export default function ZoneEntity() {

    function Zone(obj) {
        initFromResource(this, obj);
    }

    function initFromResource(entity, obj) {
        var plain = obj.plain();
        if (plain) {
            for (var key in plain) {
                if (plain.hasOwnProperty(key)) {
                    entity[key] = plain[key];
                }
            }
        }
    }

    return Zone;
}
