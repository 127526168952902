import 'rc-slider/assets/index.css';
import React, { Component, Fragment } from 'react';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import WeatherRadarPlaceholder from './WeatherRadarPlaceholder';

const styles = {
  radarWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: '0px 24px',
  },
  slider: {
    width: '100%',
    marginLeft: 25,
    marginRight: 25,
  },
  controlButtons: {
    width: 36,
    height: 36,
    padding: 0,
  },
};

const FILE_FORMAT = 'YYYYMMDD.HHmm';

class WeatherRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 13,
      plays: false,
    };
    this.length = 1;
  }

  componentDidUpdate(prevProps) {
    const { isFetching, radarList } = this.props;
    if (prevProps.isFetching && !isFetching && radarList.length) {
      this.initRadar(radarList);
    }
  }

  componentWillUnmount() {
    this.stopRadarCycle();
    this.props.unsetRadarLayers();
  }

  getMarks() {
    const arrayLength = this.dates.length;
    const marks = {};
    for (let i = 0; i < arrayLength; i += 1) {
      const date = this.dates[i];
      if ((i + 1) % 2 !== 0) {
        marks[i + 1] = moment(date)
          .local()
          .format('H:mm');
      }
    }
    return marks;
  }

  getNextStep(step = this.state.step) {
    let nextStep = step + 1;
    if (nextStep > this.dates.length) {
      nextStep = 1;
    }
    return nextStep;
  }

  initRadarCycle() {
    this.timer = setInterval(() => {
      const newStep = this.getNextStep();
      const nextStep = this.getNextStep(newStep);
      this.props.moveRadarLayer(this.dates[nextStep - 1].format(FILE_FORMAT));
      this.setState({ step: newStep });
    }, 650);
  }

  initRadar(radarList) {
    this.dates = radarList.map(name => moment.utc(name.slice(11, 24), 'YYYYMMDD.HHmm'));
    this.props.setRadarLayers(this.dates[this.dates.length - 1].format(FILE_FORMAT), this.dates[0].format(FILE_FORMAT));
    this.marks = this.getMarks();
    this.length = this.dates.length;
    this.pauseRadar();
  }

  playRadar() {
    this.initRadarCycle();
    this.setState({ plays: true });
  }

  pauseRadar() {
    this.stopRadarCycle();
    this.setState({ plays: false });
  }

  handleSliderChange(step) {
    this.setState({ step, plays: false });
    this.stopRadarCycle();
    this.props.setRadarLayers(
      this.dates[step - 1].format(FILE_FORMAT),
      this.dates[this.getNextStep(step) - 1].format(FILE_FORMAT),
    );
  }

  stopRadarCycle() {
    clearInterval(this.timer);
  }

  render() {
    const { classes, isFetching } = this.props;

    return (
      <div className={classes.radarWrapper} data-test="weather-radar-data">
        {isFetching ? (
          <WeatherRadarPlaceholder />
        ) : (
          <Fragment>
            {this.state.plays ? (
              <IconButton
                className={classes.controlButtons}
                disabled={!this.state.plays}
                onClick={() => this.pauseRadar()}
                size="large">
                <PauseIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.controlButtons}
                color="primary"
                disabled={this.state.plays}
                onClick={() => this.playRadar()}
                size="large">
                <PlayArrowIcon />
              </IconButton>
            )}
            <div className={classes.slider}>
              <Slider
                activeDotStyle={{ borderColor: '#B2B2B2', height: 14, width: 14, marginLeft: -7, marginBottom: -2 }}
                dots
                dotStyle={{ borderColor: '#B2B2B2', height: 14, width: 14, marginLeft: -7, marginBottom: -2 }}
                marks={this.marks}
                max={this.length}
                min={1}
                onChange={e => this.handleSliderChange(e)}
                railStyle={{ color: '#ABABAB', backgroundColor: '#B2B2B2', height: 2 }}
                trackStyle={{ color: '#ABABAB', backgroundColor: '#B2B2B2', height: 2 }}
                value={this.state.step}
                handleStyle={{
                  backgroundColor: '#00a179',
                  borderColor: '#00a179',
                  height: 14,
                  width: 14,
                  marginLeft: -7,
                  marginTop: -6,
                }}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

WeatherRadar.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  unsetRadarLayers: PropTypes.func.isRequired,
  moveRadarLayer: PropTypes.func.isRequired,
  setRadarLayers: PropTypes.func.isRequired,
  radarList: PropTypes.array,
};

WeatherRadar.defaultProps = {
  radarList: [],
};

export default withStyles(styles)(WeatherRadar);
