import angular from 'angular';

import userController from './user.controller';

const userComponent = {
  bindings: {
    farm: '<',
  },
  controller: userController,
};

export default angular
  .module('app.core.user', [])
  .config(config)
  .component('userComponent', userComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('farm.active.user', {
      url: '/user',
      abstract: true,
      views: {
        'farm@farm.active': {
          component: 'userComponent',
        },
      },
    })
    .state('farm.active.user.profile', {
      url: '/profile',
      views: {
        'farm@farm.active': {
          component: 'userComponent',
        },
      },
    })
    .state('farm.active.user.password', {
      url: '/password',
      views: {
        'farm@farm.active': {
          component: 'userComponent',
        },
      },
    });
}
