import React, { Component } from 'react';

import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  inputContainer: {
    width: '100%',
    color: 'inherit',
    position: 'inherit',
    padding: 0,
  },
  input: {
    fontSize: 13,
    padding: '1px 0px',
  },
  paper: {
    padding: '15px 30px 15px 15px',
    fontSize: '13px',
    position: 'relative',
  },
  noNotes: {
    color: theme.palette.grey[400],
    fontSize: '13px',
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 6,
  },
  icon: {
    width: 18,
    height: 18,
  },
});

class EditableNote extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      const editValue = this.props.initialValue;
      this.setState({
        editValue,
      });
    }
  }

  onChange = e => {
    this.setState({
      editValue: e.target.value,
    });
  };

  onKeyDown = e => {
    switch (e.key) {
      case 'Escape':
        this.setInitialState();
        break;
      default:
        break;
    }
  };

  startEditing() {
    this.setState({
      isEditing: true,
      editValue: this.props.initialValue,
    });
    setTimeout(() => {
      this.inputRef.focus();
    }, 0);
  }

  setInitialState = () => {
    this.setState(this.initialState());
  };

  initialState() {
    return {
      isEditing: false,
      isConfirming: false,
      editValue: this.props.initialValue,
    };
  }

  submitEditing() {
    if (this.state.isEditing && this.state.editValue !== this.props.initialValue) {
      this.setState({
        isEditing: false,
        editValue: this.state.editValue,
        isConfirming: true,
      });
      this.props.onConfirmEditing(this.state.editValue).then(() =>
        this.setState({
          isConfirming: false,
        }),
      );
    } else {
      this.setState({
        isEditing: false,
      });
    }
  }

  // TODO ulozit i po nejake dobe nejen na odklik
  render() {
    const { classes, defaultValue, inputTestId } = this.props;
    const { editValue, isConfirming, isEditing } = this.state;
    return (
      <Paper className={classes.paper} onClick={() => this.startEditing()}>
        <InputBase
          autoFocus={isEditing}
          className={classes.inputContainer}
          data-test={inputTestId}
          disabled={isConfirming}
          id="multiline-flexible"
          margin="dense"
          multiline
          onBlur={() => this.submitEditing()}
          onChange={e => this.onChange(e)}
          onKeyDown={e => this.onKeyDown(e)}
          placeholder={defaultValue}
          spellCheck={isEditing}
          // TODO remove spellcheck underline after blur, currently it stays there
          value={editValue}
          classes={{
            input: classes.input,
          }}
          inputRef={input => {
            this.inputRef = input;
          }}
        />
        <IconButton
          className={classes.button}
          color="default"
          data-test="edit-icon"
          disabled={isEditing || isConfirming}
          onClick={() => this.startEditing()}
          size="large">
          <CreateIcon className={classes.icon} />
        </IconButton>
      </Paper>
    );
  }
}

EditableNote.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onConfirmEditing: PropTypes.func.isRequired,
  classes: PropTypes.object,
  inputTestId: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
};

EditableNote.defaultProps = {
  initialValue: '',
  classes: {},
  inputTestId: 'editable-input',
};

export default withStyles(styles)(EditableNote);
