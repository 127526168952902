import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SensorAdminAssignFarm from '../../containers/SensorAdminAssignFarm/SensorAdminAssignFarm';

const styles = {
  button: {
    padding: '6px 12px',
  },
};

class SensorAdminBulkActions extends Component {
  isDisabled = selected => selected.length < 1;

  render() {
    const { classes, selected } = this.props;
    const isDisabled = this.isDisabled(selected);
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid className={classes.assignItem} item xs={12}>
          <SensorAdminAssignFarm
            classes={{ button: classes.button }}
            disabled={isDisabled}
            fontSize={16}
            noFarmLabel={<FormattedMessage id="SensorsAdmin.assign-farm" />}
            onAssignNodesToFarm={farm => this.props.onAssignNodesToFarm(selected, farm)}
            selectedLength={selected.length}
          />
        </Grid>
      </Grid>
    );
  }
}

SensorAdminBulkActions.propTypes = {
  selected: PropTypes.array.isRequired,
  onAssignNodesToFarm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

SensorAdminBulkActions.defaultProps = {};

export default withStyles(styles)(SensorAdminBulkActions);
