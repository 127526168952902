import { RSAAAction, RSAAResultAction } from 'redux-api-middleware';

import * as types from './telematicsDetail.constants';

import { calculateAreaFromEquipment } from '../../shared/api/telematics/drives/drives.api';

import { DriveRecalculateTo } from '../../shared/api/telematics/telematics.types';
import { TelematicsItemFormValues } from '../telematics.types';

export const createDriveSaga = (
  values: TelematicsItemFormValues,
  parcelId: string | null | undefined,
) => ({
  type: types.SAGA_CREATE_DRIVE,
  values,
  parcelId,
});

export const editDriveSaga = (
  driveId: string,
  values: TelematicsItemFormValues,
  parcelId: string | null | undefined,
) => ({
  type: types.SAGA_EDIT_DRIVE,
  driveId,
  values,
  parcelId,
});

export const calculateArea = (driveId: number, equipment: DriveRecalculateTo, changeHandler: (area: number) => void) =>
  (dispatch: (action: RSAAAction) => Promise<RSAAResultAction<{area: number}>>) => {
    dispatch(calculateAreaFromEquipment(driveId, equipment)).then(res => {
      if (res.error) return;
      if (res.payload && typeof res.payload?.area === 'number') {
        changeHandler(res.payload.area);
      }
    });
  };

export const setFormSubmission = (bool: boolean) => ({
  type: types.SET_FORM_SUBMISSION,
  payload: bool,
});

export const resetFormSubmission = () => ({
  type: types.RESET_FORM_SUBMISSION,
});

export const setFormSubmissionOK = () => ({
  type: types.SET_FORM_SUBMISSION_OK,
});

export const setFormSubmissionError = () => ({
  type: types.SET_FORM_SUBMISSION_ERROR,
});
