import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfPrimaryTab from '../../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';

class ParcelDetailTabs extends Component {
  constructor(props) {
    super(props);
    const { tabs } = props;
    this.orderedTabs = [tabs.overview, tabs.activities, tabs.monitoring, tabs.management];
  }

  handleChange = (event, value) => {
    const { activateTab, history, match, tabs } = this.props;
    const { farmId, parcelId } = match.params;
    history.push(`/farm/${farmId}/parcels/${parcelId}/${tabs[value].path}`);
    activateTab(value);
  };

  render() {
    const { activeTab, width } = this.props;
    const variant = width === 'xs' ? 'scrollable' : 'standard';

    return (
      <CfPrimaryTabs
        allowScrollButtonsMobile={true}
        centered={width !== 'xs'}
        onChange={this.handleChange}
        scrollButtons={true}
        tabValue={activeTab}
        variant={variant}
      >
        {this.orderedTabs.map(tab => (
          <CfPrimaryTab
            data-test={tab.id}
            key={tab.id}
            label={<FormattedMessage id={`ParcelDetailTabs.${tab.id}`} />}
            style={{ flexGrow: 1 }}
            value={tab.id}
          />
        ))}
      </CfPrimaryTabs>
    );
  }
}

ParcelDetailTabs.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  activateTab: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default ParcelDetailTabs;
