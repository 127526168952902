import React from 'react';

import Tab from '@mui/material/Tab';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  tab: {
    color: theme.palette.grey[500],
    fontSize: 16,
    lineHeight: 1.25,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    minWidth: 50,
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      padding: '6px 24px',
    },
  },
});

/**
 * Primary tab with predefined styles to be used inside CfPrimaryTabs.
 * Other extra props not defined here will be propagated to MUI Tab component.
 * @version 1.0.0
 */
function CfPrimaryTab(props) {
  const { classes, label, ...otherProps } = props;
  return (
    <Tab
      label={label}
      classes={{
        root: classes.tab,
      }}
      {...otherProps}
    />
  );
}

CfPrimaryTab.propTypes = {
  /** Style classes */
  classes: PropTypes.object.isRequired,
  /** Label of tab visible to the user */
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default withStyles(styles)(CfPrimaryTab);
