import { Component, createElement } from 'react';

import trim from 'lodash/trim';
import PropTypes from 'prop-types';

import CfReduxFormTextField from '../CfReduxFormTextField/CfReduxFormTextField';

export default class CfTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      input: { value },
    } = this.props;
    if (value !== prevProps.input.value) {
      this.setState({
        value,
      });
    }
  }

  render() {
    const {
      input: { onBlur, onChange, ...inputProps },
      ...props
    } = this.props;

    return createElement(CfReduxFormTextField, {
      value: this.state.value,
      onChange: evt => {
        this.setState({
          value: evt.target.value,
        });
      },
      onBlur: () => {
        const trimmedValue = trim(this.state.value);
        onChange(trimmedValue);
        onBlur(trimmedValue);
      },
      ref: 'component',
      fullWidth: true,
      input: { ...inputProps },
      ...props,
    });
  }
}

CfTextField.propTypes = {
  input: PropTypes.object.isRequired,
};
