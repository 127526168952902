export const zonesColors = {
  CAB: [
    { id: 1, color: '#FFFFD9', borderColor: '#ABABAB' },
    { id: 2, color: '#EFF9B6', borderColor: '#ABABAB' },
    { id: 3, color: '#BBE098' },
    { id: 4, color: '#93C56E' },
    { id: 5, color: '#72AA45' },
    { id: 6, color: '#5A912F' },
    { id: 7, color: '#4A7D27' },
    { id: 8, color: '#3A6920' },
    { id: 9, color: '#274F16' },
    { id: 10, color: '#16390E' },
  ],
  CW: [
    { id: 1, color: '#FFFFD9', borderColor: '#ABABAB' },
    { id: 2, color: '#EFF9B6', borderColor: '#ABABAB' },
    { id: 3, color: '#C9E9AF' },
    { id: 4, color: '#A6D0B9' },
    { id: 5, color: '#88B9C6' },
    { id: 6, color: '#669EC5' },
    { id: 7, color: '#4081B4' },
    { id: 8, color: '#23649B' },
    { id: 9, color: '#154474' },
    { id: 10, color: '#052652' },
  ],
  LAI: [
    { id: 1, color: '#FFFFD9', borderColor: '#ABABAB' },
    { id: 2, color: '#EFF9B6', borderColor: '#ABABAB' },
    { id: 3, color: '#CAE9AF' },
    { id: 4, color: '#9DD6A6' },
    { id: 5, color: '#6FC398' },
    { id: 6, color: '#45AC84' },
    { id: 7, color: '#2A8F6C' },
    { id: 8, color: '#247150' },
    { id: 9, color: '#1B5534' },
    { id: 10, color: '#163914' },
  ],
};
