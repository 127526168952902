import { getMapDragging } from '../../selectors/editor.selectors';
import { getMainMap, getLayers } from '../../selectors/map.selectors';

import { setAllCropsVisibility } from '../crops/crops.actions';
import { editorSetSelected, handleResponse, editorSetMapDragging } from '../editor/editor.actions';
import { setHoverStyleEL, setMouseClickEL, setMouseMoveEL, removeEL } from '../eventListener/eventListener.actions';
import { setDoubleClickZoomIA, removeDoubleClickZoomIA } from '../interaction/interaction.actions';
import { refreshDefaultStyles } from '../style/style.actions';

import * as sortOrder from '../../../../shared/constants/sortOrder.constants';
import * as types from './map.constants';

import { getFarmById } from '../../../../shared/api/core/farms/farms.api';
import { getParcelGeometryById } from '../../../../shared/api/core/geometry/geometry.api';
import {
  getParcelActions,
  getParcelById,
  patchParcel,
  resetParcel,
  getParcelsByName,
  resetParcels,
} from '../../../../shared/api/core/parcels/parcels.api';
import Feature from '../../services/Feature.service';

export const initMapSaga = (farmId, langId, countryCode, api, parcelId) => ({
  type: types.INIT_MAP_SAGA,
  farmId,
  langId,
  countryCode,
  api,
  parcelId,
});

export const setMapInitSuccess = ({
  type: types.MAP_INIT_SUCCESS,
});

export const storeServiceWrapper = (key, value) => ({
  type: types.STORE_SERVICE_WRAPPER,
  key,
  value,
});

export const setMapContext = context => dispatch => {
  dispatch({
    type: types.SET_MAP_CONTEXT,
    context,
  });
};

export const resetMap = () => dispatch => {
  dispatch({
    type: types.RESET_MAP,
  });
};

export const zoomToFarm = () => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToFarm();
};

export const zoomToGeometry = (geometry, scaleFactor) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToGeometry(geometry, scaleFactor);
};

export const zoomToExtent = (extent, scaleFactor) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToExtent(extent, scaleFactor);
};

export const zoomToCoords = (coords, zoom) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToCoords(coords, zoom);
};

export const setMapFetching = isFetching => dispatch => {
  dispatch({
    type: types.SET_MAP_FETCHING,
    isFetching,
  });
};

export const setDefaultSelectEL = () => dispatch => {
  dispatch(setMouseClickEL(onMouseClick, refreshDefaultStyles, 'defaultSelectELKey'));
};

export const setDefaultHoverEL = () => dispatch => {
  dispatch(setHoverStyleEL(f => Feature.isFeature(f), refreshDefaultStyles, 'defaultHoverELKey'));
};

export const setMapGrabEL = () => dispatch => {
  dispatch(setMouseMoveEL((f, evt) => onMapDrag(evt), undefined, 'mapDragELKey'));
};

const onMouseClick = feature => dispatch => {
  if (!feature) {
    dispatch(editorSetSelected([]));
    dispatch(clearParcelDetail());
  } else {
    const featureId = feature.get('id');
    dispatch(editorSetSelected({ id: featureId }));
    dispatch(fetchParcelDetail(featureId));
  }
};

const onMapDrag = evt => (dispatch, getState) => {
  const isMapDragging = getMapDragging(getState());
  if (!evt.dragging && isMapDragging) {
    dispatch(mapSetCursor(''));
    dispatch(editorSetMapDragging(false));
  } else if (evt.dragging && !isMapDragging) {
    dispatch(editorSetMapDragging(true));
    dispatch(mapSetCursor('grabbing'));
  }
};

export const activateParcelDetail = parcelId => dispatch => {
  dispatch(editorSetSelected({ id: parcelId }));
  dispatch(fetchParcelDetail(parcelId));
  dispatch(getParcelGeometryById(parcelId)).then(({ payload }) => {
    dispatch(zoomToGeometry(payload.geometry, 0.5));
  });
};

export const fetchParcelDetail = featureId => dispatch =>
  Promise.all([dispatch(getParcelById(null, featureId)), dispatch(fetchParcelLastAction(featureId))]);

export const fetchParcelLastAction = parcelId => dispatch =>
  dispatch(
    getParcelActions(null, parcelId, {
      page: 0,
      rowsPerPage: 1,
      order: sortOrder.DESC,
      orderBy: 'dateStart',
    }),
  );

export const clearParcelDetail = () => dispatch => {
  dispatch(resetParcel());
  // necessary when closing parcel detail by icon
  dispatch(editorSetSelected([]));
  dispatch(refreshDefaultStyles());
};

export const updateParcelName = (parcelId, parcelName) => (dispatch, getState) =>
  dispatch(patchParcel(parcelId, { localName: parcelName })).then(() => {
    const layersService = getLayers(getState());
    layersService.refreshParcelLabels();
  });

export const fetchBoundingBox = farmId => dispatch =>
  dispatch(getFarmById(farmId))
    .then(handleResponse)
    .then(farm => farm.boundingBox);

export const mapSetCursor = value => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.setCursor(value);
};

export const fetchMapSearchSuggestions = searchInput => dispatch => dispatch(getParcelsByName(undefined, searchInput));

export const clearMapSearchSuggestions = () => dispatch => {
  dispatch(resetParcels());
};

export const enablePreview = () => (dispatch) => {
  dispatch(setAllCropsVisibility(true));
  dispatch(setDoubleClickZoomIA());
  dispatch(setDefaultSelectEL());
  dispatch(setDefaultHoverEL());
};

export const disablePreview = () => (dispatch) => {
  dispatch(clearParcelDetail());
  dispatch(removeDoubleClickZoomIA());
  dispatch(removeEL('defaultHoverELKey'));
  dispatch(removeEL('defaultSelectELKey'));
  dispatch(setAllCropsVisibility(false));
};
