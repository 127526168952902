/**
 * Created by ondrejzvara on 10.12.15.
 */

/* @ngInject */

export default function cfPorEditor() {
  return {
    restrict: 'E',
    scope: true,
    bindToController: {
      item: '=por',
      edit: '=isEditing'
    },
    template: require('./cf-por-editor.html'),
    controller: cfPorEditorController,
    controllerAs: 'PorCtrl'
  };
}

/* @ngInject */
function cfPorEditorController($scope, ChemistriesResource, Modal, ObjTpl, OrganismsResource, Unit, $state, $timeout) {
  const vm = this;
  vm.itemBckp = {};

  vm.template = 'app/assets/templates/por/por.non-edit.html';
  vm.isPrivate = $state.current.name.indexOf('private') > -1;

  vm.activeSubstance = null;
  vm.activeSubstanceList = [];

  vm.boolSelect = [{ name: 'nedefinovano', value: null }, { name: 'Ano', value: true }, { name: 'Ne', value: false }];

  vm.waterUnits = [{ id: 'l' }, { id: 'l/ha' }];

  vm.withdrawalPeriods = ['dny', 'AT'];

  $scope.$watch(
    () => vm.item,
    (newValue) => {
      if (!newValue || _.isEmpty(newValue)) {
        return;
      }

      vm.item = newValue;

      vm.itemBckp = null;
      vm.activeSubstanceList = prepareWorkingActionSubstanceList();

      _.each(vm.item.clMaterialRestrictionList, (item) => {
        // this is a hack due to JSOG coppying references to materialDosageUnit not by value
        // but by reference, which in the end causes waterDosageUnit and  materialDosageUnit
        // to point to the same object (in case if both are initially the same objects)
        if (item.waterDosageUnit) {
          const waterDosageId = item.waterDosageUnit.id;
          item.waterDosageUnit = { id: waterDosageId };
        }
      });
    }
  );

  $scope.$watch(
    () => vm.edit,
    (newValue) => {
      if (newValue) {
        vm.itemBckp = _.cloneDeep(vm.item);
        // dirty hack so that adding new applications with existing POR works OK
        const tempList = vm.item.clMaterialRestrictionList;
        vm.item.clMaterialRestrictionList = [];
        vm.template = 'app/assets/templates/por/por.edit.html';
        $timeout(() => {
          vm.item.clMaterialRestrictionList = tempList;
        });
      } else {
        if (vm.itemBckp) {
          vm.item = _.cloneDeep(vm.itemBckp);
        }
        vm.template = 'app/assets/templates/por/por.non-edit.html';
      }
    }
  );

  vm.searchBioFunction = searchBioFunction;
  vm.searchSubstance = searchSubstance;

  vm.addPorApplication = addPorApplication;
  vm.removeApplication = removeApplication;

  vm.addActiveSubstance = addActiveSubstance;
  vm.removeActiveSubstance = removeActiveSubstance;

  vm.changeWithdrawlPeriod = changeWithdrawlPeriod;

  vm.createCropChangeCallback = createCropChangeCallback;
  vm.removeCrop = removeCrop;
  vm.createOrganismChangeCallback = createOrganismChangeCallback;
  vm.removeOrganism = removeOrganism;

  activate();

  function activate() {
    getUnits();
  }

  function prepareWorkingActionSubstanceList() {
    if (
      vm.item.clMaterialActiveSubstanceList &&
      vm.item.clMaterialActiveSubstanceList[0] &&
      vm.item.clMaterialActiveSubstanceList[0].clMaterialSubstance.name
    ) {
      return vm.item.clMaterialActiveSubstanceList[0].clMaterialSubstance.name.split(',');
    }

    return [];
  }

  function getUnits() {
    /* jshint camelcase:false */
    return Unit.getUnitsPor().then((data) => {
      vm.units = data;
      return data;
    });
  }

  function searchBioFunction(str) {
    /* jshint camelcase:false */
    return ChemistriesResource.getChemistryBioFuntions({}, { search: str });
  }

  function searchSubstance(str) {
    /* jshint camelcase:false */
    return ChemistriesResource.getChemistryActiveSubstance({}, { search: str });
  }

  function addPorApplication() {
    const newApp = ObjTpl.getObjTpl('porApp');
    newApp.materialAppliable = _.cloneDeep(vm.item);
    vm.item.clMaterialRestrictionList.push(newApp);
  }

  function removeApplication(item) {
    if (!_.isObject(item) || _.isEmpty(item)) {
      return;
    }

    const config = {
      size: 'sm',
      templateUrl: 'app/assets/templates/por/por.deletePorApplication.html'
    };

    return Modal.activateModal(config).then(() => {
      const index = vm.item.clMaterialRestrictionList.indexOf(
        _.find(vm.item.clMaterialRestrictionList, obj => obj.id === item.id)
      );

      vm.item.clMaterialRestrictionList.splice(index, 1);
    });
  }

  function createCropChangeCallback(item) {
    return function (state, crop) {
      onCropChange(state, crop, item);
    };
  }

  function onCropChange(state, crop, item) {
    if (state === 'SELECTED') {
      item.clCropList.push(crop);
      delete item.tmpCrop;
    }
  }

  function removeCrop(item, crop) {
    if (!_.isObject(crop) || _.isEmpty(crop)) {
      return;
    }

    const index = item.clCropList.indexOf(_.find(item.clCropList, obj => obj.id === crop.id));

    item.clCropList.splice(index, 1);
  }

  function createOrganismChangeCallback(item) {
    return function (state, organism) {
      onOrganismChange(state, organism, item);
    };
  }

  function onOrganismChange(state, organism, item) {
    if (state === 'SELECTED') {
      item.clMaterialRestrictionOrganisms.push({ organism });
      delete item.tmpOrg;
    } else if (state === 'CREATE') {
      OrganismsResource.createOrganism(organism).then((result) => {
        item.clMaterialRestrictionOrganisms.push({ organism: result });
        delete item.tmpOrg;
      });
    }
  }

  function removeOrganism(item, org) {
    if (!_.isObject(org) || _.isEmpty(org)) {
      return;
    }

    const index = item.clMaterialRestrictionOrganisms.indexOf(
      _.find(item.clMaterialRestrictionOrganisms, obj => obj.id === org.id)
    );

    item.clMaterialRestrictionOrganisms.splice(index, 1);
  }

  function addActiveSubstance() {
    if (vm.activeSubstance) {
      vm.activeSubstanceList.push(vm.activeSubstance);
      vm.activeSubstance = null;

      joinActionSubstanceListIntoHackUberItem();
    }
  }

  function joinActionSubstanceListIntoHackUberItem() {
    if (!vm.item.clMaterialActiveSubstanceList || !vm.item.clMaterialActiveSubstanceList[0]) {
      vm.item.clMaterialActiveSubstanceList = [];
      vm.item.clMaterialActiveSubstanceList[0] = { clMaterialSubstance: { name: null } };
    }

    vm.item.clMaterialActiveSubstanceList[0].clMaterialSubstance.name = vm.activeSubstanceList.join();
  }

  function removeActiveSubstance(subst) {
    if (subst) {
      const index = vm.activeSubstanceList.indexOf(_.find(vm.activeSubstanceList, obj => obj === subst));

      vm.activeSubstanceList.splice(index, 1);

      joinActionSubstanceListIntoHackUberItem();
    }
  }

  function changeWithdrawlPeriod(item) {
    if (item.withdrawalPeriodText === 'AT') {
      item.withdrawalPeriodDays = null;
    }
  }
}
