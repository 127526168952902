import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function SignupToLogin(props) {
  return (
    <div>
      <p style={{ textAlign: 'center', marginTop: '20px' }}>
        <FormattedMessage id="SignupToLogin.hasAccount" />{' '}
        <a
          href="#"
          onClick={props.redirect}
          style={{
            color: '#00A179',
          }}
        >
          <FormattedMessage id="SignupToLogin.logIn" />
        </a>
      </p>
    </div>
  );
}

SignupToLogin.propTypes = {
  redirect: PropTypes.func,
};

SignupToLogin.defaultProps = {
  redirect: PropTypes.func,
};
