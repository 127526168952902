import React, { Component } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CfAutosuggest from '../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

const styles = () => ({
  container: {
    height: '100%',
  },
  suggestionsContainerOpen: {
    margin: 0,
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
    position: 'static',
    height: 'calc(100% - 52px)',
  },
  suggestionsContainer: {
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    height: 'calc(100% - 52px)',
  },
  suggestionsList: {
    maxHeight: '300px',
    maxWidth: '350px',
  },
  suggestionItem: {
    overflow: 'inherit',
  },
  input: {
    padding: '15px 15px 5px 15px',
  },
  positionEnd: {
    right: '8px',
  },
});

export class AdminCountrySelector extends Component {
  getSuggestionValue = sugg => [sugg.name].filter(item => item).join(', ');

  render() {
    const { classes, isFetching, placeholder } = this.props;
    return (
      <CfAutosuggest
        alwaysRenderSuggestions={true}
        autoFocus={true}
        clearInputOnAdornmentClick={true}
        clearInputOnSelect={true}
        clearSuggestions={this.props.onSuggestionClear}
        disableUnderline={true}
        getSuggestions={this.props.getCountries}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced={true}
        isFetching={isFetching}
        multiSection={false}
        onSuggestionSelected={this.props.onSuggestionSelect}
        placeholder={placeholder}
        requiredLength={3}
        scrollable={true}
        scrollHeightMax={300}
        suggestions={this.props.suggestions.map(sugg => ({ ...sugg }))}
        testData="country-selector"
        adornment={
          <IconButton disabled={true} size="large">
            <SearchIcon />
          </IconButton>
        }
        classes={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsContainer: classes.suggestionsContainer,
          suggestionsList: classes.suggestionsList,
          suggestionItem: classes.suggestionItem,
          input: classes.input,
          positionEnd: classes.positionEnd,
        }}
      />
    );
  }
}

AdminCountrySelector.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  isFetching: PropTypes.bool,
  getCountries: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
};

AdminCountrySelector.defaultProps = {
  suggestions: [],
  isFetching: false,
};

export default withStyles(styles)(AdminCountrySelector);
