import { createSelector } from 'reselect';

import { getIrrigationAdminDevices } from '../../../../shared/api/irrigation/devices/devices.selectors';

import { IrrigationAdminDevice, IrrigationAdminState } from '../admin.irrigation.types';

export const getIrrigationAdminPage = (state: IrrigationAdminState) => state.ui.irrigation_admin.page;
export const getIrrigationAdminOrder = (state: IrrigationAdminState) => state.ui.irrigation_admin.order;
export const getIrrigationAdminOrderBy = (state: IrrigationAdminState) => state.ui.irrigation_admin.orderBy;
export const getIrrigationAdminRowsPerPage = (state: IrrigationAdminState) => state.ui.irrigation_admin.rowsPerPage;
export const getIrrigationAdminTextFilter = (state: IrrigationAdminState) => state.ui.irrigation_admin.textFilter;
export const getIrrigationAdminSelected = (state: IrrigationAdminState) => state.ui.irrigation_admin.selected;

export const getIrrigationAdminStatusFilter = (
  state: IrrigationAdminState,
) => getIrrigationAdminAdvancedFilter(state).status;

export const getIrrigationAdminDeviceTypeFilter = (
  state: IrrigationAdminState,
) => getIrrigationAdminAdvancedFilter(state).deviceType || [];

export const getIrrigationAdminAdvancedFilter = (
  state: IrrigationAdminState,
) => state.ui.irrigation_admin.advancedFilter;

export const getIrrigationAdminCountryFilter = (state: IrrigationAdminState) => state.ui.irrigation_admin.country;
export const getIrrigationAdminFarmFilter = (state: IrrigationAdminState) => state.ui.irrigation_admin.farm;

export const getIrrigationAdminSelectedOnPage = createSelector(
  getIrrigationAdminDevices,
  getIrrigationAdminSelected,
  (devicesOnPage, allSelectedDevices) =>
    allSelectedDevices.filter(selectedDevice =>
      devicesOnPage.some((d: IrrigationAdminDevice) => d.id === selectedDevice)));
