import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import resetUI from '../core/reset/reducer/reset.reducer';

export default (history) => combineReducers({
  ui: combineReducers({
    reset: resetUI(),
  }),
  router: connectRouter(history),
});
