import contains from '@turf/boolean-contains';
import isDisjoint from '@turf/boolean-disjoint';
import pointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon as toPolygon } from '@turf/helpers';
import kinks from '@turf/kinks';
import pointToLineDistance from '@turf/point-to-line-distance';

import * as errors from '../../constants/errors.constants';

import Geometry, { GEOM_TYPES } from './Geometry.service';

const SNAP_DIST_THRESHOLD = 0.00001;

export default class GeometryValidation {
  static isSimple(geometry) {
    const allowedTypes = [
      GEOM_TYPES.LINESTRING,
      GEOM_TYPES.MULTILINESTRING,
      GEOM_TYPES.POLYGON,
      GEOM_TYPES.MULTIPOLYGON,
    ];
    const geomType = Geometry.getType(geometry);
    if (!allowedTypes.includes(geomType)) {
      throw new Error(`Simplicity check not allowed on ${geomType} geometry types`);
    }

    return kinks(geometry).features.length === 0;
  }

  static isDisjoint(geometry1, geometry2) {
    return isDisjoint(geometry1, geometry2);
  }

  static isWithinThreshold(pt, polygon) {
    const border = Geometry.getPolygonBorder(polygon);
    return pointToLineDistance(pt, border) < SNAP_DIST_THRESHOLD;
  }

  static isPointInPolygon(pt, polygon, ignoreInnerRing = true) {
    const clone = Object.assign({}, polygon);
    if (ignoreInnerRing) {
      clone.coordinates = [clone.coordinates[0]];
    }
    const isPtInPolygon = pointInPolygon(pt, clone);

    // check the point distance to the polygon border
    // snapped polylines appear to be not precisely snapped to
    // the border. One of the solutions is to snap them programatically...
    // Still buggy. To be finished in further tasks
    /*
    if (isPtInPolygon) {
      if (GeometryValidation.isWithinThreshold(pt, clone)) {
        callback();
        return !isPtInPolygon;
      }
    }
    */

    return isPtInPolygon;
  }

  static isFullyCrossingPolygon(line, polygon) {
    if (Geometry.getType(line) !== GEOM_TYPES.LINESTRING || Geometry.getType(polygon) !== GEOM_TYPES.POLYGON) {
      throw new Error('Mismatched geometry types of arguments.');
    }
    const first = Geometry.getFirstCoord(line);
    const last = Geometry.getLastCoord(line);

    return (
      !GeometryValidation.isPointInPolygon(first, polygon) &&
      !GeometryValidation.isPointInPolygon(last, polygon) &&
      !isDisjoint(line, polygon)
    );
  }

  static isContained(container, contained) {
    return contains(container, contained);
  }

  static hasContainedRings(geometry) {
    if (Geometry.getType(geometry) !== GEOM_TYPES.POLYGON) {
      throw new Error('Mismatched geometry types of arguments.');
    }

    let isValid = true;
    const innerRingsCoords = [...geometry.coordinates];
    const outerRingCoords = innerRingsCoords.splice(0, 1);

    if (!innerRingsCoords.length) {
      return isValid;
    }

    const outerRingPoly = toPolygon(outerRingCoords);
    innerRingsCoords.some(innerRingCoords => {
      if (!GeometryValidation.isContained(outerRingPoly, toPolygon([innerRingCoords]))) {
        isValid = false;
        return true;
      }
      return false;
    });

    return isValid;
  }

  static validateSplitLine(line, parcel) {
    if (!GeometryValidation.isSimple(line)) {
      return Promise.reject(errors.SPLIT_IS_SIMPLE_ERROR);
    }

    if (GeometryValidation.isDisjoint(line, parcel)) {
      return Promise.reject(errors.SPLIT_IS_DISJOINT_ERROR);
    }

    if (!GeometryValidation.isFullyCrossingPolygon(line, parcel)) {
      return Promise.reject(errors.SPLIT_PARTIAL_CROSSING_ERROR);
    }

    return Promise.resolve();
  }

  static validateParcelGeometry(geometry) {
    if (!GeometryValidation.isSimple(geometry)) {
      return Promise.reject(errors.DRAW_PARCEL_IS_SIMPLE_ERROR);
    }

    if (!GeometryValidation.hasContainedRings(geometry)) {
      return Promise.reject(errors.DRAW_PARCEL_IS_SIMPLE_ERROR);
    }

    return Promise.resolve();
  }

  static validateReductionGeometry(reduction, parcel) {
    if (!GeometryValidation.isSimple(reduction)) {
      return Promise.reject(errors.DRAW_REDUCTION_IS_SIMPLE_ERROR);
    }

    if (GeometryValidation.isDisjoint(reduction, parcel)) {
      return Promise.reject(errors.DRAW_IS_DISJOINT_ERROR);
    }

    if (GeometryValidation.isContained(reduction, parcel)) {
      return Promise.reject(errors.DRAW_CONTAINED_ERROR);
    }

    return Promise.resolve(reduction);
  }

  static validateDifferenceGeometry(geometry) {
    if (Geometry.getType(geometry) !== GEOM_TYPES.POLYGON) {
      return Promise.reject(errors.DRAW_IS_MUTLIPLE_ERROR);
    }

    return Promise.resolve();
  }
}
