import * as types from '../actions/reset.constants';

export const NAMESPACE = 'reset';

export default (reducerNamespace = NAMESPACE) => (
  state = {
    langId: 'en-US',
    isFetching: false,
    mailSent: false,
    resetKeyValid: false,
    resetSuccess: false,
    error: null,
  },
  action,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case types.LANG_CHANGE:
      return {
        ...state,
        langId: action.langId,
      };
    case types.SET_FETCH:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case types.MAIL_SENT:
      return {
        ...state,
        mailSent: true,
      };
    case types.RESET_KEY_VALIDITY:
      return {
        ...state,
        resetKeyValid: action.resetKeyValid,
      };
    case types.RESET_SUCCESSFUL:
      return {
        ...state,
        resetSuccess: true,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
