import React, { FC, ReactNode, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { DEVICE_STATES } from '../../admin.irrigation.constants';

import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';

import { AdminDeviceState } from '../../admin.irrigation.types';

interface Props {
  disabled?: boolean,
  label: ReactNode,
  selectedState?: AdminDeviceState,
  setDeviceStatusFilter: (filterPart: string, items: AdminDeviceState) => void
}

const IrrigationFilterByStatus: FC<Props> = ({
  disabled = false,
  label,
  selectedState,
  setDeviceStatusFilter,
}) => {
  const intl = useIntl();

  const states = useMemo(() => DEVICE_STATES.map(s => (
    {
      name: s,
      label: intl.formatMessage({ id: `SensorsStatus.${s}` }),
    }
  )), [intl]);

  const handleChange = (value: AdminDeviceState) => setDeviceStatusFilter('status', value);

  return (
    <CfAutocomplete
      defaultValues={selectedState}
      disabled={disabled}
      id="status-filter"
      label={label}
      onChange={handleChange}
      suggestions={states}
      testId="status-filter"
    />
  );
};

export default IrrigationFilterByStatus;
