/**
 * Created by ondrejzvara on 22.10.16.
 */

/* @ngInject */
export default function cfPagination() {
  const directive = {
    restrict: 'E',
    scope: {
      tableParams: '='
    },
    template: require('./cf-pagination.html')
  };
  return directive;
}
