import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const style = () => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    '-webkit-border-radius': 3,
    '-moz-border-radius': 3,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-moz-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: 0,
  },
});

export const CfChartTooltipWrapper = (props) => {
  const { children, classes } = props;
  return (
    <div className={`${classes.tooltip}`}>
      <div>{children}</div>
    </div>
  );
};

CfChartTooltipWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(style)(CfChartTooltipWrapper);
