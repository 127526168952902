export const GET_RULES = 'GET_RULES';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_ERROR = 'GET_RULES_ERROR';
export const RESET_RULES = 'RESET_RULES';

export const DELETE_RULES = 'DELETE_RULES';
export const DELETE_RULES_SUCCESS = 'DELETE_RULES_SUCCESS';
export const DELETE_RULES_ERROR = 'DELETE_RULES_ERROR';

export const GET_RULE = 'GET_RULE';
export const GET_RULE_SUCCESS = 'GET_RULE_SUCCESS';
export const GET_RULE_ERROR = 'GET_RULE_ERROR';
export const RESET_RULE = 'RESET_RULE';

export const UPDATE_RULE = 'UPDATE_RULE';
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS';
export const UPDATE_RULE_ERROR = 'UPDATE_RULE_ERROR';

export const PATCH_RULE = 'PATCH_RULE';
export const PATCH_RULE_SUCCESS = 'PATCH_RULE_SUCCESS';
export const PATCH_RULE_ERROR = 'PATCH_RULE_ERROR';

export const CREATE_RULE = 'CREATE_RULE';
export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS';
export const CREATE_RULE_ERROR = 'CREATE_RULE_ERROR';
