import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Evapotranspiration = props => (
  <SvgIcon {...props}>
    <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 10.2C10.6 9.7 8.9 9.2 5.4 11.8L6.3 13C8.2 11.6 9.3 11.3 10.1 11.3C9.8 14.3 7.6 15.4 6.1 15.4C4.5 15.4 2 14.1 2 10.4C2 9 2.7 6.7 6.1 3.2C6.6 3.8 7.1 4.3 7.5 4.8C8.2 4.5 9 4.4 9.7 4.3C8.8 3.1 7.7 1.9 6.3 0.6L6.1 0.5L5.9 0.7C2 4.5 0 7.7 0 10.5C0 15 3.1 17.5 6.1 17.5C8.7 17.5 11.5 15.5 12.1 11.8C13.4 12.2 15 12.3 18 10L17.1 8.8C14.3 11 13.2 10.6 12 10.2Z" />
      <path d="M6.2999 9.50005C9.1999 7.30005 10.2999 7.70005 11.4999 8.10005C12.8999 8.60005 14.4999 9.10005 17.9999 6.50005L17.0999 5.30005C14.2999 7.40005 13.1999 7.10005 11.9999 6.70005C10.5999 6.20005 8.8999 5.70005 5.3999 8.30005L6.2999 9.50005Z" />
    </svg>
  </SvgIcon>
);

export default Evapotranspiration;
