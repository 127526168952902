import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { COLOR_FONT } from '../../../../theme';

const styles = {
  menuItemLink: {
    '&:hover': {
      textDecoration: 'none',
      color: COLOR_FONT.main,
    },
    '&:focus': {
      textDecoration: 'none',
      color: COLOR_FONT.main,
      outline: 'none',
    },
  },
  primaryText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mobileGutters: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  indent: {
    width: 16,
  },
};

function ContextMenuItem(props) {
  const {
    classes,
    dense,
    extraContent,
    icon,
    iconStyle,
    label,
    mobile,
    onClick,
    secondaryLevel,
    ...otherProps
  } = props;

  return (
    <MenuItem
      className={classes.menuItemLink}
      dense={dense}
      onClick={onClick}
      classes={{
        gutters: mobile ? classes.mobileGutters : '',
      }}
      {...otherProps}
    >
      {secondaryLevel && <div className={classes.indent} />}
      {icon && <ListItemIcon style={iconStyle}>{icon}</ListItemIcon>}
      <ListItemText classes={{ primary: classes.primaryText }} primary={label} />
      {extraContent}
    </MenuItem>
  );
}

ContextMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  extraContent: PropTypes.element,
  iconStyle: PropTypes.object,
  secondaryLevel: PropTypes.bool,
};

ContextMenuItem.defaultProps = {
  mobile: false,
  icon: null,
  onClick: () => {},
  dense: true,
  label: '',
  extraContent: null,
  iconStyle: null,
  secondaryLevel: false,
};

export default withStyles(styles)(ContextMenuItem);
