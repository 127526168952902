import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import DragAndDrop from 'ol/interaction/DragAndDrop';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import ImageStaticSource from 'ol/source/ImageStatic';
import VectorSource from 'ol/source/Vector';
import { Style, Icon } from 'ol/style';

import * as weatherTypes from '../constants/weatherTypes.constants';

import clearDayIcon from '../../../assets/img/icons/weather/clear-day.svg';
import clearNightIcon from '../../../assets/img/icons/weather/clear-night.svg';
import cloudyIcon from '../../../assets/img/icons/weather/cloudy.svg';
import fogIcon from '../../../assets/img/icons/weather/fog.svg';
import partlyCloudyDayIcon from '../../../assets/img/icons/weather/partly-cloudy-day.svg';
import partlyCloudyNightIcon from '../../../assets/img/icons/weather/partly-cloudy-night.svg';
import rainIcon from '../../../assets/img/icons/weather/rain.svg';
import sleetIcon from '../../../assets/img/icons/weather/sleet.svg';
import snowIcon from '../../../assets/img/icons/weather/snow.svg';
import windIcon from '../../../assets/img/icons/weather/wind.svg';
import weatherPin from '../../../assets/img/weather_pin.svg';
import WeatherCurrentlyIcon from '../../../shared/icons/weather/WeatherCurrentlyIcon';
import WeatherDayIcon from '../../../shared/icons/weather/WeatherDayIcon';
import WeatherRadarIcon from '../../../shared/icons/weather/WeatherRadarIcon';
import WeatherWeekIcon from '../../../shared/icons/weather/WeatherWeekIcon';

const weatherTypesConfig = [
  {
    id: weatherTypes.CURRENTLY,
    translationId: 'Weather.currentWeather',
    icon: WeatherCurrentlyIcon,
  },
  {
    id: weatherTypes.DAY,
    translationId: 'Weather.dayForecast',
    icon: WeatherDayIcon,
  },
  {
    id: weatherTypes.WEEK,
    translationId: 'Weather.weekForecast',
    icon: WeatherWeekIcon,
  },
  {
    id: weatherTypes.RADAR,
    translationId: 'Weather.radar',
    icon: WeatherRadarIcon,
  },
];

const weatherIcons = {
  'clear-day': clearDayIcon,
  'clear-night': clearNightIcon,
  cloudy: cloudyIcon,
  fog: fogIcon,
  'partly-cloudy-day': partlyCloudyDayIcon,
  'partly-cloudy-night': partlyCloudyNightIcon,
  rain: rainIcon,
  sleet: sleetIcon,
  snow: snowIcon,
  wind: windIcon,
};

export default class Weather {
  constructor(map, transformOptions) {
    this.map = map;
    this.transformOptions = transformOptions;
    // this.setWeatherCoordinatesInteraction(weatherCoordinatesCallback);
  }

  static getWeatherIcon(iconName) {
    return weatherIcons[iconName];
  }

  static getWeatherTypesConfig() {
    return weatherTypesConfig;
  }

  static getWeatherTypeConfig(weatherType) {
    return weatherTypesConfig.find(config => config.id === weatherType);
  }

  getMap() {
    return this.map;
  }

  setRadarLayers(dateNow, dateNext) {
    if (!this.radarLayerNow) {
      this.radarLayerNow = new ImageLayer({
        opacity: 0.6,
        source: this.getRadarSource(dateNow),
      });
      this.radarLayerNext = new ImageLayer({
        opacity: 0,
        source: this.getRadarSource(dateNext),
      });
      this.getMap().addLayer(this.radarLayerNow);
      this.getMap().addLayer(this.radarLayerNext);
    } else {
      this.radarLayerNow.setSource(this.getRadarSource(dateNow));
      this.radarLayerNext.setSource(this.getRadarSource(dateNext));
    }
  }

  moveRadarLayer(dateNext) {
    this.radarLayerNow.setSource(this.radarLayerNext.getSource());
    this.radarLayerNext.setSource(this.getRadarSource(dateNext));
  }

  unsetRadarLayers() {
    this.getMap().removeLayer(this.radarLayerNow);
    this.getMap().removeLayer(this.radarLayerNext);
    this.radarLayerNow = null;
    this.radarLayerNext = null;
  }

  getRadarSource(dateTimeString) {
    const srid = this.transformOptions.featureProjection;
    // TODO
    let imageExtent;
    switch (srid) {
      case 'EPSG:5514':
        imageExtent = [-1227618.38, -2985767.52, 2012978.09, 1866500.09];
        break;
      default:
        // 'EPSG:3857'
        imageExtent = [-1627293.37, 3628618.63, 5044402.25, 11980434.1];
    }
    return new ImageStaticSource({
      url: `http://cf-weather.s3-website.eu-central-1.amazonaws.com/radar.anim.${dateTimeString}.0.png`,
      crossOrigin: 'anonymous',
      projection: srid,
      imageExtent,
    });
  }

  setWeatherLayer() {
    const source = new VectorSource();
    this.weatherLayer = new VectorLayer({
      source,
      style: this.setWeatherStyle(),
      zIndex: 8,
      // hack to get above the decluttered parcel labels
      // https://github.com/openlayers/openlayers/issues/10096
      className: 'weather',
    });
    this.getMap().addLayer(this.weatherLayer);
    const dragAndDrop = new DragAndDrop({
      source,
    });
    this.getMap().addInteraction(dragAndDrop);
    return this.weatherLayer;
  }

  unsetWeatherLayer() {
    this.getMap().removeLayer(this.weatherLayer);
    this.weatherLayer = null;
  }

  setWeatherCoordinatesPin = coordinates => {
    const weatherSource = this.weatherLayer.getSource();
    weatherSource.clear();
    weatherSource.addFeature(new Feature(new Point(coordinates)));
  };

  setWeatherStyle() {
    return new Style({
      image: new Icon({
        src: weatherPin,
        anchor: [0.5, 1],
        size: [25, 32],
      }),
    });
  }
}
