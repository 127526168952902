import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';

import { AdminFarm } from '../../admin.irrigation.types';

interface Props {
  deviceIds: string[];
  farm: AdminFarm;
  onAccept: () => void;
  onClose: () => void;
  opened?: boolean;
}

const IrrigationAdminAssignConfirm: FC<Props> = ({
  deviceIds,
  farm,
  onAccept,
  onClose,
  opened = false,
}) => (
  <CfDialog
    acceptText={<FormattedMessage id="SensorsAdmin.assign" />}
    cancelText={<FormattedMessage id="common.cancel" />}
    dialogHeight="75px"
    onAccept={onAccept}
    onCancel={onClose}
    onClose={onClose}
    opened={opened}
    title={<FormattedMessage id="SensorsAdmin.assign-farm" />}
    >
    <FormattedMessage
      id="IrrigationAdmin.assign-to-farm-confirm"
      values={{
        farm: farm.name || '',
        length: deviceIds.length,
      }}
      />
  </CfDialog>
);

export default IrrigationAdminAssignConfirm;
