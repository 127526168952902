import React, { Component } from 'react';

import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfDialogTabs from '../../../../../../shared/components/common/CfDialogTabs/CfDialogTabs';
import CfSimpleDialog from '../../../../../../shared/components/common/CfSimpleDialog/CfSimpleDialog';

const styles = theme => ({
  restrictionCode: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.main,
    margin: '20px 0 12px 0',
  },
  restrictionMessage: {
    fontSize: 16,
  },
  restrictionMessageEmpty: {
    fontSize: 16,
    fontWeight: 500,
  },
  content: {
    margin: '24px 0px',
  },
  divider: {
    margin: '20px 0 20px 0',
  },
});

class ParcelDetailRestrictions extends Component {
  constructor(props) {
    super(props);
    this.groups = {
      NITRAT: 'NITRAT',
      AEKO: 'AEKO',
    };

    let tabValue = this.groups.NITRAT;
    if (!this.getRestrictionsItems(this.groups.NITRAT).length && this.getRestrictionsItems(this.groups.AEKO).length) {
      tabValue = this.groups.AEKO;
    }

    this.state = {
      dialogOpened: false,
      tabValue,
    };
  }

  getRestrictionsItems = (groupId) => {
    const { restrictions } = this.props;
    return restrictions.items.filter(item => item.group === groupId);
  };

  getTabs = (nitrat, aeko) => {
    const tabs = [];
    if (nitrat.length) { tabs.push({ id: 'NITRAT', labelId: 'ParcelDetailRestrictions.nitrat' }); }
    if (aeko.length) { tabs.push({ id: 'AEKO', labelId: 'ParcelDetailRestrictions.aeko' }); }
    return tabs;
  };

  activateTab(tabId) {
    this.setState({
      tabValue: tabId,
    });
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpened: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpened: false,
    });
  };

  renderRestrictions = (restrictions) => {
    const { classes } = this.props;
    return restrictions.map((r, index) => (
      <div data-test="restrictions-content" key={r.code}>
        <div className={classes.content}>
          <div className={classes.restrictionCode}>{r.code}</div>
          {r.message.length === 0 ? (
            <div className={classes.restrictionMessageEmpty}>-</div>
          ) : (
            // eslint-disable-next-line react/no-danger
            <div className={classes.restrictionMessage} dangerouslySetInnerHTML={{ __html: r.message }} />
          )}
        </div>
        {index < restrictions.length - 1 && <Divider className={classes.divider} variant="fullWidth" />}
      </div>
    ));
  };

  render() {
    const { tabValue } = this.state;
    const nitrat = this.getRestrictionsItems(this.groups.NITRAT);
    const aeko = this.getRestrictionsItems(this.groups.AEKO);
    const tabs = this.getTabs(nitrat, aeko);
    return (
      <div>
        <a href="#" onClick={this.handleDialogOpen}>
          <FormattedMessage id="ParcelDetailOverview.restrictionsButton" />
        </a>
        <CfSimpleDialog
          data-test="parcel-restrictions"
          heading={<FormattedMessage id="ParcelDetailOverview.restrictionsButton" />}
          onDialogClose={this.handleDialogClose}
          open={this.state.dialogOpened}
        >
          <CfDialogTabs
            activateTab={tabId => this.activateTab(tabId)}
            tabsInfo={tabs}
            tabValue={tabValue}
          />
          {tabValue === this.groups.NITRAT && this.renderRestrictions(nitrat)}
          {tabValue === this.groups.AEKO && this.renderRestrictions(aeko)}
        </CfSimpleDialog>
      </div>
    );
  }
}

ParcelDetailRestrictions.propTypes = {
  classes: PropTypes.object.isRequired,
  restrictions: PropTypes.object.isRequired,
};

export default withStyles(styles)(ParcelDetailRestrictions);
