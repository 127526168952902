import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';

import SnackbarText from '../SnackbarText/SnackbarText';

const tool = tools.MERGE;

class MergeHint extends Component {
  getHint = (stage, data) => {
    switch (stage) {
      case stages.STAGE_1:
      case stages.STAGE_2:
        return (
          <SnackbarText color="light">
            <FormattedMessage
              id={`hint.${tool}_STAGE_1_PT1`}
              values={{
                pt2: (
                  <SnackbarText color="dark">
                    <FormattedMessage id={`hint.${tool}_STAGE_1_PT2`} />
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );
      case stages.STAGE_3:
        return (
          <SnackbarText color="dark">
            <FormattedMessage
              id={`hint.${tool}_${stage}`}
              values={{
                parcels: <SnackbarText color="light">{data.parcels.map(p => p.localName).join(', ')}</SnackbarText>,
                parcel: <SnackbarText color="light">{data.result.localName}</SnackbarText>,
              }}
            />
          </SnackbarText>
        );
      default:
        return null;
    }
  };

  render() {
    const { data, stage } = this.props;
    return this.getHint(stage, data);
  }
}

MergeHint.propTypes = {
  stage: PropTypes.string,
  data: PropTypes.object,
};

MergeHint.defaultProps = {
  stage: null,
  data: null,
};

export default MergeHint;
