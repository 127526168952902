/**
 * Created by ondrejzvara on 25.6.16.
 */


/*@ngInject*/
export default function ResponseSuccessInterceptor( Logger, BACKEND_OPTIONS, Spinner) {

    var service = {
        logResponse: logResponse,
        jsogDecode: jsogDecode,
        spinnerEnd: spinnerEnd
    };
    return service;

    /////////////////////////

    function logResponse(data, operation, what, url, response) {
        var params = response.config.params ? JSON.stringify(response.config.params) : "";
        var formattedUrl = url.replace(BACKEND_OPTIONS.apiUrl, "");
        Logger.printa(response.config.method, response.status, formattedUrl, response.statusText, params);
    }

    function jsogDecode(data, operation, what, url, response){

        var newResponse = response.data;
        if((operation === 'getList' || operation === 'get') && (what !== 'download' && response.config.responseType !== 'blob')) {
            newResponse = JSOG.decode(newResponse);
        }
        return newResponse;
    }

    function spinnerEnd(/* jshint unused:false*/ data, operation, what, url, response){
        Spinner.responseSpinnerInterceptor(url, operation, response.config.params);
    }

}
