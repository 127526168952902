import React, { Fragment } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ServiceNotPurchasedService from '../../../services/ServiceNotPurchased.service';

const styles = theme => ({
  wrapper: {
    textAlign: 'left',
  },
  paragraph: {
    padding: '10px 0px',
  },
  listItem: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  listItemIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    height: 20,
  },
  listItemPrimary: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  fontMedium: {
    fontWeight: '500',
  },
});

function ServiceNotPurchased(props) {
  const { classes, serviceId, translationContentId } = props;
  const config = ServiceNotPurchasedService.getServiceConfig(serviceId);

  return (
    <div className={classes.wrapper}>
      {config.p1 && (
        <div className={classes.paragraph}>
          <FormattedMessage id={`${config.translationBaseId}.${translationContentId}.p1`} />
        </div>
      )}
      {config.p2 && (
        <div className={classes.paragraph}>
          {config.p2.intro && (
            <div className={classes.listItem}>
              <FormattedMessage id={`${config.translationBaseId}.${translationContentId}.p2.intro`} />
            </div>
          )}
          {config.p2.itemsLength > 0 && (
            <Fragment>
              {[...Array(config.p2.itemsLength).keys()]
                .map(e => e + 1)
                .map(e => (
                  <div className={classes.listItem} key={e}>
                    <span className={classes.listItemIcon}>
                      <DoneIcon style={{ width: 20, height: 20 }} />
                    </span>
                    <span>
                      <div className={classes.listItemPrimary}>
                        <FormattedMessage id={`${config.translationBaseId}.${translationContentId}.p2.a${e}`} />
                      </div>
                      {config.p2.additionalInfo && (
                        <div>
                          <FormattedMessage id={`${config.translationBaseId}.${translationContentId}.p2.b${e}`} />
                        </div>
                      )}
                    </span>
                  </div>
                ))}
            </Fragment>
          )}
        </div>
      )}
      {config.p3 && (
        <div className={classes.paragraph}>
          <FormattedMessage
            id={`${config.translationBaseId}.${translationContentId}.p3`}
            values={{
              b: chunks => <span className={classes.fontMedium}>{chunks}</span>,
            }}
          />
        </div>
      )}
    </div>
  );
}

ServiceNotPurchased.propTypes = {
  classes: PropTypes.object.isRequired,
  serviceId: PropTypes.string.isRequired,
  translationContentId: PropTypes.string,
};

ServiceNotPurchased.defaultProps = {
  translationContentId: 'notPurchased.content',
};

export default withStyles(styles)(ServiceNotPurchased);
