import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import ToolbarHoverStopper from '../ToolbarHoverStopper/ToolbarHoverStopper';

const styles = {
  parcelListItem: {
    display: 'flex',
    borderRadius: 20,
  },
  parcelListItemName: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '0 0 0 14px',
    lineHeight: '28px',
  },
  cancelButton: {
    padding: 0,
    margin: '0px 2px 0px 4px',
  },
};

const ToolbarParcelItem = props => {
  const { classes, onCancel, parcel } = props;

  return (
    <ToolbarSection>
      <ToolbarHoverStopper />
      <div className={classes.parcelListItem}>
        <div className={classes.parcelListItemName}>{parcel.localName}</div>
        <IconButton
          aria-label="Information"
          focusRipple={false}
          onClick={() => onCancel(parcel)}
          size="large"
          classes={{
            root: classes.cancelButton,
          }}>
          <CancelIcon />
        </IconButton>
      </div>
    </ToolbarSection>
  );
};

ToolbarParcelItem.propTypes = {
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ToolbarParcelItem.defaultProps = {};

export default withStyles(styles)(ToolbarParcelItem);
