/**
 * Created by ondrejzvara on 24.3.17.
 */

import autoFocus from './autofocus/autofocus.directive.js';
import cfCheckbox from './cf-checkbox/cf-checkbox.directive.js';
import cfCheckboxAll from './cf-checkbox-all/cf-checkbox-all.directive.js';
import cfDatepicker from './cf-datepicker/cf-datepicker.directive.js';
import cfDatepickerEnd from './cf-datepicker-end/cf-datepicker-end.directive.js';
import cfDatepickerStart from './cf-datepicker-start/cf-datepicker-start.directive.js';
import cfEdit from './cf-edit/cf-edit.directive.js';
import cfFocus from './cf-focus/cf-focus.directive.js';
import cfHttpSpinner from './cf-http-spinner/cf-http-spinner.directive.js';
import cfPagination from './cf-pagination/cf-pagination.directive.js';
import cfRoundedInput from './cf-rounded-input/cf-rounded-input.directive.js';
import cfRoundedInputSave from './cf-rounded-input-save/cf-rounded-input-save.directive.js';
import cfTabset from './cf-tabset/cf-tabset.directive.js';

export default angular
  .module('app.directives.shared', [])
  .constant('keyCodes', {
    esc: 27,
    space: 32,
    enter: 13,
    tab: 9,
    backspace: 8,
    shift: 16,
    ctrl: 17,
    alt: 18,
    capslock: 20,
    numlock: 144
  })
  .directive('autoFocus', autoFocus)
  .directive('cfCheckbox', cfCheckbox)
  .directive('cfCheckboxAll', cfCheckboxAll)
  .directive('cfDatepicker', cfDatepicker)
  .directive('cfDatepickerEnd', cfDatepickerEnd)
  .directive('cfDatepickerStart', cfDatepickerStart)
  .directive('cfEdit', cfEdit)
  .directive('cfFocus', cfFocus)
  .directive('cfHttpSpinner', cfHttpSpinner)
  .directive('cfPagination', cfPagination)
  .directive('cfRoundedInput', cfRoundedInput)
  .directive('cfRoundedInputSave', cfRoundedInputSave)
  .directive('cfTabset', cfTabset).name;
