import get from 'lodash/get';

const DEFAULT_PATH = 'api.zones';

const resolveStatePath = (state, path) => get(state, path || DEFAULT_PATH);

export const getZones = path => state => resolveStatePath(state, path)?.items || [];
export const getIsFetchingZones = path => state => resolveStatePath(state, path).isFetching;

export const getZonesStatistics = path => state => resolveStatePath(state, path).statistics;
export const isZonesStatisticsFetching = path => state => resolveStatePath(state, path).isFetchingStatistics;
