/**
 * Created by ondrejzvara on 2.11.15.
 */


/*@ngInject*/
export default function VarietiesResource(Api, ResourceUtils) {

    var baseAll = Api.setBaseAll("varieties");

    var service = {
        createVariety: createVariety,
        searchVarieties: searchVarieties
    };
    return service;

    /////////////////////////

    function createVariety(item) {
        item = Api.restangularizeOne(null, item, "varieties");
        return ResourceUtils.createItem(baseAll, item, true)
            .then(function (response) {
                return ResourceUtils.getIdAfterCreate(baseAll, response);
            });
    }

    function searchVarieties(search, cropLegislativeCode, external) {
        var params = ResourceUtils.composeParams({
            page: 1,
            count: 500,
            external: external
        }, {filter: search});

        params.cropLegislativeCode = cropLegislativeCode;
        return ResourceUtils.getItems(baseAll, params);
    }
}
