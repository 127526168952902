export const RESET_NODE = 'RESET_NODE';
export const EDIT_START = 'EDIT_START';
export const EDIT_CANCEL = 'EDIT_CANCEL';
export const EDIT_UPDATE = 'EDIT_UPDATE';
export const SET_DURATION = 'SET_DURATION';
export const RESET_DURATION = 'RESET_DURATION';
export const SET_GRANULARITY = 'SET_GRANULARITY';
export const RESET_GRANULARITY = 'RESET_GRANULARITY';
export const SET_DATE_FROM = 'SET_DATE_FROM';
export const SET_DATE_TO = 'SET_DATE_TO';
