import React, { useEffect, useState } from 'react';

import { ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IrrigationCounter from './IrrigationCounter';

const useStyles = makeStyles(() => ({
  valveName: {
    fontWeight: ({ isManualAction }) => (isManualAction ? '500' : '400'),
    fontSize: ({ isManualAction }) => (isManualAction ? '0.75rem' : '0.6875rem'),
    lineHeight: '0.8125rem',
    paddingRight: '0.625rem',
  },
  listItem: {
    padding: 0,
    marginBottom: ({ isManualAction }) => (isManualAction ? '1.25rem' : '0'),
  },
}));

const IrrigationValve = ({ handleChangeDurations, idValve, isManualAction, valve }) => {
  const classes = useStyles({ isManualAction });
  const [count, setCount] = useState(valve.durationSeconds);

  useEffect(() => {
    setCount(valve.durationSeconds);
  }, [valve]);

  const handleIncrement = () => {
    const newCount = count + 60;
    handleChangeDurations(idValve, newCount);
    setCount(newCount);
  };

  const handleDecrement = () => {
    const newCount = Math.max(count - 60, 0);
    handleChangeDurations(idValve, newCount);
    setCount(newCount);
  };

  return (
    <ListItem
      className={classes.listItem}
      key={valve.valveId}
    >
      <span className={classes.valveName}>
        <FormattedMessage id="Irrigation.planCalendarModal.valve" /> {idValve + 1}
      </span>
      <IrrigationCounter
        checked={false}
        count={count}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
        manualAction={isManualAction}
      />
    </ListItem>
  );
};

IrrigationValve.propTypes = {
  valve: PropTypes.object.isRequired,
  idValve: PropTypes.number.isRequired,
  handleChangeDurations: PropTypes.func.isRequired,
  isManualAction: PropTypes.bool,
};

IrrigationValve.defaultProps = {
  isManualAction: false,
};

export default IrrigationValve;
