import React, { Component } from 'react';

import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import PropTypes from 'prop-types';
// Fix error - DatePicker ignores greek language
import 'moment/locale/el';

class CfDatePickerTranslator extends Component {
  constructor(props) {
    super(props);
    const { format, value } = props;

    let dateValue = null;
    if (value && moment.isMoment(value) && value.isValid()) {
      dateValue = value;
    }

    this.state = {
      dateValue,
      format,
    };
  }

  componentDidUpdate(prevProps) {
    const { dateValue } = this.state;
    const { momentLocale: newMomentLocale, value: newValue } = this.props;
    const { momentLocale: oldMomentLocale } = prevProps;
    const format = moment.localeData().longDateFormat('L');

    if (newValue !== dateValue) {
      if (moment.isMoment(newValue) && newValue.isValid()) {
        this.setState({
          dateValue: newValue,
        });
      }
    }

    if (newMomentLocale !== oldMomentLocale) {
      if (moment.isMoment(newValue) && newValue.isValid()) {
        const newVal = newValue.locale(newMomentLocale);
        this.setState({
          dateValue: newVal,
          format,
        });
      }
    }
  }

  onChange = dateValue => {
    this.setState({
      dateValue,
    });
    this.props.onChange(dateValue);
  };

  render() {
    const { dateValue, format } = this.state;
    const { InputProps, momentLocale, ...rest } = this.props;

    return (
      <DatePicker
        InputProps={InputProps}
        {...rest}
        inputFormat={format}
        onChange={this.onChange}
        renderInput={(props) => <TextField name="actionDate" {...props} />}
        value={dateValue}
      />
    );
  }
}

CfDatePickerTranslator.propTypes = {
  InputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  momentLocale: PropTypes.string.isRequired,
};

CfDatePickerTranslator.defaultProps = {
  InputProps: {},
  value: null,
};

export default CfDatePickerTranslator;
