import angular from 'angular';

import AdminController from './manager.controller';

const adminComponent = {
  bindings: {
    farm: '<',
  },
  controller: AdminController,
};

export default angular
  .module('app.admin.manager', [])
  .config(config)
  .component('adminComponent', adminComponent).name;

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider
    .state('farm.active.admin', {
      url: '/admin',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
    })
    .state('farm.active.admin.sensors', {
      url: '/sensors',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
      redirectTo(trans) {
        trans
          .injector()
          .getAsync('Auth')
          .then(Auth => {
            if (Auth.isImpersActive()) {
              return { state: 'farm.active.map' };
            }
          });
      },
    })
    .state('farm.active.admin.notifications', {
      url: '/notifications',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
      redirectTo(trans) {
        trans
          .injector()
          .getAsync('Auth')
          .then(Auth => {
            if (Auth.isImpersActive()) {
              return { state: 'farm.active.map' };
            }
          });
      },
    })
    .state('farm.active.admin.sentinel', {
      url: '/sentinel',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
      redirectTo(trans) {
        trans
          .injector()
          .getAsync('Auth')
          .then(Auth => {
            if (Auth.isImpersActive()) {
              return { state: 'farm.active.map' };
            }
          });
      },
    })
    .state('farm.active.admin.irrigation', {
      url: '/irrigation',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
      redirectTo(trans) {
        trans
          .injector()
          .getAsync('Auth')
          .then(Auth => {
            if (Auth.isImpersActive()) {
              return { state: 'farm.active.map' };
            }
          });
      },
    })
    .state('farm.active.admin.telematics', {
      url: '/telematics',
      views: {
        farm: {
          component: 'adminComponent',
        },
      },
      redirectTo(trans) {
        trans
          .injector()
          .getAsync('Auth')
          .then(Auth => {
            if (Auth.isImpersActive()) {
              return { state: 'farm.active.map' };
            }
          });
      },
    });
}
