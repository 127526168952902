/**
 * Created by mpenak on 7/12/17.
 */
import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { getAppTheme } from '../../../../shared/hooks/useAppTheming';
import { checkEmailFormat } from '../../../../shared/misc/helpers';
import LicenseAgreement from '../LicenseAgreement/LicenseAgreement';
import PasswordField from '../PasswordField/PasswordField';
import SubmitError from '../SubmitError/SubmitError';

const styleSheet = () => ({
  h5: {
    fontWeight: 500,
    fontSize: 24,
  },
  h5Desc: {
    fontSize: '16px',
    marginBottom: 30,
  },
  textField: {
    textAlign: 'left',
    width: '100%',
    marginTop: 0,
  },
  errorText: {
    position: 'absolute',
    bottom: '-10px',
  },
  floatingLabelStyle: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  button: {
    width: '100%',
    marginTop: 32,
  },
});

class NewAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordErrorText: '',
      emailErrorText: '',
      license: false,
      licenseError: '',
      submitError: '',
      showPassword: false,
    };

    this.emailFormatError = false;
    this.emailExistsError = false;
    this.passwordLengthError = false;
    this.licenseCheckedError = false;
  }

  onCreateAccount = () => {
    this.setState({
      submitError: '',
    });

    this.validateEmail(this.state.email);
    this.validatePassword(this.state.password);
    this.validateLicense(this.state.license);

    if (this.emailFormatError || this.emailExistsError || this.passwordLengthError || this.licenseCheckedError) {
      return;
    }

    this.props.onCreateAccount(this.state.email, this.state.password).catch(e => {
      const msg = this.checkResponseErrors(e, <FormattedMessage id="validation.submitError" />);
      this.setState({
        submitError: msg,
      });
    });
  };

  onInputChange = e => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  onInputFocus = e => {
    const name = `${e.target.name}ErrorText`;
    this.setState({
      [name]: '',
    });
  };

  onInputBlur = (e, validate) => {
    const name = e.target.name;
    const trimmedValue = e.target.value.trim();
    this.setState({
      [name]: trimmedValue,
    });
    validate(trimmedValue);
  };

  onEmailBlur = e => {
    this.onInputBlur(e, this.validateEmail);
  };

  onPasswordBlur = e => {
    this.onInputBlur(e, this.validatePassword);
  };

  onLicenseCheck = (e, isChecked) => {
    this.validateLicense(isChecked);
  };

  setEmailValidationMsg = () => {
    let msg = '';
    if (this.emailFormatError) {
      msg = <FormattedMessage id="NewAccount.emailFormatError" />;
    } else if (this.emailExistsError) {
      msg = <FormattedMessage id="NewAccount.emailExistsError" />;
    }

    this.setState({
      emailErrorText: msg,
    });
  };

  setpasswordValidationMsg = () => {
    this.setState({
      passwordErrorText: this.passwordLengthError ? <FormattedMessage id="NewAccount.passwordLengthError" /> : '',
    });
  };

  checkResponseErrors(e, msg) {
    let result = msg;
    if (e.status === 400 || e.status === 422) {
      const errors = e && e.data && e.data.errors;
      if (errors) {
        errors.some(s => {
          if (s.code === 'GatewayEmail') {
            result = <FormattedMessage id="NewAccount.emailFormatError" />;
            return true;
          }
          return false;
        });
      }
    }
    return result;
  }

  validateLicense = isChecked => {
    this.licenseCheckedError = !isChecked;

    this.setState({
      license: isChecked,
      licenseError: !isChecked ? <FormattedMessage id="NewAccount.licenseCheckedError" /> : '',
    });
  };

  validateEmail = email => {
    this.emailFormatError = !checkEmailFormat(email);

    if (!this.emailFormatError) {
      this.props.checkEmailExists(email).then(({ payload }) => {
        this.emailExistsError = payload;
        this.setEmailValidationMsg();
      });
      return;
    }

    this.setEmailValidationMsg();
  };

  validatePassword = password => {
    this.passwordLengthError = !(password && password.trim().length >= 6);
    this.setpasswordValidationMsg();
  };

  render() {
    const classes = this.props.classes;
    const { formatMessage } = this.props.intl;
    const appTheme = getAppTheme();
    const appName = appTheme === 0 ? 'Cleverfarm' : 'Farmasense';

    return (
      <div>
        <h5 className={classes.h5}>
          <FormattedMessage id="NewAccount.heading" values={{ appname: appName }} />
        </h5>
        {/* eslint no-irregular-whitespace: "off" */}
        <p className={classes.h5Desc}>
          <FormattedMessage id="NewAccount.desc" />
        </p>
        <TextField
          className={classes.textField}
          error={this.state.emailErrorText !== ''}
          helperText={this.state.emailErrorText !== '' ? this.state.emailErrorText : ' '}
          margin="dense"
          name="email"
          onBlur={this.onEmailBlur}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          type="text"
          value={this.state.email}
          InputLabelProps={{
            style: {
              fontWeight: 400,
              zIndex: 1,
              pointerEvents: 'none',
            },
          }}
          label={formatMessage({
            id: 'NewAccount.emailPlaceholder',
          })}
        />
        <PasswordField
          errorText={this.state.passwordErrorText}
          fullWidth={true}
          onBlur={this.onPasswordBlur}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          value={this.state.password}
          hintText={formatMessage({
            id: 'NewAccount.passwordHint',
          })}
          label={formatMessage({
            id: 'NewAccount.passwordPlaceholder',
          })}
        />
        <LicenseAgreement error={this.state.licenseError} onLicenseCheck={this.onLicenseCheck} />
        <div>
          <Button
            className={classes.button}
            color="primary"
            id="signup"
            onClick={this.onCreateAccount}
            variant="contained"
          >
            {formatMessage({
              id: 'NewAccount.toAccountCreate',
            })}
          </Button>
        </div>
        <SubmitError msg={this.state.submitError} />
      </div>
    );
  }
}

NewAccount.propTypes = {
  onCreateAccount: PropTypes.func,
  checkEmailExists: PropTypes.func,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styleSheet)(injectIntl(NewAccount));
