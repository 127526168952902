import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSeedsSuggestions } from '../../../shared/selectors/actions.selectors';

import { getSeedsByName, clearSeeds } from '../../../shared/actions/actions.actions';

import CfAutosuggest from '../../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

export const CHEM_DIALOD_TYPE = 'CH';
export const FERT_DIALOD_TYPE = 'FR';

class SeedSelector extends Component {
  onSuggestionSelected = suggestion => {
    const { seedAdditionalProps } = this.props;

    const expense = {
      material: suggestion,
      ...seedAdditionalProps,
    };

    this.props.onSuggestionSelected(expense);
  };

  getSuggestionValue = sugg => [sugg.name, sugg.producent].filter(item => item).join(', ');

  getSuggestions = searchInput => {
    this.props.getSeedsByName(searchInput);
  };

  clearSuggestions = () => {
    this.props.clearSeeds();
  };

  render() {
    const {
      disabled,
      intl: { formatMessage },
      placeholder,
      suggestions,
    } = this.props;
    return (
      <CfAutosuggest
        clearSuggestions={this.clearSuggestions}
        disabled={disabled}
        getSuggestions={this.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced
        onSuggestionSelected={this.onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={3}
        suggestions={suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
        testData="seed-selector"
      />
    );
  }
}

const mapStateToProps = state => ({
  suggestions: getSeedsSuggestions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSeedsByName,
      clearSeeds,
    },
    dispatch,
  );

SeedSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  getSeedsByName: PropTypes.func.isRequired,
  clearSeeds: PropTypes.func.isRequired,
  seedAdditionalProps: PropTypes.object,
  disabled: PropTypes.bool,
};

SeedSelector.defaultProps = {
  seedAdditionalProps: {},
  disabled: false,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(SeedSelector);
