import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './actions.constants';

export const getActions = params => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    'sort-dir': params.order,
    'sort-col': params.orderBy,
    search_str: params.textFilter || undefined,
    from: params.dateFilter || undefined,
  };

  return {
    [RSAA]: {
      endpoint: `actions?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_ACTIONS, types.GET_ACTIONS_SUCCESS, types.GET_ACTIONS_ERROR],
    },
  };
};

export const getAction = actionId => ({
  [RSAA]: {
    endpoint: `v2/actions/${actionId}?`,
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_ACTION, types.GET_ACTION_SUCCESS, types.GET_ACTION_ERROR],
  },
});

export const validateAction = actionDto => ({
  [RSAA]: {
    endpoint: 'actions/validation?',
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(actionDto),
    types: [types.ACTION_VALIDATE, types.ACTION_VALIDATE_SUCCESS, types.ACTION_VALIDATE_FAILURE],
  },
});

export const saveAction = actionDto => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: 'actions?',
      method: methods.POST,
      module: modules.CORE,
      body: JSON.stringify(actionDto),
      types: [types.ACTION_SAVE, types.ACTION_SAVE_SUCCESS, types.ACTION_SAVE_FAILURE],
    },
  });

export const updateAction = (actionId, actionDto) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: `actions/${actionId}?`,
      method: methods.PUT,
      module: modules.CORE,
      body: JSON.stringify(actionDto),
      types: [types.ACTION_UPDATE, types.ACTION_UPDATE_SUCCESS, types.ACTION_UPDATE_FAILURE],
    },
  });

export const resetValidationFailure = () => dispatch => {
  dispatch({ type: types.RESET_VALIDATE_FAILURE });
};

export const getActionByTransactionId = transactionId => {
  const params = {
    store_transaction_id: transactionId,
  };

  return {
    [RSAA]: {
      endpoint: `actions?${queryString.stringify(params)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_ACTION_BY_TRANSACTION_ID,
        types.GET_ACTION_BY_TRANSACTION_ID_SUCCESS,
        types.GET_ACTION_BY_TRANSACTION_ID_ERROR,
      ],
    },
  };
};

export const deleteActions = actionIds => ({
  [RSAA]: {
    endpoint: `actions/${actionIds.join(',')}?`,
    method: methods.DELETE,
    module: modules.CORE,
    types: [types.DELETE_ACTIONS, types.DELETE_ACTIONS_SUCCESS, types.DELETE_ACTIONS_ERROR],
  },
});
