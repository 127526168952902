import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { zonesColors } from '../../services/monitoringZonesColors';

const styles = theme => ({
  legendWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
  },
  colorRectangle: {
    display: 'inline-block',
    width: 10,
    height: 10,
    marginRight: 6,
  },
  label: {
    color: theme.palette.grey[500],
  },
});

export const BioMonitoringZonesLegend = props => {
  const { classes, index } = props;

  return (
    <div className={classes.legendWrapper}>
      {zonesColors[index].map(zoneColor => (
        <span className={classes.legendItem} key={`legend_zone_${zoneColor.id}`}>
          <span
            className={classes.colorRectangle}
            style={{ backgroundColor: zoneColor.color, border: zoneColor.borderColor ? '1px solid #E0E0E0' : '' }}
          />
          <span className={classes.label}>
            <FormattedMessage id="ManagementZones.CropQualityGraph.zone" /> {zoneColor.id}
          </span>
        </span>
      ))}
    </div>
  );
};

BioMonitoringZonesLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
};

export default withStyles(styles)(BioMonitoringZonesLegend);
