import React, { FC, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { exportDrives } from '../../actions/telematicsList.actions';

import ExportButton from '../../../shared/components/common/ExportButton/ExportButton';
import { Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { ExportDrivesFormat } from '../../../shared/api/telematics/drives/drives.types';

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
});

interface TelematicsExportProps {
    exportDrives: (format: ExportDrivesFormat) => void;
}

const TelematicsExport: FC<TelematicsExportProps> = ({ exportDrives }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleExcelExport = () => {
    exportDrives(ExportDrivesFormat.Excel);
    handlePopoverClose();
  };

  return (
    <div>
      <ExportButton handleClick={handlePopoverOpen} />
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          data-test={'export-xlsx'}
          onClick={handleExcelExport}
        >
          {'XLSX'}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      exportDrives,
    },
    dispatch,
  );

export default connect(undefined, mapDispatchToProps)(TelematicsExport);
