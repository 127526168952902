import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SendAllIcon = props => (
  <SvgIcon {...props}>
    <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(24, 24) rotate(180)">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7 8V5l-7 7 7 7v-3l-4-4 4-4zm6 1V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" />
      </g>
    </svg>
  </SvgIcon>
);

export default SendAllIcon;
