import React, { Component } from 'react';

import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = {
  username: {
    fontWeight: 500,
  },
  toolbarMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
    width: 40,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  tooltip: {
    margin: 4,
  },
};

class ToolsMenuControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { buttonId, children, classes, icon, tooltipMessage } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <Tooltip
          placement="bottom"
          title={tooltipMessage}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <div
            {...(buttonId ? { id: `${buttonId}` } : {})}
            className={classes.toolbarMenuItem}
            onClick={this.handleClick}
            role="button"
            tabIndex="0"
            style={{
              color: open ? '#FFFFFF' : null,
            }}
          >
            {icon}
          </div>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={this.handleClose}
          open={open}
          action={actions => {
            this.actions = actions;
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {React.cloneElement(children, {
            updatePosition: this.actions ? this.actions.updatePosition : undefined,
            onMenuItemClick: this.handleClose,
          })}
        </Menu>
      </div>
    );
  }
}

ToolsMenuControl.propTypes = {
  buttonId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element.isRequired,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.element.isRequired,
};

ToolsMenuControl.defaultProps = {
  buttonId: '',
  tooltipMessage: '',
};

export default withStyles(styles)(ToolsMenuControl);
