import React, { CSSProperties, FC, ReactNode, useEffect } from 'react';

import { Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getItems, getIsFetchingDrives, getTotalCount } from '../../../shared/api/telematics/drives/drives.selectors';
import {
  getTelematicsListAdvancedFilter,
  getTelematicsListOrder,
  getTelematicsListOrderBy,
  getTelematicsListPage,
  getTelematicsListRowsPerPage,
  getTelematicsListSelected,
  getTelematicsListSelectedOnPage,
  getTelematicsListTextFilter,
  getTelematicsListDateFilter,
} from '../../selectors/telematicsList.selectors';

import { fetchDrivesSaga } from '../../actions/telematicsList.actions';

import { NAMESPACE as namespace } from '../../reducer/telematicsList.reducer';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableCheckbox from '../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import { COLOR_GREY } from '../../../theme';
import { Thunk } from '../../../types';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../../components/TelematicsForeignWarning/TelematicsForeignWarning';
import TelematicsNotSendInfo from '../../components/TelematicsNotSendInfo/TelematicsNotSendInfo';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { Affiliation, LogbookItemTo, State } from '../../../shared/api/telematics/telematics.types';
import { TelematicsAdvancedFilter, TelematicsListDateFilter } from '../../telematics.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const renderDoubleLinedHeader = (primaryId: string, secondaryId: string) => (
  <div>
    <p style={{ ...colStyles.head.p }}>
      <FormattedMessage id={primaryId} />
    </p>
    <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
      <FormattedMessage id={secondaryId} />
    </p>
  </div>
);

const columns = {
  from: getColDesc(
    true,
    renderDoubleLinedHeader('common.time', 'common.date'),
  ),
  'driver.name': getColDesc(
    true,
    renderDoubleLinedHeader('TelematicsList.driverName', 'TelematicsList.chipId'),
  ),
  operation: getColDesc(false, <FormattedMessage id="TelematicsList.operation" />),
  'productionOperation.name': getColDesc(true, <FormattedMessage id="TelematicsList.productionOperation" />),
  parcelName: getColDesc(false, <FormattedMessage id="TelematicsList.parcelName" />),
  cultivated: getColDesc(
    true,
    renderDoubleLinedHeader('TelematicsList.cultivatedArea', 'TelematicsList.cultivatedPlot'),
  ),
  traversedDistance: getColDesc(false, <FormattedMessage id="TelematicsList.traversedDistance" />),
  'machine.name': getColDesc(
    true,
    renderDoubleLinedHeader('TelematicsList.machine', 'TelematicsList.machineLicencePlate'),
  ),
  additionalEquipment: getColDesc(
    false,
    renderDoubleLinedHeader('TelematicsList.additionalEquipment', 'TelematicsList.equipmentChipId'),
  ),
};

const useStyles = makeStyles((theme: Theme) => ({
  cellMultipleRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellRow: {
    margin: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  sensorName: {
    fontWeight: 500,
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  cellWithIcon: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '0.4rem',
  },
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
}));

export interface TelematicsTableProps {
  advancedFilter: TelematicsAdvancedFilter;
  count: number;
  dateFilter: TelematicsListDateFilter;
  drives: LogbookItemTo[];
  fetchDrives(): void;
  goToDetail(id: string): void;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
  textFilter: string;
}

export const TelematicsTable: FC<TelematicsTableProps> = ({
  advancedFilter,
  count,
  dateFilter,
  drives,
  fetchDrives,
  goToDetail,
  isFetching,
  langId,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  textFilter,
}) => {
  useEffect(() => {
    fetchDrives();
  }, [page, rowsPerPage, order, orderBy, textFilter, advancedFilter, dateFilter.lastUpdate, fetchDrives]);

  const classes = useStyles();

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={drives}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {drives.length ? (
        <TableBody>
          {drives.map(drive => {
            const isApprovedRide = drive.state !== State.NOT_APPROVED;
            return (
              <TableRow
                className={classes.pointer}
                hover
                key={drive.id}
                onClick={() => drive.id && goToDetail(drive.id)}
              >
                {drive.id && <CfTableCheckbox id={drive.id} namespace={namespace} selected={selected} />}
                <CfTableCell name={'from'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classnames(classes.cellRow, classes.nowrap)}>
                      <FormattedTime value={drive.from} /> - <FormattedTime value={drive.to} />
                    </div>
                    <div className={classnames(classes.cellRow, classes.greyText, classes.nowrap)}>
                      <FormattedDate value={drive.from} />
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'driver.name'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.driver?.name ?? drive.driver?.code ?? '-'}
                      {drive.driver?.affiliation === Affiliation.EXTERNAL &&
                      <TelematicsForeignWarning type={TelematicsForeignWarningType.Driver} />}
                    </div>
                    {drive.driver?.affiliation === Affiliation.COMPANY &&
                    <div className={classnames(classes.cellRow, classes.greyText)}>
                      {drive.driver?.code ?? '-'}
                    </div>}
                  </div>
                </CfTableCell>
                <CfTableCell name={'operation'}>
                  <div className={classes.cellWithIcon}>
                    <Tooltip title={<FormattedMessage id={isApprovedRide ? 'TelematicsList.approvedRide' : 'TelematicsList.unApprovedRide'} />}>
                      <div className={classnames({
                        [classes.operation]: isApprovedRide,
                        [classes.operationNotApproved]: !isApprovedRide,
                      })}>
                        {drive.operation ?? '-'}
                      </div>
                    </Tooltip>
                    {drive.state !== State.THIRD_PARTY_ACKNOWLEDGED && <TelematicsNotSendInfo />}
                    {/*
                    TODO handle after added to model
                    <TelematicsPartialDriveWarning /> */}
                  </div>
                </CfTableCell>
                <CfTableCell name={'productionOperation.name'}>
                  {drive?.productionOperation?.name ?? '-'}
                </CfTableCell>
                <CfTableCell name={'parcelName'}>
                  {drive.parcel?.blockNumber ?? '-'}
                  {drive.parcel?.affiliation === Affiliation.EXTERNAL &&
                  <TelematicsForeignWarning
                    text={drive.parcel?.subjectName}
                    type={TelematicsForeignWarningType.Parcel} />}
                </CfTableCell>
                <CfTableCell name={'cultivated'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.cultivated ? <span><CfFormattedNumber value={drive.cultivated} /> ha</span> : '-'}
                    </div>
                    <div className={classnames(classes.cellRow, classes.greyText)}>
                      {drive.parcelArea ? <span><CfFormattedNumber value={drive.parcelArea} /> ha</span> : '-'}
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'traversedDistance'}>
                  {drive.distance ? <span><CfFormattedNumber value={drive.distance} /> km</span> : '-'}

                </CfTableCell>
                <CfTableCell name={'machine.name'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.machineName ?? drive.gpsUnit ?? '-'}
                    </div>
                    <div className={classnames(classes.cellRow, classes.greyText)}>
                      {drive.machineLicencePlate ?? '-'}
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'additionalEquipment'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.equipment?.name ?? drive.equipment?.code ?? '-'}
                    </div>
                    <div className={classnames(classes.cellRow, classes.greyText)}>
                      {drive.equipment?.code ?? '-'}
                    </div>
                    {drive.equipment?.affiliation === Affiliation.EXTERNAL &&
                    <TelematicsForeignWarning type={TelematicsForeignWarningType.AdditionalEquipment} />}
                  </div>
                </CfTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />)}

      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  page: getTelematicsListPage(state),
  order: getTelematicsListOrder(state),
  orderBy: getTelematicsListOrderBy(state),
  rowsPerPage: getTelematicsListRowsPerPage(state),
  selected: getTelematicsListSelected(state),
  selectedOnPage: getTelematicsListSelectedOnPage(state),
  textFilter: getTelematicsListTextFilter(state),
  advancedFilter: getTelematicsListAdvancedFilter(state),
  dateFilter: getTelematicsListDateFilter(state),
  isFetching: getIsFetchingDrives(state),
  count: getTotalCount(state),
  drives: getItems(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrives: fetchDrivesSaga,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsTable);
