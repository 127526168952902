import React, { Component } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfAutosuggest from '../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

const styles = () => ({
  suggestionsContainerOpen: {
    position: 'static',
    margin: 0,
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
  },
  suggestionsList: {
    maxHeight: '300px',
    maxWidth: '350px',
  },
  suggestionItem: {
    fontSize: 14,
    paddingTop: 6,
    paddingBottom: 6,
    overflow: 'inherit',
  },
  input: {
    fontSize: 14,
    padding: '10px 15px 5px 15px',
  },
  positionEnd: {
    right: '8px',
  },
  iconButton: {
    width: 36,
    height: 36,
  },
  icon: {
    width: 20,
    height: 20,
  },
  container: {},
});

export class DrawCultureSelector extends Component {
  getSuggestionValue = sugg => [sugg.name].filter(item => item).join(', ');

  render() {
    const { classes, isFetching } = this.props;
    const { formatMessage } = this.props.intl;
    const placeholder = formatMessage({ id: 'DrawCultureSelector.enter-culture' });
    return (
      <CfAutosuggest
        alwaysRenderSuggestions={true}
        clearInputOnAdornmentClick={true}
        clearInputOnSelect={true}
        clearSuggestions={this.props.onSuggestionClear}
        disableUnderline={true}
        getSuggestions={this.props.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isFetching={isFetching}
        multiSection={false}
        onSuggestionSelected={this.props.onSuggestionSelect}
        placeholder={placeholder}
        requiredLength={1}
        scrollable={true}
        scrollHeightMax={300}
        suggestions={this.props.suggestions.map(sugg => ({ ...sugg }))}
        testData="culture-selector"
        adornment={
          <IconButton className={classes.iconButton} disabled={true} size="large">
            <SearchIcon className={classes.icon} />
          </IconButton>
        }
        classes={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestionItem: classes.suggestionItem,
          input: classes.input,
          positionEnd: classes.positionEnd,
          spinner: classes.spinner,
        }}
      />
    );
  }
}

DrawCultureSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  isFetching: PropTypes.bool,
  getSuggestions: PropTypes.func.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
};

DrawCultureSelector.defaultProps = {
  suggestions: [],
  isFetching: false,
};

export default injectIntl(withStyles(styles)(DrawCultureSelector));
