import React, { Component } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Popover } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import { getFilteredLandUseSuggestions } from '../../selectors/editor.selectors';

import { setLandUseFilter } from '../../actions/draw/draw.actions';

import { getLanduse, resetLanduse } from '../../../../shared/api/core/landuse/landuse.api';
import CfButton from '../../../../shared/components/form/CfButton/CfButton';
import CfReduxFormTextField from '../../../../shared/components/form/CfReduxFormTextField/CfReduxFormTextField';
import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import withPopover from '../../../../shared/hocs/withPopover';
import DrawCultureSelector from '../../components/DrawCultureSelector/DrawCultureSelector';
import ToolbarHoverStopper from '../../components/ToolbarHoverStopper/ToolbarHoverStopper';

const styles = theme => ({
  inputWrapper: {
    float: 'left',
    height: '28px',
    borderRadius: '20px 0 0 20px',
    display: 'flex',
    alignItems: 'center',
  },
  inputRoot: {
    fontSize: '14px',
    margin: '0 8px 0 14px',
  },
  input: {
    padding: '2px 0 2px',
    fontWeight: 500,
    maxWidth: '100px',
    '&::placeholder': {
      opacity: 1,
    },
  },
  inputInputGrey: {
    color: theme.palette.grey[500],
  },
  rightIcon: {
    right: '-21px',
    position: 'absolute',
  },
  selectorRoot: {
    float: 'left',
    padding: '0 8px',
    minHeight: '28px',
    minWidth: 'auto',
    height: '28px',
    borderRadius: '0 20px 20px 0',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selectorLabel: {
    position: 'relative',
    textTransform: 'none',
    marginRight: '15px',
  },
  cultureNamePlaceholder: {
    color: theme.palette.grey[500],
  },
  dividerWrapper: {
    float: 'left',
    width: '1px',
    height: '28px',
    padding: '3px 0',
  },
  divider: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
  },
});

export class DrawParcelForm extends Component {
  constructor(props) {
    super(props);
    this.anchor = null;
    this.initFetchPromise = null;
  }

  componentDidMount() {
    this.initFetchPromise = this.props.getLanduse({ textFilter: null, page: 0, rowsPerPage: 50 });
  }

  componentDidUpdate(prevProps) {
    const { langId } = this.props;
    if (prevProps.langId && prevProps.langId !== langId) {
      this.props.resetLanduse();
      this.initFetchPromise = this.props.getLanduse({ textFilter: null, page: 0, rowsPerPage: 50 });
    }
  }

  componentWillUnmount() {
    this.props.resetLanduse();
  }

  onDropdownClick = () => {
    const promise = this.initFetchPromise || Promise.resolve();
    promise.then(() => {
      this.props.handlePopoverOpen({ currentTarget: this.anchor });
      this.initFetchPromise = null;
    });
  };

  onSuggestionSelect = suggestion => {
    this.props.change('culture', suggestion);
    this.onSuggestionClear();
  };

  onSuggestionClear = () => {
    this.props.handlePopoverClose();
    setTimeout(() => {
      this.props.setLandUseFilter(null);
    });
  };

  filterLandUse = (textFilter, reason) => {
    if (!textFilter && reason === 'input-focused') {
      return Promise.resolve();
    }
    this.props.setLandUseFilter(textFilter);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { anchorEl, classes, culture, isOpen, name, suggestions } = this.props;
    return (
      <ToolbarSection>
        <ToolbarHoverStopper />
        <form>
          <div className={classes.inputWrapper}>
            <Field
              autoComplete={'off'}
              autoFocus={false}
              component={CfReduxFormTextField}
              name="name"
              type="text"
              InputProps={{
                placeholder: formatMessage({ id: 'common.parcel-name' }),
                disableUnderline: false,
                spellCheck: false,
                classes: {
                  root: classes.inputRoot,
                  input: `${classes.input} ${name.length ? '' : classes.inputInputGrey}`,
                },
              }}
            />
          </div>

          <div
            className={classes.dividerWrapper}
            ref={btn => {
              this.anchor = btn;
            }}
          >
            <div className={classes.divider} />
          </div>

          <Field
            aria-label="new_parcel_culture"
            component={CfButton}
            disableFocusRipple={true}
            name="culture"
            onClick={this.onDropdownClick}
            classes={{
              root: classes.selectorRoot,
              label: classes.selectorLabel,
            }}
          >
            {culture.name ? (
              <span>{culture.name}</span>
            ) : (
              <span className={classes.cultureNamePlaceholder}>
                <FormattedMessage id="common.culture" />
              </span>
            )}
            <ArrowDropDownIcon className={classes.rightIcon} />
          </Field>

          <Popover
            anchorEl={anchorEl}
            onClose={this.onSuggestionClear}
            open={isOpen}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            PaperProps={{
              'data-test': 'culture-list',
            }}
            transformOrigin={{
              vertical: -5,
              horizontal: 0,
            }}
          >
            <DrawCultureSelector
              getSuggestions={this.filterLandUse}
              onSuggestionClear={this.onSuggestionClear}
              onSuggestionSelect={this.onSuggestionSelect}
              suggestions={suggestions}
            />
          </Popover>
        </form>
      </ToolbarSection>
    );
  }
}

const selector = formValueSelector('new_parcel');

const mapStateToProps = state => ({
  name: selector(state, 'name'),
  culture: selector(state, 'culture'),
  suggestions: getFilteredLandUseSuggestions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLanduse,
      resetLanduse,
      setLandUseFilter,
    },
    dispatch,
  );

DrawParcelForm.propTypes = {
  name: PropTypes.string,
  culture: PropTypes.object,
  intl: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  getLanduse: PropTypes.func.isRequired,
  resetLanduse: PropTypes.func.isRequired,
  setLandUseFilter: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

DrawParcelForm.defaultProps = {
  name: '',
  culture: {},
  anchorEl: null,
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'new_parcel',
  }),
  withStyles(styles),
  withPopover,
)(DrawParcelForm);
