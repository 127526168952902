/**
 * Created by ondrejzvara on 9.12.15.
 */

import angular from 'angular';

import { cfDate } from './cfDate.filter';
import { cfDateTime } from './cfDateTime.filter';
import { cfPorCropList } from './cfPorCropList.filter';
import { cfPorTargetOrganism } from './cfPorTargetOrganism.filter';
import { ifFilter } from './if.filter';
import { isFalse } from './isFalse.filter';
import { isNull } from './isNull.filter';
import { unit } from './unit.filter';


export default angular.module('app.filters', [])
    .filter('cfDate', cfDate)
    .filter('cfDateTime', cfDateTime)
    .filter('cfPorCropList', cfPorCropList)
    .filter('cfPorTargetOrganism', cfPorTargetOrganism)
    .filter('if', ifFilter)
    .filter('isFalse', isFalse)
    .filter('isNull', isNull)
    .filter('unit', unit)
    .name;

