import MultiPoint from 'ol/geom/MultiPoint';

import Geometry, { GEOM_TYPES } from '../geometry/Geometry.service';

import CommonStyles, { STROKE_COLOR, FILL_COLOR } from './CommonStyles.service';

export default class CommonPointStyle {
  static getDrawing() {
    return CommonStyles.getGeometryStyle({
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry(feature) {
        // this way supports only linestrings
        return new MultiPoint(feature.getGeometry().getCoordinates());
      },
    });
  }

  static getDrawed() {
    return CommonStyles.getGeometryStyle({
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry(feature) {
        return new MultiPoint(getFeatureCoordinates(feature));
      },
    });
  }

  static getTransparent() {
    return CommonStyles.getGeometryStyle({
      image: {
        radius: 6,
        fill: { color: FILL_COLOR.TRANSPARENT },
        stroke: { color: FILL_COLOR.TRANSPARENT, width: 0 },
      },
      geometry(feature) {
        return new MultiPoint(feature.getGeometry().getCoordinates()[0]);
      },
    });
  }

  static getDrawedError() {
    return CommonStyles.getGeometryStyle({
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.ERROR },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry(feature) {
        return new MultiPoint(getFeatureCoordinates(feature));
      },
    });
  }

  static getMeasurement() {
    return CommonStyles.getGeometryStyle({
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.MEASUREMENT },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
      geometry(feature) {
        return new MultiPoint(getFeatureCoordinates(feature));
      },
    });
  }
}

const getFeatureCoordinates = feature => {
  const geometry = feature.getGeometry();
  const geometryType = Geometry.getOLType(geometry);
  const coords = geometry.getCoordinates();
  if (geometryType === GEOM_TYPES.POLYGON) {
    return [].concat(...coords);
  }
  if (geometryType === GEOM_TYPES.LINESTRING) {
    return coords;
  }
  return [];
};
