import * as types from './satelliteProducts.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: {},
  },
  action = {},
) => {
  switch (action.type) {
    case types.GET_SATELLITE_PRODUCTS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_SATELLITE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_SATELLITE_PRODUCTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_SATELLITE_PRODUCTS:
      return {
        isFetching: false,
        error: {},
        items: {},
      };
    default:
      return state;
  }
};
