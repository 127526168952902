import React, { Component } from 'react';

import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

const styles = () => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: '0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 100,
    overflow: 'hidden',
  },
  custom: {},
});

class MapLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
    };

    this.debouncedFnc = debounce(this.displayLoader, props.offset);
  }

  componentDidUpdate(prevProps) {
    const { isFetching } = this.props;
    if (prevProps.isFetching !== isFetching) {
      if (isFetching) {
        this.debouncedFnc(isFetching);
      } else {
        this.displayLoader(isFetching);
      }
    }
  }

  componentWillUnmount() {
    if (this.debouncedFnc) {
      this.debouncedFnc.cancel();
    }
  }

  displayLoader = display => {
    this.setState({ display });
  };

  render() {
    const { display } = this.state;
    const { classes, isFetching } = this.props;

    return isFetching && display && <div className={`${classes.loader} ${classes.custom}`}>{<CircularProgress />}</div>;
  }
}

MapLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  offset: PropTypes.number,
};

MapLoader.defaultProps = {
  offset: 250,
};

export default withStyles(styles)(MapLoader);
