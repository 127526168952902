import React, { Component } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfReduxFormSwitch from '../../../../../shared/components/form/CfReduxFormSwitch/CfReduxFormSwitch';
import SentinelAdminParcelsControl from '../SentinelAdminParcelsControl/SentinelAdminParcelsControl';

const styles = {
  switchHistoricRoot: {
    display: 'inline-block',
    width: '100%',
    margin: 0,
  },
  switchHistoricLabel: {
    fontSize: '16px',
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    width: 'calc(100% - 62px)',
  },
  switchersPanel: {
    marginTop: 20,
  },
};

class SentinelAdminParcelsDialog extends Component {
  constructor(props) {
    super(props);
    this.emptyFormValues = {
      parcels: [],
      historyPotentialEnabled: false,
      biomonitoringEnabled: false,
      variableApplicationEnabled: false,
    };
  }

  onSubmit = this.props.handleSubmit(values => this.props.onAccept(!this.props.parcel, values));

  initializeForm = () => {
    const { parcel } = this.props;
    const initialValues = parcel
      ? {
        parcels: [{ parcelId: parcel.parcelId, blockNr: parcel.blockNr, name: parcel.name }],
        historyPotentialEnabled: parcel.historyPotentialEnabled,
        biomonitoringEnabled: parcel.biomonitoringEnabled,
        variableApplicationEnabled: parcel.variableApplicationEnabled,
      }
      : this.emptyFormValues;
    this.props.initialize({
      ...initialValues,
    });
  };

  handleClose = () => {
    const { destroy, onClose } = this.props;
    onClose();
    destroy();
  };

  renderSwitch(name, label) {
    const { classes } = this.props;
    return (
      <FormControlLabel
        control={<Field color="primary" component={CfReduxFormSwitch} id={name} name={name} />}
        label={label}
        classes={{
          root: classes.switchHistoricRoot,
          label: classes.switchHistoricLabel,
        }}
      />
    );
  }

  render() {
    const { classes, opened, parcel, submitting } = this.props;
    const adding = !parcel;
    return (
      <CfDialog
        acceptText={<FormattedMessage id={`${adding ? 'common.add' : 'common.edit'}`} />}
        cancelText={<FormattedMessage id="common.cancel" />}
        disabled={submitting}
        onAccept={this.onSubmit}
        onCancel={this.handleClose}
        onClose={this.handleClose}
        onEnter={this.initializeForm}
        opened={opened}
        title={
          <FormattedMessage
            id={`${
              adding
                ? 'SentinelAdminParcelsDialog.addServicesToParcels'
                : 'SentinelAdminParcelsDialog.editServicesAtParcel'
            }`}
          />
        }
      >
        <form>
          <div>
            <FieldArray component={SentinelAdminParcelsControl} editing={adding} name="parcels" />
          </div>

          <div className={classes.switchersPanel}>
            {this.renderSwitch('historyPotentialEnabled', <FormattedMessage id="SentinelAdmin.historyPotential" />)}
            {this.renderSwitch('biomonitoringEnabled', <FormattedMessage id="SentinelAdmin.monitoring" />)}
            {this.renderSwitch(
              'variableApplicationEnabled',
              <FormattedMessage id="SentinelAdmin.variableApplications" />,
            )}
          </div>
        </form>
      </CfDialog>
    );
  }
}

SentinelAdminParcelsDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object,
  destroy: PropTypes.func.isRequired,
};

SentinelAdminParcelsDialog.defaultProps = {
  opened: false,
  parcel: null,
};

export default connect()(
  reduxForm({
    form: 'sentinelAdminParcel',
  })(withStyles(styles)(SentinelAdminParcelsDialog)),
);
