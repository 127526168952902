import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { compose } from 'react-recompose';

import withWidth from '../../../hocs/withWidth';
import ShowHideMap from '../../common/ShowHideMap/ShowHideMap';

const styles = () => ({
  wrapper: {
    position: 'absolute',
    top: 56,
    bottom: 0,
    right: 0,
    left: 0,
    height: 'calc(100% - 56px)',
  },
  mapButton: {
    position: 'absolute',
    bottom: 'calc(50% - 18px)',
    backgroundColor: '#FFFFFF',
    minWidth: 0,
    padding: 0,
    zIndex: 1200,
    height: 36,
  },
  mapButtonRight: {
    right: 0,
  },
  mapButtonLeft: {
    left: 0,
  },
  bodyWrapper: {
    position: 'relative',
  },
  mapWrapper: {
    height: '100%',
  },
});

export class SidebarMapWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMap: this.isDesktop(props.width),
    };
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;
    const { displayMap } = this.state;

    if (width !== prevProps.width) {
      if (this.isDesktop(prevProps.width) && !this.isDesktop(width) && displayMap) {
        this.toggleMap();
      }

      if (!this.isDesktop(prevProps.width) && this.isDesktop(width) && !displayMap) {
        this.toggleMap();
      }
    }
  }

  isDesktop = width => ['md', 'lg', 'xl'].indexOf(width) >= 0;

  toggleMap = () => {
    this.setState({
      displayMap: !this.state.displayMap,
    });
  };

  render() {
    const { displayMap } = this.state;
    const { children, classes, map, width } = this.props;
    const displayContent = !displayMap || (width !== 'xs' && width !== 'sm');
    return (
      <Grid className={classes.wrapper} container>
        {displayContent && (
          <Grid className={classes.bodyWrapper} item md={displayMap ? 8 : 12} xs={12}>
            <Scrollbars>{children}</Scrollbars>
            <ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={!displayMap} />
          </Grid>
        )}
        <Grid className={classes.mapWrapper} item md={4} style={{ display: displayMap ? 'block' : 'none' }} xs={12}>
          {!displayContent && <ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={false} />}
          {React.cloneElement(map, { displayMap })}
        </Grid>
      </Grid>
    );
  }
}

SidebarMapWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  map: PropTypes.node.isRequired,
};

SidebarMapWrapper.defaultProps = {};

export default compose(withStyles(styles), withWidth())(SidebarMapWrapper);
