import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SensorsService from '../../../services/Sensors.service';
import CfAutocomplete from '../../common/CfAutocomplete/CfAutocomplete';

export class SensorFilterByStatus extends Component {
  mapSuggestions() {
    const {
      intl: { formatMessage },
      useLabelTranslation,
    } = this.props;
    const states = SensorsService.getNodesStates();
    return states.map(s => ({ name: s, label: useLabelTranslation ? formatMessage({ id: `SensorsStatus.${s}` }) : s }));
  }

  render() {
    const { disabled, label, selectedStatus, setSensorStatusFilter } = this.props;

    return (
      <CfAutocomplete
        defaultValues={selectedStatus}
        disabled={disabled}
        id="status-filter"
        isMultiple={true}
        label={label}
        onChange={value => setSensorStatusFilter('status', value)}
        suggestions={this.mapSuggestions()}
        testId="status-filter"
      />
    );
  }
}

SensorFilterByStatus.propTypes = {
  selectedStatus: PropTypes.array,
  label: PropTypes.node.isRequired,
  setSensorStatusFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  useLabelTranslation: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

SensorFilterByStatus.defaultProps = {
  selectedStatus: [],
  disabled: false,
  useLabelTranslation: false,
};

export default injectIntl(SensorFilterByStatus);
