import React, { Component } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import ValidationStatusIcon from '../../../../../shared/components/specific/ValidationStatusIcon/ValidationStatusIcon';
import ChemistryEffectiveness from '../../containers/ChemistryEffectiveness/ChemistryEffectiveness';

const style = {
  positionStart: {
    position: 'absolute',
    left: '-30px',
    top: '11px',
  },
};

class ActionExpensesList extends Component {
  getColorByMaterialType = typeId => {
    let color = 'primary';
    switch (typeId) {
      case 'FR':
        color = '#88B04B';
        break;
      case 'CH':
        color = '#6AA8EC';
        break;
      default:
        color = 'primary';
    }
    return color;
  };

  renderFieldSubheading = field => {
    let cont = <div />;

    if (field.material.materialTypeId === 'FR') {
      cont = field.material.producent ? <div>{field.material.producent}</div> : <div />;
    } else if (field.material.materialTypeId === 'CH') {
      cont = (
        <div>
          {[field.material.marketingAuth, field.material.regNumber, field.material.aktualState]
            .filter(val => val)
            .join(', ')}
        </div>
      );
    }
    return <div>{cont}</div>;
  };

  renderExpenseItem = (fieldName, index, field) => {
    const materialTypeId = field.material.materialTypeId;
    const color = this.getColorByMaterialType(materialTypeId);
    const { classes } = this.props;

    return (
      <SelectionItem
        editing={this.props.editing}
        key={fieldName}
        onRemoveItem={() => {
          this.props.onItemRemove(index);
        }}
      >
        <Grid container spacing={0}>
          <Grid item sm={3} xs={12}>
            <SelectionItemHeading subheading={this.renderFieldSubheading(field)}>
              <div>
                <a
                  href="#"
                  style={{ color }}
                  onClick={() => {
                    this.props.onDialogOpen(field, index);
                  }}
                >
                  {field.material.name}
                </a>
              </div>
            </SelectionItemHeading>
          </Grid>

          <Grid item sm={2} xs={6}>
            <SelectionItemColumn label={<FormattedMessage id="common.dosePerHectare" />}>
              <div>
                {field.dose ? <CfFormattedNumber value={field.dose} /> : '-'}{' '}
                {
                  <span>
                    <FormattedMessage id={`unit.${field.doseUnit.id}`} />
                    /ha
                  </span>
                }
              </div>
            </SelectionItemColumn>
          </Grid>

          <Grid item sm={2} xs={6}>
            <SelectionItemColumn label={<FormattedMessage id="common.totalDose" />}>
              <FormControl fullWidth={true}>
                {(field.dose * this.props.parcelsArea).toFixed(2) !== field.dosage && (
                  <InputAdornment classes={{ positionStart: classes.positionStart }} position="start">
                    <ValidationStatusIcon id={fieldName} type={'info'}>
                      {<FormattedMessage id="ChemistryDialog.dosage.warning" />}
                    </ValidationStatusIcon>
                  </InputAdornment>
                )}
                <div>
                  {field.dosage ? <CfFormattedNumber value={field.dosage} /> : '-'}{' '}
                  {<FormattedMessage id={`unit.${field.dosageUnit.id}`} />}
                </div>
              </FormControl>
            </SelectionItemColumn>
          </Grid>

          {materialTypeId === 'CH' && (
            <Grid item sm={3} xs={6}>
              <SelectionItemColumn label={<FormattedMessage id="ChemistryDialog.pests" />}>
                <div>
                  {field.pests && field.pests.length ? field.pests[0].name : '-'}
                  {field.pests && field.pests[1] ? ', ...' : ''}
                </div>
              </SelectionItemColumn>
            </Grid>
          )}

          {materialTypeId === 'CH' && (
            <Grid item sm={2} xs={6}>
              <SelectionItemColumn label={<FormattedMessage id="ChemistryEffectiveness.heading" />}>
                <ChemistryEffectiveness
                  chemistry={field}
                  editing={this.props.editing}
                  formName={this.props.formName}
                  index={index}
                />
              </SelectionItemColumn>
            </Grid>
          )}

          {materialTypeId === 'FR' && (
            <Grid item sm={4} xs={12}>
              <SelectionItemColumn
                label={
                  <span>
                    N <FormattedMessage id={'unit.kg'} />
                    /ha
                  </span>
                }
              >
                <div>{field.n !== undefined ? <CfFormattedNumber value={field.n} /> : '-'}</div>
              </SelectionItemColumn>
            </Grid>
          )}
        </Grid>
      </SelectionItem>
    );
  };

  render() {
    const { fields } = this.props;

    return (
      <div id="action-expense-list">
        {fields.map((fieldName, index) => {
          const field = fields.get(index);
          return this.renderExpenseItem(fieldName, index, field);
        })}
      </div>
    );
  }
}

ActionExpensesList.propTypes = {
  classes: PropTypes.object.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  rerenderSwitch: PropTypes.bool // eslint-disable-line
};

ActionExpensesList.defaultProps = {
  editing: false,
  rerenderSwitch: false,
};

export default withStyles(style)(ActionExpensesList);
