import React, { Fragment } from 'react';

import _ from 'lodash';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';

import { isDeletingActions } from '../../../../../shared/api/core/actions/actions.selectors';
import {
  getParcel,
  getParcelActions,
  isParcelActionsFetching,
  getParcelActionsTotalCount,
} from '../../../../../shared/api/core/parcels/parcels.selectors';
import { getVariableActionIds } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import ActionLabel from '../../../../../evidence/actions/list/components/ActionLabel/ActionLabel';
import { getRouting } from '../../../../../evidence/actions/shared/misc/action.helpers';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import FormattedAmount from '../../../../../shared/components/common/FormattedAmount/FormattedAmount';
import CfTableCellNumber from '../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber';
import { COLOR_GREY } from '../../../../../theme';

export const getPage = state => state.ui.parcelActivities.page;
export const getRowsPerPage = state => state.ui.parcelActivities.rowsPerPage;
export const getOrder = state => state.ui.parcelActivities.order;
export const getOrderBy = state => state.ui.parcelActivities.orderBy;
export const getSelected = state => state.ui.parcelActivities.selected;
export const getTextFilter = state => state.ui.parcelActivities.textFilter;
export const getDateFilter = state => state.ui.parcelActivities.dateFilter;

export const getIsFetching = state => isParcelActionsFetching()(state) || isDeletingActions(state);
export const getParcelActivitiesTotalCount = state => getParcelActionsTotalCount()(state);

const getFarmId = (state, props) => props.match.params.farmId;
export const getProcessedActivities = createSelector(
  getParcel(),
  getParcelActions(),
  getFarmId,
  getVariableActionIds,
  (parcel, actions, farmId, variableActionIds) => processValues(parcel, actions, farmId, variableActionIds || []),
);
export const getSelectedOnPage = createSelector(getParcelActions(), getSelected, (items, selected) =>
  _.intersection(
    items.map(item => item.id),
    selected,
  ),
);

const getActionCrop = action => {
  const result = null;

  if (!action || _.isEmpty(action) || !action.actionType.actionClass) {
    return result;
  }

  if (action.actionType.actionClass.code === 'CHEM' || action.actionType.actionClass.code === 'FERT') {
    return _.get(action, 'targetCrop.name', null);
  }

  const cropNamePath =
    action.actionType.code === 'HARVEST' || action.actionType.code === 'PLOW'
      ? 'seedApplication.seedingActionSeed.crop.name'
      : 'seedApplication.seed.crop.name';

  return _.get(action.parcel, cropNamePath, null);
};

const getActionMaterial = expense => (expense && expense.material.name ? expense.material.name : '-');
const getActionMaterialElementValue = (expense, element) =>
  (<CfTableCellNumber>
    {expense && expense[element] ? <CfFormattedNumber value={expense[element]} /> : '-'}
  </CfTableCellNumber>);
const getActionDose = expense => (<FormattedAmount expense={expense} param={'amount'} />);
const getActionDosage = expense => (<FormattedAmount expense={expense} param={'dose'} perHa={true} />);

const styles = {
  label: {
    span: {
      minWidth: 'max-content',
      display: 'flex',
    },
  },
};

const processValues = (parcel, activities, farmId, variableActionIds) =>
  activities.map(activity => {
    const { expenses, parcel: actionParcel } = activity;
    return {
      id: activity.id,
      route: getRouting(activity, variableActionIds),
      single: {
        'dateStart,id': {
          val: <FormattedDate value={activity.dateStart} />,
          rowspan: expenses.length,
        },
        'actionType.localizedNames.value': {
          val: (
            <Fragment>
              <span style={{ fontWeight: 500 }}><FormattedMessage id={`common.${activity.actionType.code}`} /></span>
              {variableActionIds?.includes?.(activity.id) && (
                <span style={styles.label.span}>
                  <ActionLabel />
                </span>
              )}
            </Fragment>
          ),
          rowspan: expenses.length,
        },
        activityCrop: {
          val: (
            <span>
              <div>{getActionCrop(activity)}</div>
            </span>
          ),
          rowspan: expenses.length,
        },
        area: {
          val: (
            <CfTableCellNumber>
              <span>
                <div>
                  <CfFormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={actionParcel.area} />
                </div>
                <div style={{ color: COLOR_GREY[500] }}>
                  <CfFormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={parcel.area} />
                </div>
              </span>
            </CfTableCellNumber>
          ),
          rowspan: expenses.length,
        },
        material: {
          val: getActionMaterial(expenses[0]),
        },
        dose: {
          val: getActionDose(expenses[0]),
        },
        dosePerHectare: {
          val: getActionDosage(expenses[0]),
        },
        n: {
          val: getActionMaterialElementValue(expenses[0], 'accountableN'),
        },
        p2o5: {
          val: getActionMaterialElementValue(expenses[0], 'p2o5'),
        },
        k2o: {
          val: getActionMaterialElementValue(expenses[0], 'k2o'),
        },
        mgo: {
          val: getActionMaterialElementValue(expenses[0], 'mgo'),
        },
        cao: {
          val: getActionMaterialElementValue(expenses[0], 'cao'),
        },
        s: {
          val: getActionMaterialElementValue(expenses[0], 's'),
        },
      },
      multi: expenses.slice(1).map(expense => ({
        material: {
          val: getActionMaterial(expense),
        },
        dose: {
          val: getActionDose(expense),
        },
        dosePerHectare: {
          val: getActionDosage(expense),
        },
        n: {
          val: getActionMaterialElementValue(expense, 'accountableN'),
        },
        p2o5: {
          val: getActionMaterialElementValue(expense, 'p2o5'),
        },
        k2o: {
          val: getActionMaterialElementValue(expense, 'k2o'),
        },
        mgo: {
          val: getActionMaterialElementValue(expense, 'mgo'),
        },
        cao: {
          val: getActionMaterialElementValue(expense, 'cao'),
        },
        s: {
          val: getActionMaterialElementValue(expense, 's'),
        },
      })),
    };
  });
