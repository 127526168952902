import React, { Fragment, Component } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setEnlargedVariant } from '../../actions/map.actions';
import { setPredictionConfig } from '../../actions/predictions.actions';

import * as pestTypes from '../../constants/pestTypes.constants';

import { getPredictionConfig } from '../../../shared/api/iot/predictions/predictions.api';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import PredictionDateSetting from '../../components/PredictionDateSetting/PredictionDateSetting';
import SensorsNavigationButton from '../../components/SensorsNavigationButton/SensorsNavigationButton';
import SensorsPestPrediction from '../../services/SensorsPestPrediction.service';

const styles = theme => ({
  sensorPlacementItem: {
    alignSelf: 'center',
  },
  content: {
    paddingRight: 16,
  },
  label: {
    flexGrow: 1,
  },
  formLabel: {
    marginBottom: 0,
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  heading: {
    fontSize: 20,
  },
  wrapper: {
    padding: theme.spacing(2),
  },
});

export class PestPredictionsSettings extends Component {
  constructor(props) {
    super(props);
    props.setEnlargedVariant(true);
  }

  componentDidMount() {
    const { predictions } = this.props;
    const fusariumPrediction = predictions.find(prediction => prediction.name === pestTypes.FUSARIUM_HEAD_BLIGHT);
    if (fusariumPrediction !== undefined) {
      this.props.getPredictionConfig(fusariumPrediction.name, fusariumPrediction.featureLocationId);
    }
  }

  changePredictionVisibility = (featureLocationId, visible) => {
    const { nodeLocationId } = this.props;
    this.props.setPredictionConfig(nodeLocationId, featureLocationId, { visible });
  };

  handleDayMonthChange = (featureLocationId, day, month) => {
    const { nodeLocationId } = this.props;
    this.props.setPredictionConfig(nodeLocationId, featureLocationId, { day, month });
  };

  render() {
    const { classes, history, match, predictions } = this.props;

    return (
      <div className={classes.wrapper}>
        <PageHeader
          backButton={<SensorsNavigationButton history={history} match={match} />}
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              classes={{ heading: classes.heading }}
              value={<FormattedMessage id="NodePredictionsSettings.pestPredictionsSettings" />}
            />
          }
        />
        <div className={classes.content}>
          <FormGroup>
            {predictions.map(prediction => {
              const predictionConf = SensorsPestPrediction.getPredictionTypeConfig(prediction.name);
              return predictionConf ? (
                <Fragment key={prediction.id}>
                  <FormControlLabel
                    data-test="prediction-toggle"
                    key={prediction.id}
                    labelPlacement="start"
                    classes={{
                      root: classes.formLabel,
                      label: classes.label,
                    }}
                    control={
                      <Switch
                        checked={prediction.visible}
                        color="primary"
                        name="set-prediction-visibility"
                        onChange={event =>
                          this.changePredictionVisibility(prediction.featureLocationId, event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography variant="body1">
                        <FormattedMessage id={predictionConf.name} />
                      </Typography>
                    }
                  />
                  {prediction.name === pestTypes.FUSARIUM_HEAD_BLIGHT &&
                    prediction.visible &&
                    !!prediction.validAfterMonth &&
                    !!prediction.validAfterDay && (
                    <PredictionDateSetting
                      day={prediction.validAfterDay}
                      label={<FormattedMessage id="NodePredictionsSettings.expectedDateOfFlowering" />}
                      month={prediction.validAfterMonth - 1}
                      onDayMonthChange={(newDay, newMonth) =>
                        this.handleDayMonthChange(prediction.featureLocationId, newDay, newMonth + 1)
                      }
                    />
                  )}
                </Fragment>
              ) : null;
            })}
          </FormGroup>
        </div>
      </div>
    );
  }
}

PestPredictionsSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  predictions: PropTypes.array.isRequired,
  setPredictionConfig: PropTypes.func.isRequired,
  getPredictionConfig: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  nodeLocationId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPredictionConfig,
      setPredictionConfig,
      setEnlargedVariant,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(PestPredictionsSettings));
