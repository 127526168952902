import isEqual from 'lodash/isEqual';

import LocalStorage from './LocalStorage.service';

/*
  tableParams: {
    [farmId]: {
      [viewId]: {},
      [viewId]: {}
    },
    [farmId]: {
      [viewId]: {}
    }
  }
*/

export default class TableCache {
  static storeTableSettings(key, farmId, viewId, data) {
    const oldData = TableCache.getTableCache(key) || {};
    const newData = {
      ...oldData,
      [farmId]: {
        ...oldData[farmId],
        [viewId]: {
          ...data,
        },
      },
    };

    LocalStorage.saveToLocalStorage(newData, key);
  }

  static getTableCache(key) {
    return LocalStorage.loadFromLocalStorage(key);
  }

  static getTableCachePerFarm(key, farmId) {
    const tableData = TableCache.getTableCache(key);
    return tableData && tableData[farmId] ? tableData[farmId] : null;
  }

  static getTableCachePerFarmView(key, farmId, viewId) {
    const tableData = TableCache.getTableCachePerFarm(key, farmId);
    return tableData && tableData[viewId] ? tableData[viewId] : null;
  }

  static isCacheExpired(key, farmId, viewId, newData) {
    const cachedData = TableCache.getTableCachePerFarmView(key, farmId, viewId);
    return !isEqual(newData, cachedData);
  }

  /** ******************************************************************************* */

  static deleteTableParams() {
    LocalStorage.removeFromLocalStorage('tableParams');
  }

  static deleteTableSelection() {
    LocalStorage.removeFromLocalStorage('tableSelection');
  }

  static storeTableParams(farmId, viewId, params) {
    if (TableCache.isCacheExpired('tableParams', farmId, viewId, params)) {
      TableCache.storeTableSettings('tableParams', farmId, viewId, params);
    }
  }

  static storeTableCheckboxes(farmId, viewId, checkboxes) {
    TableCache.storeTableSettings('tableSelection', farmId, viewId, checkboxes);
  }

  static retrieveTableParams(farmId, viewId) {
    return TableCache.getTableCachePerFarmView('tableParams', farmId, viewId);
  }

  static retrieveTableSelection(farmId, viewId) {
    return TableCache.getTableCachePerFarmView('tableSelection', farmId, viewId);
  }
}
