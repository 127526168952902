import * as types from './farms.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    item: {},
    countries: [],
    isFetchingCountries: false,
    errorCountries: {},
    eagriFarmNameIsFetching: false,
    eagriFarmName: null,
    eagriFarmNameError: false,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_FARMS:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FARMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_FARMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_FARMS:
      return {
        ...state,
        items: [],
        error: {},
      };
    case types.GET_FARM:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FARM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_FARM_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.GET_EAGRI_FARM_NAME:
      return {
        ...state,
        eagriFarmNameIsFetching: true,
      };
    case types.GET_EAGRI_FARM_NAME_SUCCESS:
      return {
        ...state,
        eagriFarmNameIsFetching: false,
        eagriFarmName: action.payload,
      };
    case types.GET_EAGRI_FARM_NAME_FAILURE:
      return {
        ...state,
        eagriFarmNameIsFetching: false,
        eagriFarmNameError: action.payload,
      };
    case types.GET_COUNTRIES:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingCountries: false,
        countries: action.payload,
      };
    case types.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingCountries: false,
        errorCountries: action.payload,
      };
    case types.RESET_COUNTRIES:
      return {
        ...state,
        countries: [],
        errorCountries: {},
      };
    default:
      return state;
  }
};
