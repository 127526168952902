import React, { Fragment, Component } from 'react';

import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors } from 'redux-form';

import { getParcelsAndZonesSuggestions, getParcelsToAdd } from '../../selectors/reports.selectors';

import {
  addParcelOrZoneParcelsToAdd,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
  updateParcelsList,
} from '../../actions/reports.actions';

import ListSelectorError from '../../../../shared/components/form/ListSelectorError/ListSelectorError';
import withFarm from '../../../../shared/hocs/context/withFarm';
import ParcelZoneSelector from '../../../actions/shared/components/ParcelZoneSelector/ParcelZoneSelector';
import ReportsParcelsList from '../../components/ReportsParcelsList/ReportsParcelsList';

class ReportsParcelsControl extends Component {
  componentDidUpdate(prevProps) {
    const {
      parcelsToAdd: prevParcelsToAdd,
    } = prevProps;

    const {
      parcelsToAdd: newParcelsToAdd,
    } = this.props;

    /*
     * Adds parcels from the external prop parcelsToAdd,
     * which is the user interaction, but parcels can be
     * added also thru the form initialization (like from the local storage)
     */
    if (newParcelsToAdd.length > prevParcelsToAdd.length) {
      this.addParcels(differenceBy(newParcelsToAdd, prevParcelsToAdd, 'id'));
      this.props.clearParcelsToAdd();
    }
  }

  getSuggestions = searchInput => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.fetchParcelsAndZonesSuggestions(searchInput, parcelsOnly, zonesOnly);
  };

  clearSuggestions = () => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.clearParcelsAndZonesSuggestions(parcelsOnly, zonesOnly);
  };

  addParcels(parcels) {
    const parcelsIndFields = this.props.fields.getAll() || [];
    this.props.updateParcelsList(
      [
        ...parcelsIndFields,
        ...parcels
          .filter(parcel => !this.isParcelInList(parcel)),
      ],
      this.props.formName,
    );
    return parcels;
  }

  isParcelInList = parcelToCheck => find(this.props.fields.getAll(), ['id', parcelToCheck.id]) !== undefined;

  handleSuggestionSelected = suggestion => {
    this.props.addParcelOrZoneParcelsToAdd(suggestion);
  };

  handleItemRemove = index => {
    this.props.fields.remove(index);
  };

  render() {
    const {
      farm,
      fields,
      intl: { formatMessage },
      meta: { error, submitFailed },
      placeholder,
      suggestions,
    } = this.props;

    return (
      <Fragment>
        <ParcelZoneSelector
          autoFocus={true}
          inputRef={ref => ref}
          onSuggestionsClearRequested={this.clearSuggestions}
          onSuggestionSelected={this.handleSuggestionSelected}
          onSuggestionsFetchRequested={this.getSuggestions}
          placeholder={placeholder}
          suggestions={suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
        />
        {submitFailed && error && <ListSelectorError error={<FormattedMessage id="ParcelControl.chooseParcel" />} />}
        <ReportsParcelsList
          farm={farm}
          fields={fields}
          onItemRemove={this.handleItemRemove}
        />
      </Fragment>
    );
  }
}

ReportsParcelsControl.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  fetchParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  clearParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  addParcelOrZoneParcelsToAdd: PropTypes.func.isRequired,
  clearParcelsToAdd: PropTypes.func.isRequired,
  updateParcelsList: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  parcelsToAdd: PropTypes.array,
  intl: PropTypes.object.isRequired,
  parcelsOnly: PropTypes.bool,
  zonesOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  farm: PropTypes.object.isRequired,
};

ReportsParcelsControl.defaultProps = {
  parcelsToAdd: [],
  parcelsOnly: false,
  zonesOnly: false,
  placeholder: 'ParcelZoneSelector.placeholder',
};

const mapStateToProps = (state, props) => {
  const { filter, formName } = props;

  return {
    formErrors: getFormSyncErrors(formName)(state),
    suggestions: getParcelsAndZonesSuggestions(i => (filter?.length ? filter.includes(i.id) : true), formName)(state),
    parcelsToAdd: getParcelsToAdd(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchParcelsAndZonesSuggestions,
      clearParcelsAndZonesSuggestions,
      addParcelOrZoneParcelsToAdd,
      clearParcelsToAdd,
      updateParcelsList,
    },
    dispatch,
  );

export default compose(connect(mapStateToProps, mapDispatchToProps),
  withFarm(), injectIntl)(ReportsParcelsControl);
