import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style, Icon, Circle, Text } from 'ol/style';

import { GEOMETRY_WHOLE_COORDINATES, GPS_COORDINATES_PRECISION } from '../irrigation.constants';

import crosshair from '../../assets/img/crosshair.png';
import pointDefault from '../../assets/img/icons/point-default.svg';
import pointSelectedHovered from '../../assets/img/icons/point-selected-hovered.svg';
import pointSelected from '../../assets/img/icons/point-selected.svg';
import Layers from '../../core/map/services/Layers.service';
import MapService from '../../core/map/services/Map.service';
import { transformOptions } from '../../shared/misc/map.helpers';

import { AREAS_MAP_STYLES as STYLES, getStrokeStyle, getFillStyle, getIconStyle } from './IrrigationAreasMap.styleHelpers';

export const LAYERS = {
  ASSIGNED_DEVICES: 'ASSIGNED_DEVICES',
  UNASSIGNED_DEVICES: 'UNASSIGNED_DEVICES',
};

export default class IrrigationMapService {
  constructor(config, farm, history, setDefaultExtent) {
    this.config = config;
    this.customer = farm.customer;
    this.farm = farm;
    this.history = history;
    this.setDefaultExtent = setDefaultExtent;

    this.map = new MapService('irrigation-areas-map', farm.id, farm.boundingBox, transformOptions);
    this.unassignedDevicesLayer = this.setPointsLayer();
    this.assignedDevicesLayer = this.setPointsLayer();
    this.layers = new Layers(this.map.getMap(), this.config.api, this.farm.id, this.map.getFarmExtent());
    this.drawInteraction = this.setDrawInteraction();
    this.resetSelector = () => {};
    this.saveDataToStore = () => {};
    this.toggleAssignedDevicesLayer(false);
  }

  getMap() {
    return this.map;
  }

  getLayers() {
    return this.layers;
  }

  toggleAssignedDevicesLayer = (bool) => {
    this.assignedDevicesLayer.setVisible(bool);
  };

  setLayersWithConfig(newLayersConfig, storeInitialLayers) {
    this.layers.setInitialLayers(newLayersConfig, storeInitialLayers);
  }

  getStyle() {
    return new Style(this.layers.getParcelLayer(), this.layers.getParcelLabelLayer(), this.customer.countryCode);
  }

  setStyleForUnassignedDevices(feature, selected, hovered) {
    const featureId = feature.get('id');

    const isSelected = selected.includes(featureId);
    const isHovered = featureId === hovered;

    if (isHovered) {
      return [getIconStyle(pointSelectedHovered)];
    }

    if (isSelected) {
      return [getIconStyle(pointSelected)];
    }

    if (featureId) {
      return [getIconStyle(pointDefault)];
    }
  }

  setStyleForAssignedDevices() {
    return new Style({
      image: new Circle({
        fill: getFillStyle(STYLES.CIRCLE_COLOR),
        stroke: getStrokeStyle(),
        radius: STYLES.RADIUS_SMALL,
      }),
    });
  }

  setHoveredStyle(featureId) {
    const hoverStyle = new Style({
      image: new Circle({
        fill: getFillStyle(STYLES.HOVER_COLOR),
        radius: STYLES.RADIUS_LARGE,
      }),
      text: new Text({
        text: featureId,
        font: STYLES.FONT,
        fill: getFillStyle(STYLES.TEXT_COLOR),
        offsetY: STYLES.OFFSET_Y,
        stroke: getStrokeStyle(),
      }),
    });

    return hoverStyle;
  }

  setPointsToLayer(points, layer = LAYERS.UNASSIGNED_DEVICES) {
    let pointsSource;
    const transformedPoints = points.map(p => {
      const { coordinates } = p.geometry;

      // check to avoid breaking the map with invalid coord data
      const hasInvalidCoordinates = coordinates.some((c) => isNaN(c));
      if (hasInvalidCoordinates) {
        return null;
      }

      const geom = new Point(transform(p.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'));
      const feature = new OlFeature({
        id: p.id,
        geometry: geom,
      });
      feature.setId(p.id);
      return feature;
    }).filter(Boolean);

    if (layer === LAYERS.UNASSIGNED_DEVICES) {
      pointsSource = this.unassignedDevicesLayer.getSource();
    } else {
      pointsSource = this.assignedDevicesLayer.getSource();
    }

    pointsSource.clear(true);
    pointsSource.addFeatures(transformedPoints);

    if (layer === LAYERS.UNASSIGNED_DEVICES) {
      const pointsExtent = this.map.getExtentFromLayer(this.unassignedDevicesLayer);
      if (pointsExtent?.length) {
        this.setDefaultExtent(pointsExtent);
      }
    }
    if (layer === LAYERS.ASSIGNED_DEVICES) {
      this.styleAssignedDevicesLayer();
    }
  }

  styleUnassignedDevicesLayer(selected, hovered) {
    this.unassignedDevicesLayer.setStyle(feature => this.setStyleForUnassignedDevices(feature, selected, hovered));
  }

  styleAssignedDevicesLayer() {
    this.assignedDevicesLayer.setStyle(() => this.setStyleForAssignedDevices());
  }

  setPointsLayer() {
    return new VectorLayer({
      source: new VectorSource(),
    });
  }

  setDrawInteraction() {
    const draw = new Draw({
      type: 'Point',
      source: this.unassignedDevicesLayer.getSource(),
      style: new Style({
        image: new Icon({
          src: crosshair,
          size: [32, 32],
        }),
      }),
    });

    draw.on('drawend', (e) => {
      const map = this.map.getMap();

      const rawCoords = e.feature.getGeometry().getCoordinates();
      const wgsCoords = transform(rawCoords, 'EPSG:3857', 'EPSG:4326');
      const coords = wgsCoords.map(coord => coord.toFixed(GPS_COORDINATES_PRECISION));
      this.resetSelector();
      this.saveDataToStore(this.selectedDeviceId, GEOMETRY_WHOLE_COORDINATES, coords);

      map.removeInteraction(draw);
    });

    return draw;
  }

  enableHoverInteraction = () => {
    const map = this.map.getMap();
    let prevHovered;
    let feature;

    map.on('pointermove', evt => {
      const featuresAtPixel = map.getFeaturesAtPixel(evt.pixel,
        { layerFilter: (layer) => layer === this.assignedDevicesLayer });

      if (featuresAtPixel.length) {
        feature = featuresAtPixel[0];
        const featureId = feature.get('id');
        if (prevHovered !== featureId) {
          prevHovered = featureId;
          feature.setStyle([this.setHoveredStyle(featureId), this.setStyleForAssignedDevices()]);
        }
      } else if (prevHovered) {
        if (feature) {
          feature.setStyle([this.setStyleForAssignedDevices()]);
        }
        prevHovered = null;
        feature = null;
      }
    });
  };

  selectNewCoordinatesFromMap(deviceId, saveDataFn, resetFn) {
    this.selectedDeviceId = deviceId;
    this.saveDataToStore = saveDataFn;
    this.resetSelector = resetFn;

    this.enableCoordinatesSelection();
  }

  enableCoordinatesSelection() {
    const map = this.map.getMap();
    const draw = this.drawInteraction;
    map.addInteraction(draw);
  }

  disableCoordinatesSelection() {
    const map = this.map.getMap();
    const draw = this.drawInteraction;
    map.removeInteraction(draw);
  }
}
