// CfReduxFormCheckbox
import Checkbox from '@mui/material/Checkbox';

import { createComponent } from '../../reduxFormUtils';

export default createComponent(
  Checkbox,
  ({
    defaultChecked,
    input,
    input: { onChange, value, ...restInputProps },
    meta,
    onChange: ignoredOnChange,
    ...restProps
  }) => ({
    ...restInputProps,
    ...restProps,
    checked: !!value,
    onChange: (evt, isInputChecked) => {
      onChange(isInputChecked);
    },
  }),
);
