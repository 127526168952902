import React, { Component, Fragment } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import withWidth from '../../../shared/hocs/withWidth';
import { fusariumCalculatorOptions } from '../../constants/fusariumCalculatorOptions';
import { fusariumCalculatorScale } from '../../constants/fusariumCalculatorScale';

const styles = theme => ({
  formControl: {
    marginBottom: 30,
    width: '100%',
  },
  formControlWrapperDefault: {
    paddingRight: 48,
  },
  formControlWrapperSm: {
    paddingRight: 16,
  },
  formControlWrapperXs: {
    paddingRight: 0,
  },
  description: {
    marginBottom: 12,
  },
  overallScore: {
    fontWeight: 700,
    fontSize: 20,
    marginRight: 12,
  },
  itemsFilled: {
    color: theme.palette.grey[500],
  },
  result: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: 1.25,
  },
  box: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 10,
    padding: 18,
  },
  boxHeading: {
    fontSize: 20,
    fontWeight: 700,
  },
  legendLabel: {
    fontWeight: 700,
    marginTop: 24,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionScore: {
    color: theme.palette.grey[500],
    marginLeft: 10,
  },
  radio: {
    padding: 4,
    marginLeft: 8,
  },
  formLabel: {
    marginBottom: 12,
    fontSize: 13,
  },
  formControlLabel: {
    marginBottom: 6,
    marginRight: 0,
  },
  radioLabel: {
    lineHeight: 1.25,
    flexGrow: 1,
    marginLeft: 6,
  },
});

class FusariumCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: {},
    };
  }

  handleChange = (itemId, value) => {
    const updatedItems = { ...this.state.selectedItems };
    updatedItems[itemId] = value;
    this.setState({ selectedItems: updatedItems });
  };

  render() {
    const { classes, width } = this.props;
    const { selectedItems } = this.state;
    const results = fusariumCalculatorOptions
      .map(item => item.options.find(option => selectedItems[item.id] === option.id))
      .filter(option => !!option);
    let formControlWrapperClass = classes.formControlWrapperDefault;
    formControlWrapperClass = width === 'sm' ? classes.formControlWrapperSm : formControlWrapperClass;
    formControlWrapperClass = width === 'xs' ? classes.formControlWrapperXs : formControlWrapperClass;

    return (
      <Grid container spacing={2}>
        <Grid className={classes.description} item xs={12}>
          <FormattedMessage id="FusariumCalculator.description" />
        </Grid>
        <Grid item sm={6} xs={12}>
          {fusariumCalculatorOptions.map(item => (
            <div className={formControlWrapperClass} key={item.id}>
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel className={classes.formLabel} component="legend">
                  <FormattedMessage id={`FusariumCalculator.options.${item.nameTranslationId}`} />
                </FormLabel>
                <RadioGroup
                  aria-label={<FormattedMessage id={`FusariumCalculator.options.${item.nameTranslationId}`} />}
                  className={classes.group}
                  name={item.nameTranslationId}
                  onChange={event => this.handleChange(item.id, event.target.value)}
                  value={selectedItems[item.id] || ''}
                >
                  {item.options.map(option => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={<Radio className={classes.radio} color="primary" />}
                      key={option.id}
                      value={option.id}
                      classes={{
                        label: classes.radioLabel,
                      }}
                      label={
                        <div className={classes.option}>
                          <FormattedMessage id={`FusariumCalculator.options.${option.nameTranslationId}`} />
                          <span className={classes.optionScore}>{option.score}</span>
                        </div>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={`${classes.box} ${classes.result}`}>
            <span className={classes.overallScore} data-test="overall-score">
              <FormattedMessage id="FusariumCalculator.totalScore" /> ={' '}
              <span data-test="score-result">
                {results.length === fusariumCalculatorOptions.length ? (
                  <Fragment>{results.reduce((a, b) => a + (b.score || 0), 0)}</Fragment>
                ) : (
                  <Fragment>?</Fragment>
                )}
              </span>
            </span>
            <span className={classes.itemsFilled}>
              <FormattedMessage
                id="FusariumCalculator.resultLabel"
                values={{ provided: results.length, total: fusariumCalculatorOptions.length }}
              />
            </span>
          </div>
          <div className={classes.box} data-test="risk-scale">
            <div className={classes.boxHeading}>
              <FormattedMessage id="FusariumCalculator.riskScale" />
            </div>
            {fusariumCalculatorScale.map(scaleItem => (
              <div data-test="risk-scale-item" key={scaleItem.id}>
                <div className={classes.legendLabel} style={{ color: scaleItem.color }}>
                  <span>
                    <FormattedMessage id={`FusariumCalculator.scale.${scaleItem.nameTranslationId}`} />
                  </span>
                  <span>
                    <FormattedMessage id={`FusariumCalculator.scale.${scaleItem.boundaryTranslationId}`} />
                  </span>
                </div>
                <div>
                  <FormattedMessage id={`FusariumCalculator.scale.${scaleItem.descriptionTranslationId}`} />
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    );
  }
}

FusariumCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(FusariumCalculator);
