import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { compose } from 'react-recompose';

import CfLazyImage from '../../../../../../shared/components/common/CfLazyImage/CfLazyImage';

const styles = theme => ({
  wrapper: {
    margin: '10px 5px 5px 5px',
  },
  image: {
    width: '150px',
    height: 'auto',
    imageRendering: 'pixelated',
    '&': {
      // fallback for FF
      imageRendering: '-moz-crisp-edges',
    },
    backgroundColor: theme.palette.grey[100],
  },
  dateHeading: {
    textAlign: 'center',
    padding: 2,
    fontSize: 13,
  },
  images: {
    display: 'flex',
  },
});

export const ManagementHistoryImage = props => {
  const { classes, item, width } = props;

  return (
    <div className={classes.wrapper} data-test="history-item">
      <div className={classes.images} style={{ flexDirection: width === 'xs' ? 'row' : 'column' }}>
        <CfLazyImage
          alt={`History Snap from ${item.date}`}
          classes={{ custom: classes.image }}
          src={item.managementZoneImgPath}
          testId="satellite"
        />
        <CfLazyImage
          alt={`History Snap from ${item.date}`}
          classes={{ custom: classes.image }}
          src={item.involvementImgPath}
          testId="involvement"
        />
      </div>
      <div className={classes.dateHeading} data-test="date">
        <FormattedDate value={new Date(item.date)} />
      </div>
    </div>
  );
};

ManagementHistoryImage.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(ManagementHistoryImage);
