import { RSAAAction } from 'redux-api-middleware';

import { getTelematicsListAdvancedFilter, getTelematicsListDateFrom, getTelematicsListDateTo, getTelematicsListOrder, getTelematicsListOrderBy, getTelematicsListTextFilter } from '../selectors/telematicsList.selectors';

import * as types from './telematicsList.constants';

import { exportDrivesApi, getDriveDetailApi, getDriversApi, getEquipmentsApi, getMachinesApi, getOperationsApi, getProductionOperationsApi } from '../../shared/api/telematics/drives/drives.api';
import FileService from '../../shared/services/File.service';

import { TelematicsState } from '../../reducers/telematics.reducer.types';
import { ExportPayload } from '../../shared/api/api.types';
import { ExportDrivesApiParams, ExportDrivesFormat, GetDriversApiParams, GetEquipmentsApiParams, GetMachinesApiParams } from '../../shared/api/telematics/drives/drives.types';
import { State } from '../../shared/api/telematics/telematics.types';

export const setDuration = (duration: string) => ({
  type: types.SET_DURATION,
  duration,
});

export const setDateFrom = (dateFrom: string) => ({
  type: types.SET_DATE_FROM,
  dateFrom,
});

export const setDateTo = (dateTo: string) => ({
  type: types.SET_DATE_TO,
  dateTo,
});

export const fetchDrivesSaga = () => ({
  type: types.SAGA_FETCH_DRIVES,
});

export const changeDriveStateSaga = (state: State) => ({
  type: types.SAGA_CHANGE_DRIVE_STATE,
  state,
});

export const exportDrives = (format: ExportDrivesFormat) =>
  (dispatch: (action: RSAAAction) => Promise<ExportPayload>, getState: () => TelematicsState) => {
    const state = getState();

    const params: ExportDrivesApiParams = {
      dateFrom: getTelematicsListDateFrom(state),
      dateTo: getTelematicsListDateTo(state),
      'sort-col': getTelematicsListOrderBy(state),
      'sort-dir': getTelematicsListOrder(state),
      state: getTelematicsListAdvancedFilter(state).state,
      operation: getTelematicsListAdvancedFilter(state).operation,
      productionOperation: getTelematicsListAdvancedFilter(state).productionOperation?.code,
      parcel: getTelematicsListAdvancedFilter(state).parcel?.map(parcel => parcel.id),
      driver: getTelematicsListAdvancedFilter(state).driver?.map(driver => driver.code),
      gpsUnit: getTelematicsListAdvancedFilter(state).machine?.map(machine => machine.gpsUnit),
      search: getTelematicsListTextFilter(state),
    };

    dispatch(exportDrivesApi(format, params)).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };

export const fetchDriveDetail = (id: string) => (dispatch: (action: RSAAAction) => void) => {
  dispatch(getDriveDetailApi(id));
};

export const fetchOperations = () => (dispatch: (action: RSAAAction) => void) => {
  dispatch(getOperationsApi());
};

export const fetchProductionOperations = () => (dispatch: (action: RSAAAction) => void) => {
  dispatch(getProductionOperationsApi());
};

export const fetchDrivers = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => TelematicsState,
) => {
  const state = getState();

  const params: GetDriversApiParams = {
    dateFrom: setCorrectDate(getTelematicsListDateFrom(state)),
    dateTo: setCorrectDate(getTelematicsListDateTo(state)),
  };
  dispatch(getDriversApi(params));
};

export const fetchMachines = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => TelematicsState,
) => {
  const state = getState();

  const params: GetMachinesApiParams = {
    dateFrom: setCorrectDate(getTelematicsListDateFrom(state)),
    dateTo: setCorrectDate(getTelematicsListDateTo(state)),
  };
  dispatch(getMachinesApi(params));
};

export const fetchEquipments = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => TelematicsState,
) => {
  const state = getState();

  const params: GetEquipmentsApiParams = {
    dateFrom: setCorrectDate(getTelematicsListDateFrom(state)),
    dateTo: setCorrectDate(getTelematicsListDateTo(state)),
  };
  dispatch(getEquipmentsApi(params));
};

// set correct dateFrom in format 'YYYY-MM-DD'
export const setCorrectDate = (date?: string) => {
  if (!date) return;
  return new Date(date).toLocaleDateString('en-CA');
};
