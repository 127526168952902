import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WeatherIcon = props => (
  <SvgIcon {...props}>
    <svg height="24" viewBox="0 0 18 18" width="24" x="0px" xmlns="http://www.w3.org/2000/svg" y="1px">
      <path
        d="M8.52137 4.71875C6.21738 4.71875 4.35107 6.58493 4.35107 8.88874C4.35107 11.1926 6.21738 13.0587 8.52137 13.0587C10.8254 13.0587 12.6917 11.1926 12.6917 8.88874C12.6917 6.58493 10.8212 4.71875 8.52137 4.71875Z"
        fill="white"
      />
      <path d="M9.24413 0.886719H7.92285V3.4878H9.24413V0.886719Z" fill="white" />
      <path d="M9.24365 14.2891H7.92236V16.8901H9.24365V14.2891Z" fill="white" />
      <path d="M16.5197 8.23438H13.9185V9.55556H16.5197V8.23438Z" fill="white" />
      <path d="M3.12081 8.23828H0.519531V9.55947H3.12081V8.23828Z" fill="white" />
      <path d="M3.3752 2.72204L2.44092 3.65625L4.28028 5.49548L5.21456 4.56127L3.3752 2.72204Z" fill="white" />
      <path d="M12.8464 12.1986L11.9121 13.1328L13.7515 14.972L14.6857 14.0378L12.8464 12.1986Z" fill="white" />
      <path d="M13.7173 2.77015L11.8779 4.60938L12.8122 5.54359L14.6516 3.70436L13.7173 2.77015Z" fill="white" />
      <path d="M4.24366 12.2428L2.4043 14.082L3.33858 15.0162L5.17794 13.177L4.24366 12.2428Z" fill="white" />
    </svg>
  </SvgIcon>
);

export default WeatherIcon;
