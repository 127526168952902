import React from 'react';

import moment from 'moment';

export const defaultValues = {
  parcels: [],
  date: moment().startOf('day'),
  actionType: '',
  description: '',
  productId: '',
  totalGain: 0,
  hectarGain: 0,
  harvestCrop: true,
  isCatchCrop: false,
  seedId: '',
  totalAmount: 0,
  hectarAmount: 0,
  unitId: 't',
};

export const FormContext = React.createContext(defaultValues);
