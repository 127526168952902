import React from 'react';

import Chip from '@mui/material/Chip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  chip: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 14,
  },
});

function CfChip(props) {
  const { classes, message } = props;
  return (<Chip className={classes.chip} label={message} />);
}

CfChip.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.node.isRequired,
};

CfChip.defaultProps = {
  classes: {},
};

export default withStyles(styles)(CfChip);
