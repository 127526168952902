import React, { FC } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getOperations } from '../../../shared/api/telematics/drives/drives.selectors';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItemColumn from '../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import { getOperationDuration, getOperationName } from '../../helpers';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { OperationTo } from '../../../shared/api/telematics/telematics.types';
import { TelematicsOperationFormValues } from '../../telematics.types';

interface Props {
    handleClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    isEditing: boolean;
    operations: OperationTo[];
    values: TelematicsOperationFormValues;
}

const useStyles = makeStyles((theme: Theme) => ({
  editLink: {
    fontWeight: 500,
  },
  disabledLink: {
    color: theme.palette.grey[500],
    cursor: 'initial',
    '&:hover, &:focus': {
      color: 'gray',
      textDecoration: 'none',
    },
  },
}));

const TelematicsOperationContent: FC<Props> = ({ handleClick, isEditing, operations, values }) => {
  const hasValidTimes = values.timeFrom && values.timeTo;
  const durationTime = getOperationDuration(values.timeFrom, values.timeTo);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item sm={3} xs={12}>
          <SelectionItemColumn>
            <div>
              {values.operation &&
                <a
                  className={classnames(classes.editLink, !isEditing && classes.disabledLink)}
                  href="#"
                  onClick={handleClick}
                >
                  {getOperationName(operations, values.operation)}
                </a>}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={2} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="common.time" />}>
            <div>
              {hasValidTimes && values.timeFrom?.format('HH:mm')}-{values.timeTo?.format('HH:mm')}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={1} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="TelematicsList.duration" />}>
            <div>
              {hasValidTimes && durationTime}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={3} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="TelematicsList.productionOperation" />}>
            <div>
              {values?.productionOperation?.name}
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={1} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
            <div>
              <CfFormattedNumber value={values.cultivated || 0} />
            </div>
          </SelectionItemColumn>
        </Grid>
        <Grid item sm={2} xs={6}>
          <SelectionItemColumn label={<FormattedMessage id="TelematicsList.operationDistance" />}>
            <div>
              <CfFormattedNumber decimalDigits={2} value={values.distance || 0} />
            </div>
          </SelectionItemColumn>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  operations: getOperations(state),
});

export default connect(mapStateToProps)(TelematicsOperationContent);
