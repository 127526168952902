export default class Intercom {
  static boot(id, opts = {}) {
    window.Intercom('boot', {
      app_id: id,
      ...opts,
    });
  }

  static show(type = 'show') {
    window.Intercom(type);
  }

  static update(data) {
    window.Intercom('update', data);
  }

  /**
   * Shortcut for shutdown and boot
   */
  static restart(id, opts = {}) {
    this.shutdown();
    this.boot(id, opts);
  }

  static shutdown() {
    window.Intercom('shutdown');
  }

  static hideLauncher() {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
}
