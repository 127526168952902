import React, { FC } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    padding: '4px 8px',
    borderRadius: '50px',
    fontSize: 14,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
}));

interface Props {
   syncWithPC: () => void
}

const SyncWithPlantControl: FC <Props> = ({
  syncWithPC,
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      disableInteractive
      title={
        <span>
          <FormattedMessage id="IrrigationAdmin.syncWithPC.tooltip" />
        </span>
      }
    >
      <Button
        className={classes.root}
        onClick={syncWithPC}
        size="small"
        variant="contained"
      >
        <RefreshIcon sx={{ mr: 1 }} />
        <FormattedMessage id="IrrigationAdmin.syncWithPC" />
      </Button>
    </Tooltip>
  );
};

export default SyncWithPlantControl;
