export const FORM_TYPES = {
  OTHER: 'OTHER',
  HARVEST: 'HARVEST',
  SOWING: 'SOWING',
  MOWING: 'MOWING',
};

export const FORM_TITLE_LOCALIZATION = {
  [FORM_TYPES.OTHER]: 'action.formTitle.other',
  [FORM_TYPES.HARVEST]: 'action.formTitle.harvest',
  [FORM_TYPES.SOWING]: 'common.SOWING',
  [FORM_TYPES.MOWING]: 'common.MOWING',
};

export const OTHER_ACTION_HARVEST_CODE = 'HARVEST';
export const OTHER_ACTION_SOWING_CODE = 'SOWING';
export const OTHER_ACTION_MOWING_CODE = 'MOWING';
export const NOT_SOWN_PARCEL_SEED_NAME = 'Bez plodiny';

export const OTHER_ACTION_TYPE_CODES = [
  'CULTIV',
  'DISK',
  'HARROW',
  'HILL',
  'HOE',
  'IRRIG',
  'MULCHING',
  'PLOW',
  'PREP',
  'ROLL',
  'SUBSOILING',
  'TILLAGE',
  'TRAFFIC',
];

export const OTHER_ACTIONS_TYPES = [
  {
    typeCodes: OTHER_ACTION_TYPE_CODES,
    formType: FORM_TYPES.OTHER,
  },
  {
    typeCodes: [OTHER_ACTION_HARVEST_CODE],
    formType: FORM_TYPES.HARVEST,
  },
  {
    typeCodes: [OTHER_ACTION_SOWING_CODE],
    formType: FORM_TYPES.SOWING,
  },
  {
    typeCodes: [OTHER_ACTION_MOWING_CODE],
    formType: FORM_TYPES.MOWING,
  },
];
