import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ClassifiersIcon = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M24.93,4.07V23.93H5.07V4.07Zm1.56-2.84h-23A1.28,1.28,0,0,0,2.23,2.51v23a1.37,1.37,0,0,0,1.28,1.28h23a1.51,1.51,0,0,0,1.28-1.28v-23A1.37,1.37,0,0,0,26.49,1.23ZM13.58,6.91H22.1V9.74H13.58Zm0,5.67H22.1v2.84H13.58Zm0,5.68H22.1V21.1H13.58ZM7.91,6.91h2.83V9.74H7.91Zm0,5.67h2.83v2.84H7.91Zm0,5.68h2.83V21.1H7.91Z" />
    </svg>
  </SvgIcon>
);

export default ClassifiersIcon;
