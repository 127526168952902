import React from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  wrapper: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const CfTableBodyEmpty = props => (
  <TableBody>
    <TableRow>
      <TableCell className={props.classes.wrapper} colSpan={props.colLength} data-test="no-data" padding="normal">
        <FormattedMessage id={props.translId} />
      </TableCell>
    </TableRow>
  </TableBody>
);

CfTableBodyEmpty.propTypes = {
  classes: PropTypes.object.isRequired,
  colLength: PropTypes.number.isRequired,
  translId: PropTypes.string,
};

CfTableBodyEmpty.defaultProps = {
  translId: 'common.empty-table',
};

export default withStyles(styles)(CfTableBodyEmpty);
