/**
 * Created by ondrejzvara on 6.4.16.
 */

/**
 * Localization service
 * @namespace Localization
 */

/* @ngInject */
export default function Localization($translate, tmhDynamicLocale, $q, $locale) {
  const service = {
    setCurrLangId,
    getCurrentLangId,
    getCurrentLangIdAsync,
    stripLangId,
    getMessages,
    str2num
  };
  return service;

  // ///////////////////////

  function setCurrLangId(langId) {
    return $q.all([$translate.use(langId), tmhDynamicLocale.set(langId)]);
  }

  function getCurrentLangId() {
    return $translate.use();
  }

  function getCurrentLangIdAsync() {
    return $translate.onReady().then(() => $translate.use());
  }

  function stripLangId(langId) {
    return langId.toLowerCase().split(/[_-]+/)[0];
  }

  function getMessages(codes) {
    return $translate(codes);
  }

  /**
     * @public
     * @memberof Localization
     * @param {String} str string to transform
     * @returns {Number}
     */
  function str2num(str) {
    if (!_.isString(str)) {
      return str;
    }

    let groupRegExPattern = $locale.NUMBER_FORMATS.GROUP_SEP;
    groupRegExPattern =
    groupRegExPattern.replace(/\s/g, '').length === 0 ? '\\s' : groupRegExPattern;
    groupRegExPattern = groupRegExPattern === '.' ? '\\.' : groupRegExPattern;

    return _.toNumber(
      str
        .replace(new RegExp(groupRegExPattern, 'g'), '')
        .replace($locale.NUMBER_FORMATS.DECIMAL_SEP, '.')
    );
  }
}
