import React, { Component } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

const styles = theme => ({
  switchFormControlLabel: {
    margin: 0,
    marginRight: 12,
  },
  monthSelect: {
    verticalAlign: 'bottom',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  monthYearWrapper: {
    minWidth: 250,
    marginBottom: 8,
  },
  labelWrapper: {
    width: 'max-content',
    marginBottom: 4,
  },
  monthPlaceholder: {
    color: theme.palette.grey[500],
  },
});

export class NodeLocationsHistoryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historic: props.historyFilter.historic,
    };
  }

  getAvailableYearsWithMonths = () => {
    const {
      intl: { formatDate },
    } = this.props;

    const startDate = moment(this.props.statistics.firstActivation);
    const startYear = startDate.year();
    const currentTime = moment();
    const yearsWithMonths = {
      years: [startYear],
      monthsByYear: {},
    };
    yearsWithMonths.monthsByYear[startYear] = [];
    let newYear = startYear;
    while (
      startDate.isBefore(currentTime) ||
      (startDate.month() === currentTime.month() && startDate.year() === currentTime.year())
    ) {
      if (startDate.year() !== newYear) {
        newYear = startDate.year();
        yearsWithMonths.years.push(startDate.year());
        yearsWithMonths.monthsByYear[startDate.year()] = [];
      }
      yearsWithMonths.monthsByYear[newYear].push({
        value: startDate.month() + 1,
        label: formatDate(startDate, { month: 'long' }),
      });
      startDate.add(1, 'months');
    }
    return yearsWithMonths;
  };

  handleSwitchChange = name => event => {
    const checked = event.target.checked;
    this.setState({ [name]: checked });
    this.props.setLocationsFilter({ historic: checked });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    const filterParams = {};
    filterParams[name] = event.target.value;
    this.props.setLocationsFilter(filterParams);
  };

  render() {
    const { classes, historyFilter } = this.props;
    const { historic } = this.state;
    const availableYearsWithMonths = this.getAvailableYearsWithMonths();

    return (
      <div className={classes.wrapper}>
        <span className={classes.labelWrapper}>
          <FormControlLabel
            className={classes.switchFormControlLabel}
            label={<FormattedMessage id="NodeLocationsHistoryFilter.viewHistory" />}
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.historic}
                color="primary"
                name="show-historic"
                onChange={this.handleSwitchChange('historic')}
              />
            }
          />
        </span>
        {historic && (
          <span className={classes.monthYearWrapper}>
            <Grid alignItems="flex-end" container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    onChange={this.handleChange('year')}
                    value={historyFilter.year}
                    inputProps={{
                      name: 'sensors-filter-year',
                      id: 'sensors-filter-year',
                    }}
                  >
                    {availableYearsWithMonths.years.map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.monthSelect} fullWidth>
                  <Select
                    displayEmpty
                    onChange={this.handleChange('month')}
                    value={historyFilter.month}
                    inputProps={{
                      name: 'sensors-filter-month',
                      id: 'sensors-filter-month',
                    }}
                  >
                    <MenuItem value="">
                      <span className={classes.monthPlaceholder}>
                        <FormattedMessage id="NodeLocationsHistoryFilter.allMonths" />
                      </span>
                    </MenuItem>
                    {availableYearsWithMonths.monthsByYear[historyFilter.year].map(month => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </span>
        )}
      </div>
    );
  }
}

NodeLocationsHistoryFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  setLocationsFilter: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  historyFilter: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(NodeLocationsHistoryFilter));
