/**
 * Created by jenc on 12/22/15.
 */

export default function () {
    return {
        link: {
            pre: function preLink() {
            },
            post: function postLink(scope, element) {
                // this succeeds since the element has been rendered
                element[0].focus();
            }
        }
    };
}
