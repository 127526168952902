/**
 * Created by ondrejzvara on 22.3.17.
 */


import angular from 'angular';

import Modal from './Modal.service';
import ModalController from './modal.controller';

export default angular.module('app.modal', [])
    .controller('ModalController', ModalController)
    .factory('Modal', Modal)
    .name;
