/**
 * Created by ondrejzvara on 25.6.16.
 */

import Jwt from '../../../../shared/services/Jwt.service';

/*@ngInject*/
export default function RequestInterceptor(Localization, Spinner, authService) {

  var service = {
    catchErrorInterceptorBypass: catchErrorInterceptorBypass,
    jsogEncode: jsogEncode,
    langParamInterceptor: langParamInterceptor,
    spinnerStart: spinnerStart,
    authRequestInterceptor: authRequestInterceptor
  };
  return service;

  /////////////////////////

  function catchErrorInterceptorBypass(element, operation, what, url, headers, params, httpConfig) {
    if (params.bypassErrorInterceptor) {

      delete params.bypassErrorInterceptor;
      httpConfig.bypassErrorInterceptor = true;

      return {
        element: element,
        headers: headers,
        params: params,
        httpConfig: httpConfig
      };
    }
  }

  function jsogEncode(element, operation) {
    var elem = element;
    if (operation === 'remove' || operation === 'put' || operation === 'post' && !(element instanceof FormData)) {
      elem = JSOG.encode(elem);
    }
    return elem;
  }

  function langParamInterceptor(element, operation, what, url, headers, params, httpConfig) {
    var langKey = Localization.getCurrentLangId();
    var bypass = params ? params.bypassLangInterceptor : false;
    delete params.bypassLangInterceptor;

    if (!_.isEmpty(langKey) && !bypass) {

      params = params ? params : {};

      var indexOfLangSeparator = langKey.indexOf("-");
      if (indexOfLangSeparator > 0) {
        langKey = langKey.substring(0, indexOfLangSeparator);
      }

      params.language = langKey;
      return {
        element: element,
        headers: headers,
        params: params,
        httpConfig: httpConfig
      };
    }
  }

  function spinnerStart(/* jshint unused:false*/ element, operation, what, url, headers, params, httpConfig) {
    Spinner.requestSpinnerInterceptor(url, operation, params);
  }

  function authRequestInterceptor(element, operation, route, url, headers) {
    const currentHeaders = headers;

    if (!Jwt.hasValidToken()) {
      authService.loginCancelled();
      return;
    }

    const token = Jwt.getToken();
    if (token) {
      currentHeaders.Authorization = 'Bearer ' + token.id_token;
    }

    return {
      headers: currentHeaders
    };
  }

}
