import moment, { Moment } from 'moment';

import { OperationTo, TelematicsOperation } from '../../shared/api/telematics/telematics.types';

export const getOperationName = (operations: OperationTo[], operationCode: TelematicsOperation) =>
  operations.find((operation) => operation.code === operationCode)?.name;

export const printTimeString = (hours: number, minutes: number) => {
  if (minutes > 9) {
    return `${hours}:${minutes}`;
  } else {
    return `${hours}:0${minutes}`;
  }
};

export const getOperationDuration = (timeFrom: Moment | null, timeTo: Moment | null) => {
  const hasValidTimes = timeFrom?.isValid() && timeTo?.isValid();
  if (hasValidTimes) {
    const start = moment(timeFrom);
    start.set('seconds', 0);
    const stop = moment(timeTo);
    stop.set('seconds', 59);
    const minutesDuration = stop.diff(start, 'minutes');
    return printTimeString((Math.floor(minutesDuration / 60)), minutesDuration % 60);
  } else {
    return '';
  }
};
