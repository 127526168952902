/**
 * Classifier controller
 */

import TableCache from '../../../../shared/services/TableCache.service';

/* @ngInject */
export default function ClassifiersPublicController(NgTableParams, Tabs, $scope, $state) {
  const vm = this;

  // private
  let resource = {};

  // public
  vm.loadingPromise = undefined;
  vm.tabs = Tabs.getTabsTpl('classifiersPublic');
  vm.tableParams = {};

  /**
   * Classifiers controller
   * @namespace Classifiers
   */
  vm.activate = activate;
  vm.setResource = setResource;

  // //////////////////////////////////////////

  function activate(farmId, queryParams) {
    vm.farmId = farmId;
    const retrievedParams = TableCache.retrieveTableParams(farmId, $state.current.name) || (queryParams || {});
    vm.tableParams = new NgTableParams(retrievedParams, {
      getData(p) {
        vm.loadingPromise = resource.getItems(p).then(result => result);
        return vm.loadingPromise;
      }
    });
  }

  function setResource(initResource) {
    resource = initResource;
  }

  $scope.$watch(
    () => vm.tableParams._params || null,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        TableCache.storeTableParams(vm.farmId, $state.current.name, newVal);
      }
    },
    true
  );
}
