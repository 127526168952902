import React from 'react';

import PropTypes from 'prop-types';

import CfAutocomplete from '../../../shared/components/common/CfAutocomplete/CfAutocomplete';

function NodeLocationSelectGroups(props) {
  const { groups, label, selectedGroups, setGroups } = props;

  return (
    <CfAutocomplete
      defaultValues={selectedGroups}
      getSelected={(option, value) => option.id === value?.id}
      id="group-select"
      isMultiple={true}
      label={label}
      onChange={value => setGroups('groups', value)}
      suggestions={groups}
      testId="group-select"
    />
  );
}

NodeLocationSelectGroups.propTypes = {
  selectedGroups: PropTypes.array,
  groups: PropTypes.array,
  label: PropTypes.node.isRequired,
  setGroups: PropTypes.func,
};

NodeLocationSelectGroups.defaultProps = {
  setGroups: () => {},
  groups: [],
  selectedGroups: [],
};

export default NodeLocationSelectGroups;
