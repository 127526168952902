import React from 'react';

import PropTypes from 'prop-types';

import CfAutocomplete from '../../common/CfAutocomplete/CfAutocomplete';

function SensorFilterByNodeType(props) {
  const { label, nodeTypes, selectedNodeType, setNodeTypeFilter } = props;

  return (
    <CfAutocomplete
      defaultValues={selectedNodeType}
      id="node-type-filter"
      isMultiple={true}
      label={label}
      onChange={value => setNodeTypeFilter('nodeType', value)}
      suggestions={nodeTypes}
      testId="node-type-filter"
    />
  );
}

SensorFilterByNodeType.propTypes = {
  selectedNodeType: PropTypes.array,
  setNodeTypeFilter: PropTypes.func.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
};

SensorFilterByNodeType.defaultProps = {
  selectedNodeType: [],
};

export default SensorFilterByNodeType;
