import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../CfFormattedNumber/CfFormattedNumber';

const style = {
  row: {
    display: 'flex',
  },
  amount: {
    width: '80%',
    textAlign: 'right',
  },
  unit: {
    textAlign: 'left',
    paddingLeft: 3,
    width: '20%',
  },
};

const FormattedAmount = props => {
  const { classes, expense, param, perHa } = props;
  return (expense ?
    (<span className={classes.row}>
      <span className={classes.amount} style={perHa ? { width: '60%' } : {}}>
        <CfFormattedNumber value={expense[param]} />
      </span>
      <span className={classes.unit} style={perHa ? { width: '40%' } : {}}>
        <FormattedMessage id={`unit.${expense.unitId ? expense.unitId.toLowerCase() : expense.unitId}`} />
        {perHa && '/ha'}
      </span>
    </span>) :
    (<span className={classes.row}>
      <span className={classes.amount}>
        {'-'}
      </span>
    </span>)
  );
};

FormattedAmount.propTypes = {
  classes: PropTypes.object,
  expense: PropTypes.object,
  perHa: PropTypes.bool,
  param: PropTypes.string.isRequired,
};

FormattedAmount.defaultProps = {
  classes: {},
  expense: null,
  perHa: false,
};

export default withStyles(style)(FormattedAmount);
